import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useHistory } from "react-router-dom";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { MdContentCopy } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import BreadCrumbs from "../../../components/plataformaAcessada/BreadCrumbs";
import api from "../../../services/api";

import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "./styles.css";

const GerenciarContrato = () => {
  const history = useHistory();

  const [textoContrato, setTextoContrato] = useState("");

  const [openListaVariaveis, setOpenListaVariaveis] = useState(false);

  const listaVariaveis = [
    "CONTRATO_ID",
    "CONTRATO_EMISSAO",
    "CONTRATO_MODELO",
    "CONTRATO_CODIGO",
    "DATA_HORA",
    "USUARIO",
    "CARGA_ID",
    "CARGA_ORIGEM_CIDADE",
    "CARGA_ORIGEM_UF",
    "CARGA_DESTINO_CIDADE",
    "CARGA_DESTINO_UF",
    "CARGA_VALOR",
    "CARGA_PESO",
    "MANIFESTO_NUMERO",
    "MANIFESTO_OBSERVACOES",
    "CTE_NUMEROS",
    "MOTORISTA_NOME",
    "MOTORISTA_CNH",
    "MOTORISTA_CPF",
    "MOTORISTA_TELEFONE",
    "MOTORISTA_END_CIDADE",
    "MOTORISTA_END_UF",
    "MOTORISTA_END_LOGRADOURO",
    "MOTORISTA_END_BAIRRO",
    "MOTORISTA_END_NUMERO",
    "MOTORISTA_END_CEP",
    "MOTORISTA_END_COMPLEMENTO",
    "MOTORISTA_PIS_PASEP",
    "TRANSPORTADORA_RAZAO_SOCIAL",
    "TRANSPORTADORA_CNPJ",
    "TRANSPORTADORA_IE",
    "TRANSPORTADORA_EMAIL",
    "TRANSPORTADORA_RNTRC",
    "TRANSPORTADORA_END_CIDADE",
    "TRANSPORTADORA_END_UF",
    "TRANSPORTADORA_END_LOGRADOURO",
    "TRANSPORTADORA_END_BAIRRO",
    "TRANSPORTADORA_END_NUMERO",
    "TRANSPORTADORA_END_CEP",
    "TRANSPORTADORA_END_COMPLEMENTO",
  ];

  const routes = [
    { path: "/acesso", breadcrumb: "Início" },
    { path: "/acesso/contrato", breadcrumb: "Contrato" },
  ];

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(`{{${text}}}`);
  };

  useEffect(() => {
    const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
    const { transportadora_id } = dadosUsuario;
    api
      .get(`/contrato/modelo/${transportadora_id}/`)
      .then((response) => {
        setTextoContrato(response.data.texto);
      })
      .catch((err) => console.log(err));
  }, []);

  const salvarContrato = () => {
    const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
    const { transportadora_id } = dadosUsuario;
    api
      .put(`/contrato/modelo/${transportadora_id}/`, { texto: textoContrato })
      .then(() => {
        confirmAlert({
          title: "Sucesso",
          message: "Contrato salvo com sucesso!",
          overlayClassName: "alert-remover-nfe",
          buttons: [
            {
              label: "Ok",
              onClick: () => history.goBack(),
              className: "botao-cancelar",
            },
          ],
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="container-novo-contrato">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>
        Voltar
      </button>

      <div className="novo-contrato">
        <p className="titulo-pagina">Editar Contrato</p>
        <p className="subtitulo-pagina">
          Para editar o texto do contrato, preencha abaixo.
        </p>
      </div>

      <div
        className="lista-variaveis"
        onClick={() => setOpenListaVariaveis((oldState) => !oldState)}
      >
        <div className="box-placeholder">
          <p className="placeholder">Lista de Variáveis</p>

          {openListaVariaveis ? (
            <TiArrowSortedUp className="icone"></TiArrowSortedUp>
          ) : (
            <TiArrowSortedDown className="icone"></TiArrowSortedDown>
          )}
        </div>

        {openListaVariaveis && (
          <div className="lista">
            {listaVariaveis.map((item, index) => (
              <div
                key={index}
                className="opcao"
                onClick={() => copyToClipboard(item)}
              >
                <p>{item}</p>
                <MdContentCopy className="icone"></MdContentCopy>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="editor-texto">
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={textoContrato}
          onChange={(e) => setTextoContrato(e)}
          placeholder="Texto"
        />
      </div>

      <div className="container-botoes">
        <button
          className="btn-cancelar"
          onClick={() => history.push("/acesso")}
        >
          Cancelar
        </button>

        <button className="btn-cadastrar" onClick={salvarContrato}>
          Salvar Contrato
        </button>
      </div>
    </div>
  );
};

export default GerenciarContrato;
