import React, { memo, useState, useCallback, useEffect } from 'react'
import { FaRegIdCard, FaMapMarkerAlt, FaMapMarker } from 'react-icons/fa'
import { GoogleMap, useJsApiLoader, DirectionsService, DirectionsRenderer, Marker } from '@react-google-maps/api'
import { parseISO, format } from 'date-fns'
// import { CircularProgressbar } from "react-circular-progressbar"
// import "react-circular-progressbar/dist/styles.css"

import './styles.css'

import truckImage from "../../../../../assets/truck.png"
import stop from "../../../../../assets/circle_stop.png"

import QuadroRegistros from "../QuadroRegistros"

import api from "../../../../../services/api"

const Eventos = ({ idCarga }) => {
  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
  const tipoUsuario = dadosUsuario.user_type
  const idUsuario = (tipoUsuario === "transportadora" || tipoUsuario === "colaborador_transportadora")
    ? dadosUsuario.transportadora_id
    : (tipoUsuario === "embarcador" || tipoUsuario === "colaborador_embarcador")
      ? dadosUsuario.embarcador_id
      : dadosUsuario.id

  const [carga, setCarga] = useState(null)
  const [logsDaCarga, setLogsDaCarga] = useState([])
  const [paradas, setParadas] = useState([])

  const [percurso, setPercurso] = useState(null)
  const [rota, setRota] = useState(null)
  const [ultimaLocalizacao, setUltimaLocalizacao] = useState(null)
  // const [map, setMap] = useState(null)

  let countRender = React.useRef(0)

  const containerStyle = {
    width: '100%',
    height: '100%'
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCJMbU5QYtvKhF_fTMIyYp5wyf_JxtL7iE"
  })

  const onLoadMap = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds()
    map.fitBounds(bounds)
    // setMap(map)
  }, [])

  // const onUnmountMap = useCallback(function callback(map) {
  //   setMap(null)
  // }, [])

  const registrarRota = useCallback(function callback(res) {
    if (res !== null) {
      if (!percurso) {
        setRota(res);
        return;
      }
      api.put('/caches/directions/', {
        percurso: JSON.stringify(percurso),
        response: JSON.stringify(res),
      })
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setRota(res)
      })
    }
  }, [percurso])

  const obtemLogsEventos = useCallback(() => {
    api.get(`cargas_logs/?carga=${idCarga}`)
      .then(function (response) {
        let auxParadas = response.data.results.filter(item => item.tipo === "Parada")
        setLogsDaCarga(response.data.results)
        setParadas(auxParadas)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idCarga])

  const obtemGeolocalizacoes = useCallback(() => {
    api.get(`geolocalizations/?carga=${idCarga}`)
      .then(function (response) {
        let geolocalizacoes = response.data.results

        if (geolocalizacoes.length > 0) {
          setUltimaLocalizacao(geolocalizacoes[geolocalizacoes.length - 1])
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idCarga])

  const websocketLogsEGeolocalizacao = useCallback(() => {
    let wsLogsEGeolocalizacao = new WebSocket(`${process.env.REACT_APP_LINK_API_WEBSOCKET}ws/cargas/notify/${tipoUsuario === "colaborador_transportadora" ? "transportadora" : tipoUsuario === "colaborador_embarcador" ? "embarcador" : tipoUsuario}/${idUsuario}`)

    wsLogsEGeolocalizacao.onopen = function () {
      console.log('WebSocket log_evento_atualizado geolocalization_atualizado conectado')
    }

    wsLogsEGeolocalizacao.onmessage = function (e) {
      let JSONMessage = JSON.parse(e.data)

      if (JSONMessage.tipo === "log_evento_atualizado ") {
        obtemLogsEventos()
      }

      if (JSONMessage.tipo === "geolocalization_atualizado ") {
        obtemGeolocalizacoes()
      }
    }

    wsLogsEGeolocalizacao.onclose = function () {
      wsLogsEGeolocalizacao = null
      setTimeout(function () { websocketLogsEGeolocalizacao() }, 5000)
    }
  }, [tipoUsuario, idUsuario, obtemLogsEventos, obtemGeolocalizacoes])

  useEffect(() => {
    websocketLogsEGeolocalizacao()
  }, [websocketLogsEGeolocalizacao])

  useEffect(() => {
    obtemLogsEventos()
    obtemGeolocalizacoes()

    const getCachedDirections = async (percurso) => {
      try {
        const { data } = await api.post(`/caches/directions/`, {
          percurso: JSON.stringify(percurso),
        });
        setRota(data);
      } catch {
        setPercurso(percurso)
      }
    }

    const getEndereco = (endereco) => {
      if (!endereco.logradouro || !endereco.cep) {
        return `${endereco.municipio}, ${endereco.uf}`;
      }
      return `${endereco.cep}, ${endereco.municipio}, ${endereco.uf}, ${endereco.bairro}`;
    }

    api.get(`cargas_full/${idCarga}/`)
      .then(function (response) {
        let dadosCarga = response.data
        let pontosDeParada = []

        if (dadosCarga.pontos_parada.length > 1) {
          dadosCarga.pontos_parada.forEach((item, index) => {
            if (index !== dadosCarga.pontos_parada.length - 1) {
              pontosDeParada.push({
                location: getEndereco(item.destino.endereco),
                stopover: true
              })
            }
          })
        }

        const percurso = {
          origin: getEndereco(dadosCarga.origem.endereco),
          destination: getEndereco(dadosCarga.pontos_parada[dadosCarga.pontos_parada.length - 1].destino.endereco),
          travelMode: "DRIVING",
          waypoints: pontosDeParada,
          optimizeWaypoints: true
        };
        getCachedDirections(percurso);

        setCarga(dadosCarga)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idCarga, obtemLogsEventos, obtemGeolocalizacoes])

  return (
    <div className="modal-perfil-motorista-carga-eventos">

      <div className="dados-pedido">
        {/* <CircularProgressbar
          className="progresso"
          value={0}
          text={`${0}%`}
        /> */}

        <div className="informacoes">
          <div>
            <FaRegIdCard className="icone"></FaRegIdCard>
            <p><span>ID:</span> {carga?.id}</p>
          </div>

          <p className="pedido"><span>Pedido:</span>
            {
              carga?.pedidos.map((item, index) =>
                index < carga.pedidos.length - 1
                  ? `${item.numero}, `
                  : ` ${item.numero}`)
            }
          </p>
          {
            carga?.motorista_em_viagem?.tipo_cadastro === "NoApp" && <p className="message-app">Cadastro de motorista sem utilização do APP, algumas funcionalidades não disponíveis</p>
          }
        </div>
      </div>

      <hr className="separador" />

      <div className="registro-eventos">
        <div className="container-log">
          <p className="titulo">
            Log de Eventos:
          </p>

          <div className="linha-logs">
            <div className="origem">
              <FaMapMarkerAlt className="icone"></FaMapMarkerAlt>
              <p>{`${carga?.origem.endereco.municipio}-${carga?.origem.endereco.uf}`}</p>
            </div>

            {logsDaCarga.map(item => (
              <div key={item.id} className={`logs-eventos ${item.tipo.replaceAll(" ", "-")}`}>
                <div className="marcador-log"></div>
                <p><span>{item.tipo}:</span> {format(parseISO(item.data_hora), "dd/MM/yyyy 'às' HH:mm")}</p>
              </div>
            ))}

            {carga?.pontos_parada.map(item => (
              <div key={item.id} className="destino">
                <FaMapMarker className="icone"></FaMapMarker>
                <p>{`${item.destino.endereco.municipio}-${item.destino.endereco.uf}`}</p>
              </div>)
            )}
          </div>
        </div>

        <div className="mapa-eventos">
          <p className="titulo">
            Mapa em tempo real:
          </p>

          <div className="container-mapa">
            <div className="mapa">
              {isLoaded &&
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  onLoad={onLoadMap}
                // onUnmount={onUnmountMap}
                >
                  {percurso &&
                    <DirectionsService
                      callback={registrarRota}
                      options={percurso}
                    ></DirectionsService>
                  }

                  {rota &&
                    <DirectionsRenderer
                      directions={rota}
                    ></DirectionsRenderer>
                  }

                  {ultimaLocalizacao &&
                    <Marker
                      position={{ lat: Number(ultimaLocalizacao.latitude), lng: Number(ultimaLocalizacao.longitude) }}
                      icon={{
                        url: truckImage,
                        scaledSize: new window.google.maps.Size(40, 40)
                      }}
                    ></Marker>
                  }

                  {paradas.map(item => (
                    <Marker
                      key={item.id}
                      position={{ lat: Number(item.latitude), lng: Number(item.longitude) }}
                      icon={{
                        url: stop,
                        scaledSize: new window.google.maps.Size(15, 15)
                      }}
                    ></Marker>
                  ))}
                </GoogleMap>
              }
            </div>
          </div>
        </div>
      </div>

      <hr className="separador" />

      <QuadroRegistros idCarga={idCarga}></QuadroRegistros>
    </div>
  )
}

export default memo(Eventos)