import { useState } from 'react'
import { ImSearch } from 'react-icons/im'

import './styles.css'

const InputSearch = ({ placeholder, suggestions, disabled, value, saveId, saveValue }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([])
  const [showSuggestions, setShowSuggestions] = useState(false)

  const onChange = (e) => {
    const userInput = e.target.value

    const unLinked = suggestions.filter(
      (suggestion) =>
        suggestion.razao_social.toLowerCase().indexOf(userInput.toLowerCase()) > -1 || suggestion.cnpj.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    )

    saveValue(e.target.value)
    setFilteredSuggestions(unLinked)
    setShowSuggestions(true)
  }

  const onClick = (e, id) => {
    setFilteredSuggestions([])
    setShowSuggestions(false)
    saveValue(e.target.innerText)
    saveId(id)
  }

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <ul className="suggestions">
        {filteredSuggestions.map((suggestion, index) => {
          let className
          return (
            <li className={className} key={suggestion.id} onClick={(e) => onClick(e, suggestion.id)}>
              {suggestion.razao_social} / {suggestion.cnpj}
            </li>
          )
        })}
      </ul>
    ) : (
      <div className="no-suggestions">
        <em>Nenhum item encontrado!</em>
      </div>
    )
  }

  return (
    <div className="container-input-search">
      <div className="input-search">
        <input
          type="text"
          onChange={onChange}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
        />
        <ImSearch className="icone"></ImSearch>
      </div>
      {showSuggestions && value && <SuggestionsListComponent />}
    </div>
  )
}

export default InputSearch