import { parseISO, format } from "date-fns";
import Modal from "react-modal";
import { GiPathDistance } from "react-icons/gi";
import {
    FaShareSquare,
    FaTrashAlt,
    FaListUl,
    FaIdCard,
} from "react-icons/fa";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { WhatsappShareButton } from "react-share";
import { RiCloseCircleFill } from "react-icons/ri";
import AdicionarCarga from "../../../AdicionarCarga";

const ModalDadosCarga = ({ abrirModalDadosCarga, carga, atualizarCargas, removerCarga,fecharModal }) => {
    const [exibirTodasParadas, setExibirTodasParadas] = useState(false);

    const compartilharNovaCarga = (carga) => {
        const textoMensagem = `
        🚨🚨🚨🚨🚨🚨
    ${carga.transportadora?.razao_social && carga.transportadora?.razao_social}
    🚨🚨🚨🚨🚨🚨
    
    🔽🔽🔽🔽🔽🔽
    
    📍 De: ${carga.origem.endereco?.municipio} - ${carga.origem.endereco?.uf}
    📍 Para: ${carga.pontos_parada
                .map((ponto) => {
                    return `${ponto?.destino.endereco?.municipio} - ${ponto?.destino.endereco?.uf}`;
                })
                .join(", ")}
    🚚 Veículo: ${carga.tipos_veiculo
                .map((veiculo) => {
                    return `${veiculo.tipo}`;
                })
                .join(", ")}
    🚛 Carroceria: ${carga.tipos_carroceria
                .map((carroceria) => {
                    return `${carroceria.tipo}`;
                })
                .join(", ")}
    📦 Tipo de carga: ${carga.produto.produto}
    📅 Data Embarque: ${format(parseISO(carga.data_embarque), "dd/MM/yyyy - HH:mm")}
    
    💰 Proposta Inicial: R$${carga.proposta_inicial}
    📲 Negociação direto por aplicativo, pelo link: https://api.rastrolog.io/share/${carga.id
            }/
    `;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="container-alert-compartilhar-carga">
                        <div className="titulo">
                            <FaShareSquare
                                className="icone compartilhar"
                                onClick={onClose}
                            ></FaShareSquare>
                            <div className="detalhes">
                                <p>Compartilhar Carga</p>
                                <div>
                                    <FaIdCard className="icone id"></FaIdCard>
                                    <p>
                                        ID: <span> {carga.id}</span>
                                    </p>
                                </div>
                            </div>
                            <RiCloseCircleFill
                                className="icone fechar"
                                onClick={onClose}
                            ></RiCloseCircleFill>
                        </div>

                        <div className="conteudo">
                            <p>
                                As informações da Carga "{carga.id}" serão encaminhadas, deseja
                                continuar?
                            </p>

                            <div className="botoes">
                                <button onClick={onClose}>Cancelar</button>
                                <WhatsappShareButton url={textoMensagem}>
                                    <button
                                        className="btn-compartilhar"
                                        onClick={() => {
                                            fecharModal();
                                            onClose();
                                        }}
                                    >
                                        Compartilhar
                                    </button>
                                </WhatsappShareButton>
                            </div>
                        </div>
                    </div>
                );
            },
        });
    };
    
    return (
        <Modal
            isOpen={abrirModalDadosCarga}
            onRequestClose={fecharModal}
            className="modal-visualizar-dados-carga"
            overlayClassName="overlay-modal-visualizar-dados-carga"
            ariaHideApp={false}
        >
            <div className="titulo">
                <p>Dados da Solicitação de Carga</p>
                <RiCloseCircleFill
                    className="icone"
                    onClick={fecharModal}
                ></RiCloseCircleFill>
            </div>

            <div className="conteudo">
                <div className="acoes">
                    {carga.pedidos.map((item, index) => {
                        return (
                            <p key={index}>
                                <span>Pedido: </span>
                                {item.numero}
                            </p>
                        );
                    })}

                    <div>
                        <FaShareSquare
                            className="icone compartilhar"
                            onClick={() => compartilharNovaCarga(carga)}
                        ></FaShareSquare>
                        <AdicionarCarga
                            idCarga={carga.id}
                            atualizarCargas={atualizarCargas}
                            fecharModalDadosCarga={fecharModal}
                        ></AdicionarCarga>
                        <FaTrashAlt
                            className="icone lixo"
                            onClick={() => removerCarga(carga.id)}
                        ></FaTrashAlt>
                    </div>
                </div>

                <div className="detalhes">
                    <div className="linha">
                        <p>
                            <span>ID: </span> {carga.id}
                        </p>
                    </div>

                    <div className="linha">
                        <p>
                            <span>Transportadora: </span>{" "}
                            {carga.transportadora?.razao_social}
                        </p>
                    </div>

                    <div className="linha">
                        <div className="cidades">
                            <p key={carga.origem.id}>
                                <span>De: </span> {carga.origem.endereco?.municipio} -{" "}
                                {carga.origem.endereco?.uf}
                            </p>

                            {carga.pontos_parada.length > 1 && (
                                <div className="abrir-lista">
                                    <FaListUl
                                        className="icone"
                                        onClick={() => setExibirTodasParadas(!exibirTodasParadas)}
                                    ></FaListUl>
                                </div>
                            )}

                            <div className="pontos-parada">
                                {exibirTodasParadas ? (
                                    carga.pontos_parada.map((ponto, index) => {
                                        return index === 0 ? (
                                            <p key={index}>
                                                <span>Para: </span>
                                                {ponto?.destino.endereco?.municipio} -{" "}
                                                {ponto?.destino.endereco?.uf}
                                            </p>
                                        ) : (
                                            <p key={index}>
                                                - {ponto?.destino.endereco?.municipio} -{" "}
                                                {ponto?.destino.endereco?.uf}
                                            </p>
                                        );
                                    })
                                ) : (
                                    <p>
                                        <span>Para: </span>{" "}
                                        {
                                            carga.pontos_parada[carga.pontos_parada.length - 1]
                                                ?.destino.endereco?.municipio
                                        }{" "}
                                        - {carga.pontos_parada[0]?.destino.endereco?.uf}
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="distancia">
                            <GiPathDistance className="icone"></GiPathDistance>
                            <p>
                                {carga?.distancia_total
                                    ? carga?.distancia_total
                                    ? Math.round(
                                        Number(carga.distancia_total / 1000)
                                        ).toLocaleString("pt-BR")
                                    : 0
                                    : 0}{" "}
                                Km
                            </p>
                        </div>
                    </div>

                    <div className="linha">
                        <p>
                            <span>ID's de Coletas: </span>
                            {carga.coletas.map((item, index) => (
                                <i key={item.id}>{item.coleta.id}{index === carga.coletas.length - 1 ? '' : ', '}</i>
                            ))}
                        </p>
                    </div>

                    <div className="linha">
                        <p>
                            <span>NF-e de Coletas: </span>
                            {carga.coletas.map((item, index) => (
                                <i key={item.id}>{item.coleta?.nfe?.numero || '-'}{index === carga.coletas.length - 1 ? '' : ', '}</i>
                            ))}
                        </p>
                    </div>

                    <div className="linha">
                        <p key={carga.produto.id}>
                            <span>Tipo de Carga: </span>{" "}
                            {carga.produto ? carga.produto.produto : "Não específicado"}
                        </p>
                    </div>

                    <div className="linha">
                        {carga.tipos_veiculo.map((tipo, index) => {
                            return index === 0 ? (
                                <p key={index}>
                                    <span className="caminhao">Tipo Caminhão: </span>
                                    {tipo.tipo}
                                </p>
                            ) : (
                                <p key={index}>
                                    <span className="caminhao"></span>
                                    {tipo.tipo}
                                </p>
                            );
                        })}
                    </div>

                    <div className="linha">
                        {carga.tipos_carroceria.map((tipo, index) => {
                            return index === 0 ? (
                                <p key={index}>
                                    <span className="carroceria">Tipo Carroceria: </span>
                                    {tipo.tipo}
                                </p>
                            ) : (
                                <p key={index}>
                                    <span className="carroceria"></span>
                                    {tipo.tipo}
                                </p>
                            );
                        })}
                    </div>

                    <div className="linha col-2">
                        <p>
                            <span>Embarque: </span>{" "}
                            {format(parseISO(carga.data_embarque), "dd/MM/yyyy")}
                        </p>
                        <p>
                            <span>Entrega: </span>{" "}
                            {format(parseISO(carga.data_descarga), "dd/MM/yyyy")}
                        </p>
                    </div>

                    <div className="linha">
                        <p>
                            <span>Valor Inicial: </span>
                            {Number(carga.proposta_inicial).toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                            })}
                            <span className="real">
                                {carga.proposta_tipo === "total" ? "Cheio" : "P/Tonelada"}
                            </span>
                        </p>
                    </div>

                    <div className="linha">
                        <p>
                            <span>Adiantamento: </span> {carga.porcentagem_adiantamento}%
                        </p>
                    </div>

                    <div className="linha">
                        <p>
                            <span>Tipo Proposta: </span>{" "}
                            {carga.is_publica ? "Pública" : "Privada"}
                        </p>
                    </div>

                    <div className="linha">
                        <p>
                            <span>Quantidade de Volumes: </span>
                            {carga.quantidade_volume
                                ? carga.quantidade_volume.toLocaleString("pt-BR")
                                : 0}
                            Un
                        </p>
                    </div>

                    <div className="linha col-2">
                        <p>
                            <span>Peso Unitário: </span>
                            {carga.peso_unitario
                                ? carga.peso_unitario.toLocaleString("pt-BR")
                                : 0}
                            Kg
                        </p>
                        <p>
                            <span>Volume Unitário: </span>
                            {carga.volume_unitario
                                ? carga.volume_unitario.toLocaleString("pt-BR")
                                : 0}
                            m3
                        </p>
                    </div>

                    <div className="linha col-2">
                        <p>
                            <span>Carga Rastreada: </span>
                            {carga.is_rastreada ? "Sim" : "Não"}
                        </p>
                        <p>
                            <span>Carga Fracionada: </span>
                            {carga.is_fracionada ? "Sim" : "Não"}
                        </p>
                    </div>

                    <div className="linha">
                        <p>
                            <span>Conta Frete: </span>
                            {carga.conta_frete.descricao}
                        </p>
                    </div>

                    <div className="linha">
                        <p>
                            <span>Observações: </span> {carga.observacoes}
                        </p>
                    </div>

                    {carga.status_gr === "pendente" && (
                        <div className="linha">
                            <p>
                                <span>Status: </span> Em análise pela GR
                            </p>
                        </div>
                    )}

                    {carga.status_gr === "negada" && (
                        <div className="linha">
                            <p>
                                <span>Status: </span>Reprovado pela GR -
                                <a
                                    href="https://score.guep.com.br/v/#/login"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Retificar
                                </a>
                            </p>
                        </div>
                    )}

                    <div className="fechamento">
                        <p>
                            <span>Fechamento: </span>{" "}
                            {format(parseISO(carga.data_fechamento), "dd/MM/yyyy - HH:mm")}
                        </p>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ModalDadosCarga;