import { useState, useRef, useEffect, useCallback } from 'react'
import { ImSearch } from 'react-icons/im'
import { FaInfoCircle } from 'react-icons/fa'

import '../styles.css'
import { LoadingAnimated } from '../../LoadingAnimated'
import api from '../../../../services/api'

const LIMIT_API = 8

const InputSearchEspecie = ({ suggestions, setSuggestions, form, saveValue, adicionarEspecie, cadastrarNovaEspecieProduto, placeholder, idCarga, inputEspecie }) => {
  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
  const idUsuario = dadosUsuario.id;

  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0)
  const [showSuggestions, setShowSuggestions] = useState(false)

  const list = useRef(null)

  const [lazyLoading, setLazyLoading] = useState({
    offset: 0,
    loading: true,
    scrollTop: 0,
    total: 0,
  });

  const handleLazyLoading = ({ loading, scrollTop }) => {
    setLazyLoading(prevLazyLoading => ({
      ...prevLazyLoading,
      loading: loading,
      offset: prevLazyLoading.offset + LIMIT_API,
      scrollTop: scrollTop,
    }));
  };

  const onChange = (e) => {
    saveValue(e.target.value)
  }

  const onClick = (e, contaFrete) => {
    setSuggestions([])
    setActiveSuggestionIndex(0)
    setShowSuggestions(false)
    saveValue(e.target.innerText)

    adicionarEspecie(contaFrete)
  }

  const onKeyDown = (e) => {

    if (e.keyCode === 9) {
      e.preventDefault();
    }

    if (e.keyCode === 13 || e.keyCode === 9) {
      if (suggestions.length === 0) {
        cadastrarNovaEspecieProduto()
        setSuggestions([])
        setShowSuggestions(false)
      } else {
        onClick(e, suggestions[activeSuggestionIndex])
      }
    } else if (e.keyCode === 38) {
      if (activeSuggestionIndex === 0) {
        return
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1)
    }
    else if (e.keyCode === 40) {
      if (activeSuggestionIndex === suggestions.length - 1) {
        return
      }

      setActiveSuggestionIndex(activeSuggestionIndex + 1)
    }
  }

  const showActiveSuggestion = useCallback((index) => {
    if (index <= suggestions.length - 1) {
      const parentRect = list.current.getBoundingClientRect()
      const child = list.current.querySelectorAll('li')[index]
      const childRect = child.getBoundingClientRect()

      const yStart = (childRect.top - parentRect.top) + list.current.scrollTop

      if (yStart >= list.current.scrollTop
        && yStart < (list.current.scrollTop + parentRect.height)) {
        return
      }

      list.current.scrollTop = yStart
    }
  }, [suggestions])

  useEffect(() => {
    if (list.current) {
      showActiveSuggestion(activeSuggestionIndex)
    }
  }, [activeSuggestionIndex, showActiveSuggestion])

  useEffect(() => {
    if (idCarga) {
      return;
    }

    api
      .get(`especieproduto/?search=${form.values.nome_especie_produto}&criado_por=${idUsuario}&limit=${LIMIT_API}&offset=${lazyLoading.offset}`)
      .then(async function (response) {
        let newEspeciesProdutos = await response.data.results;
        setSuggestions(prevEspeciesProdutos => lazyLoading.offset !== 0 ? [...prevEspeciesProdutos, ...newEspeciesProdutos] : newEspeciesProdutos)

        setLazyLoading(prevLazyLoading => ({
          ...prevLazyLoading,
          loading: false,
          total: response.data.count,
        })
        )
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idCarga, setSuggestions, form.values.nome_especie_produto, lazyLoading.offset, idUsuario])

  return (
    <div className="container-input-search-dados-carga">
      <div className="input-search">
        <input
          required
          type="text"
          ref={inputEspecie}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={form.values.nome_especie_produto}
          onBlur={() => setShowSuggestions(false)}
          onFocus={() => setShowSuggestions(true)}
        />
        <span>
          <FaInfoCircle className="icone-ajuda"></FaInfoCircle>
          {placeholder} <i>*</i>
        </span>
        <ImSearch className="icone"></ImSearch>
      </div>
      {showSuggestions &&
        <SuggestionsListComponent
          activeSuggestionIndex={activeSuggestionIndex}
          suggestions={suggestions}
          list={list}
          lazyLoading={lazyLoading}
          onClick={onClick}
          handleLazyLoading={handleLazyLoading}
          value={form.values.nome_especie_produto}
        />
      }
    </div>
  )
}

const SuggestionsListComponent = ({ suggestions, list, lazyLoading, activeSuggestionIndex, onClick, handleLazyLoading, value }) => {

  useEffect(() => {
    if (list.current) {
      list.current.scrollTop = lazyLoading.scrollTop;
    }
  }, [list, lazyLoading]);

  const handleScroll = event => {
    const {
      target: { scrollTop, scrollHeight, clientHeight }
    } = event;

    if ((scrollTop + clientHeight >= scrollHeight) && (suggestions.length < lazyLoading.total) && !lazyLoading.loading) {

      handleLazyLoading({ loading: true, scrollTop: scrollTop });
    }
  };

  return suggestions.length ? (
    <ul className="suggestions" onScroll={handleScroll} ref={list}>
      {suggestions.map((suggestion, index) => {
        let className = ""

        if (index === activeSuggestionIndex) {
          className = "suggestion-active"
        }

        return (
          <li
            className={className}
            key={suggestion.id}
            onMouseDown={(e) => onClick(e, suggestion)}
          >
            {suggestion.especie}
          </li>
        )
      })}
      {
        lazyLoading.loading && <LoadingAnimated styles={{
          display: "block", margin: "auto", marginTop: '10%'
        }} />
      }
    </ul>
  ) : (
    <div className="no-suggestions">
      <em>Nenhuma conta frete encontrada!</em>
    </div>
  )
}

export default InputSearchEspecie