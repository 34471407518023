import React, { Fragment, memo, useRef, useState, useCallback } from "react";
import { parseISO, format } from "date-fns";
import { saveAs } from "file-saver";
import Loader from "react-loader-spinner";
import { confirmAlert } from "react-confirm-alert";

import xmlIcon from "../../../../../assets/xml.png";
import cteIcon from "../../../../../assets/cte.png";
import refresh from "../../../../../assets/refresh.png";

import ModalNotas from "./ModalNotas";
import ModalPerfilMotoristaCarga from "../../../PainelMonitoramento/ModalPerfilMotoristaCarga";
import ModalAnularCte from '../../../PainelMonitoramento/ModalPerfilMotoristaCarga/Financeiro/TabelaRelatorioCargas/ModalDadosCte/ModalAnularCte';

import api from "../../../../../services/api";

import "./styles.css";

const TabelaCtes = ({ requisitaCtes, ctes, loadingCtes, handleEditarCte }) => {
  const [modalNotas, setModalNotas] = useState(false);
  const [notasTransportadas, setNotasTransportadas] = useState(false);
  const [modalPerfilMotoristaCarga, setModalPerfilMotoristaCarga] =
    useState(false);
  const [idCarga, setIdCarga] = useState(false);
  const [cte, setCte] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleModalPerfilMotoristaCarga = useCallback(
    (idCarga) => {
      if (modalPerfilMotoristaCarga) {
        setModalPerfilMotoristaCarga(false);
        document.querySelector("body").style.overflow = "visible";
      } else {
        setIdCarga(idCarga);
        setModalPerfilMotoristaCarga(true);
        document.querySelector("body").style.overflow = "hidden";
      }
    },
    [modalPerfilMotoristaCarga]
  );

  const handleModalNotas = useCallback(
    (notas) => {
      if (modalNotas) {
        setModalNotas(false);
        setNotasTransportadas(null);
        document.querySelector("body").style.overflow = "visible";
      } else {
        setNotasTransportadas(notas);
        setModalNotas(true);
        document.querySelector("body").style.overflow = "hidden";
      }
    },
    [modalNotas]
  );

  const handleEditarCteAnulacao = (cte) => {
    setCte(cte);
    setModalIsOpen(true);
  }

  const handleAnularCte = (cte) => {
    setCte(cte);
    setModalIsOpen(true);
  }

  const justificativaCancelarCTe = useRef("");

  const cancelarCte = (e, idCte, onClose) => {
    e.preventDefault();

    api
      .post(`documentos_fiscais/cte/${idCte}/cancelar/`, {
        justificativa: justificativaCancelarCTe.current.value,
      })
      .then(function () {
        onClose();
      })
      .catch(function (error) {
        confirmAlert({
          title: "Erro!",
          message: `Erro ao cancelar CTe: ${error.response?.data?.detail}`,
          overlayClassName: "alert-remover-nfe",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      });
  };

  const alertCancelarCte = (idCte, numero) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <form
            className="container-remover-documento"
            onSubmit={(e) => cancelarCte(e, idCte, onClose)}
          >
            <p className="titulo">
              Você deseja realmente <span className="cancelar">cancelar</span> o
              CTe de número {numero} do sistema?
            </p>

            <div className="justificativa">
              <textarea
                required
                type="text"
                placeholder="Preencha a justificativa... (Mínimo 15 caracteres)"
                rows={5}
                minLength="15"
                ref={justificativaCancelarCTe}
              />
            </div>

            <div className="botoes">
              <button className="cancelar" onClick={onClose}>
                Não
              </button>
              <button className="confirmar" type="submit">
                Sim
              </button>
            </div>
          </form>
        );
      },
    });
  };


  const alertAverbacao = (cte) => {
    confirmAlert({
      title: "Reenviar Averbação?",
      message: `Erro de averbação:  ${
        cte?.erros_averbacao
          ? cte?.erros_averbacao?.map((item, index) => {
              return index !== 0
                ? ` | ${item?.Descricao}`
                : ` ${item?.Descricao}`;
            })
          : "Sem erros"
      }`,
      overlayClassName: "alert-remover-nfe",
      buttons: [
        {
          label: "Reenviar",
          onClick: () => {
            api
              .post(`documentos_fiscais/cte/${cte.id}/averbar/`)
              .then(function () {})
              .catch(function (error) {
                console.log(error);
              });
          },
          className: "botao-cancelar",
        },
        {
          label: "Cancelar",
          className: "botao-confirmar",
        },
      ],
    });
  };

  return (
    <div className="container-tabela-ctes">
      {loadingCtes ? (
        <div className="container-loading-documentos">
          <Loader type="TailSpin" color="#001b48" height={50} width={50} />
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Carga</th>
              <th>CT-e</th>
              <th>Sr.</th>
              <th>Data Emissão</th>
              <th>Remetente</th>
              <th>Destinatário</th>
              <th>Consignatário</th>
              <th>Valor</th>
            </tr>
          </thead>

          <tbody>
            {ctes.map((cte, index) => (
              <Fragment key={index}>
                <tr className="linha">
                  <td className="coluna-destaque carga">
                    {cte.carga ? (
                      <span
                        onClick={() =>
                          handleModalPerfilMotoristaCarga(cte.carga)
                        }
                      >
                        ID: {cte.carga}
                      </span>
                    ) : (
                      <>Avulso</>
                    )}
                  </td>
                  <td className="coluna-destaque">{cte.numero}</td>
                  <td>{cte.serie}</td>
                  <td>
                    {cte.emissao
                      ? format(parseISO(cte.emissao), "dd/MM/yyyy")
                      : ""}
                  </td>
                  <td>{cte.remetente_razao_social}</td>
                  <td>{cte.destinatario_razao_social}</td>
                  <td>
                    {cte.modalidade === "CIF"
                      ? cte.remetente_razao_social
                      : cte.destinatario_razao_social}
                  </td>
                  <td>{cte.valor_cte || "0.00"}</td>
                </tr>

                <tr key={index} className="linha notas">
                  <td></td>
                  <td></td>
                  <td className="coluna-notas" colSpan={6}>
                    <span
                      onClick={() => handleModalNotas(cte.notas_transportadas)}
                    >
                      Ver Notas
                    </span>
                    {cte?.status === "Confirmado" &&
                      cte?.origem === "emitido" &&
                      cte?.justificativa_cancelamento === "" &&
                      cte?.tipo !== "Anulação" &&
                      cte?.tipo !== "Substituicao" &&
                      !cte?.cte_anulado &&
                      !cte?.cte_substituto && (
                      <span
                        className="coluna-notas-pad-left"
                        onClick={() => alertCancelarCte(cte?.id, cte?.numero)}
                      >
                        Cancelar
                      </span>
                    )}
                    {cte?.status === "Confirmado" && !cte?.averbado && (
                      <span
                        className="coluna-notas-pad-left"
                        onClick={() => alertAverbacao(cte)}
                      >
                        Reenviar para Averbação
                      </span>
                    )}
                    {!cte.carga &&
                      !["Confirmado", "Enviado", "Anulado"].includes(cte.status) && (
                        <>
                          {cte.status === "Pendente" ? (
                            <span
                              className="coluna-notas-pad-left"
                              onClick={() => handleEditarCte(cte.id)}
                            >
                              Continuar Preenchendo
                            </span>
                          ) : (
                            <span
                              className="coluna-notas-pad-left"
                              onClick={() => {
                                if (cte.tipo === 'Anulação') {
                                  return handleEditarCteAnulacao(cte);
                                }
                                handleEditarCte(cte.id)
                              }}
                            >
                              Retificar
                            </span>
                          )}
                        </>
                      )}
                      {!cte.carga &&
                        cte.status === 'Confirmado' &&
                        cte.origem === 'emitido' &&
                        cte?.tipo !== "Anulação" &&
                        cte?.tipo !== "Substituicao" &&
                        !cte?.cte_anulacao &&
                        !cte?.cte_substituto && (
                        <span
                          className="coluna-notas-pad-left"
                          onClick={() => handleAnularCte(cte)}
                        >
                          Anular
                        </span>
                      )}
                      {!cte?.carga && cte?.status === 'Anulado' && !cte?.cte_substituto && (
                        <span
                          className="coluna-notas-pad-left"
                          onClick={() => handleEditarCte(cte.id, true)}
                        >
                          Substituir
                        </span>
                      )}
                      {!cte?.carga && cte?.status === 'Confirmado' && cte?.tipo !== 'Complementar' && (
                        <span
                          className="coluna-notas-pad-left"
                          onClick={() => handleEditarCte(cte.id, false, true)}
                        >
                          Complementar
                        </span>
                      )}
                      {!cte?.carga && cte?.notas_transportadas?.length > 0 && (
                        <span
                          className="coluna-notas-pad-left"
                          onClick={() => handleEditarCte(cte.id, false, false, true)}
                        >
                          Novo CT-e Usando NF-e
                        </span>
                      )}
                  </td>
                </tr>

                <tr className="linha-rodape">
                  <td className="coluna-acoes">
                    {cte.arquivo && (
                      <img
                        src={xmlIcon}
                        alt="xml"
                        onClick={() =>
                          saveAs(cte.arquivo, `CT-e${cte.numero}.xml`)
                        }
                      />
                    )}
                  </td>
                  <td className="coluna-acoes">
                    {cte.dacte && (
                      <img
                        src={cteIcon}
                        alt="cte"
                        onClick={() =>
                          saveAs(cte.dacte, `CT-e${cte.numero}.pdf`)
                        }
                      />
                    )}
                  </td>
                  <td colSpan={2}>
                    Número de recibo: {cte.recibo}
                    <br />
                    Chave de acesso: {cte.chave}
                  </td>
                  <td colSpan={2}>
                    Tipo:{" "}
                    {cte.tipo}
                    <br/>
                    Data/Hora de Envio:{" "}
                    {cte.emissao
                      ? format(parseISO(cte.emissao), "dd/MM/yyyy HH:mm")
                      : ""}
                    <br />
                    Status: {cte.status}
                    {cte.erros_emissao && (
                      <span>
                        Erro:{" "}
                        {cte?.erros_emissao?.sefaz ||
                          cte?.erros_emissao?.tecnospeed}
                      </span>
                    )}
                  </td>
                  <td colSpan={1.5}>
                    Averbado: {cte.averbado ? "Sim" : "Não"}
                  </td>
                  <td className="coluna-acoes">
                    <img
                      src={refresh}
                      alt="recarregar"
                      onClick={requisitaCtes}
                    />
                  </td>
                </tr>

                {modalPerfilMotoristaCarga && (
                  <ModalPerfilMotoristaCarga
                    modalPerfilMotoristaCarga={modalPerfilMotoristaCarga}
                    handleModalPerfilMotoristaCarga={
                      handleModalPerfilMotoristaCarga
                    }
                    idCarga={idCarga}
                    moverCarga={() => {}}
                  ></ModalPerfilMotoristaCarga>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      )}

      {modalNotas && (
        <ModalNotas
          notasTransportadas={notasTransportadas}
          modalNotas={modalNotas}
          handleModalNotas={handleModalNotas}
        ></ModalNotas>
      )}
      {['Normal', 'Diária', 'Complementar', 'Cortesia', 'Reentrega'].includes(cte?.tipo) && modalIsOpen && (
        <ModalAnularCte
          id={cte?.id}
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          handleModalDadosCte={() => {}}
          requisitarDocumentos={requisitaCtes}
        />
      )}
      {cte?.tipo === 'Anulação' && modalIsOpen && (
        <ModalAnularCte
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          handleModalDadosCte={() => {}}
          requisitarDocumentos={requisitaCtes}
          idAnulacao={cte.id}
        />
      )}
    </div>
  );
};

export default memo(TabelaCtes);
