import { useState, useCallback, useEffect } from 'react';
import { FaRegCalendarCheck } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import { confirmAlert } from 'react-confirm-alert'
import ReactPaginate from "react-paginate";
import { parseISO, format } from 'date-fns'
import BreadCrumbs from "../../../../components/plataformaAcessada/BreadCrumbs";
import api from "../../../../services/api";

const BaixarCteLote = () => {
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [serie, setSerie] = useState('');
  const [numeroInicial, setNumeroInicial] = useState('');
  const [numeroFinal, setNumeroFinal] = useState('');
  const [numeros, setNumeros] = useState('');
  const [xml, setXml] = useState(true);
  const [pdf, setPdf] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [quantidadePaginas, setQuantidadePaginas] = useState(0);
  const [paginaAtual, setPaginaAtual] = useState(0);

  const routes = [
    { path: "/acesso", breadcrumb: "Início" },
    {
      path: "/acesso/painel-monitoramento",
      breadcrumb: "Painel Monitoramento",
    },
    {
      path: "/acesso/painel-monitoramento/documentos",
      breadcrumb: "Documentos",
    },
    {
      path: "/acesso/painel-monitoramento/documentos/ctes",
      breadcrumb: "Baixar CT-e's",
    },
  ];

  const requestReport = useCallback(async (pagina) => {
    const { data } = await api.get(`/documentos_fiscais/ctes/arquivos/?limit=10&offset=${(pagina - 1) * 10}`)
    
    setReports(data.results);
    setQuantidadePaginas(Math.ceil(data.count / 15));
    setPaginaAtual(pagina);
  }, []);

  useEffect(() => {
    requestReport();
  }, [requestReport])

  const handleCreateReport = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const data = {
      "data_inicial": dataInicial || undefined,
      "data_final": dataFinal || undefined,
      "numero_inicial": numeroInicial || undefined,
      "numero_final": numeroFinal || undefined,
      "numeros": numeros || undefined,
      "serie": serie || undefined,
      "xml": xml,
      "pdf": pdf,
    };
    try {
      await api.post('/documentos_fiscais/ctes/arquivos/', data);
      confirmAlert({
        title: "Sucesso!",
        message: 'Seu arquivo foi requisitado, volte nessa página para acompanhar o andamento e baixar o arquivo ZIP.',
        overlayClassName: "alert-remover-motorista",
        buttons: [
          {
            label: "Ok",
            onClick: () => { },
            className: "botao-cancelar"
          }
        ]
      })
      setIsLoading(false);
      requestReport(1);
    } catch (err) {
      setIsLoading(false);
      if (!err?.response?.data?.detail) {
        return;
      }
      const detail = err.response.data.detail;
      confirmAlert({
        title: "Erro!",
        message: detail,
        overlayClassName: "alert-remover-motorista",
        buttons: [
          {
            label: "Ok",
            onClick: () => { },
            className: "botao-cancelar"
          }
        ]
      })
    }
  };

  return (
    <div className="container-documentos-gerais">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <div className="quadro">
        <div className="container-filtros">

          <div className="input-filtro">
            <FaRegCalendarCheck className="icone-filtro" />
            <input
              type="date"
              placeholder="Data de Emissão Inicial"
              value={dataInicial}
              onChange={(e) => setDataInicial(e.target.value)}
            />
            <MdClear
              onClick={() => setDataInicial('')}
              className="icone-limpar"
            />
          </div>
          
          <div className="input-filtro">
            <FaRegCalendarCheck className="icone-filtro" />
            <input
              type="date"
              placeholder="Data de Emissão Final"
              value={dataFinal}
              onChange={(e) => setDataFinal(e.target.value)}
            />
            <MdClear
              onClick={() => setDataFinal('')}
              className="icone-limpar"
            />
          </div>

          <div className="input-filtro">
            <input
              type="number"
              placeholder="Série"
              value={serie}
              onChange={(e) => setSerie(e.target.value)}
            />
            <MdClear
              onClick={() => setSerie('')}
              className="icone-limpar"
            />
          </div>

          <div className="input-filtro">
            <input
              type="number"
              placeholder="Número Inicial"
              value={numeroInicial}
              onChange={(e) => setNumeroInicial(e.target.value)}
            />
            <MdClear
              onClick={() => setNumeroInicial('')}
              className="icone-limpar"
            />
          </div>

          <div className="input-filtro">
            <input
              type="number"
              placeholder="Número Final"
              value={numeroFinal}
              onChange={(e) => setNumeroFinal(e.target.value)}
            />
            <MdClear
              onClick={() => setNumeroFinal('')}
              className="icone-limpar"
            />
          </div>


          <div className="input-filtro">
            <input
              type="text"
              placeholder="Números Separados por ;"
              value={numeros}
              onChange={(e) => setNumeros(e.target.value)}
            />
            <MdClear
              onClick={() => setNumeros('')}
              className="icone-limpar"
            />
          </div>

          <div style={{ width: '100%' }}>
            <div className="container-radio-tipo-documento" style={{ justifyContent: 'flex-start', gap: '30px', width: 'unset' }}>
              <div className="container-input-radio">
                <span className="radio" onClick={() => setXml((prev) => !prev)}>
                  <input
                    type="radio"
                    checked={xml}
                    onChange={(e) => setXml(e.target.checked)}
                  />
                  <span className="checked"></span>
                  Baixar XML's
                </span>
              </div>
              
              <div className="container-input-radio">
                <span className="radio" onClick={() => setPdf((prev) => !prev)}>
                  <input
                    type="radio"
                    checked={pdf}
                    onChange={(e) => setPdf(e.target.checked)}
                  />
                  <span className="checked"></span>
                  Baixar PDF's
                </span>
              </div>
            </div>
          </div>

          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <button
              className="btn-buscar"
              type="button"
              onClick={handleCreateReport}
              disabled={isLoading}
            >
              {isLoading ? 'Carregando...' : 'Criar Zip Com CT-e\'s'}
            </button>
          </div>
        </div>
      </div>

      <div className="container-tabela-ctes">
        <table>
          <thead>
            <tr>
              <th>Data Inícial</th>
              <th>Data Final</th>
              <th>Série</th>
              <th>Número Inicial</th>
              <th>Número Final</th>
              <th>Números</th>
              <th>XML?</th>
              <th>PDF?</th>
              <th>Ações</th>
            </tr>
          </thead>

          <tbody>
            {reports.map((item) => (
              <tr key={item.id} className="linha">
                <td>{item.data_inicial ? format(parseISO(item.data_inicial), 'dd/MM/yyyy') : '-'}</td>
                <td>{item.data_final ? format(parseISO(item.data_final), 'dd/MM/yyyy') : '-'}</td>
                <td>{item.serie || '-'}</td>
                <td>{item.numero_inicial || '-'}</td>
                <td>{item.numero_final || '-'}</td>
                <td>{item.numeros || '-'}</td>
                <td>{item.xml ? 'Sim' : 'Não'}</td>
                <td>{item.pdf ? 'Sim' : 'Não'}</td>
                <td>
                  {item.finished ? (
                    <a href={item.arquivo} target="_blank" rel="noreferrer">
                      Baixar Zip
                    </a>
                  ) : (
                    <>Zip Sendo Gerado...</>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {quantidadePaginas > 1 && (
        <div className="paginacao-documentos-gerais">
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próxima"}
            breakLabel={"..."}
            pageCount={quantidadePaginas}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={paginaAtual}
            onPageChange={(e) => requestReport(e.selected)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      )}
    </div>
  )
}

export default BaixarCteLote;
