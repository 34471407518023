import Modal from "react-modal";

import { IoCloseCircleOutline } from "react-icons/io5";
import './styles.css';
import CardVideos from "./CardVideos";
import { useEffect, useMemo } from "react";
import api from "../../../services/api";
import { useState } from "react";
import { LoadingAnimated } from "../LoadingAnimated";

const ModalTutorial = ({ modalTutorial, handleModalTutorial, setModalVideo }) => {
    const [videosTutoriais, setVideosTutoriais] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const percentage = useMemo(() => {
        const data = videosTutoriais.reduce((prev, item) => ({
            total: prev.total + item.videos.reduce((prev, item) => {
                return prev + 1;
            }, 0),
            assistidos: prev.assistidos + item.videos.reduce((prev, item) => {
                return prev + (!!item.assistido ? 1 : 0);
            }, 0)
        }), {
            total: 0,
            assistidos: 0,
        });
        if (data.total === 0) {
            return '0%';
        }
        return `${Math.floor(data.assistidos / data.total * 100)}%`;
    }, [videosTutoriais]);

    console.log(percentage);

    useEffect(() => {
        const fetchVideosTutoriais = async () => {
            await api.get(`/tutoriais/por-categoria/`)
                .then(function (response) {
                    setVideosTutoriais(response.data);
                    setIsLoading(false)
                })
                .catch(function (error) {
                    console.log(error)
                })
        };

        fetchVideosTutoriais();
    }, []);

    return (
        <>
            <Modal
                isOpen={modalTutorial}
                onRequestClose={() => handleModalTutorial(false)}
                className="modal-tutorial-transacoes"
                overlayClassName="overlay-modal-historico-transacoes"
                ariaHideApp={false}
            >
                <div className="modal-tutorial">
                    <div className="modal-tutorial-header">
                        <h2>Tutoriais</h2>
                        <div class="tag">
                            Vídeos Assistidos: {percentage}
                        </div>
                        <span>
                            <button onClick={() => handleModalTutorial(false)}>
                                <IoCloseCircleOutline />
                            </button>
                        </span>
                    </div>
                    <div className="modal-tutorial-body">
                        {
                            videosTutoriais.length > 0 && !isLoading ?
                                videosTutoriais.map((videoOrder, index) =>
                                    <div key={index} className="modal-tutorial-body-item">
                                        <div className="modal-tutorial-body-item-header">
                                            <h3>{videoOrder.titulo}</h3>
                                        </div>
                                        <div className="modal-tutorial-body-item-container-videos">
                                            {videoOrder.videos.length > 0 &&
                                                <p className="modal-tutorial-body-item-header-conclude">
                                                    Concluído
                                                </p>}
                                            {
                                                videoOrder.videos.length > 0 ?
                                                    videoOrder.videos
                                                        .map((videoObj) =>
                                                            <CardVideos
                                                                index={videoObj.id}
                                                                key={videoObj.id}
                                                                handleModalVideo={(obj) => setModalVideo(obj)}
                                                                video={videoObj}
                                                            />
                                                        ) : <p className="message-sem-videos">Não há vídeos cadastrados nesse categoria</p>
                                            }
                                        </div>
                                    </div>
                                ) : <LoadingAnimated
                                    styles={{
                                        display: "block",
                                        margin: "auto",
                                        marginTop: '10%',
                                    }}
                                />
                        }
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalTutorial;