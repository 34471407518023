import { useEffect, useState } from 'react';
import {MdClose} from 'react-icons/md';
import { confirmAlert } from 'react-confirm-alert'
import CarroceriaItem from './carroceria-item';
import api from "../../../../services/api"
import { removerMascaraCampo } from './utils';
import {useMessages} from '../../../../context/messages-context';
import 'react-confirm-alert/src/react-confirm-alert.css'

const Carrocerias = ({ veiculoId, formTitles, carroceriasForm, desabilitarInputs }) => {
  const { showApiErrors, showError, showSuccess } = useMessages();
  const [tipoCarroceiras, setTipoCarrocerias] = useState('');

  const [savingIndex, setSavingIndex] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data} = await api.get('/tipocarroceria');
        setTipoCarrocerias(data.results || []);
      } catch {};
    }

    fetch();
  }, []);

  const handleAddCarroceria = () => {
    carroceriasForm.setFieldValue('carrocerias', [
      ...carroceriasForm.values.carrocerias,
      {
        id: null,
        rntrc: '',
        placa: '',
        marca: '',
        antt: '',
        tipo_pessoa_dono: '',
        cpf_cnpj_dono: '',
        nome_dono: '',
        tipo: '',
        tipo_nome: '',
      }
    ]);
  }

  const handleRemove = (index) => {
    const isLocal = !carroceriasForm.values.carrocerias[index].id;

    const remove = async () => {
      if (isLocal) {
        carroceriasForm.setFieldValue('carrocerias', carroceriasForm.values.carrocerias.filter((_, idx) => idx !== index));
        return;
      }
      try {
        await api.delete(`/carroceria/${carroceriasForm.values.carrocerias[index].id}/`);
        carroceriasForm.setFieldValue('carrocerias', carroceriasForm.values.carrocerias.filter((_, idx) => idx !== index));
      } catch {};
    }
    
    confirmAlert({
      title: "Remover Carroceria?",
      message: `Deseja realmente remover a carroceria #${index + 1}"? Essa ação não poderá ser desfeita!`,
      overlayClassName: "alert-remover-transportadora",
      buttons: [
        {
          label: "Sim",
          onClick: () => remove(),
          className: "botao-confirmar"
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar"
        }
      ]
    })
  }

  const getCarroceriaFormData = (data) => {
    const formData = new FormData();
    formData.append("veiculo", veiculoId)
    formData.append("rntrc", data.rntrc)
    formData.append("placa", data.placa)
    formData.append("marca", data.marca)
    formData.append("antt", data.antt)
    formData.append("tipo_pessoa_dono", data.tipo_pessoa_dono)
    formData.append("cpf_cnpj_dono", removerMascaraCampo(data.cpf_cnpj_dono))
    formData.append("nome_dono", data.nome_dono)
    formData.append("tipo", data.tipo)
    return formData;
  }

  const salvarCarroceria = async (index) => {
    if (!veiculoId) {
      showError('Veículo', 'Você precisa salvar o veículo antes de salvar as carrocerias!');
      return;
    }

    const { id, tipo } = carroceriasForm.values.carrocerias[index];
    
    if (!tipo) {
      showError('Tipo de Carroceria', 'É obrigatório selecionar o tipo de carroceria!');
      return;
    }
    setSavingIndex(index);

    const form = getCarroceriaFormData(carroceriasForm.values.carrocerias[index])

    try {
      if (id) {
        await api.put(`/carroceria/${id}/`, form);
      } else {
        const response = await api.post(`/carroceria/`, form);
        carroceriasForm.setFieldValue(`carrocerias.${index}.id`, response.data.id);
      }
      showSuccess('Sucesso!', 'Dados da Carroceria Salvos!');
    } catch (err) {
      showApiErrors(err, formTitles);
    }

    setSavingIndex(null);
  }

  return (
    <div className="categoria">
      <p className="titulo-categoria">Carrocerias</p>

      {carroceriasForm.values.carrocerias.map((item, index) => (
        <div className={`carroceria-box ${savingIndex !== null ? 'disabled' : ''}`} key={index}>
          <p className="carroceria-title">Carroceria #{index + 1}</p>
          <MdClose className="close" size={22} onClick={() => handleRemove(index)} />

          <CarroceriaItem
            index={index}
            desabilitarInputs={desabilitarInputs}
            handleChange={carroceriasForm.handleChange}
            setFieldValue={carroceriasForm.setFieldValue}
            item={item}
            tipoCarroceiras={tipoCarroceiras}
          />

          <div className="save-carroceria-line">
            <button
              type="button"
              className='btn'
              onClick={() => salvarCarroceria(index)}
            >
              {savingIndex === index ? 'Salvando...' : 'Salvar'}
            </button>
          </div>
        </div>
      ))}

      <div className="add-carroceria-line">
        <button
          type="button"
          className='btn'
          onClick={handleAddCarroceria}
          disabled={savingIndex !== null}
        >
          Adicionar
        </button>
      </div>
    </div>
  )
};

export default Carrocerias;
