import { BrazillianStates } from './states';

export const getAddressFromPostalCode = async (cep) => {
  const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
  const data = await response.json();

  const {
    uf,
    bairro,
    complemento,
    localidade: municipio,
    ibge: municipio_codigo,
    logradouro
  } = data;
  const ufData = BrazillianStates.find((item) => item.sigla === uf);
  const uf_codigo = ufData.id;
  
  return {
    uf,
    uf_codigo,
    municipio,
    municipio_codigo,
    logradouro,
    bairro,
    numero: '',
    cep,
    complemento,
  };
}
