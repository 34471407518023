import { useFormikContext } from 'formik'
import { Nfe } from './nfe';
import { Cte } from './cte';

export const ListaDocumentos = ({
  documentos,
  setDocumentos,
  onRemoved,
  onClose,
}) => {
  const { values } = useFormikContext();
  const isNfe = values.tipo === 'nfe';
  const isCte = values.tipo === 'cte';

  const handleRemove = (id) => {
    setDocumentos((prev) => prev.filter((doc) => doc.id !== id));
    onRemoved();
  }

  const handleCloseByNfe = () => {
    if (documentos.length === 1) {
      onClose();
    }
  }

  return (
    <div className="documents-list">
      {isNfe && (
        <>
          {documentos.map((item) => (
            <Nfe
              key={item.id}
              documento={item}
              onRemoved={handleRemove}
              onClose={handleCloseByNfe}
            />
          ))}
        </>
      )}
      {isCte && (
        <>
          {documentos.map((item) => (
            <Cte
              key={item.id}
              documento={item}
              onRemoved={handleRemove}
            />
          ))}
        </>
      )}
    </div>
  );
};
