import React, { memo, useState, useCallback } from 'react'
import clsx from 'clsx';
import { parseISO, format } from 'date-fns'
import { FiTruck, FiAlertTriangle } from 'react-icons/fi'
import { GiMoneyStack } from 'react-icons/gi'
import { FaRegCalendarAlt, FaClock, FaUserTie, FaTruck, FaEye } from 'react-icons/fa'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import ModalPerfilMotoristaCarga from '../../../../ModalPerfilMotoristaCarga'
import api from "../../../../../../../services/api"

import './styles.css'

const CardEntregue = ({ moverCarga, carga }) => {
  const [hover, setHover] = useState(false)

  const [modalPerfilMotoristaCarga, setModalPerfilMotoristaCarga] = useState(false)

  const handleModalPerfilMotoristaCarga = useCallback(() => {
    if (modalPerfilMotoristaCarga) {
      setModalPerfilMotoristaCarga(false)
      document.querySelector("body").style.overflow = "visible"
    } else {
      setModalPerfilMotoristaCarga(true)
      document.querySelector("body").style.overflow = "hidden"
    }
  }, [modalPerfilMotoristaCarga])

  const verificarStatusEntregue = () => {
    if (carga?.status_temporal === "no-prazo" && carga?.situacao !== "ocorrencia") {
      return "no-prazo"
    }

    if (carga?.status_temporal === "atraso" && carga?.situacao !== "ocorrencia") {
      return "atrasado"
    }

    if (carga?.situacao === "ocorrencia") {
      return "ocorrencia"
    }
  }

  const realizarPagamento = () => {
    confirmAlert({
      title: "Liberar Pagamento",
      message: `Deseja realmente liberar o pagamento da carga de ID ${carga.id}?`,
      overlayClassName: "alert-realizar-pagamento",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            api.post(`cargas/${carga.id}/liberar_pagamento/`)
              .then(function (res) {
                moverCarga()
              })
              .catch(function (error) {
                console.log(error)
              })
          },
          className: "botao-confirmar"
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar"
        }
      ]
    })
  }

  return (
    <div
      className={clsx({
        'card': true,
        'ocorrencia': carga?.situacao === "ocorrencia",
        'fracionado': carga?.is_fracionada,
      })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="linha">
        <div className="data">
          <p><span>ID: </span>{carga?.id}</p>
        </div>

        <div className="pedido">
          <div className="cor-numero">
            <div className={`cor ${verificarStatusEntregue()}`}></div>
            <p><span>Pedido: </span>{
              carga?.pedidos.length > 0
                ? carga?.pedidos.map((pedido, index) => {
                  if (index === carga?.pedidos.length - 1) {
                    return pedido.numero
                  }
                  return `${pedido.numero}, `
                })
                : "Sem número"
            }</p>
          </div>
        </div>

        {carga?.situacao === "ocorrencia" && <FiAlertTriangle className="icone-ocorrencia"></FiAlertTriangle>}

        <div className="transportadora">
          <FiTruck className="icone"></FiTruck>
          <p>
            {
              carga?.transportadora
                ? carga?.transportadora.razao_social.length > 15
                  ? carga?.transportadora.razao_social.substr(0, 15) + "..."
                  : carga?.transportadora.razao_social
                : ""
            }
          </p>
        </div>
      </div>

      <div className="linha cidades">
        <div className="embarque">
          <FaRegCalendarAlt className="icone"></FaRegCalendarAlt>
          <p><span>Embarque: </span>
            {
              carga?.data_embarque
                ? format(parseISO(carga?.data_embarque), "dd/MM/yyyy")
                : "Sem Data"
            }
          </p>
        </div>

        <div className="cidade">
          <p>
            {
              carga?.origem.endereco?.municipio?.length > 15
                ? carga?.origem.endereco?.municipio.substr(0, 15) + "..."
                : carga?.origem.endereco?.municipio
            }
            -{carga?.origem.endereco?.uf}
            /
            {
              carga?.pontos_parada[carga?.pontos_parada.length - 1]?.destino.endereco?.municipio?.length > 15
                ? carga?.pontos_parada[carga?.pontos_parada.length - 1]?.destino.endereco?.municipio.substr(0, 15) + "..."
                : carga?.pontos_parada[carga?.pontos_parada.length - 1]?.destino.endereco?.municipio
            }
            -{carga?.pontos_parada[carga?.pontos_parada.length - 1]?.destino.endereco?.uf}
          </p>
        </div>
      </div>

      <div className="linha">
        <div className="caminhao">
          <FaTruck className="icone"></FaTruck>
          <p>{carga?.tipos_veiculo[0]?.tipo} - {carga?.tipos_carroceria[0]?.tipo}</p>
        </div>

        <div className="carga status">
          <FaClock className="icone"></FaClock>

          <p>
            Status:
            <span className={carga?.status ? verificarStatusEntregue() : ""}>
              {` ${carga?.status ? verificarStatusEntregue().replace("-", " ") : ""}`}
            </span>
          </p>
        </div>
      </div>

      <div className="linha-dados-motorista">
        <div className="foto-nome">
          <FaUserTie className="icone"></FaUserTie>
          <div>
            <p className="nome">{carga?.motorista_em_viagem?.nome_completo}</p>
            <p className="cpf">{
              carga?.motorista_em_viagem?.documentos.cpf.indexOf("-") === -1
                ? carga?.motorista_em_viagem?.documentos.cpf.replace('.', "").replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")
                : carga?.motorista_em_viagem?.documentos.cpf
            }</p>
          </div>
        </div>

        <div className="veiculo">
          <p>{carga?.motorista_em_viagem?.veiculo.placa_cavalo} /</p>
          {
            carga?.motorista_em_viagem?.veiculo.carrocerias.map(item => <p key={item.id}>&nbsp;{item.placa}</p>)
          }
        </div>
      </div>

      <div className={`acoes-card entregue ${hover ? "hover" : ""}`}>
        <FaEye className="icone" onClick={handleModalPerfilMotoristaCarga}></FaEye>
        <GiMoneyStack className="icone" onClick={realizarPagamento}></GiMoneyStack>
      </div>

      {modalPerfilMotoristaCarga &&
        <ModalPerfilMotoristaCarga
          modalPerfilMotoristaCarga={modalPerfilMotoristaCarga}
          handleModalPerfilMotoristaCarga={handleModalPerfilMotoristaCarga}
          idCarga={carga?.id}
          moverCarga={() => moverCarga(carga.id)}
        />
      }
    </div>
  )
}

export default memo(CardEntregue)