import React, { memo } from 'react'
import { FaRegCalendarAlt, FaTag, FaTruck, FaUserTie } from 'react-icons/fa'
import { FiTruck } from 'react-icons/fi'

import '../styles.css'
import './styles.css'

const CardEntregue = () => {
  return (
    <div className="card-agendamento entregue">
      <div className="linha">
        <div className="id">
          <p><span>ID:</span> 420144</p>
        </div>

        <div className="pedido">
          <p><span>Pedido: </span>987464</p>
        </div>

        <div className="transportadora">
          <FiTruck className="icone"></FiTruck>
          <p>Transportadora </p>
        </div>
      </div>

      <div className="linha">
        <div className="cidade">
          <p>Anápolis-GO / Guarulhos-SP</p>
        </div>
      </div>

      <div className="linha">
        <div className="caminhao">
          <FaTruck className="icone"></FaTruck>
          <p>Carreta Graneleiro - 5 Eixos</p>
        </div>

        <div className="carga">
          <FaTag className="icone"></FaTag>
          <p>Lajes</p>
        </div>
      </div>

      <div className="linha">
        <div className="dados-motorista">
          <FaUserTie className="icone"></FaUserTie>
          <div>
            <p className="nome">João Alberto Costa</p>
            <p>CPF: 062.7894.654-95</p>
            <p>KVJ-6132 / KNB-8765</p>
          </div>
        </div>

        <div className="datas">
          <div className="embarque">
            <FaRegCalendarAlt className="icone"></FaRegCalendarAlt>
            <p><span>Início Viagem: </span>10/08 às 8:30</p>
          </div>

          <div className="embarque">
            <FaRegCalendarAlt className="icone"></FaRegCalendarAlt>
            <p><span>Embarque: </span>10/08 às 11:30</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(CardEntregue)