import React, { memo } from 'react'
import { useCards } from '../CardsContext';
import CardEmbarque from "./CardEmbarque"
import ContainerCards from '../ContainerCards'
import './styles.css'

const QuadroEmbarque = () => {
  const { embarque, setEmbarque, setTransito, fetchEmbarque } = useCards();

  return (
    <div className="quadro embarque">
      <div className="titulo">
        <p>EMBARQUE</p>
      </div>

      <div className="detalhes">
        <div className="total" onClick={() => setEmbarque((prev) => ({...prev, filtroCarga: ''}))}>
          <p>Total: <span>{embarque.painel.todas}</span></p>
        </div>
        <div className="carregamento">
          <div>
            <p className="portaria" onClick={() => setEmbarque((prev) => ({...prev, filtroCarga: 'portaria'}))}>
              Portaria: <span>{embarque.painel.portaria}</span>
            </p>
          </div>
          <div>
            <p className="carregando" onClick={() => setEmbarque((prev) => ({...prev, filtroCarga: 'carregando'}))}>
              Carregando: <span>{embarque.painel.carregando}</span>
            </p>
          </div>
        </div>
        <div className="status">
          <div>
            <p className="atrasado" onClick={() => setEmbarque((prev) => ({...prev, filtroCarga: 'atrasado'}))}>
              Atrasado Para Embarque: <span>{embarque.painel.atrasado}</span>
            </p>
          </div>
          <div>
            <p className="aguardando" onClick={() => setEmbarque((prev) => ({...prev, filtroCarga: 'pendencia'}))}>
              Aguardando Doc: <span>{embarque.painel.pendencia}</span>
            </p>
          </div>
        </div>
      </div>

      <ContainerCards
        component={
          <CardEmbarque
            moverCarga={(id) => {
              const [carga] = embarque.cargas.filter((item) => item.id === id);
              setEmbarque((prev) => ({
                ...prev,
                cargas: prev.cargas.filter((item) => item.id !== id),
                total: prev.total - 1,
              }));
              setTransito((prev) => ({
                ...prev,
                cargas: [carga, ...prev.cargas],
                total: prev.total + 1,
              }))
            }}
          />
        }
        cargas={embarque.cargas}
        total={embarque.total}
        loading={embarque.loading}
        onLoadMore={() => {
          fetchEmbarque(embarque.offset + 10);
        }}
      />
    </div>
  )
}

export default memo(QuadroEmbarque);
