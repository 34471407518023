import React, {
  memo,
  useState,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { FaTrashAlt } from "react-icons/fa";
import { GrAdd } from "react-icons/gr";
import { ImSearch } from "react-icons/im";
import Modal from "react-modal";
import { parseISO, format } from "date-fns";
import InputMask from "react-input-mask";
import { confirmAlert } from "react-confirm-alert";
import NumberFormat from "react-number-format";
import { getDisplayErrors } from "../../utilities";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./styles.css";

import ModalSelecionarCte from "./ModalSelecionarCtes";
import InputSearchCnpj from "../../../../../../../components/plataformaAcessada/InputSearchCnpj";

import api from "../../../../../../../services/api";

import newDoc from "../../../../../../../assets/newDoc.png";
import { useHistory } from "react-router-dom";

const ModalNovoManifesto = ({
  idCarga,
  idMdfeSelecionado,
  modalNovoManifesto,
  handleModalNovoManifesto,
  requisitarDocumentos,
}) => {
  const history = useHistory();

  const [modalSelecionarCtes, setModalSelecionarCtes] = useState(false);
  const [carga, setCarga] = useState(null);

  const [valesPedagio, setValesPedagio] = useState([]);
  const [cnpjsFornecedores, setCnpjsFornecedores] = useState([]);
  const [cnpjsResponsaveis, setCnpjsResponsaveis] = useState([]);
  const [cnpjFornecedor, setCnpjFornecedor] = useState("");
  const [exibirLinhaValePedagio, setExibirLinhaValePedagio] = useState(false);
  const [numeroValePedagio, setNumeroValePedagio] = useState("");
  const [cnpjResponsavel, setCnpjResponsavel] = useState("");
  const [valorValePedagio, setValorValePedagio] = useState("");

  const [mostrarCteManifestados, setMostrarCteManifestados] = useState(false);
  const [ctesSelecionados, setCtesSelecionados] = useState([]);
  const [valoresReais, setValoresReais] = useState([]);
  const [asseguradoraSelecionada, setAseguradoraSelecionada] = useState("");
  const [carreta, setCarreta] = useState("");
  const [bitrem, setBitrem] = useState("");
  const [reboque, setReboque] = useState("");

  const [rotaCarregamento, setRotaCarregamento] = useState("");
  const [motorista, setMotorista] = useState("");
  const [numeroLiberacao, setNumeroLiberacao] = useState("");
  const [agenteCarga, setAgenteCarga] = useState("");
  const [veiculo, setVeiculo] = useState("");
  const [tipoSeguro, setTipoSeguro] = useState("");
  const [custoGerenciadorRisco, setCustoGerenciadorRisco] = useState("");
  const [observacoes, setObservacoes] = useState("");
  const [numeroLacre, setNumeroLacre] = useState("");
  const [doca, setDoca] = useState("");
  const [acaoBotao, setAcaoBotao] = useState(null);

  const [protocolosAverbacao, setProtocolosAverbacao] = useState([]);
  const [protocoloAverbacao, setProtocoloAverbacao] = useState("");

  const [numero, setNumero] = useState("0");

  const [prestacaoRecebedorExpedidor, setPrestacaoRecebedorExpedidor] = useState(false);

  const handleModalSelecionarCtes = useCallback(() => {
    if (modalSelecionarCtes) {
      setModalSelecionarCtes(false);
    } else {
      setModalSelecionarCtes(true);
    }
  }, [modalSelecionarCtes]);

  const removerMascaraCampo = (dado) => {
    return dado
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll(" ", "")
      .replaceAll("-", "")
      .replaceAll(".", "")
      .replaceAll("/", "");
  };

  const adicionarValePedagio = () => {
    if (!exibirLinhaValePedagio) {
      setExibirLinhaValePedagio(true);
      return;
    }

    setExibirLinhaValePedagio(false);

    setValesPedagio((vales) => [
      ...vales,
      {
        fornecedor: removerMascaraCampo(cnpjFornecedor),
        comprovante: numeroValePedagio,
        responsavel: removerMascaraCampo(cnpjResponsavel),
        valor: Number(
          valorValePedagio.replaceAll(".", "").replaceAll(",", ".")
        ),
      },
    ]);

    setCnpjFornecedor("");
    setNumeroValePedagio("");
    setCnpjResponsavel("");
    setValorValePedagio("");
  };

  const editarValesPedagio = (novoDado, index, chave) => {
    let auxValesPedagio = [...valesPedagio];
    let valePedagioEscolhido = auxValesPedagio[index];
    valePedagioEscolhido[chave] = novoDado;

    setValesPedagio(auxValesPedagio);
    return;
  };

  const removerValePedagio = (index) => {
    let auxValesPedagio = [...valesPedagio];
    auxValesPedagio.splice(index, 1);
    setValesPedagio(auxValesPedagio);
  };

  const editarValorReal = (novoDado, index, chave) => {
    let auxVolumesReais = [...valoresReais];
    let volumeEscolhido = auxVolumesReais[index];
    volumeEscolhido[chave] = novoDado;

    setValoresReais(auxVolumesReais);

    return;
  };

  const adicionarProtocoloAverbacao = () => {
    setProtocolosAverbacao((protocolo) => [
      ...protocolo,
      {
        averbacao: protocoloAverbacao,
      },
    ]);

    setProtocoloAverbacao("");
  };

  const editarProtocolosAverbacao = (novoDado, index, chave) => {
    let auxProtocolos = [...protocolosAverbacao];
    let protocoloEscolhido = auxProtocolos[index];
    protocoloEscolhido[chave] = novoDado;

    setProtocolosAverbacao(auxProtocolos);
    return;
  };

  const somaValoresDocumento = (atributo) => {
    let soma = 0;

    if (atributo === "volumeReal" || atributo === "pesoReal") {
      valoresReais.forEach((valor) => {
        soma += Number(valor[atributo]);
      });

      return soma;
    }

    ctesSelecionados.forEach((item) => {
      soma += Number(item[atributo]);
    });

    return soma.toFixed(2);
  };

  const removerCteVinculado = (index) => {
    let auxCtesSelecionados = [...ctesSelecionados];
    auxCtesSelecionados.splice(index, 1);
    setCtesSelecionados(auxCtesSelecionados);
  };

  const verificarTipoVeiculo = () => {
    switch (carga?.motorista_em_viagem.veiculo.tipo_veiculo.tipo) {
      case "Toco":
        return "02";
      case "Truck":
        return "01";
      default:
        return "06";
    }
  };

  const verificarTipoCarroceria = (carroceria) => {
    switch (
      carroceria ||
      carga?.motorista_em_viagem.veiculo.carrocerias[0].tipo.tipo
    ) {
      case "Baú":
        return "02";
      case "Baú Frigorifico":
        return "02";
      case "Caçamba":
        return "01";
      case "Grade Baixa":
        return "01";
      case "Graneleiro":
        return "03";
      case "Cavaqueira":
        return "01";
      case "Prancha":
        return "01";
      case "Bug Porta Container":
        return "04";
      case "Munk":
        return "00";
      case "Silo":
        return "00";
      case "Tanque":
        return "00";
      case "Gaiola":
        return "00";
      case "Cegonheira":
        return "00";
      case "Sider":
        return "05";
      default:
        return "00";
    }
  };

  const formatarReboques = () => {
    let auxReboques = [];

    if (carreta !== "") {
      let cpf_cnpj = {};
      const doc = removerMascaraCampo(carreta.cpf_cnpj_dono);
      if (doc.length > 11) {
        cpf_cnpj = { cnpj: doc };
      } else {
        cpf_cnpj = { cpf: doc };
      }

      auxReboques.push({
        placa: String(carreta.placa || '').toUpperCase().replaceAll("-", ""),
        tara: 0,
        capacidade_kg: 0,
        capacidade_m3: 0,
        tipo_carroceria: verificarTipoCarroceria(reboque.tipo_carroceria),
        proprietario: {
          rntrc: carreta.rntrc.padStart(8, "0"),
          nome: carreta.nome_dono,
          ...cpf_cnpj,
        },
      });
    }

    if (bitrem !== "") {
      let cpf_cnpj = {};
      const doc = removerMascaraCampo(bitrem.cpf_cnpj_dono);
      if (doc.length > 11) {
        cpf_cnpj = { cnpj: doc };
      } else {
        cpf_cnpj = { cpf: doc };
      }
      auxReboques.push({
        placa: String(bitrem.placa || '').toUpperCase().replaceAll("-", ""),
        tara: 0,
        capacidade_kg: 0,
        capacidade_m3: 0,
        tipo_carroceria: verificarTipoCarroceria(reboque.tipo_carroceria),
        proprietario: {
          rntrc: bitrem.rntrc.padStart(8, "0"),
          nome: bitrem.nome_dono,
          ...cpf_cnpj,
        },
      });
    }

    if (reboque !== "") {
      let cpf_cnpj = {};
      const doc = removerMascaraCampo(bitrem.cpf_cnpj_dono);
      if (doc.length > 11) {
        cpf_cnpj = { cnpj: doc };
      } else {
        cpf_cnpj = { cpf: doc };
      }
      auxReboques.push({
        placa: String(reboque.placa || '').toUpperCase().replaceAll('-', ''),
        tara: 0,
        capacidade_kg: 0,
        capacidade_m3: 0,
        tipo_carroceria: verificarTipoCarroceria(reboque.tipo_carroceria),
        proprietario: {
          rntrc: reboque.rntrc.padStart(8, "0"),
          nome: reboque.nome_dono,
          ...cpf_cnpj,
        },
      });
    }

    return auxReboques;
  };

  const gerarCPFouCNPJ = (campo) => {
    if (campo.length > 11) {
      return {
        cnpj: campo,
      };
    }
    return {
      cpf: campo,
    };
  };

  const gerarTipoTransportador = (cpfOuCnpj) => {
    if (cpfOuCnpj.length > 11) {
      return "1";
    }
    return "2";
  };

  let displayCidadeUfOrigem = useMemo(() => {
    const [cte] = ctesSelecionados;
    if (!cte) {
      return "";
    }
    let { nome_municipio, uf } = cte.remetente_endereco;
    if (prestacaoRecebedorExpedidor && cte.expedidor_endereco) {
      nome_municipio = cte.expedidor_endereco.nome_municipio;
      uf = cte.expedidor_endereco.uf;
    }
    return `${nome_municipio} - ${uf}`;
  }, [ctesSelecionados, prestacaoRecebedorExpedidor]);

  let displayCidadeUfDestino = useMemo(() => {
    const [cte] = ctesSelecionados;
    if (!cte) {
      return "";
    }
    let { nome_municipio, uf } = cte.destinatario_endereco;
    if (prestacaoRecebedorExpedidor && cte.recebedor_endereco) {
      nome_municipio = cte.recebedor_endereco.nome_municipio;
      uf = cte.recebedor_endereco.uf;
    }
    return `${nome_municipio} - ${uf}`;
  }, [ctesSelecionados, prestacaoRecebedorExpedidor]);

  const hasExpedidorRecebedor = useMemo(() => {
    const [cte] = ctesSelecionados;
    if (!cte) {
      return false;
    }
    return (cte.recebedor_endereco && cte.expedidor_endereco);
  }, [ctesSelecionados]);

  useEffect(() => {
    setPrestacaoRecebedorExpedidor(hasExpedidorRecebedor);
  }, [hasExpedidorRecebedor]);

  const gerarMunicipiosDescarga = () => {
    const agrupados = {};
    for (const cte of ctesSelecionados) {
      let { codigo_municipio: municipioDescarga } = cte.destinatario_endereco;
      if (prestacaoRecebedorExpedidor && cte.recebedor_endereco) {
        municipioDescarga = cte.recebedor_endereco.codigo_municipio;
      }
      if (Object.prototype.hasOwnProperty.call(agrupados, municipioDescarga)) {
        agrupados[municipioDescarga].push(cte.chave);
      } else {
        agrupados[municipioDescarga] = [cte.chave];
      }
    }

    return Object.keys(agrupados).map((item) => ({
      codigo_municipio: item,
      documentos: {
        ctes: agrupados[item].map((key) => ({
          chave: key,
        })),
      },
    }));
  };

  const registrarMdfe = (e) => {
    e.preventDefault();
    let valesPedagioCadastrados = valesPedagio;

    if (
      cnpjFornecedor !== "" &&
      numeroValePedagio !== "" &&
      cnpjResponsavel !== "" &&
      valorValePedagio !== ""
    ) {
      valesPedagioCadastrados.push({
        fornecedor: removerMascaraCampo(cnpjFornecedor),
        comprovante: numeroValePedagio,
        responsavel: removerMascaraCampo(cnpjResponsavel),
        valor: Number(
          valorValePedagio.replaceAll(".", "").replaceAll(",", ".")
        ),
      });
    }

    const [cte] = ctesSelecionados;
    let {
      uf: ufCarregamento,
      codigo_municipio: municipioCarregamento,
      cep: cepCarregamento,
    } = cte.remetente_endereco;
    let { uf: ufDescarga, cep: cepDescarga } = cte.destinatario_endereco;
    
    if (prestacaoRecebedorExpedidor && cte.recebedor_endereco) {
      ufDescarga = cte.recebedor_endereco.uf;
      cepDescarga = cte.recebedor_endereco.cep;
    }
    if (prestacaoRecebedorExpedidor && cte.expedidor_endereco) {
      ufCarregamento = cte.expedidor_endereco.uf;
      municipioCarregamento = cte.expedidor_endereco.codigo_municipio;
      cepCarregamento = cte.expedidor_endereco.cep;
    }

    let dados = {
      emitir: acaoBotao === "Salvar" ? false : true,
      mdfe: idMdfeSelecionado ? idMdfeSelecionado : null,
      carga: idCarga,
      numero: numero,
      uf_carregamento: ufCarregamento,
      uf_descarregamento: ufDescarga,
      quantidade_cte: ctesSelecionados.length,
      quantidade_nfe: 0,
      valor_carga: somaValoresDocumento("valor_carga"),
      unidade_peso: carga?.proposta_tipo === "total" ? "01" : "02",
      peso_bruto: somaValoresDocumento("peso"),
      inicio_viagem: carga?.data_embarque,
      rota: rotaCarregamento,
      numero_liberacao: numeroLiberacao,
      tipo_transportador: gerarTipoTransportador(
        removerMascaraCampo(carga?.motorista_em_viagem?.veiculo?.cpf_cnpj_dono)
      ),
      custo_gerenciador_risco: isNaN(custoGerenciadorRisco)
        ? Number(
            custoGerenciadorRisco?.replaceAll(".", "")?.replaceAll(",", ".")
          )
        : custoGerenciadorRisco,
      municipio_carregamento: {
        codigo_municipio: municipioCarregamento,
      },
      municipios_descarga: gerarMunicipiosDescarga(),
      seguro: {
        seguradora: {
          responsavel: tipoSeguro,
          nome: asseguradoraSelecionada?.nome,
          apolice: asseguradoraSelecionada?.apolice,
          cnpj_seguradora: asseguradoraSelecionada?.cnpj,
        },
        seguro: protocolosAverbacao,
      },
      rodoviario: {
        placa: String(carga?.motorista_em_viagem?.veiculo?.placa_cavalo ||'').toUpperCase().replaceAll("-", ""),
        tara: "0",
        capacidade_kg: "0",
        capacidade_m3: "0",
        proprietario: {
          rntrc: carga?.motorista_em_viagem?.veiculo?.rntrc.padStart(8, "0"),
          nome: carga?.motorista_em_viagem?.veiculo?.nome_dono,
          ...gerarCPFouCNPJ(
            removerMascaraCampo(
              carga?.motorista_em_viagem?.veiculo?.cpf_cnpj_dono
            )
          ),
        },
        tipo_rodado: verificarTipoVeiculo(),
        tipo_carroceria:
          carreta !== "" ||
          carga?.motorista_em_viagem?.veiculo?.placa_cavalo !==
            carga?.motorista_em_viagem?.veiculo?.carrocerias[0]?.placa
            ? "00"
            : verificarTipoCarroceria(
                carga?.motorista_em_viagem?.veiculo?.carrocerias[0]?.tipo?.tipo
              ),
        contratante: {
          cnpj: carga?.transportadora?.cnpj,
        },
        condutor: {
          nome: carga?.motorista_em_viagem.nome_completo,
          cpf: removerMascaraCampo(carga?.motorista_em_viagem.documentos.cpf),
        },
        vale_pedagio: valesPedagio.map((item) => ({
          ...item,
          fornecedor: removerMascaraCampo(item.fornecedor),
          responsavel: removerMascaraCampo(item.responsavel),
        })),
        reboques: formatarReboques(),
        lacres:
          numeroLacre !== ""
            ? [
                {
                  numero: numeroLacre,
                },
              ]
            : [],
      },
      produto_predominante: {
        tipo_carga: "05",
        descricao: carga?.produto.produto,
      },
      cep_carregado: removerMascaraCampo(cepCarregamento),
      cep_descarregado: removerMascaraCampo(cepDescarga),
      observacoes: observacoes,
    };

    console.log("dados mdfe", dados);

    api
      .post("documentos_fiscais/emitirmdfe/", dados)
      .then(function () {
        confirmAlert({
          title: "Sucesso",
          message: `Documento ${
            acaoBotao === "Salvar" ? "salvo" : "enviado"
          } com sucesso!`,
          overlayClassName: "alert-sucesso-cte",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                handleModalNovoManifesto();
                requisitarDocumentos();
              },
            },
          ],
        });
      })
      .catch(function (error) {
        console.log(error.response);

        const { data } = error.response;
        const errors = getDisplayErrors(data);
        let erroCoins = false;
        errors.forEach((item) => {
          if (item?.title === "Rastro Coins Insuficientes.") {
            erroCoins = true;
          }
        });

        if (erroCoins) {
          erroCoins = false;

          confirmAlert({
            overlayClassName: "alert-sucesso-cte",
            customUI: ({ onClose }) => (
              <div className="react-confirm-alert-body errors-modal-body">
                <h1>Rastro Coins Insuficientes</h1>
                <div className="errors-modal-part">
                  <p>
                    Suas Rastro Coins acabaram. Realize a compra de mais coins!
                  </p>
                </div>
                <div className="react-confirm-alert-button-group">
                  <button
                    className="botao-cancelar"
                    onClick={() => {
                      onClose();
                      history.push("/acesso/rastro-coins");
                    }}
                  >
                    Comprar Rastro Coins
                  </button>
                </div>
              </div>
            ),
          });
        } else {
          confirmAlert({
            overlayClassName: "alert-sucesso-cte",
            customUI: ({ onClose }) => (
              <div className="react-confirm-alert-body errors-modal-body">
                <h1>Houveram Alguns Erros</h1>
                <div className="errors-modal-part">
                  {errors.map((item) => (
                    <div key={JSON.stringify(item)}>
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                  ))}
                </div>
                <div className="react-confirm-alert-button-group">
                  <button className="botao-cancelar" onClick={onClose}>
                    Ok
                  </button>
                </div>
              </div>
            ),
          });
        }
      });
  };

  useEffect(() => {
    let auxProtocolosCtes = [];

    ctesSelecionados.forEach((cte) => {
      setValoresReais((volumesReais) => [
        ...volumesReais,
        { idCte: cte.id, volumeReal: "", pesoReal: "" },
      ]);

      if (cte.numero_averbacao) {
        auxProtocolosCtes.push({ averbacao: cte.numero_averbacao });
      }
    });

    setProtocolosAverbacao(auxProtocolosCtes);
  }, [ctesSelecionados]);

  useEffect(() => {
    api
      .get(`documentos_fiscais/valepedagio/`)
      .then(function (response) {
        let auxFornecedores = response.data.results.map(
          (cnpjs) => cnpjs.cnpj_fornecedor
        );
        let auxResponsaveis = response.data.results.map(
          (cnpjs) => cnpjs.cnpj_responsavel
        );

        setCnpjsFornecedores(auxFornecedores);
        setCnpjsResponsaveis(auxResponsaveis);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idCarga]);

  useEffect(() => {
    api
      .get(`cargas_full/${idCarga}/`)
      .then(function (response) {
        let auxCarga = response.data;
        setCarga(response.data);

        setMotorista(auxCarga.motorista_em_viagem.nome_completo);
        setAgenteCarga(auxCarga.motorista_em_viagem.nome_completo);
        setVeiculo(auxCarga.motorista_em_viagem.veiculo.placa_cavalo);

        setNumero((prev) => {
          if (prev === "0") {
            return null;
          }
          return prev;
        });

        if (
          auxCarga?.motorista_em_viagem?.veiculo?.carrocerias.length > 0 &&
          auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[0].placa !==
            auxCarga?.motorista_em_viagem?.veiculo?.placa_cavalo
        ) {
          setCarreta({
            placa: String(auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[0].placa || '').toUpperCase().replaceAll("-", ""),
            tipo_carroceria:
              auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[0].tipo.tipo,
            rntrc: auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[0].rntrc,
            cpf_cnpj_dono:
              auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[0]
                .cpf_cnpj_dono,
            nome_dono:
              auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[0].nome_dono,
          });
        }

        if (
          auxCarga?.motorista_em_viagem?.veiculo?.carrocerias.length > 1 &&
          auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[1].placa !==
            auxCarga?.motorista_em_viagem?.veiculo?.placa_cavalo
        ) {
          setBitrem({
            placa: String(auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[1].placa || "").toUpperCase().replaceAll("-", ""),
            tipo_carroceria:
              auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[1].tipo.tipo,
            rntrc: auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[1].rntrc,
            cpf_cnpj_dono:
              auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[1]
                .cpf_cnpj_dono,
            nome_dono:
              auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[1].nome_dono,
          });
        }

        if (
          auxCarga?.motorista_em_viagem?.veiculo?.carrocerias.length > 2 &&
          auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[2].placa !==
            auxCarga?.motorista_em_viagem?.veiculo?.placa_cavalo
        ) {
          setReboque({
            placa: String(auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[2].placa || '').toUpperCase().replaceAll("-", ""),
            tipo_carroceria:
              auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[2].tipo.tipo,
            rntrc: auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[2].rntrc,
            cpf_cnpj_dono:
              auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[2]
                .cpf_cnpj_dono,
            nome_dono:
              auxCarga?.motorista_em_viagem?.veiculo?.carrocerias[2].nome_dono,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idCarga]);

  useEffect(() => {
    if (idMdfeSelecionado) {
      api
        .get(`documentos_fiscais/mdfe/${idMdfeSelecionado}/dados_emissao/`)
        .then(function (response) {
          let dadosMdfe = response.data;

          setValesPedagio(dadosMdfe.rodoviario?.vale_pedagio);
          setCtesSelecionados(dadosMdfe.documentos?.ctes);
          setTipoSeguro(dadosMdfe.seguro?.seguradora?.responsavel);
          setProtocolosAverbacao(dadosMdfe.seguro?.seguro);
          setObservacoes(dadosMdfe.observacoes);
          setNumeroLacre(dadosMdfe.rodoviario?.lacres[0]?.numero || "");
          setRotaCarregamento(dadosMdfe.rota);
          setNumeroLiberacao(dadosMdfe.numero_liberacao);
          setCustoGerenciadorRisco(dadosMdfe.custo_gerenciador_risco);
          setAseguradoraSelecionada({
            nome: dadosMdfe.seguro?.seguradora?.nome,
            apolice: dadosMdfe.seguro?.seguradora?.apolice,
            cnpj: dadosMdfe.seguro?.seguradora?.cnpj_seguradora,
          });

          if (dadosMdfe.status === "Negado") {
            setNumero(dadosMdfe.numero);
          }

          console.log(dadosMdfe);

          if (dadosMdfe.rodoviario?.reboques.length > 0) {
            let cpf_cnpj = dadosMdfe.reboques[0]?.rodoviario?.proprietario?.cpf;
            if (!cpf_cnpj) {
              cpf_cnpj = dadosMdfe.reboques[0]?.rodoviario?.proprietario?.cnpj;
            }
            setCarreta({
              placa: String(dadosMdfe.reboques[0]?.rodoviario?.placa || '').toUpperCase().replaceAll('-', ''),
              tipo_carroceria:
                dadosMdfe.reboques[0]?.rodoviario?.tipo_carroceria,
              rntrc: dadosMdfe.reboques[0]?.rodoviario?.proprietario?.rntrc,
              cpf_cnpj_dono: cpf_cnpj,
              nome_dono: dadosMdfe.reboques[0]?.rodoviario?.proprietario?.nome,
            });

            if (dadosMdfe.rodoviario?.reboques.length > 1) {
              cpf_cnpj = dadosMdfe.reboques[1]?.rodoviario?.proprietario?.cpf;
              if (!cpf_cnpj) {
                cpf_cnpj =
                  dadosMdfe.reboques[1]?.rodoviario?.proprietario?.cnpj;
              }
              setBitrem({
                placa: String(dadosMdfe.reboques[1]?.rodoviario?.placa || '').toUpperCase().replaceAll('-', ''),
                tipo_carroceria:
                  dadosMdfe.reboques[1]?.rodoviario?.tipo_carroceria,
                rntrc: dadosMdfe.reboques[1]?.rodoviario?.proprietario?.rntrc,
                cpf_cnpj_dono: cpf_cnpj,
                nome_dono:
                  dadosMdfe.reboques[1]?.rodoviario?.proprietario?.nome,
              });
            }

            if (dadosMdfe.rodoviario?.reboques.length > 2) {
              cpf_cnpj = dadosMdfe.reboques[2]?.rodoviario?.proprietario?.cpf;
              if (!cpf_cnpj) {
                cpf_cnpj =
                  dadosMdfe.reboques[2]?.rodoviario?.proprietario?.cnpj;
              }
              setBitrem({
                placa: String(dadosMdfe.reboques[2]?.rodoviario?.placa || '').toUpperCase().replaceAll('-', ''),
                tipo_carroceria:
                  dadosMdfe.reboques[2]?.rodoviario?.tipo_carroceria,
                rntrc: dadosMdfe.reboques[2]?.rodoviario?.proprietario?.rntrc,
                cpf_cnpj_dono: cpf_cnpj,
                nome_dono:
                  dadosMdfe.reboques[2]?.rodoviario?.proprietario?.nome,
              });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [idMdfeSelecionado]);

  return (
    <>
      <Modal
        isOpen={modalNovoManifesto}
        onRequestClose={handleModalNovoManifesto}
        className="modal-novo-manifesto"
        overlayClassName="overlay-modal-novo-manifesto"
        ariaHideApp={false}
      >
        <form className="novo-manifesto" onSubmit={registrarMdfe}>
          <RiCloseCircleFill
            className="icone-fechar"
            onClick={handleModalNovoManifesto}
          ></RiCloseCircleFill>

          <p className="titulo">Manifesto</p>

          <div className="container-data-hora">
            <div className="container-input-comum data-hora">
              <input
                required
                type="text"
                value={numero === null ? 'Automático' : numero}
                onChange={(e) => setNumero(e.target.value)}
                disabled
              />
              <span>Número MDF-e</span>
            </div>

            <div className="container-input-comum data-hora">
              <input
                required
                type="text"
                disabled
                defaultValue={format(new Date(), "dd/MM/yyyy")}
              />
              <span>Data Saída</span>
            </div>

            <div className="container-input-comum data-hora">
              <input
                required
                type="text"
                disabled
                defaultValue={format(new Date(), "HH:mm")}
              />
              <span>Horário</span>
            </div>
          </div>

          <img
            className="icone-novo-vale"
            src={newDoc}
            alt="documento"
            onClick={adicionarValePedagio}
          />

          {(!idMdfeSelecionado || exibirLinhaValePedagio) && (
            <div className="container-formulario">
              <p className="titulo-formulario">Vale Pedágio</p>
              <div className="container-inputs-4col">
                <InputSearchCnpj
                  tipoCnpj="fornecedor"
                  value={cnpjFornecedor}
                  saveValue={(e) => setCnpjFornecedor(e)}
                  suggestions={cnpjsFornecedores}
                  placeholder="CNPJ Fornecedor"
                ></InputSearchCnpj>

                <div className="container-input-comum">
                  <input
                    type="text"
                    value={numeroValePedagio}
                    onChange={(e) => setNumeroValePedagio(e.target.value)}
                  />
                  <span>Num. Comp. de compra</span>
                </div>

                <InputSearchCnpj
                  tipoCnpj="responsavel"
                  value={cnpjResponsavel}
                  saveValue={(e) => setCnpjResponsavel(e)}
                  suggestions={cnpjsResponsaveis}
                  placeholder="CNPJ Responsável"
                ></InputSearchCnpj>

                <div className="container-input-comum">
                  <NumberFormat
                    value={valorValePedagio}
                    onChange={(e) => setValorValePedagio(e.target.value)}
                    displayType={"number"}
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                  <span>Valor R$</span>
                </div>
              </div>
            </div>
          )}

          {valesPedagio.map((_, index) => (
            <div key={index} className="container-formulario">
              <div className="container-titulo">
                <p className="titulo-formulario">Vale Pedágio</p>
                <FaTrashAlt
                  className="remover-vale"
                  onClick={() => removerValePedagio(index)}
                ></FaTrashAlt>
              </div>

              <div className="container-inputs-4col">
                <div className="container-input-comum">
                  <InputMask
                    mask="99.999.999/9999-99"
                    value={valesPedagio[index].fornecedor}
                    onChange={(e) =>
                      editarValesPedagio(e.target.value, index, "fornecedor")
                    }
                  ></InputMask>
                  <span>CNPJ Fornecedor</span>
                </div>

                <div className="container-input-comum">
                  <input
                    type="text"
                    value={valesPedagio[index].comprovante}
                    onChange={(e) =>
                      editarValesPedagio(e.target.value, index, "comprovante")
                    }
                  />
                  <span>Num. Comp. de compra</span>
                </div>

                <div className="container-input-comum">
                  <InputMask
                    mask="99.999.999/9999-99"
                    value={valesPedagio[index].responsavel}
                    onChange={(e) =>
                      editarValesPedagio(e.target.value, index, "responsavel")
                    }
                  ></InputMask>
                  <span>CNPJ Resp. Pagamento</span>
                </div>

                <div className="container-input-comum">
                  <NumberFormat
                    value={valesPedagio[index].valor}
                    onChange={(e) =>
                      editarValesPedagio(e.target.value, index, "valor")
                    }
                    displayType={"number"}
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                  <span>Valor R$</span>
                </div>
              </div>
            </div>
          ))}

          <div className="container-formulario">
            <p className="titulo-formulario">
              Relação do(s) CT-e(s) Deste Manifesto
            </p>

            <button
              className="btn-selecionar-cte"
              onClick={handleModalSelecionarCtes}
            >
              Selecionar CT-e(s)
              <ImSearch className="icone"></ImSearch>
            </button>

            <div className="container-input-checkbox">
              <span
                className="checkbox"
                onClick={() =>
                  setMostrarCteManifestados((prevState) => !prevState)
                }
              >
                <input
                  type="checkbox"
                  checked={mostrarCteManifestados}
                  onChange={() =>
                    setMostrarCteManifestados((prevState) => !prevState)
                  }
                />
                <span className="checked"></span>
                Mostrar CT-e(s) já manifestados
              </span>
            </div>

            <div className="container-tabela-ctes-manifesto">
              <table>
                <thead>
                  <tr>
                    <th>Emissão</th>
                    <th>CT-e</th>
                    <th>Remetente</th>
                    <th>Origem</th>
                    <th>Destinatário</th>
                    <th>Destino</th>
                    <th>Vol"</th>
                    <th>Vol" Real</th>
                    <th>Peso</th>
                    <th>Peso Real</th>
                    <th>R$</th>
                    <th>R$ Frete</th>
                    <th>Ações</th>
                  </tr>
                </thead>

                <tbody>
                  {ctesSelecionados.map((cte, index) => (
                    <Fragment key={index}>
                      <tr>
                        <td>
                          {cte.emissao
                            ? format(parseISO(cte.emissao), "dd/MM/yyyy")
                            : ""}
                        </td>
                        <td className="coluna-destaque">{cte.numero}</td>
                        <td>{cte.remetente_razao_social}</td>
                        <td>
                          {cte?.remetente_endereco?.nome_municipio}-
                          {cte?.remetente_endereco?.uf}
                        </td>
                        <td>{cte.destinatario_razao_social}</td>
                        <td>
                          {cte?.destinatario_endereco?.nome_municipio}-
                          {cte?.destinatario_endereco?.uf}
                        </td>
                        <td>{cte.volumes}</td>
                        <td>
                          <input
                            type="text"
                            value={valoresReais[index]?.volumeReal || ""}
                            onChange={(e) =>
                              editarValorReal(
                                e.target.value,
                                index,
                                "volumeReal"
                              )
                            }
                          />
                        </td>
                        <td>{cte.peso}</td>
                        <td>
                          <input
                            type="text"
                            value={valoresReais[index]?.pesoReal || ""}
                            onChange={(e) =>
                              editarValorReal(e.target.value, index, "pesoReal")
                            }
                          />
                        </td>
                        <td>{cte.valor_carga}</td>
                        <td>{cte.valor_cte}</td>
                        <td className="coluna-acoes-cte">
                          <FaTrashAlt
                            className="icone"
                            onClick={() => removerCteVinculado(index)}
                          ></FaTrashAlt>
                        </td>
                      </tr>
                    </Fragment>
                  ))}

                  <tr className="linha-totais">
                    <td colSpan={5} className="coluna-total">
                      Totais:
                    </td>
                    <td>{ctesSelecionados.length} Ct-e(s)</td>
                    <td>{somaValoresDocumento("volumes")}</td>
                    <td>{somaValoresDocumento("volumeReal")}</td>
                    <td>{somaValoresDocumento("peso")}</td>
                    <td>{somaValoresDocumento("pesoReal")}</td>
                    <td>{somaValoresDocumento("valor_carga")}</td>
                    <td>{somaValoresDocumento("valor_cte")}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="container-formulario">
            <p className="titulo-formulario">Local de carregamento</p>
            {hasExpedidorRecebedor && (
              <div className="container-input-4col" style={{marginTop: 20}}>
                <span className="checkbox">
                  <input
                    id="prestacao-recebedor-expedidor"
                    type="checkbox"
                    value={true}
                    checked={prestacaoRecebedorExpedidor}
                    onChange={(e) =>
                      setPrestacaoRecebedorExpedidor(e.target.checked)
                    }
                  />
                  <label htmlFor="prestacao-recebedor-expedidor" style={{ paddingLeft: 5 }}>
                    Municipio/UF de Início e Fim da Prestação é o Expedidor/Recebedor?
                  </label>
                </span>
              </div>
            )}

            <div className="container-inputs-4col">
              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  value={displayCidadeUfOrigem}
                  disabled
                  onChange={() => {}}
                />
                <span>Cidade-UF-Origem</span>
              </div>

              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  disabled
                  value={displayCidadeUfDestino}
                  onChange={() => {}}
                />
                <span>Cidade-UF-Destino</span>
              </div>

              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  value={rotaCarregamento}
                  onChange={(e) => setRotaCarregamento(e.target.value)}
                />
                <span>Rota</span>
              </div>
            </div>

            <p className="titulo-formulario categoria">Dados Motorista</p>

            <div className="container-inputs-4col">
              <div className="container-input-comum">
                <input required type="text" value={motorista} disabled />
                <span>Motorista</span>
              </div>

              <div className="container-input-comum">
                <input
                  type="text"
                  value={numeroLiberacao}
                  onChange={(e) => setNumeroLiberacao(e.target.value)}
                />
                <span>Num. Liberação</span>
              </div>

              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  value={agenteCarga}
                  onChange={(e) => agenteCarga(e.target.value)}
                />
                <span>Agente Carga</span>
              </div>

              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  value={veiculo}
                  onChange={(e) => setVeiculo(e.target.value)}
                  disabled
                />
                <span>Veículo</span>
              </div>

              <div className="container-input-comum">
                <select
                  onChange={(e) =>
                    setCarreta({
                      placa:
                        e.target.selectedOptions[0].getAttribute("data-placa"),
                      tipo_carroceria: e.target.selectedOptions[0].getAttribute(
                        "data-tipo_carroceria"
                      ),
                      rntrc:
                        e.target.selectedOptions[0].getAttribute("data-rntrc"),
                      cpf_cnpj_dono:
                        e.target.selectedOptions[0].getAttribute(
                          "data-cpf_cnpj_dono"
                        ),
                      nome_dono:
                        e.target.selectedOptions[0].getAttribute(
                          "data-nome_dono"
                        ),
                    })
                  }
                >
                  {carga?.motorista_em_viagem?.veiculo?.carrocerias.length >
                  0 ? (
                    carga?.motorista_em_viagem?.veiculo?.carrocerias[0]
                      .placa !==
                    carga?.motorista_em_viagem?.veiculo?.placa_cavalo ? (
                      <option
                        key={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[0].id
                        }
                        value={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[0]
                        }
                        data-placa={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[0]
                            .placa
                        }
                        data-tipo_carroceria={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[0]
                            .tipo.tipo
                        }
                        data-rntrc={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[0]
                            .rntrc
                        }
                        data-cpf_cnpj_dono={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[0]
                            .cpf_cnpj_dono
                        }
                        data-nome_dono={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[0]
                            .nome_dono
                        }
                      >
                        {
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[0]
                            .placa
                        }
                      </option>
                    ) : (
                      <option value="">Carreta</option>
                    )
                  ) : (
                    <option value="">Carreta</option>
                  )}
                </select>
                <span>Carroceria</span>
              </div>

              <div className="container-input-comum">
                <select
                  onChange={(e) =>
                    setBitrem({
                      placa:
                        e.target.selectedOptions[0].getAttribute("data-placa"),
                      tipo_carroceria: e.target.selectedOptions[0].getAttribute(
                        "data-tipo_carroceria"
                      ),
                      rntrc:
                        e.target.selectedOptions[0].getAttribute("data-rntrc"),
                      cpf_cnpj_dono:
                        e.target.selectedOptions[0].getAttribute(
                          "data-cpf_cnpj_dono"
                        ),
                      nome_dono:
                        e.target.selectedOptions[0].getAttribute(
                          "data-nome_dono"
                        ),
                    })
                  }
                >
                  {carga?.motorista_em_viagem?.veiculo?.carrocerias.length >
                  1 ? (
                    carga?.motorista_em_viagem?.veiculo?.carrocerias[1]
                      .placa !==
                    carga?.motorista_em_viagem?.veiculo?.placa_cavalo ? (
                      <option
                        key={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[1].id
                        }
                        value={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[1]
                        }
                        data-placa={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[1]
                            .placa
                        }
                        data-tipo_carroceria={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[1]
                            .tipo.tipo
                        }
                        data-rntrc={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[1]
                            .rntrc
                        }
                        data-cpf_cnpj_dono={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[1]
                            .cpf_cnpj_dono
                        }
                        data-nome_dono={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[1]
                            .nome_dono
                        }
                      >
                        {
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[1]
                            .placa
                        }
                      </option>
                    ) : (
                      <option value="">Bi-trem</option>
                    )
                  ) : (
                    <option value="">Bi-trem</option>
                  )}
                </select>
                <span>Bi-trem</span>
              </div>

              <div className="container-input-comum">
                <select
                  onChange={(e) =>
                    setReboque({
                      placa:
                        e.target.selectedOptions[0].getAttribute("data-placa"),
                      tipo_carroceria: e.target.selectedOptions[0].getAttribute(
                        "data-tipo_carroceria"
                      ),
                      rntrc:
                        e.target.selectedOptions[0].getAttribute("data-rntrc"),
                      cpf_cnpj_dono:
                        e.target.selectedOptions[0].getAttribute(
                          "data-cpf_cnpj_dono"
                        ),
                      nome_dono:
                        e.target.selectedOptions[0].getAttribute(
                          "data-nome_dono"
                        ),
                    })
                  }
                >
                  {carga?.motorista_em_viagem?.veiculo?.carrocerias.length >
                  2 ? (
                    carga?.motorista_em_viagem?.veiculo?.carrocerias[2]
                      .placa !==
                    carga?.motorista_em_viagem?.veiculo?.placa_cavalo ? (
                      <option
                        key={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[2].id
                        }
                        value={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[2]
                        }
                        data-placa={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[2]
                            .placa
                        }
                        data-tipo_carroceria={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[2]
                            .tipo.tipo
                        }
                        data-rntrc={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[2]
                            .rntrc
                        }
                        data-cpf_cnpj_dono={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[2]
                            .cpf_cnpj_dono
                        }
                        data-nome_dono={
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[2]
                            .nome_dono
                        }
                      >
                        {
                          carga?.motorista_em_viagem?.veiculo?.carrocerias[2]
                            .placa
                        }
                      </option>
                    ) : (
                      <option value="">Reboque</option>
                    )
                  ) : (
                    <option value="">Reboque</option>
                  )}
                </select>
                <span>Reboque</span>
              </div>
            </div>

            <div className="container-inputs-4col">
              <div className="container-input-comum">
                <select
                  value={tipoSeguro}
                  onChange={(e) => setTipoSeguro(e.target.value)}
                  required
                >
                  <option value="">Tipo Seguro do CT-e</option>
                  <option value="1">Seguro Emitente</option>
                  <option value="2">Seguro Contratante</option>
                </select>
              </div>

              <div className="container-input-comum">
                <select
                  required
                  value={asseguradoraSelecionada}
                  onChange={(e) =>
                    setAseguradoraSelecionada({
                      nome: e.target.selectedOptions[0].getAttribute(
                        "data-nome"
                      ),
                      apolice:
                        e.target.selectedOptions[0].getAttribute(
                          "data-apolice"
                        ),
                      cnpj: e.target.selectedOptions[0].getAttribute(
                        "data-cnpj"
                      ),
                    })
                  }
                >
                  <option value="">Asseguradora</option>
                  {carga?.transportadora.asseguradoras.map((item) => (
                    <option
                      key={item.id}
                      value={item}
                      data-nome={item.nome}
                      data-apolice={item.apolice}
                      data-cnpj={item.cnpj}
                    >
                      {item.nome}
                    </option>
                  ))}
                </select>
              </div>

              <div className="container-input-comum">
                <input
                  type="text"
                  value={asseguradoraSelecionada?.apolice || ""}
                  disabled
                />
                <span>Apolice</span>
              </div>

              <div className="container-input-comum">
                <input
                  type="text"
                  value={asseguradoraSelecionada?.cnpj || ""}
                  disabled
                />
                <span>CNPJ da Asseguradora</span>
              </div>

              <div className="container-input-comum">
                <NumberFormat
                  required
                  value={custoGerenciadorRisco}
                  onChange={(e) => setCustoGerenciadorRisco(e.target.value)}
                  displayType={"number"}
                  thousandSeparator="."
                  decimalSeparator=","
                />
                <span>Custo Gerenc. Risco R$</span>
              </div>

              <div className="input-protocolo-averbacao">
                <div className="container-input-comum">
                  <input
                    type="text"
                    value={protocoloAverbacao}
                    onChange={(e) => setProtocoloAverbacao(e.target.value)}
                  />
                  <span>Protocolo de averbação</span>
                </div>

                <GrAdd
                  className="icone"
                  onClick={adicionarProtocoloAverbacao}
                ></GrAdd>
              </div>

              {protocolosAverbacao.map((_, index) => (
                <div className="container-input-comum" key={index}>
                  <input
                    required
                    type="text"
                    value={protocolosAverbacao[index].averbacao}
                    onChange={(e) =>
                      editarProtocolosAverbacao(
                        e.target.value,
                        index,
                        "averbacao"
                      )
                    }
                  />
                  <span>Protocolo de averbação</span>
                </div>
              ))}
            </div>
          </div>

          <div className="container-formulario-duplo">
            <div className="container-formulario">
              <p className="titulo-formulario">Outros</p>

              <div className="container-inputs-1col">
                <div className="container-input-comum">
                  <textarea
                    // required
                    type="text"
                    rows="4"
                    value={observacoes}
                    onChange={(e) => setObservacoes(e.target.value)}
                  />
                  <span>Observações</span>
                </div>
              </div>
            </div>

            <div className="container-formulario">
              <p className="titulo-formulario">Funcionarios</p>

              <div className="container-inputs-2col">
                <div className="container-input-comum">
                  <input
                    // required
                    type="text"
                    value={numeroLacre}
                    onChange={(e) => setNumeroLacre(e.target.value)}
                  />
                  <span>Número do Lacre</span>
                </div>

                <div className="container-input-comum">
                  <input
                    // required
                    type="text"
                    value={doca}
                    onChange={(e) => setDoca(e.target.value)}
                  />
                  <span>Doca</span>
                </div>
              </div>
            </div>
          </div>

          <div className="container-botoes">
            <button className="btn cancelar" onClick={handleModalNovoManifesto}>
              CANCELAR
            </button>

            <button
              type="submit"
              className="btn salvar"
              onClick={() => setAcaoBotao("Salvar")}
            >
              SALVAR
            </button>

            <button
              type="submit"
              className="btn enviar"
              onClick={() => setAcaoBotao("Enviar")}
            >
              ENVIAR
            </button>
          </div>
        </form>
      </Modal>

      {modalSelecionarCtes && (
        <ModalSelecionarCte
          idCarga={idCarga}
          modalSelecionarCtes={modalSelecionarCtes}
          handleModalSelecionarCtes={handleModalSelecionarCtes}
          registrarCtesSelecionados={(ctes) => setCtesSelecionados(ctes)}
        ></ModalSelecionarCte>
      )}
    </>
  );
};

export default memo(ModalNovoManifesto);
