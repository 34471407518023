import { confirmAlert, overlayClassName } from './confirmAlert';

export const confirmDelete = ({title, message}) => {
  return new Promise((resolve) => {
    confirmAlert({
      title,
      message,
      overlayClassName,
      buttons: [
        {
          label: "Sim",
          onClick: () => resolve(true),
          className: "botao-confirmar"
        },
        {
          label: "Não",
          onClick: () => resolve(false),
          className: "botao-cancelar"
        },
      ],
      afterClose: () => resolve(false),
    });
  });
};
