import React from 'react';
import './three-cols.scss';

export const ThreeCols = ({
  children,
  gap = undefined,
  columnGap = undefined,
}) => {
  return (
    <div
      className="inputs-three-columns"
      style={{
        gap,
        columnGap,
      }}
    >
      {children}
    </div>
  )
}
