import { useQuery } from 'react-query';
import api from '../../../services/api'

const getClientById = async (id) => {
  let path = `/fracionado/clientes/${id}/`;
  const { data } = await api.get(path);
  return data;
}

export const useClientById = (id) => {
  return useQuery(['clients-by-id', id], async () => {
    if (!id) {
      return null;
    }
    return getClientById(id);
  }, {
    cacheTime: 0,
    retry: false,
    refetchInterval: 0,
    refetchIntervalInBackground: 0,
  });
};
