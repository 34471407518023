import React, { useState } from "react";
import StepWizard from "react-step-wizard";
import Modal from "react-modal";

import "react-confirm-alert/src/react-confirm-alert.css";

import "./styles.css";

import EscolherFormaPagamento from "./Components/EscolherFormaPagamento";
import Pix from "./Components/Pix";
import Cartao from "./Components/Cartao";

const ModalPagamento = ({
  modalPagamento,
  handleModalPagamento,
  totalSugeridoCoins,
  quantidadeMeses,
  totalPagar,
}) => {
  const [formaPagamento, setFormaPagamento] = useState("PIX");

  return (
    <Modal
      isOpen={modalPagamento}
      onRequestClose={handleModalPagamento}
      className="modal-pagamento-coins"
      overlayClassName="overlay-modal-pagamento-coins"
      ariaHideApp={false}
    >
      <StepWizard>
        <EscolherFormaPagamento
          handleModalPagamento={handleModalPagamento}
          formaPagamento={formaPagamento}
          setFormaPagamento={setFormaPagamento}
          totalSugeridoCoins={totalSugeridoCoins}
          quantidadeMeses={quantidadeMeses}
          totalPagar={totalPagar}
        ></EscolherFormaPagamento>
        {formaPagamento === "PIX" ? (
          <Pix handleModalPagamento={handleModalPagamento}></Pix>
        ) : (
          <Cartao handleModalPagamento={handleModalPagamento}></Cartao>
        )}
      </StepWizard>
    </Modal>
  );
};

export default ModalPagamento;
