import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs';
import avatar from "../../../../assets/avatar.png"
import { AiOutlineCloseCircle } from "react-icons/ai"
import './styles.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import UFSelectOptions from '../../../../components/formularios/UFSelectOptions';
import { useHistory } from 'react-router-dom';
import api from '../../../../services/api';
import { confirmAlert } from 'react-confirm-alert';
import { useMessages } from '../../../../context/messages-context';
import { removerMascaraCampo } from '../../Motorista/NovoMotorista/utils';
import { IoIosAddCircleOutline } from 'react-icons/io';

const EditarTransportadora = () => {

    const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
    const { showApiErrors, showSuccess } = useMessages();
    const [fotoPerfil, setFotoPerfil] = useState(null);
    const [municipios, setMunicipios] = useState([])
    const history = useHistory()
    const transportadoraForm = useFormik({
        initialValues: {
            redes_sociais: {
                site: "",
                instagram: "",
                facebook: "",
                linkedin: ""
            },
            asseguradoras: [
                {
                    nome: "",
                    apolice: "",
                    cnpj: "",
                }
            ],
            endereco: {
                pais: "Brasil",
                uf: "",
                uf_codigo: "",
                municipio: "",
                municipio_codigo: "",
                logradouro: "",
                bairro: "",
                numero: "",
                cep: "",
                complemento: "",
            },
            endereco_galpao: {
                pais: "Brasil",
                uf: "",
                uf_codigo: "",
                municipio: "",
                municipio_codigo: "",
                logradouro: "",
                bairro: "",
                numero: "",
                cep: "",
                complemento: "",
            },
            RNTRC: "",
            razao_social: "",
            cnpj: "",
            inscricao_estadual: "",
            telefone: "",
            email: "",
            observacoes: "",
            is_ativo: true,
            imagem_perfil: "",
            codigo_numerico_cte: "",
            codigo_numerico_mdfe: "",
            serie_cte: "",
            serie_mdfe: "",
            numero_cte: "",
            numero_mdfe: "",
        }
    });

    const asseguradoras = transportadoraForm.values.asseguradoras;

    const routes = [
        { path: '/acesso', breadcrumb: 'Início' },
        { path: '/acesso/perfil-editar-transportadora', breadcrumb: 'Ajuste Transportadora' },
    ];

    const displayFotoPerfil = useMemo(() => {
        if (!fotoPerfil) {
            return transportadoraForm?.values?.imagem_perfil;
        }
        return URL.createObjectURL(fotoPerfil);
    }, [fotoPerfil, transportadoraForm?.values?.imagem_perfil]);

    const handleChangeUf = (uf) => {
        fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`)
            .then(res => {
                return res.json()
            })
            .then(municipios => {
                setMunicipios(municipios)
            });
    }

    const changeCepDebounce = useRef(null);
    const handleChangeCep = (cep) => {
        transportadoraForm.setFieldValue('endereco.cep', cep);

        clearTimeout(changeCepDebounce.current);
        changeCepDebounce.current = setTimeout(() => {
            if (cep.length === 9) {
                fetch(`https://viacep.com.br/ws/${cep}/json/`)
                    .then((response) => {
                        response.json()
                            .then((data) => {
                                let dadosEndereco = data

                                transportadoraForm.setFieldValue('endereco.uf', dadosEndereco.uf);
                                transportadoraForm.setFieldValue('endereco.municipio', dadosEndereco.localidade);
                                transportadoraForm.setFieldValue('endereco.bairro', dadosEndereco.bairro);
                                transportadoraForm.setFieldValue('endereco.logradouro', dadosEndereco.logradouro);
                            })
                    })
                    .catch((error) => {
                        console.log("ERRO: " + error.message)
                    })
            }
        }, 300);
    }

    const changeGalpaoCepDebounce = useRef(null);
    const handleChangeGalpaoCep = (cep) => {
        transportadoraForm.setFieldValue('endereco_galpao.cep', cep);

        clearTimeout(changeGalpaoCepDebounce.current);
        changeGalpaoCepDebounce.current = setTimeout(() => {
            if (cep.length === 9) {
                fetch(`https://viacep.com.br/ws/${cep}/json/`)
                    .then((response) => {
                        response.json()
                            .then((data) => {
                                let dadosEndereco = data

                                transportadoraForm.setFieldValue('endereco_galpao.uf', dadosEndereco.uf);
                                transportadoraForm.setFieldValue('endereco_galpao.municipio', dadosEndereco.localidade);
                                transportadoraForm.setFieldValue('endereco_galpao.bairro', dadosEndereco.bairro);
                                transportadoraForm.setFieldValue('endereco_galpao.logradouro', dadosEndereco.logradouro);
                            })
                    })
                    .catch((error) => {
                        console.log("ERRO: " + error.message)
                    })
            }
        }, 300);
    }

    const getTransportadoraFormData = () => {
        const formData = new FormData();

        formData.append('razao_social', transportadoraForm.values.razao_social);
        formData.append('cnpj', removerMascaraCampo(transportadoraForm.values.cnpj));
        formData.append('inscricao_estadual', transportadoraForm.values.inscricao_estadual);
        formData.append('telefone', removerMascaraCampo(transportadoraForm.values.telefone));
        formData.append('email', transportadoraForm.values.email);
        formData.append('observacoes', transportadoraForm.values.observacoes);
        formData.append('is_ativo', transportadoraForm.values.is_ativo);
        formData.append('RNTRC', transportadoraForm.values.RNTRC);

        formData.append("endereco.pais", transportadoraForm.values.endereco.pais)
        formData.append("endereco.uf", transportadoraForm.values.endereco.uf)
        formData.append("endereco.municipio", transportadoraForm.values.endereco.municipio)
        formData.append("endereco.logradouro", transportadoraForm.values.endereco.logradouro)
        formData.append("endereco.bairro", transportadoraForm.values.endereco.bairro)
        formData.append("endereco.numero", transportadoraForm.values.endereco.numero)
        formData.append("endereco.cep", removerMascaraCampo(transportadoraForm.values.endereco.cep))
        formData.append("endereco.complemento", transportadoraForm.values.endereco.complemento)

        formData.append("endereco_galpao.pais", transportadoraForm.values.endereco_galpao.pais)
        formData.append("endereco_galpao.uf", transportadoraForm.values.endereco_galpao.uf)
        formData.append("endereco_galpao.municipio", transportadoraForm.values.endereco_galpao.municipio)
        formData.append("endereco_galpao.logradouro", transportadoraForm.values.endereco_galpao.logradouro)
        formData.append("endereco_galpao.bairro", transportadoraForm.values.endereco_galpao.bairro)
        formData.append("endereco_galpao.numero", transportadoraForm.values.endereco_galpao.numero)
        formData.append("endereco_galpao.cep", removerMascaraCampo(transportadoraForm.values.endereco_galpao.cep))
        formData.append("endereco_galpao.complemento", transportadoraForm.values.endereco_galpao.complemento)

        formData.append('redes_sociais.instagram', transportadoraForm.values.redes_sociais.instagram);
        formData.append('redes_sociais.facebook', transportadoraForm.values.redes_sociais.facebook);
        formData.append('redes_sociais.linkedin', transportadoraForm.values.redes_sociais.linkedin);
        formData.append('redes_sociais.site', transportadoraForm.values.redes_sociais.site);

        formData.append('codigo_numerico_cte', transportadoraForm.values.codigo_numerico_cte);
        formData.append('codigo_numerico_mdfe', transportadoraForm.values.codigo_numerico_mdfe);
        formData.append('serie_cte', transportadoraForm.values.serie_cte);
        formData.append('serie_mdfe', transportadoraForm.values.serie_mdfe);
        formData.append('numero_cte', transportadoraForm.values.numero_cte);
        formData.append('numero_mdfe', transportadoraForm.values.numero_mdfe);

        if (fotoPerfil && fotoPerfil?.name && fotoPerfil?.size) {
            formData.append('imagem_perfil', fotoPerfil);
        }

        return formData;
    }

    const handleSubmit = async (e) => {
        const formData = getTransportadoraFormData();

        const asseguradorasSemMascara = transportadoraForm.values.asseguradoras.map((item) => {
            return {
                ...item,
                cnpj: removerMascaraCampo(item.cnpj)
            }
        });

        const asseguradoras = asseguradorasSemMascara;

        try {

            const response1 = await api.patch(`transportadora/${transportadoraForm.values.id}/`, formData);

            const asseguradorasPromises = [];

            asseguradoras.map((item) => {
                if (item.id) {
                    return asseguradorasPromises.push(
                        api.patch(`asseguradora/${item.id}/`, item)
                    );
                } else {
                    return asseguradorasPromises.push(
                        api.post('asseguradora/', item)
                    );
                }
            });

            const responses = await Promise.all(asseguradorasPromises);
            const allResponsesSuccessful = responses.every(response => response.status === 200);

            if (allResponsesSuccessful && response1.status === 200) {
                showSuccess('Sucesso!', 'Dados Transportadora Salvos!');
            }

        } catch (err) {
            showApiErrors(err);
        }
    }

    const handleFinish = (e) => {

        e.preventDefault();

        confirmAlert({
            title: "Finalizar alterações?",
            message: `Deseja Finalizar as alterações do cadastro da transportadora?`,
            overlayClassName: "alert-remover-grupo-permissoes",
            buttons: [
                {
                    label: "Sim",
                    onClick: () => {
                        handleSubmit()
                    },
                    className: "botao-confirmar"
                },
                {
                    label: "Não",
                    onClick: () => { },
                    className: "botao-cancelar"
                }
            ]
        })
    };

    const handleAddAsseguradora = () => {
        transportadoraForm.setFieldValue('asseguradoras', [...asseguradoras, {
            nome: "",
            apolice: "",
            cnpj: "",
        }])
    }

    const handleRemoveAsseguradora = (index) => {
        transportadoraForm.setFieldValue('asseguradoras', asseguradoras.filter((_, i) => i !== index))
    }

    const handleAsseguradoraChange = (index, event, key) => {
        const newAsseguradoras = [...transportadoraForm.values.asseguradoras];
        newAsseguradoras[index] = {
            ...newAsseguradoras[index],
            [key]: event.target.value,
        };
        transportadoraForm.setValues({
            ...transportadoraForm.values,
            asseguradoras: newAsseguradoras,
        });
    };

    useEffect(() => {
        api.get(`transportadora/${dadosUsuario.transportadora_id}`)
            .then(async function (response) {
                let dadosTransportadora = await response.data
                transportadoraForm.setValues({
                    ...transportadoraForm.initialValues,
                    ...dadosTransportadora,
                    redes_sociais: {
                        ...transportadoraForm.initialValues.redes_sociais,
                        ...(dadosTransportadora.redes_sociais || {}),
                    },
                    endereco_galpao: {
                        ...transportadoraForm.initialValues.endereco_galpao,
                        ...(dadosTransportadora.endereco_galpao || {})
                    }
                })
            })
            .catch(function (error) {
                console.log(error)
            })
    }, [dadosUsuario.transportadora_id])

    return (
        <div className="container-perfil-editar-transportadora">
            <BreadCrumbs
                routes={routes}
            >
            </BreadCrumbs>

            <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

            <div className="perfil-editar-transportadora">
                <p className="titulo-pagina">
                    Editar Transportadora
                </p>
                <p className="subtitulo-pagina">
                    Por favor, informe os dados solicitados para editar o cadastro da transportadora.
                </p>

                <form onSubmit={handleFinish}>
                    <div className="categoria">
                        <p className="titulo-categoria">Dados da Transportadora</p>

                        <div className="container-inputs-1col">
                            <label className="input-file">
                                <div className="container-foto-perfil">
                                    <div className="foto">
                                        <img src={displayFotoPerfil || avatar} alt="foto" />
                                    </div>

                                    <p>Adicionar Foto Perfil</p>
                                </div>

                                <input
                                    placeholder="Anexar Imagem Curso"
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={(e) => setFotoPerfil(e.target.files[0])}
                                />
                            </label>
                        </div>
                        <div className="container-inputs-2col">
                            <div className="container-input">
                                <input
                                    required
                                    type="text"
                                    value={transportadoraForm.values.razao_social}
                                    id="razao_social"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Razão Social <i>*</i></span>
                            </div>

                            <div className="container-input">
                                <InputMask
                                    required
                                    mask="99.999.999/9999-99"
                                    maskChar=" "
                                    value={transportadoraForm.values.cnpj}
                                    id="cnpj"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>CNPJ<i>*</i></span>
                            </div>

                            <div className="container-input">
                                <input
                                    required
                                    type="text"
                                    value={transportadoraForm.values.inscricao_estadual}
                                    id="inscricao_estadual"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Inscrição Estadual: <i>*</i></span>
                            </div>

                            <div className="container-input">
                                <input
                                    required
                                    type="number"
                                    value={transportadoraForm.values.RNTRC}
                                    id="nome_completo"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>RNTRC: <i>*</i></span>
                            </div>

                            <div className="container-input">
                                <InputMask
                                    required
                                    mask="(99) 99999-9999"
                                    maskChar=" "
                                    value={transportadoraForm.values.telefone}
                                    id="telefone"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Telefone Contato <i>*</i></span>
                            </div>

                            <div className="container-input">
                                <input
                                    required
                                    type="email"
                                    value={transportadoraForm.values.email}
                                    id="email"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>E-mail <i>*</i></span>
                            </div>

                            <div className="container-input">
                                <textarea
                                    type="text"
                                    placeholder='Observações'
                                    rows={5}
                                    value={transportadoraForm.values.observacoes
                                    }
                                    id="observacoes"
                                    onChange={transportadoraForm.handleChange}
                                />
                            </div>

                            <div className="container-input-checkbox">
                                <span className="checkbox"
                                >
                                    <input
                                        type="checkbox"
                                        checked={transportadoraForm.values.is_ativo || true}
                                        id="is_ativo"
                                        disabled={true}
                                    />
                                    <span className="checked"></span>
                                    Ativo
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="categoria">
                        <p className="titulo-categoria">Endereço</p>

                        <div className="container-inputs-2col">
                            <div className="container-input">
                                <select
                                    required
                                    value={transportadoraForm.values.endereco.pais}
                                >
                                    <option value="Brasil" disabled>Brasil</option>
                                </select>
                                <span>País <i>*</i></span>
                            </div>

                            <div className="container-input">
                                <InputMask
                                    required
                                    mask="99999-999"
                                    maskChar=""
                                    value={transportadoraForm.values.endereco.cep}
                                    id="endereco.cep"
                                    onChange={(e) => handleChangeCep(e.target.value)}
                                />
                                <span>CEP <i>*</i></span>
                            </div>

                            <div className="container-input">
                                <select
                                    required
                                    id="endereco.uf"
                                    value={transportadoraForm.values.endereco.uf}
                                    onChange={(e) => handleChangeUf(e.target.value)}
                                >
                                    <option disabled value="">UF*</option>
                                    <UFSelectOptions />
                                </select>
                            </div>

                            {municipios.length > 0
                                ? <div className="container-input">
                                    <select
                                        required
                                        value={transportadoraForm.values.endereco.municipio}
                                        id="endereco.municipio"
                                        onChange={transportadoraForm.handleChange}
                                    >
                                        <option value="" disabled></option>
                                        {municipios.map((item) => {
                                            return (
                                                <option key={item.id} value={item.nome}>{item.nome}</option>
                                            )
                                        })}
                                    </select>
                                    <span>Município <i>*</i></span>
                                </div>
                                : <div className="container-input">
                                    <input
                                        required
                                        type="text"
                                        value={transportadoraForm.values.endereco.municipio}
                                        id="endereco.municipio"
                                        onChange={transportadoraForm.handleChange}
                                    />
                                    <span>Município <i>*</i></span>
                                </div>
                            }

                            <div className="container-input">
                                <input
                                    required
                                    type="text"
                                    value={transportadoraForm.values.endereco.bairro}
                                    id="endereco.bairro"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Bairro <i>*</i></span>
                            </div>

                            <div className="container-input">
                                <input
                                    required
                                    type="text"
                                    value={transportadoraForm.values.endereco.logradouro}
                                    id="endereco.logradouro"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Logradouro <i>*</i></span>
                            </div>

                            <div className="container-input">
                                <input
                                    required
                                    type="text"
                                    value={transportadoraForm.values.endereco.numero}
                                    id="endereco.numero"
                                    onChange={transportadoraForm.handleChange}
                                    maxLength={4}
                                />
                                <span>Número</span>
                            </div>

                            <div className="container-input">
                                <input
                                    type="text"
                                    value={transportadoraForm.values.endereco.complemento}
                                    id="endereco.complemento"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Complemento</span>
                            </div>
                        </div>
                    </div>


                    <div className="categoria">
                        <p className="titulo-categoria">Endereço de Galpão (Fracionado)</p>

                        <div className="container-inputs-2col">
                            <div className="container-input">
                                <select
                                    required
                                    value={transportadoraForm.values.endereco_galpao.pais}
                                >
                                    <option value="Brasil" disabled>Brasil</option>
                                </select>
                                <span>País <i>*</i></span>
                            </div>

                            <div className="container-input">
                                <InputMask
                                    required
                                    mask="99999-999"
                                    maskChar=""
                                    value={transportadoraForm.values.endereco_galpao.cep}
                                    id="endereco_galpao.cep"
                                    onChange={(e) => handleChangeGalpaoCep(e.target.value)}
                                />
                                <span>CEP <i>*</i></span>
                            </div>

                            <div className="container-input">
                                <select
                                    required
                                    id="endereco_galpao.uf"
                                    value={transportadoraForm.values.endereco_galpao.uf}
                                    onChange={(e) => handleChangeUf(e.target.value)}
                                >
                                    <option disabled value="">UF*</option>
                                    <UFSelectOptions />
                                </select>
                            </div>

                            {municipios.length > 0
                                ? <div className="container-input">
                                    <select
                                        required
                                        value={transportadoraForm.values.endereco_galpao.municipio}
                                        id="endereco_galpao.municipio"
                                        onChange={transportadoraForm.handleChange}
                                    >
                                        <option value="" disabled></option>
                                        {municipios.map((item) => {
                                            return (
                                                <option key={item.id} value={item.nome}>{item.nome}</option>
                                            )
                                        })}
                                    </select>
                                    <span>Município <i>*</i></span>
                                </div>
                                : <div className="container-input">
                                    <input
                                        required
                                        type="text"
                                        value={transportadoraForm.values.endereco_galpao.municipio}
                                        id="endereco_galpao.municipio"
                                        onChange={transportadoraForm.handleChange}
                                    />
                                    <span>Município <i>*</i></span>
                                </div>
                            }

                            <div className="container-input">
                                <input
                                    required
                                    type="text"
                                    value={transportadoraForm.values.endereco_galpao.bairro}
                                    id="endereco_galpao.bairro"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Bairro <i>*</i></span>
                            </div>

                            <div className="container-input">
                                <input
                                    required
                                    type="text"
                                    value={transportadoraForm.values.endereco_galpao.logradouro}
                                    id="endereco_galpao.logradouro"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Logradouro <i>*</i></span>
                            </div>

                            <div className="container-input">
                                <input
                                    required
                                    type="text"
                                    value={transportadoraForm.values.endereco_galpao.numero}
                                    id="endereco_galpao.numero"
                                    onChange={transportadoraForm.handleChange}
                                    maxLength={4}
                                />
                                <span>Número</span>
                            </div>

                            <div className="container-input">
                                <input
                                    type="text"
                                    value={transportadoraForm.values.endereco_galpao.complemento}
                                    id="endereco_galpao.complemento"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Complemento</span>
                            </div>
                        </div>
                    </div>

                    <div className="categoria">
                        <p className="titulo-categoria">Documentos Fiscais</p>

                        <div className="container-inputs-2col">
                            <div className="container-input">
                                <input
                                    required
                                    type="number"
                                    value={transportadoraForm.values.codigo_numerico_cte}
                                    id="codigo_numerico_cte"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Código Numérico CT-e<i>*</i></span>
                            </div>
                            <div className="container-input">
                                <input
                                    required
                                    type="number"
                                    value={transportadoraForm.values.codigo_numerico_mdfe}
                                    id="codigo_numerico_mdfe"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Código Numérico MDF-e<i>*</i></span>
                            </div>
                            <div className="container-input">
                                <input
                                    required
                                    type="number"
                                    value={transportadoraForm.values.numero_cte}
                                    id="numero_cte"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Número de CT-e<i>*</i></span>
                            </div>
                            <div className="container-input">
                                <input
                                    required
                                    type="number"
                                    value={transportadoraForm.values.numero_mdfe}
                                    id="numero_mdfe"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Número de MDF-e<i>*</i></span>
                            </div>
                            <div className="container-input">
                                <input
                                    required
                                    type="number"
                                    value={transportadoraForm.values.serie_cte}
                                    id="serie_cte"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Série do CT-e<i>*</i></span>
                            </div>
                            <div className="container-input">
                                <input
                                    required
                                    type="number"
                                    value={transportadoraForm.values.serie_mdfe}
                                    id="serie_mdfe"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Série do MDF-e<i>*</i></span>
                            </div>
                        </div>
                    </div>
                    <div className="categoria">
                        <p className="titulo-categoria">Redes Sociais</p>

                        <div className="container-inputs-2col">
                            <div className="container-input">
                                <input
                                    type="text"
                                    value={transportadoraForm.values.redes_sociais.site}
                                    id="redes_sociais.site"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Site</span>
                            </div>
                            <div className="container-input">
                                <input
                                    type="text"
                                    value={transportadoraForm.values.redes_sociais.instagram}
                                    id="redes_sociais.instagram"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Instagram</span>
                            </div>
                            <div className="container-input">
                                <input
                                    type="text"
                                    value={transportadoraForm.values.redes_sociais.facebook}
                                    id="redes_sociais.facebook"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Facebook</span>
                            </div>
                            <div className="container-input">
                                <input
                                    type="text"
                                    value={transportadoraForm.values.redes_sociais.linkedin}
                                    id="endereco.linkedin"
                                    onChange={transportadoraForm.handleChange}
                                />
                                <span>Linkedin</span>
                            </div>
                        </div>
                    </div>
                    <div className="categoria">
                        <p className="titulo-categoria">Asseguradoras</p>

                        {
                            asseguradoras.map((item, index) => {
                                return (
                                    <div key={index} className="container-inputs-2col">
                                        <div className="container-input">
                                            <input
                                                required
                                                type="text"
                                                value={item.nome}
                                                id="asseguradora.nome"
                                                onChange={(e) => handleAsseguradoraChange(index, e, 'nome')}
                                            />
                                            <span>Nome<i>*</i></span>
                                        </div>
                                        <div className="container-input">
                                            <input
                                                required
                                                type="text"
                                                value={item.apolice}
                                                id="asseguradora.apolice"
                                                onChange={(e) => handleAsseguradoraChange(index, e, 'apolice')}
                                            />
                                            <span>Ápolice<i>*</i></span>
                                        </div>
                                        <div className="container-input">
                                            <InputMask
                                                required
                                                mask="99.999.999/9999-99"
                                                maskChar=" "
                                                value={item.cnpj}
                                                id="asseguradora.cnpj"
                                                onChange={(e) => handleAsseguradoraChange(index, e, 'cnpj')}
                                            />
                                            <span>CNPJ<i>*</i></span>
                                        </div>
                                        {!!(index >= 1) && <button
                                            type='button'
                                            onClick={() => handleRemoveAsseguradora(index)}
                                            className="btn-remove"
                                        >
                                            <AiOutlineCloseCircle />
                                        </button>}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="container-input">
                        <button
                            type='button'
                            onClick={handleAddAsseguradora}
                            className="btn-add"
                        ><IoIosAddCircleOutline /> Adicionar outro(a) Asseguradora</button>
                    </div>

                    <div className="container-botoes">
                        <button
                            className="btn-cadastrar"
                            type='submit'
                        >
                            Finalizar Alterações
                        </button>
                    </div>
                </form>
            </div >
        </div >
    )
}

export default EditarTransportadora;
