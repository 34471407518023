import { useMutation, useQueryClient } from 'react-query';
import { useSearch } from '../../hooks';
import api from '../../../services/api';
import { useMessages } from '../../../context/messages-context';

export const useDeleteClientMutation = () => {
  const { showApiErrors } = useMessages();
  const { page, term } = useSearch();
  const queryClient = useQueryClient();

  return useMutation(async ({ id }) => {
    try {
      await api.delete(`/fracionado/clientes/${id}/`);

      
      await queryClient.invalidateQueries(['clients']);
      await queryClient.refetchQueries(['clients', term, page]);
    } catch (err) {
      showApiErrors(err);
      return null;
    }
  });
}
