import React, { memo, useEffect, useState } from "react";
import Modal from "react-modal";
import { RiCloseCircleFill } from "react-icons/ri";

import "./styles.css";

import api from "../../../../../../../services/api";
import { confirmAlert } from "react-confirm-alert";

const ModalNovoReembolso = ({
  modalNovoFretePagar,
  handleModalNovoReembolso,
  idCarga,
  requisitarFretes,
  clientes,

  idReembolso,
  requisitarDocumentos,
}) => {
  const [tipoSelecionado, setTipoSelecionado] = useState("");
  const [cliente, setCliente] = useState("");
  const [valor, setValor] = useState("");
  const [idReembolsoNota, setIdReembolsoNota] = useState("");
  const [observacoes, setObservacoes] = useState("");

  useEffect(() => {
    if (!idReembolso) {
      return;
    }

    const fetch = async () => {
      try {
        const response = await api.get(`documentos_fiscais/fretereceber/${idReembolso}`);
        const { data } = response;
        setTipoSelecionado(data.tipo);
        setCliente(data.cliente);
        setValor(data.valor);
        setIdReembolsoNota(data.id_reembolso);
        setObservacoes(data.observacoes);
      } catch { }
    }
    fetch();
  }, [idReembolso]);

  const registrarNovoReembolso = async () => {
    try {
      if (idReembolso) {
        await api.patch(`documentos_fiscais/fretereceber/${idReembolso}/`, {
          tipo: tipoSelecionado,
          cliente: cliente,
          valor: valor,
          observacoes: observacoes,
          id_reembolso: idReembolsoNota || null,
        });
      } else {
        await api.post(`documentos_fiscais/fretereceber/`, {
          tipo: tipoSelecionado,
          cliente: cliente,
          valor: valor,
          observacoes: observacoes,
          origem: "reembolso",
          id_reembolso: idReembolsoNota || null,
          carga: idCarga,
        });
      }
      requisitarFretes();
      requisitarDocumentos();
      handleModalNovoReembolso();
    } catch (err) {
      console.log(err.response);
      let erros = []

      for (const property in err?.response?.data) {
        const formattedProperty = property.replace('id_reembolso', 'id');
        erros.push(`${formattedProperty}: ${err?.response?.data[property]}`);
      }

      confirmAlert({
        title: "Erro",
        message: erros[0],
        overlayClassName: "alert-remover-documento",
        buttons: [
          {
            label: "Ok",
            className: "botao-cancelar",
          },
        ],
      });

    }
  };

  return (
    <Modal
      isOpen={modalNovoFretePagar}
      onRequestClose={handleModalNovoReembolso}
      className="modal-novo-frete-pagar"
      overlayClassName="overlay-modal-novo-frete-pagar"
      ariaHideApp={false}
    >
      <div className="container-novo-frete">
        <RiCloseCircleFill
          className="icone-fechar"
          onClick={handleModalNovoReembolso}
        ></RiCloseCircleFill>

        <p className="titulo">Novo Reembolso de Despesas</p>

        <div className="container-inputs">
          <div className="container-inputs-1col">
            <div className="container-input-comum input-id">
              {!!idReembolso ? (
                <input
                  type="number"
                  value={idReembolsoNota}
                  min={1}
                  onChange={(e) => setIdReembolsoNota(e.target.value)}
                />
              ) : (
                <input
                  type="text"
                  value="Automático"
                  disabled
                />
              )}
              <span>ID</span>
            </div>
          </div>
          <div className="container-inputs-4col">

            <div className="container-input-comum">
              <input
                type="date"
                value={new Date().toISOString().substring(0, 10)}
                disabled
              />
            </div>

            <div className="container-input-comum">
              <select
                value={tipoSelecionado}
                onChange={(e) => setTipoSelecionado(e.target.value)}
              >
                <option value="">Tipo</option>
                <option value="boleto">Boleto</option>
                <option value="ted-doc">TED/DOC</option>
                <option value="pix">PIX</option>
                <option value="dinheiro">Dinheiro</option>
                <option value="cheque">Cheque</option>
                <option value="outros">Outros</option>
              </select>
            </div>

            <div className="container-input-comum">
              <select
                value={cliente}
                onChange={(e) => setCliente(e.target.value)}
              >
                <option value="">Clientes</option>
                {clientes.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

            <div className="container-input-comum">
              <input
                required
                type="number"
                value={valor}
                onChange={(e) => setValor(e.target.value)}
              />
              <span>Valor</span>
            </div>
          </div>

          <div className="container-inputs-1col">
            <div className="container-input-comum">
              <input
                required
                type="text"
                value={observacoes}
                onChange={(e) => setObservacoes(e.target.value)}
              />
              <span>Observações</span>
            </div>
          </div>

          <button className="btn-registrar" onClick={registrarNovoReembolso}>
            {idReembolso ? 'Salvar Alterações' : 'Registrar Novo Reembolso'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalNovoReembolso);
