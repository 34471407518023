import { useState, useRef, useEffect, useCallback } from 'react'
import { ImSearch } from 'react-icons/im'

import './styles.css'

const InputSearchVeiculoCarroceria = ({ placeholder, suggestions, disabled, value, saveId, saveValue }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([])
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0)
  const [showSuggestions, setShowSuggestions] = useState(false)

  const list = useRef(null)

  const onChange = (e) => {
    const userInput = e.target.value

    const unLinked = suggestions.filter(
      (suggestion) =>
        suggestion.tipo.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    )

    saveValue(e.target.value)
    setFilteredSuggestions(unLinked)
    setShowSuggestions(true)
  }

  const onClick = (veiculo) => {
    setFilteredSuggestions([])
    setActiveSuggestionIndex(0)
    setShowSuggestions(false)
    saveValue(veiculo.tipo)
    saveId(veiculo.id)
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      onClick(filteredSuggestions[activeSuggestionIndex])
    } else if (e.keyCode === 38) {
      if (activeSuggestionIndex === 0) {
        return
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1)
    }
    else if (e.keyCode === 40) {
      if (activeSuggestionIndex === filteredSuggestions.length -1) {
        return
      }

      setActiveSuggestionIndex(activeSuggestionIndex + 1)
    }
  }

  const showActiveSuggestion = useCallback((index) => {
    if (index <= filteredSuggestions.length - 1) {
      const parentRect = list.current.getBoundingClientRect()
      const child = list.current.querySelectorAll('li')[index]
      const childRect = child.getBoundingClientRect()

      const yStart = (childRect.top - parentRect.top) + list.current.scrollTop

      if (yStart >= list.current.scrollTop
        && yStart < (list.current.scrollTop + parentRect.height)) {
        return
      }

      list.current.scrollTop = yStart
    }
  }, [filteredSuggestions])

  useEffect(() => {
    if (list.current) {
      showActiveSuggestion(activeSuggestionIndex)
    }
  }, [activeSuggestionIndex, showActiveSuggestion])

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <ul className="suggestions">
        {filteredSuggestions.map((suggestion, index) => {
          let className = ""

          if (index === activeSuggestionIndex) {
            className = "suggestion-active"
          }

          return (
            <li
              className={className}
              key={suggestion.id}
              onClick={() => onClick(suggestion)}
            >
              {suggestion.tipo}
            </li>
          )
        })}
      </ul>
    ) : (
      <div className="no-suggestions">
        <em>Nenhum item encontrado!</em>
      </div>
    )
  }

  return (
    <div className="container-input-search-veiculo-carroceria">
      <div className="input-search-veiculo-carroceria">
        <input
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
        />
        <ImSearch className="icone"></ImSearch>
      </div>
      {showSuggestions && value && <SuggestionsListComponent />}
    </div>
  )
}

export default InputSearchVeiculoCarroceria