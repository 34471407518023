import React, { memo, useEffect } from 'react'
import Modal from 'react-modal'
import { parseISO, format } from 'date-fns'
import { RiCloseCircleFill } from 'react-icons/ri'
import { FaUserTie, FaRegCalendarAlt, FaRegCalendarCheck } from 'react-icons/fa'

import './styles.css'

import CardsMotoristas from './CardsMotoristas'
import api from "../../../../../../../services/api"
import { useState } from 'react'
import { useCallback } from 'react'

const ModalPropostaCarga = ({ atualizarCargas, carga, moverCarga, modalPropostaCarga, handleModalPropostaCarga }) => {
  const [candidaturas, setCandidaturas] = useState([])
  const [modalPerfilMotorista, setModalPerfilMotorista] = useState(false);

  useEffect(() => {
    api.get(`candidatura/?carga=${carga.id}`)
      .then(function (response) {
        let auxCandidaturas = []

        response.data.results.forEach(item => {
          if (item.status === "pendente" || item.status === "analise") {
            auxCandidaturas.push(item)
          }
        })

        setCandidaturas(auxCandidaturas)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [carga.id, modalPerfilMotorista])

  const handleModalPerfilMotorista = useCallback((bool) => {
    setModalPerfilMotorista(bool)
  }, [])

  return (
    <Modal
      isOpen={modalPropostaCarga}
      onRequestClose={handleModalPropostaCarga}
      className="modal-proposta-carga"
      overlayClassName="overlay-modal-proposta-carga"
      ariaHideApp={false}
    >

      <div className="titulo">
        <p>Propostas para a Carga</p>
        <RiCloseCircleFill className="icone" onClick={handleModalPropostaCarga}></RiCloseCircleFill>
      </div>

      <div className="informacoes-carga">
        <div className="linha">
          <div className="valor">
            <div>
              <p>R$</p>
            </div>

            <p>
              {Number(carga.proposta_inicial).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </p>
          </div>

          <div className="pedido-id">
            <div className="pedido">
              <p>
                <span>Pedido: </span>
                {
                  carga.pedidos.map((item, index) => index < carga.pedidos.length - 1
                    ? `${item.numero}, `
                    : item.numero)
                }
              </p>
            </div>

            <div className="id">
              <p><span>ID:</span> {carga.id}</p>
            </div>
          </div>

          <div className="motoristas">
            <FaUserTie className="icone"></FaUserTie>
            <span>{candidaturas.length}</span>
          </div>
        </div>

        <div className="linha cidades">
          <p>{`${carga.origem.endereco?.municipio}-${carga.origem.endereco?.uf}`} /
            {carga.pontos_parada.map((item, index) => {
              if (carga.pontos_parada.length > 1) {
                if (index < carga.pontos_parada.length - 1) {
                  return ` ${item.destino.endereco?.municipio}-${item.destino.endereco?.uf},`
                }
                return ` ${item.destino.endereco?.municipio}-${item.destino.endereco?.uf}`
              }
              return ` ${item.destino.endereco?.municipio}-${item.destino.endereco?.uf}`
            })}</p>
        </div>

        <div className="linha">
          <div className="embarque">
            <FaRegCalendarAlt className="icone"></FaRegCalendarAlt>
            <p><span>Embarque: </span>{format(parseISO(carga.data_embarque), "dd/MM")}</p>
          </div>

          <div className="entrega">
            <FaRegCalendarCheck className="icone"></FaRegCalendarCheck>
            <p><span>Entrega: </span>{format(parseISO(carga.data_descarga), "dd/MM")}</p>
          </div>
        </div>
      </div>

      <div className="container-cards-motoristas">
        {candidaturas.length > 0
          ? candidaturas.map((item, index) => {
            return (
              <CardsMotoristas
                key={index}
                index={index}
                atualizarCargas={atualizarCargas}
                moverCarga={moverCarga}
                candidatura={item}
                handleModalPropostaCarga={handleModalPropostaCarga}
                modalPerfilMotorista={modalPerfilMotorista}
                setModalPerfilMotorista={handleModalPerfilMotorista}
              ></CardsMotoristas>
            )
          })
          : <p className="msg-sem-candidatos">Nenhum motorista candidato!</p>
        }
      </div>
    </Modal>
  )
}

export default memo(ModalPropostaCarga)