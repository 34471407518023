import React, { memo, useEffect } from 'react'

import './styles.css'

import caminhao from "../../../../assets/truck_blue.png"

import api from '../../../../services/api'

const NotificacaoCandidaturaNegada = ({ dadosCandidatura, idNotificacao }) => {
  useEffect(() => {
    const registrarNotificacaoVisualizada = async () => {
      await api.patch(`empresa_notificacoes/${idNotificacao}/`, {
        visualizado: true
      })
        .then(function () {
        })
        .catch(function (error) {
          console.log(error)
        })
    };

    if (!idNotificacao) {
      return;
    }
    registrarNotificacaoVisualizada();
  }, [idNotificacao]);

  return (
    <div className="notificacao-candidatura-negada">
      <img src={caminhao} alt="" />

      <div className="conteudo">
        <p>
          A candidatura do motorista {dadosCandidatura.candidatura_item.motorista.nome_completo} na carga
          {dadosCandidatura.carga.id} para {dadosCandidatura.carga?.pontos_parada.map((item, index) => {
            return (
              index !== 0
                ? ` | ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
                : ` ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
            )
          })} foi negada pois não foi possível enviar os dados do motorista para o gerenciador de riscos.
        </p>
      </div>
    </div>
  )
}

export default memo(NotificacaoCandidaturaNegada)