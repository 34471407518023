import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Modal from 'react-modal'
import StepWizard from 'react-step-wizard'
import { FaIdCard } from 'react-icons/fa'
import { RiCloseCircleFill } from 'react-icons/ri'
import NavWizard from './nav-wizard';
import Etapa1 from "./Etapa1"
import Etapa2 from './Etapa2';
import Etapa3 from './Etapa3';
import api from "../../../../../services/api";
import { useCallback } from 'react';

const LIMIT_API = 8

const ModalNovaCarga = ({
  idCarga,
  abrirModalNovaCarga,
  fecharModal,
  destinatarios,

  atualizarCargas,
  fecharModalSemAlert,
  fecharModalDadosCarga,
}) => {
  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
  const tipoUsuario = dadosUsuario.user_type;
  const idUsuario = dadosUsuario.id;

  const [todosVeiculos, setTodosVeiculos] = useState([]);
  const [todasCarrocerias, setTodasCarrocerias] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [especiesProdutos, setEspeciesProdutos] = useState([]);
  const [tiposContaFrete, setTiposContaFrete] = useState([]);

  const [isLoadingCarga, setIsLoadingCarga] = useState(false);

  const [quantidadeCargas, setQuantidadeCargas] = useState(1);
  const form = useFormik({
    initialValues: {
      is_fracionada: false,
      pedidos: [],
      conta_frete: "",
      nome_conta_frete: "",
      produto: null,
      nome_produto: '',
      especie_produto: null,
      nome_especie_produto: '',
      is_rastreada: false,
      pontos_parada: [],
      quantidadeCargas: 1,

      tipos_veiculo: [],
      tipos_veiculo_leve: {},
      tipos_veiculo_medio: {},
      tipos_veiculo_pesado: {},
      tipos_carroceria: [],
      tipos_carroceria_fechada: {},
      tipos_carroceria_aberta: {},
      tipos_carroceria_especial: {},

      quantidade_volume: "",
      peso_unitario: "",
      volume_unitario: "",

      data_embarque: "",
      data_descarga: "",

      proposta_inicial: "",
      proposta_tipo: "",
      porcentagem_adiantamento: "",

      is_lotacao_veiculo: true,
      is_livre_carga_descarga: true,
      is_publica: true,
      observacoes: "",
      data_fechamento: "",
      motorista: null,
      motorista_nome: '',
      grupo: null,

      transportadora: null,
      transportadora_nome: "",

      escolher_grupo_motorista: false,

      coletas: {},

      destino_uf: {
        label: '',
        value: '',
        codigo: '',
      },
      destino_municipio: {
        label: '',
        value: '',
        codigo: '',
      },
      origem_uf: {
        label: '',
        value: '',
        codigo: '',
      },
      origem_municipio: {
        label: '',
        value: '',
        codigo: '',
      },
    },
  });

  const [lazyLoading, setLazyLoading] = useState({
    offset: 0,
    loading: true,
    scrollTop: 0,
    total: 0,
  });

  const handleLazyLoading = ({ loading, scrollTop }) => {
    setLazyLoading(prevLazyLoading => ({
      ...prevLazyLoading,
      loading: loading,
      offset: prevLazyLoading.offset + LIMIT_API,
      scrollTop: scrollTop,
    }));
  };

  useEffect(() => {
    if (idCarga) {
      return;
    }

    api.get(`tipoveiculo/`)
      .then(function (response) {
        let dadosVeiculos = response.data.results
        let auxObjTiposVeiculosLeves = {}
        let auxObjTiposVeiculosMedio = {}
        let auxObjTiposVeiculosPesados = {}

        dadosVeiculos.forEach((item) => {
          if (item.categoria_veiculo === 1) {
            auxObjTiposVeiculosLeves[item.tipo] = false
          }

          if (item.categoria_veiculo === 2) {
            auxObjTiposVeiculosMedio[item.tipo] = false
          }

          if (item.categoria_veiculo === 3) {
            auxObjTiposVeiculosPesados[item.tipo] = false
          }
        })

        setTodosVeiculos(response.data.results)
        form.setFieldValue('tipos_veiculo_leve', auxObjTiposVeiculosLeves);
        form.setFieldValue('tipos_veiculo_medio', auxObjTiposVeiculosMedio);
        form.setFieldValue('tipos_veiculo_pesado', auxObjTiposVeiculosPesados);
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idCarga]);

  useEffect(() => {
    if (idCarga) {
      return;
    }

    api.get(`tipocarroceria/`)
      .then(function (response) {
        let dadosCarrocerias = response.data.results
        let auxObjTiposCarroceriasFechadas = {}
        let auxObjTiposCarroceriasAbertas = {}
        let auxObjTiposCarroceriasEspecial = {}

        dadosCarrocerias.forEach((item) => {
          if (item.categoria_carroceria === 1) {
            auxObjTiposCarroceriasFechadas[item.tipo] = false
          }

          if (item.categoria_carroceria === 2) {
            auxObjTiposCarroceriasAbertas[item.tipo] = false
          }

          if (item.categoria_carroceria === 3) {
            auxObjTiposCarroceriasEspecial[item.tipo] = false
          }
        })

        setTodasCarrocerias(response.data.results)
        form.setFieldValue('tipos_carroceria_fechada', auxObjTiposCarroceriasFechadas);
        form.setFieldValue('tipos_carroceria_aberta', auxObjTiposCarroceriasAbertas);
        form.setFieldValue('tipos_carroceria_especial', auxObjTiposCarroceriasEspecial);
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idCarga]);

  useEffect(() => {
    if (!idCarga) {
      form.setValues(form.initialValues);
      return;
    }

    const getTiposVeiculos = async (tipos) => {
      let dadosVeiculos = [];
      try {
        const { data } = await api.get(`tipoveiculo/`);
        dadosVeiculos = data.results;
      } catch { };

      let auxObjTiposVeiculosLeves = {}
      let auxObjTiposVeiculosMedio = {}
      let auxObjTiposVeiculosPesados = {}

      dadosVeiculos.forEach((item) => {
        if (item.categoria_veiculo === 1) {
          auxObjTiposVeiculosLeves[item.tipo] = false
        }

        if (item.categoria_veiculo === 2) {
          auxObjTiposVeiculosMedio[item.tipo] = false
        }

        if (item.categoria_veiculo === 3) {
          auxObjTiposVeiculosPesados[item.tipo] = false
        }
      })

      tipos.forEach((item) => {
        for (let prop in auxObjTiposVeiculosLeves) {
          if (prop === item.tipo) {
            auxObjTiposVeiculosLeves[prop] = true
          }
        }

        for (let prop in auxObjTiposVeiculosMedio) {
          if (prop === item.tipo) {
            auxObjTiposVeiculosMedio[prop] = true
          }
        }

        for (let prop in auxObjTiposVeiculosPesados) {
          if (prop === item.tipo) {
            auxObjTiposVeiculosPesados[prop] = true
          }
        }
      });

      setTodosVeiculos(dadosVeiculos)

      return {
        tipos_veiculo_leve: auxObjTiposVeiculosLeves,
        tipos_veiculo_medio: auxObjTiposVeiculosMedio,
        tipos_veiculo_pesado: auxObjTiposVeiculosPesados,
      }
    }

    const getTiposCarroceria = async (tipos) => {
      let dadosCarrocerias = []
      try {
        const { data } = await api.get(`tipocarroceria/`);
        dadosCarrocerias = data.results;
      } catch { };
      let auxObjTiposCarroceriasFechadas = {}
      let auxObjTiposCarroceriasAbertas = {}
      let auxObjTiposCarroceriasEspecial = {}

      dadosCarrocerias.forEach((item) => {
        if (item.categoria_carroceria === 1) {
          auxObjTiposCarroceriasFechadas[item.tipo] = false
        }

        if (item.categoria_carroceria === 2) {
          auxObjTiposCarroceriasAbertas[item.tipo] = false
        }

        if (item.categoria_carroceria === 3) {
          auxObjTiposCarroceriasEspecial[item.tipo] = false
        }
      });

      tipos.forEach((item) => {
        for (let prop in auxObjTiposCarroceriasFechadas) {
          if (prop === item.tipo) {
            auxObjTiposCarroceriasFechadas[prop] = true
          }
        }

        for (let prop in auxObjTiposCarroceriasAbertas) {
          if (prop === item.tipo) {
            auxObjTiposCarroceriasAbertas[prop] = true
          }
        }

        for (let prop in auxObjTiposCarroceriasEspecial) {
          if (prop === item.tipo) {
            auxObjTiposCarroceriasEspecial[prop] = true
          }
        }
      })

      setTodasCarrocerias(dadosCarrocerias)
      return {
        tipos_carroceria_fechada: auxObjTiposCarroceriasFechadas,
        tipos_carroceria_aberta: auxObjTiposCarroceriasAbertas,
        tipos_carroceria_especial: auxObjTiposCarroceriasEspecial,
      };
    }

    const getTipoContaFrete = async (conta_frete) => {
      try {
        const response = await api.get(`tipocontafrete/?criado_por=${idUsuario}`)
        setTiposContaFrete(response.data.results);
        return { conta_frete, nome_conta_frete: conta_frete.descricao };
      } catch (err) {
        return { conta_frete, nome_conta_frete: conta_frete.descricao };
      }
    };

    const getProduto = async (produto) => {
      try {
        const response = await api.get(`produtocarga/?criado_por=${idUsuario}`);
        setProdutos(response.data.results);
        return { produto, nome_produto: produto.produto };
      } catch {
        return { produto, nome_produto: produto.produto };
      }
    };

    const getEspecieProduto = async (especie_produto) => {
      try {
        const response = await api.get(`especieproduto/?criado_por=${idUsuario}`)
        setEspeciesProdutos(response.data.results);
        return { especie_produto, nome_especie_produto: especie_produto.especie };
      } catch {
        return { especie_produto, nome_especie_produto: especie_produto.especie };
      }
    };

    const obtemNomeMotorista = async (idMotorista) => {
      if (!idMotorista) {
        return '';
      }
      try {
        const response = api.get(`motoristas/all/${idMotorista}/`);
        return response.data.nome_completo;
      } catch {
        return '';
      }
    };

    const fetchCarga = async () => {
      setIsLoadingCarga(true);
      try {
        const { data: dadosCarga } = await api.get(`cargas/${idCarga}/`);

        let escolherGrupo = false;
        if (dadosCarga.motorista !== null) {
          escolherGrupo = false;
        }
        if (dadosCarga.grupo !== null) {
          escolherGrupo = true;
        }

        const coletas = dadosCarga.coletas.reduce((prev, item) => ({
          ...prev,
          [item.coleta.id]: {
            active: true,
            id: item.coleta.id,
            status: item.coleta.status,
            nfe: item.coleta.nfe.numero,
            cte: item.coleta.cte?.numero,
            cliente: item.coleta.nfe.modalidade_frete === 'CIF' ? item.coleta.nfe.remetente_razao_social : item.coleta.nfe.destinatario_razao_social,
            destino: `${item.coleta.nfe.destinatario_endereco.nome_municipio} - ${item.coleta.nfe.destinatario_endereco.uf}`,
          },
        }), {});

        const data = {
          ...form.initialValues,
          pedidos: dadosCarga.pedidos.map((pedido) => ({
            id: pedido.numero.toString(),
            text: pedido.numero,
          })),
          is_fracionada: dadosCarga.is_fracionada,
          ...(await getTipoContaFrete(dadosCarga.conta_frete)),
          ...(await getProduto(dadosCarga.produto)),
          ...(await getEspecieProduto(dadosCarga.especie_produto)),
          is_rastreada: dadosCarga.is_rastreada,
          origem_uf: {
            label: dadosCarga.origem.endereco.uf,
            value: dadosCarga.origem.endereco.uf,
            codigo: dadosCarga.origem.endereco.uf_codigo,
          },
          origem_municipio: {
            label: dadosCarga.origem.endereco.municipio,
            value: dadosCarga.origem.endereco.municipio,
            codigo: dadosCarga.origem.endereco.municipio_codigo,
          },
          origem_texto: `${dadosCarga.origem.empresa} - ${dadosCarga.origem.endereco?.municipio} - ${dadosCarga.origem.endereco?.uf}`,
          pontos_parada: dadosCarga.pontos_parada.map((ponto) => ({
            uf: {
              value: ponto.destino.endereco.uf,
              label: ponto.destino.endereco.uf,
              codigo: ponto.destino.endereco.uf_codigo,
            },
            municipio: {
              value: ponto.destino.endereco.municipio,
              label: ponto.destino.endereco.municipio,
              codigo: ponto.destino.endereco.municipio_codigo,
            }
          })),

          quantidade_volume: dadosCarga.quantidade_volume,
          peso_unitario: dadosCarga.peso_unitario,
          volume_unitario: dadosCarga.volume_unitario,
          data_embarque: dadosCarga.data_embarque,
          data_descarga: dadosCarga.data_descarga,

          ...(await getTiposVeiculos(dadosCarga.tipos_veiculo)),
          ...(await getTiposCarroceria(dadosCarga.tipos_carroceria)),

          transportadora: dadosCarga.transportadora?.id || null,
          transportadora_nome: dadosCarga.transportadora?.razao_social || '',

          proposta_inicial: String(dadosCarga.proposta_inicial).replace('.', ','),
          proposta_tipo: dadosCarga.proposta_tipo,
          porcentagem_adiantamento: dadosCarga.porcentagem_adiantamento || '',
          is_lotacao_veiculo: dadosCarga.is_lotacao_veiculo,
          is_livre_carga_descarga: dadosCarga.is_livre_carga_descarga,
          is_publica: dadosCarga.is_publica,
          observacoes: dadosCarga.observacoes,
          data_fechamento: dadosCarga.data_fechamento,

          escolher_grupo_motorista: escolherGrupo,

          grupo: dadosCarga.grupo || '',

          motorista: dadosCarga.motorista,
          motorista_nome: await obtemNomeMotorista(dadosCarga.motorista),

          coletas,
        }

        form.setValues(data);
      } catch (err) {
        console.log(err);
      }
      setIsLoadingCarga(false);
    };

    fetchCarga();
  }, [idCarga]);

  const handleConfirm = () => {
    const removerMascaraCampo = (dado) => {
      if (dado) {
        return dado.toString().replace(',', '').replace('.', '')
      }
    }

    let dadosCarga = {
      ...form.values,
      conta_frete: form.values.conta_frete.id,
      especie_produto: form.values.especie_produto.id,
      pedidos: form.values.pedidos.map((item) => ({
        numero: item.text,
      })),
      produto: form.values.produto.id,
      custom_origem: {
        'uf': form.values.origem_uf.value,
        'uf_codigo': form.values.origem_uf.codigo,
        'municipio': form.values.origem_municipio.value,
        'municipio_codigo': form.values.origem_municipio.codigo,
      },
      pontos_parada: form.values.pontos_parada.map((item) => ({
        'uf': item.uf.value,
        'uf_codigo': item.uf.codigo,
        'municipio': item.municipio.value,
        'municipio_codigo': item.municipio.codigo,
      })),
      proposta_inicial: form.values.proposta_inicial ? form.values.proposta_inicial.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }) : null,
      transportadora:
        tipoUsuario === "transportadora" ||
          tipoUsuario === "colaborador_transportadora"
          ? dadosUsuario.transportadora_id
          : form.values.transportadora,
      embarcador:
        tipoUsuario === "embarcador" || tipoUsuario === "colaborador_embarcador"
          ? dadosUsuario.embarcador_id
          : "",

      volume_unitario: Number(removerMascaraCampo(form.values.volume_unitario)),
      quantidade_volume: Number(removerMascaraCampo(form.values.quantidade_volume)),
      peso_unitario: Number(removerMascaraCampo(form.values.peso_unitario)),

      motorista: form.values.motorista,
      grupo: form.values.grupo,
    }
    delete dadosCarga.origem_texto;
    delete dadosCarga.tipos_carroceria_aberta;
    delete dadosCarga.tipos_carroceria_especial;
    delete dadosCarga.tipos_carroceria_fechada;
    delete dadosCarga.tipos_veiculo_leve;
    delete dadosCarga.tipos_veiculo_medio;
    delete dadosCarga.tipos_veiculo_pesado;
    delete dadosCarga.transportadora_nome;
    delete dadosCarga.escolher_grupo_motorista;

    if (dadosCarga.is_fracionada) {
      dadosCarga.coletas = Object
        .keys(dadosCarga.coletas)
        .map((item) => dadosCarga.coletas[item])
        .sort((a, b) => {
          if (a.order === undefined || a.order === null) {
            return 1;
          }
          if (b.order === undefined || b.order === null) {
            return -1;
          }
          return a.order - b.order;
        })
        .map((item) => item.id);
      
      dadosCarga.custom_origem = null;
      dadosCarga.pontos_parada = [];
    } else {
      dadosCarga.coletas = [];
    }

    if (idCarga) {
      dadosCarga = {
        ...dadosCarga,

        close_notified: false,
        status_temporal: "no-prazo",
      };

      api
        .put(`cargas/${idCarga}/`, dadosCarga)
        .then(() => {
          atualizarCargas();
          fecharModalSemAlert();
          fecharModalDadosCarga();
        })
        .catch(function (error) {
          console.log(error);
          console.log("ERRO", error.response);
        });

      return;
    }

    let promises = [];
    for (let i = 0; i < quantidadeCargas; i++) {
      promises.push(
        api
          .post(`cargas/`, dadosCarga)
          .then((response) => {
            console.log(response.data);
          })
          .catch(function (error) {
            console.log(error.response.data);
          })
      );
    }

    Promise.all(promises).then(() => {
      atualizarCargas();
      fecharModalSemAlert();
    });
  };

  const handleSetTiposContaFrete = useCallback((suggestions) => {
    setTiposContaFrete(suggestions)
  }, [])

  const handleSetProdutos = useCallback((suggestions) => {
    setProdutos(suggestions)
  }, [])

  const handleSetEspeciesProdutos = useCallback((suggestions) => {
    setEspeciesProdutos(suggestions)
  }, [])

  return (
    <Modal
      isOpen={abrirModalNovaCarga}
      onRequestClose={fecharModal}
      className="modal-adicionar-carga"
      overlayClassName="overlay-modal-adicionar-carga"
      ariaHideApp={false}
    >

      <div className="titulo">
        <div className="detalhes">
          <p>{idCarga ? "Alterar Carga" : "Nova Carga"}</p>
          {idCarga &&
            <div>
              <FaIdCard className="icone id"></FaIdCard>
              <p>ID: <span>{idCarga}</span></p>
            </div>
          }
        </div>
        <RiCloseCircleFill className="icone fechar" onClick={fecharModal}></RiCloseCircleFill>
      </div>

      <div className="conteudo">
        {isLoadingCarga && (
          <div className="is-loading-conteudo">
            Carregando...
          </div>
        )}
        <StepWizard nav={<NavWizard />} transitions={"none"} isLazyMount>
          <Etapa1
            destinatarios={destinatarios}
            form={form}
            quantidadeCargas={quantidadeCargas}
            setQuantidadeCargas={setQuantidadeCargas}
            tiposContaFrete={tiposContaFrete}
            produtos={produtos}
            especiesProdutos={especiesProdutos}
            handleLazyLoading={handleLazyLoading}
            lazyLoading={lazyLoading}
            idCarga={idCarga}
            setTiposContaFrete={handleSetTiposContaFrete}
            setProdutos={handleSetProdutos}
            setEspeciesProdutos={handleSetEspeciesProdutos}
          />
          <Etapa2
            form={form}
            todosVeiculos={todosVeiculos}
            todasCarrocerias={todasCarrocerias}
          />
          <Etapa3
            idCarga={idCarga}
            fecharModal={fecharModal}
            onConfirm={handleConfirm}
            form={form}
          />
        </StepWizard>
      </div>
    </Modal>
  )
}

export default ModalNovaCarga;
