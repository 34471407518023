import React, { memo, useState, useRef, useCallback, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { MdClear } from "react-icons/md";
import { FaRegCalendarCheck } from "react-icons/fa";
import { parseISO, format } from "date-fns";
import ReactPaginate from "react-paginate";
import { useHistory } from 'react-router-dom';

import "./styles.css";

import BreadCrumbs from "../../../../components/plataformaAcessada/BreadCrumbs";
import TabelaCtes from "./TabelaCtes";
import TabelaMDFes from "./TabelaMDFes";
import GerarDocumentoAvulso from "./GerarDocumentoAvulso";

import api from "../../../../services/api";

const DocumentosGerais = () => {
  const history = useHistory();

  const routes = [
    { path: "/acesso", breadcrumb: "Início" },
    {
      path: "/acesso/painel-monitoramento",
      breadcrumb: "Painel Monitoramento",
    },
    {
      path: "/acesso/painel-monitoramento/documentos",
      breadcrumb: "Documentos",
    },
  ];

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
  const idUsuario = dadosUsuario.id;
  const idTransportadora = dadosUsuario.transportadora_id;

  const [ufs, setUfs] = useState([]);

  const [ctes, setCtes] = useState([]);
  const [mdfes, setMdfes] = useState([]);
  const [loadingCtes, setLoadingCtes] = useState(true);
  const [loadingMdfes, setLoadingMdfes] = useState(true);
  const [quantidadePaginasCte, setQuantidadePaginasCte] = useState(1);
  const [quantidadePaginasMdfe, setQuantidadePaginasMdfe] = useState(1);
  const [paginaAtualCte, setPaginaAtualCte] = useState(0);
  const [paginaAtualMdfe, setPaginaAtualMdfe] = useState(0);

  const [tipoDocumento, setTipoDocumento] = useState("cte");
  const [mostrarApenasCteMeus, setMostrarApenasCteMeus] = useState(false);

  const [mostrarApenasMdfesMeus, setMostrarApenasMdfesMeus] = useState(false);

  const numero = useRef("");
  const idCarga = useRef("");
  const dataInicio = useRef("");
  const dataFinal = useRef("");
  const status = useRef("");
  const remetente = useRef("");
  const destinatario = useRef("");
  const cliente = useRef("");
  const motorista = useRef("");
  const planilha = useRef("");
  const apenasAverbadas = useRef("");
  const apenasAvulso = useRef("");
  const apenasUfDestino = useRef("");
  const ordenacao = useRef("");
  const serie = useRef("");

  const [idEditarCte, setIdEditarCte] = useState(null);
  const [tipoCtePadrao, setTipoCtePadrao] = useState('0');

  const formatarDatas = (formato, data) => {
    if (data.length > 9) {
      if (formato === "gte") {
        return format(parseISO(data), "yyyy-MM-dd'T'00:00:00");
      } else {
        return format(parseISO(data), "yyyy-MM-dd'T'23:59:59");
      }
    }

    return "";
  };

  const requisitaCtes = useCallback(
    (paginaSelecionada, offSet) => {
      setLoadingCtes(true);
      setPaginaAtualCte(paginaSelecionada);

      console.log("ordenacao", ordenacao.current.value);

      api
        .get(
          `documentos_fiscais/cte/?emissao__gte=${formatarDatas(
            "gte",
            dataInicio.current.value
          )}&emissao__lte=${formatarDatas(
            "lte",
            dataFinal.current.value
          )}&carga__isnull=${apenasAvulso.current.value === 'true' ? 'true' : ''}&status=${
            status.current.value
          }&remetente_razao_social__unaccent__icontains=${
            remetente.current.value
          }&destinatario_razao_social__unaccent__icontains=${
            destinatario.current.value
          }&carga__motorista_em_viagem__nome_completo__unaccent__icontains=${
            motorista.current.value
          }&serie=${serie.current.value}&destino=${
            apenasUfDestino.current.value
          }&numero=${
            numero.current.value
          }&averbado=${apenasAverbadas.current.value}&usuario_emissao=${
            mostrarApenasCteMeus ? idUsuario : ""
          }&offset=${offSet ? offSet : 0}&ordering=${ordenacao.current.value}`
        )
        .then(function (response) {
          setQuantidadePaginasCte(Math.ceil(response.data.count / 15));
          setCtes(response.data.results);
          setLoadingCtes(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    [mostrarApenasCteMeus, idUsuario]
  );

  const requisitaMdfes = useCallback(
    (paginaSelecionada, offSet) => {
      setLoadingMdfes(true);
      setPaginaAtualMdfe(paginaSelecionada);

      console.log("ordenacao", ordenacao.current.value);

      api
        .get(
          `documentos_fiscais/mdfe/?status=${
            status.current.value
          }&emissao__gte=${formatarDatas(
            "gte",
            dataInicio.current.value
          )}&emissao__lte=${formatarDatas(
            "lte",
            dataFinal.current.value
          )}&carga__motorista_em_viagem__nome_completo__unaccent__icontains=${
            motorista.current.value
          }&serie=${serie.current.value}&uf_descarregamento=${
            apenasUfDestino.current.value
          }&numero=${
            numero.current.value
          }&usuario_emissao=${mostrarApenasMdfesMeus ? idUsuario : ""}&offset=${
            offSet ? offSet : 0
          }&ordering=${ordenacao.current.value}`
        )
        .then(function (response) {
          setQuantidadePaginasMdfe(Math.ceil(response.data.count / 15));
          setMdfes(response.data.results);
          setLoadingMdfes(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    [mostrarApenasMdfesMeus, idUsuario]
  );

  const exportarRelatorioCte = async () => {
    api
      .post("/documentos_fiscais/relatorio/ctes/token/")
      .then((response) => {
        const { token } = response.data;
        window.open(
          `${process.env.REACT_APP_LINK_API}relatorio/ctes/download/${token}/?transportadora=${idTransportadora}&emissao_inicial=${dataInicio.current.value}&emissao_final=${dataFinal.current.value}&serie=${serie.current.value}`
        );
      }).catch((err) => console.log(err));
  };

  const exportarPlanilhas = async () => {
    const requisitarPlanilha = (tipo) => {
      api
        .post("omie/auth/")
        .then(function (response) {
          let token = response.data.code;

          if (token) {
            window.open(
              `${process.env.REACT_APP_LINK_API}omie/planilha/${tipo}/${token}/?transportadora=${idTransportadora}&carga=${idCarga.current.value}&cliente=${cliente.current.value}&motorista=${motorista.current.value}&status=${status.current.value}&date_start=${dataInicio.current.value}&date_end=${dataFinal.current.value}`
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    switch (planilha.current.value) {
      case "cliente_fornecedores":
        await requisitarPlanilha("pessoas");
        break;
      case "pagar":
        await requisitarPlanilha("pagar");
        break;
      case "receber":
        await requisitarPlanilha("receber");
        break;
      default:
        await requisitarPlanilha("pessoas");
        await requisitarPlanilha("receber");
        await requisitarPlanilha("pagar");
        break;
    }
  };

  useEffect(() => {
    requisitaCtes();
    requisitaMdfes();
  }, [requisitaCtes, requisitaMdfes]);

  useEffect(() => {
    fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((res) => {
        return res.json();
      })
      .then((ufs) => {
        setUfs(ufs);
      });
  }, []);

  const editarCte = (id, substituir, complementar, novo) => {
    if (!substituir && !complementar && !novo) {
      setTipoCtePadrao('0');
      setIdEditarCte(id);
      return;
    }
    
    if (substituir) {
      setTipoCtePadrao('3');
      setIdEditarCte(id);
    }
    if (complementar) {
      setTipoCtePadrao('1');
      setIdEditarCte(id);
    }
    if (novo) {
      setTipoCtePadrao('-1');
      setIdEditarCte(id);
      return;
    }
  }

  return (
    <div className="container-documentos-gerais">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <div className="quadro">
        <div className="container-radio-tipo-documento">
          <div className="container-input-radio">
            <span className="radio" onClick={() => setTipoDocumento("cte")}>
              <input
                type="radio"
                value="cte"
                checked={tipoDocumento === "cte"}
                onChange={() => setTipoDocumento("cte")}
              />
              <span className="checked"></span>
              CT-e
            </span>
          </div>

          <div className="container-input-radio">
            <span className="radio" onClick={() => setTipoDocumento("mdfe")}>
              <input
                type="radio"
                value={"mdfe"}
                checked={tipoDocumento === "mdfe"}
                onChange={() => setTipoDocumento("mdfe")}
              />
              <span className="checked"></span>
              MDF-e
            </span>
          </div>

          <div className="container-input-radio">
            <span
              className="radio"
              onClick={() => setTipoDocumento("financeiro")}
            >
              <input
                type="radio"
                value={"financeiro"}
                checked={tipoDocumento === "financeiro"}
                onChange={() => setTipoDocumento("financeiro")}
              />
              <span className="checked"></span>
              Financeiro
            </span>
          </div>

          <div className="container-input-radio">
            <span
              className="radio"
              onClick={() => setTipoDocumento("relatorio-ctes")}
            >
              <input
                type="radio"
                value={"relatorio-ctes"}
                checked={tipoDocumento === "relatorio-ctes"}
                onChange={() => setTipoDocumento("relatorio-ctes")}
              />
              <span className="checked"></span>
              Relatório de CT-e's
            </span>
          </div>
        </div>

        {tipoDocumento === "cte" && (
          <GerarDocumentoAvulso
            idCte={idEditarCte}
            tipoCtePadrao={tipoCtePadrao}
            clearIdCte={() => setIdEditarCte(null)}
            requisitaCtes={() => requisitaCtes(1, 0)}
            otherActions={
              <button className="btn-documento-avulso" onClick={() => history.push('/acesso/painel-monitoramento/documentos/ctes')}>
                Baixar Arquivos em Lote
              </button>
            }
          />
        )}

        <div className="container-filtros">
          {["financeiro"].includes(tipoDocumento) && (
            <div className="input-filtro">
              <BsSearch className="icone-filtro"></BsSearch>
              <input type="text" placeholder="ID Carga" ref={idCarga} />
              <MdClear
                onClick={() => (idCarga.current.value = "")}
                className="icone-limpar"
              ></MdClear>
            </div>
          )}

          {!["relatorio-ctes", "financeiro"].includes(tipoDocumento) && (
            <div className="input-filtro">
              <input type="number" placeholder="Número" ref={numero} />
              <MdClear
                onClick={() => (numero.current.value = "")}
                className="icone-limpar"
              ></MdClear>
            </div>
          )}

          <div className="input-filtro">
            <FaRegCalendarCheck className="icone-filtro"></FaRegCalendarCheck>
            <input
              onFocus={() => (dataInicio.current.type = "date")}
              onBlur={() => {
                dataInicio.current.value === "" &&
                  (dataInicio.current.type = "text");
              }}
              placeholder={`"Data Emissão ${
                tipoDocumento === "cte"
                  ? "CT-e"
                  : tipoDocumento === "mdfe"
                  ? "MDF-e"
                  : ""
              } | De"`}
              ref={dataInicio}
            />
            <MdClear
              onClick={() => (dataInicio.current.value = "")}
              className="icone-limpar"
            ></MdClear>
          </div>

          <div className="input-filtro">
            <FaRegCalendarCheck className="icone-filtro"></FaRegCalendarCheck>
            <input
              onFocus={() => (dataFinal.current.type = "date")}
              onBlur={() => {
                dataFinal.current.value === "" &&
                  (dataFinal.current.type = "text");
              }}
              placeholder={`"Data Emissão ${
                tipoDocumento === "cte"
                  ? "CT-e"
                  : tipoDocumento === "mdfe"
                  ? "MDF-e"
                  : ""
              } | Até"`}
              ref={dataFinal}
            />
            <MdClear
              className="icone-limpar"
              onClick={() => (dataFinal.current.value = "")}
            ></MdClear>
          </div>

          {tipoDocumento !== "relatorio-ctes" && (
            <div className="input-filtro">
              <select ref={status}>
                <option value="">Status</option>
                {tipoDocumento === "financeiro" ? (
                  <>
                    <option value="andamento">Andamento</option>
                    <option value="finalizada">Finalizadas</option>
                  </>
                ) : (
                  <>
                    <option value="Pendente">Pendente</option>
                    <option value="Enviado">Enviado</option>
                    <option value="Confirmado">Confirmado</option>
                    <option value="Negado">Negado</option>
                    <option value="Cancelado">Cancelado</option>
                    <option value="FS-DA">FS-DA</option>
                  </>
                )}
              </select>
            </div>
          )}

          {tipoDocumento === "cte" && (
            <>
              <div className="input-filtro">
                <BsSearch className="icone-filtro"></BsSearch>
                <input type="text" placeholder="Remetente" ref={remetente} />
                <MdClear
                  onClick={() => (remetente.current.value = "")}
                  className="icone-limpar"
                ></MdClear>
              </div>

              <div className="input-filtro">
                <BsSearch className="icone-filtro"></BsSearch>
                <input
                  type="text"
                  placeholder="Destinatário"
                  ref={destinatario}
                />
                <MdClear
                  onClick={() => (destinatario.current.value = "")}
                  className="icone-limpar"
                ></MdClear>
              </div>
            </>
          )}

          {tipoDocumento === "financeiro" && (
            <div className="input-filtro">
              <BsSearch className="icone-filtro"></BsSearch>
              <input type="text" placeholder="Cliente" ref={cliente} />
              <MdClear
                onClick={() => (cliente.current.value = "")}
                className="icone-limpar"
              ></MdClear>
            </div>
          )}

          {tipoDocumento !== "relatorio-ctes" && (
            <div className="input-filtro">
              <BsSearch className="icone-filtro"></BsSearch>
              <input type="text" placeholder="Motorista" ref={motorista} />
              <MdClear
                onClick={() => (motorista.current.value = "")}
                className="icone-limpar"
              ></MdClear>
            </div>
          )}

          {tipoDocumento === "financeiro" && (
            <div className="input-filtro">
              <select ref={planilha}>
                <option value="">Planilhas</option>
                <option value="cliente_fornecedores">
                  Dados Clientes e Fornecedores
                </option>
                <option value="pagar">Contas a Pagar</option>
                <option value="receber">Contas a Receber</option>
                <option value="todas">Todas</option>
              </select>
            </div>
          )}

          {tipoDocumento !== "financeiro" && (
            <>
              {tipoDocumento !== "relatorio-ctes" && (
                <>
                  <div className="input-filtro">
                    <select ref={apenasAverbadas}>
                      <option value="">Mostrar apenas averbadas</option>
                      <option value={true}>Sim</option>
                      <option value={false}>Não</option>
                    </select>
                  </div>

                  <div className="input-filtro">
                    <select ref={apenasAvulso}>
                      <option value="">Mostrar apenas avulsos</option>
                      <option value={true}>Sim</option>
                      <option value={false}>Não</option>
                    </select>
                  </div>

                  <div className="input-filtro">
                    <select ref={apenasUfDestino}>
                      <option value="">
                        UF Destino do {tipoDocumento === "cte" ? "CT-e" : "MDF-e"}
                      </option>
                      {ufs.map((uf) => (
                        <option key={uf.id} value={uf.sigla}>
                          {uf.sigla}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="input-filtro">
                    <select ref={ordenacao}>
                      <option value="">Ordenação</option>
                      <option value="emissao">Menor Data</option>
                      <option value="-emissao">Maior Data</option>
                      <option
                        value={
                          tipoDocumento === "cte" ? "valor_cte" : "valor_carga"
                        }
                      >
                        Menor Valor
                      </option>
                      <option
                        value={
                          tipoDocumento === "cte" ? "-valor_cte" : "-valor_carga"
                        }
                      >
                        Maior Valor
                      </option>
                    </select>
                  </div>
                </>
              )}

              <div className="input-filtro">
                <input type="number" placeholder="Série" ref={serie} />
                <MdClear
                  onClick={() => (serie.current.value = "")}
                  className="icone-limpar"
                ></MdClear>
              </div>
            </>
          )}

          <div
            className={`${["financeiro", "relatorio-ctes"].includes(tipoDocumento)
                ? "container-rodape-financeiro"
                : "container-rodape-filtros"
            }`}
          >
            <div className="container-checkboxes">
              {tipoDocumento === "cte" && (
                <>
                  <div className="container-input-checkbox">
                    <span
                      className="checkbox"
                      onClick={() =>
                        setMostrarApenasCteMeus(!mostrarApenasCteMeus)
                      }
                    >
                      <input
                        type="checkbox"
                        checked={mostrarApenasCteMeus}
                        onChange={() =>
                          setMostrarApenasCteMeus(!mostrarApenasCteMeus)
                        }
                      />
                      <span className="checked"></span>
                      <div className="label">
                        <p>Mostrar apenas CT-e(s) criadas por mim</p>
                      </div>
                    </span>
                  </div>
                </>
              )}

              {tipoDocumento === "mdfe" && (
                <>
                  <div className="container-input-checkbox">
                    <span
                      className="checkbox"
                      onClick={() =>
                        setMostrarApenasMdfesMeus(!mostrarApenasMdfesMeus)
                      }
                    >
                      <input
                        type="checkbox"
                        checked={mostrarApenasMdfesMeus}
                        onChange={() =>
                          setMostrarApenasMdfesMeus(!mostrarApenasMdfesMeus)
                        }
                      />
                      <span className="checked"></span>
                      <div className="label">
                        <p>Mostrar apenas MDF-e(s) criadas por mim</p>
                      </div>
                    </span>
                  </div>
                </>
              )}
            </div>

            {tipoDocumento === "financeiro" && (
              <button className="btn-buscar" onClick={exportarPlanilhas}>
                Exportar Planilha
              </button>
            )}

            {tipoDocumento === "relatorio-ctes" && (
              <button className="btn-buscar" onClick={exportarRelatorioCte}>
                Exportar Relatório
              </button>
            )}

            {!["financeiro", "relatorio-ctes"].includes(tipoDocumento) && (
              <button
                className="btn-buscar"
                onClick={
                  tipoDocumento === "cte"
                    ? () => requisitaCtes(0)
                    : () => requisitaMdfes(0)
                }
              >
                Buscar {tipoDocumento === "cte" ? "CT-e(s)" : "MDF-e(s)"}
              </button>
            )}
          </div>
        </div>

        {!["financeiro", "relatorio-ctes"].includes(tipoDocumento) && (
          <>
            {tipoDocumento === "cte" ? (
              <TabelaCtes
                requisitaCtes={requisitaCtes}
                ctes={ctes}
                loadingCtes={loadingCtes}
                handleEditarCte={editarCte}
              ></TabelaCtes>
            ) : (
              <TabelaMDFes
                requisitaMdfes={requisitaMdfes}
                mdfes={mdfes}
                loadingMdfes={loadingMdfes}
              ></TabelaMDFes>
            )}
          </>
        )}
      </div>

      {!["financeiro", "relatorio-ctes"].includes(tipoDocumento) && (
        <div className="paginacao-documentos-gerais">
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próxima"}
            breakLabel={"..."}
            pageCount={
              tipoDocumento === "cte"
                ? quantidadePaginasCte
                : quantidadePaginasMdfe
            }
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={
              tipoDocumento === "cte" ? paginaAtualCte : paginaAtualMdfe
            }
            onPageChange={(e) => {
              tipoDocumento === "cte"
                ? requisitaCtes(e.selected, e.selected * 15)
                : requisitaMdfes(e.selected, e.selected * 15);
            }}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      )}
    </div>
  );
};

export default memo(DocumentosGerais);
