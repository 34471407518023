export const  ufs = [
  {
    label: 'RO',
    value: 'RO',
    codigo: '11',
  },
  {
    label: 'AC',
    value: 'AC',
    codigo: '12',
  },
  {
    label: 'AM',
    value: 'AM',
    codigo: '13',
  },
  {
    label: 'RR',
    value: 'RR',
    codigo: '14',
  },
  {
    label: 'PA',
    value: 'PA',
    codigo: '15',
  },
  {
    label: 'AP',
    value: 'AP',
    codigo: '16',
  },
  {
    label: 'TO',
    value: 'TO',
    codigo: '17',
  },
  {
    label: 'MA',
    value: 'MA',
    codigo: '21',
  },
  {
    label: 'PI',
    value: 'PI',
    codigo: '22',
  },
  {
    label: 'CE',
    value: 'CE',
    codigo: '23',
  },
  {
    label: 'RN',
    value: 'RN',
    codigo: '24',
  },
  {
    label: 'PB',
    value: 'PB',
    codigo: '25',
  },
  {
    label: 'PE',
    value: 'PE',
    codigo: '26',
  },
  {
    label: 'AL',
    value: 'AL',
    codigo: '27',
  },
  {
    label: 'SE',
    value: 'SE',
    codigo: '28',
  },
  {
    label: 'BA',
    value: 'BA',
    codigo: '29',
  },
  {
    label: 'MG',
    value: 'MG',
    codigo: '31',
  },
  {
    label: 'ES',
    value: 'ES',
    codigo: '32',
  },
  {
    label: 'RJ',
    value: 'RJ',
    codigo: '33',
  },
  {
    label: 'SP',
    value: 'SP',
    codigo: '35',
  },
  {
    label: 'PR',
    value: 'PR',
    codigo: '41',
  },
  {
    label: 'SC',
    value: 'SC',
    codigo: '42',
  },
  {
    label: 'RS',
    value: 'RS',
    codigo: '43',
  },
  {
    label: 'MS',
    value: 'MS',
    codigo: '50',
  },
  {
    label: 'MT',
    value: 'MT',
    codigo: '51',
  },
  {
    label: 'GO',
    value: 'GO',
    codigo: '52',
  },
  {
    label: 'DF',
    value: 'DF',
    codigo: '53',
  }
]
