import React, { memo, useRef, useState, useCallback, useEffect } from "react";
import { FaRegCalendarAlt, FaRegCalendarCheck } from "react-icons/fa";
import { parseISO, format } from "date-fns";

import "./styles.css";

import BreadCrumbs from "../../../../components/plataformaAcessada/BreadCrumbs";
import ModalHistorico from "./Components/ModalHistorico";

import api from "../../../../services/api";

const HistoricoTransacoes = () => {
  const routes = [
    { path: "/acesso", breadcrumb: "Início" },
    {
      path: "/acesso/historico-transacoes",
      breadcrumb: "Histórico De Transações",
    },
  ];

  const [modalHistorico, setModalHistorico] = useState(false);
  const [historicoSelecionado, setHistoricoSelecionado] = useState(null);
  const [gastos, setGastos] = useState([]);
  const [compras, setCompras] = useState([]);

  const [dataInicialGastos, setDataInicialGastos] = useState("");
  const [dataFinalGastos, setDataFinalGastos] = useState("");
  const [dataInicialCompras, setDataInicialCompras] = useState("");
  const [dataFinalCompras, setDataFinalCompras] = useState("");
  const inputDataInicialGastos = useRef(null);
  const inputDataFinalGastos = useRef(null);
  const inputDataInicialCompras = useRef(null);
  const inputDataFinalCompras = useRef(null);

  const handleModalHistorico = useCallback(
    (historicoSelecionado) => {
      if (modalHistorico) {
        setHistoricoSelecionado(null);
        setModalHistorico(false);
        document.querySelector("body").style.overflow = "visible";
      } else {
        setHistoricoSelecionado(historicoSelecionado);
        setModalHistorico(true);
        document.querySelector("body").style.overflow = "hidden";
      }
    },
    [modalHistorico]
  );

  const formatarDatas = (data) => {
    if (data.length > 9) {
      return format(parseISO(data), "yyyy-MM-dd");
    }

    return "";
  };

  const requisitarTransacoes = useCallback(
    (tipo) => {
      api
        .get(
          `/pagamentos/transacoes/resumed/?tipo=${tipo}&data__gte=${formatarDatas(
            tipo === "saida" ? dataInicialGastos : dataInicialCompras
          )}&data__lte=${formatarDatas(
            tipo === "saida" ? dataFinalGastos : dataFinalCompras
          )}`
        )
        .then(function (response) {
          if (tipo === "saida") {
            setGastos(response.data.results);
          } else {
            setCompras(response.data.results);
          }
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    [dataInicialGastos, dataInicialCompras, dataFinalGastos, dataFinalCompras]
  );

  useEffect(() => {
    requisitarTransacoes("saida");
    requisitarTransacoes("entrada");
  }, [requisitarTransacoes]);

  return (
    <div className="container-historico-transacoes">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <div className="historico-transacoes">
        <div className="quadro-historico">
          <p className="titulo">Histórico de Gastos</p>
          <p className="subtitulo">
            Histórico de todos os gastos realizados na plataforma:
          </p>

          <div className="datas">
            <div className="container-input-date">
              <FaRegCalendarAlt className="icone"></FaRegCalendarAlt>
              <input
                ref={inputDataInicialGastos}
                type="text"
                onFocus={() => (inputDataInicialGastos.current.type = "date")}
                onBlur={() => {
                  dataInicialGastos === "" &&
                    (inputDataInicialGastos.current.type = "text");
                }}
                placeholder="Data Inicial"
                value={dataInicialGastos}
                onChange={(e) => setDataInicialGastos(e.target.value)}
              />
            </div>

            <div className="container-input-date">
              <FaRegCalendarCheck className="icone"></FaRegCalendarCheck>
              <input
                ref={inputDataFinalGastos}
                type="text"
                onFocus={() => (inputDataFinalGastos.current.type = "date")}
                onBlur={() => {
                  dataFinalGastos === "" &&
                    (inputDataFinalGastos.current.type = "text");
                }}
                placeholder="Data Final"
                value={dataFinalGastos}
                onChange={(e) => setDataFinalGastos(e.target.value)}
              />
            </div>
          </div>

          <div className="container-tabela-historico">
            <table>
              <thead>
                <tr>
                  <th>Quant. Serviço</th>
                  <th>Item</th>
                  <th>Coins Consumido</th>
                  <th>Data</th>
                </tr>
              </thead>

              <tbody>
                {gastos.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr className="divisoria-linhas-tabela"></tr>
                      <tr key={index}>
                        <td>{item.quantidade}</td>
                        <td>{item.origem}</td>
                        <td className="coluna-coins">
                          <span>{item.coins}</span>
                        </td>
                        <td>{format(parseISO(item.data), "dd/MM")}</td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>

          <button
            className="botao-detalhes"
            onClick={() => handleModalHistorico("saida")}
          >
            Ver Detalhes
          </button>
        </div>

        <div className="quadro-historico">
          <p className="titulo">Histórico de Compras</p>

          <p className="subtitulo">
            Histórico de todas as compras realizados na plataforma:
          </p>

          <div className="datas">
            <div className="container-input-date">
              <FaRegCalendarAlt className="icone"></FaRegCalendarAlt>
              <input
                ref={inputDataInicialCompras}
                type="text"
                onFocus={() => (inputDataInicialCompras.current.type = "date")}
                onBlur={() => {
                  dataInicialCompras === "" &&
                    (inputDataInicialCompras.current.type = "text");
                }}
                placeholder="Data Inicial"
                value={dataInicialCompras}
                onChange={(e) => setDataInicialCompras(e.target.value)}
              />
            </div>

            <div className="container-input-date">
              <FaRegCalendarCheck className="icone"></FaRegCalendarCheck>
              <input
                ref={inputDataFinalCompras}
                type="text"
                onFocus={() => (inputDataFinalCompras.current.type = "date")}
                onBlur={() => {
                  dataFinalCompras === "" &&
                    (inputDataFinalCompras.current.type = "text");
                }}
                placeholder="Data Final"
                value={dataFinalCompras}
                onChange={(e) => setDataFinalCompras(e.target.value)}
              />
            </div>
          </div>

          <hr className="separador" />

          <div className="container-tabela-historico">
            <table>
              <tbody>
                {compras.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr className="divisoria-linhas-tabela"></tr>
                      <tr key={index}>
                        <td className="coluna-coins">
                          <span>{item.coins}</span> RC Adquiridos
                        </td>

                        <td>{item.origem}</td>
                        <td>{format(parseISO(item.data), "dd/MM")}</td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>

          <button
            className="botao-detalhes"
            onClick={() => handleModalHistorico("entrada")}
          >
            Ver Detalhes
          </button>
        </div>
      </div>

      {modalHistorico && (
        <ModalHistorico
          historicoSelecionado={historicoSelecionado}
          modalHistorico={modalHistorico}
          handleModalHistorico={handleModalHistorico}
          tipo={historicoSelecionado}
        ></ModalHistorico>
      )}
    </div>
  );
};

export default memo(HistoricoTransacoes);
