import React, { memo, useState, useCallback } from 'react'

import './styles.css'

import portaria from "../../../../assets/portaria.jpg"

import ModalPerfilMotoristaCarga from '../../../../pages/plataformaAcessada/PainelMonitoramento/ModalPerfilMotoristaCarga'

import api from '../../../../services/api'

const NotificacaoMotoristaNaPortaria = ({ forceRender, carga, closeToast, idNotificacao }) => {
  const [modalPerfilMotoristaCarga, setModalPerfilMotoristaCarga] = useState(false)

  const registrarNotificacaoVisualizada = useCallback(async () => {
    await api.patch(`empresa_notificacoes/${idNotificacao}/`, {
      visualizado: true
    })
      .then(function () {
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idNotificacao])

  const handleModalPerfilMotoristaCarga = useCallback(() => {
    if (modalPerfilMotoristaCarga) {
      setModalPerfilMotoristaCarga(false)
      document.querySelector("body").style.overflow = "visible"
      closeToast()
    } else {
      registrarNotificacaoVisualizada()
      setModalPerfilMotoristaCarga(true)
      document.querySelector("body").style.overflow = "hidden"
    }
  }, [modalPerfilMotoristaCarga, closeToast, registrarNotificacaoVisualizada])

  return (
    <div className="notificacao-motorista-na-portaria">
      <img src={portaria} alt="" />

      <div className="conteudo">
        <p>
          Motorista {carga.motorista_em_viagem.nome_completo} chegou na PORTARIA para carregar a carga de ID {carga.id} para
          {carga.pontos_parada.map((item, index) => {
            return (
              index !== 0
                ? ` | ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
                : ` ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
            )
          })}.
        </p>

        <button onClick={handleModalPerfilMotoristaCarga}>Visualizar</button>
      </div>

      {modalPerfilMotoristaCarga &&
        <ModalPerfilMotoristaCarga
          modalPerfilMotoristaCarga={modalPerfilMotoristaCarga}
          handleModalPerfilMotoristaCarga={handleModalPerfilMotoristaCarga}
          idCarga={carga.id}
          moverCarga={forceRender}
          closeToast={closeToast}
        ></ModalPerfilMotoristaCarga>
      }
    </div>
  )
}

export default memo(NotificacaoMotoristaNaPortaria)