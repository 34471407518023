import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { useMessages } from '../../../context/messages-context';

export const useCreateOrUpdateClientTableMutation = () => {
  const { showApiErrors } = useMessages();
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation(async ({ id, data }) => {
    try {
      if (id) {
        await api.patch(`/fracionado/tabelas/${id}/`, data);
      } else {
        await api.post(`/fracionado/tabelas/`, data);
      }
    } catch (err) {
      if (err?.response?.status === 400 && err?.response?.data) {
        throw err?.response?.data;
      }
      showApiErrors(err);
      return null;
    }

    await queryClient.invalidateQueries(['clients-tables']);
    await queryClient.refetchQueries(['clients-tables', '', 1]);

    history.push({
      pathname: '/acesso/clientes/tabelas',
      search: `?cliente=${data.cliente}`,
    });
  });
}
