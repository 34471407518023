import InputMask from 'react-input-mask'

const DocumentoDono = ({
  idTipo,
  valueTipo,
  idDocumento,
  valueDocumento,
  onChange,
  disabled,
  className,
}) => {
  return (
    <>
      <div className="container-input">
        <select
          required
          value={valueTipo}
          id={idTipo}
          onChange={onChange}
          disabled={disabled}
          className={className}
        >
          <option value="" disabled></option>
          <option value="PF">Pessoa Física</option>
          <option value="PJ">Pessoa Jurídica</option>
        </select>
        <span>Tipo Dono Veículo <i>*</i></span>
      </div>

      <div className="container-input">
        <InputMask
          required
          mask={valueTipo === "PF" ? "999.999.999-99" : "99.999.999/9999-99"}
          maskChar=" "
          value={valueDocumento}
          id={idDocumento}
          onChange={onChange}
          disabled={disabled}
          className={className}
        />
        <span>CPF/CNPJ Dono <i>*</i></span>
      </div>
    </>
  );
}

export default DocumentoDono;
