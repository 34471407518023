import React, { memo, useRef, useState, useEffect, useCallback } from "react";
import { FaRegIdCard, FaFileUpload } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./styles.css";

import QuadroRegistros from "../QuadroRegistros";

import api from "../../../../../services/api";

const Imagens = ({ idCarga }) => {
  const [carga, setCarga] = useState(null);

  const anexoCarregado = useRef("");
  const anexoDescarregado = useRef("");
  const anexoCanhoto = useRef("");

  const requisitarCarga = useCallback(() => {
    api
      .get(`cargas_full/${idCarga}/`)
      .then(function (response) {
        setCarga(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idCarga]);

  const visualizarImagem = (imagem) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="container-visualizar-foto-carga">
            <img className="foto-carga-aberta" src={imagem} alt="" />

            <div className="container-btn-visualizar-foto-carga">
              <button className="btn-cancelar" onClick={onClose}>
                Fechar
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const removerImagem = (idImagem, tipoImagem) => {
    api
      .delete(
        tipoImagem === "canhoto"
          ? `cargas_canhoto/${idImagem}/`
          : `cargas_imagens/${idImagem}/`
      )
      .then(function () {
        requisitarCarga();
      })
      .catch(function (error) {
        console.log(error);

        confirmAlert({
          title: "Erro",
          message: `Erro ao remover imagem. Tente novamente`,
          overlayClassName: "alert-remover-imagem",
          buttons: [
            {
              label: "Ok",
              className: "botao-cancelar",
            },
          ],
        });
      });
  };

  const alertRemoverImagem = (idImagem, tipoImagem) => {
    confirmAlert({
      title: "Remover Imagem",
      message: `Deseja realmente remover esta imagem?`,
      overlayClassName: "alert-remover-imagem",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            removerImagem(idImagem, tipoImagem);
          },
          className: "botao-confirmar",
        },
        {
          label: "Não",
          onClick: () => {},
          className: "botao-cancelar",
        },
      ],
    });
  };

  useEffect(() => {
    requisitarCarga();
  }, [requisitarCarga]);

  const enviarCarregado = async () => {
    const arrayArquivos = Array.from(anexoCarregado.current.files);
    const promises = [];

    for (const item of arrayArquivos) {
      const formData = new FormData();
      formData.append("imagem", item);
      formData.append("carga", idCarga);
      formData.append("is_descarga", false);

      promises.push(api.post("cargas_imagens/", formData));
    }

    await Promise.all(promises);
    requisitarCarga();
  };

  const enviarDescarregados = async () => {
    const arrayArquivos = Array.from(anexoDescarregado.current.files);
    const promises = [];

    for (const item of arrayArquivos) {
      const formData = new FormData();
      formData.append("imagem", item);
      formData.append("carga", idCarga);
      formData.append("is_descarga", true);

      promises.push(api.post("cargas_imagens/", formData));
    }

    await Promise.all(promises);
    requisitarCarga();
  };

  const enviarCanhoto = async () => {
    const arrayArquivos = Array.from(anexoCanhoto.current.files);
    const promises = [];

    for (const item of arrayArquivos) {
      const formData = new FormData();
      formData.append("imagem", item);
      formData.append("carga", idCarga);

      promises.push(api.post("cargas_canhoto/", formData));
    }

    await Promise.all(promises);
    requisitarCarga();
  };

  return (
    <div className="modal-perfil-motorista-carga-imagens">
      <div className="dados-pedido">
        <div className="informacoes">
          <div>
            <FaRegIdCard className="icone"></FaRegIdCard>
            <p>
              <span>ID:</span> {carga?.id}
            </p>
          </div>

          <p className="pedido">
            <span>Pedido:</span>
            {carga?.pedidos.map((item, index) =>
              index < carga.pedidos.length - 1
                ? `${item.numero}, `
                : ` ${item.numero}`
            )}
          </p>

          {
            carga?.motorista_em_viagem?.tipo_cadastro === "NoApp" && <p className="message-app">Cadastro de motorista sem utilização do APP, algumas funcionalidades não disponíveis</p>
          }
        </div>
      </div>

      <hr className="separador" />

      <p className="titulo">Fotos da carga durante o processo</p>

      <div className="fotos-carga">
        <div className="caminhao-carregado">
          <p>Caminhão carregado</p>

          <div className="fotos">
            {carga?.imagens.map(
              (item) =>
                !item.is_descarga && (
                  <div className="container-img">
                    <RiCloseCircleFill
                      className="icone-remover"
                      onClick={() => alertRemoverImagem(item.id, "carga")}
                    ></RiCloseCircleFill>

                    <img
                      key={item.id}
                      src={item.imagem}
                      alt=""
                      onClick={() => visualizarImagem(item.imagem)}
                    />
                  </div>
                )
            )}
          </div>
          <div className="btn-upload">
            <label htmlFor="arquivo">
              <FaFileUpload className="icone"></FaFileUpload>
              <p>Enviar Arquivo</p>
            </label>

            <input
              type="file"
              name="arquivo"
              ref={anexoCarregado}
              onChange={enviarCarregado}
              accept="image/png, image/gif, image/jpeg"
              multiple
            />
          </div>
        </div>

        <div className="descarga">
          <p>Descarga</p>

          <div className="fotos">
            {carga?.imagens.map(
              (item) =>
                item.is_descarga && (
                  <div className="container-img">
                    <RiCloseCircleFill
                      className="icone-remover"
                      onClick={() => alertRemoverImagem(item.id, "carga")}
                    ></RiCloseCircleFill>

                    <img
                      key={item.id}
                      src={item.imagem}
                      alt=""
                      onClick={() => visualizarImagem(item.imagem)}
                    />
                  </div>
                )
            )}
          </div>
          <div className="btn-upload">
            <label htmlFor="arquivo">
              <FaFileUpload className="icone"></FaFileUpload>
              <p>Enviar Arquivo</p>
            </label>

            <input
              type="file"
              name="arquivo"
              ref={anexoDescarregado}
              onChange={enviarDescarregados}
              accept="image/png, image/gif, image/jpeg"
              multiple
            />
          </div>
        </div>

        <div className="canhoto">
          <p>Fotos do canhoto</p>

          <div className="fotos">
            {carga?.imagens_canhoto.map((item) => (
              <div className="container-img">
                <RiCloseCircleFill
                  className="icone-remover"
                  onClick={() => alertRemoverImagem(item.id, "canhoto")}
                ></RiCloseCircleFill>

                <img
                  key={item.id}
                  src={item.imagem}
                  alt=""
                  onClick={() => visualizarImagem(item.imagem)}
                />
              </div>
            ))}
          </div>
          <div className="btn-upload">
            <label htmlFor="arquivo">
              <FaFileUpload className="icone"></FaFileUpload>
              <p>Enviar Arquivo</p>
            </label>

            <input
              type="file"
              name="arquivo"
              ref={anexoCanhoto}
              onChange={enviarCanhoto}
              accept="image/png, image/gif, image/jpeg"
              multiple
            />
          </div>
        </div>
      </div>

      <hr className="separador" />

      <QuadroRegistros idCarga={idCarga}></QuadroRegistros>
    </div>
  );
};

export default memo(Imagens);
