import { createContext, useContext } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './messages-context.css';

export const MessagesContext = createContext({});

const options = {
  className: 'toast-error',
  progressClassName: 'toast-error-progress',
  bodyClassName: 'toast-error-body'
};

const getTitle = (path, titles) => {
  if (!titles) {
    return null;
  }
  if (!Object.prototype.hasOwnProperty.call(titles, path)) {
    return null;
  }
  return titles[path];
}

const showErrorsFromDict = (data, base, titles) => {
  Object.keys(data).forEach((key) => {
    const path = base ? `${base}.${key}` : key;

    if (typeof data[key] === 'string') {
      const title = getTitle(path, titles);
      const text = data[key];
      toast((
        <>
          {title && (
            <span className="toast-title">{title}</span>
          )}
          <span className="toast-text">
            {text}
          </span>
        </>
      ), options);
      return;
    }
    if (Array.isArray(data[key])) {
      for (const text of data[key]) {
        const title = getTitle(path, titles);
        toast((
          <>
            {title && (
              <span className="toast-title">{title}</span>
            )}
            <span className="toast-text">
              {text}
            </span>
          </>
        ), options);
      }
      return;
    }
    if (typeof data[key] === 'object') {
      showErrorsFromDict(data[key], path, titles);
    }
  })
}

export const MessagesProvider = ({children}) => {
  const showApiErrors = (error, titles) => {
    if (!error.response) {
      return;
    }
    if (typeof error.response.data === 'string') {
      toast('Erro Desconhecido', options);
      return;
    }
    const data = error.response.data;
    if (Object.prototype.hasOwnProperty.call(data, 'detail')) {
      toast(data.detail, options);
      return;
    }
    showErrorsFromDict(data, '', titles);
  };
  
  const showError = (title, text) => {
    toast((
      <>
        {title && (
          <span className="toast-title">{title}</span>
        )}
        <span className="toast-text">
          {text}
        </span>
      </>
    ), options);
  }

  const showSuccess = (title, text) => {
    toast((
      <>
        {title && (
          <span className="toast-title">{title}</span>
        )}
        <span className="toast-text">
          {text}
        </span>
      </>
    ), {
      className: 'toast-success',
      progressClassName: 'toast-success-progress',
      bodyClassName: 'toast-success-body'

    });
  }

  return (
    <MessagesContext.Provider value={{ showApiErrors, showError, showSuccess }}>
      {children}
      <ToastContainer />
    </MessagesContext.Provider>
  );
};

export const useMessages = () => useContext(MessagesContext);
