import React, { memo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import './styles.css'

import apiPrivada from "../../../services/api"

const PrimeiroAcesso = () => {
  const history = useHistory()

  const [senha, setSenha] = useState('')
  const [confirmarSenha, setConfirmarSenha] = useState('')

  const alterarSenha = (e) => {
    e.preventDefault()

    if ((senha.trim() === '' || confirmarSenha.trim() === '') || (senha.trim() !== confirmarSenha.trim())) {
      return confirmAlert({
        title: "Erro!",
        message: `A senha e confirmação da senha devem ser preenchidas e idênticas!`,
        overlayClassName: "alert-primeiro-acesso",
        buttons: [
          {
            label: "Ok"
          }
        ]
      })
    }

    apiPrivada.post("auth/changepassword/", {
      password1: senha,
      password2: confirmarSenha
    })
      .then(function (e) {
        history.push('/loading')
      })
      .catch(function (e) {
        console.log(e)
      })

  }

  return (
    <form className="formulario-primeiro-acesso">
      <div className="titulo-formulario">
        <p>Primeiro Acesso</p>
      </div>

      <div className="conteudo-formulario">
        <p>Redefina a sua senha:</p>

        <label>
          Nova Senha:
          <input
            required
            type="password"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />
        </label>

        <label>
          Confirmar Senha:
          <input
            required
            type="password"
            value={confirmarSenha}
            onChange={(e) => setConfirmarSenha(e.target.value)}
          />
        </label>

        <button
          type="submit"
          onClick={alterarSenha}
        >
          SALVAR
        </button>
      </div>
    </form>
  )
}

export default memo(PrimeiroAcesso)