import React, { memo, useRef, useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import api from "../../../../services/api"

const NovaCarroceria = ({ match }) => {
  const history = useHistory()
  const idCarroceria = match.params?.idCarroceria
  const desabilitarInputs = match.path === '/acesso/carroceria/visualizar-carroceria/:idCarroceria' ? true : false
  const isEditar = match.path === '/acesso/carroceria/editar-carroceria/:idCarroceria' ? true : false

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/carroceria', breadcrumb: 'Carroceria' },
    { path: '/acesso/carroceria/nova-carroceria', breadcrumb: 'Nova Carroceria' },
    { path: `/acesso/carroceria/visualizar-carroceria`, breadcrumb: null },
    { path: `/acesso/carroceria/visualizar-carroceria/${idCarroceria}`, breadcrumb: 'Visualizar Carroceria' },
    { path: `/acesso/carroceria/editar-carroceria`, breadcrumb: null },
    { path: `/acesso/carroceria/editar-carroceria/${idCarroceria}`, breadcrumb: 'Editar Carroceria' },
  ]

  const [msgErro, setMsgErro] = useState(false)

  const categoriaCarroceria = useRef('')
  const nomeCarroceria = useRef('')

  const verificaPreenchimentoCampos = () => {
    if (nomeCarroceria.current.value.trim() === "" || categoriaCarroceria.current.value.trim() === "") {
      return setMsgErro(true)
    } else {
      return true
    }
  }

  const cadastrarCarroceria = () => {
    let dados = {
      categoria_carroceria: categoriaCarroceria.current.value,
      tipo: nomeCarroceria.current.value
    }

    if (verificaPreenchimentoCampos()) {
      api.post("tipocarroceria/", dados)
        .then(function () {
          history.push("/acesso/carroceria")
        })
        .catch(function (e) {
          console.log(e)
        })
    }
  }

  const editarCarroceria = () => {
    let dados = {
      categoria_carroceria: categoriaCarroceria.current.value,
      tipo: nomeCarroceria.current.value
    }

    if (verificaPreenchimentoCampos()) {
      api.put(`tipocarroceria/${idCarroceria}/`, dados)
        .then(function () {
          history.push("/acesso/carroceria")
        })
        .catch(function (e) {
          console.log(e.response)
        })
    }
  }

  useEffect(() => {
    if (idCarroceria) {
      api.get(`tipocarroceria/${idCarroceria}/`)
        .then(function (response) {
          let dadosCarroceria = response.data

          categoriaCarroceria.current.value = dadosCarroceria.categoria_carroceria
          nomeCarroceria.current.value = dadosCarroceria.tipo
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [idCarroceria])

  return (
    <div className="container-nova-carroceria">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="nova-carroceria">
        <p className="titulo-pagina">
          {
            desabilitarInputs && !isEditar
              ? "Visualizar Carroceria"
              : isEditar
                ? "Editar Carroceria"
                : "Nova Carroceria"
          }
        </p>
        <p className="subtitulo-pagina">
          {
            desabilitarInputs && !isEditar
              ? "Dados informados no cadastro da carroceria"
              : isEditar
                ? "Por favor, informe os dados solicitados para editar o cadastro da carroceria"
                : "Por favor, informe os dados solicitados para cadastro de nova carroceria"
          }
        </p>

        <div className="categoria">
          <p className="titulo-categoria">Dados Da Carroceria</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <select
                required
                ref={categoriaCarroceria}
                disabled={desabilitarInputs}
              >
                <option value="" disabled></option>
                <option value={1}>Fechada</option>
                <option value={2}>Aberta</option>
                <option value={3}>Especial</option>
              </select>
              <span>Tipo Carroceria (fechada; aberta; especial) <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={nomeCarroceria}
                disabled={desabilitarInputs}
              />
              <span>Nome Carroceria <i>*</i></span>
            </div>
          </div>
        </div>

        {
          !desabilitarInputs &&
          <div className="container-botoes">
            <button
              className="btn-cancelar"
              onClick={() => history.replace("/acesso/carroceria")}
            >
              Cancelar
            </button>

            <button
              className="btn-cadastrar"
              onClick={isEditar ? editarCarroceria : cadastrarCarroceria}
            >
              {isEditar ? "Salvar Carroceria" : "Cadastrar Carroceria"}
            </button>
          </div>
        }

        {msgErro && <p className="msg-erro">Todos os campos com * devem ser preenchidos!</p>}
      </div>
    </div>
  )
}

export default memo(NovaCarroceria)