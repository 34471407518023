import React, { memo, useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import InputMask from 'react-input-mask'
import { BsFillTelephoneFill, BsStarFill, BsStar } from 'react-icons/bs'
import { FaRegIdCard } from 'react-icons/fa'
import Rating from 'react-rating'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import api from "../../../../services/api"

const PerfilMotorista = () => {
  const history = useHistory()

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/perfil-motorista', breadcrumb: 'Perfil' },
  ]

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
  const idMotorista = dadosUsuario.motorista_id

  const [avaliacaoMotorista, setAvaliacaoMotorista] = useState(null)

  const nomeCompleto = useRef('')
  const cpf = useRef('')
  const cnh = useRef('')
  const email = useRef('')
  const endereco = useRef('')
  const cidade = useRef('')
  const bairro = useRef('')
  const cep = useRef('')
  const observacoes = useRef('')

  useEffect(() => {
    api.get(`motoristas/all/${idMotorista}`)
      .then(function (response) {
        setAvaliacaoMotorista(response.data.estatisticas.avaliacao)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idMotorista])

  return (
    <div className="container-perfil-motorista">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <div className="perfil-motorista">
        <div className="container-foto">
          <div className="quadro-topo"></div>

          <div className="foto-id">
            <img src="https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80" alt="" />
            <div className="id">
              <FaRegIdCard className="icone"></FaRegIdCard>
              <p><i>ID: </i>1234567</p>
            </div>
          </div>

          <div className="dados">
            <p className="nome">Rodrigo Silva</p>
            <p className="funcao">Motorista</p>

            <div className="telefones">
              <div>
                <BsFillTelephoneFill className="icone"></BsFillTelephoneFill>
                <p>(62) 98257-7895</p>
              </div>
              <div>
                <BsFillTelephoneFill className="icone"></BsFillTelephoneFill>
                <p>(62) 98257-7895</p>
              </div>
            </div>
          </div>

          <div className="container-avaliacao">
            <p className="titulo">Rating</p>

            <div className="rating">
              <Rating
                initialRating={avaliacaoMotorista !== null ? avaliacaoMotorista : 0}
                emptySymbol={<BsStar className="icone vazio"></BsStar>}
                fullSymbol={<BsStarFill className="icone preenchido"></BsStarFill>}
              />
            </div>
          </div>
        </div>

        <div className="container-campos">
          <div className="topo">
            <p>Editar Perfil</p>

            <button>Alterar</button>
          </div>

          <div className="container-inputs">
            <div className="container-inputs-2col">
              <label>
                Nome Completo
                <input
                  placeholder="Dados..."
                  ref={nomeCompleto}
                />
              </label>

              <label>
                CPF
                <input
                  placeholder="Dados..."
                  ref={cpf}
                />
              </label>

              <label>
                CNH
                <input
                  placeholder="Dados..."
                  ref={cnh}
                />
              </label>

              <label>
                E-mail
                <input
                  placeholder="Dados..."
                  ref={email}
                />
              </label>
            </div>

            <div className="container-inputs-1col">
              <label>
                Endereço
                <input
                  placeholder="Dados..."
                  ref={endereco}
                />
              </label>
            </div>

            <div className="container-inputs-3col">
              <label>
                Cidade
                <input
                  placeholder="Dados..."
                  ref={cidade}
                />
              </label>

              <label>
                Bairro
                <input
                  placeholder="Dados..."
                  ref={bairro}
                />
              </label>

              <label>
                CEP
                <InputMask
                  placeholder="Dados..."
                  mask="99999-999"
                  maskChar="_"
                  ref={cep}
                ></InputMask>
              </label>
            </div>

            <div className="container-inputs-1col">
              <label>
                Observações
                <textarea
                  placeholder="Dados..."
                  rows={4}
                  type="text"
                  ref={observacoes}
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <button className="btn-voltar" onClick={() => history.goBack()}>Voltar Para Carga</button>
    </div>
  )
}

export default memo(PerfilMotorista)