import React, { memo, useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FaUserPlus } from 'react-icons/fa'
import { ImSearch } from 'react-icons/im'
import ReactPaginate from 'react-paginate'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import TabelaListarMotoristas from '../../../../components/plataformaAcessada/TabelaListarMotoristas'
import api from "../../../../services/api"

const GerenciarMotoristas = () => {
  const history = useHistory()

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
  const tipoUsuario = dadosUsuario.user_type

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/motorista', breadcrumb: 'Motorista' },
    { path: '/acesso/motorista/gerenciar-motorista', breadcrumb: 'Gerenciar Motoristas' },
  ]

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const [searchTransportadora, setSearchTransportadora] = useState('')
  const [searchMotorista, setSearchMotorista] = useState('')
  const [motoristas, setMotoristas] = useState([])
  const [quantidadePaginas, setQuantidadePaginas] = useState(1)
  const [paginaAtual, setPaginaAtual] = useState(0)

  const requisitarMotoristas = useCallback((paginaSelecionada, offSet) => {
    setPaginaAtual(paginaSelecionada)

    if (tipoUsuario !== "transportadora" && tipoUsuario !== "colaborador_transportadora") {
      api.get(`motoristas/all/?offset=${offSet ? offSet : 0}`)
        .then(function (response) {
          setQuantidadePaginas(Math.ceil(response.data.count / 30))
          setMotoristas(response.data.results)
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      api.get(`motoristas/all/?transportadora=${dadosUsuario.transportadora_id}&?offset=${offSet ? offSet : 0}`)
        .then(function (response) {
          setQuantidadePaginas(Math.ceil(response.data.count / 30))
          setMotoristas(response.data.results)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [tipoUsuario, dadosUsuario.transportadora_id])

  const filtrarItens = () => {
    setQuantidadePaginas(1)
    setMotoristas([])
    setPaginaAtual(0)

    api.post(`motorista/filter`, {
      query: searchTransportadora === '' ? searchMotorista : searchTransportadora
    })
      .then(function (response) {
        let listaMotoristas = response.data
        let auxListaMotoristas = []

        if (tipoUsuario !== "transportadora" && tipoUsuario !== "colaborador_transportadora") {
          listaMotoristas.forEach((item) => {
            auxListaMotoristas.push(item)
          })
        } else {
          listaMotoristas.forEach((item) => {
            if (item.transportadora === dadosUsuario.transportadora_id) {
              auxListaMotoristas.push(item)
            }
          })
        }

        setMotoristas(auxListaMotoristas)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const verificarTeclaSearch = (e) => {
    if (e.keyCode === 13) {
      filtrarItens()
    }
  }

  useEffect(() => {
    if (searchTransportadora === '' && searchMotorista === '') {
      forceUpdate()
      requisitarMotoristas()
    }
  }, [requisitarMotoristas, forceUpdate, searchTransportadora, searchMotorista])

  return (
    <div className="container-gerenciar-motorista">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="gerenciar-motorista">
        <div className="container-topo">
          <div>
            <p className="titulo-pagina">Gerenciar Motoristas</p>
            <p className="subtitulo-pagina">Realize o filtro para busca do motorista desejado</p>
          </div>

          <button className="btn-novo-motorista" onClick={() => history.push("/acesso/motorista/novo-motorista")}>
            <FaUserPlus className="icone"></FaUserPlus>
            Novo Motorista
          </button>
        </div>

        <div className="container-search">
          {(tipoUsuario !== "transportadora" && tipoUsuario !== "colaborador_transportadora") &&
            <div className="input-search transportadora">
              <input
                type="text"
                placeholder="Buscar Transportadora (Razão Social; CNPJ)"
                value={searchTransportadora}
                onChange={(e) => setSearchTransportadora(e.target.value)}
                onKeyDown={verificarTeclaSearch}
              />
              <ImSearch className="icone"></ImSearch>
            </div>
          }

          <div className="input-search motorista">
            <input
              type="text"
              placeholder="Buscar Motorista (Nome)"
              value={searchMotorista}
              onChange={(e) => setSearchMotorista(e.target.value)}
              onKeyDown={verificarTeclaSearch}
            />
            <ImSearch className="icone"></ImSearch>
          </div>

          <button className="btn-buscar" onClick={filtrarItens}>Buscar</button>
        </div>

        {
          (tipoUsuario === "transportadora" || tipoUsuario === "colaborador_transportadora") && motoristas.length === 0
            ? <p className="msg-lista-motorista-vazia">Não existem motoristas particulares cadastrados</p>
            :
            <>
              <TabelaListarMotoristas
                motoristas={motoristas}
                requisitarMotoristas={requisitarMotoristas}
              ></TabelaListarMotoristas>

              <div className="container-paginacao-motorista">
                <ReactPaginate
                  previousLabel={"Anterior"}
                  nextLabel={"Próxima"}
                  breakLabel={"..."}
                  pageCount={quantidadePaginas}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  forcePage={paginaAtual}
                  onPageChange={(e) => requisitarMotoristas(e.selected, e.selected * 30)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </>
        }
      </div>
    </div>
  )
}

export default memo(GerenciarMotoristas)