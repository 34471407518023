import React, { useState, useMemo, memo, useEffect, useCallback } from "react"
import { FaSort } from "react-icons/fa"
import ReactPaginate from 'react-paginate'
import { parseISO, format } from 'date-fns'

import './styles.css'

import api from "../../../../../../../../services/api"

const HistoricoMotorista = ({ motorista }) => {
  const [cargas, setCargas] = useState([])
  const [quantidadePaginas, setQuantidadePaginas] = useState(1)

  const [paginaAtual, setPaginaAtual] = useState(0)
  const [indexExpandirLinha, setIndexExpandirLinha] = useState(null)

  const requisitarCargas = useCallback((paginaSelecionada, offSet) => {
    setPaginaAtual(paginaSelecionada)

    api.get(`cargas_historico/?motorista_em_viagem=${motorista.id}&limit=15&offset=${offSet ? offSet : 0}`)
      .then(function (response) {
        setQuantidadePaginas(Math.ceil(response.data.count / 15))
        setCargas(response.data.results)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [motorista.id])

  useEffect(() => {
    requisitarCargas()
  }, [requisitarCargas])

  const TabelaHistorico = () => {
    const OrdernarLinhasTabela = (itens, config = null) => {
      const [ordenacao, setOrdenacao] = useState(config)

      const itensOrdenados = useMemo(() => {
        let itenDesordenados = [...itens]

        if (ordenacao !== null) {
          itenDesordenados.sort((a, b) => {
            if (a[ordenacao.chave] < b[ordenacao.chave]) {
              return ordenacao.direcao === "ascendente" ? -1 : 1
            }

            if (a[ordenacao.chave] > b[ordenacao.chave]) {
              return ordenacao.direcao === "ascendente" ? 1 : -1
            }

            return 0
          })
        }

        return itenDesordenados
      }, [itens, ordenacao])

      const solicitandoOrdenacao = (chave) => {
        let direcao = "ascendente"

        if (ordenacao && ordenacao.chave === chave && ordenacao.direcao === "ascendente") {
          direcao = "decrescente"
        }

        setOrdenacao({ chave, direcao })
      }

      return { itens: itensOrdenados, solicitandoOrdenacao, ordenacao }
    }

    const { itens, solicitandoOrdenacao, ordenacao } = OrdernarLinhasTabela(cargas)

    const obtendoClasse = (nome) => {
      if (!ordenacao) {
        return
      }

      return ordenacao.chave === nome ? ordenacao.direcao : undefined
    }

    return (
      <div className="container-tabela-historico">
        <table>
          <thead>
            <tr>
              <th onClick={() => solicitandoOrdenacao("id")} className={obtendoClasse("id")}>
                <div>
                  ID CARGA
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th onClick={() => solicitandoOrdenacao("origem")} className={obtendoClasse("origem")}>
                <div>
                  ROTA<br />(Origem - Destino)
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th onClick={() => solicitandoOrdenacao("kmRodado")} className={obtendoClasse("kmRodado")}>
                <div>
                  KM<br />RODADO
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th onClick={() => solicitandoOrdenacao("carga")} className={obtendoClasse("carga")}>
                <div>
                  CARGA
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th onClick={() => solicitandoOrdenacao("embarcador")} className={obtendoClasse("embarcador")}>
                <div>
                  EMBAR<br />CADOR
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th onClick={() => solicitandoOrdenacao("dataColeta")} className={obtendoClasse("dataColeta")}>
                <div>
                  DATA<br />COLETA
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th onClick={() => solicitandoOrdenacao("avaliacao")} className={obtendoClasse("avaliacao")}>
                <div>
                  AVALIAÇÃO
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {itens.map((item, index) => (
              <React.Fragment key={index}>
                <tr key={index} className={index % 2 === 0 ? "" : "linha-impar"}>
                  <td>{item.id}</td>
                  <td>{`${item.origem.endereco?.municipio}-${item.origem.endereco?.uf} / `}
                    <>
                      {
                        item.pontos_parada.length > 1
                          ?
                          <>
                            {`${item.pontos_parada[0]?.destino?.endereco?.municipio}-${item.pontos_parada[0]?.destino?.endereco?.uf}, `}
                            {indexExpandirLinha === index
                              ?
                              <>
                                {
                                  item.pontos_parada.map((pontos, indexPontos) =>
                                    indexPontos !== 0
                                      ? indexPontos < item.pontos_parada.length - 1
                                        ? `${pontos.destino?.endereco?.municipio}-${pontos.destino?.endereco?.uf}, `
                                        : ` ${pontos.destino?.endereco?.municipio}-${pontos.destino?.endereco?.uf}`
                                      : '')
                                }

                                <p className="expandir-linha" onClick={() => setIndexExpandirLinha(null)}>Ver menos</p>
                              </>
                              : <p className="expandir-linha" onClick={() => setIndexExpandirLinha(index)} >Ver mais</p>
                            }
                          </>
                          : `${item.pontos_parada[0]?.destino?.endereco.municipio}-${item.pontos_parada[0]?.destino?.endereco.uf}`
                      }
                    </>
                  </td>
                  <td>
                    {
                      item.distancia_percorrida
                        ? (Math.round(Number(item.distancia_percorrida / 1000))).toLocaleString('pt-BR')
                        : 0
                    }
                  </td>
                  <td>{item.produto?.produto}</td>
                  <td>{item.embarcador?.razao_social}</td>
                  <td>{item.data_coleta && format(parseISO(item.data_descarga), "dd/MM/yyyy")}</td>
                  <td>{item.avaliacao_empresa ? item.avaliacao_empresa : 0}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className="container-historico">
      <p className="titulo">Histórico de Cargas:</p>

      <div className="container-quadros">
        <div className="quadro total">
          <div>
            <p>Total</p>
            <p>{motorista.estatisticas?.tempo_apos_cadastro}</p>
          </div>
          <p className="cargas">Cargas: <span>{motorista.estatisticas?.cargas_total}</span></p>
        </div>

        <div className="quadro">
          <p>Cargas On-Time</p>
          <p>
            {
              motorista.estatisticas?.porcentagem_no_tempo
                ? Number(motorista.estatisticas?.porcentagem_no_tempo).toFixed(0)
                : 0
            }%
          </p>
        </div>

        <div className="quadro">
          <p>Top Região</p>
          <p>{motorista.estatisticas?.top_regiao_destino}</p>
        </div>
      </div>

      <TabelaHistorico />

      <div className="container-paginacao-historico">
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          pageCount={quantidadePaginas}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          forcePage={paginaAtual}
          onPageChange={(e) => requisitarCargas(e.selected, e.selected * 15)}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  )
}

export default memo(HistoricoMotorista)