import React, { memo, useEffect, useState } from "react";
import Modal from "react-modal";
import { confirmAlert } from 'react-confirm-alert'
import { RiCloseCircleFill } from "react-icons/ri";

import "./styles.css";

import api from "../../../../../../../services/api";

const ModalNovoFretePagar = ({
  modalNovoFretePagar,
  handleModalNovoFrete,
  dadosFreteSelecionado,
  idCarga,
  requisitarFretes,
  requisitarDocumentos,
}) => {
  const [data, setData] = useState(new Date().toISOString().substring(0, 10));
  const [tipoSelecionado, setTipoSelecionado] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");
  const [valor, setValor] = useState("");
  const [observacoes, setObservacoes] = useState("");

  const registrarNovoFrete = () => {
    const dados = {
      data: data,
      tipo: tipoSelecionado,
      forma_pagamento: formaPagamento,
      valor: valor,
      observacoes: observacoes,
      carga: idCarga,
      editado: true,
    };

    if (dadosFreteSelecionado) {
      api
        .patch(
          `documentos_fiscais/fretepagar/${dadosFreteSelecionado.id}/`,
          dados
        )
        .then(function () {
          requisitarFretes();
          handleModalNovoFrete();
          requisitarDocumentos();
        })
        .catch(function (error) {
          if (error?.response?.data?.detail) {
            confirmAlert({
              title: "Erro",
              message: error?.response?.data?.detail,
              overlayClassName: "alert-remover-frete",
              buttons: [
                {
                  label: "Ok",
                  className: "botao-confirmar"
                },
              ]
            })
          }
          console.log(error);
        });
    } else {
      api
        .post(`documentos_fiscais/fretepagar/`, dados)
        .then(function () {
          requisitarFretes();
          handleModalNovoFrete();
          requisitarDocumentos();
        })
        .catch(function (error) {
          if (error?.response?.data?.detail) {
            confirmAlert({
              title: "Erro",
              message: error?.response?.data?.detail,
              overlayClassName: "alert-remover-frete",
              buttons: [
                {
                  label: "Ok",
                  className: "botao-confirmar"
                },
              ]
            })
          }
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (dadosFreteSelecionado) {
      setData(dadosFreteSelecionado.data);
      setTipoSelecionado(dadosFreteSelecionado.tipo);
      setFormaPagamento(dadosFreteSelecionado.forma_pagamento);
      setValor(dadosFreteSelecionado.valor);
      setObservacoes(dadosFreteSelecionado.observacoes);
    }
  }, [dadosFreteSelecionado]);

  return (
    <Modal
      isOpen={modalNovoFretePagar}
      onRequestClose={handleModalNovoFrete}
      className="modal-novo-frete-pagar"
      overlayClassName="overlay-modal-novo-frete-pagar"
      ariaHideApp={false}
    >
      <div className="container-novo-frete">
        <RiCloseCircleFill
          className="icone-fechar"
          onClick={handleModalNovoFrete}
        ></RiCloseCircleFill>

        <p className="titulo">Novo Frete a Pagar</p>

        <div className="container-inputs">
          <div className="container-inputs-4col">
            <div className="container-input-comum">
              <input
                required
                type="date"
                value={data}
                onChange={(e) => setData(e.target.value)}
              />
            </div>

            <div className="container-input-comum">
              <select
                value={tipoSelecionado}
                onChange={(e) => setTipoSelecionado(e.target.value)}
              >
                <option value="">Tipo</option>
                <option value="mot-rec">Mot. Rec</option>
                <option value="abastecimento">Abastecimento</option>
                <option value="avaria">Avaria</option>
                <option value="pesquisa-gr">Pesquisa GR</option>
                <option value="consulta-gr">Consulta GR</option>
                <option value="outros">Outros</option>
                <option value="adiantamento">Adiantamento</option>
                <option value="pedagio">Pedágio</option>
                <option value="IR">IR</option>
                <option value="INSS">INSS</option>
                <option value="SEST/SENAT">SEST/SENAT</option>
                <option value="saldo">Saldo</option>
                <option value="aditivo">Aditivo</option>
              </select>
            </div>

            <div className="container-input-comum">
              <select
                value={formaPagamento}
                onChange={(e) => setFormaPagamento(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="ted-doc">Transferência</option>
                <option value="pix">PIX</option>
                <option value="dinheiro">Dinheiro</option>
                <option value="cheque">Cheque</option>
                <option value="outros">Outros</option>
              </select>
            </div>

            <div className="container-input-comum">
              <input
                required
                type="number"
                value={valor}
                onChange={(e) => setValor(e.target.value)}
              />
              <span>Valor</span>
            </div>
          </div>

          <div className="container-inputs-1col">
            <div className="container-input-comum">
              <input
                required
                type="text"
                value={observacoes}
                onChange={(e) => setObservacoes(e.target.value)}
              />
              <span>Observações</span>
            </div>
          </div>

          <button className="btn-registrar" onClick={registrarNovoFrete}>
            {dadosFreteSelecionado
              ? "Salvar Alterações"
              : "Registrar Novo Frete"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalNovoFretePagar);
