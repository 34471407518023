import React, { memo, useState, useEffect } from "react";

import "./styles.css";

import BreadCrumbs from "../../../../components/plataformaAcessada/BreadCrumbs";
import Calculadora from "./Components/Calculadora";
import SemCalculadora from "./Components/SemCalculadora";

import api from "../../../../services/api";

import coins from "../../../../assets/coins.png";

const Compra = () => {
  const routes = [
    { path: "/acesso", breadcrumb: "Início" },
    { path: "/acesso/rastro-coins", breadcrumb: "Compre sua Rastro Coins" },
  ];

  const [useCalculadora, setUseCalculadora] = useState(true);
  const [valoresCoins, setValoresCoins] = useState([]);

  const handleOpcaoCompra = () => {
    setUseCalculadora((prevUseCalculadora) => !prevUseCalculadora);
  };

  useEffect(() => {
    api
      .get("pagamentos/produtos/")
      .then(function (response) {
        setValoresCoins(response.data.results);
      })
      .catch(function (e) {
        console.log(e);
      });
  }, []);

  return (
    <div className="container-rastro-coins">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <div className="rastro-coins">
        <div className="quadro-compra">
          <div className="opcoes">
            <div
              className={`opcao ${useCalculadora && "selected"}`}
              onClick={handleOpcaoCompra}
            >
              <p>Calculadora De Rastro Coins</p>
              <div className="checkbox-wrapper">
                <label>
                  <input
                    type="checkbox"
                    checked={useCalculadora ? true : false}
                  />
                </label>
              </div>
            </div>

            <div
              className={`opcao ${!useCalculadora && "selected"}`}
              onClick={handleOpcaoCompra}
            >
              <p>Comprar Sem Calculadora</p>
              <div className="checkbox-wrapper">
                <label>
                  <input
                    type="checkbox"
                    checked={!useCalculadora ? true : false}
                  />
                </label>
              </div>
            </div>
          </div>

          {useCalculadora ? (
            <Calculadora></Calculadora>
          ) : (
            <SemCalculadora></SemCalculadora>
          )}
        </div>

        <div className="coluna-precos">
          <div className="container-tabela-precos">
            <table>
              <thead>
                <tr>
                  <th>Descrição dos Serviços</th>
                  <th>Rastro Coins A Pagar</th>
                </tr>
              </thead>

              <tbody>
                {valoresCoins.map((valor) => (
                  <tr>
                    <td>
                      {valor.produto === "viagme-sem-gr"
                        ? "Viagem"
                        : valor.produto?.replace("-", " ")}
                    </td>
                    <td>RC - {valor.coins}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="background">
            <img src={coins} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Compra);
