import React, { memo, useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FaUserPlus } from 'react-icons/fa'
import { ImSearch } from 'react-icons/im'
import ReactPaginate from 'react-paginate'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import TabelaListarTransportadoras from '../../../../components/plataformaAcessada/TabelaListarTransportadoras'
import api from "../../../../services/api"

const GerenciarEmbarcadorTransportadora = ({ match }) => {
  const history = useHistory()
  const idEmbarcador = match.params?.idEmbarcador

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/embarcador', breadcrumb: 'Embarcador' },
    { path: '/acesso/embarcador/gerenciar-embarcador', breadcrumb: 'Gerenciar Embarcador' },
    { path: `/acesso/embarcador/gerenciar-embarcador/${idEmbarcador}`, breadcrumb: null },
    { path: `/acesso/embarcador/gerenciar-embarcador/${idEmbarcador}/transportadora`, breadcrumb: 'Transportadora' },
  ]

  const [embarcador, setEmbarcador] = useState(null)

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const [search, setSearch] = useState('')
  const [transportadoras, setTransportadoras] = useState([])
  const [quantidadePaginas, setQuantidadePaginas] = useState(1)
  const [paginaAtual, setPaginaAtual] = useState(0)

  useEffect(() => {
    api.get(`embarcador/${idEmbarcador}/`)
      .then(function (response) {
        setEmbarcador(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idEmbarcador])

  const requisitarTransportadoras = useCallback((paginaSelecionada, offSet) => {
    setPaginaAtual(paginaSelecionada)

    api.get(`transportadora/?embarcador=${idEmbarcador}&offset=${offSet ? offSet : 0}`)
      .then(function (response) {
        setQuantidadePaginas(Math.ceil(response.data.count / 30))
        setTransportadoras(response.data.results)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idEmbarcador])

  const filtrarItens = () => {
    setQuantidadePaginas(1)
    setTransportadoras([])
    setPaginaAtual(0)

    api.post(`transportadora/filter`, {
      query: search
    })
      .then(function (response) {
        let listaTransportadoras = response.data
        let auxListaTransportadoras = []

        listaTransportadoras.forEach((item) => {
          if (item.embarcador?.toString() === idEmbarcador) {
            auxListaTransportadoras.push(item)
          }
        })

        setTransportadoras(auxListaTransportadoras)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const verificarTeclaSearch = (e) => {
    if (e.keyCode === 13) {
      filtrarItens()
    }
  }

  useEffect(() => {
    if (search === '') {
      forceUpdate()
      requisitarTransportadoras()
    }
  }, [requisitarTransportadoras, forceUpdate, search])

  return (
    <div className="container-gerenciar-embarcador-transportadora">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="gerenciar-embarcador-transportadora">
        <div className="container-topo">
          <div>
            <p className="titulo-pagina">Gerenciar Embarcador / Transportadoras</p>
          </div>
        </div>

        <input
          className="input-nome-embarcador"
          type="text"
          defaultValue={embarcador?.razao_social}
          disabled={true}
        />

        <p className="subtitulo-pagina">
          Transportadoras vinculadas ao embarcador. Realize o filtro para busca da transportadora desejada
        </p>

        <div className="container-search-adicionar">
          <div className="container-search">
            <div className="input-search">
              <input
                type="text"
                placeholder="Buscar Transportadora (Razão Social; CNPJ)"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={verificarTeclaSearch}
              />
              <ImSearch className="icone"></ImSearch>
            </div>

            <button className="btn-buscar" onClick={filtrarItens}>Buscar</button>
          </div>

          <button className="btn-nova-transportadora" onClick={() => history.push(`/acesso/transportadora/nova-transportadora/${idEmbarcador}`)}>
            <FaUserPlus className="icone"></FaUserPlus>
            Nova Transportadora
          </button>
        </div>

        <TabelaListarTransportadoras
          transportadoras={transportadoras}
          idEmbarcador={idEmbarcador}
          requisitarTransportadoras={requisitarTransportadoras}
        ></TabelaListarTransportadoras>

        <div className="container-paginacao-transportadora">
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próxima"}
            breakLabel={"..."}
            pageCount={quantidadePaginas}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={paginaAtual}
            onPageChange={(e) => requisitarTransportadoras(e.selected, e.selected * 30)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(GerenciarEmbarcadorTransportadora)