import React, { memo, useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import Modal from "react-modal";
import { FaTrashAlt } from "react-icons/fa";

import "./styles.css";

const ModalCartaCorrecao = ({
  modalCartaCorrecao,
  handleModalCartaCorrecao,
}) => {
  const [cartas, setCartas] = useState([]);
  const [exibirLinhaCarta, setExibirLinhaCarta] = useState(true);
  const [grupo, setGrupo] = useState("ide");
  const [campo, setCampo] = useState("");
  const [valor, setValor] = useState("");
  const [justificativa, setJustificativa] = useState("");

  const grupos = [
    {
      name: "Identificação do CT-e",
      value: "ide",
    },
    {
      name: "Emitente",
      value: "emit",
    },
    {
      name: "Endereço do Emitente",
      value: "enderEmit",
    },
    {
      name: "Remetente",
      value: "rem",
    },
    {
      name: "Endereço do Remetente",
      value: "enderRem",
    },
    {
      name: "Expedidor",
      value: "exped",
    },
    {
      name: "Endereço do Expedidor",
      value: "enderExped",
    },
    {
      name: "Recebedor",
      value: "receb",
    },
    {
      name: "Endereço Do Recebedor",
      value: "enderReceb",
    },
    {
      name: "Destinatário",
      value: "dest",
    },
    {
      name: "Endereço do Destinatário",
      value: "enderDest",
    },
  ];

  const optionsGrupos = {
    ide: [
      {
        name: "Código Fiscal de Operações e Prestações",
        value: "CFOP",
      },
      {
        name: "Natureza da Operação",
        value: "natOp",
      },
      {
        name: "Formato de Impressão do DACTE",
        value: "tpImp",
      },
      {
        name: "Tipo do CT-e",
        value: "tpCTe",
      },
      {
        name: "Código do Município de Envio do CT-e",
        value: "cMunEnv",
      },
      {
        name: "Nome do Município de Envio do CT-e",
        value: "xMunEnv",
      },
      {
        name: "Sigla da UF de Envio do CT-e",
        value: "UFEnv",
      },
      {
        name: "Tipo do Serviço",
        value: "tpServ",
      },
      {
        name: "Código do Município de Início da Prestação",
        value: "cMunIni",
      },
      {
        name: "Nome do Município do Início da Prestação",
        value: "xMunIni",
      },
      {
        name: "UF do início da prestação",
        value: "UFIni",
      },
      {
        name: "Código do Município de término da prestação",
        value: "CMunFim",
      },
      {
        name: "Nome do Município do término da prestação",
        value: "xMunFim",
      },
      {
        name: "UF do término da prestação",
        value: "UFFim",
      },
      {
        name: "Indicador do papel do tomador na prestação do serviço",
        value: "indIEToma",
      },
    ],
    emit: [
      {
        name: "Razão Social ou Nome do Emitente",
        value: "xNome",
      },
      {
        name: "Nome fantasia",
        value: "xFant",
      },
    ],
    enderEmit: [
      {
        name: "Logradouro",
        value: "xLgr",
      },
      {
        name: "Número",
        value: "nro",
      },
      {
        name: "Complemento",
        value: "xCpl",
      },
      {
        name: "Bairro",
        value: "xBairro",
      },
      {
        name: "Código do Município",
        value: "cMun",
      },
      {
        name: "Nome do Município",
        value: "xMun",
      },
      {
        name: "CEP",
        value: "CEP",
      },
      {
        name: "Sigla da UF",
        value: "UF",
      },
      {
        name: "Telefone",
        value: "fone",
      },
    ],
    rem: [
      {
        name: "Razão Social ou Nome do Emitente",
        value: "xNome",
      },
      {
        name: "Nome fantasia",
        value: "xFant",
      },
      {
        name: "Telefone",
        value: "fone",
      },
      {
        name: "E-mail",
        value: "email",
      },
    ],
    enderRem: [
      {
        name: "Logradouro",
        value: "xLgr",
      },
      {
        name: "Número",
        value: "nro",
      },
      {
        name: "Complemento",
        value: "xCpl",
      },
      {
        name: "Bairro",
        value: "xBairro",
      },
      {
        name: "Código do Município",
        value: "cMun",
      },
      {
        name: "Nome do Município",
        value: "xMun",
      },
      {
        name: "CEP",
        value: "CEP",
      },
      {
        name: "Sigla da UF",
        value: "UF",
      },
      {
        name: "Código do País",
        value: "cPais",
      },
      {
        name: "Nome do País",
        value: "xPais",
      },
    ],
    exped: [
      {
        name: "CNPJ",
        value: "CNPJ",
      },
      {
        name: "CPF",
        value: "CPF",
      },
      {
        name: "IE",
        value: "IE",
      },
      {
        name: "Razão Social ou Nome",
        value: "xNome",
      },
      {
        name: "Telefone",
        value: "fone",
      },
      {
        name: "E-mail",
        value: "email",
      },
    ],
    enderExped: [
      {
        name: "Logradouro",
        value: "xLgr",
      },
      {
        name: "Número",
        value: "nro",
      },
      {
        name: "Complemento",
        value: "xCpl",
      },
      {
        name: "Bairro",
        value: "xBairro",
      },
      {
        name: "Código do Município",
        value: "cMun",
      },
      {
        name: "Nome do Município",
        value: "xMun",
      },
      {
        name: "CEP",
        value: "CEP",
      },
      {
        name: "Sigla da UF",
        value: "UF",
      },
      {
        name: "Código do País",
        value: "cPais",
      },
      {
        name: "Nome do País",
        value: "xPais",
      },
    ],
    receb: [
      {
        name: "CNPJ",
        value: "CNPJ",
      },
      {
        name: "CPF",
        value: "CPF",
      },
      {
        name: "IE",
        value: "IE",
      },
      {
        name: "Razão Social ou Nome",
        value: "xNome",
      },
      {
        name: "Telefone",
        value: "fone",
      },
      {
        name: "E-mail",
        value: "email",
      },
    ],
    enderReceb: [
      {
        name: "Logradouro",
        value: "xLgr",
      },
      {
        name: "Número",
        value: "nro",
      },
      {
        name: "Complemento",
        value: "xCpl",
      },
      {
        name: "Bairro",
        value: "xBairro",
      },
      {
        name: "Código do Município",
        value: "cMun",
      },
      {
        name: "Nome do Município",
        value: "xMun",
      },
      {
        name: "CEP",
        value: "CEP",
      },
      {
        name: "Sigla da UF",
        value: "UF",
      },
      {
        name: "Código do País",
        value: "cPais",
      },
      {
        name: "Nome do País",
        value: "xPais",
      },
    ],
    dest: [
      {
        name: "Razão Social ou Nome",
        value: "xNome",
      },
      {
        name: "Telefone",
        value: "fone",
      },
      {
        name: "E-mail",
        value: "email",
      },
    ],
    enderDest: [
      {
        name: "Logradouro",
        value: "xLgr",
      },
      {
        name: "Número",
        value: "nro",
      },
      {
        name: "Complemento",
        value: "xCpl",
      },
      {
        name: "Bairro",
        value: "xBairro",
      },
      {
        name: "Código do Município",
        value: "cMun",
      },
      {
        name: "Nome do Município",
        value: "xMun",
      },
      {
        name: "CEP",
        value: "CEP",
      },
      {
        name: "Sigla da UF",
        value: "UF",
      },
      {
        name: "Código do País",
        value: "cPais",
      },
      {
        name: "Nome do País",
        value: "xPais",
      },
    ],
  };

  const [campos, setCampos] = useState(optionsGrupos.ide);

  const adicionarCarta = () => {
    if (!exibirLinhaCarta) {
      setExibirLinhaCarta(true);
      return;
    }

    setExibirLinhaCarta(false);

    setCartas((cartas) => [
      ...cartas,
      {
        grupo: grupo,
        campo: campo,
        valor: valor,
        justificativa: justificativa,
        optionsCampo: optionsGrupos[grupo],
      },
    ]);

    setGrupo("");
    setCampo("");
    setValor("");
    setJustificativa("");
  };

  const editarCarta = (novoDado, index, chave) => {
    let auxCartas = [...cartas];
    let cartaEscolhida = auxCartas[index];
    cartaEscolhida[chave] = novoDado;

    setCartas(auxCartas);
    return;
  };

  const removerCarta = (index) => {
    let auxCartas = [...cartas];
    auxCartas.splice(index, 1);
    setCartas(auxCartas);
  };

  return (
    <Modal
      isOpen={modalCartaCorrecao}
      onRequestClose={handleModalCartaCorrecao}
      className="modal-carta-correcao"
      overlayClassName="overlay-modal-carta-correcao"
      ariaHideApp={false}
    >
      <div className="carta-correcao">
        <RiCloseCircleFill
          className="icone-fechar"
          onClick={handleModalCartaCorrecao}
        ></RiCloseCircleFill>

        <p className="titulo">Carta de Correção</p>

        <div className="descricao">
          A Carta de Correcao e disciplinada pelo Art. 58-B do CONVENIO/SINIEF
          06/89: Fica permitida a utilizacao de carta de correcao, para
          regularizacao de erro ocorrido na emissao de documentos fiscais
          relativos a prestacao de servico de transporte, desde que o erro nao
          esteja relacionado com:
          <br />I - as variaveis que determinam o valor do imposto tais como:
          base de calculo, aliquota, diferenca de preco, quantidade, valor da
          prestacao;
          <br />
          II - a correcao de dados cadastrais que implique mudanca do emitente,
          tomador, remetente ou do destinatario;
          <br />
          III - a data de emissao ou de saida.
        </div>

        {cartas.map((_, index) => (
          <div key={index} className="container-formulario">
            <FaTrashAlt
              className="remover-carta"
              onClick={() => removerCarta(index)}
            ></FaTrashAlt>

            <div className="container-inputs-4col">
              <div className="container-input-comum">
                <select
                  value={cartas[index]?.grupo || ""}
                  onChange={(e) => {
                    editarCarta(e.target.value, index, "grupo");
                    editarCarta(
                      optionsGrupos[e.target.value],
                      index,
                      "optionsCampo"
                    );
                  }}
                >
                  {grupos.map((grupo, ind) => (
                    <option key={ind} value={grupo.value}>
                      {grupo.name}
                    </option>
                  ))}
                </select>
                <span>Grupo</span>
              </div>

              <div className="container-input-comum">
                <select
                  value={cartas[index]?.campo || ""}
                  onChange={(e) => editarCarta(e.target.value, index, "campo")}
                >
                  {cartas[index]?.optionsCampo?.map((grupo, ind) => (
                    <option key={ind} value={grupo.value}>
                      {grupo.name}
                    </option>
                  ))}
                </select>
                <span>Campo</span>
              </div>

              <div className="container-input-comum">
                <input
                  value={cartas[index].valor}
                  onChange={(e) => editarCarta(e.target.value, index, "valor")}
                  placeholder="Valor"
                ></input>
                <span>Valor</span>
              </div>

              <div className="container-input-comum">
                <input
                  value={cartas[index].justificativa}
                  onChange={(e) =>
                    editarCarta(e.target.value, index, "justificativa")
                  }
                  placeholder="Justificativa"
                ></input>
                <span>Justificativa</span>
              </div>
            </div>
          </div>
        ))}

        {exibirLinhaCarta && (
          <div className="container-inputs-4col">
            <div className="container-input-comum">
              <select
                value={grupo}
                onChange={(e) => {
                  setGrupo(e.target.value);
                  setCampos(optionsGrupos[e.target.value]);
                }}
              >
                {grupos.map((grupo, ind) => (
                  <option key={ind} value={grupo.value}>
                    {grupo.name}
                  </option>
                ))}
              </select>
              <span>Grupo</span>
            </div>

            <div className="container-input-comum">
              <select value={campo} onChange={(e) => setCampo(e.target.value)}>
                {campos.map((grupo, ind) => (
                  <option key={ind} value={grupo.value}>
                    {grupo.name}
                  </option>
                ))}
              </select>
              <span>Campo</span>
            </div>

            <div className="container-input-comum">
              <input
                type="number"
                value={valor}
                onChange={(e) => setValor(e.target.value)}
                placeholder="Valor"
              ></input>
              <span>Valor</span>
            </div>

            <div className="container-input-comum">
              <input
                value={justificativa}
                onChange={(e) => setJustificativa(e.target.value)}
                placeholder="Justificativa"
              ></input>
              <span>Justificativa</span>
            </div>
          </div>
        )}

        <p className="btn-adicionar" onClick={adicionarCarta}>
          + Adicionar Carta
        </p>
      </div>
    </Modal>
  );
};

export default memo(ModalCartaCorrecao);
