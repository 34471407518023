import React, {
  Fragment,
  memo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { parseISO, format } from "date-fns";
import { FaPlus } from "react-icons/fa";
import { HiOutlineRefresh } from "react-icons/hi";
import { RiCloseCircleFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { saveAs } from "file-saver";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import ModalNovoManifesto from "./ModalNovoManifesto";
import api from "../../../../../../services/api";

import pdfIcon from "../../../../../../assets/pdf.png";
import xmlIcon from "../../../../../../assets/xml.png";
import cteIcon from "../../../../../../assets/cte.png";

import "./styles.css";

const TabelaMDFe = ({ idCarga, requisitarMdfes, manifestos }) => {
  const history = useHistory();
  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));

  const [modalNovoManifesto, setModalNovoManifesto] = useState(false);
  const [idMdfeSelecionado, setIdMdfeSelecionado] = useState(null);

  const justificativaCancelarMdfe = useRef("");

  const handleModalNovoManifesto = useCallback(
    (idManifesto) => {
      if (modalNovoManifesto) {
        setModalNovoManifesto(false);
        document.querySelector("body").style.overflow = "visible";
      } else {
        setModalNovoManifesto(true);
        setIdMdfeSelecionado(idManifesto);
        document.querySelector("body").style.overflow = "hidden";
      }
    },
    [modalNovoManifesto]
  );

  const cancelarMdfe = (idMdfe, onClose) => {
    api
      .post(`documentos_fiscais/mdfe/${idMdfe}/cancelar/`, {
        justificativa: justificativaCancelarMdfe.current.value,
      })
      .then(function () {
        onClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const alertCancelarMdfe = (idMdfe) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="container-remover-documento">
            <p className="titulo">
              Você deseja realmente <span className="cancelar">cancelar</span> o
              MDF-e de ID {idMdfe} do sistema?
            </p>

            <div className="justificativa">
              <textarea
                type="text"
                placeholder="Preencha a justificativa... (Mínimo 15 caracteres)"
                rows={5}
                minLength="15"
                ref={justificativaCancelarMdfe}
              />
            </div>

            <div className="botoes">
              <button className="cancelar" onClick={onClose}>
                Não
              </button>
              <button
                className="confirmar"
                onClick={() => cancelarMdfe(idMdfe, onClose)}
              >
                Sim
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const encerrarMdfe = (idMdfe) => {
    api
      .post(`documentos_fiscais/mdfe/${idMdfe}/encerrar/`)
      .then(function () {})
      .catch(function (error) {
        console.log(error);
      });
  };

  const alertEncerrarMdfe = (idMdfe) => {
    confirmAlert({
      title: "Encerrar MDF-e",
      message: `Deseja realmente encerrar o MDF-e de ID ${idMdfe} do sistema?`,
      overlayClassName: "alert-remover-nfe",
      buttons: [
        {
          label: "Sim",
          onClick: () => encerrarMdfe(idMdfe),
          className: "botao-confirmar",
        },
        {
          label: "Não",
          onClick: () => {},
          className: "botao-cancelar",
        },
      ],
    });
  };

  const verificarCertificadoDigital = () => {
    if (
      dadosUsuario.user_type === "transportadora" &&
      !dadosUsuario.certificado_digital_adicionado
    ) {
      return confirmAlert({
        title: "Certificado Digital",
        message:
          "Você ainda não cadastrou o seu certificado digital e senha no sistema!",
        overlayClassName: "alert-certificado-digital",
        buttons: [
          {
            label: "Cadastrar",
            onClick: () => {
              document.querySelector("body").style.overflow = "visible";
              history.replace(
                `/acesso/transportadora/editar-transportadora/${dadosUsuario.transportadora_id}`
              );
            },
            className: "botao-confirmar",
          },
        ],
      });
    }

    handleModalNovoManifesto();
  };

  useEffect(() => {
    requisitarMdfes();
  }, [requisitarMdfes]);

  const handleResolverMdfe = (idMdfe) => {
    api
      .post(`documentos_fiscais/mdfe/${idMdfe}/resolver/`)
      .then(function () {
        confirmAlert({
          title: "Enviado para Sincronizar!",
          message: 'O MDF-e foi enviado para sincronizar. Em alguns instantes a tabela deve atualizar.',
          overlayClassName: "alert-remover-nfe",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                requisitarMdfes()
              }
            },
          ],
        });
      })
      .catch(function (error) {
        confirmAlert({
          title: "Erro!",
          message: `Erro ao resolver MDF-e: ${error.response?.data?.detail}`,
          overlayClassName: "alert-remover-nfe",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      });
  }

  return (
    <div className="tabela-mdfs">
      <button className="btn-financeiro" onClick={verificarCertificadoDigital}>
        Incluir MDF
        <FaPlus className="icone"></FaPlus>
      </button>

      <div className="container-tabela-mdfs">
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>Manifesto</th>
              <th>Protocolo</th>
              <th>Cidade Origem</th>
              <th>Cidade Destino</th>
              <th>Motorista</th>
              <th>Lançamento</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {manifestos.map((manifesto, index) => (
              <Fragment key={manifesto.id}>
                <tr className={`linha par`}>
                  <td className="coluna-acoes">
                    {manifesto.damdfe && (
                      <img
                        src={pdfIcon}
                        alt="pdf"
                        onClick={() =>
                          saveAs(
                            manifesto.damdfe,
                            `Manifesto${manifesto.numero}.pdf`
                          )
                        }
                      />
                    )}
                  </td>
                  <td className="coluna-acoes"></td>
                  <td>{manifesto.numero}</td>
                  <td>{manifesto.protocolo}</td>
                  <td>{manifesto.cidade_origem}</td>
                  <td>{manifesto.cidade_destino}</td>
                  <td>{manifesto.motorista}</td>
                  <td>
                    {manifesto.emissao
                      ? format(parseISO(manifesto.emissao), "dd/MM/yyyy HH:mm")
                      : "-"}
                  </td>
                  <td className="coluna-acoes">
                    {manifesto.status === "Confirmado" && (
                      <RiCloseCircleFill
                        className="icone remover"
                        onClick={() => alertCancelarMdfe(manifesto.id)}
                      ></RiCloseCircleFill>
                    )}
                  </td>
                </tr>

                <tr key={index} className={`linha impar`}>
                  <td className="coluna-acoes">
                    {manifesto.arquivo && (
                      <img
                        src={xmlIcon}
                        alt="xml"
                        onClick={() =>
                          saveAs(
                            manifesto.arquivo,
                            `Manifesto${manifesto.numero}.xml`
                          )
                        }
                      />
                    )}
                  </td>
                  <td className="coluna-acoes">
                    {manifesto.ctes_transportados.length > 0 &&
                      manifesto.ctes_transportados.map((cte) => (
                        <img
                          key={cte.id}
                          src={cteIcon}
                          alt="cte"
                          onClick={() =>
                            saveAs(cte.cte.arquivo, `CT-e${cte.cte.numero}.xml`)
                          }
                        />
                      ))}
                  </td>
                  <td colSpan={2} className="coluna-informacoes">
                    Recebido: <span>{manifesto.recibo}</span>
                    <br />
                    Descrição do Status:
                    {manifesto.status === "Pendente" ||
                    manifesto.status === "Negado" ||
                    manifesto.status === "Cancelado" ? (
                      <span
                        className="status-manifesto"
                        onClick={() => handleModalNovoManifesto(manifesto.id)}
                      >
                        {`${manifesto.status} | ${
                          manifesto?.erros_emissao?.sefaz ||
                          manifesto?.erros_emissao?.tecnospeed ||
                          ""
                        }`}
                      </span>
                    ) : (
                      `${manifesto.status} | ${
                        manifesto?.erros_emissao?.sefaz ||
                        manifesto?.erros_emissao?.tecnospeed ||
                        ""
                      }`
                    )}
                  </td>
                  <td colSpan={2}>Chave: {manifesto.chave}</td>
                  <td>{manifesto.cavalo}</td>
                  <td className="coluna-motorista">
                    {manifesto.status === "Confirmado" && (
                      <span onClick={() => alertEncerrarMdfe(manifesto.id)}>
                        Encerrar Manifesto
                      </span>
                    )}
                  </td>
                  <td className="coluna-acoes">
                    <HiOutlineRefresh
                      className="icone"
                      onClick={() => handleResolverMdfe(manifesto.id)}
                    ></HiOutlineRefresh>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {modalNovoManifesto && (
        <ModalNovoManifesto
          idCarga={idCarga}
          idMdfeSelecionado={idMdfeSelecionado}
          modalNovoManifesto={modalNovoManifesto}
          handleModalNovoManifesto={handleModalNovoManifesto}
          requisitarDocumentos={requisitarMdfes}
        ></ModalNovoManifesto>
      )}
    </div>
  );
};

export default memo(TabelaMDFe);
