import React, { memo, useState, useRef, useEffect, useCallback } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { BsFillCalendarDateFill, BsSearch } from 'react-icons/bs'
import { MdClear } from 'react-icons/md'
import { FiTruck } from 'react-icons/fi'
import { FaTag, FaMapMarkerAlt, FaMapMarker } from 'react-icons/fa'
import { format } from 'date-fns'
import pt from 'date-fns/locale/pt'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import ModalAgendamento from './ModalAgendamento'

const AgendaCargas = () => {
  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/painel-monitoramento', breadcrumb: 'Painel Monitoramento' },
    { path: '/acesso/painel-monitoramento/agenda', breadcrumb: 'Agenda' },
  ]

  const events = [
    {
      id: 1,
      title: 'event 1',
      start: '2022-02-15T10:00:00',
      end: '2022-02-15T12:00:00',
      tipo: "embarcado"
    },
    {
      id: 2,
      title: 'event 2',
      start: '2022-02-14T13:00:00',
      end: '2022-02-14T14:00:00',
      tipo: "entrega-prevista"
    },
    {
      id: 3,
      title: 'event 3',
      start: '2022-02-15T15:00:00',
      end: '2022-02-15T16:00:00',
      tipo: "inicio-viagem"
    },
    {
      id: 4,
      title: 'event 4',
      start: '2022-02-15T08:00:00',
      end: '2022-02-15T09:30:00',
      tipo: "embarcando"
    },
    {
      id: 5,
      title: 'event 5',
      start: '2022-02-16T12:00:00',
      end: '2022-02-16T13:00:00',
      tipo: "programado"
    },
    {
      id: 6,
      title: 'event 6',
      start: '2022-02-16T18:00:00',
      end: '2022-02-16T19:00:00',
      tipo: "entregue"
    },
    {
      id: 7,
      title: 'event 7',
      start: '2022-02-16T14:00:00',
      end: '2022-02-16T16:00:00',
      tipo: "portaria"
    },
  ]

  const [openDay, setOpenDay] = useState(false)
  const [modalAgendamento, setModalAgendamento] = useState(false)
  const [tipoEventoSelecionado, setTipoEventoSelecionado] = useState('')
  const ref = useRef()

  const [filtroCarga, setFiltroCarga] = useState('')
  const [filtroTransportadora, setFiltroTransportadora] = useState('')
  const [filtroTipoCarga, setFiltroTipoCarga] = useState('')
  const [filtroOrigem, setFiltroOrigem] = useState('')
  const [filtroDestino, setFiltroDestino] = useState('')
  const [checkboxTodos, setCheckboxTodos] = useState(false)
  const [checkboxProgramado, setCheckboxProgramado] = useState(false)
  const [checkboxPortaria, setCheckboxPortaria] = useState(false)
  const [checkboxEmbarcando, setCheckboxEmbarcando] = useState(false)
  const [checkboxEmbarcado, setCheckboxEmbarcado] = useState(false)
  const [checkboxInicioViagem, setCheckboxInicioViagem] = useState(false)
  const [checkboxEntregaPrevista, setCheckboxEntregaPrevista] = useState(false)
  const [checkboxEntregue, setCheckboxEntregue] = useState(false)

  const TituloDia = ({ args }) => {
    let dataDoDia = args.date.getDate()

    let embarcado = []
    let inicioViagem = []
    let entregaPrevista = []
    let embarcando = []
    let programado = []
    let entregue = []
    let portaria = []

    events.forEach(item => {
      if (new Date(item.start).getDate() === dataDoDia) {
        switch (item.tipo) {
          case "embarcado":
            return embarcado.push(item)
          case "entrega-prevista":
            return entregaPrevista.push(item)
          case "inicio-viagem":
            return inicioViagem.push(item)
          case "embarcando":
            return embarcando.push(item)
          case "programado":
            return programado.push(item)
          case "entregue":
            return entregue.push(item)
          case "portaria":
            return portaria.push(item)
          default:
            return
        }
      }
    })

    return (
      <div className="container-titulo-dia">
        <p className="dia-mes">{format(args.date, "dd/MM", { locale: pt })}</p>
        <p className="dia-semana">{format(args.date, "iiii", { locale: pt })}</p>
        <div className="eventos">
          {embarcado.length > 0 &&
            <div className="evento embarcado">
              <span>{embarcado.length}</span>
            </div>
          }
          {inicioViagem.length > 0 &&
            <div className="evento inicio-viagem">
              <span>{inicioViagem.length}</span>
            </div>
          }
          {entregaPrevista.length > 0 &&
            <div className="evento entrega-prevista">
              <span>{entregaPrevista.length}</span>
            </div>
          }
          {embarcando.length > 0 &&
            <div className="evento embarcando">
              <span>{embarcando.length}</span>
            </div>
          }
          {programado.length > 0 &&
            <div className="evento programado">
              <span>{programado.length}</span>
            </div>
          }
          {entregue.length > 0 &&
            <div className="evento entregue">
              <span>{entregue.length}</span>
            </div>
          }
          {portaria.length > 0 &&
            <div className="evento portaria">
              <span>{portaria.length}</span>
            </div>
          }
        </div>
      </div>
    )
  }

  const handleModalAgendamento = useCallback((tipoEvento) => {
    if (modalAgendamento) {
      setModalAgendamento(false)
      document.querySelector("body").style.overflow = "visible"
    } else {
      setTipoEventoSelecionado(tipoEvento)
      setModalAgendamento(true)
      document.querySelector("body").style.overflow = "hidden"
    }
  }, [modalAgendamento])

  const RenderEventos = ({ args }) => {
    let tipoEvento = args.event._def.extendedProps.tipo

    const verificarTipo = () => {
      switch (tipoEvento) {
        case "embarcado":
          return "Embarcado"
        case "entrega-prevista":
          return "Entrega Prevista"
        case "inicio-viagem":
          return "Inicio Viagem"
        case "embarcando":
          return "Embarcando"
        case "programado":
          return "Programado"
        case "entregue":
          return "Entregue"
        case "portaria":
          return "Portaria"
        default:
          break
      }
    }

    return (
      <div className={`container-evento ${tipoEvento}`} onClick={() => handleModalAgendamento(tipoEvento)}>
        <p className="titulo">{verificarTipo()}</p>
        <div className="quantidade">
          <p>3</p>
        </div>
      </div>
    )
  }

  useEffect(() => {
    openDay
      ? ref.current.getApi().changeView('timeGridDay', new Date())
      : ref.current.getApi().changeView('timeGridWeek')
  }, [openDay])

  return (
    <div className="container-agenda-cargas">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <div className="container-filtros-agenda">
        <div className="input-filtro">
          <BsSearch className="icone-filtro"></BsSearch>
          <input
            type="text"
            placeholder="Pesquisar carga"
            value={filtroCarga}
            onChange={(e) => setFiltroCarga(e.target.value)}
          />
          <MdClear onClick={() => setFiltroCarga('')} className="icone-limpar"></MdClear>
        </div>

        <div className="input-filtro">
          <FiTruck className="icone-filtro"></FiTruck>
          <input
            type="text"
            placeholder="Transportadora"
            value={filtroTransportadora}
            onChange={(e) => setFiltroTransportadora(e.target.value)}
          />
          <MdClear onClick={() => setFiltroTransportadora('')} className="icone-limpar"></MdClear>
        </div>

        <div className="input-filtro">
          <FaTag className="icone-filtro"></FaTag>
          <input
            type="text"
            placeholder="Tipo de Carga"
            value={filtroTipoCarga}
            onChange={(e) => setFiltroTipoCarga(e.target.value)}
          />
          <MdClear onClick={() => setFiltroTipoCarga('')} className="icone-limpar"></MdClear>
        </div>

        <div className="input-filtro">
          <FaMapMarkerAlt className="icone-filtro"></FaMapMarkerAlt>
          <input
            type="text"
            placeholder="Origem em"
            value={filtroOrigem}
            onChange={(e) => setFiltroOrigem(e.target.value)}
          />
          <MdClear onClick={() => setFiltroOrigem('')} className="icone-limpar"></MdClear>
        </div>

        <div className="input-filtro">
          <FaMapMarker className="icone-filtro"></FaMapMarker>
          <input
            type="text"
            placeholder="Destino em"
            value={filtroDestino}
            onChange={(e) => setFiltroDestino(e.target.value)}
          />
          <MdClear onClick={() => setFiltroDestino('')} className="icone-limpar"></MdClear>
        </div>
      </div>

      <div className="container-status-agenda">
        <div className="container-input-checkbox">
          <span
            className="checkbox"
            onClick={() => setCheckboxTodos(!checkboxTodos)}
          >
            <input
              type="checkbox"
              checked={checkboxTodos}
              onChange={() => setCheckboxTodos(!checkboxTodos)}
            />
            <span className="checked"></span>
            <div className="label">
              <p>Todos</p>
              <div className="cor todos"></div>
            </div>
          </span>
        </div>

        <div className="container-input-checkbox">
          <span
            className="checkbox"
            onClick={() => setCheckboxProgramado(!checkboxProgramado)}
          >
            <input
              type="checkbox"
              checked={checkboxProgramado}
              onChange={() => setCheckboxProgramado(!checkboxProgramado)}
            />
            <span className="checked"></span>
            <div className="label">
              <p>Programado para Embarque</p>
              <div className="cor programado"></div>
            </div>
          </span>
        </div>

        <div className="container-input-checkbox">
          <span
            className="checkbox"
            onClick={() => setCheckboxPortaria(!checkboxPortaria)}
          >
            <input
              type="checkbox"
              checked={checkboxPortaria}
              onChange={() => setCheckboxPortaria(!checkboxPortaria)}
            />
            <span className="checked"></span>
            <div className="label">
              <p>Portaria</p>
              <div className="cor portaria"></div>
            </div>
          </span>
        </div>

        <div className="container-input-checkbox">
          <span
            className="checkbox"
            onClick={() => setCheckboxEmbarcando(!checkboxEmbarcando)}
          >
            <input
              type="checkbox"
              checked={checkboxEmbarcando}
              onChange={() => setCheckboxEmbarcando(!checkboxEmbarcando)}
            />
            <span className="checked"></span>
            <div className="label">
              <p>Embarcando</p>
              <div className="cor embarcando"></div>
            </div>
          </span>
        </div>

        <div className="container-input-checkbox">
          <span
            className="checkbox"
            onClick={() => setCheckboxEmbarcado(!checkboxEmbarcado)}
          >
            <input
              type="checkbox"
              checked={checkboxEmbarcado}
              onChange={() => setCheckboxEmbarcado(!checkboxEmbarcado)}
            />
            <span className="checked"></span>
            <div className="label">
              <p>Embarcado</p>
              <div className="cor embarcado"></div>
            </div>
          </span>
        </div>

        <div className="container-input-checkbox">
          <span
            className="checkbox"
            onClick={() => setCheckboxInicioViagem(!checkboxInicioViagem)}
          >
            <input
              type="checkbox"
              checked={checkboxInicioViagem}
              onChange={() => setCheckboxInicioViagem(!checkboxInicioViagem)}
            />
            <span className="checked"></span>
            <div className="label">
              <p>Início Viagem</p>
              <div className="cor inicio-viagem"></div>
            </div>
          </span>
        </div>

        <div className="container-input-checkbox">
          <span
            className="checkbox"
            onClick={() => setCheckboxEntregaPrevista(!checkboxEntregaPrevista)}
          >
            <input
              type="checkbox"
              checked={checkboxEntregaPrevista}
              onChange={() => setCheckboxEntregaPrevista(!checkboxEntregaPrevista)}
            />
            <span className="checked"></span>
            <div className="label">
              <p>Entrega Prevista</p>
              <div className="cor entrega-prevista"></div>
            </div>
          </span>
        </div>

        <div className="container-input-checkbox">
          <span
            className="checkbox"
            onClick={() => setCheckboxEntregue(!checkboxEntregue)}
          >
            <input
              type="checkbox"
              checked={checkboxEntregue}
              onChange={() => setCheckboxEntregue(!checkboxEntregue)}
            />
            <span className="checked"></span>
            <div className="label">
              <p>Entregue</p>
              <div className="cor entregue"></div>
            </div>
          </span>
        </div>
      </div>

      <div className="agenda-cargas">
        <div className="calendario">
          <FullCalendar
            ref={ref}
            locale={"pt"}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            headerToolbar={{
              start: `icone dayOrWeek prev next title`,
              center: '',
              end: ""
            }}
            customButtons={{
              icone: {
                text: <BsFillCalendarDateFill className="icone-calendario"></BsFillCalendarDateFill>,
                click: () => setOpenDay(!openDay)
              },
              dayOrWeek: {
                text: openDay ? "Semana" : "Hoje",
                click: () => setOpenDay(!openDay)
              }
            }}
            titleFormat={{ day: 'numeric', year: 'numeric', month: 'long' }}
            slotLabelFormat={{ hour: 'numeric', minute: '2-digit', omitZeroMinute: false }}
            slotLabelClassNames="hour-day"
            allDaySlot={false}
            nowIndicator
            nowIndicatorContent={`${new Date().getHours()}:${new Date().getMinutes()}`}
            nowIndicatorClassNames="indicator-hour"
            slotMinTime={"06:00:00"}
            slotMaxTime={"20:00:00"}
            height="auto"
            dayHeaderContent={args => <TituloDia args={args}></TituloDia>}
            events={events}
            eventContent={args => <RenderEventos args={args}></RenderEventos>}
            eventClassNames="evento"
            dateClick={(e) => console.log(e.dateStr)}
            eventClick={(e) => console.log(e.event.id)}
          />
        </div>
      </div>

      {modalAgendamento &&
        <ModalAgendamento
          modalAgendamento={modalAgendamento}
          handleModalAgendamento={handleModalAgendamento}
          tipoEventoSelecionado={tipoEventoSelecionado}
        ></ModalAgendamento>
      }
    </div>
  )
}

export default memo(AgendaCargas)