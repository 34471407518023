import React, { memo, useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  BsFillTelephoneFill,
  BsBookmarkStarFill,
  BsBookmarkStar,
  BsStar,
  BsStarFill,
} from "react-icons/bs";
import { FiTruck } from "react-icons/fi";
import { FaRegIdCard, FaRegChartBar, FaRegCreditCard } from "react-icons/fa";
import { MdCheck, MdOutlineClear } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";
// import { GiPathDistance } from 'react-icons/gi'
import Rating from "react-rating";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./styles.css";

import Informacoes from "./Informacoes";
import Historico from "./Historico";
import Avaliacoes from "./Avaliacoes";
import Pagamentos from "./Pagamentos";
import api from "../../../../../../../services/api";

const ModalPerfilMotorista = ({
  idCandidatura,
  motorista,
  modalPerfilMotorista,
  handleModalPerfilMotorista,
  handleModalPropostaCarga,
  
  editable,
  idCandidaturaRetificar,

  onSaved,
}) => {
  // const motorista = motorista
  const [idMotoristaFavorito, setIdMotoristaFavorito] = useState(null);

  const checkboxRealizarConsulta = useRef(true);

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));

  const reprovarProposta = () => {
    const reprovar = (onClose) => {
      api
        .post(`/candidatura/${idCandidatura}/negar_candidatura/`)
        .then(function () {
          onClose();
          handleModalPropostaCarga();
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="container-alert-proposta">
            <p className="titulo">
              Você deseja realmente <span className="reprovar">reprovar</span> o
              motorista para a carga?
            </p>

            <div className="motorista">
              <img src={motorista.imagem_perfil} alt="" />
              <p>{motorista.nome_completo}</p>
            </div>

            <div className="botoes">
              <button className="cancelar" onClick={onClose}>
                Não
              </button>
              <button className="confirmar" onClick={() => reprovar(onClose)}>
                Sim
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const aprovarProposta = () => {
    const aprovar = (onClose) => {
      api
        .post(`/candidatura/${idCandidatura}/aprovar_candidatura/`)
        .then(function () {
          onClose();
          handleModalPropostaCarga();
        })
        .catch(function (error) {
        });
    };

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="container-alert-proposta">
            <div className="container-input-checkbox-consulta">
              <input
                type="checkbox"
                ref={checkboxRealizarConsulta}
                className="teste"
              />
              <p>Realizar consulta no gerenciador de risco.</p>
            </div>

            <div className="motorista">
              <img src={motorista.imagem_perfil} alt="" />
              <p>{motorista.nome_completo}</p>
            </div>

            <p className="titulo">
              Você deseja realmente <span className="aprovar">aprovar</span> o
              motorista para a carga?
            </p>

            <div className="botoes">
              <button className="cancelar" onClick={onClose}>
                Não
              </button>
              <button className="confirmar" onClick={() => aprovar(onClose)}>
                Sim
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const handleMotoristaFavorito = () => {
    if (idMotoristaFavorito !== null) {
      api
        .delete(`motoristafavorito/${idMotoristaFavorito}/`)
        .then(function () {
          setIdMotoristaFavorito(null);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      api
        .post(`motoristafavorito/`, {
          transportadora: dadosUsuario.transportadora_id,
          motorista: motorista.id,
        })
        .then(function (response) {
          setIdMotoristaFavorito(response.data.id);
        })
        .catch(function (error) {
          console.log(error.response.data);
        });
    }
  };

  useEffect(() => {
    api
      .get(
        `motoristafavorito/?transportadora=${dadosUsuario.transportadora_id}`
      )
      .then(function (response) {
        let dados = response.data.results;

        dados.forEach((item) => {
          if (item.motorista.id === motorista.id) {
            setIdMotoristaFavorito(item.id);
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [dadosUsuario.transportadora_id, motorista.id]);

  return (
    <Modal
      isOpen={modalPerfilMotorista}
      onRequestClose={handleModalPerfilMotorista}
      className="modal-perfil-motorista"
      overlayClassName="overlay-modal-perfil-motorista"
      ariaHideApp={false}
    >
      <div className="detalhes-motorista">
        <div className="quadro-topo">
          {idMotoristaFavorito !== null ? (
            <div
              className="container-favorito"
              onClick={handleMotoristaFavorito}
            >
              <BsBookmarkStarFill className="icone-estrela"></BsBookmarkStarFill>
              <div className="background"></div>
            </div>
          ) : (
            <BsBookmarkStar
              className="icone-estrela"
              onClick={handleMotoristaFavorito}
            ></BsBookmarkStar>
          )}

          <div className="rating">
            <Rating
              initialRating={motorista.estatisticas?.avaliacao || 0}
              emptySymbol={<BsStar className="icone vazio"></BsStar>}
              fullSymbol={
                <BsStarFill className="icone preenchido"></BsStarFill>
              }
            />
          </div>

          <RiCloseCircleFill
            className="icone-fechar"
            onClick={handleModalPerfilMotorista}
          ></RiCloseCircleFill>
        </div>

        <div className="foto-id">
          <div className="telefones">
            <div>
              <BsFillTelephoneFill className="icone"></BsFillTelephoneFill>
              <p>{motorista.telefone1}</p>
            </div>
            {motorista.telefone2 && (
              <div>
                <BsFillTelephoneFill className="icone"></BsFillTelephoneFill>
                <p>{motorista.telefone2}</p>
              </div>
            )}
          </div>

          <img src={motorista.imagem_perfil} alt="" />

          <div className="id">
            <FaRegIdCard className="icone"></FaRegIdCard>
            <p>
              <i>ID: </i>
              {motorista.id}
            </p>
          </div>
        </div>

        <div className="dados">
          <p className="nome">{motorista.nome_completo}</p>
          <p className="cpf">
            CPF:{" "}
            {motorista.documentos.cpf
              .replace(/\D/g, "")
              .replace(/(\d{3})(\d)/, "$1.$2")
              .replace(/(\d{3})(\d)/, "$1.$2")
              .replace(/(\d{3})(\d{1,2})$/, "$1-$2")}
          </p>
        </div>

        <div className="veiculo">
          <p>
            <span>{motorista.veiculo.tipo_veiculo.tipo}</span> -
            {motorista.veiculo.carrocerias.map((item, index) =>
              index < motorista.veiculo.carrocerias.length - 1
                ? `${item.tipo.tipo}, `
                : ` ${item.tipo.tipo}`
            )}
          </p>
        </div>

        <div className="placas">
          <div>
            <p>{motorista.veiculo.placa_cavalo}</p>
          </div>
          <div>
            {motorista.veiculo.carrocerias.map((item) => (
              <p key={item.id}>{item.placa}</p>
            ))}
          </div>
        </div>

        {/* <div className="linha">
          <GiPathDistance className="icone"></GiPathDistance>
          <p><span>Distância até o local de embarque: </span>{candidatura.estatistica.distancia} km</p>
        </div> */}

        {idCandidatura && (
          <div className="botoes">
            <button className="recusar" onClick={reprovarProposta}>
              <MdOutlineClear className="icone"></MdOutlineClear>
            </button>
            <button className="aceitar" onClick={aprovarProposta}>
              <MdCheck className="icone"></MdCheck>
            </button>
          </div>
        )}
      </div>

      <div className="todas-informacoes">
        <Tabs>
          <TabList className="container-abas">
            <Tab className="aba" selectedClassName="selecionada">
              <AiOutlineInfoCircle className="icone"></AiOutlineInfoCircle>
              <p>Informações</p>
            </Tab>
            <Tab className="aba" selectedClassName="selecionada">
              <FiTruck className="icone"></FiTruck>
              <p>Histórico</p>
            </Tab>
            <Tab className="aba" selectedClassName="selecionada">
              <FaRegChartBar className="icone"></FaRegChartBar>
              <p>Avaliações</p>
            </Tab>
            <Tab className="aba" selectedClassName="selecionada">
              <FaRegCreditCard className="icone"></FaRegCreditCard>
              <p>Pagamentos</p>
            </Tab>
          </TabList>

          <TabPanel>
            <Informacoes
              handleModalPerfilMotorista={handleModalPerfilMotorista}
              motorista={motorista}
              editable={editable}
              idCandidaturaRetificar={idCandidaturaRetificar}
              onSaved={onSaved}
            />
          </TabPanel>
          <TabPanel>
            <Historico motorista={motorista}></Historico>
          </TabPanel>
          <TabPanel>
            <Avaliacoes motorista={motorista}></Avaliacoes>
          </TabPanel>
          <TabPanel>
            <Pagamentos
              id={motorista.id}
              dadosBancarios={motorista.dados_bancarios}
              editable={editable}
              onSaved={onSaved}
            />
          </TabPanel>
        </Tabs>
      </div>
    </Modal>
  );
};

export default memo(ModalPerfilMotorista);
