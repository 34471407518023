import React, { memo, useState, useCallback } from "react";
import { GiWallet } from "react-icons/gi";

import "./styles.css";

import Pagamento from "../../../Pagamento";

import api from "../../../../../../services/api";

const SemCalculadora = () => {
  const [quantidadeRastroCoins, setQuantidadeRastroCoins] = useState(0);
  const [totalPagar, setTotalPagar] = useState(0);
  const [modalPagamento, setModalPagamento] = useState(false);

  const handleModalPagamento = useCallback(() => {
    if (modalPagamento) {
      setModalPagamento(false);
      document.querySelector("body").style.overflow = "visible";
    } else {
      setModalPagamento(true);
      document.querySelector("body").style.overflow = "hidden";
    }
  }, [modalPagamento]);

  const calculaTotalAPagar = useCallback(() => {
    let dados = {
      coins: quantidadeRastroCoins,
    };

    api
      .post("pagamentos/coins/preco/", dados)
      .then(function (response) {
        setTotalPagar(response.data.price);
      })
      .catch(function (e) {
        console.log(e.response.data);
      });
  }, [quantidadeRastroCoins]);

  return (
    <div className="sem-calculadora">
      <p className="subtitulo">
        Informe a quantidade de Rastro coins desejada abaixo:
      </p>

      <div className="quadro-total">
        <p>Informe A Quantidade De Rastro Coins</p>

        <div className="input-quantidade">
          <input
            type="number"
            placeholder="Digite..."
            value={quantidadeRastroCoins}
            onChange={(e) => setQuantidadeRastroCoins(e.target.value)}
            onBlur={calculaTotalAPagar}
          />
        </div>
      </div>

      <button className="btn-comprar" onClick={handleModalPagamento}>
        <div>
          <GiWallet className="icone" />
          <p>Comprar Agora</p>
        </div>
      </button>

      {modalPagamento && (
        <Pagamento
          modalPagamento={modalPagamento}
          handleModalPagamento={handleModalPagamento}
          totalSugeridoCoins={quantidadeRastroCoins}
          quantidadeMeses={1}
          totalPagar={totalPagar}
        ></Pagamento>
      )}
    </div>
  );
};

export default memo(SemCalculadora);
