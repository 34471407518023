import { useMutation, useQueryClient } from 'react-query';
import { useSearch } from '../../hooks';
import api from '../../../services/api';
import { useMessages } from '../../../context/messages-context';

export const useCreateColetaMutation = () => {
  const { showApiErrors } = useMessages();
  const { page } = useSearch();
  const queryClient = useQueryClient();

  return useMutation(async ({ files }) => {
    const result = [];
    for (const item of files) {
      try {
        const formData = new FormData();
        formData.append("nfe", item)

        const { data } = await api.post(`fracionado/coletas/`, formData);
        result.push(data);
      } catch (err) {
        showApiErrors(err);
        return null;
      }
    }

    await queryClient.invalidateQueries(['coletas']);
    await queryClient.refetchQueries(['coletas', page]);
    return result;
  });
}
