import React, { useState, memo, useCallback } from "react";
import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  Menu,
  SubMenu,
  MenuItem,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {
  FaTruckLoading,
  FaTruck,
  FaUserPlus,
  FaRegUserCircle,
  FaClipboardList,
  FaMoneyBillWave,
  FaFileContract,
} from "react-icons/fa";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { BiMenuAltRight } from "react-icons/bi";
import { RiCarFill, RiDashboardFill } from "react-icons/ri";
import { FiTruck, FiUser } from "react-icons/fi";
import { ImAddressBook, ImExit } from "react-icons/im";
import { GiWallet } from "react-icons/gi";
import { Link, useHistory } from "react-router-dom";
import { IoSettingsSharp } from "react-icons/io5";
import Homologacao from '../../homologacao';

import "./custom.scss";
import "./styles.css";

import logo from "../../../assets/logo.png";
import ModalTutorial from "../ModalTutorial";
import ModalVideo from "../ModalTutorial/ModalVideo";

const MenuLateral = () => {
  const history = useHistory();

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
  const tipoUsuario = dadosUsuario.user_type;

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [collapsed, setCollapsed] = useState(true);
  const [menusOpen, setMenusOpen] = useState({
    painelMonitoramento: false,
    cadastros: false,
    rastroCoins: false,
  });

  const [submenusOpen, setSubmenusOpen] = useState({
    embarcador: false,
    transportadora: false,
    motorista: false,
    destinatario: false,
    tipoDeCaminhao: false,
    carroceria: false,
    colaborador: false,
    financeiro: false,
    contrato: false,
    cliente: false,
  });

  const [modalTutorial, setModalTutorial] = useState(false);
  const [modalVideo, setModalVideo] = useState({
    modal_video: false,
    titulo: '',
    videoId: '',
    videoIdYoutube: '',
  }

  )

  const abrirMenuLateral = () => {
    setCollapsed(false);
  };

  const abrirMenuSelecionado = (chave) => {
    let auxMenusOpen = menusOpen;

    if (auxMenusOpen[chave]) {
      auxMenusOpen[chave] = false;
    } else {
      for (let property in auxMenusOpen) {
        auxMenusOpen[property] = false;
      }

      auxMenusOpen[chave] = true;
    }

    setMenusOpen(auxMenusOpen);

    forceUpdate();
  };

  const abrirSubmenuSelecionado = (chave) => {
    let auxSubmenusOpen = submenusOpen;

    if (auxSubmenusOpen[chave]) {
      auxSubmenusOpen[chave] = false;
    } else {
      for (let property in auxSubmenusOpen) {
        auxSubmenusOpen[property] = false;
      }
      auxSubmenusOpen[chave] = true;
    }

    setSubmenusOpen(auxSubmenusOpen);

    forceUpdate();
  };

  const logout = () => {
    localStorage.removeItem("@RASTRO:TOKEN");
    localStorage.removeItem("@RASTRO:REFRESH_TOKEN");
    localStorage.removeItem("@RASTRO:USER");
    history.replace("/");
  };

  return (
    <>
      <ProSidebar
        className="menuLateral"
        collapsed={collapsed}
        onMouseLeave={() => setCollapsed(true)}
      >
        <SidebarHeader className="header">
          <div>
            <div className="container-logo">
              <img src={logo} alt="" />
            </div>
            <BiMenuAltRight
              className="icone"
              onClick={() => setCollapsed(true)}
            ></BiMenuAltRight>
          </div>
        </SidebarHeader>

        <SidebarContent className="conteudo">
          {/* <Menu className='menu-dashboard' onClick={abrirMenuLateral}>
          <MenuItem className='item-menu-dashboard' icon={<RiComputerLine className='icone' />}>
            <Link to='/acesso'>
              Dashboard
            </Link>
          </MenuItem>
        </Menu> */}

          <Menu className="menu-opcoes principal" onClick={abrirMenuLateral}>
            <SubMenu
              className="submenu-opcoes"
              title="Painel Monitoramento"
              icon={<RiDashboardFill className="icone" />}
              open={menusOpen.painelMonitoramento}
              onOpenChange={() => abrirMenuSelecionado("painelMonitoramento")}
            >
              <MenuItem>
                <Link to="/acesso/painel-monitoramento">Painel</Link>
              </MenuItem>
              {/* <MenuItem>
              <Link to='/acesso/painel-monitoramento/agenda'>
                Agenda
              </Link>
            </MenuItem> */}
              <MenuItem>
                <Link to="/acesso/painel-monitoramento/historico">Histórico</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/acesso/painel-monitoramento/documentos">
                  Documentos
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/acesso/coletas">
                  Coletas
                </Link>
              </MenuItem>
            </SubMenu>
          </Menu>

          <Menu className="menu-opcoes principal" onClick={abrirMenuLateral}>
            <SubMenu
              className="submenu-opcoes"
              title="Cadastros"
              icon={<FaClipboardList className="icone embarcador" />}
              open={menusOpen.cadastros}
              onOpenChange={() => abrirMenuSelecionado("cadastros")}
            >
              {tipoUsuario === "admin" && (
                <Menu
                  className="menu-opcoes embarcador"
                  onClick={abrirMenuLateral}
                >
                  <SubMenu
                    className="submenu-opcoes cadastro"
                    title="Embarcador"
                    icon={<FaTruck className="icone embarcador" />}
                    open={submenusOpen.embarcador}
                    onOpenChange={() => abrirSubmenuSelecionado("embarcador")}
                  >
                    <MenuItem>
                      <Link to="/acesso/embarcador/novo-embarcador">
                        Novo Embarcador
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to="/acesso/embarcador/cadastro-simplificado">
                        Cadastro Simplificado
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to="/acesso/embarcador/gerenciar-embarcador">
                        Gerenciar Embarcadores
                      </Link>
                    </MenuItem>
                  </SubMenu>
                </Menu>
              )}

              {(tipoUsuario === "admin" ||
                tipoUsuario === "embarcador" ||
                tipoUsuario === "colaborador_embarcador") && (
                  <Menu className="menu-opcoes" onClick={abrirMenuLateral}>
                    <SubMenu
                      className="submenu-opcoes cadastro"
                      title="Transportadora"
                      icon={<FiTruck className="icone" />}
                      open={submenusOpen.transportadora}
                      onOpenChange={() => abrirSubmenuSelecionado("transportadora")}
                    >
                      <>
                        <MenuItem>
                          <Link to="/acesso/transportadora/nova-transportadora">
                            Nova Transportadora
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link to="/acesso/transportadora/cadastro-simplificado">
                            Cadastro Simplificado
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link to="/acesso/transportadora/gerenciar-transportadora">
                            Gerenciar Transportadoras
                          </Link>
                        </MenuItem>
                      </>
                      {(tipoUsuario === "embarcador" ||
                        tipoUsuario === "colaborador_embarcador") && (
                          <>
                            <MenuItem>
                              <Link to="/acesso/transportadora/transportadoras-publicas">
                                Transportadoras Públicas
                              </Link>
                            </MenuItem>
                            <MenuItem>
                              <Link to="/acesso/transportadora/transportadoras-favoritas">
                                Favoritas
                              </Link>
                            </MenuItem>
                          </>
                        )}
                    </SubMenu>
                  </Menu>
                )}

              {(tipoUsuario === "admin" ||
                tipoUsuario === "transportadora" ||
                tipoUsuario === "colaborador_transportadora") && (
                  <Menu className="menu-opcoes" onClick={abrirMenuLateral}>
                    <SubMenu
                      className="submenu-opcoes cadastro"
                      title="Motorista"
                      icon={<FaUserPlus className="icone" />}
                      open={submenusOpen.motorista}
                      onOpenChange={() => abrirSubmenuSelecionado("motorista")}
                    >
                      <MenuItem>
                        <Link to="/acesso/motorista/novo-motorista">
                          Novo Motorista
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/acesso/motorista/cadastro-simplificado">
                          Cadastro Simplificado
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/acesso/motorista/gerenciar-motorista">
                          Gerenciar Motoristas
                        </Link>
                      </MenuItem>
                      {(tipoUsuario === "transportadora" ||
                        tipoUsuario === "colaborador_transportadora") && (
                          <>
                            <MenuItem>
                              <Link to="/acesso/motorista/motoristas-publicos">
                                Motoristas Públicos
                              </Link>
                            </MenuItem>
                            <MenuItem>
                              <Link to="/acesso/motorista/grupos-motoristas">
                                Grupos de Motoristas
                              </Link>
                            </MenuItem>
                            <MenuItem>
                              <Link to="/acesso/motorista/motoristas-favoritos">
                                Favoritos
                              </Link>
                            </MenuItem>
                          </>
                        )}
                    </SubMenu>
                  </Menu>
                )}

              {(tipoUsuario === "admin" ||
                tipoUsuario === "transportadora" ||
                tipoUsuario === "colaborador_transportadora") && (
                  <Menu className="menu-opcoes" onClick={abrirMenuLateral}>
                    <SubMenu
                      className="submenu-opcoes cadastro"
                      title="Financeiro"
                      icon={<FaMoneyBillWave className="icone" />}
                      open={submenusOpen.financeiro}
                      onOpenChange={() => abrirSubmenuSelecionado("financeiro")}
                    >
                      <MenuItem>
                        <Link to="/acesso/financeiro">Gerenciar Financeiro</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/acesso/aliquotas">Gerenciar Alíquotas</Link>
                      </MenuItem>
                    </SubMenu>
                  </Menu>
                )}

              {(tipoUsuario === "admin" ||
                tipoUsuario === "transportadora" ||
                tipoUsuario === "colaborador_transportadora") && (
                  <Menu className="menu-opcoes" onClick={abrirMenuLateral}>
                    <SubMenu
                      className="submenu-opcoes cadastro"
                      title="Contrato"
                      icon={<FaFileContract className="icone" />}
                      open={submenusOpen.contrato}
                      onOpenChange={() => abrirSubmenuSelecionado("contrato")}
                    >
                      <MenuItem>
                        <Link to="/acesso/contrato">Gerenciar Contrato</Link>
                      </MenuItem>
                    </SubMenu>
                  </Menu>
                )}

              <Menu className="menu-opcoes" onClick={abrirMenuLateral}>
                <SubMenu
                  className="submenu-opcoes cadastro"
                  title="Destinatário"
                  icon={<ImAddressBook className="icone" />}
                  open={submenusOpen.destinatario}
                  onOpenChange={() => abrirSubmenuSelecionado("destinatario")}
                >
                  <MenuItem>
                    <Link to="/acesso/destinatario/novo-destinatario">
                      Novo Destinatário
                    </Link>
                  </MenuItem>
                  {/* <MenuItem>Cadastro Simplificado</MenuItem> */}
                  <MenuItem>
                    <Link to="/acesso/destinatario/gerenciar-destinatario">
                      Gerenciar Destinatários
                    </Link>
                  </MenuItem>
                </SubMenu>
              </Menu>

              {tipoUsuario === "admin" && (
                <Menu className="menu-opcoes" onClick={abrirMenuLateral}>
                  <SubMenu
                    className="submenu-opcoes cadastro"
                    title="Tipo De Caminhão"
                    icon={<RiCarFill className="icone" />}
                    open={submenusOpen.tipoDeCaminhao}
                    onOpenChange={() => abrirSubmenuSelecionado("tipoDeCaminhao")}
                  >
                    <MenuItem>
                      <Link to="/acesso/tipo-caminhao/novo-tipo-caminhao">
                        Novo Tipo De Caminhão
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to="/acesso/tipo-caminhao/gerenciar-tipo-caminhao">
                        Gerenciar Tipos De Caminhões
                      </Link>
                    </MenuItem>
                  </SubMenu>
                </Menu>
              )}

              {tipoUsuario === "admin" && (
                <Menu className="menu-opcoes" onClick={abrirMenuLateral}>
                  <SubMenu
                    className="submenu-opcoes cadastro"
                    title="Carroceria"
                    icon={<FaTruckLoading className="icone" />}
                    open={submenusOpen.carroceria}
                    onOpenChange={() => abrirSubmenuSelecionado("carroceria")}
                  >
                    <MenuItem>
                      <Link to="/acesso/carroceria/nova-carroceria">
                        Nova Carroceria
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to="/acesso/carroceria/gerenciar-carroceria">
                        Gerenciar Carrocerias
                      </Link>
                    </MenuItem>
                  </SubMenu>
                </Menu>
              )}

              <Menu className="menu-opcoes" onClick={abrirMenuLateral}>
                <SubMenu
                  className="submenu-opcoes cadastro"
                  title="Colaborador"
                  icon={<FiUser className="icone" />}
                  open={submenusOpen.colaborador}
                  onOpenChange={() => abrirSubmenuSelecionado("colaborador")}
                >
                  <MenuItem>
                    <Link to="/acesso/colaborador/novo-colaborador">
                      Novo Colaborador
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/acesso/colaborador/gerenciar-colaborador">
                      Gerenciar Colaboradores
                    </Link>
                  </MenuItem>
                  {/* <MenuItem>
                  <Link to='/acesso/colaborador/permissoes'>
                    Permissões
                  </Link>
                </MenuItem> */}
                </SubMenu>
              </Menu>


              <Menu className="menu-opcoes" onClick={abrirMenuLateral}>
                <SubMenu
                  className="submenu-opcoes cadastro"
                  title="Cliente"
                  icon={<FiUser className="icone" />}
                  open={submenusOpen.cliente}
                  onOpenChange={() => abrirSubmenuSelecionado("cliente")}
                >
                  <MenuItem>
                    <Link to="/acesso/clientes/novo">
                      Novo Cliente
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/acesso/clientes/gerenciar">
                      Gerenciar Clientes
                    </Link>
                  </MenuItem>
                </SubMenu>
              </Menu>
            </SubMenu>
          </Menu>

          <Menu className="menu-opcoes principal" onClick={abrirMenuLateral}>
            <SubMenu
              className="submenu-opcoes"
              title="Rastro Coins"
              icon={<GiWallet className="icone" />}
              open={menusOpen.rastroCoins}
              onOpenChange={() => abrirMenuSelecionado("rastroCoins")}
            >
              <MenuItem>
                <Link to="/acesso/rastro-coins">Compre sua Rastro Coins</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/acesso/historico-transacoes">
                  Histórico de Transações
                </Link>
              </MenuItem>
            </SubMenu>
          </Menu>
        </SidebarContent>

        <SidebarFooter className="rodape">
          <p className="titulo">CONTA</p>

          <Menu className="menu-opcoes">
            {
              (tipoUsuario === "transportadora" ||
                tipoUsuario === "colaborador_transportadora") &&
              <MenuItem
                className="item-menu-rodape"
                icon={<IoSettingsSharp className="icone" />}
                onClick={abrirMenuLateral}
              >
                <Link
                  to={"/acesso/perfil-editar-transportadora"}
                >
                  Ajuste Transportadora
                </Link>
              </MenuItem>
            }

            {tipoUsuario !== "admin" && (
              <MenuItem
                className="item-menu-rodape"
                icon={<FaRegUserCircle className="icone" />}
                onClick={abrirMenuLateral}
              >
                <Link
                  to={
                    tipoUsuario === "transportadora" ||
                      tipoUsuario === "colaborador_transportadora"
                      ? "/acesso/perfil-transportadora"
                      : "/acesso/perfil-embarcador"
                  }
                >
                  Perfil
                </Link>
              </MenuItem>
            )}

            <MenuItem
              className="item-menu-rodape"
              icon={<MdOutlineOndemandVideo className="icone" />}
              onClick={abrirMenuLateral}
            >
              <p onClick={() => setModalTutorial(true)}>Tutoriais</p>
            </MenuItem>

            <MenuItem
              className="item-menu-rodape"
              icon={<ImExit className="icone exit" />}
              onClick={logout}
            >
              Sair
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
      {modalTutorial && (
        <ModalTutorial
          modalTutorial={modalTutorial}
          handleModalTutorial={(bool) => setModalTutorial(bool)}
          setModalVideo={(obj) => {
            setModalVideo(obj)
            setModalTutorial(false)
          }
          }
        ></ModalTutorial>
      )}
      {modalVideo.modal_video && (
        <ModalVideo
          modalVideo={modalVideo.modal_video}
          handleModalVideo={(bool) => {
            setModalVideo({
              modal_video: bool,
              titulo: '',
              url: '',
            })
          }}
          handleModalTutorial={(bool) => setModalTutorial(bool)}
          titulo={modalVideo.titulo}
          videoIdYoutube={modalVideo.videoIdYoutube}
          videoId={modalVideo.videoId}
        ></ModalVideo>
      )}
    </>
  );
};

export default memo(MenuLateral);
