import { InputMask } from '../input-mask';

export const InputCPF = ({ ...props }) => {
  return (
    <InputMask
      {...props}
      mask="999.999.999-99"
      maskChar=" "
    />
  );
};
