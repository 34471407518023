import React from 'react';
import InputMaskBase from 'react-input-mask';
import { useField } from 'formik';
import '../input/input.scss';

export const InputPostalCode = ({ label, onPostalCodeComplete, ...props }) => {
  const [rawField, meta, helpers] = useField(props);

  const field = {
    ...rawField,
    onChange: (e) => {
      const postalCode = String(e.target.value).replace("-", "").trim();
      if (postalCode.length === 8 && onPostalCodeComplete) {
        onPostalCodeComplete(e.target.value);
      }
      rawField.onChange(e);
    },
  }

  return (
    <div className="form-input-container">
      <div className="form-input">
        <InputMaskBase mask="99999-999" maskChar=" " {...props} {...field} />
        <span>{label} {props.required && <i>*</i>}</span>
      </div>
      {meta.touched && meta.error && (
        <span className="form-input-error">{meta.error}</span>
      )}
    </div>
  );
}
