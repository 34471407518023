import React from 'react';
import clsx from 'clsx';
import './two-cols.scss';

export const TwoCols = ({
  children,
  variant,
  gap = undefined,
  columnGap = undefined,
}) => {
  return (
    <div
      className={clsx({
        "inputs-two-columns": true,
        "half-two": variant === 'half-two'
      })}
      style={{
        gap,
        columnGap,
      }}
    >
      {children}
    </div>
  )
}
