import React, { memo, useRef, useEffect, useState, useMemo } from 'react'
import InputMask from 'react-input-mask'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik';

import UFSelectOptions from '../../../../components/formularios/UFSelectOptions';
import {useMessages} from '../../../../context/messages-context';

import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import DocumentoDono from '../../../../components/formularios/DocumentoDono'
import InputSearch from '../../../../components/plataformaAcessada/InputSearch'
import InputSearchVeiculoCarroceria from '../../../../components/plataformaAcessada/InputSearchVeiculoCarroceria'
import InputCPFouCNPJ from '../../../../components/formularios/InputCPFouCNPJ'
import api from "../../../../services/api"
import avatar from "../../../../assets/avatar.png"

import Carrocerias from './carrocerias';
import DadosBancarios from './dados-bancarios';
import { removerMascaraCampo } from './utils';

const NovoMotorista = ({ match }) => {
  const history = useHistory()
  const { showApiErrors, showError, showSuccess } = useMessages();

  const [idMotorista, setIdMotorista] = useState(() => match.params?.idMotorista);
  const desabilitarInputs = match.path === '/acesso/motorista/visualizar-motorista/:idMotorista' ? true : false
  const isEditar = match.path === '/acesso/motorista/editar-motorista/:idMotorista' ? true : false

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
  const tipoUsuario = dadosUsuario.user_type

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/motorista', breadcrumb: 'Motorista' },
    { path: '/acesso/motorista/novo-motorista', breadcrumb: 'Novo Motorista' },
    { path: '/acesso/motorista/cadastro-simplificado', breadcrumb: 'Cadastro Simplificado' },
    { path: `/acesso/motorista/visualizar-motorista`, breadcrumb: null },
    { path: `/acesso/motorista/visualizar-motorista/${idMotorista}`, breadcrumb: 'Visualizar Motorista' },
    { path: `/acesso/motorista/editar-motorista`, breadcrumb: null },
    { path: `/acesso/motorista/editar-motorista/${idMotorista}`, breadcrumb: 'Editar Motorista' },
  ];

  // MOTORISTA
  const motoristaForm = useFormik({
    initialValues: {
      imagem_perfil: '',
      nome_completo: '',
      telefone1: '',
      telefone2: '',
      email: '',
      comprovante_endereco: '',
      data_nascimento: '',
      sexo: 'M',
      is_ativo: true,
      transportadora: '',

      documentos: {
        categoria: '',
        cpf: '',
        rg: '',
        rg_uf: '',
        rg_orgao_expedidor: '',
        nome_mae: '',
        nome_pai: '',
        pis_pasep: '',
        cnh: '',
        cnh_uf: '',
        cnh_seguranca: '',
        cnh_primeira: '',
        cnh_validade: '',
        cnh_verso: '',
        cnh_frente: '',
      },

      endereco: {
        pais: 'Brasil',
        uf: '',
        municipio: '',
        logradouro: '',
        bairro: '',
        numero: '',
        cep: '',
        complemento: '',
      },

      dados_bancarios: {
        banco: '',
        agencia: '',
        conta: '',
        titular: '',
        tipo_pix: '',
        chave_pix: '',
        tipo_conta: '',
      },
    },
  });
  const [fotoPerfil, setFotoPerfil] = useState(null);
  const [cnhFrente, setCnhFrente] = useState(null);
  const [cnhVerso, setCnhVerso] = useState(null);
  const [comprovanteEndereco, setComprovanteEndereco] = useState(null);
  const displayFotoPerfil = useMemo(() => {
    if (!fotoPerfil) {
      return motoristaForm.values.imagem_perfil;
    }
    return URL.createObjectURL(fotoPerfil);
  }, [fotoPerfil, motoristaForm.values.imagem_perfil]);
  // VEICULO
  const [veiculoId, setVeiculoId] = useState(null);
  const veiculoForm = useFormik({
    initialValues: {
      tipo_veiculo: '',
      tipo_veiculo_nome: '',
      ano_fabricacao_cavalo: '',
      placa_cavalo: '',
      marca_cavalo: '',
      antt_cavalo: '',
      renavam_cavalo: '',
      rntrc: '',
      marca_rastreador: '',
      id_rastreador: '',
      status_rastreador: '',
      documento: '',
      tipo_pessoa_dono: 'PF',
      cpf_cnpj_dono: '',
      quantidade_eixos: '',
      nome_dono: '',
      is_ativo: true,
    }
  });
  const [documentoVeiculo, setDocumentoVeiculo] = useState(null);
  // Carrocerias
  const carroceriasForm = useFormik({
    initialValues: {
      carrocerias: [],
    },
  });
  // TOAST TITLES
  const formTitles = {
    data_nascimento: 'Data de Nascimento',
    sexo: 'Sexo',
    transportadora: 'Transportadora',
    is_ativo: 'Ativo',
    comprovante_endereco: 'Comprovante de Endereço',
    email: 'E-mail',
    imagem_perfil: 'Imagem de Perfil',
    nome_completo: 'Nome Completo',
    telefone1: 'Telefone 1',
    telefone2: 'Telefone 2',
    'documentos.cpf': 'CPF',
    'documentos.nome_mae': 'Nome da Mãe',
    'documentos.nome_pai': 'Nome do Pai',
    'documentos.rg': 'RG',
    'documentos.rg_uf': 'UF do RG',
    'documentos.rg_orgao_expedidor': 'Orgão Expedidor do RG',
    'documentos.cnh': 'CNH',
    'documentos.cnh_uf': 'UF da CNH',
    'documentos.categoria': 'Categoria da CNH',
    'documentos.cnh_primeira': 'Data da Primeira CNH',
    'documentos.cnh_seguranca': 'Código de Segurança da CNH',
    'documentos.pis_pasep': 'PIS/PASEP',
    'documentos.cnh_validade': 'Validade da CNH',
    'documentos.cnh_frente': 'Frente da CNH',
    'documentos.cnh_verso': 'Verso da CNH',
    'endereco.pais': 'País',
    'endereco.uf': 'UF',
    'endereco.municipio': 'Municipio',
    'endereco.logradouro': 'Logradouro',
    'endereco.bairro': 'Bairro',
    'endereco.numero': 'Numero',
    'endereco.cep': 'CEP',
    'endereco.complemento': 'Complemento',
    'tipo_veiculo': 'Tipo do Veículo',
    'ano_fabricacao_cavalo': 'Ano de Fabricação do Cavalo',
    'placa_cavalo': 'Placa do Cavalo',
    'marca_cavalo': 'Marca do Cavalo',
    'antt_cavalo': 'ANTT do Cavalo',
    'renavam_cavalo': 'Renavam do Cavalo',
    'rntrc': 'RNTRC',
    'marca_rastreador': 'Marca do Rastreador',
    'id_rastreador': 'ID do Rastreador',
    'status_rastreador': 'Status do Rastreador',
    'documento': 'Documento do Cavalo',
    'tipo_pessoa_dono': 'Tipo de Pessoa Dona',
    'cpf_cnpj_dono': 'CPF/CNPJ do Dono',
    'quantidade_eixos': 'Quantidade de Eixos',
  }

  const [checkboxIsParteTransportadora, setCheckboxIsParteTransportadora] = useState(false);

  const [nomeTransportadora, setNomeTransportadora] = useState('')
  const [idTransportadora, setIdTransportadora] = useState(() => {
    if (tipoUsuario === "transportadora" || tipoUsuario === "colaborador_transportadora") {
      return dadosUsuario.transportadora_id;
    }
    return null;
  })
  const [municipios, setMunicipios] = useState([])

  const [anexoFotosCaminhaoBackend, setAnexoFotosCaminhaoBackend] = useState([])
  const [originalAnexoFotosCaminhaoBackend, setOriginalAnexoFotosCaminhaoBackend] = useState([])
  const [anexoFotosCaminhao, setAnexoFotosCaminhao] = useState(null)

  const [cadastroPublico, setCadastroPublico] = useState(true);

  const visualizarFotoCaminhao = (imagem) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="container-visualizar-foto-caminhao">
            <img
              className="foto-caminhao-aberta"
              src={imagem}
              alt=""
            />

            <div className="container-btn-visualizar-foto-caminhao">
              <button
                className="btn-cancelar"
                onClick={onClose}
              >
                Fechar
              </button>
            </div>
          </div>
        )
      }
    })
  }

  const apresentarFotosCaminhao = () => {
    if (anexoFotosCaminhaoBackend.length > 0) {
      return anexoFotosCaminhaoBackend.map((item, index) => {
        return (
          <img
            key={index}
            className="foto-caminhao"
            src={item.imagem}
            onClick={() => visualizarFotoCaminhao(item.imagem)}
            alt=""
          />
        )
      })
    }

    let arrayFotos = Array.from(anexoFotosCaminhao || [])

    return arrayFotos.map((item, index) => {
      return (
        <img
          key={index}
          className="foto-caminhao"
          src={URL.createObjectURL(item)}
          onClick={() => visualizarFotoCaminhao(URL.createObjectURL(item))}
          alt=""
        />
      )
    })
  }

  const apresentarDocumentoAnexado = (documento) => {
    window.open(documento, '_blank');
  }

  const obtemNomeTransportadora = (idTransportadora) => {
    api.get(`transportadora/${idTransportadora}/`)
      .then(function (response) {
        setNomeTransportadora(response.data.razao_social)
        setIdTransportadora(idTransportadora)
        setCheckboxIsParteTransportadora(true)
      })
      .catch(function (error) {
        console.log(error)
      })
  }


  const getMotoristaFormData = () => {
    const formData = new FormData()
    // motorista
    if (fotoPerfil) {
      formData.append("imagem_perfil", fotoPerfil)
    }
    formData.append("nome_completo", motoristaForm.values.nome_completo)
    formData.append("telefone1", removerMascaraCampo(motoristaForm.values.telefone1))
    formData.append("telefone2", removerMascaraCampo(motoristaForm.values.telefone2))
    formData.append("email", motoristaForm.values.email)
    if (comprovanteEndereco) {
      formData.append("comprovante_endereco", comprovanteEndereco)
    }
    formData.append("data_nascimento", motoristaForm.values.data_nascimento)
    formData.append("sexo", motoristaForm.values.sexo)
    formData.append("is_ativo", motoristaForm.values.is_ativo)
    if (cadastroPublico) {
      formData.append("transportadora", '')
      formData.append('tipo_cadastro', 'NoApp')
    } else {
      formData.append("transportadora", idTransportadora)
    }
    // documentos
    formData.append("documentos.categoria", motoristaForm.values.documentos.categoria)
    formData.append("documentos.cpf", removerMascaraCampo(motoristaForm.values.documentos.cpf))
    formData.append("documentos.rg", removerMascaraCampo(motoristaForm.values.documentos.rg))
    formData.append("documentos.rg_uf", motoristaForm.values.documentos.rg_uf)
    formData.append("documentos.rg_orgao_expedidor", motoristaForm.values.documentos.rg_orgao_expedidor)
    formData.append("documentos.nome_mae", motoristaForm.values.documentos.nome_mae)
    formData.append("documentos.nome_pai", motoristaForm.values.documentos.nome_pai)
    formData.append("documentos.pis_pasep", removerMascaraCampo(motoristaForm.values.documentos.pis_pasep))
    formData.append("documentos.cnh", removerMascaraCampo(motoristaForm.values.documentos.cnh))
    formData.append("documentos.cnh_uf", motoristaForm.values.documentos.cnh_uf)
    formData.append("documentos.cnh_seguranca", motoristaForm.values.documentos.cnh_seguranca)
    formData.append("documentos.cnh_primeira", motoristaForm.values.documentos.cnh_primeira)
    formData.append("documentos.cnh_validade", motoristaForm.values.documentos.cnh_validade)
    if (cnhVerso) {
      formData.append("documentos.cnh_verso", cnhVerso)
    }
    if (cnhFrente) {
      formData.append("documentos.cnh_frente", cnhFrente)
    }
    // endereco
    formData.append("endereco.pais", motoristaForm.values.endereco.pais)
    formData.append("endereco.uf", motoristaForm.values.endereco.uf)
    formData.append("endereco.municipio", motoristaForm.values.endereco.municipio)
    formData.append("endereco.logradouro", motoristaForm.values.endereco.logradouro)
    formData.append("endereco.bairro", motoristaForm.values.endereco.bairro)
    formData.append("endereco.numero", motoristaForm.values.endereco.numero)
    formData.append("endereco.cep", removerMascaraCampo(motoristaForm.values.endereco.cep))
    formData.append("endereco.complemento", motoristaForm.values.endereco.complemento)
    // dados bancários
    formData.append("dados_bancarios.banco", motoristaForm.values.dados_bancarios.banco)
    formData.append("dados_bancarios.agencia", motoristaForm.values.dados_bancarios.agencia)
    formData.append("dados_bancarios.conta", motoristaForm.values.dados_bancarios.conta)
    formData.append("dados_bancarios.titular", motoristaForm.values.dados_bancarios.titular)
    formData.append("dados_bancarios.tipo_pix", motoristaForm.values.dados_bancarios.tipo_pix)
    formData.append("dados_bancarios.chave_pix", motoristaForm.values.dados_bancarios.chave_pix)
    formData.append("dados_bancarios.tipo_conta", motoristaForm.values.dados_bancarios.tipo_conta)
    return formData;
  }

  const getVeiculoFormData = () => {
    // veiculo
    const veiculoFormData = new FormData();
    veiculoFormData.append("tipo_veiculo", veiculoForm.values.tipo_veiculo)
    veiculoFormData.append("ano_fabricacao_cavalo", veiculoForm.values.ano_fabricacao_cavalo)
    veiculoFormData.append("placa_cavalo", veiculoForm.values.placa_cavalo)
    veiculoFormData.append("marca_cavalo", veiculoForm.values.marca_cavalo)
    veiculoFormData.append("antt_cavalo", veiculoForm.values.antt_cavalo)
    veiculoFormData.append("renavam_cavalo", removerMascaraCampo(veiculoForm.values.renavam_cavalo))
    veiculoFormData.append("rntrc", veiculoForm.values.rntrc)
    veiculoFormData.append("marca_rastreador", veiculoForm.values.marca_rastreador)
    veiculoFormData.append("id_rastreador", veiculoForm.values.id_rastreador)
    veiculoFormData.append("status_rastreador", veiculoForm.values.status_rastreador)
    if (documentoVeiculo) {
      veiculoFormData.append("documento", documentoVeiculo)
    }
    veiculoFormData.append("tipo_pessoa_dono", veiculoForm.values.tipo_pessoa_dono)
    veiculoFormData.append("cpf_cnpj_dono", removerMascaraCampo(veiculoForm.values.cpf_cnpj_dono))
    veiculoFormData.append("quantidade_eixos", veiculoForm.values.quantidade_eixos)
    veiculoFormData.append("is_ativo", motoristaForm.values.is_ativo)
    veiculoFormData.append("nome_dono", veiculoForm.values.nome_dono)
    return veiculoFormData;
  };

  

  const [salvandoMotorista, setSalvandoMotorista] = useState(false);

  const cadastrarMotorista = async () => {
    const formData = getMotoristaFormData();
    setSalvandoMotorista(true);

    try {
      const motoristaResponse = await api.post("motoristas/all/", formData)
      setIdMotorista(motoristaResponse.data.id);
      
      showSuccess('Sucesso!', 'Dados do Motorista Salvos!');
    } catch (err) {
      showApiErrors(err, formTitles);
    }
    setSalvandoMotorista(false);
  }

  const editarMotorista = async () => {
    setSalvandoMotorista(true);
    const formData = getMotoristaFormData();

    try {
      await api.put(`motoristas/all/${idMotorista}/`, formData);
      showSuccess('Sucesso!', 'Dados do Motorista Salvos!');
    } catch (err) {
      showApiErrors(err, formTitles);
    }
    setSalvandoMotorista(false);
  }

  const [salvandoVeiculo, setSalvandoVeiculo] = useState(false);

  const salvarVeiculo = async (e) => {
    e.preventDefault();
    if (!idMotorista) {
      showError('Motorista', 'Você precisa salvar o motorista antes de salvar o veículo!');
      return;
    }

    if (!veiculoForm.values.tipo_veiculo) {
      showError('Tipo do Veículo', 'É obrigatório selecionar o tipo de veículo!');
      return;
    }

    const veiculoFormData = getVeiculoFormData();
    setSalvandoVeiculo(true);
    
    try {
      let veiculoResponse = {};
      if (veiculoId) {
        veiculoResponse = await api.patch(`/veiculo/${veiculoId}/`, veiculoFormData)

        if (anexoFotosCaminhao && anexoFotosCaminhao.length > 0) {
          const promises = [
            // delete all the original images calling an DELETE to the route
            ...(originalAnexoFotosCaminhaoBackend || []).map((item) => {
              if (item.id) {
                return api.delete(`imagemveiculo/${item.id}/`)
              }
              return null
            }).filter((item) => item !== null),
            // append the new image calling the POST to the route
            ...Array.from(anexoFotosCaminhao).map((item) => {
              const itemFormData = new FormData()
              itemFormData.append("imagem", item)
              itemFormData.append("veiculo", veiculoId)
              return api.post("imagemveiculo/", itemFormData)
            }),
          ]
          await Promise.all(promises);
        }
      } else {
        veiculoResponse = await api.post("/veiculo/", veiculoFormData)
        
        let arrayFotos = Array.from(anexoFotosCaminhao || [])
        if (arrayFotos.length > 0) {
          const promises = [];
          for (const item of arrayFotos) {
            const imageFormData = new FormData()
            imageFormData.append("imagem", item)
            imageFormData.append("veiculo", veiculoResponse.data.id)
            promises.push(api.post("imagemveiculo/", imageFormData))
          }
          await Promise.all(promises);
        }
      }
      setVeiculoId(veiculoResponse.data.id);
      const updateData = { veiculo: veiculoResponse.data.id };
      await api.patch(`motoristas/all/${idMotorista}/`, updateData);
      showSuccess('Sucesso!', 'Dados do Veículo Salvos!');
    } catch (err) {
      showApiErrors(err, formTitles);
    }
    setSalvandoVeiculo(false);
  }

  const changeCepDebounce = useRef(null);
  const handleChangeCep = (cep) => {
    motoristaForm.setFieldValue('endereco.cep', cep);

    clearTimeout(changeCepDebounce.current);
    changeCepDebounce.current = setTimeout(() => {
      if (cep.length === 9) {
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
          .then((response) => {
            response.json()
              .then((data) => {
                let dadosEndereco = data

                motoristaForm.setFieldValue('endereco.uf', dadosEndereco.uf);
                motoristaForm.setFieldValue('endereco.municipio', dadosEndereco.localidade);
                motoristaForm.setFieldValue('endereco.bairro', dadosEndereco.bairro);
                motoristaForm.setFieldValue('endereco.logradouro', dadosEndereco.logradouro);
              })
          })
          .catch((error) => {
            console.log("ERRO: " + error.message)
          })
      }
    }, 300);
  }

  const handleChangeUf = (uf) => {
    fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`)
      .then(res => {
        return res.json()
      })
      .then(municipios => {
        setMunicipios(municipios)
      });
  }

  const [transportadoras, setTransportadoras] = useState([]);
  const [tiposVeiculos, setTiposVeiculos] = useState([]);

  useEffect(() => {
    api.get(`transportadora/`)
      .then(function (response) {
        setTransportadoras(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })

    api.get(`tipoveiculo/`)
      .then(function (response) {
        setTiposVeiculos(response.data.results)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, []);

  useEffect(() => {
    if (!idMotorista) {
      return;
    }
    api.get(`motoristas/all/${idMotorista}/`)
      .then(function (response) {
        let dadosMotorista = response.data

        if (dadosMotorista.transportadora !== null) {
          obtemNomeTransportadora(dadosMotorista.transportadora)
        }

        motoristaForm.setValues({
          ...motoristaForm.initialValues,
          imagem_perfil: dadosMotorista.imagem_perfil,
          nome_completo: dadosMotorista.nome_completo,
          telefone1: dadosMotorista.telefone1,
          telefone2: dadosMotorista.telefone2,
          email: dadosMotorista.email,
          sexo: dadosMotorista.sexo,
          data_nascimento: dadosMotorista.data_nascimento,
          comprovante_endereco: dadosMotorista.comprovante_endereco,
          is_ativo: dadosMotorista.is_ativo,
          documentos: {
            ...motoristaForm.initialValues.documentos,
            categoria: dadosMotorista.documentos.categoria,
            cpf: dadosMotorista.documentos.cpf,
            rg: dadosMotorista.documentos.rg,
            rg_uf: dadosMotorista.documentos.rg_uf,
            rg_orgao_expedidor: dadosMotorista.documentos.rg_orgao_expedidor,
            nome_mae: dadosMotorista.documentos.nome_mae,
            nome_pai: dadosMotorista.documentos.nome_pai,
            pis_pasep: dadosMotorista.documentos.pis_pasep,
            cnh: dadosMotorista.documentos.cnh,
            cnh_uf: dadosMotorista.documentos.cnh_uf,
            cnh_seguranca: dadosMotorista.documentos.cnh_seguranca,
            cnh_primeira: dadosMotorista.documentos.cnh_primeira,
            cnh_validade: dadosMotorista.documentos.cnh_validade,
            cnh_verso: dadosMotorista.documentos.cnh_verso,
            cnh_frente: dadosMotorista.documentos.cnh_frente,
          },
          endereco: {
            ...motoristaForm.initialValues.endereco,
            pais: dadosMotorista.endereco.pais,
            cep: dadosMotorista.endereco.cep,
            uf: dadosMotorista.endereco.uf,
            municipio: dadosMotorista.endereco.municipio,
            logradouro: dadosMotorista.endereco.logradouro,
            bairro: dadosMotorista.endereco.bairro,
            numero: dadosMotorista.endereco.numero,
            complemento: dadosMotorista.endereco.complemento,
          },
          dados_bancarios: {
            agencia: dadosMotorista.dados_bancarios.agencia,
            banco: dadosMotorista.dados_bancarios.banco,
            titular: dadosMotorista.dados_bancarios.titular,
            chave_pix: dadosMotorista.dados_bancarios.chave_pix,
            conta: dadosMotorista.dados_bancarios.conta,
            tipo_conta: dadosMotorista.dados_bancarios.tipo_conta,
            tipo_pix: dadosMotorista.dados_bancarios.tipo_pix,
          }
        });
        if (dadosMotorista.veiculo) {
          veiculoForm.setValues({
            ...veiculoForm.initialValues,
            tipo_veiculo_nome: dadosMotorista.veiculo.tipo_veiculo.tipo,
            tipo_veiculo: dadosMotorista.veiculo.tipo_veiculo.id,
            placa_cavalo: dadosMotorista.veiculo.placa_cavalo,
            marca_cavalo: dadosMotorista.veiculo.marca_cavalo,
            ano_fabricacao_cavalo: dadosMotorista.veiculo.ano_fabricacao_cavalo,
            antt_cavalo: dadosMotorista.veiculo.antt_cavalo,
            renavam_cavalo: dadosMotorista.veiculo.renavam_cavalo,
            rntrc: dadosMotorista.veiculo.rntrc,
            status_rastreador: dadosMotorista.veiculo.status_rastreador,
            id_rastreador: dadosMotorista.veiculo.id_rastreador,
            marca_rastreador: dadosMotorista.veiculo.marca_rastreador,
            tipo_pessoa_dono: dadosMotorista.veiculo.tipo_pessoa_dono,
            cpf_cnpj_dono: dadosMotorista.veiculo.cpf_cnpj_dono,
            quantidade_eixos: dadosMotorista.veiculo.quantidade_eixos,
            documento: dadosMotorista.veiculo.documento,
            nome_dono: dadosMotorista.veiculo.nome_dono,
          });
          setVeiculoId(dadosMotorista.veiculo.id);

          carroceriasForm.setValues({
            carrocerias: dadosMotorista.veiculo.carrocerias.map((item) => ({
              id: item.id,
              rntrc: item.rntrc,
              placa: item.placa.toUpperCase(),
              marca: item.marca,
              antt: item.antt,
              tipo_pessoa_dono: item.tipo_pessoa_dono,
              cpf_cnpj_dono: item.cpf_cnpj_dono,
              nome_dono: item.nome_dono,
              tipo: item.tipo.id,
              tipo_nome: item.tipo.tipo,
            }))
          })
        }
        setAnexoFotosCaminhaoBackend(dadosMotorista.veiculo.imagens)
        setOriginalAnexoFotosCaminhaoBackend(dadosMotorista.veiculo.imagens)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idMotorista]);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (salvandoMotorista) {
      return;
    }

    if (idMotorista) {
      editarMotorista();
      return;
     }
     cadastrarMotorista();
  }

  const handleFinish = () => {
    let dadosSalvos = '';
    if (idMotorista) {
      dadosSalvos = dadosSalvos ? `${dadosSalvos}, Motorista` : 'Motorista';
    }
    if (veiculoId) {
      dadosSalvos = dadosSalvos ? `${dadosSalvos}, Veículo` : 'Veículo';
    }
    let num = 0
    for (const carroceria of carroceriasForm.values.carrocerias) {
      if (carroceria.id) {
        num += 1;
      }
    }
    if (num === 1) {
      dadosSalvos = dadosSalvos ? `${dadosSalvos}, ${num} Carroceria` : `${num} Carroceria`;
    } else if (num > 1) {
      dadosSalvos = dadosSalvos ? `${dadosSalvos}, ${num} Carrocerias` : `${num} Carrocerias`;
    }

    confirmAlert({
      title: "Finalizar Cadastro de Motorista?",
      message: `Dados Salvos: ${dadosSalvos}`,
      overlayClassName: "alert-remover-grupo-permissoes",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            history.push("/acesso/motorista")
          },
          className: "botao-confirmar"
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar"
        }
      ]
    })
  };

  return (
    <div className="container-novo-motorista">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

        <div className="novo-motorista">
          <p className="titulo-pagina">
            {
              desabilitarInputs && !isEditar
                ? "Visualizar Motorista"
                : isEditar
                  ? "Editar Motorista"
                  : "Novo Motorista"
            }
          </p>
          <p className="subtitulo-pagina">
            {
              desabilitarInputs && !isEditar
                ? "Dados informados no cadastro do motorista"
                : isEditar
                  ? "Por favor, informe os dados solicitados para editar o cadastro do motorista"
                  : "Por favor, informe os dados solicitados para cadastro de novo motorista"
            }
          </p>

          <form onSubmit={handleSubmit}>
            {!desabilitarInputs && (
              <div className="categoria">
                <p className="titulo-categoria">Cadastro é Público?</p>
                <div className="container-inputs-2col">
                  <div className="container-input-checkbox">
                    <span
                      style={{ marginRight: 20 }}
                      className="checkbox"
                      onClick={desabilitarInputs ? () => { } : () => setCadastroPublico(true)}
                    >
                      <input
                        type="checkbox"
                        checked={cadastroPublico}
                        onChange={() => setCadastroPublico(true)}
                      />
                      <span className="checked"></span>
                      Cadastro Público
                    </span>
                    <span
                      className="checkbox"
                      onClick={desabilitarInputs ? () => { } : () => setCadastroPublico(false)}
                    >
                      <input
                        type="checkbox"
                        checked={!cadastroPublico}
                        onChange={() => setCadastroPublico(false)}
                      />
                      <span className="checked"></span>
                      Frota
                    </span>
                  </div>
                </div>
              </div>
            )}

            <>
              {(tipoUsuario !== "transportadora" && tipoUsuario !== "colaborador_transportadora") && (
                <div className="categoria">
                  <p className="titulo-categoria">Motorista faz parte de alguma transportadora?</p>

                  <div className="container-inputs-1col">
                    <div className="container-input-checkbox">
                      <span
                        className="checkbox"
                        onClick={desabilitarInputs
                          ? () => { }
                          : () => setCheckboxIsParteTransportadora(!checkboxIsParteTransportadora)}
                      >
                        <input
                          type="checkbox"
                          checked={checkboxIsParteTransportadora}
                          onChange={() => setCheckboxIsParteTransportadora(!checkboxIsParteTransportadora)}
                          disabled={desabilitarInputs}
                        />
                        <span className="checked"></span>
                        Sim
                      </span>
                    </div>
                  </div>

                  {
                    checkboxIsParteTransportadora &&
                    <InputSearch
                      placeholder="Buscar Transportadora (Razão Social CNPJ)"
                      value={nomeTransportadora}
                      saveValue={(e) => setNomeTransportadora(e)}
                      saveId={(e) => setIdTransportadora(e)}
                      disabled={desabilitarInputs}
                      suggestions={transportadoras}>
                    </InputSearch>
                  }
                </div>
              )}
            </>

            <div className="categoria">
              <p className="titulo-categoria">Dados do Motorista</p>

              <div className="container-inputs-1col">
                <label className="input-file">
                  <div className="container-foto-perfil">
                    <div className="foto">
                      <img src={displayFotoPerfil || avatar} alt="foto" />
                    </div>

                    <p>Adicionar Foto Perfil</p>
                  </div>

                  <input
                    placeholder="Anexar Imagem Curso"
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(e) => setFotoPerfil(e.target.files[0])}
                    disabled={desabilitarInputs}
                  />
                </label>
              </div>

              <div className="container-inputs-2col">
                <div className="container-input">
                  <input
                    required
                    type="text"
                    value={motoristaForm.values.nome_completo}
                    id="nome_completo"
                    onChange={motoristaForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>Nome Completo <i>*</i></span>
                </div>

                <div className="container-input">
                  <InputMask
                    required
                    mask="(99) 99999-9999"
                    maskChar=" "
                    disabled={desabilitarInputs}
                    value={motoristaForm.values.telefone1}
                    id="telefone1"
                    onChange={motoristaForm.handleChange}
                  />
                  <span>Telefone Contato <i>*</i></span>
                </div>

                <div className="container-input">
                  <InputMask
                    mask="(99) 99999-9999"
                    maskChar=" "
                    disabled={desabilitarInputs}
                    value={motoristaForm.values.telefone2}
                    id="telefone2"
                    onChange={motoristaForm.handleChange}
                  />
                  <span>Telefone Contato - 2</span>
                </div>

                <div className="container-input">
                  <input
                    required
                    type="email"
                    value={motoristaForm.values.email}
                    id="email"
                    onChange={motoristaForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>E-mail <i>*</i></span>
                </div>

                <div className="container-input">
                  <input
                    required
                    type="date"
                    value={motoristaForm.values.data_nascimento}
                    id="data_nascimento"
                    onChange={motoristaForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>Data de Nascimento <i>*</i></span>
                </div>

                <div className="container-input-checkbox">
                  <span
                    style={{ marginRight: 20 }}
                    className="checkbox"
                    onClick={desabilitarInputs ? () => { } : () => motoristaForm.setFieldValue('sexo', 'M')}
                  >
                    <input
                      type="checkbox"
                      checked={motoristaForm.values.sexo === 'M'}
                      onChange={() => motoristaForm.setFieldValue('sexo', 'M')}
                      disabled={desabilitarInputs}
                    />
                    <span className="checked"></span>
                    Masculino
                  </span>
                  <span
                    className="checkbox"
                    onClick={desabilitarInputs ? () => { } : () => motoristaForm.setFieldValue('sexo', 'F')}
                  >
                    <input
                      type="checkbox"
                      checked={motoristaForm.values.sexo === 'F'}
                      onChange={() => motoristaForm.setFieldValue('sexo', 'F')}
                      disabled={desabilitarInputs}
                    />
                    <span className="checked"></span>
                    Feminino
                  </span>
                </div>
              </div>
            </div>

            <div className="categoria">
              <p className="titulo-categoria">Documentos do Motorista</p>

              <div className="container-inputs-2col">
                <div className="container-input">
                  <select
                    required
                    value={motoristaForm.values.documentos.categoria}
                    id="documentos.categoria"
                    onChange={motoristaForm.handleChange}
                    disabled={desabilitarInputs}
                  >
                    <option value="" disabled></option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="AB">AB</option>
                    <option value="AC">AC</option>
                    <option value="AD">AD</option>
                    <option value="AE">AE</option>
                  </select>
                  <span>Categoria <i>*</i></span>
                </div>

                <div className="container-input">
                  <InputMask
                    required
                    mask="999.999.999-99"
                    maskChar=" "
                    disabled={desabilitarInputs}
                    value={motoristaForm.values.documentos.cpf}
                    id="documentos.cpf"
                    onChange={motoristaForm.handleChange}
                  />
                  <span>CPF<i>*</i></span>
                </div>

                <div className="container-input">
                  <InputMask
                    required
                    mask="9999999"
                    maskChar=" "
                    disabled={desabilitarInputs}
                    value={motoristaForm.values.documentos.rg}
                    id="documentos.rg"
                    onChange={motoristaForm.handleChange}
                  />
                  <span>RG <i>*</i></span>
                </div>

                <div className="container-input">
                  <input
                    required
                    type="text"
                    disabled={desabilitarInputs}
                    value={motoristaForm.values.documentos.rg_orgao_expedidor}
                    id="documentos.rg_orgao_expedidor"
                    onChange={motoristaForm.handleChange}
                  />
                  <span>Orgão Expedidor do RG <i>*</i></span>
                </div>

                <div className="container-input">
                  <select
                    required
                    id="documentos.rg_uf"
                    value={motoristaForm.values.documentos.rg_uf}
                    onChange={motoristaForm.handleChange}
                    disabled={desabilitarInputs}
                  >
                    <option disabled value=""></option>
                    <UFSelectOptions />
                  </select>
                  <span>UF do RG <i>*</i></span>
                </div>

                <div className="container-input">
                  <input
                    required
                    type="text"
                    disabled={desabilitarInputs}
                    value={motoristaForm.values.documentos.nome_mae}
                    id="documentos.nome_mae"
                    onChange={motoristaForm.handleChange}
                  />
                  <span>Nome Completo da Mãe <i>*</i></span>
                </div>

                <div className="container-input">
                  <input
                    type="text"
                    value={motoristaForm.values.documentos.nome_pai}
                    id="documentos.nome_pai"
                    onChange={motoristaForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>Nome Completo do Pai</span>
                </div>

                <div className="container-input">
                  <InputMask
                    mask="999.99999.99-9"
                    maskChar=" "
                    value={motoristaForm.values.documentos.pis_pasep}
                    id="documentos.pis_pasep"
                    onChange={motoristaForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>PIS/PASEB</span>
                </div>

                <div className="container-input">
                  <InputMask
                    required
                    mask="99999999999"
                    maskChar=" "
                    value={motoristaForm.values.documentos.cnh}
                    id="documentos.cnh"
                    onChange={motoristaForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>CNH<i>*</i></span>
                </div>

                <div className="container-input">
                  <input
                    required
                    type="date"
                    value={motoristaForm.values.documentos.cnh_validade}
                    id="documentos.cnh_validade"
                    onChange={motoristaForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>Validade CNH <i>*</i></span>
                </div>

                <div className="container-input">
                  <input
                    required
                    type="date"
                    value={motoristaForm.values.documentos.cnh_primeira}
                    id="documentos.cnh_primeira"
                    onChange={motoristaForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>Primeira CNH <i>*</i></span>
                </div>

                <div className="container-input">
                  <select
                    required
                    id="documentos.cnh_uf"
                    value={motoristaForm.values.documentos.cnh_uf}
                    onChange={motoristaForm.handleChange}
                    disabled={desabilitarInputs}
                  >
                    <option disabled value=""></option>
                    <UFSelectOptions />
                  </select>
                  <span>UF da CNH <i>*</i></span>
                </div>

                <div className="container-input">
                  <InputMask
                    required
                    mask="99999999999"
                    maskChar=" "
                    type="text"
                    value={motoristaForm.values.documentos.cnh_seguranca}
                    id="documentos.cnh_seguranca"
                    onChange={motoristaForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>Código de Segurança da CNH <i>*</i></span>
                </div>

                <div className="container-input">
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(e) => setCnhFrente(e.target.files[0])}
                    disabled={desabilitarInputs}
                  />
                  <span>Anexar CNH (Frente)</span>
                  {motoristaForm.values.documentos.cnh_frente &&
                    <p
                      className="nome-anexo"
                      onClick={() => apresentarDocumentoAnexado(motoristaForm.values.documentos.cnh_frente)}
                    >
                      Atual: {motoristaForm.values.documentos.cnh_frente}
                    </p>
                  }
                </div>
                <div className="container-input">
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(e) => setCnhVerso(e.target.files[0])}
                    disabled={desabilitarInputs}
                    multiple
                  />
                  <span>Anexar CNH (Verso)</span>
                  {motoristaForm.values.documentos.cnh_verso &&
                    <p
                      className="nome-anexo"
                      onClick={() => apresentarDocumentoAnexado(motoristaForm.values.documentos.cnh_verso)}
                    >
                      Atual: {motoristaForm.values.documentos.cnh_verso}
                    </p>
                  }
                </div>
              </div>
            </div>

            <div className="categoria">
              <p className="titulo-categoria">Endereço</p>

              <div className="container-inputs-2col">
                <div className="container-input">
                  <select
                    required
                    value={motoristaForm.values.endereco.pais}
                    disabled={true}
                  >
                    <option value="Brasil" disabled>Brasil</option>
                  </select>
                  <span>País <i>*</i></span>
                </div>

                <div className="container-input">
                  <InputMask
                    required
                    mask="99999-999"
                    maskChar=""
                    value={motoristaForm.values.endereco.cep}
                    id="endereco.cep"
                    onChange={(e) => handleChangeCep(e.target.value)}
                    disabled={desabilitarInputs}
                  />
                  <span>CEP <i>*</i></span>
                </div>

                <div className="container-input">
                  <select
                    required
                    id="endereco.uf"
                    value={motoristaForm.values.endereco.uf}
                    onChange={(e) => handleChangeUf(e.target.value)}
                    disabled={desabilitarInputs}
                  >
                    <option disabled value="">UF*</option>
                    <UFSelectOptions />
                  </select>
                </div>

                {municipios.length > 0
                  ? <div className="container-input">
                    <select
                      required
                      value={motoristaForm.values.endereco.municipio}
                      id="endereco.municipio"
                      onChange={motoristaForm.handleChange}
                      disabled={desabilitarInputs}
                    >
                      <option value="" disabled></option>
                      {municipios.map((item) => {
                        return (
                          <option key={item.id} value={item.nome}>{item.nome}</option>
                        )
                      })}
                    </select>
                    <span>Município <i>*</i></span>
                  </div>
                  : <div className="container-input">
                    <input
                      required
                      type="text"
                      value={motoristaForm.values.endereco.municipio}
                      id="endereco.municipio"
                      onChange={motoristaForm.handleChange}
                      disabled={desabilitarInputs}
                    />
                    <span>Município <i>*</i></span>
                  </div>
                }

                <div className="container-input">
                  <input
                    required
                    type="text"
                    value={motoristaForm.values.endereco.bairro}
                    id="endereco.bairro"
                    onChange={motoristaForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>Bairro <i>*</i></span>
                </div>

                <div className="container-input">
                  <input
                    required
                    type="text"
                    value={motoristaForm.values.endereco.logradouro}
                    id="endereco.logradouro"
                    onChange={motoristaForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>Logradouro <i>*</i></span>
                </div>

                <div className="container-input">
                  <input
                    required
                    type="text"
                    value={motoristaForm.values.endereco.numero}
                    id="endereco.numero"
                    onChange={motoristaForm.handleChange}
                    maxLength={4}
                    disabled={desabilitarInputs}
                  />
                  <span>Número</span>
                </div>

                <div className="container-input">
                  <input
                    required
                    type="text"
                    value={motoristaForm.values.endereco.complemento}
                    id="endereco.complemento"
                    onChange={motoristaForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>Complemento</span>
                </div>

                <div className="container-input">
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(e) => setComprovanteEndereco(e.target.files[0])}
                    disabled={desabilitarInputs}
                  />
                  <span>Anexar Comprovante de Endereço</span>
                  {motoristaForm.values.comprovante_endereco &&
                    <p
                      className="nome-anexo"
                      onClick={() => apresentarDocumentoAnexado(motoristaForm.values.comprovante_endereco)}
                    >
                      Atual: {motoristaForm.values.comprovante_endereco}
                    </p>
                  }
                </div>
              </div>
            </div>

            <div className="categoria">
              <p className="titulo-categoria">Status Motorista</p>

              <div className="container-input-checkbox">
                <span
                  className="checkbox"
                  onClick={desabilitarInputs ? () => { } : () => motoristaForm.setFieldValue('is_ativo', !motoristaForm.values.is_ativo)}
                >
                  <input
                    type="checkbox"
                    checked={motoristaForm.values.is_ativo}
                    onChange={() => motoristaForm.setFieldValue('is_ativo', !motoristaForm.values.is_ativo)}
                    disabled={desabilitarInputs}
                  />
                  <span className="checked"></span>
                  Ativo
                </span>
              </div>
            </div>


            <DadosBancarios
              dados_bancarios={motoristaForm.values.dados_bancarios}
              desabilitarInputs={desabilitarInputs}
              handleChange={motoristaForm.handleChange}
              setFieldValue={motoristaForm.setFieldValue}
            />

            <div className="container-botoes">
              <button
                className="btn-cadastrar"
                type="submit"
                disabled={salvandoMotorista}
              >
                {salvandoMotorista? 'Salvando...' : 'Salvar Motorista'}
              </button>
            </div>
          </form>

          <form onSubmit={salvarVeiculo}>
            <div className="categoria">
              <p className="titulo-categoria">Veículo</p>

              <div className="container-inputs-2col">
                <div>
                  <div className="container-input">
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={(e) => { setAnexoFotosCaminhao(e.target.files); setAnexoFotosCaminhaoBackend([]) }}
                      disabled={desabilitarInputs}
                      multiple
                    />
                    <span>Anexar fotos caminhão</span>
                  </div>

                  <div className="container-fotos-caminhao">
                    {apresentarFotosCaminhao()}
                  </div>
                </div>

                <InputSearchVeiculoCarroceria
                  placeholder="Buscar Tipo Veículo"
                  value={veiculoForm.values.tipo_veiculo_nome}
                  saveValue={(e) => veiculoForm.setFieldValue('tipo_veiculo_nome', e)}
                  saveId={(e) => veiculoForm.setFieldValue('tipo_veiculo', e)}
                  disabled={desabilitarInputs}
                  suggestions={tiposVeiculos}>
                </InputSearchVeiculoCarroceria>
              </div>

              <div className="container-inputs-2col">
                <div className="container-input">
                  <InputMask
                    required
                    mask="***-****"
                    maskChar=" "
                    value={veiculoForm.values.placa_cavalo}
                    id="placa_cavalo"
                    onChange={veiculoForm.handleChange}
                    disabled={desabilitarInputs}
                  ></InputMask>
                  <span>Placa Cavalo <i>*</i></span>
                </div>

                <div className="container-input">
                  <input
                    required
                    type="text"
                    value={veiculoForm.values.marca_cavalo}
                    id="marca_cavalo"
                    onChange={veiculoForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>Marca Cavalo <i>*</i></span>
                </div>

                <div className="container-input">
                  <input
                    required
                    type="number"
                    value={veiculoForm.values.ano_fabricacao_cavalo}
                    id="ano_fabricacao_cavalo"
                    onChange={veiculoForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>Ano Fabricação Cavalo <i>*</i></span>
                </div>

                <div className="container-input">
                  <InputCPFouCNPJ
                    required
                    type="text"
                    value={veiculoForm.values.antt_cavalo}
                    id="antt_cavalo"
                    onChange={veiculoForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>CPF/CNPJ registrado na ANTT do Cavalo <i>*</i></span>
                </div>

                <div className="container-input">
                  <InputMask
                    required
                    mask="99999999999"
                    maskChar=" "
                    value={veiculoForm.values.renavam_cavalo}
                    id="renavam_cavalo"
                    onChange={veiculoForm.handleChange}
                    disabled={desabilitarInputs}
                  ></InputMask>
                  <span>Renavam Cavalo <i>*</i></span>
                </div>

                <div className="container-input">
                  <InputMask
                    required
                    mask="999999999"
                    maskChar=" "
                    type="text"
                    value={veiculoForm.values.rntrc}
                    id="rntrc"
                    onChange={veiculoForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>RNTRC <i>*</i></span>
                </div>

                <DocumentoDono
                  idTipo="tipo_pessoa_dono"
                  valueTipo={veiculoForm.values.tipo_pessoa_dono}
                  idDocumento="cpf_cnpj_dono"
                  valueDocumento={veiculoForm.values.cpf_cnpj_dono}
                  onChange={veiculoForm.handleChange}
                  disabled={desabilitarInputs}
                />

                <div className="container-input">
                  <input
                    required
                    type="text"
                    value={veiculoForm.values.nome_dono}
                    id={`nome_dono`}
                    onChange={veiculoForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>Nome do Dono <i>*</i></span>
                </div>

                <div className="container-input">
                  <input
                    required
                    type="number"
                    value={veiculoForm.values.quantidade_eixos}
                    id="quantidade_eixos"
                    onChange={veiculoForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>Quantidade de Eixos</span>
                </div>

                <div className="container-input">
                  <input
                    type="text"
                    value={veiculoForm.values.marca_rastreador}
                    id="marca_rastreador"
                    onChange={veiculoForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>Marca do Rastreador</span>
                </div>

                <div className="container-input">
                  <input
                    type="text"
                    value={veiculoForm.values.id_rastreador}
                    id="id_rastreador"
                    onChange={veiculoForm.handleChange}
                    disabled={desabilitarInputs}
                  />
                  <span>ID do Rastreador</span>
                </div>

                <div className="container-input-checkbox">
                  <span
                    className="checkbox"
                    onClick={desabilitarInputs ? () => { } : () => veiculoForm.setFieldValue('status_rastreador', veiculoForm.values.status_rastreador === '1' ? '0' : '1')}
                  >
                    <input
                      type="checkbox"
                      checked={veiculoForm.values.status_rastreador === '1'}
                      onChange={() => veiculoForm.setFieldValue('status_rastreador', veiculoForm.values.status_rastreador === '1' ? '0' : '1')}
                      disabled={desabilitarInputs}
                    />
                    <span className="checked"></span>
                    Rastreador Ativo?
                  </span>
                </div>

                <div className="container-input">
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(e) => setDocumentoVeiculo(e.target.files[0])}
                    disabled={desabilitarInputs}
                  />
                  <span>Anexar Documento Veículo</span>
                  {veiculoForm.values.documento &&
                    <p
                      className="nome-anexo"
                      onClick={() => apresentarDocumentoAnexado(veiculoForm.values.documento)}
                    >
                      Atual: {veiculoForm.values.documento}
                    </p>
                  }
                </div>
              </div>
            </div>

            <div className="container-botoes">
              <button
                className="btn-cadastrar"
                type="submit"
                disabled={!idMotorista || salvandoVeiculo}
              >
                {salvandoVeiculo? 'Salvando...' : 'Salvar Veículo'}
              </button>
            </div>
          </form>

          <Carrocerias
            carroceriasForm={carroceriasForm}
            desabilitarInputs={desabilitarInputs}
            veiculoId={veiculoId}
            formTitles={formTitles}
          />

        {!desabilitarInputs && (
          <div className="container-botoes">
            <button
              className="btn-cadastrar"
              onClick={handleFinish}
            >
              Finalizar Cadastro
            </button>
          </div>
        )}

        </div>

    </div >
  )
}

export default memo(NovoMotorista)