import './h-stack.scss';

export const HStack = ({
  children,
  alignItems = 'flex-start',
  justifyContent = 'flex-start',
  gap = '5px',
  padding = 'none',
  width = undefined,
  height = undefined,
}) => {
  return (
    <div className="hstack" style={{ alignItems, justifyContent, gap, padding, height, width }}>
      {children}
    </div>
  )
};