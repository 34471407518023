import React, { memo, useState, useEffect, useCallback } from "react";
import { FaPlus, FaEdit, FaTrashAlt } from "react-icons/fa";
import { confirmAlert } from 'react-confirm-alert'

import ModalNovoReembolso from "./ModalNovoReembolso";

import 'react-confirm-alert/src/react-confirm-alert.css'
import "./styles.css";

import api from "../../../../../../services/api";

const TabelaFretesReceber = ({ idCarga, updateState, clientes, requisitarDocumentos }) => {
  const [fretes, setFretes] = useState([]);
  const [modalNovoReembolso, setModalNovoReembolso] = useState(false);
  const [idReembolsoEditar, setIdReembolsoEditar] = useState(null);

  const handleModalNovoReembolso = useCallback(() => {
    if (modalNovoReembolso) {
      setModalNovoReembolso(false);
      document.querySelector("body").style.overflow = "visible";
    } else {
      setModalNovoReembolso(true);
      document.querySelector("body").style.overflow = "hidden";
    }
  }, [modalNovoReembolso]);

  const handleEditarReembolso = (id) => {
    setIdReembolsoEditar(id);
    setModalNovoReembolso(true);
    document.querySelector("body").style.overflow = "hidden";
  }

  const removerReembolso = async (id) => {
    try {
      await api.delete(`documentos_fiscais/fretereceber/${id}/`);
    } catch {}
    requisitarFretes();
    requisitarDocumentos();
  }

  const alertRemoverReembolso = (id) => {
    confirmAlert({
      title: "Remover Frete a Receber",
      message: `Deseja realmente remover o Frete a Receber de ID ${id} sistema?`,
      overlayClassName: "alert-remover-frete",
      buttons: [
        {
          label: "Sim",
          onClick: () => removerReembolso(id),
          className: "botao-confirmar"
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar"
        }
      ]
    })
  }

  const somaValoresFrete = () => {
    let soma = 0;

    fretes.forEach((item) => {
      soma += Number(item.valor);
    });

    return soma.toFixed(2);
  };

  const requisitarFretes = useCallback(() => {
    api
      .get(`documentos_fiscais/fretereceber/?carga=${idCarga}`)
      .then(function (response) {
        setFretes(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idCarga]);

  useEffect(() => {
    requisitarFretes();
  }, [idCarga, updateState, requisitarFretes]);

  return (
    <div className="tabela-fretes-receber">
      <button className="btn-frete" onClick={handleModalNovoReembolso}>
        Novo Reembolso de Despesas
        <FaPlus className="icone"></FaPlus>
      </button>

      <div className="container-tabela-fretes-receber">
        <table>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Num. Documento</th>
              <th>Cliente</th>
              <th>Valor</th>
              <th>Observações</th>
              <th>Ações</th>
            </tr>
          </thead>

          <tbody>
            {fretes.map((frete, index) => (
              <tr
                key={index}
                className={`linha ${index % 2 === 0 ? "par" : "impar"}`}
              >
                <td>{frete.tipo}</td>
                <td>{frete.numero_documento || frete.id_reembolso || ''}</td>
                <td>{frete.cliente}</td>
                <td>{frete.valor}</td>
                <td className="coluna-observacoes">{frete.observacoes}</td>
                <td className="coluna-acoes">
                  {frete.origem === 'reembolso' && (
                    <>
                      <FaEdit className="icone" onClick={() => handleEditarReembolso(frete.id)} />
                      <FaTrashAlt className="icone lixo" onClick={() => alertRemoverReembolso(frete.id)} />
                    </>
                  )}
                </td>
              </tr>
            ))}
            <tr className="linha-total">
              <td colSpan={3}>Total</td>
              <td className="coluna-total">{somaValoresFrete("valor")}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      {modalNovoReembolso && (
        <ModalNovoReembolso
          modalNovoFretePagar={modalNovoReembolso}
          handleModalNovoReembolso={handleModalNovoReembolso}
          requisitarFretes={requisitarFretes}
          idCarga={idCarga}
          clientes={clientes}
          idReembolso={idReembolsoEditar}
          requisitarDocumentos={requisitarDocumentos}
        />
      )}
    </div>
  );
};

export default memo(TabelaFretesReceber);
