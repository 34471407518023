import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FaEye, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { confirmDelete } from '../../../../components/actions';
import { useDeleteClientTableMutation } from '../../../../core/mutations'

export const TabelaRow = ({ tabela }) => {
  const deleteTableMutation = useDeleteClientTableMutation();
  
  const tipoTabela = useMemo(() => {
    if (tabela.tipo_tabela === "PESO_KG") {
      return "Peso/KG";
    }
    if (tabela.tipo_tabela === "PESO_CUBAGEM") {
      return "Peso/Cubagem";
    }
    if (tabela.tipo_tabela === "NOTA_FISCAL") {
      return "% Nota Fiscal";
    }
    if (tabela.tipo_tabela === "COMBINADO") {
      return "Combinado";
    }
    if (tabela.tipo_tabela === "POR_VOLUME") {
      return "Por Volume";
    }
    if (tabela.tipo_tabela === "POR_PALLET") {
      return "Por Pallet";
    }
    return '';
  }, [tabela]);

  const origem = useMemo(() => {
    if (tabela.tipo_rota === "GERAL") {
      return "Tabela Global"
    }
    if (tabela.tipo_rota === "UF") {
      return tabela.origem?.uf||'';
    }
    if (tabela.origem?.municipio) {
      return `${tabela.origem?.municipio} - ${tabela.origem?.uf}`
    }
    return tabela.origem?.uf;
  }, [tabela]);

  const destino = useMemo(() => {
    if (tabela.tipo_rota === "GERAL") {
      return "Tabela Global"
    }
    if (tabela.tipo_rota === "UF") {
      return tabela.destino?.uf||'';
    }
    if (tabela.destino?.municipio) {
      return `${tabela.destino?.municipio} - ${tabela.destino?.uf}`
    }
    return tabela.destino?.uf;
  }, [tabela]);

  const handleDeleteTabela = async () => {
    const title = "Remover Cliente";
    const message = `Deseja realmente remover a tabela de frete do sistema?`;

    const confirm = await confirmDelete({ title, message });
    if (!confirm) {
      return;
    }

    deleteTableMutation.mutate({ id: tabela.id });
  }

  return (
    <>
      <tr className="divisoria-linhas-tabela"></tr>
      <tr>
        <td>{tabela.cliente?.nome_completo}</td>
        <td>{tipoTabela}</td>
        <td>{origem}</td>
        <td>{destino}</td>
        <td>{tabela.ativa ? 'Sim' : 'Não'}</td>
        <td className="coluna-acoes">
          <Link to={`/acesso/clientes/tabelas/visualizar/${tabela.id}`}>
            <FaEye className="icone"></FaEye>
          </Link>
          <Link to={`/acesso/clientes/tabelas/editar/${tabela.id}`}>
            <FaEdit className="icone"></FaEdit>
          </Link>
          <FaTrashAlt className="icone" onClick={handleDeleteTabela}></FaTrashAlt>
        </td>
      </tr>
    </>
  );
};
