import React, { useState } from "react";

import "./styles.css";

import cartao from "../../../../../../assets/credit-card.png";

const Cartao = ({ previousStep, handleModalPagamento }) => {
  const [nome, setNome] = useState("");
  const [numero, setNumero] = useState("");
  const [vencimento, setVencimento] = useState("");
  const [codigo, setCodigo] = useState("");
  const [parcelas, setParcelas] = useState("");

  return (
    <div className="pagamento-cartao">
      <p className="titulo">Compre sua Rastro Coins</p>
      <p className="subtitulo">Descrição Da Compra</p>

      <div className="quadro-totais">
        <div>
          <p>Total Rastro Coins</p>
          <input
            type="text"
            placeholder="Total..."
            value={`RC - ${34100}`}
            disabled
          />
        </div>

        <div>
          <p>R$ Total A Pagar - 6 Meses</p>
          <input
            type="text"
            placeholder="Total..."
            value={`R$ ${18661.23}`}
            disabled
          />
        </div>
      </div>

      <hr className="separador" />

      <img src={cartao} alt="" className="icone-pagamento" />

      <p className="subtitulo">
        Informe abaixo os dados do cartão de crédito para concluir a compra.
      </p>

      <div className="container-input-custom">
        <input
          type="text"
          placeholder="Nome no cartão"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />
      </div>

      <div className="container-input-custom">
        <input
          type="numeric"
          placeholder="Número no cartão"
          value={numero}
          onChange={(e) => setNumero(e.target.value)}
        />
      </div>

      <div className="container-col-2">
        <div className="container-input-custom">
          <input
            type="date"
            placeholder="Validade"
            value={vencimento}
            onChange={(e) => setVencimento(e.target.value)}
          />
        </div>

        <div className="container-input-custom">
          <input
            type="numeric"
            placeholder="Código"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
          />
        </div>
      </div>

      <div className="container-input-custom">
        <select value={parcelas} onChange={(e) => setParcelas(e.target.value)}>
          <option value="">Parcelas</option>
          <option value="1">1 Parcela</option>
          <option value="2">2 Parcelas</option>
          <option value="3">3 Parcelas</option>
          <option value="4">4 Parcelas</option>
          <option value="5">5 Parcelas</option>
          <option value="6">6 Parcelas</option>
          <option value="7">7 Parcelas</option>
          <option value="8">8 Parcelas</option>
          <option value="9">9 Parcelas</option>
          <option value="10">10 Parcelas</option>
          <option value="11">11 Parcelas</option>
          <option value="12">12 Parcelas</option>
        </select>
      </div>

      <div className="container-botoes">
        <button onClick={previousStep}>Voltar</button>
        <button className="avancar" onClick={handleModalPagamento}>
          Comprar
        </button>
      </div>
    </div>
  );
};

export default Cartao;
