import React, { useMemo } from 'react';
import NumberFormat from "react-number-format";
import { addDays } from "date-fns";
import listaCFOPs from "../../../../../../../utils/listaCFOPs";
import {round} from './utils';

const Step2 = ({ prestacaoForm, rateio, carga }) => {
  const faixa = useMemo(() => {
    const data = rateio.sort((a, b) => a.numero_cte - b.numero_cte);
    return `Serão Emitidos ${data.length} CT-e's`;
  }, [rateio]);

  const totalPeso = useMemo(() => {
    const num = round(
      rateio.reduce((prev, cur) => {
        return prev + parseFloat(cur.peso);
      }, 0),
      2
    );
    return `${num} Kg`;
  }, [rateio]);

  const totalCte = useMemo(() => {
    const num = round(
      rateio.reduce((prev, cur) => {
        return prev + parseFloat(cur.valor_frete);
      }, 0),
      2
    );
    if (isNaN(num)) {
      return 'R$ 0,00';
    }
    return `R$ ${num.toLocaleString('pt-br', { currency: 'BRL' })}`;
  }, [rateio]);

  const totalImpostos = useMemo(() => {
    const num = round(
      rateio.reduce((prev, cur) => {
        return prev + parseFloat(cur.valor_frete) * (parseFloat(prestacaoForm.values.aliquota) / 100);
      }, 0),
      2
    );
    if (isNaN(num)) {
      return 'R$ 0,00';
    }
    return `R$ ${num.toLocaleString('pt-br', { currency: 'BRL' })}`;
  }, [rateio, prestacaoForm.values]);

  const handleChangeParcelas = (value) => {
    const periodo = parseInt(prestacaoForm.values.periodo, 10);

    let duplicatas = [];
    for (let i = 0; i < parseInt(value, 10); i+=1) {
      const periodoInicial = carga?.transportadora?.omie_vencimento_conta_receber || 0;
      let dataVencimento = addDays(new Date(), periodoInicial + periodo * i);

      duplicatas.push({
        numero: i + 1,
        data_vencimento: dataVencimento.toISOString().split("T")[0],
      })
    }
    
    prestacaoForm.setFieldValue('parcelas', value);
    prestacaoForm.setFieldValue('duplicatas', duplicatas)
  }

  const handleChangePeriodo = (value) => {
    const periodo = parseInt(value || '0', 10);

    const duplicatas = prestacaoForm.values.duplicatas
    for (let i = 0; i < duplicatas.length; i += 1) {
      const periodoInicial = carga?.transportadora?.omie_vencimento_conta_receber || 0;
      let dataVencimento = addDays(new Date(), periodoInicial + periodo * i);
      duplicatas[i].data_vencimento = dataVencimento.toISOString().split("T")[0];
    }
    prestacaoForm.setFieldValue('periodo', value);
    prestacaoForm.setFieldValue('duplicatas', duplicatas);
  }

  return (
    <>
      <p className="titulo-formulario prestacao">Informações da Carga</p>
      <div className="container-inputs-4col">
        <div className="input-radio-gerar-cte">
          <div className="container-input-radio">
            <span
              className={`radio ${prestacaoForm.values.isFracionado && "checked"}`}
              onClick={() => prestacaoForm.setFieldValue('isFracionado', true)}
            >
              <input
                type="radio"
                value={true}
                checked={prestacaoForm.values.isFracionado === true}
                onChange={() => prestacaoForm.setFieldValue('isFracionado', true)}
              />
              <span className="checked"></span>
              Fracionado
            </span>
          </div>

          <div className="container-input-radio">
            <span
              className={`radio ${!prestacaoForm.values.isFracionado && "checked"}`}
              onClick={() => prestacaoForm.setFieldValue('isFracionado', false)}
            >
              <input
                type="radio"
                value={false}
                checked={prestacaoForm.values.isFracionado === false}
                onChange={() => prestacaoForm.setFieldValue('isFracionado', false)}
              />
              <span className="checked"></span>
              Cheio
            </span>
          </div>
        </div>

        <div className="input-radio-gerar-cte">
          <div className="container-input-radio">
            <span
              className={`radio ${prestacaoForm.values.isAPagar && "checked"}`}
              onClick={() => prestacaoForm.setFieldValue('isAPagar', true)}
            >
              <input
                type="radio"
                value={true}
                checked={prestacaoForm.values.isAPagar === true}
                onChange={() => prestacaoForm.setFieldValue('isAPagar', true)}
              />
              <span className="checked"></span>
              A Pagar
            </span>
          </div>

          <div className="container-input-radio">
            <span
              className={`radio ${!prestacaoForm.values.isAPagar && "checked"}`}
              onClick={() => prestacaoForm.setFieldValue('isAPagar', false)}
            >
              <input
                type="radio"
                value={false}
                checked={prestacaoForm.values.isAPagar === false}
                onChange={() => prestacaoForm.setFieldValue('isAPagar', false)}
              />
              <span className="checked"></span>
              Pago
            </span>
          </div>
        </div>
      </div>

      <p className="titulo-formulario prestacao">Rateio</p>
      <div className="container-inputs-4col">
        <div className="container-input-comum">
          <NumberFormat
            required
            id="valorTotalFrete"
            value={prestacaoForm.values.valorTotalFrete}
            onChange={prestacaoForm.handleChange}
            displayType={"number"}
            thousandSeparator="."
            decimalSeparator=","
          />
          <span>Valor Total Prestação</span>
        </div>

        <div className="container-input-comum">
          <select id="dividir" value={prestacaoForm.values.dividir} onChange={prestacaoForm.handleChange}>
            <option value="valor_nfe">Valor da NF-e</option>
            <option value="peso_nfe">Peso da NF-e</option>
            <option value="qtd_volumes">Quantidade De Volumes</option>
            <option value="qtd_nfe">Quantidade De NF-e</option>
          </select>
          <span>Dividir Por</span>
        </div>


        <div className="container-embutir-total">
          <p>Agrupar CT-e's Mesmo Remetente/Destinatário</p>
          
          <div className="input-radio-gerar-cte">
            <div className="container-input-radio">
              <span
                className={`radio ${prestacaoForm.values.agrupar && "checked"}`}
                onClick={() => prestacaoForm.setFieldValue('agrupar', true)}
              >
                <input
                  type="radio"
                  value={true}
                  checked={prestacaoForm.values.agrupar === true}
                  onChange={() => prestacaoForm.setFieldValue('agrupar', true)}
                />
                <span className="checked"></span>
                Agrupar
              </span>
            </div>

            <div className="container-input-radio">
              <span
                className={`radio ${!prestacaoForm.values.agrupar && "checked"}`}
                onClick={() => prestacaoForm.setFieldValue('agrupar', false)}
              >
                <input
                  type="radio"
                  value={false}
                  checked={prestacaoForm.values.agrupar === false}
                  onChange={() => prestacaoForm.setFieldValue('agrupar', false)}
                />
                <span className="checked"></span>
                Não Agrupar
              </span>
            </div>
          </div>
        </div>
      </div>
      <p className="titulo-formulario prestacao">Impostos</p>
      <div className="container-inputs-4col">
        <div className="container-input-comum">
          <select
            id="situacaoIcms"
            value={prestacaoForm.values.situacaoIcms}
            onChange={prestacaoForm.handleChange}
            required
          >
            <option value="" disabled>Selecionar</option>
            <option value="00">00 – Tributada Integralmente</option>
            <option value="20">20 – Com redução de base de Cálculo</option>
            <option value="40">40 – Isenta</option>
            <option value="41">41 - Não Tributada</option>
            <option value="51">51 - Diferimento</option>
            <option value="60">
              60 - ICMS cobrado anteriormente por substituição tributária
            </option>
            <option value="90">90 - Outros</option>
          </select>
          <span>Situação ICMS</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            id="aliquota"
            type="number"
            value={prestacaoForm.values.aliquota}
            onChange={prestacaoForm.handleChange}
          />
          <span>Aliq. %</span>
        </div>

        <div className="container-embutir-total">
          <p>Embutir no total da prestação?</p>
          
          <div className="input-radio-gerar-cte azul">
            <div className="container-input-radio">
              <span className="checkbox">
                <input
                  id="embutir-federias"
                  type="checkbox"
                  value={true}
                  checked={prestacaoForm.values.embutir_impostos_federais}
                  onChange={(e) => prestacaoForm.setFieldValue('embutir_impostos_federais', e.target.checked)}
                />
                <label htmlFor="embutir-federias">PIS/CONFINS/IR/CSSL</label>
              </span>
            </div>

            <div className="container-input-radio">
              <span className="checkbox">
                <input
                  id="embutir-icms"
                  type="checkbox"
                  value={false}
                  checked={prestacaoForm.values.embutir_icms}
                  onChange={(e) => prestacaoForm.setFieldValue('embutir_icms', e.target.checked)}
                />
                <label htmlFor="embutir-icms">ICMS</label>
              </span>
            </div>
          </div>
        </div>
      </div>

      <p className="titulo-formulario prestacao">Dados dos CT-e's</p>
      <div className="container-inputs-2col">
        <div className="container-input-comum">
          <input
            // required
            type="text"
            id="observacoesGerais"
            value={prestacaoForm.values.observacoesGerais}
            onChange={prestacaoForm.handleChange}
          />
          <span>Observações Gerais</span>
        </div>
        <div className="container-input-comum">
          <select
            value={prestacaoForm.values.cfopSelecionado}
            onChange={(e) => {
              prestacaoForm.setFieldValue('cfopSelecionado', e.target.value);
              prestacaoForm.setFieldValue('textoCfopSelecionado', e.target.selectedOptions[0].getAttribute("data-texto"));
            }}
          >
            <option value="">Selecione</option>
            {listaCFOPs.map((cfop) => {
              return (
                <option
                  key={cfop
                    .split("-")[0]
                    .replaceAll(" ", "")
                    .replaceAll(".", "")}
                  value={cfop
                    .split("-")[0]
                    .replaceAll(" ", "")
                    .replaceAll(".", "")}
                  data-texto={cfop.split("-")[1]}
                >
                  {cfop}
                </option>
              );
            })}
          </select>
          <span>CFOP</span>
        </div>
      </div>
      <div className="container-inputs-2col">
        <div className="container-inputs-2col nopad">
          <div className="container-input-comum">
            <select
              id="tomadorContribuinte"
              value={prestacaoForm.values.tomadorContribuinte}
              onChange={prestacaoForm.handleChange}
              required
            >
              <option value="1">Contribuinte ICMS</option>
              <option value="2">Contribuinte isento de inscrição</option>
              <option value="9">Não Contribuinte</option>
            </select>
            <span>Tomador Contribuinte</span>
          </div>

          <div className="container-input-comum">
            <input
              type="date"
              id="dataFechamentoCte"
              value={prestacaoForm.values.dataFechamentoCte}
              onChange={prestacaoForm.handleChange}
            />
            <span>Fechamento CT-e</span>
          </div>
        </div>
      </div>

      <p className="titulo-formulario prestacao">Duplicatas a Receber</p>
      <div className="container-inputs-2col">
        <div className="container-input-comum">
          <select
            required
            value={prestacaoForm.values.parcelas}
            onChange={(e) => handleChangeParcelas(e.target.value)}
          >
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
          <span>Quantidade de Parcelas</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="number"
            value={prestacaoForm.values.periodo}
            onChange={(e) => handleChangePeriodo(e.target.value)}
          />
          <span>Período</span>
        </div>
      </div>

      {prestacaoForm.values.duplicatas.map((item, index) => (
        <div key={index} className="container-inputs-2col">
          <div className="container-input-comum">
            <input
              required
              type="number"
              defaultValue={item.numero}
              disabled
            />
            <span>Parcela</span>
          </div>

          <div className="container-input-comum">
            <input
              type="date"
              value={item.data_vencimento}
              id={`duplicatas.${index}.data_vencimento`}
              onChange={prestacaoForm.handleChange}
            />
            <span>Vencimento</span>
          </div>
        </div>
      ))}

      <p className="titulo-formulario prestacao">Informações</p>
      <div className="informacoes-emissao">
        {faixa}

        <div className="totals">
          <div className="totals-prestacao">
            <span>Valor CT-e Total</span>
            <p>{totalCte}</p>
          </div>
          <div className="totals-prestacao">
            <span>ICMS Total</span>
            <p>{totalImpostos}</p>
          </div>
          <div className="totals-peso">
            <span>Peso Total</span>
            <p>{totalPeso}</p>
          </div>
        </div>
      </div>
    </>
  )
};

export default Step2;
