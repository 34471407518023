import { useState } from "react";
import { Modal } from "../modal-base";
import { Suspense } from "../../components/utils";
import { Button } from "../../components/common";
import { Clientes } from './clientes';
// import './modal-copiar-tabela-frete.scss';

export const ModalSelecionarDadosCliente = ({ isOpen, onClose, onConfirmSelect }) => {
  const [page, setPage] = useState(1);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Importar dados de Cliente"
    >
      <Suspense>
        <Clientes
          page={page}
          setPage={setPage}
          onConfirmSelect={onConfirmSelect}
        />
      </Suspense>

      <div className="modal-footer">
        <Button variant="saveBack" small type="button" onClick={onClose}>Fechar</Button>
      </div>
    </Modal>
  );
};
