import React, { memo } from "react";
import { MdClear } from "react-icons/md";

import "./styles.css";

const QuadroQuantidades = ({
  label,
  value,
  setValue,
  type,
  options,
  onBlur,
}) => {
  return (
    <div className="quadro-quatidades">
      <p>{label}</p>

      <div className="input-quantidade">
        {type === "select" ? (
          <select
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
          >
            {options.map((option, index) => (
              <option key={index} value={option}>
                {`${option} ${option === 1 ? "mês" : "meses"}`}
              </option>
            ))}
          </select>
        ) : (
          <>
            <input
              type="number"
              placeholder="Digite..."
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onBlur={onBlur}
            />
            <MdClear
              onClick={() => setValue("")}
              className="icone-limpar"
            ></MdClear>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(QuadroQuantidades);
