import React, { memo } from 'react'
import { FaEye, FaTrashAlt, FaEdit, FaIdCard, FaStar } from 'react-icons/fa'
import { FiStar } from 'react-icons/fi'
import { Link, useLocation } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import './styles.css'

import api from "../../../services/api"
import { useState } from 'react'
import { useEffect } from 'react'

const TabelaListarTransportadoras = ({ transportadoras, idEmbarcador, isPublica, requisitarTransportadoras }) => {
  const location = useLocation()
  const pathName = location.pathname

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
  const tipoUsuario = dadosUsuario.user_type

  const removerTransportadora = (nome, id) => {
    confirmAlert({
      title: "Remover Transportadora",
      message: `Deseja realmente remover a transportadora "${nome}" do sistema?`,
      overlayClassName: "alert-remover-transportadora",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            api.delete(`transportadora/${id}/`)
              .then(function () {
                requisitarTransportadoras()
              })
              .catch(function (error) {
                console.log(error)
              })
          },
          className: "botao-confirmar"
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar"
        }
      ]
    })
  }

  const LinhaTabela = ({ item }) => {
    const [idFavorito, setIdFavorito] = useState(null)

    useEffect(() => {
      if (tipoUsuario === "embarcador" || tipoUsuario === "colaborador_embarcador") {
        api.get(`/transportadorafavorita/?embarcador=${dadosUsuario.embarcador_id}&transportadora=${item.id}`)
          .then(function (response) {
            if (response.data.count > 0) {
              setIdFavorito(response.data.results[0].id)
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    }, [item])

    const gerenciarFavorito = (idTransportadora) => {
      if (idFavorito !== null) {
        api.delete(`transportadorafavorita/${idFavorito}/`)
          .then(function () {
            setIdFavorito(null)
          })
          .catch(function (error) {
            console.log(error)
          })
      } else {
        api.post(`transportadorafavorita/`, {
          embarcador: dadosUsuario.embarcador_id,
          transportadora: idTransportadora
        })
          .then(function (response) {
            setIdFavorito(response.data.id)
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    }

    return (
      <>
        <tr className="divisoria-linhas-tabela"></tr>
        <tr>
          <td>{item.id}</td>
          <td>{item.razao_social}</td>
          <td>{item.cnpj.replace('.', '').replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}</td>
          {tipoUsuario === "admin" && <td>Plano estático</td>}

          <td className="coluna-acoes">
            {isPublica
              ?
              <>
                {
                  (tipoUsuario === "admin" || tipoUsuario === "transportadora" || tipoUsuario === "colaborador_transportadora") &&
                  <Link to={`/acesso/transportadora/visualizar-transportadora/${item.id}`}>
                    <FaEye className="icone"></FaEye>
                  </Link>
                }
                {
                  idFavorito !== null
                    ? <FaStar className="icone favorito" onClick={() => gerenciarFavorito(item.id)}></FaStar>
                    : <FiStar className="icone favorito" onClick={() => gerenciarFavorito(item.id)}></FiStar>
                }
              </>
              :
              <>
                <Link
                  to={pathName === "/acesso/transportadora/gerenciar-transportadora"
                    ? `/acesso/transportadora/gerenciar-transportadora/${item.id}/motorista`
                    : `/acesso/embarcador/gerenciar-embarcador/${idEmbarcador}/transportadora/${item.id}/motorista`
                  }>
                  <FaIdCard className="icone motorista"></FaIdCard>
                </Link>
                {
                  (tipoUsuario === "admin" || tipoUsuario === "transportadora" || tipoUsuario === "colaborador_transportadora") &&
                  <Link to={`/acesso/transportadora/visualizar-transportadora/${item.id}`}>
                    <FaEye className="icone"></FaEye>
                  </Link>
                }
                <Link to={`/acesso/transportadora/editar-transportadora/${item.id}`}>
                  <FaEdit className="icone"></FaEdit>
                </Link>
                <FaTrashAlt className="icone" onClick={() => removerTransportadora(item.razao_social, item.id)}></FaTrashAlt>
              </>
            }
          </td>
        </tr>
      </>
    )
  }

  return (
    <div className="container-tabela-listar-transportadoras">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Razão Social</th>
            <th>CNPJ</th>
            {tipoUsuario === "admin" && <th>Plano</th>}
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          {transportadoras.map((item) => {
            return (
              <LinhaTabela key={item.id} item={item}></LinhaTabela>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default memo(TabelaListarTransportadoras)