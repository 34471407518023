import React, { Suspense as ReactSuspense } from 'react';
import { LoadingBox } from '../layout/loading-box';

export const Suspense = ({ children }) => {
  return (
    <ReactSuspense fallback={<LoadingBox />}>
      {children}
    </ReactSuspense>
  )
}
