import React, { memo, useState, useRef } from 'react'
import { BsFillPersonFill, BsSearch } from 'react-icons/bs'
import { MdClear } from 'react-icons/md'
import { FiTruck } from 'react-icons/fi'
import { HiOutlineDocumentText } from 'react-icons/hi';
import { FaRegCalendarAlt, FaRegCalendarCheck, FaTag, FaMapMarkerAlt, FaMapMarker } from 'react-icons/fa'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import TabelaHistorico from './TabelaHistorico'

const HistoricoCargas = () => {
  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/painel-monitoramento', breadcrumb: 'Painel Monitoramento' },
    { path: '/acesso/painel-monitoramento/historico', breadcrumb: 'Histórico' },
  ]

  const [filtroCarga, setFiltroCarga] = useState('')
  const [filtroTransportadora, setFiltroTransportadora] = useState('')
  const [filtroEmbarque, setFiltroEmbarque] = useState('')
  const [filtroEmbarqueFinal, setFiltroEmbarqueFinal] = useState('')
  const [filtroDescarga, setFiltroDescarga] = useState('')
  const [filtroDescargaFinal, setFiltroDescargaFinal] = useState('')
  const [filtroTipoCarga, setFiltroTipoCarga] = useState('')
  const [filtroOrigem, setFiltroOrigem] = useState('')
  const [filtroDestino, setFiltroDestino] = useState('')
  const [filtroMotorista, setFiltroMotorista] = useState('')
  const [filtroNfe, setFiltroNfe] = useState('');
  const [filtroCte, setFiltroCte] = useState('');
  const [checkboxTodos, setCheckboxTodos] = useState(false)
  const [checkboxPrazo, setCheckboxPrazo] = useState(false)
  const [checkboxPendencia, setCheckboxPendencia] = useState(false)
  const [checkboxOcorrencia, setCheckboxOcorrencia] = useState(false)
  const [checkboxAtrasado, setCheckboxAtrasado] = useState(false)

  const inputEmbarque = useRef()
  const inputEmbarqueFinal = useRef();
  const inputDescarga = useRef()
  const inputDescargaFinal = useRef();

  return (
    <div className="container-historico-cargas">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <div className="container-filtros-historico">
        <div className="input-filtro">
          <BsSearch className="icone-filtro"></BsSearch>
          <input
            type="text"
            placeholder="Pesquisar Carga"
            value={filtroCarga}
            onChange={(e) => setFiltroCarga(e.target.value)}
          />
          <MdClear onClick={() => setFiltroCarga('')} className="icone-limpar"></MdClear>
        </div>

        <div className="input-filtro">
          <FiTruck className="icone-filtro"></FiTruck>
          <input
            type="text"
            placeholder="Transportadora"
            value={filtroTransportadora}
            onChange={(e) => setFiltroTransportadora(e.target.value)}
          />
          <MdClear onClick={() => setFiltroTransportadora('')} className="icone-limpar"></MdClear>
        </div>

        <div className="input-filtro">
          <FaRegCalendarAlt className="icone-filtro"></FaRegCalendarAlt>
          <input
            ref={inputEmbarque}
            type="text"
            onFocus={() => (inputEmbarque.current.type = "date")}
            onBlur={() => { filtroEmbarque === '' && (inputEmbarque.current.type = "text") }}
            placeholder="Data Embarque (De)"
            value={filtroEmbarque}
            onChange={(e) => setFiltroEmbarque(e.target.value)}
          />
          <MdClear
            className="icone-limpar"
            onClick={
              () => {
                setFiltroEmbarque('');
                inputEmbarque.current.type = "text"
              }
            }
          ></MdClear>
        </div>

        <div className="input-filtro">
          <FaRegCalendarAlt className="icone-filtro"></FaRegCalendarAlt>
          <input
            ref={inputEmbarqueFinal}
            type="text"
            onFocus={() => (inputEmbarqueFinal.current.type = "date")}
            onBlur={() => { filtroEmbarqueFinal === '' && (inputEmbarqueFinal.current.type = "text") }}
            placeholder="Data Embarque (Até)"
            value={filtroEmbarqueFinal}
            onChange={(e) => setFiltroEmbarqueFinal(e.target.value)}
          />
          <MdClear
            className="icone-limpar"
            onClick={
              () => {
                setFiltroEmbarqueFinal('');
                inputEmbarqueFinal.current.type = "text"
              }
            }
          ></MdClear>
        </div>
        
        <div className="input-filtro">
          <FaRegCalendarCheck className="icone-filtro"></FaRegCalendarCheck>
          <input
            ref={inputDescarga}
            type="text"
            onFocus={() => (inputDescarga.current.type = "date")}
            onBlur={() => { filtroDescarga === '' && (inputDescarga.current.type = "text") }}
            placeholder="Data Descarga (De)"
            value={filtroDescarga}
            onChange={(e) => setFiltroDescarga(e.target.value)}
          />
          <MdClear
            className="icone-limpar"
            onClick={
              () => {
                setFiltroDescarga('');
                inputDescarga.current.type = "text"
              }
            }
          ></MdClear>
        </div>

        <div className="input-filtro">
          <FaRegCalendarCheck className="icone-filtro"></FaRegCalendarCheck>
          <input
            ref={inputDescargaFinal}
            type="text"
            onFocus={() => (inputDescargaFinal.current.type = "date")}
            onBlur={() => { filtroDescargaFinal === '' && (inputDescargaFinal.current.type = "text") }}
            placeholder="Data Descarga (Até)"
            value={filtroDescargaFinal}
            onChange={(e) => setFiltroDescargaFinal(e.target.value)}
          />
          <MdClear
            className="icone-limpar"
            onClick={
              () => {
                setFiltroDescargaFinal('');
                inputDescargaFinal.current.type = "text"
              }
            }
          ></MdClear>
        </div>

        <div className="input-filtro">
          <FaTag className="icone-filtro"></FaTag>
          <input
            type="text"
            placeholder="Tipo de Carga"
            value={filtroTipoCarga}
            onChange={(e) => setFiltroTipoCarga(e.target.value)}
          />
          <MdClear onClick={() => setFiltroTipoCarga('')} className="icone-limpar"></MdClear>
        </div>

        <div className="input-filtro">
            <BsFillPersonFill className="icone-filtro"></BsFillPersonFill>
            <input
              type="text"
              placeholder="Motorista"
              value={filtroMotorista}
              onChange={(e) => setFiltroMotorista(e.target.value)}
            />
            <MdClear
              onClick={() => setFiltroMotorista("")}
              className="icone-limpar"
            ></MdClear>
          </div>

        <div className="input-filtro">
          <FaMapMarkerAlt className="icone-filtro"></FaMapMarkerAlt>
          <input
            type="text"
            placeholder="Origem em"
            value={filtroOrigem}
            onChange={(e) => setFiltroOrigem(e.target.value)}
          />
          <MdClear onClick={() => setFiltroOrigem('')} className="icone-limpar"></MdClear>
        </div>

        <div className="input-filtro">
          <FaMapMarker className="icone-filtro"></FaMapMarker>
          <input
            type="text"
            placeholder="Destino em"
            value={filtroDestino}
            onChange={(e) => setFiltroDestino(e.target.value)}
          />
          <MdClear onClick={() => setFiltroDestino('')} className="icone-limpar"></MdClear>
        </div>

        <div className="input-filtro">
          <HiOutlineDocumentText className="icone-filtro"></HiOutlineDocumentText>
          <input
            type="text"
            placeholder="Número da NF-e"
            value={filtroNfe}
            onChange={(e) => setFiltroNfe(e.target.value)}
          />
          <MdClear onClick={() => setFiltroNfe('')} className="icone-limpar"></MdClear>
        </div>

        <div className="input-filtro">
          <HiOutlineDocumentText className="icone-filtro"></HiOutlineDocumentText>
          <input
            type="text"
            placeholder="Número do CT-e"
            value={filtroCte}
            onChange={(e) => setFiltroCte(e.target.value)}
          />
          <MdClear onClick={() => setFiltroCte('')} className="icone-limpar"></MdClear>
        </div>
      </div>

      <div className="container-status-historico">
        <div className="container-input-checkbox">
          <span
            className="checkbox"
            onClick={() => setCheckboxTodos(!checkboxTodos)}
          >
            <input
              type="checkbox"
              checked={checkboxTodos}
              onChange={() => setCheckboxTodos(!checkboxTodos)}
            />
            <span className="checked"></span>
            <div className="label">
              <p>Todos</p>
              <div className="cor todos"></div>
            </div>
          </span>
        </div>

        <div className="container-input-checkbox">
          <span
            className="checkbox"
            onClick={() => setCheckboxPrazo(!checkboxPrazo)}
          >
            <input
              type="checkbox"
              checked={checkboxPrazo}
              onChange={() => setCheckboxPrazo(!checkboxPrazo)}
            />
            <span className="checked"></span>
            <div className="label">
              <p>No Prazo</p>
              <div className="cor prazo"></div>
            </div>
          </span>
        </div>

        <div className="container-input-checkbox">
          <span
            className="checkbox"
            onClick={() => setCheckboxPendencia(!checkboxPendencia)}
          >
            <input
              type="checkbox"
              checked={checkboxPendencia}
              onChange={() => setCheckboxPendencia(!checkboxPendencia)}
            />
            <span className="checked"></span>
            <div className="label">
              <p>Pendência</p>
              <div className="cor pendencia"></div>
            </div>
          </span>
        </div>

        <div className="container-input-checkbox">
          <span
            className="checkbox"
            onClick={() => setCheckboxOcorrencia(!checkboxOcorrencia)}
          >
            <input
              type="checkbox"
              checked={checkboxOcorrencia}
              onChange={() => setCheckboxOcorrencia(!checkboxOcorrencia)}
            />
            <span className="checked"></span>
            <div className="label">
              <p>Com Registros</p>
              <div className="cor ocorrencia"></div>
            </div>
          </span>
        </div>

        <div className="container-input-checkbox">
          <span
            className="checkbox"
            onClick={() => setCheckboxAtrasado(!checkboxAtrasado)}
          >
            <input
              type="checkbox"
              checked={checkboxAtrasado}
              onChange={() => setCheckboxAtrasado(!checkboxAtrasado)}
            />
            <span className="checked"></span>
            <div className="label">
              <p>Atrasado</p>
              <div className="cor atrasado"></div>
            </div>
          </span>
        </div>
      </div>

      <TabelaHistorico
        filtroCarga={filtroCarga}
        filtroTransportadora={filtroTransportadora}
        filtroEmbarque={filtroEmbarque.replaceAll("_", "")}
        filtroEmbarqueFinal={filtroEmbarqueFinal.replaceAll("_", "")}
        filtroDescarga={filtroDescarga.replaceAll("_", "")}
        filtroDescargaFinal={filtroDescargaFinal.replaceAll("_", "")}
        filtroTipoCarga={filtroTipoCarga}
        filtroOrigem={filtroOrigem}
        filtroDestino={filtroDestino}
        filtroMotorista={filtroMotorista}
        filtroNfe={filtroNfe}
        filtroCte={filtroCte}
        checkboxTodos={checkboxTodos}
        checkboxPrazo={checkboxPrazo ? "no-prazo" : ""}
        checkboxPendencia={checkboxPendencia ? "pendencia" : ""}
        checkboxOcorrencia={checkboxOcorrencia ? "ocorrencia" : ""}
        checkboxAtrasado={checkboxAtrasado ? "atraso" : ""}
      ></TabelaHistorico>
    </div>
  )
}

export default memo(HistoricoCargas)