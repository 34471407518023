import React, { memo, useState } from "react";
import ModalPerfilMotorista from './ModalPerfilMotorista';
import AdicionarCarga from "../../AdicionarCarga";
import CardsNovasCargas from "./CardsNovasCargas";
import ContainerCards from "../ContainerCards";
import { useCards } from '../CardsContext';
import api from '../../../../../../services/api';
import "react-confirm-alert/src/react-confirm-alert.css";
import "./styles.css";

const QuadroNovasCargas = () => {
  const { novas, setNovas, setEmbarque, fetchNovasCargas, updateCarga } = useCards();

  const [retificarModal, setRetificarModal] = useState(false);
  const [retificarData, setRetificarData] = useState(null);
  const [retificarCarga, setRetificarCarga] = useState(null);
  const [retificarCandidatura, setRetificarCandidatura] = useState(null);

  return (
    <>
      <div className="quadro novas-cargas">
        <div className="titulo">
          <p>NOVAS CARGAS</p>
        </div>

        <div className="detalhes">
          <div>
            <p className="total" onClick={() => setNovas(prev => ({ ...prev, filtroCarga: "" }))}>
              Total: <span>{novas.painel.total}</span>
            </p>
          </div>
          <div className="negociacao">
            <p
              className="em-negociacao"
              onClick={() => setNovas(prev => ({ ...prev, filtroCarga: "em_negociacao" }))}
            >
              Em Negociação: <span>{novas.painel.em_negociacao}</span>
            </p>
            <p
              className="sem-negociacao"
              onClick={() => setNovas(prev => ({ ...prev, filtroCarga: "sem_negociacao" }))}
            >
              Sem Negociação: <span>{novas.painel.sem_negociacao}</span>
            </p>
          </div>
          <div className="icones">
            <AdicionarCarga
              idCarga={null}
              atualizarCargas={() => {
                setNovas((prev) => ({ ...prev, offset: 0, cargas: [], total: 0 }));
                fetchNovasCargas();
              }}
            />
          </div>
        </div>
        <ContainerCards
          component={
            <CardsNovasCargas
              setRetificarCandidatura={setRetificarCandidatura}
              setRetificarModal={setRetificarModal}
              setRetificarData={setRetificarData}
              setRetificarCarga={setRetificarCarga}
              onRemove={(id) => {
                setNovas((prev) => ({
                  ...prev,
                  cargas: prev.cargas.filter((item) => item.id !== id),
                }));
              }}
              onUpdate={(id) => updateCarga(setNovas, id)}
              moverCarga={async (id) => {
                const [carga] = novas.cargas.filter((item) => item.id === id);
                setNovas((prev) => ({
                  ...prev,
                  cargas: prev.cargas.filter((item) => item.id !== id),
                  total: prev.total - 1,
                }));
                const { id: cargaId } = carga;
                const { data } = await api.get(`/cargas/${cargaId}/`);

                setEmbarque((prev) => ({
                  ...prev,
                  cargas: [data, ...prev.cargas],
                  total: prev.total + 1,
                }))
              }}
            />
          }
          cargas={novas.cargas}
          total={novas.total}
          loading={novas.loading}
          onLoadMore={() => {
            fetchNovasCargas(novas.offset + 10);
          }}
        />

      </div>

      {retificarModal && retificarData && (
        <ModalPerfilMotorista
          motorista={retificarData}
          modalPerfilMotorista={retificarModal}
          handleModalPerfilMotorista={() => {
            setRetificarData(null);
            setRetificarModal(false);
            setRetificarCandidatura(null);
            if (!retificarCarga) {
              return;
            }
            updateCarga(setNovas, retificarCarga);
            setRetificarCarga(null);
          }}
          idCandidaturaRetificar={retificarCandidatura}
          handleModalPropostaCarga={() => { }}
          editable={true}
        />
      )}
    </>
  );
};

export default memo(QuadroNovasCargas);