import React from 'react';
import InputMaskBase from 'react-input-mask';
import { useField } from 'formik';
import '../input/input.scss';

export const InputMask = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <div className="form-input-container">
      <div className="form-input">
        <InputMaskBase {...props} {...field} />
        <span>{label} {props.required && <i>*</i>}</span>
      </div>
      {meta.touched && meta.error && (
        <span className="form-input-error">{meta.error}</span>
      )}
    </div>
  );
}
