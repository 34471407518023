import React, { memo, useRef, useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import { useHistory } from 'react-router-dom'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import InputSearch from '../../../../components/plataformaAcessada/InputSearch'
import api from "../../../../services/api"

const CadastroSimplificadoTransportadora = () => {
  const history = useHistory()

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
  const tipoUsuario = dadosUsuario.user_type

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/transportadora', breadcrumb: 'Transportadora' },
    { path: '/acesso/transportadora/cadastro-simplificado', breadcrumb: 'Cadastro Simplificado' },
  ]

  const [embarcadores, setEmbarcadores] = useState([])
  const [checkboxIsParteEmbacador, setCheckboxIsParteEmbacador] = useState(false)
  const [nomeEmbarcador, setNomeEmbarcador] = useState('')
  const [idEmbarcador, setIdEmbarcador] = useState('')
  const [checkboxStatus, setCheckboxStatus] = useState(true)
  const [msgErro, setMsgErro] = useState(false)

  const razaoSocial = useRef('')
  const cnpj = useRef('')
  const telefoneContato = useRef('')
  const email = useRef('')
  const plano = useRef('')
  const validadePlano = useRef('')

  const removerMascaraCampo = (dado) => {
    return dado.replace('(', '').replace(')', '').replace(' ', '').replace('-', '').replace('.', '').replace('/', '')
  }

  const verificaPreenchimentoCampos = (dados) => {
    if (dados.razao_social.trim() === "" || dados.cnpj.trim() === ""
      || dados.telefone.trim() === "" || dados.email.trim() === "") {
      return setMsgErro(true)
    } else {
      return true
    }
  }

  const cadastrarTransportadora = () => {
    let dados = {
      embarcador: idEmbarcador,
      razao_social: razaoSocial.current.value,
      cnpj: removerMascaraCampo(cnpj.current.value).replace('.', ''),
      email: email.current.value,
      telefone: removerMascaraCampo(telefoneContato.current.value),
      user_type: 2,
      is_ativo: checkboxStatus
    }

    if (verificaPreenchimentoCampos(dados)) {
      api.post("transportadora/", dados)
        .then(function () {
          history.push("/acesso/transportadora")
        })
        .catch(function (e) {
          console.log(e.response.data)
        })
    }
  }

  useEffect(() => {
    if (tipoUsuario === "embarcador" || tipoUsuario === "colaborador_embarcador") {
      setIdEmbarcador(dadosUsuario.embarcador_id)
    }
  }, [dadosUsuario.embarcador_id, tipoUsuario])

  useEffect(() => {
    api.get(`embarcador/`)
      .then(function (response) {
        setEmbarcadores(response.data.results)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  return (
    <div className="container-cadastro-simplificado-transportadora">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="nova-transportadora">
        <p className="titulo-pagina">Nova Transportadora - Cadastro Simplificado</p>
        <p className="subtitulo-pagina">Por favor, informe os dados solicitados para cadastro de nova transportadora</p>

        {(tipoUsuario !== "embarcador" && tipoUsuario !== "colaborador_embarcador") &&
          <div className="categoria">
            <p className="titulo-categoria">Transportadora faz parte de um embarcador?</p>

            <div className="container-inputs-1col">
              <div className="container-input-checkbox">
                <span
                  className="checkbox"
                  onClick={() => setCheckboxIsParteEmbacador(!checkboxIsParteEmbacador)}>
                  <input
                    type="checkbox"
                    checked={checkboxIsParteEmbacador}
                    onChange={() => setCheckboxIsParteEmbacador(!checkboxIsParteEmbacador)}
                  />
                  <span className="checked"></span>
                  Sim
                </span>
              </div>
            </div>

            {
              checkboxIsParteEmbacador &&
              <InputSearch
                placeholder="Buscar Embarcador (Razão Social CNPJ)"
                value={nomeEmbarcador}
                saveValue={(e) => setNomeEmbarcador(e)}
                saveId={(e) => setIdEmbarcador(e)}
                suggestions={embarcadores}>
              </InputSearch>
            }
          </div>
        }

        <div className="categoria">
          <p className="titulo-categoria">Dados da Transportadora</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <input
                required
                type="text"
                ref={razaoSocial}
              />
              <span>Razão Social <i>*</i></span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="99.999.999/9999-99"
                ref={cnpj}
              ></InputMask>
              <span>CNPJ <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={email}
              />
              <span>E-mail <i>*</i></span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="(99) 99999-9999"
                ref={telefoneContato}
              ></InputMask>
              <span>Telefone Contato <i>*</i></span>
            </div>
          </div>
        </div>

        {tipoUsuario === "admin" &&
          <div className="categoria">
            <p className="titulo-categoria">Plano da Transportadora</p>

            <div className="container-inputs-2col">
              <div className="container-input">
                <select
                  required
                  ref={plano}
                  defaultValue={''}
                >
                  <option value="" disabled></option>
                </select>
                <span>Selecione o Plano <i>*</i></span>
              </div>

              <div className="container-input">
                <input
                  required
                  type="date"
                  ref={validadePlano}
                />
                <span>Validade do Plano <i>*</i></span>
              </div>
            </div>
          </div>
        }

        <div className="categoria">
          <p className="titulo-categoria">Status Transportadora</p>

          <div className="container-inputs-1col">
            <div className="container-input-checkbox">
              <span className="checkbox" onClick={() => setCheckboxStatus(!checkboxStatus)} >
                <input
                  type="checkbox"
                  checked={checkboxStatus}
                  onChange={() => setCheckboxStatus(!checkboxStatus)}
                />
                <span className="checked"></span>
                Ativo
              </span>
            </div>
          </div>
        </div>

        <div className="container-botoes">
          <button
            className="btn-cancelar"
            onClick={() => history.replace("/acesso/transportadora")}
          >
            Cancelar
          </button>

          <button
            className="btn-cadastrar"
            onClick={cadastrarTransportadora}
          >
            Cadastrar Transportadora
          </button>
        </div>

        {msgErro && <p className="msg-erro">Todos os campos com * devem ser preenchidos!</p>}
      </div>
    </div>
  )
}

export default memo(CadastroSimplificadoTransportadora)