import React, { Fragment, memo, useRef, useState, useCallback } from "react";
import { HiOutlineRefresh } from "react-icons/hi";
import { RiCloseCircleFill } from "react-icons/ri";
import { saveAs } from "file-saver";
import Loader from "react-loader-spinner";
import { parseISO, format } from "date-fns";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

// import email from "../../../../../assets/email.png"
import pdfIcon from "../../../../../assets/pdf.png";
import xmlIcon from "../../../../../assets/xml.png";
import cteIcon from "../../../../../assets/cte.png";

import ModalPerfilMotoristaCarga from "../../../PainelMonitoramento/ModalPerfilMotoristaCarga";
import ModalNovoManifesto from "../../../PainelMonitoramento/ModalPerfilMotoristaCarga/Financeiro/TabelaMDFe/ModalNovoManifesto";

import api from "../../../../../services/api";

import "./styles.css";

const TabelaMDFe = ({ mdfes, requisitaMdfes, loadingMdfes }) => {
  const justificativaCancelarMdfe = useRef("");
  const [modalPerfilMotoristaCarga, setModalPerfilMotoristaCarga] =
    useState(false);
  const [modalNovoManifesto, setModalNovoManifesto] = useState(false);
  const [idMdfeSelecionado, setIdMdfeSelecionado] = useState(null);

  const [idCarga, setIdCarga] = useState(false);

  const handleModalPerfilMotoristaCarga = useCallback(
    (idCarga) => {
      if (modalPerfilMotoristaCarga) {
        setModalPerfilMotoristaCarga(false);
        document.querySelector("body").style.overflow = "visible";
      } else {
        setIdCarga(idCarga);
        setModalPerfilMotoristaCarga(true);
        document.querySelector("body").style.overflow = "hidden";
      }
    },
    [modalPerfilMotoristaCarga]
  );

  const handleModalNovoManifesto = useCallback(
    (idManifesto) => {
      if (modalNovoManifesto) {
        setModalNovoManifesto(false);
        document.querySelector("body").style.overflow = "visible";
      } else {
        setModalNovoManifesto(true);
        setIdMdfeSelecionado(idManifesto);
        document.querySelector("body").style.overflow = "hidden";
      }
    },
    [modalNovoManifesto]
  );

  const cancelarMdfe = (idMdfe, onClose) => {
    api
      .post(`documentos_fiscais/mdfe/${idMdfe}/cancelar/`, {
        justificativa: justificativaCancelarMdfe.current.value,
      })
      .then(function () {
        onClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const alertCancelarMdfe = (idMdfe) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="container-remover-documento">
            <p className="titulo">
              Você deseja realmente <span className="cancelar">cancelar</span> o
              MDF-e de ID {idMdfe} do sistema?
            </p>

            <div className="justificativa">
              <textarea
                type="text"
                placeholder="Preencha a justificativa... (Mínimo 15 caracteres)"
                rows={5}
                minLength="15"
                ref={justificativaCancelarMdfe}
              />
            </div>

            <div className="botoes">
              <button className="cancelar" onClick={onClose}>
                Não
              </button>
              <button
                className="confirmar"
                onClick={() => cancelarMdfe(idMdfe, onClose)}
              >
                Sim
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const encerrarMdfe = (idMdfe) => {
    api
      .post(`documentos_fiscais/mdfe/${idMdfe}/encerrar/`)
      .then(function () {})
      .catch(function (error) {
        console.log(error);
      });
  };

  const alertEncerrarMdfe = (idMdfe) => {
    confirmAlert({
      title: "Encerrar MDF-e",
      message: `Deseja realmente encerrar o MDF-e de ID ${idMdfe} do sistema?`,
      overlayClassName: "alert-remover-nfe",
      buttons: [
        {
          label: "Sim",
          onClick: () => encerrarMdfe(idMdfe),
          className: "botao-confirmar",
        },
        {
          label: "Não",
          onClick: () => {},
          className: "botao-cancelar",
        },
      ],
    });
  };

  return (
    <div className="container-tabela-mdfs-documentos">
      {loadingMdfes ? (
        <div className="container-loading-documentos">
          <Loader type="TailSpin" color="#001b48" height={50} width={50} />
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>ID Carga</th>
              <th>Manifesto</th>
              <th>Protocolo</th>
              <th>Cidade Origem</th>
              <th>Cidade Destino</th>
              {/* <th>Filiar Origem</th>
            <th>Filiar Destino</th> */}
              <th>Motorista</th>
              <th>Lançamento</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {mdfes.map((mdfe, index) => (
              <Fragment key={index}>
                <tr className={`linha ${index % 2 === 0 ? "par" : "impar"}`}>
                  <td className="coluna-acoes">
                    {mdfe.damdfe && (
                      <img
                        src={pdfIcon}
                        alt="pdf"
                        onClick={() =>
                          saveAs(mdfe.damdfe, `Manifesto${mdfe.numero}.pdf`)
                        }
                      />
                    )}
                  </td>
                  <td className="coluna-acoes">
                    {/* <img src={email} alt="email" /> */}
                  </td>
                  <td className="coluna-destaque">
                    <span
                      onClick={() =>
                        handleModalPerfilMotoristaCarga(mdfe.carga)
                      }
                    >
                      ID: {mdfe.carga}
                    </span>
                  </td>
                  <td>{mdfe.numero}</td>
                  <td>{mdfe.protocolo}</td>
                  <td>{mdfe.cidade_origem}</td>
                  <td>{mdfe.cidade_destino}</td>
                  <td>{mdfe.motorista}</td>
                  <td>
                    {mdfe.emissao
                      ? format(parseISO(mdfe.emissao), "dd/MM/yyyy HH:mm")
                      : "-"}
                  </td>
                  <td className="coluna-acoes">
                    {mdfe.status === "Confirmado" && (
                      <RiCloseCircleFill
                        className="icone remover"
                        onClick={() => alertCancelarMdfe(mdfe.id)}
                      ></RiCloseCircleFill>
                    )}
                  </td>
                </tr>

                <tr className={`linha ${index % 2 === 0 ? "par" : "impar"}`}>
                  <td className="coluna-acoes">
                    {mdfe.arquivo && (
                      <img
                        src={xmlIcon}
                        onClick={() =>
                          saveAs(mdfe.arquivo, `Manifesto${mdfe.numero}.xml`)
                        }
                        alt="xml"
                      />
                    )}
                  </td>
                  <td className="coluna-acoes">
                    {mdfe.ctes_transportados.length > 0 &&
                      mdfe.ctes_transportados.map((cte) => (
                        <img
                          key={cte.id}
                          src={cteIcon}
                          onClick={() =>
                            saveAs(cte.cte.arquivo, `CT-e${cte.cte.numero}.xml`)
                          }
                          alt="cte"
                        />
                      ))}
                  </td>
                  <td></td>

                  <td colSpan={2} className="coluna-informacoes">
                    Recebido: <span>{mdfe.recibo}</span>
                    <br />
                    Descrição do Status:
                    {mdfe.status === "Pendente" ||
                    mdfe.status === "Negado" ||
                    mdfe.status === "Cancelado" ? (
                      <span
                        className="status-mdfe"
                        onClick={() => handleModalNovoManifesto(mdfe.id)}
                      >
                        {`${mdfe.status} | ${
                          mdfe?.erros_emissao?.sefaz ||
                          mdfe?.erros_emissao?.tecnospeed ||
                          ""
                        }`}
                      </span>
                    ) : (
                      `${mdfe.status} | ${
                        mdfe?.erros_emissao?.sefaz ||
                        mdfe?.erros_emissao?.tecnospeed ||
                        ""
                      }`
                    )}
                  </td>
                  <td colSpan={2}>Chave: {mdfe.chave}</td>
                  <td>{mdfe.cavalo}</td>
                  <td className="coluna-motorista">
                    {mdfe.status === "Confirmado" && (
                      <span onClick={() => alertEncerrarMdfe(mdfe.id)}>
                        Encerrar Manifesto
                      </span>
                    )}
                  </td>
                  <td className="coluna-acoes">
                    <HiOutlineRefresh
                      className="icone"
                      onClick={requisitaMdfes}
                    ></HiOutlineRefresh>
                  </td>
                </tr>

                {modalPerfilMotoristaCarga && (
                  <ModalPerfilMotoristaCarga
                    modalPerfilMotoristaCarga={modalPerfilMotoristaCarga}
                    handleModalPerfilMotoristaCarga={
                      handleModalPerfilMotoristaCarga
                    }
                    idCarga={idCarga}
                    moverCarga={() => {}}
                  ></ModalPerfilMotoristaCarga>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      )}

      {modalNovoManifesto && (
        <ModalNovoManifesto
          idCarga={idCarga}
          idMdfeSelecionado={idMdfeSelecionado}
          modalNovoManifesto={modalNovoManifesto}
          handleModalNovoManifesto={handleModalNovoManifesto}
          requisitarDocumentos={requisitaMdfes}
        ></ModalNovoManifesto>
      )}
    </div>
  );
};

export default memo(TabelaMDFe);
