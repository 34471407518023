import React, { memo } from "react"

import './styles.css'

import avaliacao from "../../../../../../../../assets/avaliacao.png"

const AvaliacoesMotorista = ({ motorista }) => {
  return (
    <div className="container-avaliacoes">
      <p className="titulo">Rating do Motorista:</p>

      <div className="conteudo">
        <div className="imagem">
          <img src={avaliacao} alt="" />
        </div>

        <div className="quadros">
          <div className="quadro">
            <p>Quantidade De Cargas</p>
            <p>{motorista.estatisticas?.cargas_total}</p>
          </div>

          <div className="quadro">
            <p>Entregas On-Time</p>
            <p>
              {
                motorista.estatisticas?.porcentagem_no_tempo
                  ? Number(motorista.estatisticas?.porcentagem_no_tempo).toFixed(0)
                  : 0
              }%
            </p>
          </div>

          <div className="quadro">
            <p>Quantidade De Km Rodados</p>
            <p>
              {
                motorista.estatisticas?.distancia_percorrida
                  ? (Math.round(Number(motorista.estatisticas.distancia_percorrida / 1000))).toLocaleString('pt-BR')
                  : 0
              } Km
            </p>
          </div>

          <div className="quadro">
            <p>Cargas Sem Ocorrências</p>
            <p>
              {
                motorista.estatisticas?.porcentagem_sem_ocorrencia
                  ? Number(motorista.estatisticas?.porcentagem_sem_ocorrencia).toFixed(0)
                  : 0
              }%
            </p>
          </div>

          <div className="quadro">
            <p>Avaliação dos Clientes</p>
            <p>{motorista.estatisticas?.avaliacao}</p>
          </div>

          <div className="quadro">
            <p>Cargas Realizadas Conosco</p>
            <p>{motorista.estatisticas?.conosco?.quantidade_cargas ? motorista.estatisticas?.conosco?.quantidade_cargas : 0}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(AvaliacoesMotorista)