export const removerMascaraCampo = (dado) => {
  return dado
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll(' ', '')
    .replaceAll('-', '')
    .replaceAll('.', '')
    .replaceAll('/', '')
    .replaceAll('_', '');
}
