import React, { memo, useRef, useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { BsGlobe, BsFacebook, BsLinkedin } from 'react-icons/bs'
import { FaInstagramSquare, FaPlus } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import InputSearch from '../../../../components/plataformaAcessada/InputSearch'
import api from "../../../../services/api"

const NovaTransportadora = ({ match }) => {
  const history = useHistory()
  const mathIdEmbarcador = match.params?.idEmbarcador
  const idTranportadora = match.params?.idTransportadora
  const desabilitarInputs = match.path === '/acesso/transportadora/visualizar-transportadora/:idTransportadora' ? true : false
  const isEditar = match.path === '/acesso/transportadora/editar-transportadora/:idTransportadora' ? true : false

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
  const tipoUsuario = dadosUsuario.user_type

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/transportadora', breadcrumb: 'Transportadora' },
    { path: `/acesso/transportadora/nova-transportadora/${mathIdEmbarcador}`, breadcrumb: null },
    { path: '/acesso/transportadora/nova-transportadora', breadcrumb: 'Nova Transportadora' },
    { path: `/acesso/transportadora/visualizar-transportadora`, breadcrumb: null },
    { path: `/acesso/transportadora/visualizar-transportadora/${idTranportadora}`, breadcrumb: 'Visualizar Transportadora' },
    { path: `/acesso/transportadora/editar-transportadora`, breadcrumb: null },
    { path: `/acesso/transportadora/editar-transportadora/${idTranportadora}`, breadcrumb: 'Editar Transportadora' },
  ]

  // const [paises, setPaises] = useState([])
  const [ufs, setUfs] = useState([])
  const [municipios, setMunicipios] = useState([])
  const [embarcadores, setEmbarcadores] = useState([])

  const [checkboxIsParteEmbacador, setCheckboxIsParteEmbacador] = useState(false)
  const [nomeEmbarcador, setNomeEmbarcador] = useState('')
  const [idEmbarcador, setIdEmbarcador] = useState(mathIdEmbarcador ? mathIdEmbarcador : '')
  const [pais, setPais] = useState('Brasil')
  const [cep, setCep] = useState('')
  const [uf, setUf] = useState('')
  const [municipio, setMunicipio] = useState('')
  const [bairro, setBairro] = useState('')
  const [logradouro, setLogradouro] = useState('')
  const [asseguradoraNome, setAsseguradoraNome] = useState('')
  const [asseguradoraApolice, setAsseguradoraApolice] = useState('')
  const [asseguradoraCnpj, setAsseguradoraCnpj] = useState('')
  const [asseguradoras, setAsseguradoras] = useState([])
  const [checkboxPermissaoAcesso, setCheckboxPermissaoAcesso] = useState(false)
  const [checkboxStatus, setCheckboxStatus] = useState(true)
  const [msgErro, setMsgErro] = useState(false)

  const razaoSocial = useRef('')
  const fotoPerfil = useRef('')
  const cnpj = useRef('')
  const inscricaoEstadual = useRef('')
  const telefoneContato = useRef('')
  const rntrc = useRef('')
  const certificadoDigital = useRef('')
  const senhaCertificadoDigital = useRef('')
  const email = useRef('')
  const numero = useRef('')
  const complemento = useRef('')
  const site = useRef('')
  const instagram = useRef('')
  const facebook = useRef('')
  const linkedin = useRef('')
  const observacoes = useRef('')
  const plano = useRef('')
  const validadePlano = useRef('')
  const validadePrazoAcesso = useRef('')

  const adicionarAsseguradora = () => {
    setAsseguradoras(asseguradoras => [...asseguradoras,
    {
      nome: asseguradoraNome,
      apolice: asseguradoraApolice,
      cnpj: asseguradoraCnpj
    }
    ])

    setAsseguradoraNome('')
    setAsseguradoraApolice('')
    setAsseguradoraCnpj('')
  }

  const obtemNomeEmbarcador = (idEmbarcador) => {
    api.get(`embarcador/${idEmbarcador}/`)
      .then(function (response) {
        setNomeEmbarcador(response.data.razao_social)
        setIdEmbarcador(idEmbarcador)
        setCheckboxIsParteEmbacador(true)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const removerMascaraCampo = (dado) => {
    return dado.replace('(', '').replace(')', '').replace(' ', '').replace('-', '').replace('.', '').replace('/', '')
  }

  const verificaPreenchimentoCampos = () => {
    if (razaoSocial.current.value.trim() === "" || cnpj.current.value.trim() === "" || inscricaoEstadual.current.value.trim() === ""
      || telefoneContato.current.value.trim() === "" || email.current.value.trim() === "" || rntrc.current.value.trim() === ""
      || pais.trim() === "" || cep.trim() === "" || uf.trim() === ""
      || municipio.trim() === "" || logradouro.trim() === ""
      || bairro.trim() === "" || numero.current.value.trim() === ""
      || complemento.current.value.trim() === "") {
      return setMsgErro(true)
    } else {
      return true
    }
  }

  const cadastrarTransportadora = () => {
    if (verificaPreenchimentoCampos()) {
      const formData = new FormData()
      formData.append("imagem_perfil", fotoPerfil.current.value !== "" ? fotoPerfil.current.files[0] : "")
      formData.append("certificado_digital", certificadoDigital.current.value !== "" ? certificadoDigital.current.files[0] : "")
      formData.append("certificado_digital_sincronizar", certificadoDigital.current.value !== "" ? true : false)
      formData.append("razao_social", razaoSocial.current.value)
      formData.append("cnpj", removerMascaraCampo(cnpj.current.value).replace('.', ''))
      formData.append("inscricao_estadual", inscricaoEstadual.current.value)
      formData.append("telefone", removerMascaraCampo(telefoneContato.current.value))
      formData.append("email", email.current.value)
      formData.append("RNTRC", rntrc.current.value)
      formData.append("certificado_digital_senha", senhaCertificadoDigital.current.value)
      formData.append("endereco.pais", pais)
      formData.append("endereco.uf", uf)
      formData.append("endereco.municipio", municipio)
      formData.append("endereco.logradouro", logradouro)
      formData.append("endereco.bairro", bairro)
      formData.append("endereco.numero", numero.current.value)
      formData.append("endereco.cep", cep)
      formData.append("endereco.complemento", complemento.current.value)
      formData.append("redes_sociais.site", site.current.value)
      formData.append("redes_sociais.instagram", instagram.current.value)
      formData.append("redes_sociais.facebook", facebook.current.value)
      formData.append("redes_sociais.linkedin", linkedin.current.value)
      formData.append("asseguradoras", asseguradoras)
      formData.append("observacoes", observacoes.current.value)
      formData.append("user_type", 2)
      formData.append("is_ativo", checkboxStatus)
      formData.append("embarcador", idEmbarcador)

      api.post("transportadora/", formData)
        .then(function (response) {
          let idTransportadora = response.data.id
          let auxAsseguradoras = asseguradoras

          if (asseguradoraNome !== '' && asseguradoraApolice !== '') {
            auxAsseguradoras.push({ nome: asseguradoraNome, apolice: asseguradoraApolice })
          }

          if (auxAsseguradoras.length > 0) {
            auxAsseguradoras.forEach((item) => {
              api.post("asseguradora/", {
                nome: item.nome,
                apolice: item.apolice,
                transportadora: idTransportadora
              })
                .then(function () {
                })
                .catch(function (e) {
                  console.log(e)
                })
            })

            history.push("/acesso/transportadora")
          } else {
            history.push("/acesso/transportadora")
          }
        })
        .catch(function (e) {
          console.log(e)
        })
    }
  }

  const editarTransportadora = () => {
    if (verificaPreenchimentoCampos()) {
      const formData = new FormData()
      if (fotoPerfil.current.files.length > 0) {
        formData.append("imagem_perfil", fotoPerfil.current.value !== "" ? fotoPerfil.current.files[0] : "")
      }

      if (certificadoDigital.current.files.length > 0) {
        formData.append("certificado_digital", certificadoDigital.current.value !== "" ? certificadoDigital.current.files[0] : "")
      }

      formData.append("certificado_digital_sincronizar", certificadoDigital.current.value !== "" ? true : false)
      formData.append("razao_social", razaoSocial.current.value)
      formData.append("cnpj", removerMascaraCampo(cnpj.current.value).replace('.', ''))
      formData.append("inscricao_estadual", inscricaoEstadual.current.value)
      formData.append("telefone", removerMascaraCampo(telefoneContato.current.value))
      formData.append("email", email.current.value)
      formData.append("RNTRC", rntrc.current.value)
      formData.append("certificado_digital_senha", senhaCertificadoDigital.current.value)
      formData.append("endereco.pais", pais)
      formData.append("endereco.uf", uf)
      formData.append("endereco.municipio", municipio)
      formData.append("endereco.logradouro", logradouro)
      formData.append("endereco.bairro", bairro)
      formData.append("endereco.numero", numero.current.value)
      formData.append("endereco.cep", cep)
      formData.append("endereco.complemento", complemento.current.value)
      formData.append("redes_sociais.site", site.current.value)
      formData.append("redes_sociais.instagram", instagram.current.value)
      formData.append("redes_sociais.facebook", facebook.current.value)
      formData.append("redes_sociais.linkedin", linkedin.current.value)
      formData.append("asseguradoras", asseguradoras)
      formData.append("observacoes", observacoes.current.value)
      formData.append("user_type", 2)
      formData.append("is_ativo", checkboxStatus)
      formData.append("embarcador", idEmbarcador)

      api.put(`transportadora/${idTranportadora}/`, formData)
        .then(function () {
          let auxAsseguradoras = asseguradoras

          if (certificadoDigital.current.files.length > 0) {
            let auxDadosUsuario = dadosUsuario
            auxDadosUsuario.certificado_digital_adicionado = true
            localStorage.setItem("@RASTRO:USER", JSON.stringify(auxDadosUsuario))
          }

          if (asseguradoraNome !== '' && asseguradoraApolice !== '') {
            auxAsseguradoras.push({ nome: asseguradoraNome, apolice: asseguradoraApolice, cnpj: asseguradoraApolice })
          }

          if (auxAsseguradoras.length > 0) {
            auxAsseguradoras.forEach((item) => {
              if (item.id) {
                api.put(`asseguradora/${item.id}/`, {
                  nome: item.nome,
                  apolice: item.apolice,
                  cnpj: removerMascaraCampo(item.cnpj).replace('.', '')
                })
                  .then(function () {
                  })
                  .catch(function (e) {
                    console.log(e.response)
                  })
              } else {
                api.post("asseguradora/", {
                  nome: item.nome,
                  apolice: item.apolice,
                  cnpj: removerMascaraCampo(item.cnpj).replace('.', ''),
                  transportadora: idTranportadora
                })
                  .then(function () {
                  })
                  .catch(function (e) {
                    console.log(e)
                  })
              }
            })

            history.push("/acesso/transportadora")
          } else {
            history.push("/acesso/transportadora")
          }
        })
        .catch(function (e) {
          console.log(e)
        })
    }
  }

  const editarAsseguradoras = (novoDado, index, chave) => {
    let auxAsseguradoras = [...asseguradoras]
    let asseguradoraEscolhida = auxAsseguradoras[index]
    asseguradoraEscolhida[chave] = novoDado

    setAsseguradoras(auxAsseguradoras)

    return
  }

  // useEffect(() => {
  //   fetch('https://servicodados.ibge.gov.br/api/v1/paises')
  //     .then(res => {
  //       return res.json()
  //     })
  //     .then(paises => {
  //       setPaises(paises)
  //     })
  // }, [])

  useEffect(() => {
    if (cep.length === 9) {
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((response) => {
          response.json()
            .then((data) => {
              let dadosEndereco = data

              setUf(dadosEndereco.uf)
              setMunicipio(dadosEndereco.localidade)
              setBairro(dadosEndereco.bairro)
              setLogradouro(dadosEndereco.logradouro)
            })
        })
        .catch((error) => {
          console.log("ERRO: " + error.message)
        })
    }
  }, [cep])

  useEffect(() => {
    if (pais === 'Brasil') {
      fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        .then(res => {
          return res.json()
        })
        .then(ufs => {
          setUfs(ufs)
        })
    } else {
      setUfs([])
    }
  }, [pais])

  useEffect(() => {
    fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`)
      .then(res => {
        return res.json()
      })
      .then(municipios => {
        setMunicipios(municipios)
      })
  }, [uf])

  useEffect(() => {
    if (tipoUsuario === "embarcador" || tipoUsuario === "colaborador_embarcador") {
      setIdEmbarcador(dadosUsuario.embarcador_id)
    }
  }, [dadosUsuario.embarcador_id, tipoUsuario])

  useEffect(() => {
    api.get(`embarcador/`)
      .then(function (response) {
        setEmbarcadores(response.data.results)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (mathIdEmbarcador) {
      obtemNomeEmbarcador(mathIdEmbarcador)
    }

    if (idTranportadora) {
      api.get(`transportadora/${idTranportadora}/`)
        .then(function (response) {
          let dadosTranportadora = response.data

          if (dadosTranportadora.embarcador !== null) {
            obtemNomeEmbarcador(dadosTranportadora.embarcador)
          }

          if (dadosTranportadora.asseguradoras.length > 0) {
            let auxAsseguradoras = []

            dadosTranportadora.asseguradoras.forEach((item) => {
              auxAsseguradoras.push(item)
            })

            setAsseguradoras(auxAsseguradoras)
          }

          razaoSocial.current.value = dadosTranportadora.razao_social
          cnpj.current.value = dadosTranportadora.cnpj
          inscricaoEstadual.current.value = dadosTranportadora.inscricao_estadual
          telefoneContato.current.value = dadosTranportadora.telefone
          email.current.value = dadosTranportadora.email
          rntrc.current.value = dadosTranportadora.RNTRC
          setPais(dadosTranportadora.endereco.pais)
          setCep(dadosTranportadora.endereco.cep)
          setUf(dadosTranportadora.endereco.uf)
          setMunicipio(dadosTranportadora.endereco.municipio)
          setLogradouro(dadosTranportadora.endereco.logradouro)
          setBairro(dadosTranportadora.endereco.bairro)
          numero.current.value = dadosTranportadora.endereco.numero
          complemento.current.value = dadosTranportadora.endereco.complemento
          site.current.value = dadosTranportadora.redes_sociais.site
          instagram.current.value = dadosTranportadora.redes_sociais.instagram
          facebook.current.value = dadosTranportadora.redes_sociais.facebook
          linkedin.current.value = dadosTranportadora.redes_sociais.linkedin
          observacoes.current.value = dadosTranportadora.observacoes
          setCheckboxStatus(dadosTranportadora.is_ativo)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [mathIdEmbarcador, idTranportadora])

  return (
    <div className="container-nova-transportadora">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="nova-transportadora">
        <p className="titulo-pagina">
          {
            desabilitarInputs && !isEditar
              ? "Visualizar Transportadora"
              : isEditar
                ? "Editar Transportadora"
                : "Nova Transportadora"
          }
        </p>
        <p className="subtitulo-pagina">
          {
            desabilitarInputs && !isEditar
              ? "Dados informados no cadastro da transportadora"
              : isEditar
                ? "Por favor, informe os dados solicitados para editar o cadastro da transportadora"
                : "Por favor, informe os dados solicitados para cadastro de nova transportadora"
          }
        </p>

        {(tipoUsuario !== "embarcador" && tipoUsuario !== "colaborador_embarcador") &&
          <div className="categoria">
            <p className="titulo-categoria">Transportadora faz parte de um embarcador?</p>

            <div className="container-inputs-1col">
              <div className="container-input-checkbox">
                <span
                  className="checkbox"
                  onClick={desabilitarInputs
                    ? () => { }
                    : () => setCheckboxIsParteEmbacador(!checkboxIsParteEmbacador)}
                >
                  <input
                    type="checkbox"
                    checked={checkboxIsParteEmbacador}
                    onChange={() => setCheckboxIsParteEmbacador(!checkboxIsParteEmbacador)}
                    disabled={desabilitarInputs}
                  />
                  <span className="checked"></span>
                  Sim
                </span>
              </div>
            </div>

            {
              checkboxIsParteEmbacador &&
              <InputSearch
                placeholder="Buscar Embarcador (Razão Social CNPJ)"
                value={nomeEmbarcador}
                saveValue={(e) => setNomeEmbarcador(e)}
                saveId={(e) => setIdEmbarcador(e)}
                disabled={desabilitarInputs || mathIdEmbarcador}
                suggestions={embarcadores}>
              </InputSearch>
            }
          </div>
        }

        <div className="categoria">
          <p className="titulo-categoria">Dados da Transportadora</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <input
                required
                type="text"
                ref={razaoSocial}
                disabled={desabilitarInputs}
              />
              <span>Razão Social <i>*</i></span>
            </div>

            <div className="container-input file">
              <input
                placeholder="Anexar Imagem Curso"
                type="file"
                accept="image/png, image/jpeg"
                ref={fotoPerfil}
              />
              <span>Foto</span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="99.999.999/9999-99"
                ref={cnpj}
                disabled={desabilitarInputs}
              ></InputMask>
              <span>CNPJ <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={inscricaoEstadual}
                disabled={desabilitarInputs}
              />
              <span>Inscrição Estadual <i>*</i></span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="(99) 99999-9999"
                maskChar=" "
                ref={telefoneContato}
                disabled={desabilitarInputs}
              ></InputMask>
              <span>Telefone Contato <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={email}
                disabled={desabilitarInputs}
              />
              <span>E-mail <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={rntrc}
                disabled={desabilitarInputs}
              />
              <span>RNTRC <i>*</i></span>
            </div>

            <div className="container-input file">
              <input
                placeholder="Anexar Imagem Curso"
                type="file"
                ref={certificadoDigital}
                disabled={desabilitarInputs}
              />
              <span>Certificado Digital</span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={senhaCertificadoDigital}
                disabled={desabilitarInputs}
              />
              <span>Senha Certificado Digital</span>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Endereço</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <select
                required
                value={pais}
                // onChange={(e) => setPais(e.target.value)}
                disabled={true}
              >
                <option value="Brasil" disabled>Brasil</option>
                {/* {paises.map((item) => {
                  return (
                    <option key={item.id.M49} value={item.nome.abreviado}>{item.nome.abreviado}</option>
                  )
                })} */}
              </select>
              <span>País <i>*</i></span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="99999-999"
                maskChar=""
                value={cep}
                onChange={(e) => setCep(e.target.value)}
                disabled={desabilitarInputs}
              ></InputMask>
              <span>CEP <i>*</i></span>
            </div>

            {ufs.length > 0
              ? <div className="container-input">
                <select
                  required
                  value={uf}
                  onChange={(e) => setUf(e.target.value)}
                  disabled={desabilitarInputs}
                >
                  <option value="" disabled></option>
                  {ufs.map((item) => {
                    return (
                      <option key={item.id} value={item.sigla}>{item.sigla}</option>
                    )
                  })}
                </select>
                <span>UF <i>*</i></span>
              </div>
              : <div className="container-input">
                <input
                  required
                  type="text"
                  value={uf}
                  onChange={(e) => setUf(e.target.value)}
                  disabled={desabilitarInputs}
                />
                <span>UF <i>*</i></span>
              </div>
            }

            {ufs.length > 0
              ? <div className="container-input">
                <select
                  required
                  value={municipio}
                  onChange={(e) => setMunicipio(e.target.value)}
                  disabled={desabilitarInputs}
                >
                  <option value="" disabled></option>
                  {municipios.map((item) => {
                    return (
                      <option key={item.id} value={item.nome}>{item.nome}</option>
                    )
                  })}
                </select>
                <span>Município <i>*</i></span>
              </div>
              : <div className="container-input">
                <input
                  required
                  type="text"
                  value={municipio}
                  onChange={(e) => setMunicipio(e.target.value)}
                  disabled={desabilitarInputs}
                />
                <span>Município <i>*</i></span>
              </div>
            }

            <div className="container-input">
              <input
                required
                type="text"
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
                disabled={desabilitarInputs}
              />
              <span>Bairro <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                value={logradouro}
                onChange={(e) => setLogradouro(e.target.value)}
                disabled={desabilitarInputs}
              />
              <span>Logradouro <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={numero}
                maxLength={4}
                disabled={desabilitarInputs}
              />
              <span>Número <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={complemento}
                disabled={desabilitarInputs}
              />
              <span>Complemento <i>*</i></span>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Redes Sociais</p>

          <div className="container-inputs-2col">
            <div>
              <div className="container-input">
                <input
                  required
                  type="text"
                  ref={site}
                  disabled={desabilitarInputs}
                />
                <span className="input-rede-social">
                  Site
                  <BsGlobe className="icone"></BsGlobe>
                </span>
              </div>

              <div className="container-input">
                <input
                  required
                  type="text"
                  ref={instagram}
                  disabled={desabilitarInputs}
                />
                <span className="input-rede-social">
                  Instagram
                  <FaInstagramSquare className="icone instagram"></FaInstagramSquare>
                </span>
              </div>
            </div>

            <div>
              <div className="container-input">
                <input
                  required
                  type="text"
                  ref={facebook}
                  disabled={desabilitarInputs}
                />
                <span className="input-rede-social">
                  Facebook
                  <BsFacebook className="icone facebook"></BsFacebook>
                </span>
              </div>

              <div className="container-input">
                <input
                  required
                  type="text"
                  ref={linkedin}
                  disabled={desabilitarInputs}
                />
                <span className="input-rede-social">
                  Linkedin
                  <BsLinkedin className="icone linkedin"></BsLinkedin>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Asseguradoras</p>

          {!desabilitarInputs &&
            <div className="container-inputs-3col">
              <div className="container-input">
                <input
                  required
                  type="text"
                  value={asseguradoraNome}
                  onChange={(e) => setAsseguradoraNome(e.target.value)}
                  disabled={desabilitarInputs}
                />
                <span>Nome <i>*</i></span>
              </div>

              <div className="container-input">
                <input
                  required
                  type="text"
                  value={asseguradoraApolice}
                  onChange={(e) => setAsseguradoraApolice(e.target.value)}
                  disabled={desabilitarInputs}
                />
                <span>Apólice <i>*</i></span>
              </div>

              <div className="container-input">
                <InputMask
                  required
                  mask="99.999.999/9999-99"
                  value={asseguradoraCnpj}
                  onChange={(e) => setAsseguradoraCnpj(e.target.value)}
                  disabled={desabilitarInputs}
                ></InputMask>
                <span>CNPJ <i>*</i></span>
              </div>
            </div>
          }

          {asseguradoras.map((item, index) => {
            return (
              <div className="container-inputs-3col" key={index}>
                <div className="container-input">
                  <input
                    required
                    type="text"
                    value={asseguradoras[index].nome}
                    onChange={(e) => editarAsseguradoras(e.target.value, index, "nome")}
                    disabled={desabilitarInputs}
                  />
                  <span>Nome <i>*</i></span>
                </div>

                <div className="container-input">
                  <input
                    required
                    type="text"
                    value={asseguradoras[index].apolice}
                    onChange={(e) => editarAsseguradoras(e.target.value, index, "apolice")}
                    disabled={desabilitarInputs}
                  />
                  <span>Apólice <i>*</i></span>
                </div>

                <div className="container-input">
                  <InputMask
                    required
                    mask="99.999.999/9999-99"
                    value={asseguradoras[index].cnpj}
                    onChange={(e) => editarAsseguradoras(e.target.value, index, "cnpj")}
                    disabled={desabilitarInputs}
                  ></InputMask>
                  <span>CNPJ <i>*</i></span>
                </div>
              </div>
            )
          })}

          {!desabilitarInputs &&
            <button
              className="btn-adicionar-asseguradora"
              onClick={adicionarAsseguradora}
            >
              <FaPlus className="icone"></FaPlus>
              Adicionar Mais
            </button>
          }
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Observações</p>

          <div className="container-inputs-1col-expandida">
            <div className="container-input">
              <textarea
                required
                rows={6}
                type="text"
                ref={observacoes}
                disabled={desabilitarInputs}
              />
              <span>Observações da transportadora</span>
            </div>
          </div>
        </div>

        {tipoUsuario === "admin" &&
          <div className="categoria">
            <p className="titulo-categoria">Plano da Transportadora</p>

            <div className="container-inputs-2col">
              <div className="container-input">
                <select
                  required
                  ref={plano}
                  defaultValue={''}
                  disabled={desabilitarInputs}
                >
                  <option value="" disabled></option>
                </select>
                <span>Selecione o Plano <i>*</i></span>
              </div>

              <div className="container-input">
                <input
                  required
                  type="date"
                  ref={validadePlano}
                  disabled={desabilitarInputs}
                />
                <span>Validade do Plano <i>*</i></span>
              </div>
            </div>
          </div>
        }

        <div className="categoria">
          <p className="titulo-categoria">Prazo de Acesso</p>

          <div className="container-inputs-1col">
            <div className="container-input-checkbox">
              <span
                className="checkbox"
                onClick={desabilitarInputs
                  ? () => { }
                  : () => setCheckboxPermissaoAcesso(!checkboxPermissaoAcesso)}
              >
                <input
                  type="checkbox"
                  checked={checkboxPermissaoAcesso}
                  onChange={() => setCheckboxPermissaoAcesso(!checkboxPermissaoAcesso)}
                  disabled={desabilitarInputs}
                />
                <span className="checked"></span>
                Prazo de permissão de acesso
              </span>
            </div>

            <div className="container-input">
              <input
                required
                type="date"
                ref={validadePrazoAcesso}
                disabled={desabilitarInputs || !checkboxPermissaoAcesso}
              />
              <span>Data Validade <i>*</i></span>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Status Transportadora</p>

          <div className="container-inputs-1col">
            <div className="container-input-checkbox">
              <span className="checkbox" onClick={desabilitarInputs ? () => { } : () => setCheckboxStatus(!checkboxStatus)} >
                <input
                  type="checkbox"
                  checked={checkboxStatus}
                  onChange={() => setCheckboxStatus(!checkboxStatus)}
                  disabled={desabilitarInputs}
                />
                <span className="checked"></span>
                Ativo
              </span>
            </div>
          </div>
        </div>

        {
          !desabilitarInputs &&
          <div className="container-botoes">
            <button
              className="btn-cancelar"
              onClick={() => history.replace("/acesso/transportadora")}
            >
              Cancelar
            </button>

            <button
              className="btn-cadastrar"
              onClick={isEditar ? () => editarTransportadora() : cadastrarTransportadora}
            >
              {isEditar ? "Salvar Transportadora" : "Cadastrar Transportadora"}
            </button>
          </div>
        }

        {msgErro && <p className="msg-erro">Todos os campos com * devem ser preenchidos!</p>}
      </div>
    </div>
  )
}

export default memo(NovaTransportadora)