import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import MenuLateral from "../components/plataformaAcessada/MenuLateral/index";

// import Dashboard from '../pages/plataformaAcessada/Dashboard'
import HistoricoNotificacoes from "../pages/plataformaAcessada/HistoricoNotificacoes";

import NovoEmbarcador from "../pages/plataformaAcessada/Embarcador/NovoEmbarcador";
import CadastroSimplificadoEmbarcador from "../pages/plataformaAcessada/Embarcador/CadastroSimplificado";
import GerenciarEmbarcador from "../pages/plataformaAcessada/Embarcador/GerenciarEmbarcador";
import GerenciarEmbarcadorTransportadora from "../pages/plataformaAcessada/Embarcador/GerenciarEmbarcadorTransportadora";
import GerenciarEmbarcadorMotorista from "../pages/plataformaAcessada/Embarcador/GerenciarEmbarcadorMotorista";

import NovaTransportadora from "../pages/plataformaAcessada/Transportadora/NovaTransportadora";
import CadastroSimplificadoTransportadora from "../pages/plataformaAcessada/Transportadora/CadastroSimplificado";
import GerenciarTransportadora from "../pages/plataformaAcessada/Transportadora/GerenciarTransportadora";
import GerenciarTransportadoraMotorista from "../pages/plataformaAcessada/Transportadora/GerenciarTransportadoraMotorista";
import TransportadorasPublicas from "../pages/plataformaAcessada/Transportadora/TransportadorasPublicas";
import TransportadorasFavoritas from "../pages/plataformaAcessada/Transportadora/TransportadorasFavoritas";

import GerenciarFinanceiro from "../pages/plataformaAcessada/GerenciarFinanceiro";
import GerenciarAliquotas from "../pages/plataformaAcessada/GerenciarAliquotas";
import GerenciarContrato from "../pages/plataformaAcessada/GerenciarContrato";

import NovoMotorista from "../pages/plataformaAcessada/Motorista/NovoMotorista";
import CadastroSimplificadoMotorista from "../pages/plataformaAcessada/Motorista/CadastroSimplificado";
import GerenciarMotorista from "../pages/plataformaAcessada/Motorista/GerenciarMotorista";
import MotoristasPublicos from "../pages/plataformaAcessada/Motorista/MotoristasPublicos";
import MotoristasFavoritos from "../pages/plataformaAcessada/Motorista/MotoristasFavoritos";
import GrupoMotoristas from "../pages/plataformaAcessada/Motorista/GrupoMotoristas";
import NovoGrupo from "../pages/plataformaAcessada/Motorista/GrupoMotoristas/NovoGrupo";
import GerenciarMotoristasDoGrupo from "../pages/plataformaAcessada/Motorista/GrupoMotoristas/GerenciarMotoristasDoGrupo";

import NovoDestinatario from "../pages/plataformaAcessada/Destinatario/NovoDestinatario";
import GerenciarDestinatario from "../pages/plataformaAcessada/Destinatario/GerenciarDestinatario";

import NovoTipoDeCaminhao from "../pages/plataformaAcessada/TipoDeCaminhao/NovoTipoDeCaminhao";
import GerenciarTipoDeCaminhao from "../pages/plataformaAcessada/TipoDeCaminhao/GerenciarTipoDeCaminhao";

import NovaCarroceria from "../pages/plataformaAcessada/Carroceria/NovaCarroceria";
import GerenciarCarroceria from "../pages/plataformaAcessada/Carroceria/GerenciarCarroceria";

import NovoColaborador from "../pages/plataformaAcessada/Colaborador/NovoColaborador";
import GerenciarColaborador from "../pages/plataformaAcessada/Colaborador/GerenciarColaborador";
import GerenciarPermissoes from "../pages/plataformaAcessada/Colaborador/Permissoes/GerenciarPermissoes";
import NovoGrupoPermissoes from "../pages/plataformaAcessada/Colaborador/Permissoes/NovoGrupoPermissoes";

import PainelMonitoramento from "../pages/plataformaAcessada/PainelMonitoramento/Painel";
import Agenda from "../pages/plataformaAcessada/PainelMonitoramento/Agenda";
import Historico from "../pages/plataformaAcessada/PainelMonitoramento/Historico";
import DocumentosGerais from "../pages/plataformaAcessada/PainelMonitoramento/DocumentosGerais";
import BaixarCteLote from "../pages/plataformaAcessada/PainelMonitoramento/BaixarCteLote";

import PerfilMotorista from "../pages/plataformaAcessada/Perfis/Motorista";
import PerfilTransportadora from "../pages/plataformaAcessada/Perfis/Transportadora";
import PerfilEmbarcador from "../pages/plataformaAcessada/Perfis/Embarcador";
import PerfilEditarTransportadora from "../pages/plataformaAcessada/Perfis/EditarTransportadora";

import Compra from "../pages/plataformaAcessada/RastroCoins/Compra";
import HistoricoTransacoes from "../pages/plataformaAcessada/RastroCoins/HistoricoTransacoes";
import useNetworkStatus from "../services/useNetworkStatus";
import AlertNetworkStatus from "../components/plataformaAcessada/AlertNetworkStatus";
import { useState } from "react";
import { useEffect } from "react";
import ModalAlertaCoins from '../components/plataformaAcessada/ModalAlertaCoins';
import NovoCliente from "../pages/plataformaAcessada/Cliente/NovoCliente";
import GerenciarCliente from "../pages/plataformaAcessada/Cliente/GerenciarCliente";
import GerenciarTabelas from "../pages/plataformaAcessada/Cliente/GerenciarTabelas";
import NovaTabela from "../pages/plataformaAcessada/Cliente/NovaTabela";

import GerenciarColetas from "../pages/plataformaAcessada/Coletas/GerenciarColetas";

export default function PlataformaAcessada({ match }) {

  const { isOnline } = useNetworkStatus();
  const [prevOnline, setPrevOnline] = useState(isOnline);

  useEffect(() => {
    if ((prevOnline && !isOnline) || !isOnline) {
      AlertNetworkStatus("Falha de Comunicação com o sistema.", "Verifique sua internet e tente novamente.", "off");
    } else if (!prevOnline && isOnline) {
      AlertNetworkStatus("Conexão Restabelecida.", "Você está online novamente.", "on");
    }

    setPrevOnline(isOnline);

  }, [isOnline, prevOnline]);

  return (
    <div style={{ display: "flex", backgroundColor: "#f1f4fa" }}>
      <MenuLateral></MenuLateral>

      <Switch>
        <Route
          path={`${match.url}`}
          exact
          component={PainelMonitoramento}
        ></Route>
        <Route
          path={`${match.url}/historico-notificacoes`}
          exact
          component={HistoricoNotificacoes}
        ></Route>

        <Route path={`${match.url}/embarcador`} exact>
          <Redirect
            to={`${match.url}/embarcador/gerenciar-embarcador`}
          ></Redirect>
        </Route>
        <Route
          path={`${match.url}/embarcador/novo-embarcador`}
          exact
          component={NovoEmbarcador}
        ></Route>
        <Route
          path={`${match.url}/embarcador/cadastro-simplificado`}
          exact
          component={CadastroSimplificadoEmbarcador}
        ></Route>
        <Route
          path={`${match.url}/embarcador/gerenciar-embarcador`}
          exact
          component={GerenciarEmbarcador}
        ></Route>
        <Route
          path={`${match.url}/embarcador/gerenciar-embarcador/:idEmbarcador/transportadora`}
          exact
          component={GerenciarEmbarcadorTransportadora}
        ></Route>
        <Route
          path={`${match.url}/embarcador/gerenciar-embarcador/:idEmbarcador/transportadora/:idTransportadora/motorista`}
          exact
          component={GerenciarEmbarcadorMotorista}
        ></Route>
        <Route
          path={`${match.url}/embarcador/visualizar-embarcador/:idEmbarcador`}
          exact
          component={NovoEmbarcador}
        ></Route>
        <Route
          path={`${match.url}/embarcador/editar-embarcador/:idEmbarcador`}
          exact
          component={NovoEmbarcador}
        ></Route>

        <Route path={`${match.url}/transportadora`} exact>
          <Redirect
            to={`${match.url}/transportadora/gerenciar-transportadora`}
          ></Redirect>
        </Route>
        <Route
          path={`${match.url}/transportadora/nova-transportadora/:idEmbarcador?`}
          exact
          component={NovaTransportadora}
        ></Route>
        <Route
          path={`${match.url}/transportadora/cadastro-simplificado`}
          exact
          component={CadastroSimplificadoTransportadora}
        ></Route>
        <Route
          path={`${match.url}/transportadora/gerenciar-transportadora`}
          exact
          component={GerenciarTransportadora}
        ></Route>
        <Route
          path={`${match.url}/transportadora/gerenciar-transportadora/:idTransportadora/motorista`}
          exact
          component={GerenciarTransportadoraMotorista}
        ></Route>
        <Route
          path={`${match.url}/transportadora/transportadoras-publicas`}
          exact
          component={TransportadorasPublicas}
        ></Route>
        <Route
          path={`${match.url}/transportadora/transportadoras-favoritas`}
          exact
          component={TransportadorasFavoritas}
        ></Route>
        <Route
          path={`${match.url}/transportadora/visualizar-transportadora/:idTransportadora`}
          exact
          component={NovaTransportadora}
        ></Route>
        <Route
          path={`${match.url}/transportadora/editar-transportadora/:idTransportadora`}
          exact
          component={NovaTransportadora}
        ></Route>

        <Route
          path={`${match.url}/financeiro`}
          exact
          component={GerenciarFinanceiro}
        ></Route>

        <Route
          path={`${match.url}/aliquotas`}
          exact
          component={GerenciarAliquotas}
        />

        <Route
          path={`${match.url}/contrato`}
          exact
          component={GerenciarContrato}
        ></Route>

        <Route path={`${match.url}/motorista`} exact>
          <Redirect
            to={`${match.url}/motorista/gerenciar-motorista`}
          ></Redirect>
        </Route>
        <Route
          path={`${match.url}/motorista/novo-motorista`}
          exact
          component={NovoMotorista}
        ></Route>
        <Route
          path={`${match.url}/motorista/cadastro-simplificado`}
          exact
          component={CadastroSimplificadoMotorista}
        ></Route>
        <Route
          path={`${match.url}/motorista/gerenciar-motorista`}
          exact
          component={GerenciarMotorista}
        ></Route>
        <Route
          path={`${match.url}/motorista/motoristas-publicos`}
          exact
          component={MotoristasPublicos}
        ></Route>
        <Route
          path={`${match.url}/motorista/grupos-motoristas`}
          exact
          component={GrupoMotoristas}
        ></Route>
        <Route
          path={`${match.url}/motorista/grupos-motoristas/novo-grupo`}
          exact
          component={NovoGrupo}
        ></Route>
        <Route
          path={`${match.url}/motorista/grupos-motoristas/editar-grupo/:idGrupo`}
          exact
          component={NovoGrupo}
        ></Route>
        <Route
          path={`${match.url}/motorista/grupos-motoristas/gerenciar-motoristas/:idGrupo`}
          exact
          component={GerenciarMotoristasDoGrupo}
        ></Route>
        <Route
          path={`${match.url}/motorista/motoristas-favoritos`}
          exact
          component={MotoristasFavoritos}
        ></Route>
        <Route
          path={`${match.url}/motorista/visualizar-motorista/:idMotorista`}
          exact
          component={NovoMotorista}
        ></Route>
        <Route
          path={`${match.url}/motorista/editar-motorista/:idMotorista`}
          exact
          component={NovoMotorista}
        ></Route>

        <Route path={`${match.url}/destinatario`} exact>
          <Redirect
            to={`${match.url}/destinatario/gerenciar-destinatario`}
          ></Redirect>
        </Route>
        <Route
          path={`${match.url}/destinatario/novo-destinatario`}
          exact
          component={NovoDestinatario}
        ></Route>
        <Route
          path={`${match.url}/destinatario/gerenciar-destinatario`}
          exact
          component={GerenciarDestinatario}
        ></Route>
        <Route
          path={`${match.url}/destinatario/visualizar-destinatario/:idDestinatario`}
          exact
          component={NovoDestinatario}
        ></Route>
        <Route
          path={`${match.url}/destinatario/editar-destinatario/:idDestinatario`}
          exact
          component={NovoDestinatario}
        ></Route>

        <Route path={`${match.url}/clientes`} exact>
          <Redirect
            to={`${match.url}/clientes/gerenciar`}
          ></Redirect>
        </Route>
        <Route
          path={`${match.url}/clientes/novo`}
          exact
          component={NovoCliente}
        ></Route>
        <Route
          path={`${match.url}/clientes/gerenciar`}
          exact
          component={GerenciarCliente}
        ></Route>
        <Route
          path={`${match.url}/clientes/tabelas`}
          exact
          component={GerenciarTabelas}
        ></Route>
        <Route
          path={`${match.url}/clientes/tabelas/criar`}
          exact
          component={NovaTabela}
        ></Route>
        <Route
          path={`${match.url}/clientes/tabelas/visualizar/:id`}
          exact
          component={NovaTabela}
        ></Route>
        <Route
          path={`${match.url}/clientes/tabelas/editar/:id`}
          exact
          component={NovaTabela}
        ></Route>
        <Route
          path={`${match.url}/clientes/visualizar/:id`}
          exact
          component={NovoCliente}
        ></Route>
        <Route
          path={`${match.url}/clientes/editar/:id`}
          exact
          component={NovoCliente}
        ></Route>
        <Route
          path={`${match.url}/coletas/`}
          exact
          component={GerenciarColetas}
        />

        <Route path={`${match.url}/tipo-caminhao`} exact>
          <Redirect
            to={`${match.url}/tipo-caminhao/gerenciar-tipo-caminhao`}
          ></Redirect>
        </Route>
        <Route
          path={`${match.url}/tipo-caminhao/novo-tipo-caminhao`}
          exact
          component={NovoTipoDeCaminhao}
        ></Route>
        <Route
          path={`${match.url}/tipo-caminhao/gerenciar-tipo-caminhao`}
          exact
          component={GerenciarTipoDeCaminhao}
        ></Route>
        <Route
          path={`${match.url}/tipo-caminhao/visualizar-tipo-caminhao/:idTipoCaminhao`}
          exact
          component={NovoTipoDeCaminhao}
        ></Route>
        <Route
          path={`${match.url}/tipo-caminhao/editar-tipo-caminhao/:idTipoCaminhao`}
          exact
          component={NovoTipoDeCaminhao}
        ></Route>

        <Route path={`${match.url}/carroceria`} exact>
          <Redirect
            to={`${match.url}/carroceria/gerenciar-carroceria`}
          ></Redirect>
        </Route>
        <Route
          path={`${match.url}/carroceria/nova-carroceria`}
          exact
          component={NovaCarroceria}
        ></Route>
        <Route
          path={`${match.url}/carroceria/gerenciar-carroceria`}
          exact
          component={GerenciarCarroceria}
        ></Route>
        <Route
          path={`${match.url}/carroceria/visualizar-carroceria/:idCarroceria`}
          exact
          component={NovaCarroceria}
        ></Route>
        <Route
          path={`${match.url}/carroceria/editar-carroceria/:idCarroceria`}
          exact
          component={NovaCarroceria}
        ></Route>

        <Route path={`${match.url}/colaborador`} exact>
          <Redirect
            to={`${match.url}/colaborador/gerenciar-colaborador`}
          ></Redirect>
        </Route>
        <Route
          path={`${match.url}/colaborador/novo-colaborador`}
          exact
          component={NovoColaborador}
        ></Route>
        <Route
          path={`${match.url}/colaborador/gerenciar-colaborador`}
          exact
          component={GerenciarColaborador}
        ></Route>
        <Route
          path={`${match.url}/colaborador/visualizar-colaborador/:idColaborador`}
          exact
          component={NovoColaborador}
        ></Route>
        <Route
          path={`${match.url}/colaborador/editar-colaborador/:idColaborador`}
          exact
          component={NovoColaborador}
        ></Route>
        <Route
          path={`${match.url}/colaborador/permissoes`}
          exact
          component={GerenciarPermissoes}
        ></Route>
        <Route
          path={`${match.url}/colaborador/permissoes/novo-grupo-permissoes`}
          exact
          component={NovoGrupoPermissoes}
        ></Route>
        <Route
          path={`${match.url}/colaborador/permissoes/visualizar-grupo-permissoes/:id`}
          exact
          component={NovoGrupoPermissoes}
        ></Route>
        <Route
          path={`${match.url}/colaborador/permissoes/editar-grupo-permissoes/:id`}
          exact
          component={NovoGrupoPermissoes}
        ></Route>

        <Route
          path={`${match.url}/painel-monitoramento`}
          exact
          component={PainelMonitoramento}
        ></Route>
        <Route
          path={`${match.url}/painel-monitoramento/agenda`}
          exact
          component={Agenda}
        ></Route>
        <Route
          path={`${match.url}/painel-monitoramento/historico`}
          exact
          component={Historico}
        ></Route>
        <Route
          path={`${match.url}/painel-monitoramento/documentos/ctes`}
          exact
          component={BaixarCteLote}
        ></Route>
        <Route
          path={`${match.url}/painel-monitoramento/documentos`}
          exact
          component={DocumentosGerais}
        ></Route>

        <Route
          path={`${match.url}/perfil-motorista`}
          exact
          component={PerfilMotorista}
        ></Route>
        <Route
          path={`${match.url}/perfil-transportadora`}
          exact
          component={PerfilTransportadora}
        ></Route>
        <Route
          path={`${match.url}/perfil-editar-transportadora`}
          exact
          component={PerfilEditarTransportadora}
        ></Route>
        <Route
          path={`${match.url}/perfil-embarcador`}
          exact
          component={PerfilEmbarcador}
        ></Route>

        <Route
          path={`${match.url}/rastro-coins`}
          exact
          component={Compra}
        ></Route>

        <Route
          path={`${match.url}/historico-transacoes`}
          exact
          component={HistoricoTransacoes}
        ></Route>
      </Switch>

      <ModalAlertaCoins />
    </div>
  );
}
