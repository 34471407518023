import React, { memo, useEffect, useState } from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { NavLink, Link } from "react-router-dom";
import { FaRegBell } from "react-icons/fa";

import "./styles.css";

import api from "../../../services/api";

const BreadCrumbs = ({ routes, isPainelMonitoramento }) => {
  const breadcrumbs = useBreadcrumbs(routes);

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
  const tipoUsuario = dadosUsuario.user_type;

  const [notificacoes, setNotificacoes] = useState([]);
  const [saldoRastroCoins, setSaldoRastroCoins] = useState(0);
  const [transportadora, setTransportadora] = useState(0);

  useEffect(() => {
    if (
      tipoUsuario === "transportadora" ||
      tipoUsuario === "colaborador_transportadora"
    ) {
      api
        .get(
          `empresa_notificacoes/?transportadora=${dadosUsuario.transportadora_id}`
        )
        .then(function (response) {
          setNotificacoes(response.data.results);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (
      tipoUsuario === "embarcador" ||
      tipoUsuario === "colaborador_embarcador"
    ) {
      api
        .get(`empresa_notificacoes/?embarcador=${dadosUsuario.embarcador_id}`)
        .then(function (response) {
          setNotificacoes(response.data.results);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [tipoUsuario, dadosUsuario.transportadora_id, dadosUsuario.embarcador_id]);

  useEffect(() => {
    api
      .get(`pagamentos/saldo/`)
      .then(function (response) {
        let auxSaldoRastroCoins = 0;

        response.data?.items.forEach((saldo) => {
          auxSaldoRastroCoins += saldo.coins;
        });

        setSaldoRastroCoins(auxSaldoRastroCoins);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    api.get(`transportadora/${dadosUsuario.transportadora_id}`)
      .then(function (response) {
        let dadosTransportadora = response.data
        setTransportadora(dadosTransportadora)

      })
      .catch(function (error) {
        console.log(error)
      })
  }, [dadosUsuario.transportadora_id])

  return (
    <div className="container-breadcrumbs">
      <div className="container-breads">
        {breadcrumbs.map(
          ({ match, breadcrumb }) =>
            match.path !== "/" && (
              <span key={match.path} className="breadcrumb">
                <NavLink
                  to={match.path}
                  activeClassName="breadcrumb-ativo"
                  exact={true}
                >
                  {breadcrumb}
                </NavLink>
              </span>
            )
        )}

        {isPainelMonitoramento && (
          <>
            <span className="breadcrumb">
              <Link to="/acesso/painel-monitoramento/historico">Histórico</Link>
            </span>

            <span className="breadcrumb">
              <Link to="/acesso/painel-monitoramento/documentos">
                Documentos
              </Link>
            </span>
            
            <span className="breadcrumb">
              <Link to="/acesso/coletas">
                Coletas
              </Link>
            </span>
          </>
        )}
      </div>

      <div className="outras-navegacoes">
        {transportadora.razao_social &&
          <div>
            <span className="nome-transportadora">
              {`${transportadora.razao_social}  - `}
            </span>
            <span className="usuario-logado">
              {dadosUsuario.username}
            </span>
          </div>
        }

        <Link to="/acesso/historico-transacoes">
          <button className="botao-rastro-coins">
            RC - {saldoRastroCoins}
          </button>
        </Link>

        <div className="container-notificacoes">
          <Link to="/acesso/historico-notificacoes">
            <FaRegBell className="icone"></FaRegBell>

            {notificacoes.filter((item) => !item.visualizado).length > 0 && (
              <div className="quantidade">
                {notificacoes.filter((item) => !item.visualizado).length}
              </div>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default memo(BreadCrumbs);
