const LISTA_CFOPS = [
  '1.206-Anul. de val. relat. à prest. de serv. de trans.',
  '2.206-Anul. de val. relat. à prest. de serv. de trans.',
  '3.206-Anul. de val. relat. à prest. de serv. de trans.',
  '5.206-Anul. de val. relat. à aquis. de serv. de trans.',
  '5.351-Prest. serv. trans. p exe. de serv. da mesma nat.',
  '5.352-Prest. serv. trans. a estab. Ind.',
  '5.353-Prest. serv. trans. a estab. comercial',
  '5.354-Prest. serv. trans. a estab. de prest. de serv. de comunic.',
  '5.355-Prest. serv. trans. a estab. ger. ou distr. energia elétrica',
  '5.356-Prest. serv. trans. a estab. de produtor rural',
  '5.357-Prest. serv. trans. a não contr',
  '5.359-Prest. serv. trans. qnd. a merc trans e disp de emis. de nf',
  '5.360-Prest. serv. trans. a contr subst em rel. ao serv. de trans.',
  '5.601-Trans. de crédito de ICMS acumulado',
  '5.602-Trans. sal cred ICMS p mesma empresa p comp de sal. dev ICMS',
  '5.603-Ressarcimento de ICMS retido por substituição tributária',
  '5.605-Trans. de sal dev de ICMS de outro estab. da mesma empresa',
  '5.606-Util de sal cred de ICMS p extin p comps de débitos fiscais',
  '5.932-Pres de serv de tran inic em und fed dif d onde insc o prest',
  '5.949-Outra saída de merc. ou prest. de serv. não especificado',
  '6.206-Anul. de val. relat. a aquis. de serv. de trans.',
  '6.351-Prest. de serv. de trans. p exe. de serv. da mesma nat.',
  '6.352-Prest. de serv. de trans. a estab. industria',
  '6.353-Prest. de serv. de trans. a estab. comercial',
  '6.354-Prest de serv de trans a estab de prest de serv de comunic.',
  '6.355-Prest de serv de trans estab ger ou de distr de ener eletr',
  '6.356-Prest. de serv. de trans. a estab. de produtor rural',
  '6.357-Prest. de serv. de trans. a não contr.',
  '6.359-Prest serv trans quando merc trans está disp de emis de nf',
  '6.360-Prest de serv de trans a contr subst em rel ao serv de trans',
  '6.603-Ressarcimento de ICMS retido por substituição tributária',
  '6.932-Prest serv de tran insc em un fed dif d onde inscr o prest',
  '6.949-Outra saída de merc. ou prest. de serv. não especificado',
  '7.206-Anul. de val. relat. a aquis. de serv. de trans.',
  '7.358-Prest. de serv. de trans.',
  '7.949-Outra saída de merc. ou prest. de serv. não especificado'
];

export default LISTA_CFOPS;