import React, { memo, useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FaTrashAlt, FaUserPlus } from 'react-icons/fa'
import { ImSearch } from 'react-icons/im'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ReactPaginate from 'react-paginate'

import './styles.css'

import BreadCrumbs from '../../../../../components/plataformaAcessada/BreadCrumbs'
import api from "../../../../../services/api"
import ModalAdicionarMotorista from '../ModalAdicionarMotorista'

const GerenciarMotoristasDoGrupo = ({ match }) => {
  const history = useHistory()
  const idGrupo = match.params?.idGrupo

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/motorista/grupos-motoristas', breadcrumb: 'Grupos de Motoristas' },
    { path: '/acesso/motorista/grupos-motoristas/gerenciar-motoristas', breadcrumb: null },
    { path: `/acesso/motorista/grupos-motoristas/gerenciar-motoristas/${idGrupo}/`, breadcrumb: 'Gerenciar Motoristas' },
  ]

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const [grupo, setGrupo] = useState('')
  const [motoristas, setMotoristas] = useState([])
  const [search, setSearch] = useState('')
  const [quantidadePaginas, setQuantidadePaginas] = useState(1)
  const [paginaAtual, setPaginaAtual] = useState(0)
  const [abrirModalAdicionarMotorista, setAbrirModalAdicionarMotorista] = useState(false)

  const abrirModal = () => {
    setAbrirModalAdicionarMotorista(true)
    document.querySelector("body").style.overflow = "hidden"
  }

  const fecharModal = useCallback(() => {
    setAbrirModalAdicionarMotorista(false)
    document.querySelector("body").style.overflow = "visible"
  }, [])

  const requisitarMotoristas = useCallback((paginaSelecionada, offSet) => {
    setPaginaAtual(paginaSelecionada)

    api.get(`motoristasgrupo/?grupo=${idGrupo}&?offset=${offSet ? offSet : 0}`)
      .then(function (response) {
        setQuantidadePaginas(Math.ceil(response.data.count / 30))
        let auxMotoristas = []

        response.data.results.sort((x, y) => x.id - y.id).forEach((item) => {
          auxMotoristas.push(item)
        })

        setMotoristas(auxMotoristas)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idGrupo])

  const filtrarItens = () => {
    setQuantidadePaginas(1)
    setMotoristas([])
    setPaginaAtual(0)

    api.get(`/motoristasgrupo/filtro_motoristas_grupo/?grupo=${idGrupo}&nome=${search}`)
      .then(function (response) {
        console.log("xxx", response.data)
        setMotoristas(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const verificarTeclaSearch = (e) => {
    if (e.keyCode === 13) {
      filtrarItens()
    }
  }

  const removerMotorista = (nome, id) => {
    confirmAlert({
      title: "Remover Motorista",
      message: `Deseja realmente remover o motorista "${nome}" do grupo?`,
      overlayClassName: "alert-remover-motorista-grupo",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            api.delete(`motoristasgrupo/${id}/`)
              .then(function () {
                requisitarMotoristas()
              })
              .catch(function (error) {
              })
          },
          className: "botao-confirmar"
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar"
        }
      ]
    })
  }

  useEffect(() => {
    if (search === '') {
      forceUpdate()
      requisitarMotoristas()
    }
  }, [requisitarMotoristas, forceUpdate, search])

  useEffect(() => {
    api.get(`grupos/${idGrupo}/`)
      .then(function (response) {
        setGrupo(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idGrupo])

  return (
    <div className="container-gerenciar-motoritas-grupo">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="gerenciar-motoritas-grupo">
        <div className="container-topo">
          <div>
            <p className="titulo-pagina">Gerenciar Motoristas - Grupo</p>
          </div>
        </div>

        <input
          className="input-nome-transportadora"
          type="text"
          defaultValue={grupo?.descricao}
          disabled={true}
        />

        <p className="subtitulo-pagina">
          Motoristas vinculados ao grupo. Realize o filtro para busca do motorista desejado
        </p>

        <div className="container-search-adicionar">
          <div className="container-search">
            <div className="input-search">
              <input
                type="text"
                placeholder="Buscar Motorista (Razão Social)"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={verificarTeclaSearch}
              />
              <ImSearch className="icone"></ImSearch>
            </div>

            <button className="btn-buscar" onClick={filtrarItens}>Buscar</button>
          </div>

          <button className="btn-novo-motorista-grupo" onClick={abrirModal}>
            <FaUserPlus className="icone"></FaUserPlus>
            Novo Motorista
          </button>
        </div>

        <div className="container-tabela-listar-motoristas-grupo">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Tipo Veículo</th>
                <th>Ações</th>
              </tr>
            </thead>

            <tbody>
              {motoristas.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr className="divisoria-linhas-tabela"></tr>
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.motorista.nome_completo}</td>
                      <td>{item.motorista?.veiculo?.tipo_veiculo?.tipo}</td>

                      <td className="coluna-acoes">
                        <FaTrashAlt className="icone" onClick={() => removerMotorista(item.motorista.nome_completo, item.id)}></FaTrashAlt>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="container-paginacao-motoristas-grupo">
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próxima"}
            breakLabel={"..."}
            pageCount={quantidadePaginas}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={paginaAtual}
            onPageChange={(e) => search === ""
              ? requisitarMotoristas(e.selected, e.selected * 30)
              : filtrarItens(e.selected, e.selected * 30)
            }
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>

      {abrirModalAdicionarMotorista &&
        <ModalAdicionarMotorista
          idGrupo={idGrupo}
          abrirModalAdicionarMotorista={abrirModalAdicionarMotorista}
          fecharModal={fecharModal}
          requisitarMotoristas={requisitarMotoristas}
        ></ModalAdicionarMotorista>
      }
    </div>
  )
}

export default memo(GerenciarMotoristasDoGrupo)