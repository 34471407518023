import { useQuery } from 'react-query';
import api from '../../../services/api'
import { useSearch } from '../../hooks'
import {API_CLIENT_PAGE_SIZE} from '../../config';

export const getColetasPaginated = async ({
  page,
  vincularCarga,
  cliente,
  ufRemetente,
  ufDestinatario,
  municipioRemetente,
  municipioDestinatario,
  dataInicial,
  dataFinal,
  status,
}) => {
  let path = `/fracionado/coletas/?limit=${API_CLIENT_PAGE_SIZE}&`;
  if (page) {
    path = `${path}offset=${(page - 1) * API_CLIENT_PAGE_SIZE}&`;
  }
  if (vincularCarga) {
    path = `${path}vincular_carga=1&`;
  }
  if (cliente) {
    path = `${path}cliente=${cliente}&`
  }
  if (ufRemetente) {
    path = `${path}remetente_uf=${ufRemetente}&`
  }
  if (municipioRemetente) {
    path = `${path}remetente_municipio=${municipioRemetente}&`
  }
  if (ufDestinatario) {
    path = `${path}destinatario_uf=${ufDestinatario}&`
  }
  if (municipioDestinatario) {
    path = `${path}destinatario_municipio=${municipioDestinatario}&`
  }
  if (dataInicial) {
    path = `${path}data_solicitacao__gte=${dataInicial}&`
  }
  if (dataFinal) {
    path = `${path}data_solicitacao__lte=${dataFinal}&`
  }
  if (status) {
    path = `${path}status=${status}&`
  }

  const { data } = await api.get(path);
  return data;
}

export const useColetasPaginated = (
  rawPage,
  rawCliente,
  rawUfRemetente,
  rawUfDestinatario,
  rawMunicipioRemetente,
  rawMunicipioDestinatario,
  rawDataInicial,
  rawDataFinal,
  vincularCarga = false
) => {
  const {
    page,
    cliente,
    ufRemetente,
    ufDestinatario,
    municipioRemetente,
    municipioDestinatario,
    dataInicial,
    dataFinal,
    status,
  } = useSearch();

  return useQuery(
    [
      'coletas',
      rawPage || page,
      rawCliente || cliente,
      rawUfRemetente || ufRemetente,
      rawUfDestinatario || ufDestinatario,
      rawMunicipioRemetente || municipioRemetente,
      rawMunicipioDestinatario || municipioDestinatario,
      rawDataInicial || dataInicial,
      rawDataFinal || dataFinal,
      status || '',
      vincularCarga,
  ],
    async () => {
      return getColetasPaginated({
        page: rawPage || page,
        vincularCarga,
        cliente: rawCliente || cliente,
        ufRemetente: rawUfRemetente || ufRemetente,
        ufDestinatario: rawUfDestinatario || ufDestinatario,
        municipioRemetente: rawMunicipioRemetente || municipioRemetente,
        municipioDestinatario: rawMunicipioDestinatario || municipioDestinatario,
        dataInicial: rawDataInicial || dataInicial,
        status: status || '',
        dataFinal: rawDataFinal || dataFinal,
      });
    }
  );
};
