import { useQuery } from 'react-query';
import api from '../../../services/api'
import { useSearch } from '../../hooks'
import {API_CLIENT_PAGE_SIZE} from '../../config';

const getTablesPaginated = async ({ page, cliente }) => {
    let path = `/fracionado/tabelas/?limit=${API_CLIENT_PAGE_SIZE}&`;
    if (page) {
        path = `${path}offset=${(page - 1) * API_CLIENT_PAGE_SIZE}&`;
    }
    if (cliente) {
        path = `${path}cliente=${cliente}&`
    }

    const { data } = await api.get(path);
    return data;
}

export const useClientsTablesPaginated = (rawPage) => {
    const { page, cliente } = useSearch();

    return useQuery(['clients-tables', cliente, rawPage || page], async () => {
        return getTablesPaginated({ page: rawPage || page, cliente });
    });
};
