import { useState, useEffect } from 'react';
import { apiPublica } from './api';

const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const online = await apiPublica.head('/banks/')
        setIsOnline(online.status >= 200 && online.status < 300)
      } catch (error) {
        setIsOnline(false);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isOnline]);

  return { isOnline };
};

export default useNetworkStatus;
