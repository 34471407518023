import { useMutation } from 'react-query';
import api from '../../../services/api';
import { useMessages } from '../../../context/messages-context';

export const useUpdateNfeMutation = () => {
  const { showSuccess, showApiErrors } = useMessages();

  return useMutation(async ({ id, data }) => {
    try {
      const { data: result } = await api.patch(`documentos_fiscais/nfe/${id}/`, data);
      showSuccess("Sucesso!", "NF-e Salva Com Sucesso!");
      return result;
    } catch (err) {
      showApiErrors(err);
      return null;
    }
  });
}
