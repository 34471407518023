import React, { memo, useRef, useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { useHistory } from 'react-router-dom'
import { parseISO, format } from 'date-fns'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import InputSearch from '../../../../components/plataformaAcessada/InputSearch'
import api from "../../../../services/api"

const CadastroSimplificadoMotorista = ({ match }) => {
  const history = useHistory()
  const idMotorista = match.params?.idMotorista
  const desabilitarInputs = match.path === '/acesso/motorista/visualizar-motorista/:idMotorista' ? true : false
  const isEditar = match.path === '/acesso/motorista/editar-motorista/:idMotorista' ? true : false

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
  const tipoUsuario = dadosUsuario.user_type

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/motorista', breadcrumb: 'Motorista' },
    { path: '/acesso/motorista/novo-motorista', breadcrumb: 'Novo Motorista' },
    { path: '/acesso/motorista/cadastro-simplificado', breadcrumb: 'Cadastro Simplificado' },
    { path: `/acesso/motorista/visualizar-motorista`, breadcrumb: null },
    { path: `/acesso/motorista/visualizar-motorista/${idMotorista}`, breadcrumb: 'Visualizar Motorista' },
    { path: `/acesso/motorista/editar-motorista`, breadcrumb: null },
    { path: `/acesso/motorista/editar-motorista/${idMotorista}`, breadcrumb: 'Editar Motorista' },
  ]

  const [nomeTransportadora, setNomeTransportadora] = useState('')
  const [idTransportadora, setIdTransportadora] = useState('')
  const [ufs, setUfs] = useState([])
  const [msgErro, setMsgErro] = useState(false)

  const [checkboxIsParteTransportadora, setCheckboxIsParteTransportadora] = useState(false)
  const [pais, setPais] = useState('Brasil')
  const [cep, setCep] = useState('')
  const [bairro, setBairro] = useState('')
  const [logradouro, setLogradouro] = useState('')
  const [transportadoras, setTransportadoras] = useState([])

  const [checkboxStatus, setCheckboxStatus] = useState(true)
  const [sexo, setSexo] = useState('M')

  const nome = useRef('')
  const telefoneContato = useRef('')
  const email = useRef('')
  const dataNascimento = useRef('')
  const categoria = useRef('')
  const cpf = useRef('')
  const rg = useRef('')
  const rgOrgaoExpedidor = useRef('')
  const nomeMae = useRef('')
  const cnh = useRef('')
  const [cnhUf, setCnhUf] = useState('')
  const cnhSeguranca = useRef('')
  const cnhPrimeira = useRef('')
  const validadeCnh = useRef('')

  const obtemNomeTransportadora = (idTransportadora) => {
    api.get(`transportadora/${idTransportadora}/`)
      .then(function (response) {
        setNomeTransportadora(response.data.razao_social)
        setIdTransportadora(idTransportadora)
        setCheckboxIsParteTransportadora(true)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const removerMascaraCampo = (dado) => {
    return dado.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '').replaceAll('.', '').replaceAll('/', '')
  }

  const formatarDatas = (data) => {
    if (data) {
      return format(parseISO(data), "yyyy-MM-dd")
    }
  }

  const verificaPreenchimentoCampos = () => {
    if (nome.current.value.trim() === "" || telefoneContato.current.value.trim() === ""
      || categoria.current.value.trim() === "" || cpf.current.value.trim() === ""
      || rg.current.value.trim() === "" || cnh.current.value.trim() === ""
      || validadeCnh.current.value.trim() === "" || pais === "" || logradouro.trim() === ""
      || bairro.trim() === "" || cep.trim() === "" || rgOrgaoExpedidor.current.value.trim() === ""
      || nomeMae.current.value.trim() === "" || cnhPrimeira.current.value.trim() === ""

    ) {
      return setMsgErro(true)
    } else {
      return true
    }
  }

  const cadastrarMotorista = async () => {
    if (!verificaPreenchimentoCampos()) {
      return;
    }
    const formData = new FormData()
    // motorista
    formData.append("nome_completo", nome.current.value)
    formData.append("telefone1", removerMascaraCampo(telefoneContato.current.value))
    formData.append("email", email.current.value)
    formData.append("data_nascimento", dataNascimento.current.value)
    formData.append("sexo", sexo)
    formData.append("is_ativo", checkboxStatus)
    formData.append("transportadora", idTransportadora)
    // documentos
    formData.append("documentos.categoria", categoria.current.value)
    formData.append("documentos.cpf", removerMascaraCampo(cpf.current.value))
    formData.append("documentos.rg", removerMascaraCampo(rg.current.value))
    formData.append("documentos.rg_orgao_expedidor", rgOrgaoExpedidor.current.value)
    formData.append("documentos.nome_mae", nomeMae.current.value)
    formData.append("documentos.cnh", removerMascaraCampo(cnh.current.value))
    formData.append("documentos.cnh_uf", cnhUf)
    formData.append("documentos.cnh_seguranca", cnhSeguranca.current.value)
    formData.append("documentos.cnh_primeira", cnhPrimeira.current.value !== "" ? formatarDatas(cnhPrimeira.current.value) : "")
    formData.append("documentos.cnh_validade", validadeCnh.current.value !== "" ? formatarDatas(validadeCnh.current.value) : "")
    // endereco
    formData.append("endereco.pais", pais)
    formData.append("endereco.logradouro", logradouro)
    formData.append("endereco.bairro", bairro)
    formData.append("endereco.cep", cep)
    // enviar
    try {
      await api.post("motoristas/all/", formData)
      history.push("/acesso/motorista")
    } catch (err) {
      console.log(err.response);
    }
  }

  const editarMotorista = async () => {
    if (!verificaPreenchimentoCampos()) {
      return;
    }
    const formData = new FormData()
    // motorista
    formData.append("nome_completo", nome.current.value)
    formData.append("telefone1", removerMascaraCampo(telefoneContato.current.value))
    formData.append("email", email.current.value)
    formData.append("data_nascimento", dataNascimento.current.value)
    formData.append("sexo", sexo)
    formData.append("is_ativo", checkboxStatus)
    formData.append("transportadora", idTransportadora)
    // documentos
    formData.append("documentos.categoria", categoria.current.value)
    formData.append("documentos.cpf", removerMascaraCampo(cpf.current.value))
    formData.append("documentos.rg", removerMascaraCampo(rg.current.value))
    formData.append("documentos.rg_orgao_expedidor", rgOrgaoExpedidor.current.value)
    formData.append("documentos.nome_mae", nomeMae.current.value)
    formData.append("documentos.cnh", removerMascaraCampo(cnh.current.value))
    formData.append("documentos.cnh_uf", cnhUf)
    formData.append("documentos.cnh_seguranca", cnhSeguranca.current.value)
    formData.append("documentos.cnh_primeira", cnhPrimeira.current.value !== "" ? formatarDatas(cnhPrimeira.current.value) : "")
    formData.append("documentos.cnh_validade", validadeCnh.current.value !== "" ? formatarDatas(validadeCnh.current.value) : "")
    // endereco
    formData.append("endereco.pais", pais)
    formData.append("endereco.logradouro", logradouro)
    formData.append("endereco.bairro", bairro)
    formData.append("endereco.cep", cep)
    try {
      await api.put(`motoristas/all/${idMotorista}/`, formData);
      history.push("/acesso/motorista");
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (cep.length === 9) {
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((response) => {
          response.json()
            .then((data) => {
              let dadosEndereco = data

              setBairro(dadosEndereco.bairro)
              setLogradouro(dadosEndereco.logradouro)
            })
        })
        .catch((error) => {
          console.log("ERRO: " + error.message)
        })
    }
  }, [cep])

  useEffect(() => {
    if (pais === 'Brasil') {
      fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        .then(res => {
          return res.json()
        })
        .then(ufs => {
          setUfs(ufs)
        })
    } else {
      setUfs([])
    }
  }, [pais])

  useEffect(() => {
    if (tipoUsuario === "transportadora" || tipoUsuario === "colaborador_transportadora") {
      setIdTransportadora(dadosUsuario.transportadora_id)
    }
  }, [dadosUsuario.transportadora_id, tipoUsuario])

  useEffect(() => {
    api.get(`transportadora/`)
      .then(function (response) {
        setTransportadoras(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (idMotorista) {
      api.get(`motoristas/all/${idMotorista}/`)
        .then(function (response) {
          let dadosMotorista = response.data

          if (dadosMotorista.transportadora !== null) {
            obtemNomeTransportadora(dadosMotorista.transportadora)
          }

          nome.current.value = dadosMotorista.nome_completo
          telefoneContato.current.value = dadosMotorista.telefone1
          email.current.value = dadosMotorista.email
          setSexo(dadosMotorista.sexo)
          dataNascimento.current.value = dadosMotorista.data_nascimento
          categoria.current.value = dadosMotorista.documentos.categoria
          cpf.current.value = dadosMotorista.documentos.cpf
          rg.current.value = dadosMotorista.documentos.rg
          rgOrgaoExpedidor.current.value = dadosMotorista.documentos.rg_orgao_expedidor
          nomeMae.current.value = dadosMotorista.documentos.nome_mae
          cnh.current.value = dadosMotorista.documentos.cnh
          setCnhUf(dadosMotorista.documentos.cnh_uf)
          cnhSeguranca.current.value = dadosMotorista.documentos.cnh_seguranca
          cnhPrimeira.current.value = dadosMotorista.documentos.cnh_primeira
          validadeCnh.current.value = dadosMotorista.documentos.cnh_validade
          setPais(dadosMotorista.endereco.pais)
          setCep(dadosMotorista.endereco.cep)
          setLogradouro(dadosMotorista.endereco.logradouro)
          setBairro(dadosMotorista.endereco.bairro)
          setCheckboxStatus(dadosMotorista.is_ativo)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [idMotorista])

  return (
    <div className="container-cadastro-simplificado-motorista">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="novo-motorista">
        <p className="titulo-pagina">
          {
            desabilitarInputs && !isEditar
              ? "Visualizar Motorista - Cadastro Simplificado"
              : isEditar
                ? "Editar Motorista - Cadastro Simplificado"
                : "Novo Motorista - Cadastro Simplificado"
          }
        </p>
        <p className="subtitulo-pagina">
          {
            desabilitarInputs && !isEditar
              ? "Dados informados no cadastro do motorista"
              : isEditar
                ? "Por favor, informe os dados solicitados para editar o cadastro do motorista"
                : "Por favor, informe os dados solicitados para cadastro de novo motorista"
          }
        </p>

        {(tipoUsuario !== "transportadora" && tipoUsuario !== "colaborador_transportadora") &&
          <div className="categoria">
            <p className="titulo-categoria">Motorista faz parte de alguma transportadora?</p>

            <div className="container-inputs-1col">
              <div className="container-input-checkbox">
                <span
                  className="checkbox"
                  onClick={desabilitarInputs
                    ? () => { }
                    : () => setCheckboxIsParteTransportadora(!checkboxIsParteTransportadora)}
                >
                  <input
                    type="checkbox"
                    checked={checkboxIsParteTransportadora}
                    onChange={() => setCheckboxIsParteTransportadora(!checkboxIsParteTransportadora)}
                    disabled={desabilitarInputs}
                  />
                  <span className="checked"></span>
                  Sim
                </span>
              </div>
            </div>

            {
              checkboxIsParteTransportadora &&
              <InputSearch
                placeholder="Buscar Transportadora (Razão Social CNPJ)"
                value={nomeTransportadora}
                saveValue={(e) => setNomeTransportadora(e)}
                saveId={(e) => setIdTransportadora(e)}
                disabled={desabilitarInputs}
                suggestions={transportadoras}>
              </InputSearch>
            }
          </div>
        }

        <div className="categoria">
          <p className="titulo-categoria">Dados do Motorista</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <input
                required
                type="text"
                ref={nome}
                disabled={desabilitarInputs}
              />
              <span>Nome Completo <i>*</i></span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="(99) 99999-9999"
                maskChar=" "
                ref={telefoneContato}
                disabled={desabilitarInputs}
              ></InputMask>
              <span>Telefone Contato <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={email}
                disabled={desabilitarInputs}
              />
              <span>E-mail</span>
            </div>

            <div className="container-input">
              <input
                required
                type="date"
                ref={dataNascimento}
                disabled={desabilitarInputs}
              />
              <span>Data de Nascimento <i>*</i></span>
            </div>

            <div className="container-input-checkbox">
              <span style={{ marginRight: 20 }} className="checkbox" onClick={desabilitarInputs ? () => { } : () => setSexo('M')} >
                <input
                  type="checkbox"
                  checked={sexo === 'M'}
                  onChange={() => setSexo('M')}
                  disabled={desabilitarInputs}
                />
                <span className="checked"></span>
                Masculino
              </span>
              <span className="checkbox" onClick={desabilitarInputs ? () => { } : () => setSexo('F')} >
                <input
                  type="checkbox"
                  checked={sexo === 'F'}
                  onChange={() => setSexo('F')}
                  disabled={desabilitarInputs}
                />
                <span className="checked"></span>
                Feminino
              </span>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Documentos do Motorista</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <input
                required
                type="text"
                ref={categoria}
                disabled={desabilitarInputs}
              />
              <span>Categoria <i>*</i></span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="999.999.999-99"
                maskChar=" "
                ref={cpf}
                disabled={desabilitarInputs}
              ></InputMask>
              <span>CPF<i>*</i></span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="9999999"
                maskChar=" "
                ref={rg}
                disabled={desabilitarInputs}
              ></InputMask>
              <span>RG <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={rgOrgaoExpedidor}
                disabled={desabilitarInputs}
              />
              <span>Orgão Expedidor do RG <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={nomeMae}
                disabled={desabilitarInputs}
              />
              <span>Nome da Mãe <i>*</i></span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="99999999999"
                maskChar=" "
                ref={cnh}
                disabled={desabilitarInputs}
              ></InputMask>
              <span>CNH<i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="date"
                ref={validadeCnh}
                disabled={desabilitarInputs}
              />
              <span>Validade CNH <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="date"
                ref={cnhPrimeira}
                disabled={desabilitarInputs}
              />
              <span>Primeira CNH <i>*</i></span>
            </div>

            {ufs.length > 0
              ? <div className="container-input">
                <select
                  required
                  value={cnhUf}
                  onChange={(e) => setCnhUf(e.target.value)}
                  disabled={desabilitarInputs}
                >
                  <option value="" disabled></option>
                  {ufs.map((item) => {
                    return (
                      <option key={item.id} value={item.sigla}>{item.sigla}</option>
                    )
                  })}
                </select>
                <span>UF da CNH</span>
              </div>
              : <div className="container-input">
                <input
                  required
                  type="text"
                  value={cnhUf}
                  onChange={(e) => setCnhUf(e.target.value)}
                  disabled={desabilitarInputs}
                />
                <span>UF da CNH</span>
              </div>
            }

            <div className="container-input">
              <input
                required
                type="text"
                ref={cnhSeguranca}
                disabled={desabilitarInputs}
              />
              <span>Código de Segurança da CNH</span>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Endereço</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <select
                required
                value={pais}
                disabled={true}
              >
                <option value="Brasil" disabled>Brasil</option>
              </select>
              <span>País <i>*</i></span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="99999-999"
                maskChar=""
                value={cep}
                onChange={(e) => setCep(e.target.value)}
                disabled={desabilitarInputs}
              ></InputMask>
              <span>CEP <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
                disabled={desabilitarInputs}
              />
              <span>Bairro <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                value={logradouro}
                onChange={(e) => setLogradouro(e.target.value)}
                disabled={desabilitarInputs}
              />
              <span>Logradouro <i>*</i></span>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Status Motorista</p>

          <div className="container-input-checkbox">
            <span className="checkbox" onClick={desabilitarInputs ? () => { } : () => setCheckboxStatus(!checkboxStatus)} >
              <input
                type="checkbox"
                checked={checkboxStatus}
                onChange={() => setCheckboxStatus(!checkboxStatus)}
                disabled={desabilitarInputs}
              />
              <span className="checked"></span>
              Ativo
            </span>
          </div>
        </div>

        {
          !desabilitarInputs &&
          <div className="container-botoes">
            <button
              className="btn-cancelar"
              onClick={() => history.replace("/acesso/motorista")}
            >
              Cancelar
            </button>

            <button
              className="btn-cadastrar"
              onClick={isEditar ? editarMotorista : cadastrarMotorista}
            >
              {isEditar ? "Salvar Motorista" : "Cadastrar Motorista"}
            </button>
          </div>
        }

        {msgErro && <p className="msg-erro">Todos os campos com * devem ser preenchidos!</p>}
      </div>
    </div >
  )
}

export default memo(CadastroSimplificadoMotorista)