import React, { memo, useRef, useEffect } from 'react'
import { parseISO, format } from 'date-fns'
import { saveAs } from 'file-saver';

import './styles.css'

import newDoc from "../../../../../../../../assets/newDoc.png"
import removeDoc from "../../../../../../../../assets/remove.png"
// import pdfIcon from "../../../../../../../../assets/pdf.png"
import xmlIcon from "../../../../../../../../assets/xml.png"

const FormularioNfe = ({ nfe, isNfeSelecionada, nfesVinculadasCte, vincularNfe, desvincularNfe }) => {
  const numero = useRef('')
  const serie = useRef('')
  const emissao = useRef('')
  const valor = useRef('')
  const peso = useRef('')
  const volume = useRef('')
  const embalagem = useRef('')
  const conteudo = useRef('')
  const bcIcm = useRef('')
  const vlIcm = useRef('')
  const icmSt = useRef('')
  const cfop = useRef('')
  const chaveAcessoNfe = useRef('')
  const pedido = useRef('')
  const tipoDocumento = useRef('')
  const comprimento = useRef('')
  const largura = useRef('')
  const altura = useRef('')

  useEffect(() => {
    numero.current.value = nfe.numero
    serie.current.value = nfe.serie
    try {
      emissao.current.value = format(parseISO(nfe.emissao), "dd/MM/yyyy HH:mm")
    } catch {
      emissao.current.value = '';
    }
    embalagem.current.value = nfe.embalagem
    conteudo.current.value = nfe.conteudo
    bcIcm.current.value = nfe.bc_icms
    vlIcm.current.value = nfe.vl_icms
    icmSt.current.value = nfe.st_icms
    cfop.current.value = nfe.cfop
    pedido.current.value = nfe.pedido
    tipoDocumento.current.value = "nfe"
    valor.current.value = nfe.valor || 0
    peso.current.value = nfe.peso || 0
    volume.current.value = nfe.volume || 0
    chaveAcessoNfe.current.value = nfe.chave
    comprimento.current.value = nfe.comprimento || 0
    largura.current.value = nfe.largura || 0
    altura.current.value = nfe.altura || 0
  }, [nfe])

  return (
    <div className={
      !isNfeSelecionada
        ? nfesVinculadasCte.findIndex(nfes => nfes.chave === nfe.chave) !== -1
          ? `formulario-nfe-cte vinculada`
          : `formulario-nfe-cte`
        : `formulario-nfe-cte vinculada`
    }>
      <div className="container-titulo">
        <p className="titulo-formulario">Notas Fiscais</p>

        <div className="container-acoes">
          {
            !isNfeSelecionada
              ? nfesVinculadasCte.findIndex(nfes => nfes.chave === nfe.chave) !== -1
                ? <img src={removeDoc} onClick={() => desvincularNfe(nfe)} alt="documento" />
                : <img src={newDoc} onClick={() => vincularNfe(nfe)} alt="remove-documento" />
              : <></>
          }

          <img src={xmlIcon} alt="xml" onClick={() => saveAs(nfe.arquivo, `NF-e${nfe.numero}.xml`)} />
          {/* <img src={pdfIcon} alt="pdf" onClick={() => saveAs(xmlCte, "CT-e.xml")}/> */}
        </div>

        <div className="status">
          {
            !isNfeSelecionada
              ? nfesVinculadasCte.findIndex(nfes => nfes.chave === nfe.chave) !== -1
                ? <p className='vinculada'>Vinculada ao CT-e</p>
                : <p>Não vinculada ao CT-e</p>
              : <p className='vinculada'>Vinculada ao CT-e</p>
          }
        </div>
      </div>

      <div className="container-inputs-4col">
        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={numero}
            disabled
            defaultValue="Dado"
          />
          <span>Número</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={serie}
            disabled
            defaultValue="Dado"
          />
          <span>Série</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={emissao}
            disabled
            defaultValue="Dado"
          />
          <span>Emissão</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={valor}
            disabled
            defaultValue="Dado"
          />
          <span>Valor</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={peso}
            disabled
            defaultValue="Dado"
          />
          <span>Peso</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={volume}
            disabled
            defaultValue="Dado"
          />
          <span>Volume</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={embalagem}
            disabled
            defaultValue="Dado"
          />
          <span>Embalagem</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={conteudo}
            disabled
            defaultValue="Dado"
          />
          <span>Conteúdo</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={bcIcm}
            disabled
            defaultValue="Dado"
          />
          <span>BC Icm</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={vlIcm}
            disabled
            defaultValue="Dado"
          />
          <span>VL Icm</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={icmSt}
            disabled
            defaultValue="Dado"
          />
          <span>Icm ST</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={cfop}
            disabled
            defaultValue="Dado"
          />
          <span>CFOP</span>
        </div>
      </div>

      <div className="container-inputs-3col">
        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={chaveAcessoNfe}
            disabled
            defaultValue="Dado"
          />
          <span>Chave de Acesso NF-e</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={pedido}
            disabled
            defaultValue="Dado"
          />
          <span>Pedido</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={tipoDocumento}
            disabled
            defaultValue="Dado"
          />
          <span>Tipo Documento</span>
        </div>
      </div>

      <p className="titulo-formulario categoria">Cubagem</p>

      <div className="container-inputs-3col mini">
        <div className="container-input-comum">
          <input
            required
            type="number"
            ref={comprimento}
            disabled
            defaultValue="Dado"
          />
          <span>Comprimento</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="number"
            ref={largura}
            disabled
            defaultValue="Dado"
          />
          <span>Largura</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="number"
            ref={altura}
            disabled
            defaultValue="Dado"
          />
          <span>Altura</span>
        </div>
      </div>
    </div>
  )
}

export default memo(FormularioNfe)