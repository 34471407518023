import { useQuery } from 'react-query';
import api from '../../../services/api'

const getTransportadora = async (id) => {
  let path = `/transportadora/${id}/`;
  const { data } = await api.get(path);
  return data;
}

export const useAuthenticatedTransportadora = () => {
    const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
    
  return useQuery(['auth-transportadora', dadosUsuario.transportadora_id], async () => {
    if (!dadosUsuario.transportadora_id) {
      return null;
    }
    return getTransportadora(dadosUsuario.transportadora_id);
  }, {
    cacheTime: 0,
    retry: false,
    refetchInterval: 0,
    refetchIntervalInBackground: 0,
  });
};
