import React, { memo } from 'react'
import CardEntregue from "./CardEntregue"
import ContainerCards from '../ContainerCards'
import { useCards } from '../CardsContext';
import './styles.css'

const QuadroEntregue = () => {
  const { entregue, setEntregue, fetchEntregues } = useCards();

  return (
    <div className="quadro entregue">
      <div className="titulo">
        <p>ENTREGUE</p>
      </div>

      <div className="detalhes">
        <div className="total" onClick={() => setEntregue((prev) => ({...prev, filtroCarga: ''}))}>
          <p>Total: <span>{entregue.painel.todas}</span></p>
        </div>
        <div className="prazo" onClick={() => setEntregue((prev) => ({...prev, filtroCarga: 'no_prazo'}))}>
          <p>No Prazo: <span>{entregue.painel.no_prazo}</span></p>
        </div>
        <div className="atrasado" onClick={() => setEntregue((prev) => ({...prev, filtroCarga: 'atrasado'}))}>
          <p>Atrasado: <span>{entregue.painel.atrasado}</span></p>
        </div>
        <div className="ocorrencia" onClick={() => setEntregue((prev) => ({...prev, filtroCarga: 'ocorrencia'}))}>
          <p>Ocorrência: <span>{entregue.painel.ocorrencia}</span></p>
        </div>
      </div>

      <ContainerCards
        component={
          <CardEntregue
            moverCarga={(id) => {
              setEntregue((prev) => ({
                ...prev,
                cargas: prev.cargas.filter((carga) => carga.id !== id),
                total: prev.total - 1,
              }))
            }}
          />
        }
        cargas={entregue.cargas}
        total={entregue.total}
        loading={entregue.loading}
        onLoadMore={() => {
          fetchEntregues(entregue.offset + 10);
        }}
      />
    </div>
  )
}

export default memo(QuadroEntregue);
