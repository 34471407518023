import React, { memo } from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { useCreateOrUpdateClientMutation } from '../../../../core/mutations';
import { removeFieldMask } from '../../../../utils/fields';
import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import { Suspense } from '../../../../components/utils';
import { ClienteForm } from './ClienteForm';
import './styles.css'

const initialValues = {
  nome_completo: '',
  telefone: '',
  email: '',
  is_ativo: true,
  cnpj: '',
  endereco: {
    pais: 'Brasil',
    uf: '',
    uf_codigo: '',
    municipio: '',
    municipio_codigo: '',
    logradouro: '',
    bairro: '',
    numero: '',
    cep: '',
    complemento: '',
  }
}

const NovoCliente = ({ match }) => {
  const history = useHistory()
  const id = match.params?.id
  const isReadOnly = match.path === '/acesso/clientes/visualizar/:id' ? true : false
  const isEditar = match.path === '/acesso/clientes/editar/:id' ? true : false
  const createClientMutation = useCreateOrUpdateClientMutation();

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/clientes/gerenciar', breadcrumb: 'Clientes' },
    { path: '/acesso/clientes/novo', breadcrumb: 'Novo Cliente' },
    { path: `/acesso/clientes/visualizar/`, breadcrumb: null },
    { path: `/acesso/clientes/visualizar/${id}`, breadcrumb: 'Visualizar Cliente' },
    { path: `/acesso/clientes/editar/`, breadcrumb: null },
    { path: `/acesso/clientes/editar/${id}`, breadcrumb: 'Editar Cliente' },
  ];

  const handleSubmit = (rawData, actions) => {
    let data = {
      ...rawData,
      cnpj: removeFieldMask(rawData.cnpj),
      telefone: removeFieldMask(rawData.telefone),
    };

    createClientMutation.mutate(
      { data, id },
      {
        onError: (data) => {
          actions.setErrors(data);
        }
      }
    );
  };

  return (
    <div className="container-novo-destinatario">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <div className="novo-destinatario">
            <p className="titulo-pagina">
              {
                isReadOnly && !isEditar
                  ? "Visualizar Cliente"
                  : isEditar
                    ? "Editar Cliente"
                    : "Novo Cliente"
              }
            </p>
            <p className="subtitulo-pagina">
              {
                isReadOnly && !isEditar
                  ? "Dados informados no cadastro do cliente"
                  : isEditar
                    ? "Por favor, informe os dados solicitados para editar o cadastro do cliente"
                    : "Por favor, informe os dados solicitados para cadastro de novo cliente"
              }
            </p>

            <Suspense>
              <ClienteForm id={id} readonly={isReadOnly} />
            </Suspense>

            {!isReadOnly && (
              <div className="container-botoes">
                <button
                  className="btn-cancelar"
                  onClick={() => history.push("/acesso/clientes")}
                  disabled={createClientMutation.isLoading}
                >
                  Cancelar
                </button>

                <button
                  className="btn-cadastrar"
                  type="submit"
                  disabled={createClientMutation.isLoading}
                >
                  {createClientMutation.isLoading ? (
                    <>Salvando...</>
                  ) : (
                    <>
                      {isEditar ? "Salvar Cliente" : "Cadastrar Cliente"}
                    </>
                  )}
                </button>
              </div>
            )}
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default memo(NovoCliente)
