import React, { useState, useEffect } from "react";
import { ImArrowLeft, ImArrowRight } from "react-icons/im";
import { FaInfoCircle } from "react-icons/fa";
import NumberFormat from "react-number-format";
import InputSearchTransportadora from "../../../../../../components/plataformaAcessada/InputSearchDadosCarga/InputSearchTransportadora";
import InputSearchMotoristas from "../../../../../../components/plataformaAcessada/InputSearchDadosCarga/InputSearchMotoristas";
import api from "../../../../../../services/api";
import "./styles.css";

const Etapa3 = ({
  currentStep,
  totalSteps,
  nextStep,
  previousStep,
  idCarga,
  fecharModal,

  onConfirm,
  form,
}) => {
  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
  const tipoUsuario = dadosUsuario.user_type;
  const [msgErro, setMsgErro] = useState({
    visible: false,
    message: ''
  });

  const [grupos, setGrupos] = useState([]);
  const [transportadoras, setTransportadoras] = useState([]);

  const [isLoadingGrupos, setIsLoadingGrupos] = useState(false);
  const [isLoadingTransportadoras, setIsLoadingTransportadoras] = useState(false);

  const verificaPreenchimentoCampos = (dados) => {
    if (
      dados.conta_frete === ("" || undefined) ||
      dados.produto === ("" || undefined) ||
      dados.especie_produto === ("" || undefined) ||
      (!dados.origem_uf.value && !dados.is_fracionada) ||
      (!dados.origem_municipio.value && !dados.is_fracionada) ||
      (dados.pontos_parada.length < 1 && !dados.is_fracionada) ||
      (dados.tipos_veiculo.length < 1 && !dados.is_fracionada) ||
      dados.tipos_carroceria < 1 ||
      dados.data_embarque.trim() === "" ||
      dados.data_descarga.trim() === "" ||
      dados.proposta_inicial === "" ||
      dados.proposta_tipo === "" ||
      dados.adiantamento === "" ||
      dados.data_fechamento.trim() === ""
    ) {
      setMsgErro({
        visible: true,
        message: 'Todos os campos com * em todas as etapas devem ser preenchidos!'
      });
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (dadosUsuario.user_type === "embarcador") {
      let auxResults = [];

      const promises = [
        api
          .get(`transportadora/?embarcador=${dadosUsuario.embarcador_id}`)
          .then(function (response) {
            auxResults = [...auxResults, ...response.data.results];
          })
          .catch(function (error) {
            console.log(error);
          }),
        api
          .get(
            `transportadorafavorita/?embarcador=${dadosUsuario.embarcador_id}`
          )
          .then(function (response) {
            let auxFavoritos = [];

            response.data.results.forEach((item) => {
              if (item.transportadora.embarcador === null) {
                auxFavoritos.push(item.transportadora);
              }
            });

            auxResults = [...auxResults, ...auxFavoritos];
          })
          .catch(function (error) {
            console.log(error);
          }),
      ];

      setIsLoadingTransportadoras(true);
      Promise.all(promises)
        .then(() => {
          setTransportadoras(auxResults);
        })
        .catch(function (e) {
          console.log(e);
        })
        .finally(() => {
          setIsLoadingTransportadoras(false);
        });
    } else {
      setIsLoadingTransportadoras(true);
      api
        .get(`transportadora/`)
        .then(function (response) {
          setTransportadoras(response.data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          setIsLoadingTransportadoras(false);
        });
    }
  }, [dadosUsuario.embarcador_id, dadosUsuario.user_type]);

  useEffect(() => {
    if (!dadosUsuario.transportadora_id) {
      return;
    }

    setIsLoadingGrupos(true);
    api
      .get(`grupos/?transportadora=${dadosUsuario.transportadora_id}`)
      .then(function (response) {
        setGrupos(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setIsLoadingGrupos(false);
      });
  }, [dadosUsuario.transportadora_id]);


  useEffect(() => {
    if (!form.values.data_embarque) {
      return;
    }

    form.setFieldValue('data_fechamento', form.values.data_embarque)
  }, [form.values.data_embarque])

  const handleConfirm = () => {

    if (!verificaPreenchimentoCampos(form.values)) {
      return;
    }

    if (isNaN(form.values.porcentagem_adiantamento) || form.values.porcentagem_adiantamento > 100 || form.values.porcentagem_adiantamento < 1) {
      setMsgErro({
        visible: true,
        message: 'A porcentagem de adiantamento não pode ser maior que 100% ou menor que 1%'
      });
      return;
    }

    onConfirm();
  }

  return (
    <div className="carga-etapa-3">
      {(isLoadingGrupos || isLoadingTransportadoras) && (
        <div className="is-loading-etapa-3">
          Carregando...
        </div>
      )}
      <div className="conteudo">
        <p className="titulo-etapa">
          Por favor, informe os dados abaixo, para cadastrar uma nova carga.
        </p>

        <div className="container-inputs-2col">
          <div className="container-input">
            <NumberFormat
              required
              value={form.values.proposta_inicial}
              onChange={(e) => form.setFieldValue('proposta_inicial', e.target.value)}
              displayType={"number"}
              thousandSeparator="."
              decimalSeparator=","
            // suffix=',00'
            />
            <span>
              <FaInfoCircle className="icone"></FaInfoCircle>
              Proposta Inicial (R$)<i>*</i>
            </span>
          </div>

          <div className="container-input">
            <select
              required
              value={form.values.proposta_tipo}
              onChange={(e) => form.setFieldValue('proposta_tipo', e.target.value)}
            >
              <option value="" disabled></option>
              <option value="total">Cheio</option>
              <option value="tonelada">Por Tonelada</option>
            </select>
            <span>
              <FaInfoCircle className="icone"></FaInfoCircle>
              Tipo da Proposta <i>*</i>
            </span>
          </div>

          <div className="container-input">
            <input
              required
              type="number"
              value={form.values.porcentagem_adiantamento}
              onChange={(e) => form.setFieldValue('porcentagem_adiantamento', e.target.value)}
            />
            <span>
              <FaInfoCircle className="icone"></FaInfoCircle>
              Adiantamento 1% - 100% <i>*</i>
            </span>
          </div>

          <div className="input-radio">
            <div className="label">
              <p>Lotação Veículo?</p>
              <FaInfoCircle className="icone"></FaInfoCircle>
            </div>

            <div className="container-radio">
              <div className="container-input-radio">
                <span className="radio" onClick={() => form.setFieldValue('is_lotacao_veiculo', true)}>
                  <input
                    type="radio"
                    value={true}
                    checked={form.values.is_lotacao_veiculo}
                    onChange={() => form.setFieldValue('is_lotacao_veiculo', true)}
                  />
                  <span className="checked"></span>
                  Sim
                </span>
              </div>

              <div className="container-input-radio">
                <span
                  className="radio"
                  onClick={() => form.setFieldValue('is_lotacao_veiculo', false)}
                >
                  <input
                    type="radio"
                    value={false}
                    checked={!form.values.is_lotacao_veiculo}
                    onChange={() => form.setFieldValue('is_lotacao_veiculo', false)}
                  />
                  <span className="checked"></span>
                  Não
                </span>
              </div>
            </div>
          </div>

          <div className="input-radio">
            <div className="label">
              <p>Motorista livre de carga e descarga?</p>
              <FaInfoCircle className="icone"></FaInfoCircle>
            </div>

            <div className="container-radio">
              <div className="container-input-radio">
                <span
                  className="radio"
                  onClick={() => form.setFieldValue('is_livre_carga_descarga', true)}
                >
                  <input
                    type="radio"
                    value={true}
                    checked={form.values.is_livre_carga_descarga}
                    onChange={() => form.setFieldValue('is_livre_carga_descarga', true)}
                  />
                  <span className="checked"></span>
                  Sim
                </span>
              </div>

              <div className="container-input-radio">
                <span
                  className="radio"
                  onClick={() => form.setFieldValue('is_livre_carga_descarga', false)}
                >
                  <input
                    type="radio"
                    value={false}
                    checked={!form.values.is_livre_carga_descarga}
                    onChange={() => form.setFieldValue('is_livre_carga_descarga', false)}
                  />
                  <span className="checked"></span>
                  Não
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="input-radio">
          <div className="label">
            <p>Esta proposta será pública?</p>
            <FaInfoCircle className="icone"></FaInfoCircle>
          </div>

          <div className="container-radio">
            <div className="container-input-radio">
              <span className="radio" onClick={() => form.setFieldValue('is_publica', true)}>
                <input
                  type="radio"
                  value={true}
                  checked={form.values.is_publica}
                  onChange={() => form.setFieldValue('is_publica', true)}
                />
                <span className="checked"></span>
                Sim
              </span>
            </div>

            <div className="container-input-radio">
              <span className="radio" onClick={() => form.setFieldValue('is_publica', false)}>
                <input
                  type="radio"
                  value={false}
                  checked={!form.values.is_publica}
                  onChange={() => form.setFieldValue('is_publica', false)}
                />
                <span className="checked"></span>
                Não
              </span>
            </div>
          </div>
        </div>

        <div className="container-input">
          <textarea
            required
            rows={4}
            type="text"
            value={form.values.observacoes}
            onChange={(e) => form.setFieldValue('observacoes', e.target.value)}
          />
          <span>
            <FaInfoCircle className="icone"></FaInfoCircle>
            Observações
          </span>
        </div>

        <div className="container-input">
          <input
            min={new Date(new Date().getTime() - 3 * 60 * 60 * 1000)
              .toISOString()
              .slice(0, -8)}
            type="datetime-local"
            value={form.values.data_fechamento}
            onChange={(e) => form.setFieldValue('data_fechamento', e.target.value)}
          />
          <span>
            <FaInfoCircle className="icone"></FaInfoCircle>
            Data Fechamento Proposta <i>*</i>
          </span>
        </div>

        {tipoUsuario !== "transportadora" &&
          tipoUsuario !== "colaborador_transportadora" && (
            <InputSearchTransportadora
              placeholder="Buscar Transportadora (Razão Social)"
              value={form.values.transportadora_nome}
              saveValue={(e) => form.setFieldValue('transportadora_nome', e)}
              saveId={(e) => form.setFieldValue('transportadora', e)}
              suggestions={transportadoras}
            ></InputSearchTransportadora>
          )}

        {!form.values.is_publica && (
          <>
            <div className="input-radio col-1">
              <div className="label">
                <p>Qual a opção desejada?</p>
                <FaInfoCircle className="icone"></FaInfoCircle>
              </div>

              <div className="container-radio">
                <div className="container-input-radio">
                  <span
                    className="radio"
                    onClick={() => form.setFieldValue('escolher_grupo_motorista', false)}
                  >
                    <input
                      type="radio"
                      value={false}
                      checked={!form.values.escolher_grupo_motorista}
                      onChange={() => form.setFieldValue('escolher_grupo_motorista', false)}
                    />
                    <span className="checked"></span>
                    Escolher motorista
                  </span>
                </div>

                <div className="container-input-radio">
                  <span
                    className="radio"
                    onClick={() => form.setFieldValue('escolher_grupo_motorista', true)}
                  >
                    <input
                      type="radio"
                      value={true}
                      checked={form.values.escolher_grupo_motorista}
                      onChange={() => form.setFieldValue('escolher_grupo_motorista', true)}
                    />
                    <span className="checked"></span>
                    Escolher grupo de motoristas
                  </span>
                </div>
              </div>
            </div>

            {form.values.escolher_grupo_motorista ? (
              <div className="container-input">
                <select
                  required
                  value={form.values.grupo}
                  onChange={(e) => form.setFieldValue('grupo', e.target.value)}
                >
                  <option value="" disabled></option>
                  {grupos.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.descricao}
                      </option>
                    );
                  })}
                </select>
                <span>
                  <FaInfoCircle className="icone"></FaInfoCircle>
                  Selecione o grupo de motoristas <i>*</i>
                </span>
              </div>
            ) : (
              <InputSearchMotoristas
                placeholder="Buscar Motorista (ID; Nome)"
                value={form.values.motorista_nome}
                saveValue={(e) => form.setFieldValue('motorista_nome', e)}
                saveId={(e) => form.setFieldValue('motorista', e)}
              ></InputSearchMotoristas>
            )}
          </>
        )}

        <div className="container-botoes">
          <button className="btn-cancelar" onClick={fecharModal}>
            Cancelar
          </button>
          <button
            className="btn-criar"
            onClick={handleConfirm}
          >
            {idCarga ? "Salvar Carga" : "Criar Carga"}
          </button>
        </div>

        {msgErro.visible && (
          <p className="msg-erro">
            {msgErro.message}
          </p>
        )}
      </div>

      <div className="navegadores">
        <ImArrowLeft
          className={`icone ${currentStep !== 1 ? "active" : ""}`}
          onClick={previousStep}
        ></ImArrowLeft>
        <ImArrowRight
          className={`icone ${currentStep !== totalSteps ? "active" : ""}`}
          onClick={nextStep}
        ></ImArrowRight>
      </div>
    </div>
  );
};

export default Etapa3;
