import React from 'react';
import './spinner.scss'

export const Spinner = ({ styles }) => {
  return (
    <div style={styles} className='spinner'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
