import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useSearch = () => {
    const { search } = useLocation();
    
    const searchParams = useMemo(() => {
        return new URLSearchParams(search ||'');
    }, [search]);

    const page = useMemo(() => {
        const pageValue = searchParams.get('page');
        if (!pageValue) {
            return 1;
        }
        const pageNumber = Number(pageValue);
        if (!pageNumber || Number.isNaN(pageNumber)) {
            return 1;
        }
        return pageNumber;
    }, [searchParams]);

    const term = useMemo(() => {
        const termValue = searchParams.get('search');
        if (!termValue) {
            return '';
        }
        return termValue;
    }, [searchParams])

    const status = useMemo(() => {
        const statusValue = searchParams.get('status');
        if (!statusValue) {
            return '';
        }
        return statusValue;
    }, [searchParams])

    const cliente = useMemo(() => {
        const termValue = searchParams.get('search');
        if (!termValue) {
            return '';
        }
        return termValue;
    }, [searchParams])

    const ufRemetente = useMemo(() => {
        return searchParams.get('ufRemetente') || '';
    }, [searchParams]);

    const ufDestinatario = useMemo(() => {
        return searchParams.get('ufDestinatario') || '';
    }, [searchParams]);

    const municipioRemetente = useMemo(() => {
        return searchParams.get('municipioRemetente') || '';
    }, [searchParams]);

    const municipioDestinatario = useMemo(() => {
        return searchParams.get('municipioDestinatario') || '';
    }, [searchParams]);

    const dataInicial = useMemo(() => {
        return searchParams.get('dataInicial') || '';
    }, [searchParams]);
    
    const dataFinal = useMemo(() => {
        return searchParams.get('dataFinal') || '';
    }, [searchParams]);

    return {
        page,
        term,
        cliente,
        ufRemetente,
        ufDestinatario,
        municipioRemetente,
        municipioDestinatario,
        dataInicial,
        dataFinal,
        status
    };
};
