import { useRouteMatch } from 'react-router-dom'
import { useFormikContext } from 'formik';
import { FourCols, Input, Radio, TwoCols } from '../../../../../../components/forms';
import { HStack } from '../../../../../../components/utils';
import { TabelaPesoFaixas } from './TabelaPesoFaixas';

export const TabelaPeso = () => {
  const match = useRouteMatch();
  const isReadOnly = match.path === '/acesso/clientes/tabelas/visualizar/:id' ? true : false;
  const { values } = useFormikContext();

  return (
    <>
      <TwoCols>
        <HStack gap="20px">
          <Radio
            name="peso.tipo"
            id="peso_tipo_rs"
            value="PESO"
            label="R$/Peso"
            disabled={isReadOnly}
          />

          <Radio
            name="peso.tipo"
            id="peso_tipo_faixa"
            value="FAIXA_PESO"
            label="R$/Faixa de Peso"
            disabled={isReadOnly}
          />
        </HStack>
      </TwoCols>

      {values.peso.tipo === 'PESO' && (
        <FourCols>
          <Input
            name="peso.valor"
            disabled={isReadOnly}
            label="R$ por KG"
          />
        </FourCols>
      )}
      {values.peso.tipo === 'FAIXA_PESO' && (
        <>
          <div style={{ overflowX: 'auto' }}>
            <TabelaPesoFaixas />
          </div>

          <FourCols>
            <Input
              disabled={isReadOnly}
              name="peso.excedente"
              label="Excedente"
            />
          </FourCols>
        </>
      )}
    </>
  )
};
