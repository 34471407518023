function capitalizeFirstLetter(string) {
  if (string === "uf" || string === "rntrc") {
    return string.toUpperCase();
  }
  if (string === "rodoviario") {
    return "Modal Rodoviário";
  }
  if (string === "detail") {
    return "Detalhes";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getFieldNameFromKey(key) {
  return key
    .split(".")
    .map((item) => item.split("_").map(capitalizeFirstLetter).join(" "))
    .filter((item) => item.trim() !== "")
    .reduce(
      (current, item) => `${current}${current === "" ? "" : " -> "}${item}`,
      ""
    );
}

export function getDisplayErrors(data, basepath) {
  if (
    data?.detail ===
    "Você não possuí saldo de COINS disponível para executar essa ação."
  ) {
    return [
      {
        title: "Rastro Coins Insuficientes.",
        description:
          "Suas Rastro Coins acabaram. Realize a compra de mais coins!",
      },
    ];
  }

  if (typeof data === "string") {
    return [
      {
        title: "Houve um erro desconhecido.",
        description: "Se persistir, entre em contato com o suporte!",
      },
    ];
  }

  const keys = Object.keys(data);

  let displayErrors = [];

  for (const key of keys) {
    if (Array.isArray(data[key])) {
      if (data[key].length > 0 && typeof data[key][0] === "string") {
        data[key].forEach((item) =>
          displayErrors.push({
            title: getFieldNameFromKey(
              `${basepath || ""}${!!basepath ? "." : ""}${key}`
            ),
            description: item,
          })
        );
      } else {
        data[key].forEach((item) => {
          const newErrors = getDisplayErrors(
            item,
            `${basepath || ""}${!!basepath ? "." : ""}${key}`
          );
          displayErrors.push(...newErrors);
        });
      }
      continue;
    }
    const newErrors = getDisplayErrors(
      data[key],
      `${basepath || ""}${!!basepath ? "." : ""}${key}`
    );
    displayErrors.push(...newErrors);
  }

  return displayErrors;
}
