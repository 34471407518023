import React, { memo, useState, useCallback } from 'react'
import clsx from 'clsx';
import { parseISO, format } from 'date-fns'
import { FiTruck, FiAlertTriangle } from 'react-icons/fi'
import {
  FaRegCalendarAlt,
  FaRegCalendarCheck,
  FaClock,
  FaUserTie,
  FaTruck,
  FaEye,
  FaFileExport
} from 'react-icons/fa'

import ModalPerfilMotoristaCarga from '../../../../ModalPerfilMotoristaCarga'

import './styles.css'

const CardEmbarque = ({ moverCarga, carga }) => {
  const [hover, setHover] = useState(false)

  const [modalPerfilMotoristaCarga, setModalPerfilMotoristaCarga] = useState(false)

  const handleModalPerfilMotoristaCarga = useCallback(() => {
    if (modalPerfilMotoristaCarga) {
      setModalPerfilMotoristaCarga(false)
      document.querySelector("body").style.overflow = "visible"
    } else {
      setModalPerfilMotoristaCarga(true)
      document.querySelector("body").style.overflow = "hidden"
    }
  }, [modalPerfilMotoristaCarga])

  const verificarStatusEmbarque = (carga) => {
    if (carga.status_temporal === "no-prazo" && !carga.checkpoints.chegada_local_embarque) {
      return "total"
    }

    if (carga.checkpoints.chegada_local_embarque && !carga.checkpoints.entrada_carregamento) {
      return "portaria"
    }

    if (carga.checkpoints.entrada_carregamento_data && !carga.checkpoints.carregamento_finalizado) {
      return "carregando"
    }

    if (carga.status_temporal === "atraso" && !carga.checkpoints.chegada_local_embarque) {
      return "sem-embarcar"
    }

    if (carga.checkpoints.carregamento_finalizado && !carga.checkpoints.viagem_liberada) {
      return "aguardando-doc"
    }

    return "total"
  }

  return (
    <div
      className={clsx({
        'card': true,
        'ocorrencia': carga.situacao === "ocorrencia",
        'fracionado': carga.is_fracionada,
      })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="linha">
        <div className="data">
          <p><span>ID: </span>{carga.id}</p>
        </div>

        <div className="pedido">
          <div className="cor-numero">
            <div className={`cor ${verificarStatusEmbarque(carga)}`}></div>
            <p><span>Pedido: </span>{
              carga.pedidos.length > 0
                ? carga.pedidos.map((pedido, index) => {
                  if (index === carga.pedidos.length - 1) {
                    return pedido.numero
                  }
                  return `${pedido.numero}, `
                })
                : "sem número"
            }</p>
          </div>
        </div>

        {carga.situacao === "ocorrencia" && <FiAlertTriangle className="icone-ocorrencia"></FiAlertTriangle>}

        <div className="transportadora">
          <FiTruck className="icone"></FiTruck>
          <p>
            {
              carga.transportadora
                ? carga.transportadora.razao_social.length > 15
                  ? carga.transportadora.razao_social.substr(0, 15) + "..."
                  : carga.transportadora.razao_social
                : ""
            }
          </p>
        </div>
      </div>

      <div className="linha">
        <div className="embarque">
          <FaRegCalendarAlt className="icone"></FaRegCalendarAlt>
          <p><span>Embarque: </span>{format(parseISO(carga.data_embarque), "dd/MM/yyyy")}</p>
        </div>

        <div className="entrega">
          <FaRegCalendarCheck className="icone"></FaRegCalendarCheck>
          <p><span>Entrega: </span>{format(parseISO(carga.data_descarga), "dd/MM/yyyy")}</p>
        </div>

        <div className="cidade">
          <p>
            {
              carga.origem.endereco?.municipio.length > 15
                ? carga.origem.endereco?.municipio.substr(0, 15) + "..."
                : carga.origem.endereco?.municipio
            }
            -{carga.origem.endereco?.uf}
            /
            {
              carga.pontos_parada[carga.pontos_parada.length - 1]?.destino.endereco?.municipio?.length || 0 > 15
                ? carga.pontos_parada[carga.pontos_parada.length - 1]?.destino.endereco?.municipio.substr(0, 15) + "..."
                : carga.pontos_parada[carga.pontos_parada.length - 1]?.destino.endereco?.municipio
            }
            -{carga.pontos_parada[carga.pontos_parada.length - 1]?.destino.endereco?.uf}
          </p>
        </div>
      </div>

      <div className="linha">
        <div className="caminhao">
          <FaTruck className="icone"></FaTruck>
          <p>{carga.motorista_em_viagem?.veiculo?.tipo_veiculo?.tipo} - {carga.motorista_em_viagem?.veiculo?.carrocerias[0]?.tipo?.tipo}</p>
        </div>

        <div className="carga status">
          <FaClock className="icone"></FaClock>

          <p>
            Status:
            <span className={verificarStatusEmbarque(carga)}>
              {` ${verificarStatusEmbarque(carga).replace("-", " ")}`}
            </span>
          </p>
        </div>
      </div>

      <div className="linha-dados-motorista">
        <div className="foto-nome">
          <FaUserTie className="icone"></FaUserTie>
          <div>
            <p className="nome">{carga.motorista_em_viagem?.nome_completo}</p>
            <p className="cpf">{
              carga.motorista_em_viagem?.documentos.cpf.indexOf("-") === -1
                ? carga.motorista_em_viagem?.documentos.cpf.replace('.', "").replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")
                : carga.motorista_em_viagem?.documentos.cpf
            }</p>
          </div>
        </div>

        <div className="veiculo">
          <p>{carga.motorista_em_viagem?.veiculo.placa_cavalo} /</p>
          {
            carga.motorista_em_viagem?.veiculo.carrocerias.map(item => <p key={item.id}>&nbsp;{item.placa}</p>)
          }
        </div>
      </div>

      <div className={`acoes-card embarque ${hover ? "hover" : ""}`}>
        <FaEye className="icone" onClick={handleModalPerfilMotoristaCarga}></FaEye>
        <FaFileExport className="icone" onClick={handleModalPerfilMotoristaCarga}></FaFileExport>
      </div>

      {modalPerfilMotoristaCarga &&
        <ModalPerfilMotoristaCarga
          modalPerfilMotoristaCarga={modalPerfilMotoristaCarga}
          handleModalPerfilMotoristaCarga={handleModalPerfilMotoristaCarga}
          idCarga={carga.id}
          moverCarga={() => moverCarga(carga.id)}
        />
      }
    </div>
  )
}

export default memo(CardEmbarque)