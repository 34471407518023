import { useState } from "react";

export const useSessionState = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === "undefined") {
            return initialValue instanceof Function ? initialValue() : initialValue;
        }
        try {
            const item = window.sessionStorage.getItem(key);
            if (item) {
                return JSON.parse(item);
            }
        } catch {}
        return initialValue instanceof Function ? initialValue() : initialValue;
    });

    const setValue = (value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            if (typeof window !== "undefined") {
                window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch {}
    };

    return [storedValue, setValue];
};
