import React, { memo, useRef, useEffect } from "react";

import { useHistory } from "react-router-dom";

import "./styles.css";

import BreadCrumbs from "../../../components/plataformaAcessada/BreadCrumbs";
import api from "../../../services/api";

const GerenciarFinanceiro = () => {
  const history = useHistory();

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
  const idTransportadora = dadosUsuario.transportadora_id;

  const routes = [
    { path: "/acesso", breadcrumb: "Início" },
    { path: "/acesso/financeiro", breadcrumb: "Financeiro" },
  ];

  // const [msgErro, setMsgErro] = useState(false);

  const contaCorrentePagar = useRef("");
  const categoriaContaPagar = useRef("");
  const diasVencimentoSaldo = useRef("");
  const diasVencimentoOutros = useRef("");
  const diasVencimentoReceber = useRef("");
  const contaCorrenteReceber = useRef("");
  const categoriaContaReceber = useRef("");
  const categoriaContaReceberReembolso = useRef("");

  const embutirIR = useRef("");
  const aliquotaIR = useRef("");
  const embutirCSSL = useRef("");
  const aliquotaCSSL = useRef("");
  const embutirPIS = useRef("");
  const aliquotaPIS = useRef("");
  const embutirCOFINS = useRef("");
  const aliquotaCOFINS = useRef("");
  const embutirINSS = useRef("");
  const aliquotaINSS = useRef("");

  // const verificaPreenchimentoCampos = (dados) => {
  //   if (
  //     dados.omie_conta_corrente_pagar?.trim() === "" ||
  //     dados.omie_categoria_conta_pagar?.trim() === "" ||
  //     dados.omie_vencimento_conta_pagar_saldo?.trim() === "" ||
  //     dados.omie_vencimento_conta_pagar_outros?.trim() === "" ||
  //     dados.omie_conta_corrente_receber?.trim() === "" ||
  //     dados.omie_categoria_conta_receber?.trim() === "" ||
  //     dados.omie_categoria_conta_receber_reembolso?.trim() === "" ||
  //     dados.omie_vencimento_conta_receber?.trim() === ""
  //   ) {
  //     return setMsgErro(true);
  //   } else {
  //     return true;
  //   }
  // };

  const cadastrarFinanceiro = () => {
    let dados = {
      omie_conta_corrente_pagar: contaCorrentePagar.current.value,
      omie_categoria_conta_pagar: categoriaContaPagar.current.value,
      omie_vencimento_conta_pagar_saldo: diasVencimentoSaldo.current.value,
      omie_vencimento_conta_pagar_outros: diasVencimentoOutros.current.value,
      omie_conta_corrente_receber: contaCorrenteReceber.current.value,
      omie_categoria_conta_receber: categoriaContaReceber.current.value,
      omie_categoria_conta_receber_reembolso:
        categoriaContaReceberReembolso.current.value,
      omie_vencimento_conta_receber: diasVencimentoReceber.current.value,
      cte_embutir_ir: embutirIR.current.checked,
      cte_embutir_cssl: embutirCSSL.current.checked,
      cte_embutir_pis: embutirPIS.current.checked,
      cte_embutir_cofins: embutirCOFINS.current.checked,
      cte_embutir_inss: embutirINSS.current.checked,
      cte_aliq_ir: aliquotaIR.current.value,
      cte_aliq_cssl: aliquotaCSSL.current.value,
      cte_aliq_pis: aliquotaPIS.current.value,
      cte_aliq_cofins: aliquotaCOFINS.current.value,
      cte_aliq_inss: aliquotaINSS.current.value,
    };

    // if (verificaPreenchimentoCampos(dados)) {
    api
      .patch(`transportadora/${idTransportadora}/`, dados)
      .then(function () {
        history.goBack();
      })
      .catch(function (e) {
        console.log(e.response.data);
      });
    // }
  };

  useEffect(() => {
    if (idTransportadora) {
      api
        .get(`transportadora/${idTransportadora}/`)
        .then(function (response) {
          let dadosTransportadora = response.data;

          contaCorrentePagar.current.value =
            dadosTransportadora.omie_conta_corrente_pagar;
          categoriaContaPagar.current.value =
            dadosTransportadora.omie_categoria_conta_pagar;
          diasVencimentoSaldo.current.value =
            dadosTransportadora.omie_vencimento_conta_pagar_saldo;
          diasVencimentoOutros.current.value =
            dadosTransportadora.omie_vencimento_conta_pagar_outros;
          diasVencimentoReceber.current.value =
            dadosTransportadora.omie_vencimento_conta_receber;
          contaCorrenteReceber.current.value =
            dadosTransportadora.omie_conta_corrente_receber;
          categoriaContaReceber.current.value =
            dadosTransportadora.omie_categoria_conta_receber;
          categoriaContaReceberReembolso.current.value =
            dadosTransportadora.omie_categoria_conta_receber_reembolso;

          embutirIR.current.checked = dadosTransportadora.cte_embutir_ir;
          embutirCSSL.current.checked = dadosTransportadora.cte_embutir_cssl;
          embutirPIS.current.checked = dadosTransportadora.cte_embutir_pis;
          embutirCOFINS.current.checked = dadosTransportadora.cte_embutir_cofins;
          embutirINSS.current.checked = dadosTransportadora.cte_embutir_inss;

          aliquotaIR.current.value = dadosTransportadora.cte_aliq_ir;
          aliquotaCSSL.current.value = dadosTransportadora.cte_aliq_cssl;
          aliquotaPIS.current.value = dadosTransportadora.cte_aliq_pis;
          aliquotaCOFINS.current.value = dadosTransportadora.cte_aliq_cofins;
          aliquotaINSS.current.value = dadosTransportadora.cte_aliq_inss;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [idTransportadora]);

  return (
    <div className="container-novo-financeiro">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>
        Voltar
      </button>

      <div className="novo-financeiro">
        <p className="titulo-pagina">Editar Financeiro</p>
        <p className="subtitulo-pagina">
          Por favor, informe os dados solicitados para editar o cadastro do
          financeiro
        </p>

        <div className="categoria">
          <p className="titulo-categoria">Dados do Financeiro</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <input required type="text" ref={contaCorrentePagar} />
              <span>Conta Corrente de Contas a Pagar no Omie</span>
            </div>

            <div className="container-input">
              <input required type="text" ref={categoriaContaPagar} />
              <span>Categoria Conta a Pagar no Omie</span>
            </div>

            <div className="container-input">
              <input required type="number" ref={diasVencimentoSaldo} />
              <span>Dias de Vencimento de Contas a Pagar (Saldo) no Omie</span>
            </div>

            <div className="container-input">
              <input required type="number" ref={diasVencimentoOutros} />
              <span>Dias de Vencimento de Contas a Pagar (Outros) no Omie</span>
            </div>

            <div className="container-input">
              <input required type="text" ref={diasVencimentoReceber} />
              <span>Dias de Vencimento de Contas a Receber</span>
            </div>

            <div className="container-input">
              <input required type="text" ref={contaCorrenteReceber} />
              <span>Conta Corrente de Contas a Receber no Omie</span>
            </div>

            <div className="container-input">
              <input required type="text" ref={categoriaContaReceber} />
              <span>Categoria Conta a Receber no Omie</span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={categoriaContaReceberReembolso}
              />
              <span>Categoria Conta a Receber Reembolso</span>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Embutir Impostos Federais no CT-e</p>

          <div className="container-inputs-2col">
            <div className="container-input checkbox">
              <input type="checkbox" id="cte_embutir_ir" ref={embutirIR} />
              <label for="cte_embutir_ir">Embutir IR no CT-e</label>
            </div>
            <div className="container-input">
              <input required type="text" ref={aliquotaIR} />
              <span>Alíquota de IR</span>
            </div>
            <div className="container-input checkbox">
              <input type="checkbox" id="cte_embutir_cssl" ref={embutirCSSL} />
              <label for="cte_embutir_cssl">Embutir CSSL no CT-e</label>
            </div>
            <div className="container-input">
              <input required type="text" ref={aliquotaCSSL} />
              <span>Alíquota de CSSL</span>
            </div>
            <div className="container-input checkbox">
              <input type="checkbox" id="cte_embutir_pis" ref={embutirPIS} />
              <label for="cte_embutir_pis">Embutir PIS no CT-e</label>
            </div>
            <div className="container-input">
              <input required type="text" ref={aliquotaPIS} />
              <span>Alíquota de PIS</span>
            </div>
            <div className="container-input checkbox">
              <input type="checkbox" id="cte_embutir_cofins" ref={embutirCOFINS} />
              <label for="cte_embutir_cofins">Embutir COFINS no CT-e</label>
            </div>
            <div className="container-input">
              <input required type="text" ref={aliquotaCOFINS} />
              <span>Alíquota de COFINS</span>
            </div>
            <div className="container-input checkbox">
              <input type="checkbox" id="cte_embutir_inss" ref={embutirINSS} />
              <label for="cte_embutir_inss">Embutir INSS no CT-e</label>
            </div>
            <div className="container-input">
              <input required type="text" ref={aliquotaINSS} />
              <span>Alíquota de INSS</span>
            </div>
          </div>
        </div>

        <div className="container-botoes">
          <button
            className="btn-cancelar"
            onClick={() => history.push("/acesso/destinatario")}
          >
            Cancelar
          </button>

          <button className="btn-cadastrar" onClick={cadastrarFinanceiro}>
            Salvar Financeiro
          </button>
        </div>
        {/* 
        {msgErro && (
          <p className="msg-erro">
            Todos os campos com * devem ser preenchidos!
          </p>
        )} */}
      </div>
    </div>
  );
};

export default memo(GerenciarFinanceiro);
