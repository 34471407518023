import React, { memo, useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FaUserPlus } from 'react-icons/fa'
import { ImSearch } from 'react-icons/im'
import ReactPaginate from 'react-paginate'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import TabelaListarMotoristas from '../../../../components/plataformaAcessada/TabelaListarMotoristas'
import api from "../../../../services/api"

const GerenciarEmbarcadorMotorista = ({ match }) => {
  const history = useHistory()
  const idEmbarcador = match.params?.idEmbarcador
  const idTransportadora = match.params?.idTransportadora

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/embarcador', breadcrumb: 'Embarcador' },
    { path: '/acesso/embarcador/gerenciar-embarcador', breadcrumb: 'Gerenciar Embarcador' },
    { path: `/acesso/embarcador/gerenciar-embarcador/${idEmbarcador}`, breadcrumb: null },
    { path: `/acesso/embarcador/gerenciar-embarcador/${idEmbarcador}/transportadora/${idTransportadora}`, breadcrumb: null },
    { path: `/acesso/embarcador/gerenciar-embarcador/${idEmbarcador}/transportadora/${idTransportadora}/motorista`, breadcrumb: 'Motorista' }
  ]

  const [embarcador, setEmbarcador] = useState(null)
  const [transportadora, setTransportadora] = useState(null)

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const [search, setSearch] = useState('')
  const [motoristas, setMotoristas] = useState([])
  const [quantidadePaginas, setQuantidadePaginas] = useState(1)
  const [paginaAtual, setPaginaAtual] = useState(0)

  useEffect(() => {
    api.get(`embarcador/${idEmbarcador}/`)
      .then(function (response) {
        setEmbarcador(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })

    api.get(`transportadora/${idTransportadora}/`)
      .then(function (response) {
        setTransportadora(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idEmbarcador, idTransportadora])

  const requisitarMotoristas = useCallback((paginaSelecionada, offSet) => {
    setPaginaAtual(paginaSelecionada)

    api.get(`motorista/?transportadora=${idTransportadora}&offset=${offSet ? offSet : 0}`)
      .then(function (response) {
        setQuantidadePaginas(Math.ceil(response.data.count / 30))
        setMotoristas(response.data.results)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idTransportadora])

  const filtrarItens = () => {
    setQuantidadePaginas(1)
    setMotoristas([])
    setPaginaAtual(0)

    api.post(`motorista/filter`, {
      query: search
    })
      .then(function (response) {
        let listaMotoristas = response.data
        let auxListaMotoristas = []

        listaMotoristas.forEach((item) => {
          if (item.transportadora?.toString() === idTransportadora) {
            auxListaMotoristas.push(item)
          }
        })

        setMotoristas(auxListaMotoristas)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const verificarTeclaSearch = (e) => {
    if (e.keyCode === 13) {
      filtrarItens()
    }
  }

  useEffect(() => {
    if (search === '') {
      forceUpdate()
      requisitarMotoristas()
    }
  }, [requisitarMotoristas, forceUpdate, search])

  return (
    <div className="container-gerenciar-embarcador-motorista">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="gerenciar-embarcador-motorista">
        <div className="container-topo">
          <div>
            <p className="titulo-pagina">Gerenciar Embarcador - Transportadora - Motoristas</p>
          </div>
        </div>

        <div className="container-nomes-embarcador-transportadora">
          <input
            className="input-nome embarcador"
            type="text"
            defaultValue={embarcador?.razao_social}
            disabled={true}
          />

          <input
            className="input-nome transportadora"
            type="text"
            defaultValue={transportadora?.razao_social}
            disabled={true}
          />
        </div>

        <p className="subtitulo-pagina">
          Motoristas vinculados a transportadora. Realize o filtro para busca do motorista desejado
        </p>

        <div className="container-search-adicionar">
          <div className="container-search">
            <div className="input-search">
              <input
                type="text"
                placeholder="Buscar Motorista (Nome)"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={verificarTeclaSearch}
              />
              <ImSearch className="icone"></ImSearch>
            </div>

            <button className="btn-buscar" onClick={filtrarItens}>Buscar</button>
          </div>

          <button className="btn-novo-motorista" onClick={() => history.push("/acesso/motorista/novo-motorista")}>
            <FaUserPlus className="icone"></FaUserPlus>
            Novo Motorista
          </button>
        </div>

        <TabelaListarMotoristas
          motoristas={motoristas}
          requisitarMotoristas={requisitarMotoristas}
        ></TabelaListarMotoristas>

        <div className="container-paginacao-motoristas">
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próxima"}
            breakLabel={"..."}
            pageCount={quantidadePaginas}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={paginaAtual}
            onPageChange={(e) => requisitarMotoristas(e.selected, e.selected * 30)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(GerenciarEmbarcadorMotorista)