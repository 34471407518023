import React, { memo, useRef, useState } from "react";
import Modal from "react-modal";
import Rating from "react-rating";
import { BsStarFill, BsStar } from "react-icons/bs";
import "react-confirm-alert/src/react-confirm-alert.css";

import entrega from "../../../../assets/entrega_finalizada.jpg";
import api from "../../../../services/api";

import "./styles.css";

const ModalAvaliacaoMotorista = ({
  carga,
  modalAvaliacaoMotorista,
  handleModalAvaliacaoMotorista,
}) => {
  const [estrelas, setEstrelas] = useState(0);

  const descricao = useRef("");

  const registrarAvaliacao = () => {
    api
      .post(`cargas_avaliacoes/`, {
        tipo: "motorista",
        avaliacao: estrelas,
        descricao: descricao.current.value,
        carga: carga.id,
        motorista: carga.motorista_em_viagem.id,
      })
      .then(() => {
        handleModalAvaliacaoMotorista();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Modal
      isOpen={modalAvaliacaoMotorista}
      className="modal-avaliacao-motorista"
      overlayClassName="overlay-modal-avaliacao-motorista"
      ariaHideApp={false}
    >
      <div className="avaliacao-motorista">
        <p>
          O motorista {carga.motorista_em_viagem.nome_completo} finalizou a
          entrega com a carga de {carga.id}, do destino
          {carga.pontos_parada.map((item, index) => {
            return index !== 0
              ? ` | ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
              : ` ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`;
          })}
          ,
          {carga.situacao === "ocorrencia"
            ? " com ocorrência"
            : " sem ocorrência"}
          .
        </p>

        <img src={entrega} alt="" />

        <p>Como foi trabalhar com o nome motorista?</p>

        <div className="rating-motorista">
          <Rating
            initialRating={estrelas}
            emptySymbol={<BsStar className="icone vazio"></BsStar>}
            fullSymbol={<BsStarFill className="icone preenchido"></BsStarFill>}
            onChange={(e) => setEstrelas(e)}
          />
        </div>

        <textarea required rows={4} type="text" ref={descricao} />

        <div className="botoes">
          <button onClick={registrarAvaliacao}>Enviar Avaliação</button>
          <p onClick={handleModalAvaliacaoMotorista}>Avaliar Depois</p>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalAvaliacaoMotorista);
