import Modal from "react-modal";
import { IoCloseCircleOutline } from "react-icons/io5";
import './styles.css';
import YouTubePlayer from "./YoutubePlay";

const ModalVideo = ({ titulo, videoId, videoIdYoutube, handleModalVideo, handleModalTutorial, modalVideo }) => {

    const returnModal = () => {
        handleModalVideo(false);
    }

    return (
        <Modal
            isOpen={modalVideo}
            onRequestClose={returnModal}
            className="modal-video-transacoes"
            overlayClassName="overlay-modal-historico-transacoes"
            ariaHideApp={false}
        >
            <div className="modal-video">
                <div className="modal-tutorial-header">
                    <h2>{titulo}</h2>
                    <span>
                        <button onClick={returnModal}>
                            <IoCloseCircleOutline />
                        </button>
                    </span>
                </div>
                <div className="modal-video-body">
                    <button className="btn-voltar-pagina" onClick={() => {
                        handleModalTutorial(true);
                        handleModalVideo(false);
                    }}>Voltar</button>
                    <YouTubePlayer
                        videoId={videoId}
                        videoIdYoutube={videoIdYoutube}
                    />
                </div>
            </div>
        </Modal >
    )
}

export default ModalVideo
