import React, { useEffect } from 'react'
import { Formik, useFormikContext } from 'formik';
import { RiCloseCircleFill } from 'react-icons/ri'
import {
  Input,
  InputValorPeso,
  Radio,
  TwoCols,
  ThreeCols,
  FiveCols,
  Select,
} from '../../../components/forms';
import { confirmDelete } from '../../../components/actions';
import { useUpdateNfeMutation, useDeleteNfeMutation } from '../../../core/mutations'
import { HStack } from '../../../components/utils';
import { Button } from '../../../components/common';

const initialValues = {
  modalidade: 'CIF',
  chaveNfe: '',
  remetente: '',
  remetenteRazaoSocial: '',
  remetenteCidadeUf: '',
  destinatario: '',
  destinatarioRazaoSocial: '',
  destinatarioCidadeUf: '',
  valorNfe: '',
  valorTnCtSelecionado: 'TOTAL',
  valorTnCt: '',
  valorCobrancaSelecionado: 'TOTAL',
  valorCobranca: '',
  tipoPagamento: 'BOLETO',
  observacoes: '',
  peso: '0',
  distancia: '0',
  volume: '0',
  comprimento: '0',
  largura: '0',
  altura: '0',
}

const NfeForm = ({ documento, onRemoved, onClose }) => {
  const { values, setValues, setFieldValue } = useFormikContext();
  const saveMutation = useUpdateNfeMutation();
  const deleteMutation = useDeleteNfeMutation();

  const handleSave = () => {
    if (deleteMutation.isLoading || saveMutation.isLoading) {
      return;
    }

    saveMutation.mutate({
      id: documento.id,
      data: {
        modalidade_frete: values.modalidade,
        valor_cte_tipo: values.valorTnCtSelecionado,
        valor_cte: Number((values.valorTnCt||'0').replaceAll(".", '').replaceAll(",", '.')),
        valor_cobranca_tipo: values.valorCobrancaSelecionado,
        valor_cobranca: Number((values.valorCobranca||'0').replaceAll(".", '').replaceAll(",", '.')),
        tipo_pagamento: values.tipoPagamento,
        observacoes: values.observacoes,
        comprimento: values.comprimento,
        largura: values.largura,
        altura: values.altura,
        volume: values.volume,
        distancia: values.distancia,
      },
    }, {
      onSuccess: () => onClose(),
    });
  }

  const handleDelete = async () => {
    if (deleteMutation.isLoading || saveMutation.isLoading) {
      return;
    }
    const confirm = await confirmDelete({
      title: "Tem certeza?",
      message: `Tem certeza que deseja deletar a NF-e ${documento.numero}? Essa ação não poderá ser desfeita!`
    });
    if (!confirm) {
      return;
    }
    
    deleteMutation.mutate(
      { id: documento.id },
      {
        onSuccess: () => onRemoved(documento.id)
      }
    );
  }

  useEffect(() => {
    setValues({
      modalidade: documento?.modalidade_frete === "CIF" ? "CIF" : "FOB",
      chaveNfe: documento?.chave,
      remetente: documento?.remetente_cnpj,
      remetenteRazaoSocial: documento?.remetente_razao_social,
      remetenteCidadeUf: `${documento?.remetente_endereco.nome_municipio}-${documento?.remetente_endereco.uf}`,
      destinatario: documento?.destinatario_cnpj,
      destinatarioRazaoSocial: documento?.destinatario_razao_social,
      destinatarioCidadeUf: `${documento?.destinatario_endereco.nome_municipio}-${documento?.destinatario_endereco.uf}`,
      valorNfe: documento?.valor,
      valorTnCtSelecionado: documento?.valor_cte_tipo,
      valorTnCt: documento?.valor_cte,
      valorCobrancaSelecionado: documento?.valor_cobranca_tipo,
      valorCobranca: documento?.valor_cobranca,
      tipoPagamento: documento?.tipo_pagamento,
      observacoes: documento?.observacoes || '',
      peso: String(documento?.peso || 0),
      volume: String(documento?.volume || 0),
      comprimento: String(documento?.comprimento || 0),
      largura: String(documento?.largura || 0),
      altura: String(documento?.altura || 0),
    });
  }, [documento, setValues]);

  useEffect(() => {
    const comprimento = parseFloat(values?.comprimento || 0) / 100;
    const largura = parseFloat(values?.largura || 0) / 100;
    const altura = parseFloat(values?.altura || 0) / 100;

    const volume = (comprimento * largura * altura).toFixed(2);
    setFieldValue('volume', volume);
  }, [setFieldValue, values?.comprimento, values?.largura, values?.altura]);

  return (
    <div className="nfe-item">
      <RiCloseCircleFill className="close" onClick={handleDelete} />

      <p className="titulo">NF-e {documento.numero}</p>
      
      <ThreeCols columnGap="30px">
        <Input
          disabled
          white
          borderless
          name="chaveNfe"
          label="Chave de Acesso da NF-e"
        />
        <HStack alignItems="center" gap="20px">
          <Radio
            name="modalidade"
            value="CIF"
            label="CIF"
            id="cif"
          />
          <Radio
            name="modalidade"
            value="FOB"
            label="FOB"
            id="fob"
          />
        </HStack>
      </ThreeCols>
      <ThreeCols columnGap="30px">
        <Input
          disabled
          white
          borderless
          name="remetente"
          label="Remetente"
        />
        <Input
          disabled
          white
          borderless
          name="remetenteRazaoSocial"
          label="Razão Social"
        />
        <Input
          disabled
          white
          borderless
          name="remetenteCidadeUf"
          label="Cidade-UF"
        />
      </ThreeCols>
      <ThreeCols columnGap="30px">
        <Input
          disabled
          white
          borderless
          name="destinatario"
          label="Destinatário"
        />
        <Input
          disabled
          white
          borderless
          name="destinatarioRazaoSocial"
          label="Razão Social"
        />
        <Input
          disabled
          white
          borderless
          name="destinatarioCidadeUf"
          label="Cidade-UF"
        />
      </ThreeCols>
      <ThreeCols columnGap="30px">
        <Input
          disabled
          white
          borderless
          name="valorNfe"
          label="Valor NF-e"
        />
        <InputValorPeso
          white
          borderless
          name="valorTnCt"
          label="Valor CT-e"
          tipoField="valorTnCtSelecionado"
        />
        <InputValorPeso
          white
          borderless
          name="valorCobranca"
          label="Valor Cobrança"
          tipoField="valorCobrancaSelecionado"
        />
      </ThreeCols>
      <TwoCols variant="half-two" columnGap="30px">
        <Select
          white
          borderless
          name="tipoPagamento"
          label="Tipo de Pagamento"
          options={[
            {
              label: "Boleto",
              value: "boleto"
            },
            {
              label: "PIX",
              value: "pix"
            },
            {
              label: "TED/DOC",
              value: "ted-doc"
            },
            {
              label: "Mot. Rec",
              value: "mot-rec"
            },
            {
              label: "Planilha",
              value: "planilha"
            },
          ]}
        />
        <Input
          white
          borderless
          name="observacoes"
          label="Observações"
        />
      </TwoCols>
      <FiveCols columnGap="30px">
        <Input
          white
          borderless
          type="number"
          name="peso"
          label="Peso"
        />
        <Input
          white
          borderless
          type="number"
          name="comprimento"
          label="Comprimento (cm)"
        />
        <Input
          white
          borderless
          type="number"
          name="largura"
          label="Largura (cm)"
        />
        <Input
          white
          borderless
          type="number"
          name="altura"
          label="Altura (cm)"
        />
        <Input
          white
          borderless
          type="number"
          name="volume"
          label="Volume (m3)"
        />
      </FiveCols>
      <FiveCols>
        <Input
          white
          borderless
          type="number"
          name="distancia"
          label="Distância (km)"
        />
      </FiveCols>

      <HStack justifyContent="center" padding="10px 0">
        <Button
          variant="save"
          disabled={saveMutation.isLoading || deleteMutation.isLoading}
          onClick={handleSave}
          small
        >
          {saveMutation.isLoading ? 'Salvando...' : 'Salvar'}
        </Button>
      </HStack>
    </div>
  )
};

export const Nfe = ({
  documento,
  onRemoved,
  onClose,
}) => {
  return (
    <Formik initialValues={initialValues}>
      <NfeForm documento={documento} onRemoved={onRemoved} onClose={onClose} />
    </Formik>
  )
}
