import React from 'react';
import { useField } from 'formik';
import clsx from 'clsx';
import './input.scss';

export const Input = ({ label, small, white, borderless, helpText, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <div className="form-input-container">
      <div
        className={clsx({
          'form-input': true,
          'small': small,
          'no-label': !label,
          'disabled': props.disabled,
          'white': !!white,
          'borderless': !!borderless,
        })}
      >
        <input {...props} {...field} />
        {label && (<span>{label} {props.required && <i>*</i>}</span>)}
      </div>
      {meta.touched && meta.error && (
        <span className="form-input-error">{meta.error}</span>
      )}
      {helpText && !(meta.touched && meta.error) && (
        <span className="form-input-helper">{helpText}</span>
      )}
    </div>
  );
}
