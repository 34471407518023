import React, { useState, useMemo, memo, useEffect, useCallback } from "react";
import { FaSort, FaFileExport } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { parseISO, format } from "date-fns";
import Loader from "react-loader-spinner";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./styles.css";

import avaliacao from "../../../../../assets/avaliacao.png";
import ctes from "../../../../../assets/ctes.png";
import cadastros from "../../../../../assets/cadastros.png";
import valorTotal from "../../../../../assets/valorTotal.png";

import ModalPerfilMotoristaCarga from "../../ModalPerfilMotoristaCarga";

import api from "../../../../../services/api";

const HistoricoMotorista = ({
  filtroCarga,
  filtroTransportadora,
  filtroEmbarque,
  filtroEmbarqueFinal,
  filtroDescarga,
  filtroDescargaFinal,
  filtroTipoCarga,
  filtroOrigem,
  filtroDestino,
  filtroMotorista,
  filtroNfe,
  filtroCte,
  checkboxTodos,
  checkboxPrazo,
  checkboxPendencia,
  checkboxOcorrencia,
  checkboxAtrasado,
}) => {
  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
  const tipoUsuario = dadosUsuario.user_type;

  const [loading, setLoading] = useState(true);
  const [quantidadePaginas, setQuantidadePaginas] = useState(1);
  const [paginaAtual, setPaginaAtual] = useState(0);
  const [cargas, setCargas] = useState([]);
  const [cargasStats, setCargasStats] = useState({});
  const [modalPerfilMotoristaCarga, setModalPerfilMotoristaCarga] =
    useState(false);
  const [indexExpandirLinha, setIndexExpandirLinha] = useState(null);

  const handleModalPerfilMotoristaCarga = useCallback(
    (idCarga) => {
      if (modalPerfilMotoristaCarga) {
        setModalPerfilMotoristaCarga(false);
        document.querySelector("body").style.overflow = "visible";
      } else {
        setModalPerfilMotoristaCarga(idCarga);
        document.querySelector("body").style.overflow = "hidden";
      }
    },
    [modalPerfilMotoristaCarga]
  );

  const exportarPlanilha = async () => {
    const requisitarPlanilha = (tipo) => {
      api
        .post("omie/auth/")
        .then(function (response) {
          let token = response.data.code;

          if (token) {
            window.open(
              `${process.env.REACT_APP_LINK_API}omie/planilha/${tipo}/${token}/?date_start=${filtroEmbarque}&date_end=${filtroDescarga}`
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    if (filtroEmbarque === "" || filtroEmbarque === "") {
      return confirmAlert({
        title: "Parâmetros Vazios!",
        message: `Para exportar as planilhas informe a "Data Embarque" e "Data Descarga" nos filtros da carga.`,
        overlayClassName: "alert-erro-planinhas",
        buttons: [
          {
            label: "Ok",
            className: "botao-confirmar",
          },
        ],
      });
    }

    await requisitarPlanilha("pessoas");
    await requisitarPlanilha("receber");
    await requisitarPlanilha("pagar");
  };

  const requisitarCargas = useCallback(
    (paginaSelecionada, offSet) => {
      setPaginaAtual(paginaSelecionada);

      const formatarDataEmbarque = (formato) => {
        if (filtroEmbarque.length > 9 && formato === "gte") {
          return format(parseISO(filtroEmbarque), "yyyy-MM-dd'T'00:00:00");
        }
        if (filtroEmbarqueFinal.length > 9 && formato !== "gte") {
          return format(parseISO(filtroEmbarqueFinal), "yyyy-MM-dd'T'23:59:59");
        }

        return "";
      };

      const formatarDataDescarga = (formato) => {
        if (filtroDescarga.length > 9 && formato === "gte") {
          return format(parseISO(filtroDescarga), "yyyy-MM-dd'T'00:00:00");
        }
        if (filtroDescargaFinal.length > 9 && formato !== "gte") {
          return format(parseISO(filtroDescargaFinal), "yyyy-MM-dd'T'23:59:59");
        }

        return "";
      };

      console.log(filtroMotorista);

      if (
        tipoUsuario === "transportadora" ||
        tipoUsuario === "colaborador_transportadora"
      ) {
        api
          .get(
            !checkboxTodos
              ? `cargas_historico/?origem=${filtroOrigem}&transportadora=${
                  dadosUsuario.transportadora_id
                }&transportadora__razao_social__icontains=${filtroTransportadora}&status_temporal=${
                  checkboxPrazo || checkboxAtrasado
                }&motorista_em_viagem__nome_completo__icontains=${filtroMotorista}&situacao=${
                  checkboxPendencia || checkboxOcorrencia || ""
                }&data_embarque__gte=${formatarDataEmbarque(
                  "gte"
                )}&data_embarque__lte=${formatarDataEmbarque(
                  "lte"
                )}&data_descarga__gte=${formatarDataDescarga(
                  "gte"
                )}&data_descarga__lte=${formatarDataDescarga(
                  "lte"
                )}&nfes__numero__contains=${filtroNfe}&ctes__numero__contains=${filtroCte}&id__icontains=${filtroCarga}&destino=${filtroDestino}&produto__produto__unaccent__icontains=${filtroTipoCarga}&limit=15&offset=${
                  offSet ? offSet : 0
                }`
              : `cargas_historico/?origem=${filtroOrigem}&transportadora=${
                  dadosUsuario.transportadora_id
                }&transportadora__razao_social__icontains=${filtroTransportadora}&data_embarque__gte=${formatarDataEmbarque(
                  "gte"
                )}&motorista_em_viagem__nome_completo__icontains=${filtroMotorista}&data_embarque__lte=${formatarDataEmbarque(
                  "lte"
                )}&data_descarga__gte=${formatarDataDescarga(
                  "gte"
                )}&data_descarga__lte=${formatarDataDescarga(
                  "lte"
                )}&nfes__numero__contains=${filtroNfe}&ctes__numero__contains=${filtroCte}&id__icontains=${filtroCarga}&destino=${filtroDestino}&produto__produto__unaccent__icontains=${filtroTipoCarga}&limit=15&offset=${
                  offSet ? offSet : 0
                }`
          )
          .then(function (response) {
            setQuantidadePaginas(Math.ceil(response.data.count / 15));
            setCargas(response.data.results);
            setCargasStats(response.data.stats);
            setLoading(false);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else if (
        tipoUsuario === "embarcador" ||
        tipoUsuario === "colaborador_embarcador"
      ) {
        api
          .get(
            !checkboxTodos
              ? `cargas_historico/?origem=${filtroOrigem}&embarcador=${
                  dadosUsuario.embarcador_id
                }&transportadora__razao_social__icontains=${filtroTransportadora}&status_temporal=${
                  checkboxPrazo || checkboxAtrasado
                }&motorista_em_viagem__nome_completo__icontains=${filtroMotorista}&situacao=${
                  checkboxPendencia || checkboxOcorrencia || ""
                }&data_embarque__gte=${formatarDataEmbarque(
                  "gte"
                )}&data_embarque__lte=${formatarDataEmbarque(
                  "lte"
                )}&data_descarga__gte=${formatarDataDescarga(
                  "gte"
                )}&data_descarga__lte=${formatarDataDescarga(
                  "lte"
                )}&nfes__numero__contains=${filtroNfe}&ctes__numero__contains=${filtroCte}&id__icontains=${filtroCarga}&destino=${filtroDestino}&produto__produto__unaccent__icontains=${filtroTipoCarga}&limit=15&offset=${
                  offSet ? offSet : 0
                }`
              : `cargas_historico/?origem=${filtroOrigem}&embarcador=${
                  dadosUsuario.embarcador_id
                }&motorista_em_viagem__nome_completo__icontains=${filtroMotorista}&transportadora__razao_social__icontains=${filtroTransportadora}&data_embarque__gte=${formatarDataEmbarque(
                  "gte"
                )}&data_embarque__lte=${formatarDataEmbarque(
                  "lte"
                )}&data_descarga__gte=${formatarDataDescarga(
                  "gte"
                )}&data_descarga__lte=${formatarDataDescarga(
                  "lte"
                )}&nfes__numero__contains=${filtroNfe}&ctes__numero__contains=${filtroCte}&id__icontains=${filtroCarga}&destino=${filtroDestino}&produto__produto__unaccent__icontains=${filtroTipoCarga}&limit=15&offset=${
                  offSet ? offSet : 0
                }`
          )
          .then(function (response) {
            setQuantidadePaginas(Math.ceil(response.data.count / 15));
            setCargas(response.data.results);
            setLoading(false);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        api
          .get(
            !checkboxTodos
              ? `cargas_historico/?origem=${filtroOrigem}&transportadora__razao_social__icontains=${filtroTransportadora}&status_temporal=${
                  checkboxPrazo || checkboxAtrasado
                }&motorista_em_viagem__nome_completo__icontains=${filtroMotorista}&situacao=${
                  checkboxPendencia || checkboxOcorrencia || ""
                }&data_embarque__gte=${formatarDataEmbarque(
                  "gte"
                )}&data_embarque__lte=${formatarDataEmbarque(
                  "lte"
                )}&data_descarga__gte=${formatarDataDescarga(
                  "gte"
                )}&data_descarga__lte=${formatarDataDescarga(
                  "lte"
                )}&nfes__numero__contains=${filtroNfe}&ctes__numero__contains=${filtroCte}&id__icontains=${filtroCarga}&destino=${filtroDestino}&produto__produto__unaccent__icontains=${filtroTipoCarga}&limit=15&offset=${
                  offSet ? offSet : 0
                }`
              : `cargas_historico/?origem=${filtroOrigem}&transportadora__razao_social__icontains=${filtroTransportadora}&data_embarque__gte=${formatarDataEmbarque(
                  "gte"
                )}&motorista_em_viagem__nome_completo__icontains=${filtroMotorista}&data_embarque__lte=${formatarDataEmbarque(
                  "lte"
                )}&data_descarga__gte=${formatarDataDescarga(
                  "gte"
                )}&data_descarga__lte=${formatarDataDescarga(
                  "lte"
                )}&nfes__numero__contains=${filtroNfe}&ctes__numero__contains=${filtroCte}&id__icontains=${filtroCarga}&destino=${filtroDestino}&produto__produto__unaccent__icontains=${filtroTipoCarga}&limit=15&offset=${
                  offSet ? offSet : 0
                }`
          )
          .then(function (response) {
            setQuantidadePaginas(Math.ceil(response.data.count / 15));
            setCargas(response.data.results);
            setLoading(false);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    [
      tipoUsuario,
      dadosUsuario.transportadora_id,
      dadosUsuario.embarcador_id,
      filtroCarga,
      filtroMotorista,
      filtroTransportadora,
      filtroEmbarque,
      filtroEmbarqueFinal,
      filtroDescarga,
      filtroDescargaFinal,
      filtroTipoCarga,
      filtroOrigem,
      filtroDestino,
      filtroNfe,
      filtroCte,
      checkboxTodos,
      checkboxPrazo,
      checkboxPendencia,
      checkboxOcorrencia,
      checkboxAtrasado,
    ]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      requisitarCargas();
    }, 500);

    return () => {
      clearTimeout(timer);
    }
  }, [
    requisitarCargas,
    filtroCarga,
    filtroTransportadora,
    filtroEmbarque,
    filtroDescarga,
    filtroTipoCarga,
    filtroOrigem,
    filtroDestino,
    checkboxTodos,
    checkboxPrazo,
    checkboxPendencia,
    checkboxOcorrencia,
    checkboxAtrasado,
  ]);

  const TabelaHistorico = ({ cargas }) => {
    const OrdernarLinhasTabela = (itens, config = null) => {
      const [ordenacao, setOrdenacao] = useState(config);

      const itensOrdenados = useMemo(() => {
        let itenDesordenados = [...itens];

        if (ordenacao !== null) {
          itenDesordenados.sort((a, b) => {
            if (a[ordenacao.chave] < b[ordenacao.chave]) {
              return ordenacao.direcao === "ascendente" ? -1 : 1;
            }

            if (a[ordenacao.chave] > b[ordenacao.chave]) {
              return ordenacao.direcao === "ascendente" ? 1 : -1;
            }

            return 0;
          });
        }

        return itenDesordenados;
      }, [itens, ordenacao]);

      const solicitandoOrdenacao = (chave) => {
        let direcao = "ascendente";

        if (
          ordenacao &&
          ordenacao.chave === chave &&
          ordenacao.direcao === "ascendente"
        ) {
          direcao = "decrescente";
        }

        setOrdenacao({ chave, direcao });
      };

      return { itens: itensOrdenados, solicitandoOrdenacao, ordenacao };
    };

    const { itens, solicitandoOrdenacao, ordenacao } =
      OrdernarLinhasTabela(cargas);

    const obtendoClasse = (nome) => {
      if (!ordenacao) {
        return;
      }

      return ordenacao.chave === nome ? ordenacao.direcao : undefined;
    };

    const verificarStatus = (statusTemporal, situacao, isCancelada) => {
      if (isCancelada) {
        return "Cancelada";
      }

      if (situacao === "ocorrencia") {
        return "Com Registro";
      }

      if (situacao === "pendencia") {
        return "Pendencia";
      }

      if (statusTemporal === "atraso") {
        return "Atrasado";
      }

      if (statusTemporal === "no-prazo") {
        return "No Prazo";
      }
    };

    return (
      <div className="container-tabela-historico-cargas">
        <table>
          <thead>
            <tr>
              <th
                onClick={() => solicitandoOrdenacao("statusEntrega")}
                className={obtendoClasse("statusEntrega")}
              >
                <div>
                  STATUS
                  <br />
                  ENTREGA
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("id")}
                className={obtendoClasse("id")}
              >
                <div>
                  ID CARGA
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("numeroPedido")}
                className={obtendoClasse("numeroPedido")}
              >
                <div>
                  NÚMERO
                  <br />
                  PEDIDO
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("origem")}
                className={obtendoClasse("origem")}
              >
                <div>
                  ORIGEM
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("ufOrigem")}
                className={obtendoClasse("ufOrigem")}
              >
                <div>
                  UF
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("destino")}
                className={obtendoClasse("destino")}
              >
                <div>
                  DESTINO
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("ufDestino")}
                className={obtendoClasse("ufDestino")}
              >
                <div>
                  UF
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("enderecoEntrega")}
                className={obtendoClasse("enderecoEntrega")}
              >
                <div>
                  ENDEREÇO
                  <br />
                  ENTREGA
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("empresa")}
                className={obtendoClasse("empresa")}
              >
                <div>
                  EMPRESA
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("tipoProduto")}
                className={obtendoClasse("tipoProduto")}
              >
                <div>
                  TIPO
                  <br />
                  PRODUTO
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("idMotorista")}
                className={obtendoClasse("idMotorista")}
              >
                <div>
                  ID
                  <br />
                  MOTORISTA
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("nomeMotorista")}
                className={obtendoClasse("nomeMotorista")}
              >
                <div>
                  NOME
                  <br />
                  MOTORISTA
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("tipoCaminhao")}
                className={obtendoClasse("tipoCaminhao")}
              >
                <div>
                  TIPO
                  <br />
                  CAMINHÃO
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("placaCavalo")}
                className={obtendoClasse("placaCavalo")}
              >
                <div>
                  PLACA
                  <br />
                  CAVALO
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("distanciaPercorrida")}
                className={obtendoClasse("distanciaPercorrida")}
              >
                <div>
                  DISTÂNCIA
                  <br />
                  PERCORRIA
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("dataEmbarque")}
                className={obtendoClasse("dataEmbarque")}
              >
                <div>
                  DATA
                  <br />
                  EMBARQUE
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("dataEntrega")}
                className={obtendoClasse("dataEntrega")}
              >
                <div>
                  DATA
                  <br />
                  ENTREGA
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("valorInicial")}
                className={obtendoClasse("valorInicial")}
              >
                <div>
                  VALOR INICIAL
                  <br />
                  FRETE
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("freteNegociacao")}
                className={obtendoClasse("freteNegociacao")}
              >
                <div>
                  FRETE
                  <br />
                  NEGOCIAÇÃO
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("variacao")}
                className={obtendoClasse("variacao")}
              >
                <div>
                  VARIAÇÃO
                  <br />%<FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("dataDescarga")}
                className={obtendoClasse("dataDescarga")}
              >
                <div>
                  DATA
                  <br />
                  DESCARGA
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("statusPagamento")}
                className={obtendoClasse("statusPagamento")}
              >
                <div>
                  STATUS
                  <br />
                  PAGAMENTO
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("lucro")}
                className={obtendoClasse("lucro")}
              >
                <div>
                  LUCRO
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("percentualLucro")}
                className={obtendoClasse("percentualLucro")}
              >
                <div>
                  LUCRO %<FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>

              <th
                onClick={() => solicitandoOrdenacao("valorTotal")}
                className={obtendoClasse("valorTotal")}
              >
                <div>
                  VALOR
                  <br />
                  TOTAL
                  <FaSort className="icone-ordenacao"></FaSort>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {itens.map((item, index) => (
              <React.Fragment key={index}>
                <tr
                  key={index}
                  className={
                    index % 2 === 0
                      ? item.motorista_em_viagem
                        ? "modal"
                        : ""
                      : item.motorista_em_viagem
                      ? "modal"
                      : "linha-impar"
                  }
                  onClick={(e) =>
                    item.motorista_em_viagem &&
                    e.target.className !== "expandir-linha"
                      ? handleModalPerfilMotoristaCarga(item.id)
                      : {}
                  }
                >
                  <td className="coluna-status">
                    <div
                      className={`icone-entrega ${item.status_temporal} ${
                        item.situacao
                      } ${item.carga_cancelada && "todos"}`}
                    ></div>
                    {verificarStatus(
                      item.status_temporal,
                      item.situacao,
                      item.carga_cancelada
                    )}
                  </td>
                  <td>{item.id}</td>
                  <td>
                    {item.pedido_carga.length > 0
                      ? item.pedido_carga.map((pedido, index) =>
                          index < item.pedido_carga.length - 1
                            ? `${pedido.numero}, `
                            : ` ${pedido.numero}`
                        )
                      : "Sem número"}
                  </td>
                  <td>{item.origem.endereco.municipio}</td>
                  <td>{item.origem.endereco.uf}</td>
                  <td>
                    {item.pontos_parada.length > 1 ? (
                      <>
                        {`${item.pontos_parada[0]?.destino?.endereco?.municipio}, `}
                        {indexExpandirLinha === index ? (
                          <>
                            {item.pontos_parada.map((pontos, index) =>
                              index !== 0
                                ? index < item.pontos_parada.length - 1
                                  ? `${pontos.destino?.endereco?.municipio}, `
                                  : ` ${pontos.destino?.endereco?.municipio}`
                                : ""
                            )}

                            <p
                              className="expandir-linha"
                              onClick={() => setIndexExpandirLinha(null)}
                            >
                              Ver menos
                            </p>
                          </>
                        ) : (
                          <p
                            className="expandir-linha"
                            onClick={() => setIndexExpandirLinha(index)}
                          >
                            Ver mais
                          </p>
                        )}
                      </>
                    ) : (
                      item.pontos_parada[0]?.destino?.endereco?.municipio
                    )}
                  </td>
                  <td>
                    {item.pontos_parada.length > 1 ? (
                      <>
                        {`${item.pontos_parada[0]?.destino?.endereco?.uf}, `}
                        {indexExpandirLinha === index &&
                          item.pontos_parada.map((pontos, index) =>
                            index !== 0
                              ? index < item.pontos_parada.length - 1
                                ? `${pontos.destino?.endereco?.uf}, `
                                : ` ${pontos.destino?.endereco?.uf}`
                              : ""
                          )}
                      </>
                    ) : (
                      item.pontos_parada[0]?.destino?.endereco?.uf
                    )}
                  </td>
                  <td>
                    {item.pontos_parada.length > 1 ? (
                      <>
                        {`${item.pontos_parada[0]?.destino?.endereco?.logradouro}, ${item.pontos_parada[0]?.destino?.endereco?.numero}, ${item.pontos_parada[0]?.destino?.endereco?.bairro} - ${item.pontos_parada[0]?.destino?.endereco?.complemento} - ${item.pontos_parada[0]?.destino?.endereco?.cep} | `}
                        {indexExpandirLinha === index &&
                          item.pontos_parada.map((pontos, index) =>
                            index !== 0
                              ? index < item.pontos_parada.length - 1
                                ? `${pontos.destino?.endereco?.logradouro}, ${pontos.destino?.endereco?.numero}, ${pontos.destino?.endereco?.bairro} - ${pontos.destino?.endereco?.complemento} - ${pontos.destino?.endereco?.cep} | `
                                : ` ${pontos.destino?.endereco?.logradouro}, ${pontos.destino?.endereco?.numero}, ${pontos.destino?.endereco?.bairro} - ${pontos.destino?.endereco?.complemento} - ${pontos.destino?.endereco?.cep}`
                              : ""
                          )}
                      </>
                    ) : (
                      `${item.pontos_parada[0]?.destino?.endereco?.logradouro}, ${item.pontos_parada[0]?.destino?.endereco?.numero}, ${item.pontos_parada[0]?.destino?.endereco?.bairro} - ${item.pontos_parada[0]?.destino?.endereco?.complemento} - ${item.pontos_parada[0]?.destino?.endereco?.cep}`
                    )}
                  </td>
                  <td>
                    {item?.pontos_parada.map((item) => item.destino.empresa)}
                  </td>
                  <td>{item.produto?.produto}</td>
                  <td>
                    {item.motorista_em_viagem?.id
                      ? item.motorista_em_viagem?.id
                      : ""}
                  </td>
                  <td>
                    {item.motorista_em_viagem?.nome_completo
                      ? item.motorista_em_viagem?.nome_completo
                      : ""}
                  </td>
                  <td>
                    {item.motorista_em_viagem?.veiculo.tipo_veiculo.tipo
                      ? item.motorista_em_viagem?.veiculo.tipo_veiculo.tipo
                      : ""}
                  </td>
                  <td>
                    {item.motorista_em_viagem?.veiculo.placa_cavalo
                      ? item.motorista_em_viagem?.veiculo.placa_cavalo
                      : ""}
                  </td>
                  <td>
                    {item.distancia_percorrida
                      ? Math.round(
                          Number(item.distancia_percorrida / 1000)
                        ).toLocaleString("pt-BR")
                      : 0}
                  </td>
                  <td>{format(parseISO(item.data_embarque), "dd/MM/yyyy")}</td>
                  <td>
                    {item.data_entrega &&
                      format(parseISO(item.data_entrega), "dd/MM/yyyy")}
                  </td>
                  <td>
                    {Number(item.proposta_inicial).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                  <td>
                    {Number(item.valor_negociacao).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                  <td>{item.valor_negociacao_variacao}</td>
                  <td>{format(parseISO(item.data_descarga), "dd/MM/yyyy")}</td>
                  <td>
                    {item.status_pagamento === "a-pagar"
                      ? "Pagamento liberado"
                      : item.status_pagamento
                          .replace("-", " ")
                          .replace(/^\w/, (c) => c.toUpperCase())}
                  </td>
                  <td>{item.lucro?.total}</td>
                  <td>{item.lucro?.percentual}%</td>
                  <td>{item.lucro?.base}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="container-historico">
      <p className="titulo">Histórico de Cargas</p>

      {loading ? (
        <div className="container-loading-historico-cargas">
          <Loader type="TailSpin" color="#001b48" height={50} width={50} />
        </div>
      ) : (
        <>
          <div className="container-quadros-historico-cargas">
            <div className="quadro col-2">
              <img src={avaliacao} alt="" />
              <div>
                <p className="titulo">Cargas Solicitadas</p>
                <p className="qte-solicitadas">{cargasStats.cargas || 0}</p>

                <p className="titulo">Cargas Entregues</p>
                <p className="qte-entregues">
                  {cargasStats.cargas_entregues || 0}
                </p>
              </div>
            </div>

            <div className="quadro ctes">
              <img src={ctes} alt="" />
              <p className="titulo">
                Ct'es Emitidos: <span>{cargasStats.ctes || 0}</span>
              </p>
            </div>

            <div className="quadro frete">
              <p className="titulo">Valor Total de Frete Negociado</p>
              <div>
                <img src={valorTotal} alt="" />
                <p>
                  {Number(cargasStats.total_negociado || 0).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </p>
              </div>
            </div>

            <div className="quadro frete">
              <p className="titulo">Valor Total de CT-e's</p>
              <div>
                <img src={valorTotal} alt="" />
                <p>
                  {Number(cargasStats.total || 0).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </p>
              </div>
            </div>

            <div className="quadro col-2 cadastros">
              <img src={cadastros} alt="" />
              <div>
                <p className="titulo">Consultas</p>
                <p className="numero">
                  {cargasStats.consultas || 0}
                </p>

                <p className="titulo">Pesquisas</p>
                <p className="numero">
                  {cargasStats.pesquisas || 0}
                </p>
              </div>
            </div>

            <button className="exportar" onClick={exportarPlanilha}>
              <FaFileExport className="icone"></FaFileExport>
              <p>Exportar</p>
            </button>
          </div>

          <TabelaHistorico cargas={cargas} />

          <div className="container-paginacao-historico">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={quantidadePaginas}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              forcePage={paginaAtual}
              onPageChange={(e) =>
                requisitarCargas(e.selected, e.selected * 15)
              }
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>

          {modalPerfilMotoristaCarga && (
            <ModalPerfilMotoristaCarga
              modalPerfilMotoristaCarga={modalPerfilMotoristaCarga && true}
              handleModalPerfilMotoristaCarga={handleModalPerfilMotoristaCarga}
              idCarga={modalPerfilMotoristaCarga}
            ></ModalPerfilMotoristaCarga>
          )}
        </>
      )}
    </div>
  );
};

export default memo(HistoricoMotorista);
