import ReactModal from "react-modal";
import clsx from 'clsx';
import { RiCloseCircleFill } from 'react-icons/ri';
import './modal-base.scss';

export const Modal = ({ isOpen, onClose, title, className, children, large, zIndex }) => {
  
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={clsx({
        "rastro-modal": true,
        "large": !!large,
        [className]: true,
      })}
      overlayClassName="overlay-rastro-modal"
      ariaHideApp={false}
      style={{
        overlay: { zIndex }
      }}
    >
      <button className="close-button" onClick={onClose}>
        <RiCloseCircleFill />
      </button>

      <p className="title">{title}</p>

      {children}
    </ReactModal>
  )
}
