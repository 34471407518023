import { useMutation } from 'react-query';
import api from '../../../services/api';
import { useMessages } from '../../../context/messages-context';

export const useDeleteCteMutation = () => {
  const { showSuccess, showApiErrors } = useMessages();

  return useMutation(async ({ id }) => {
    try {
      await api.delete(`documentos_fiscais/cte/${id}/`);
      showSuccess("Sucesso!", "CT-e Removido com Sucesso!");
    } catch (err) {
      showApiErrors(err);
      return null;
    }
  });
}
