const NavWizard = ({ totalSteps, currentStep, goToStep }) => {
  const dots = []

  for (let i = 1; i <= totalSteps; i += 1) {
    const isActive = currentStep === i
    dots.push((
      <div
        key={`step-${i}`}
        className={`dot ${isActive || i < currentStep ? "active " : ''}`}
        onClick={() => goToStep(i)}
      >
        <p>Etapa <br />{i}</p>
      </div>
    ))
  }

  return (
    <div className="container-nav">
      <div className="linhas">
        <hr className={`primeira ${currentStep > 1 ? "active" : ""}`} />
        <hr className={`segunda ${currentStep > 2 ? "active" : ""}`} />
      </div>

      <div className="nav-wizard">{dots}</div>
    </div>
  )
}

export default NavWizard;
