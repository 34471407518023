import { GrAttachment } from 'react-icons/gr';
import { useFormikContext } from 'formik'

export const UploadButton = ({ onUploadFiles }) => {
  const { values } = useFormikContext();
  const isNfe = values.tipo === 'nfe';
  const isCte = values.tipo === 'cte';

  if (!isNfe && !isCte) {
    return null;
  }

  return (
    <div className="btn-upload">
      <label htmlFor="arquivo">
        <p>Anexar {isNfe ? "NF-e" : "CT-e"}</p>
        <GrAttachment className="icone"></GrAttachment>
      </label>

      <input
        type="file"
        name="arquivo"
        accept=".xml"
        onChange={(e) => onUploadFiles(Array.from(e.target.files), isNfe)}
        multiple
      />
    </div>
  )
};
