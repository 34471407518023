import React from 'react';
import { Spinner } from '../spinner';
import './loading-box.scss';

export const LoadingBox = () => {
  return (
    <div className="loading-box">
      <Spinner />
    </div>
  )
};
