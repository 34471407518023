import axios from "axios"

export const apiPublica = axios.create({
  baseURL: process.env.REACT_APP_LINK_API
})

const apiPrivada = axios.create({
  baseURL: process.env.REACT_APP_LINK_API
})

const obterTokenLocalStorage = async (config) => {
  try {
    const tokenLocalStorage = localStorage.getItem("@RASTRO:TOKEN")

    if (tokenLocalStorage !== null) {
      config.headers.Authorization = `Bearer ${tokenLocalStorage}`
      return config
    }
  } catch (e) {
    console.log("ERRO AO OBTER TOKEN DE USUÁRIO", e)

    return config
  }
}

apiPrivada.interceptors.request.use(config => {
  return obterTokenLocalStorage(config)
})

apiPrivada.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const refreshTokenLocal = localStorage.getItem("@RASTRO:REFRESH_TOKEN")

    if (error.response.status === 401 && refreshTokenLocal) {
      try {
        const response = await apiPublica.post("/auth/token/refresh/", {
          refresh_token: refreshTokenLocal,
        });

        localStorage.setItem("@RASTRO:TOKEN", response.data.access_token)
      } catch {
        localStorage.removeItem('@RASTRO:TOKEN');
        localStorage.removeItem('@RASTRO:REFRESH_TOKEN');
        window.location.pathname = '/';
        return Promise.reject(error);
      }

      return apiPrivada.request(error.config)
    }
    return Promise.reject(error)
  }
)

export default apiPrivada