import React from 'react';
import { Link, useHistory } from 'react-router-dom'
import { FaEye, FaTrashAlt, FaEdit } from 'react-icons/fa'
import { confirmDelete } from '../../../../components/actions';
import { Button } from '../../../../components/common';
import { useDeleteClientMutation } from '../../../../core/mutations'

export const ClienteRow = ({ cliente }) => {
  const deleteClientMutation = useDeleteClientMutation();
  const history = useHistory();

  const handleDeleteDestinatario = async () => {
    const title = "Remover Cliente";
    const message = `Deseja realmente remover o cliente "${cliente.nome_completo}" do sistema?`;

    const confirm = await confirmDelete({ title, message });
    if (!confirm) {
      return;
    }

    deleteClientMutation.mutate({ id: cliente.id });
  }

  const handleGoToTables = () => {
    history.push({
      pathname: '/acesso/clientes/tabelas',
      search: `?search=${cliente.id}`
    })
  }

  return (
    <>
      <tr className="divisoria-linhas-tabela"></tr>
      <tr>
        <td>{cliente.cnpj}</td>
        <td>{cliente.nome_completo}</td>

        <td className="coluna-acoes">
          {deleteClientMutation.isLoading ? (
            <>Deletando...</>
          ) : (
            <>
              <Link to={`/acesso/clientes/visualizar/${cliente.id}`}>
                <FaEye className="icone"></FaEye>
              </Link>
              <Link to={`/acesso/clientes/editar/${cliente.id}`}>
                <FaEdit className="icone"></FaEdit>
              </Link>
              <FaTrashAlt className="icone" onClick={handleDeleteDestinatario}></FaTrashAlt>
            </>
          )}
        </td>
        <td>
          <Button variant="smallAction" onClick={handleGoToTables}>
            Ver Tabelas
          </Button>
        </td>
      </tr>
    </>
  );
};
