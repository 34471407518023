import { useClientsTablesPaginated } from '../../core/queries';
import { Pagination } from '../../components/layout/pagination';
import { API_CLIENT_PAGE_SIZE } from '../../core/config';
import { Linha } from './linha';

export const Tabelas = ({ page, setPage, onConfirmCopy }) => {
  const { data } = useClientsTablesPaginated(page);

  console.log(data);

  return (
    <div className="tabelas-grid">
      {!data.results.length && (
        <div className="empty">
          Não existem tabelas para copiar!
        </div>
      )}
      {data.results.map((item) => (
        <Linha key={item.id} tabela={item} onConfirmCopy={onConfirmCopy} />
      ))}

      <div>
        <Pagination
          count={data?.count || 0}
          size={API_CLIENT_PAGE_SIZE}
          router={false}
          page={page}
          onChangePage={setPage}
        />
      </div>
    </div>
  );
};
