import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Modal } from "../modal-base";
import { HStack, Suspense } from "../../components/utils";
import { ModalSelecionarColetaTabela } from './modal-selecionar-coleta-tabela';
import { Button } from '../../components/common';
import { InputFiltro } from '../../components/layout';
import { FourCols } from '../../components/forms';

export const ModalSelecionarColeta = ({ isOpen, onClose, onConfirm }) => {
  const [cliente, setCliente] = useState('');
  const [ufRemetente, setUfRemetente] = useState('');
  const [municipioRemetente, setMunicipioRemetente] = useState('');
  const [ufDestinatario, setUfDestinatario] = useState('');
  const [municipioDestinatario, setMunicipioDestinatario] = useState('');
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');

  const handleSubmit = (data) => {
    const confirmData = Object
      .keys(data)
      .filter((item) => data[item].active)
      .reduce((prev, item) => ({
        ...prev,
        [item]: data[item],
      }), {});
    
      onConfirm(confirmData);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Selecionar Coleta"
      zIndex={10005}
      large
    >
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        <Form>
          <HStack padding="20px 0">
            <FourCols gap="20px" columnGap="20px" width='100%'>
              <InputFiltro
                placeholder="Cliente"
                value={cliente}
                setValue={setCliente}
              />
              <InputFiltro
                placeholder="UF do Remetente"
                value={ufRemetente}
                setValue={setUfRemetente}
              />
              <InputFiltro
                placeholder="Município do Remetente"
                value={municipioRemetente}
                setValue={setMunicipioRemetente}
              />
              <InputFiltro
                placeholder="UF do Destinatário"
                value={ufDestinatario}
                setValue={setUfDestinatario}
              />
              <InputFiltro
                placeholder="Município do Destinatário"
                value={municipioDestinatario}
                setValue={setMunicipioDestinatario}
              />
              <InputFiltro
                placeholder="Data de Solicitação (De)"
                type="date"
                value={dataInicial}
                setValue={setDataInicial}
              />
              <InputFiltro
                placeholder="Data de Solicitação (Até)"
                type="date"
                value={dataFinal}
                setValue={setDataFinal}
              />
            </FourCols>
          </HStack>

          <Suspense>
            <ModalSelecionarColetaTabela
              cliente={cliente}
              ufRemetente={ufRemetente}
              ufDestinatario={ufDestinatario}
              municipioRemetente={municipioRemetente}
              municipioDestinatario={municipioDestinatario}
              dataInicial={dataInicial}
              dataFinal={dataFinal}
            />

            <HStack alignItems="center" justifyContent="center" padding="15px 0">
              <Button variant="save" type="submit">Adicionar</Button>
            </HStack>
          </Suspense>
        </Form>
      </Formik>
    </Modal>
  )
};
