import React, { memo, useRef, useEffect, useState } from 'react'
import { RiCloseCircleFill } from 'react-icons/ri'
import { confirmAlert } from 'react-confirm-alert'
import NumberFormat from 'react-number-format'
import 'react-confirm-alert/src/react-confirm-alert.css'

import './styles.css'

import api from "../../../../../../../../services/api"

const Nfe = ({ documento, removerDocumento, isDocumentoUnico, handleModalNovoDocumento, requisitarDocumentos }) => {
  const [isCif, setIsCif] = useState(true)

  const chaveNfe = useRef('')
  const remetente = useRef('')
  const razaoSocialRemetente = useRef('')
  const cidadeUfRemetente = useRef('')
  const destinatario = useRef('')
  const razaoSocialDestinatario = useRef('')
  const cidadeUfDestinatario = useRef('')
  // const cte = useRef('')
  // const nfe = useRef('dado')
  // const cliente = useRef('')
  const valorNfe = useRef('')
  const valorTnCtSelecionado = useRef('')
  const valorTnCt = useRef('')
  const valorCobrancaSelecionado = useRef('')
  const valorCobranca = useRef('')
  const tipoPagamento = useRef('')
  const observacoes = useRef('')
  const peso = useRef('')
  const [volume, setVolume] = useState('');
  const [comprimento, setComprimento] = useState('');
  const [largura, setLargura] = useState('');
  const [altura, setAltura] = useState('');

  const salvarAlteracoes = () => {
    const dados = {
      modalidade_frete: isCif ? "CIF" : "FOB",
      valor_cte_tipo: valorTnCtSelecionado.current.value,
      valor_cte: Number(valorTnCt.current.state.value.replaceAll(".", '').replaceAll(",", '.')),
      valor_cobranca_tipo: valorCobrancaSelecionado.current.value,
      valor_cobranca: Number(valorCobranca.current.state.value.replaceAll(".", '').replaceAll(",", '.')),
      tipo_pagamento: tipoPagamento.current.value,
      observacoes: observacoes.current.value,
      comprimento: comprimento,
      largura: largura,
      altura: altura,
      volume: volume,
    }

    api.patch(`documentos_fiscais/nfe/${documento.id}/`, dados)
      .then(function () {
        confirmAlert({
          title: "Sucesso",
          message: "Dados salvos com sucesso!",
          overlayClassName: "alert-sucesso-nfe",
          buttons: [
            {
              label: "Ok",
              onClick: isDocumentoUnico
                ? () => {
                  handleModalNovoDocumento();
                  requisitarDocumentos()
                }
                : () => requisitarDocumentos()
            }
          ]
        })
      })
      .catch(function (error) {
        console.log(error.response.data)
      })
  }

  useEffect(() => {
    setIsCif(documento?.modalidade_frete === "CIF" ? true : false)
    chaveNfe.current.value = documento?.chave
    remetente.current.value = documento?.remetente_cnpj
    razaoSocialRemetente.current.value = documento?.remetente_razao_social
    cidadeUfRemetente.current.value = `${documento?.remetente_endereco.nome_municipio}-${documento?.remetente_endereco.uf}`
    destinatario.current.value = documento?.destinatario_cnpj
    razaoSocialDestinatario.current.value = documento?.destinatario_razao_social
    cidadeUfDestinatario.current.value = `${documento?.destinatario_endereco.nome_municipio}-${documento?.destinatario_endereco.uf}`
    valorNfe.current.value = documento?.valor
    valorTnCtSelecionado.current.value = documento?.valor_cte_tipo
    valorTnCt.current.value = documento?.valor_cte
    valorCobrancaSelecionado.current.value = documento?.valor_cobranca_tipo
    valorCobranca.current.value = documento?.valor_cobranca
    tipoPagamento.current.value = documento?.tipo_pagamento
    observacoes.current.value = documento?.observacoes
    peso.current.value = documento?.peso || 0
    setVolume(documento?.volume || 0);
    setComprimento(documento?.comprimento || 0)
    setLargura(documento?.largura || 0)
    setAltura(documento?.altura || 0);
  }, [documento])

  useEffect(() => {
    const comp = parseFloat(comprimento || 0) / 100;
    const larg = parseFloat(largura || 0) / 100;
    const alt = parseFloat(altura || 0) / 100;

    const volume = (comp * larg * alt).toFixed(2);
    setVolume(volume || '0')
  }, [largura, altura, comprimento]);

  return (
    <div className="formulario-nfe">
      <RiCloseCircleFill className="icone-fechar" onClick={() => removerDocumento(documento.id)}></RiCloseCircleFill>

      <p className="titulo">NF-e - Número</p>

      <div className="container-inputs-3col">
        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={chaveNfe}
            disabled
            defaultValue={"Dado"}
          />
          <span>Chave de Acesso da NF-e</span>
        </div>

        <div className="input-radio-cif-fob">
          <div className="container-input-radio">
            <span className="radio" onClick={() => setIsCif(true)} >
              <input
                type="radio"
                value={true}
                checked={isCif === true}
                onChange={() => setIsCif(true)}
              />
              <span className="checked"></span>
              CIF
            </span>
          </div>

          <div className="container-input-radio">
            <span className="radio" onClick={() => setIsCif(false)} >
              <input
                type="radio"
                value={false}
                checked={isCif === false}
                onChange={() => setIsCif(false)}
              />
              <span className="checked"></span>
              FOB
            </span>
          </div>
        </div>
      </div>

      <div className="container-inputs-3col">
        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={remetente}
            disabled
            defaultValue={"Dado"}
          />
          <span>Remetente</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={razaoSocialRemetente}
            disabled
            defaultValue={"Dado"}
          />
          <span>Razão Social</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={cidadeUfRemetente}
            disabled
            defaultValue={"Dado"}
          />
          <span>Cidade-UF</span>
        </div>
      </div>

      <div className="container-inputs-3col">
        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={destinatario}
            disabled
            defaultValue={"Dado"}
          />
          <span>Destinatário</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={razaoSocialDestinatario}
            disabled
            defaultValue={"Dado"}
          />
          <span>Razão Social</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={cidadeUfDestinatario}
            disabled
            defaultValue={"Dado"}
          />
          <span>Cidade - UF</span>
        </div>
      </div>

      <div className="container-inputs-3col">
        <div className="container-input-select disabled">
          <select disabled>
            <option value=''>Valor NF-e</option>
          </select>

          <input
            required
            type="number"
            ref={valorNfe}
            disabled
            defaultValue={"Dado"}
          />
        </div>

        <div className="container-input-select">
          <select ref={valorTnCtSelecionado}>
            <option value='tonelada'>Valor TN CT-e</option>
            <option value='total'>Valor Total CT-e</option>
          </select>

          <NumberFormat
            required
            ref={valorTnCt}
            displayType={'number'}
            thousandSeparator="."
            decimalSeparator=","
          />
        </div>

        <div className="container-input-select">
          <select ref={valorCobrancaSelecionado}>
            <option value='tonelada'>Valor Cobrança TN</option>
            <option value='total'>Valor Cobrança Total</option>
          </select>

          <NumberFormat
            required
            ref={valorCobranca}
            displayType={'number'}
            thousandSeparator="."
            decimalSeparator=","
          />
        </div>
      </div>

      <div className="container-inputs-2col">
        <div className="container-input-comum">
          <select ref={tipoPagamento}>
            <option value=''>Tipo de Pagamento</option>
            <option value='boleto'>Boleto</option>
            <option value='pix'>PIX</option>
            <option value='ted-doc'>TED/DOC</option>
            <option value='mot-rec'>Mot. Rec</option>
            <option value='planilha'>Planilha</option>
          </select>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={observacoes}
          />
          <span>Observações</span>
        </div>
      </div>

      <div className="container-inputs-5col">
        <div className="container-input-comum">
          <input
            required
            type="number"
            ref={peso}
            disabled
            defaultValue={"Dado"}
          />
          <span>Peso</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="number"
            value={comprimento}
            onChange={(e) => setComprimento(e.target.value)}
          />
          <span>Comprimento (cm)</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="number"
            value={largura}
            onChange={(e) => setLargura(e.target.value)}
          />
          <span>Largura (cm)</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="number"
            value={altura}
            onChange={(e) => setAltura(e.target.value)}
          />
          <span>Altura (cm)</span>
        </div>
        
        <div className="container-input-comum">
          <input
            required
            type="number"
            value={volume}
            onChange={(e) => setVolume(e.target.value)}
          />
          <span>Volume (m3)</span>
        </div>
      </div>

      <button
        className="btn-salvar"
        onClick={salvarAlteracoes}
      >
        Salvar
      </button>
    </div>
  )
}

export default memo(Nfe)