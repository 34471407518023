import React, { memo, useRef, useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import './styles.css'

import BreadCrumbs from '../../../../../components/plataformaAcessada/BreadCrumbs'
import api from "../../../../../services/api"

const NovoGrupoMotoristas = ({ match }) => {
  const history = useHistory()
  const idGrupo = match.params?.idGrupo
  const isEditar = match.path === '/acesso/motorista/grupos-motoristas/editar-grupo/:idGrupo' ? true : false

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/motorista', breadcrumb: 'Motorista' },
    { path: '/acesso/motorista/grupos-motoristas', breadcrumb: 'Grupos de Motoristas' },
    { path: '/acesso/motorista/grupos-motoristas/novo-grupo', breadcrumb: 'Novo Grupo' },
    { path: `/acesso/motorista/grupos-motoristas/editar-grupo`, breadcrumb: null },
    { path: `/acesso/motorista/grupos-motoristas/editar-grupo/${idGrupo}/`, breadcrumb: 'Editar Grupo' },
  ]

  const [checkboxStatus, setCheckboxStatus] = useState(true)
  const [msgErro, setMsgErro] = useState(false)

  const nome = useRef('')

  const verificaPreenchimentoCampos = (dados) => {
    if (dados.descricao.trim() === "") {
      return setMsgErro(true)
    } else {
      return true
    }
  }

  const cadastrarGrupo = () => {
    let dados = {
      descricao: nome.current.value,
      transportadora: dadosUsuario.transportadora_id,
      is_ativo: checkboxStatus
    }

    if (verificaPreenchimentoCampos(dados)) {
      api.post("grupos/", dados)
        .then(function () {
          history.push("/acesso/motorista/grupos-motoristas")
        })
        .catch(function (e) {
          console.log(e)
        })
    }
  }

  const editarGrupo = () => {
    let dados = {
      descricao: nome.current.value,
      transportadora: dadosUsuario.transportadora_id,
      is_ativo: checkboxStatus
    }

    if (verificaPreenchimentoCampos(dados)) {
      api.put(`grupos/${idGrupo}/`, dados)
        .then(function () {
          history.push("/acesso/motorista/grupos-motoristas")
        })
        .catch(function (e) {
          console.log(e)
        })
    }
  }

  useEffect(() => {
    if (idGrupo) {
      api.get(`grupos/${idGrupo}/`)
        .then(function (response) {
          let dadosGrupo = response.data

          nome.current.value = dadosGrupo.descricao
          setCheckboxStatus(dadosGrupo.is_ativo)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [idGrupo])

  return (
    <div className="container-novo-grupo-motoristas">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="novo-grupo-motoristas">
        <p className="titulo-pagina">
          {
            !isEditar
              ? "Novo Grupo de Motoristas"
              : "Editar Grupo de Motoristas"
          }
        </p>
        <p className="subtitulo-pagina">
          {
            !isEditar
              ? "Por favor, informe os dados solicitados para cadastro do novo grupo de motoristas"
              : "Por favor, informe os dados solicitados para editar o cadastro do grupo de motoristas"
          }
        </p>

        <div className="categoria">
          <p className="titulo-categoria">Dados do Grupo de Motoristas</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <input
                required
                type="text"
                ref={nome}
              />
              <span>Nome <i>*</i></span>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Status Grupo de Motoristas</p>

          <div className="container-inputs-1col">
            <div className="container-input-checkbox">
              <span className="checkbox" onClick={() => setCheckboxStatus(!checkboxStatus)} >
                <input
                  type="checkbox"
                  checked={checkboxStatus}
                  onChange={() => setCheckboxStatus(!checkboxStatus)}
                />
                <span className="checked"></span>
                Ativo
              </span>
            </div>
          </div>
        </div>

        <div className="container-botoes">
          <button
            className="btn-cancelar"
            onClick={() => history.push("/acesso/motorista/grupos-motoristas")}
          >
            Cancelar
          </button>

          <button
            className="btn-cadastrar"
            onClick={isEditar ? editarGrupo : cadastrarGrupo}
          >
            {isEditar ? "Salvar Grupo" : "Cadastrar Grupo"}
          </button>
        </div>

        {msgErro && <p className="msg-erro">Todos os campos devem ser preenchidos!</p>}
      </div>
    </div>
  )
}

export default memo(NovoGrupoMotoristas)