import React, { memo, useState, useEffect } from 'react'
import Modal from 'react-modal'
import { RiCloseCircleFill } from 'react-icons/ri'

import './styles.css'

import api from "../../../../../services/api"

const ModalHistoricoNegociacoes = ({ idCarga, modalHistoricoNegociacoes, handleModalHistoricoNegociacoes }) => {
  const [carga, setCarga] = useState(null)
  const [negociacoes, setNegociacoes] = useState([])

  useEffect(() => {
    api.get(`candidatura/?carga=${idCarga}`)
      .then(function (response) {
        let auxNegociacoes = []

        response.data.results.forEach(item => {
          if (item.status === "aprovada") {
            auxNegociacoes.unshift(item)
          } else {
            auxNegociacoes.push(item)
          }
        })

        setNegociacoes(auxNegociacoes)
      })
      .catch(function (error) {
        console.log(error)
      })

    api.get(`cargas/${idCarga}/`)
      .then(function (response) {
        setCarga(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idCarga])

  return (
    <Modal
      isOpen={modalHistoricoNegociacoes}
      onRequestClose={handleModalHistoricoNegociacoes}
      className="modal-historico-negociacoes"
      overlayClassName="overlay-modal-historico-negociacoes"
      ariaHideApp={false}
    >

      <div className="titulo">
        <p>Histórico de Negociações</p>
        <RiCloseCircleFill className="icone" onClick={handleModalHistoricoNegociacoes}></RiCloseCircleFill>
      </div>

      <div className="conteudo">
        <div className="proposta-inicial">
          <p>PROPOSTA INICIAL:</p>

          <div className="valor">
            <div>
              <p>R$</p>
            </div>

            <p className="real">
              {Number(carga?.proposta_inicial).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              {carga?.proposta_tipo === "total" ? " Cheio" : " P/Tonelada"}
            </p>
          </div>
        </div>

        <div className="titulo-tabela">
          <p>MOTORISTA</p>
          <p>PROPOSTA<br />MOTORISTA</p>
          <p>VALOR<br /> FECHADO</p>
          <p>DIFERENÇA</p>
        </div>

        <div className="cards-negociacoes">
          {negociacoes.map(item => (
            <div className={`card ${item.status}`} key={item.id}>
              <div className="img-nome">
                <img src={item.motorista.imagem_perfil} alt="" />
                <p>{item.motorista.nome_completo}</p>
              </div>

              <p>
                {item.contra_proposta
                  ? Number(item.contra_proposta).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                  : "-"}
              </p>

              <p className="valor-fechado">
                {
                  item.status === "aprovada"
                    ? item.contra_proposta
                      ? Number(item.contra_proposta).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                      : Number(item.carga.proposta_inicial).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                    : "-"
                }
              </p>

              <p>
                {item.contra_proposta_percentual ? `${item.contra_proposta_percentual}%` : '-'}
              </p>
            </div>
          ))}
        </div>

        <button className="btn-voltar" onClick={handleModalHistoricoNegociacoes}>VOLTAR</button>
      </div>
    </Modal>
  )
}

export default memo(ModalHistoricoNegociacoes)