import { useState } from "react";
import { Formik } from "formik";
import { Modal } from "../modal-base";
import { Radio } from "../../components/forms";
import { LoadingBox } from "../../components/layout/loading-box";
import {
  useAttachNfesMutation,
  useAttachCtesMutation,
  useCreateColetaMutation,
  useCreateColetaAvulsaMutation,
} from "../../core/mutations";
import { UploadButton } from './components/upload-button';
import { ListaDocumentos } from './components/lista-documentos';
import { ColetaAvulsa } from './components/coleta-avulsa';
import './modal-anexar-dfe.scss';

export const ModalAnexarDFE = ({
	isOpen,
	onClose,
	onlyNfe,
	idCarga,
  isColeta,
  title="Inserir Documento Fiscal Eletrônico",
  onAttach,
  onRemoved,
  onFileChanged,
  hideAvulso,
}) => {
	const attachNfes = useAttachNfesMutation();
  const attachCtes = useAttachCtesMutation();
  const criarColeta = useCreateColetaMutation();
  const criarColetaAvulsa = useCreateColetaAvulsaMutation();
	const [documentos, setDocumentos] = useState([]);

	const isLoading = attachNfes.isLoading || criarColeta.isLoading || attachCtes.isLoading;

  const handleAttached = () => {
    if (!onAttach) {
      return;
    }
    onAttach();
  }

  const handleRemoved = () => {
    if (!onRemoved) {
      return;
    }
    onRemoved();
  }

  const handleCreateAvulsa = (data) => {
    criarColetaAvulsa.mutate(data, {
      onSuccess: (data) => {
        if (!data) {
          return;
        }
        handleAttached();
        onClose();
      },
    });
  }

	const handleUploadFiles = async (files, isNfe) => {
    if (onFileChanged) {
      return onFileChanged(files, isNfe);
    }

		const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
		const idTransportadora = dadosUsuario.transportadora_id

		if (isNfe) {
      if (isColeta) {
        criarColeta.mutate({ files }, {
          onSuccess: (output) => {
            if (!output) {
              return;
            }
            const nfes = output.map((item) => item.nfe);
            setDocumentos((prev) => [...prev, ...nfes]);
            handleAttached();
          }
        })
        return;
      }
			attachNfes.mutate({
				transportadora: idTransportadora,
				carga: idCarga || '',
				data: files,
			}, {
				onSuccess: (output) => {
          if (!output) {
            return;
          }
					setDocumentos((prev) => [...prev, ...output]);
          handleAttached();
				},
			});
      return;
		}

    attachCtes.mutate({
      transportadora: idTransportadora,
      carga: idCarga || '',
      data: files,
    }, {
      onSuccess: (output) => {
        if (!output) {
          return;
        }
        setDocumentos((prev) => [...prev, ...output]);
        handleAttached();
      },
    });
	}

	return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      large
    >
			<Formik initialValues={{ tipo: 'nfe' }}>
				<div className="modal-anexar-dfe">
					<div className="modal-anexar-dfe-radio-container">
						<Radio id="doc-nfe" name="tipo" value="nfe" label="NF-e" />
						{!onlyNfe && !isColeta && (
							<Radio id="doc-cte" name="tipo" value="cte" label="CT-e" />
						)}
            {isColeta && !hideAvulso && (
              <Radio id="doc-avulsa" name="tipo" value="avulsa" label="Sem NF-e" />
            )}
					</div>
					
					{isLoading ? (
						<LoadingBox />
					) : (
            <>
						  <UploadButton onUploadFiles={handleUploadFiles} />

              <ColetaAvulsa onCreate={handleCreateAvulsa} />
            </>
					)}

					<ListaDocumentos
            documentos={documentos}
            setDocumentos={setDocumentos}
            onRemoved={handleRemoved}
            onClose={onClose}
          />
				</div>
			</Formik>
		</Modal>
	);
};
