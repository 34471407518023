import React, { memo, useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FaUserPlus } from 'react-icons/fa'
import { ImSearch } from 'react-icons/im'
import ReactPaginate from "react-paginate"

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import TabelaListarTransportadoras from '../../../../components/plataformaAcessada/TabelaListarTransportadoras'
import api from "../../../../services/api"

const GerenciarTransportadora = () => {
  const history = useHistory()

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
  const tipoUsuario = dadosUsuario.user_type

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/transportadora', breadcrumb: 'Transportadora' },
    { path: '/acesso/transportadora/gerenciar-transportadora', breadcrumb: 'Gerenciar Transportadoras' },
  ]

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const [searchEmbarcador, setSearchEmbarcador] = useState('')
  const [searchTransportadora, setSearchTransportadora] = useState('')
  const [transportadoras, setTransportadoras] = useState([])
  const [quantidadePaginas, setQuantidadePaginas] = useState(1)
  const [paginaAtual, setPaginaAtual] = useState(0)

  const requisitarTransportadoras = useCallback((paginaSelecionada, offSet) => {
    setPaginaAtual(paginaSelecionada)

    if (tipoUsuario !== "embarcador" && tipoUsuario !== "colaborador_embarcador") {
      api.get(`transportadora/?offset=${offSet ? offSet : 0}`)
        .then(function (response) {
          setQuantidadePaginas(Math.ceil(response.data.count / 30))
          let auxTransportadoras = []

          response.data.results.sort((x, y) => x.id - y.id).forEach((item) => {
            auxTransportadoras.push(item)
          })
  
          setTransportadoras(auxTransportadoras)
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      api.get(`transportadora/?embarcador=${dadosUsuario.embarcador_id}&is_ativo=true?offset=${offSet ? offSet : 0}`)
        .then(function (response) {
          setQuantidadePaginas(Math.ceil(response.data.count / 30))
          let auxTransportadoras = []

          response.data.results.sort((x, y) => x.id - y.id).forEach((item) => {
            auxTransportadoras.push(item)
          })
  
          setTransportadoras(auxTransportadoras)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [tipoUsuario, dadosUsuario.embarcador_id])

  const filtrarItens = () => {
    setQuantidadePaginas(1)
    setTransportadoras([])
    setPaginaAtual(0)

    api.post(`transportadora/filter`, {
      query: searchEmbarcador === '' ? searchTransportadora : searchEmbarcador
    })
      .then(function (response) {
        let listaTransportadoras = response.data
        let auxListaTransportadoras = []

        if (tipoUsuario !== "embarcador" && tipoUsuario !== "colaborador_embarcador") {
          listaTransportadoras.forEach((item) => {
            auxListaTransportadoras.push(item)
          })
        } else {
          listaTransportadoras.forEach((item) => {
            if (item.embarcador === dadosUsuario.embarcador_id) {
              auxListaTransportadoras.push(item)
            }
          })
        }

        setTransportadoras(auxListaTransportadoras)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const verificarTeclaSearch = (e) => {
    if (e.keyCode === 13) {
      filtrarItens()
    }
  }

  useEffect(() => {
    if (searchEmbarcador === '' && searchTransportadora === '') {
      forceUpdate()
      requisitarTransportadoras()
    }
  }, [requisitarTransportadoras, forceUpdate, searchEmbarcador, searchTransportadora])

  return (
    <div className="container-gerenciar-transportadora">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="gerenciar-transportadora">
        <div className="container-topo">
          <div>
            <p className="titulo-pagina">Gerenciar Transportadoras</p>
            <p className="subtitulo-pagina">Realize o filtro para busca da transportadora desejada</p>
          </div>

          <button className="btn-nova-transportadora" onClick={() => history.push("/acesso/transportadora/nova-transportadora")}>
            <FaUserPlus className="icone"></FaUserPlus>
            Nova Transportadora
          </button>
        </div>

        <div className="container-search">
          {(tipoUsuario !== "embarcador" && tipoUsuario !== "colaborador_embarcador") &&
            <div className="input-search embarcador">
              <input
                type="text"
                placeholder="Buscar Embarcador (Razão Social; CNPJ)"
                value={searchEmbarcador}
                onChange={(e) => setSearchEmbarcador(e.target.value)}
                onKeyDown={verificarTeclaSearch}
              />
              <ImSearch className="icone"></ImSearch>
            </div>
          }

          <div className="input-search transpotadora">
            <input
              type="text"
              placeholder="Buscar Transportadora (Razão Social; CNPJ)"
              value={searchTransportadora}
              onChange={(e) => setSearchTransportadora(e.target.value)}
              onKeyDown={verificarTeclaSearch}
            />
            <ImSearch className="icone"></ImSearch>
          </div>

          <button className="btn-buscar" onClick={filtrarItens}>Buscar</button>
        </div>

        <TabelaListarTransportadoras
          transportadoras={transportadoras}
          requisitarTransportadoras={requisitarTransportadoras}
        ></TabelaListarTransportadoras>

        <div className="container-paginacao-transportadora">
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próxima"}
            breakLabel={"..."}
            pageCount={quantidadePaginas}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={paginaAtual}
            onPageChange={(e) => requisitarTransportadoras(e.selected, e.selected * 30)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(GerenciarTransportadora)