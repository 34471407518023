import React, { memo, useRef, useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import api from "../../../../services/api"

const NovoTipoDeCaminhao = ({ match }) => {
  const history = useHistory()
  const idTipoCaminhao = match.params?.idTipoCaminhao
  const desabilitarInputs = match.path === '/acesso/tipo-caminhao/visualizar-tipo-caminhao/:idTipoCaminhao' ? true : false
  const isEditar = match.path === '/acesso/tipo-caminhao/editar-tipo-caminhao/:idTipoCaminhao' ? true : false

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/tipo-caminhao', breadcrumb: 'Tipo De Caminhão' },
    { path: '/acesso/tipo-caminhao/novo-tipo-caminhao', breadcrumb: 'Novo Tipo De Caminhão' },
    { path: `/acesso/tipo-caminhao/visualizar-tipo-caminhao`, breadcrumb: null },
    { path: `/acesso/tipo-caminhao/visualizar-tipo-caminhao/${idTipoCaminhao}`, breadcrumb: 'Visualizar Tipo De Caminhão' },
    { path: `/acesso/tipo-caminhao/editar-tipo-caminhao`, breadcrumb: null },
    { path: `/acesso/tipo-caminhao/editar-tipo-caminhao/${idTipoCaminhao}`, breadcrumb: 'Editar Tipo De Caminhão' },
  ]

  const [msgErro, setMsgErro] = useState(false)

  const porteCaminhao = useRef('')
  const tipoCaminhao = useRef('')

  const verificaPreenchimentoCampos = () => {
    if (porteCaminhao.current.value.trim() === "" || tipoCaminhao.current.value.trim() === "") {
      return setMsgErro(true)
    } else {
      return true
    }
  }

  const cadastrarTipoCaminhao = () => {
    let dados = {
      categoria_veiculo: porteCaminhao.current.value,
      tipo: tipoCaminhao.current.value
    }

    if (verificaPreenchimentoCampos()) {
      api.post("tipoveiculo/", dados)
        .then(function () {
          history.push("/acesso/tipo-caminhao")
        })
        .catch(function (e) {
          console.log(e)
        })
    }
  }

  const editarTipoCaminhao = () => {
    let dados = {
      categoria_veiculo: porteCaminhao.current.value,
      tipo: tipoCaminhao.current.value
    }

    if (verificaPreenchimentoCampos()) {
      api.put(`tipoveiculo/${idTipoCaminhao}/`, dados)
        .then(function () {
          history.push("/acesso/tipo-caminhao")
        })
        .catch(function (e) {
          console.log(e.response)
        })
    }
  }

  useEffect(() => {
    if (idTipoCaminhao) {
      api.get(`tipoveiculo/${idTipoCaminhao}/`)
        .then(function (response) {
          let dadosTipoCaminhao = response.data

          porteCaminhao.current.value = dadosTipoCaminhao.categoria_veiculo
          tipoCaminhao.current.value = dadosTipoCaminhao.tipo
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [idTipoCaminhao])

  return (
    <div className="container-novo-tipo-caminhao">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="novo-tipo-caminhao">
        <p className="titulo-pagina">
          {
            desabilitarInputs && !isEditar
              ? "Visualizar Tipo De Caminhão"
              : isEditar
                ? "Editar Tipo De Caminhão"
                : "Novo Tipo De Caminhão"
          }
        </p>
        <p className="subtitulo-pagina">
          {
            desabilitarInputs && !isEditar
              ? "Dados informados no cadastro do tipo de caminhão"
              : isEditar
                ? "Por favor, informe os dados solicitados para editar o cadastro do tipo de caminhão"
                : "Por favor, informe os dados solicitados para cadastro de novo tipo de caminhão"
          }
        </p>

        <div className="categoria">
          <p className="titulo-categoria">Dados do Tipo De Caminhão</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <select
                required
                ref={porteCaminhao}
                disabled={desabilitarInputs}
              >
                <option value="" disabled></option>
                <option value={1}>Leve</option>
                <option value={2}>Médio</option>
                <option value={3}>Pesado</option>
              </select>
              <span>Porte caminhão (leve; médio; pesado) <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={tipoCaminhao}
                disabled={desabilitarInputs}
              />
              <span>Tipo De Caminhão <i>*</i></span>
            </div>
          </div>
        </div>

        {
          !desabilitarInputs &&
          <div className="container-botoes">
            <button
              className="btn-cancelar"
              onClick={() => history.replace("/acesso/tipo-caminhao")}
            >
              Cancelar
            </button>

            <button
              className="btn-cadastrar"
              onClick={isEditar ? editarTipoCaminhao : cadastrarTipoCaminhao}
            >
              {isEditar ? "Salvar Caminhão" : "Cadastrar Caminhão"}
            </button>
          </div>
        }

        {msgErro && <p className="msg-erro">Todos os campos com * devem ser preenchidos!</p>}
      </div>
    </div>
  )
}

export default memo(NovoTipoDeCaminhao)