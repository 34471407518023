import { useEffect, useState } from "react";
import ModalIncluirNota from './ModalIncluirNota';
import ModalGerarCteAvulso from "./ModalGerarCteAvulso";
import { confirmAlert } from 'react-confirm-alert'
import api from '../../../../../services/api';

const GerarDocumentoAvulso = ({idCte, clearIdCte, requisitaCtes, tipoCtePadrao, otherActions}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCteModalOpen, setIsCteModalOpen] = useState(false);
  const [dadosNfeSelecionada, setDadosNfeSelecionada] = useState([]);

  const handleGerar = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const checkCte = (id) => {
      api
        .get(`/documentos_fiscais/cte/${id}/`)
        .then((response) => {
          const { data } = response;
          const { notas_transportadas } = data;
          if (notas_transportadas.length === 0) {
            if (data.cte_complementado) {
              checkCte(data.cte_complementado);
            }
            return;
          }
          const [nota] = notas_transportadas;
          if (!nota || !nota.nfe) {
            if (data.cte_complementado) {
              checkCte(data.cte_complementado);
            }
            return;
          }
          console.log(nota);
          
          setDadosNfeSelecionada([nota.nfe]);
          
          setIsModalOpen(false);
          setIsCteModalOpen(true);
        })
        .catch((err) => console.log(err));
    }

    if (idCte && !isCteModalOpen) {
      checkCte(idCte);
    }
  }, [idCte, isCteModalOpen]);

  const handleGerarCteAvulso = () => {
    if (dadosNfeSelecionada.length === 0) {
      confirmAlert({
        title: "Erro!",
        message: `Você precisa anexar pelo menos uma NF-e para poder gerar um CT-e avulso!`,
        overlayClassName: "alert-remover-nfe",
        buttons: [
          {
            label: "Ok",
            className: "botao-cancelar"
          }
        ]
      })
      return
    }
    setIsModalOpen(false)
    setIsCteModalOpen(true)
    clearIdCte()
  }

  return (
    <>
      <div className="container-add-documento">
        {otherActions}
        <button className="btn-documento-avulso" onClick={handleGerar}>
          Gerar Documento Avulso
        </button>
      </div>

      <ModalIncluirNota
        isOpen={isModalOpen}
        dadosDocumentos={dadosNfeSelecionada}
        setDadosDocumentos={(dados) => setDadosNfeSelecionada(dados)}
        onClose={() => setIsModalOpen(false)}
        onGerarCteAvulso={handleGerarCteAvulso}
      />
      {isCteModalOpen && (!!dadosNfeSelecionada || !!idCte) && (
        <>
          <ModalGerarCteAvulso
            isOpen={isCteModalOpen}
            tipoCteInicial={tipoCtePadrao}
            onClose={() => {
              setDadosNfeSelecionada([])
              setIsCteModalOpen(false)
              clearIdCte()
              requisitaCtes()
            }}
            dadosNfes={dadosNfeSelecionada}
            idCteSelecionado={idCte}
          />
        </>
      )}
    </>
  );
};

export default GerarDocumentoAvulso;
