import React from 'react';
import './no-items-box.scss';

export const NoItemsBox = ({ text, createButton, onCreateClick }) => {
  return (
    <div className="no-itens-box">
      <p className="text">{text || 'Não existem itens para exibir.'}</p>

      {createButton && (
        <button className="create-button" type='button' onClick={onCreateClick}>
          Criar Novo
        </button>
      )}
    </div>
  )
}