import { useState, useEffect } from 'react';
import Select from 'react-select';
import { MdWrongLocation } from 'react-icons/md';
import { ufs } from './ufs';

const SelecionarEndereco = ({
  title,
  onChangeUf,
  uf,
  municipio,
  onChangeMunicipio,

  /*
  addButton,
  onAddButtonClick,*/
  removeButton,
  onRemoveButtonClick
}) => {
  const [municipios, setMunicipios] = useState([]);

  useEffect(() => {
    fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf.value}/municipios`)
      .then(res => {
        return res.json()
      })
      .then(municipios => {
        setMunicipios(municipios)
      })
  }, [uf]);

  return (
    <div className={`selecionar-endereco ${removeButton ? 'remove' : ''}`}>
      {!!title && (
        <p className="title">{title}</p>
      )}

      <Select
        required
        value={!uf.value ? null : uf}
        onChange={onChangeUf}
        options={ufs}
        placeholder="UF"
      />
      <Select
        required
        value={!municipio.value ? null : municipio}
        onChange={onChangeMunicipio}
        options={municipios.map((item) => ({
          label: item.nome,
          value: item.nome,
          codigo: item.id,
        }))}
        placeholder="Municipio"
      />
      {removeButton && (
        <button onClick={onRemoveButtonClick}>
          <MdWrongLocation size={24} />
        </button>
      )}
    </div>
  )
};

export default SelecionarEndereco;
