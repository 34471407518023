import { useCallback } from 'react';
import { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { MdSignalWifiStatusbarConnectedNoInternet } from 'react-icons/md'
import './style.css'

const AlertNetworkStatus = (title, message, status, onConfirm) => {

    const isOnline = status === 'on' ? true : false;

    confirmAlert({
        overlayClassName: "alert-network-modal",
        closeOnClickOutside: isOnline,
        customUI: ({ onClose }) => (
            <CustomModal onClose={onClose} isOnline={isOnline} title={title} message={message} />
        ),
    });
};

export default AlertNetworkStatus;

const CustomModal = ({ onClose, isOnline, title, message }) => {

    const handleTimeout = useCallback(() => {
        setTimeout(() => {
            onClose();
        }, 5000);
    }, [onClose]);

    useEffect(() => {
        if (isOnline) {
            handleTimeout();
        }
    }, [isOnline, handleTimeout]);

    return (
        <div className="custom-modal">
            <div className="custom-modal-header">
                <button className="custom-modal-close" onClick={onClose}>
                    X
                </button>
            </div>
            <div className="custom-modal-content">
                <span className={!isOnline ? 'custom-modal-icon-off' : 'custom-modal-icon-on'}>
                    <MdSignalWifiStatusbarConnectedNoInternet />
                </span>
                <div>
                    <p className="custom-modal-title">{title}</p>
                    <p>{message}</p>
                </div>
            </div>
        </div>
    );
};