import InputMask from 'react-input-mask'
import { MdOutlineSwapVert, MdClose } from 'react-icons/md';
import './styles.css';

const FormularioPapelCte = ({
  title,
  papel,
  setPapel,
  showSwap = false,
  onSwapClick,
  showClose = false,
  onCloseClick,
}) => {
  const handleChange = (field, value) => {
    setPapel((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleChangeEndereco = (field, value) => {
    setPapel((prev) => ({
      ...prev,
      endereco: {
        ...prev.endereco,
        [field]: value,
      },
    }));
  }

  const handleChangeCep = (value) => {
    if (value.length !== 9) {
      handleChangeEndereco('cep', value);
      return;
    }
    fetch(`https://viacep.com.br/ws/${value}/json/`)
      .then((response) => {
        response.json()
          .then((data) => {
            setPapel((prev) => ({
              ...prev,
              endereco: {
                ...prev.endereco,
                cep: value,
                uf: data.uf,
                nome_municipio: data.localidade,
                codigo_municipio: data.ibge,
                logradouro: data.logradouro,
                bairro: data.bairro,
              }
            }))
          })
      })
      .catch((error) => {
        console.log("ERRO: " + error.message)
      })
  }
  
  return (
    <div className="container-formulario formulario-papel-cte">
      <p className="titulo-formulario">{title}</p>

      {showSwap && (
        <MdOutlineSwapVert
          className='swap'
          size="24"
          onClick={onSwapClick}
          title="Inverter Remetente e Destinatário"
        />
      )}
      {showClose && (
        <MdClose
          className='swap'
          size="24"
          onClick={onCloseClick}
          title={`Remover ${title}`}
        />
      )}

      <div className="container-inputs-3col">
        <div className="container-input-comum">
          <input
            required
            type="text"
            onChange={(e) => handleChange('razao_social', e.target.value)}
            value={papel.razao_social}
          />
          <span>Nome / R.Social</span>
        </div>

        <div className="container-input-comum">
          <input
            type="text"
            onChange={(e) => handleChange('cnpj', e.target.value)}
            value={papel.cnpj}
          />
          <span>CNPJ</span>
        </div>

        <div className="container-input-comum">
          <input
            type="text"
            onChange={(e) => handleChange('cpf', e.target.value)}
            value={papel.cpf}
          />
          <span>CPF</span>
        </div>

        <div className="container-input-comum">
          <input
            type="text"
            onChange={(e) => handleChange('ie', e.target.value)}
            value={papel.ie}
          />
          <span>I.E</span>
        </div>
      </div>

      <p className="titulo-formulario prestacao">Endereço</p>

      <div className="container-inputs-3col">
        <div className="container-input-comum">
          <InputMask
            required
            mask="99999-999"
            maskChar=""
            onChange={(e) => handleChangeCep(e.target.value)}
            value={papel.endereco.cep}
          ></InputMask>
          <span>CEP</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            onChange={(e) => handleChangeEndereco('uf', e.target.value)}
            value={papel.endereco.uf}
          />
          <span>UF</span>
        </div>
        
        <div className="container-input-comum">
          <input
            type="text"
            onChange={(e) => handleChangeEndereco('nome_municipio', e.target.value)}
            value={papel.endereco.nome_municipio}
          />
          <span>Nome Municipio</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            onChange={(e) => handleChangeEndereco('codigo_municipio', e.target.value)}
            value={papel.endereco.codigo_municipio}
          />
          <span>Código Municipio</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            onChange={(e) => handleChangeEndereco('bairro', e.target.value)}
            value={papel.endereco.bairro}
          />
          <span>Bairro</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            onChange={(e) => handleChangeEndereco('logradouro', e.target.value)}
            value={papel.endereco.logradouro}
          />
          <span>Logradouro</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            onChange={(e) => handleChangeEndereco('numero', e.target.value)}
            value={papel.endereco.numero}
          />
          <span>Número</span>
        </div>

        <div className="container-input-comum">
          <input
            type="text"
            onChange={(e) => handleChangeEndereco('complemento', e.target.value)}
            value={papel.endereco.complemento}
          />
          <span>Complemento</span>
        </div>
      </div>
    </div>
  );
}

export default FormularioPapelCte;
