import React, { useEffect, useRef } from "react";
import { LoadingAnimated } from "../../../../../../components/plataformaAcessada/LoadingAnimated";
import './styles.css';

const ContainerCards = ({ cargas, total, loading, component, onLoadMore }) => {
    const list = useRef();

    const handleLoadMore = () => {
        onLoadMore();
    };

    const handleScroll = (event) => {
        if (loading || cargas.length >= total) {
            return;
        }
        const {
            target: { scrollTop, scrollHeight, clientHeight }
        } = event;
        if (scrollTop + clientHeight >= (scrollHeight - 80)) {
            handleLoadMore();
        }
    };

    useEffect(() => {
        if (!loading || !list.current) {
            return;
        }
        const { scrollHeight } = list.current;
        list.current.scrollTo({
            top: scrollHeight,
            behavior: 'smooth',
        });
    }, [loading]);

    return (
        <div className="cards" ref={list} onScroll={handleScroll}>
            {cargas.map((item) =>
                React.cloneElement(component, { key: item.id, carga: item })
            )}
            {loading ? (
                <LoadingAnimated
                    styles={{
                        display: "block",
                        margin: "auto",
                        marginTop: '10%',
                    }}
                />
            ) : (
                <>
                    {cargas.length < total && (
                        <div className="painel-load-more-container">
                            <button
                                type="button"
                                onClick={handleLoadMore}
                            >
                                Carregar Mais
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default ContainerCards