import React, { memo, useState, useCallback, useEffect } from "react";
import { FaTrashAlt, FaEdit, FaPlus, FaFileContract } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { BsPrinterFill } from "react-icons/bs";
import { IoReload, IoDocumentsOutline } from "react-icons/io5";
import { HiOutlineDocumentAdd } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { saveAs } from "file-saver";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./styles.css";

import { ModalAnexarDFE } from '../../../../../../modals/modal-anexar-dfe'

import ModalNovoDocumento from "./ModalNovoDocumento";
import ModalDadosNfe from "./ModalDadosNfe";
import ModalDadosCte from "./ModalDadosCte";
import ModalGerarCte from "./ModalGerarCte";
import ModalAnularCte from "./ModalDadosCte/ModalAnularCte";
import ModalSelecionarNotas from './ModalSelecionarNotas';
import FormularioEditarRelatorio from "./FormularioEditarRelatorio";

import api from "../../../../../../services/api";

const TabelaRelatorioCargas = ({
  idCarga,
  documentos,
  requisitarDocumentos,
  manifestos,
}) => {
  const history = useHistory();
  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));

  const [anexarDocumentoOpen, setAnexarDocumentoOpen] = useState(false);

  //const [modalNovoDocumento, setModalNovoDocumento] = useState(false);
  const [modalDadosNfe, setModalDadosNfe] = useState(false);
  const [modalDadosCte, setModalDadosCte] = useState(false);
  const [contrato, setContrato] = useState(null);
  const [romaneio, setRomaneio] = useState(null);
  const [ordemColeta, setOrdemColeta] = useState(null);
  const [dadosNfeSelecionada, setDadosNfeSelecionada] = useState(null);
  const [idCteSelecionado, setIdCteSelecionado] = useState(null);
  const [idColetaSelecionado, setIdColetaSelecionado] = useState(null);
  const [idDocumentoSelecionado, setIdDocumentoSelecionado] = useState(null);
  const [tipoDocumentoSelecionado, setTipoDocumentoSelecionado] =
    useState(null);
  const [idDocumentoVisualizar, setIdDocumentoVisualizar] = useState(null);
  const [tipoCtePadrao, setTipoCtePadrao] = useState(0);

  const [idCteAnulacaoRetificar, setIdCteAnulacaoRetificar] = useState(null);

  const [gerarCteLote, setGerarCteLote] = useState(false);

  const [carga, setCarga] = useState(null);

  const handleOpenAnexarDocumento = () => {
    setAnexarDocumentoOpen(true);
    document.querySelector("body").style.overflow = "hidden";
  }

  const handleCloseAnexarDocumento = () => {
    setAnexarDocumentoOpen(false);
    document.querySelector("body").style.overflow = "visible";
  }

  /*
  const handleModalNovoDocumento = useCallback(() => {
    if (modalNovoDocumento) {
      setModalNovoDocumento(false);
      document.querySelector("body").style.overflow = "visible";
    } else {
      setModalNovoDocumento(true);
      document.querySelector("body").style.overflow = "hidden";
    }
  }, [modalNovoDocumento]);
  */

  const handleModalDadosNfe = useCallback(
    (idNfe) => {
      if (modalDadosNfe) {
        setModalDadosNfe(false);
        document.querySelector("body").style.overflow = "visible";
      } else {
        setIdDocumentoVisualizar(idNfe);
        setModalDadosNfe(true);
        document.querySelector("body").style.overflow = "hidden";
      }
    },
    [modalDadosNfe]
  );

  const handleModalDadosCte = useCallback(
    (idCte) => {
      if (modalDadosCte) {
        setModalDadosCte(false);
        document.querySelector("body").style.overflow = "visible";
      } else {
        setIdDocumentoVisualizar(idCte);
        setModalDadosCte(true);
        document.querySelector("body").style.overflow = "hidden";
      }
    },
    [modalDadosCte]
  );

  const handleModalGerarCte = useCallback(
    (nfe, idCte, idColeta) => {
      if (dadosNfeSelecionada) {
        setDadosNfeSelecionada(null);
        setIdCteSelecionado(null);
        setIdColetaSelecionado(null);
        document.querySelector("body").style.overflow = "visible";
      } else {
        setDadosNfeSelecionada(nfe);
        setIdCteSelecionado(idCte);
        setIdColetaSelecionado(idColeta);
        document.querySelector("body").style.overflow = "hidden";
      }
    },
    [dadosNfeSelecionada]
  );

  const handleModalGerarCteSubstituto = (nfe, idCte) => {
    setDadosNfeSelecionada(nfe);
    setIdCteSelecionado(idCte);
    setTipoCtePadrao(3);
    document.querySelector("body").style.overflow = "hidden";
  };

  const removerNfe = (idNfe) => {
    api
      .delete(`documentos_fiscais/nfe/${idNfe}/`)
      .then(function () {
        requisitarDocumentos();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const alertRetificarCte = (doc, idCte) => {
    confirmAlert({
      title: "Retificar CTe?",
      message: `Erro de emissão:  ${
        doc.ctes[0]?.erros_emissao?.sefaz ||
        doc.ctes[0]?.erros_emissao?.tecnospeed
      }`,
      overlayClassName: "alert-remover-nfe",
      buttons: [
        {
          label: "Ok",
          onClick: () => handleModalGerarCte(doc, idCte),
          className: "botao-cancelar",
        },
        {
          label: "Cancelar",
          className: "botao-confirmar",
        },
      ],
    });
  };

  const alertRemoverNfe = (idNfe) => {
    confirmAlert({
      title: "Remover NFe",
      message: `Deseja realmente remover a NFe de ID ${idNfe} do sistema?`,
      overlayClassName: "alert-remover-nfe",
      buttons: [
        {
          label: "Sim",
          onClick: () => removerNfe(idNfe),
          className: "botao-confirmar",
        },
        {
          label: "Não",
          onClick: () => {},
          className: "botao-cancelar",
        },
      ],
    });
  };

  const alertAverbacao = (cte) => {
    confirmAlert({
      title: "Reenviar Averbação?",
      message: `Erro de averbação:  ${
        cte?.erros_averbacao
          ? cte?.erros_averbacao?.map((item, index) => {
              return index !== 0
                ? ` | ${item?.Descricao}`
                : ` ${item?.Descricao}`;
            })
          : "Sem erros"
      }`,
      overlayClassName: "alert-remover-nfe",
      buttons: [
        {
          label: "Reenviar",
          onClick: () => {
            api
              .post(`documentos_fiscais/cte/${cte.id}/averbar/`)
              .then(function () {})
              .catch(function (error) {
                console.log(error);
              });
          },
          className: "botao-cancelar",
        },
        {
          label: "Cancelar",
          className: "botao-confirmar",
        },
      ],
    });
  };

  const handleGerarCteComplementar = (nfe, idCte) => {
    setDadosNfeSelecionada(nfe);
    setIdCteSelecionado(idCte);
    setIdColetaSelecionado(nfe.coleta?.id || null);
    setTipoCtePadrao(1);
    document.querySelector("body").style.overflow = "hidden";
  };

  const verificarCertificadoDigital = (nfe) => {
    if (
      dadosUsuario.user_type === "transportadora" &&
      !dadosUsuario.certificado_digital_adicionado
    ) {
      return confirmAlert({
        title: "Certificado Digital",
        message:
          "Você ainda não cadastrou o seu certificado digital e senha no sistema!",
        overlayClassName: "alert-certificado-digital",
        buttons: [
          {
            label: "Cadastrar",
            onClick: () => {
              document.querySelector("body").style.overflow = "visible";
              history.replace(
                `/acesso/transportadora/editar-transportadora/${dadosUsuario.transportadora_id}`
              );
            },
            className: "botao-confirmar",
          },
        ],
      });
    }

    handleModalGerarCte(nfe, null, nfe?.coleta?.id || null);
  };

  const criarContrato = (atualizar) => {
    api
      .post("contrato/gerar/", {
        carga: idCarga,
      })
      .then(function (response) {
        setContrato(response.data.arquivo);

        confirmAlert({
          title: "Sucesso",
          message: atualizar
            ? "Contrato atualizado com sucesso!"
            : "Contrato criado com sucesso!",
          overlayClassName: "alert-remover-nfe",
          buttons: [
            {
              label: "Ok",
              className: "botao-cancelar",
            },
          ],
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const atualizarInformacaoRomaneio = () => {
    api
      .get(`cargas/${idCarga}/`)
      .then(function (response) {
        setCarga(response.data);
        setContrato(response.data.contrato?.arquivo);
        setRomaneio(response.data.romaneio);
        setOrdemColeta(response.data.ordem_coleta);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const criarOrdemColeta = (atualizar) => {
    api
      .post("fracionado/ordem-coleta/", {
        carga: idCarga,
      })
      .then(function (response) {
        setOrdemColeta(response.data);

        confirmAlert({
          title: "Sucesso",
          message: atualizar
            ? "Ordem de Coleta atualizada com sucesso!"
            : "Ordem de Coleta criada com sucesso!",
          overlayClassName: "alert-remover-nfe",
          buttons: [
            {
              label: "Ok",
              className: "botao-cancelar",
            },
          ],
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const criarRomaneio = (atualizar) => {
    api
      .post("fracionado/romaneio/", {
        carga: idCarga,
      })
      .then(function (response) {
        setRomaneio(response.data);

        confirmAlert({
          title: "Sucesso",
          message: atualizar
            ? "Romaneio atualizado com sucesso!"
            : "Romaneio criado com sucesso!",
          overlayClassName: "alert-remover-nfe",
          buttons: [
            {
              label: "Ok",
              className: "botao-cancelar",
            },
          ],
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const exportarRelatorio = (tipo) => {
    api
      .post("documentos_fiscais/relatorio/", {
        carga: idCarga,
      })
      .then(function (response) {
        let token = response.data.token;

        if (token) {
          window.open(
            `${process.env.REACT_APP_LINK_API}relatorio/${tipo}/${token}/`
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    api
      .get(`cargas/${idCarga}/`)
      .then(function (response) {
        setCarga(response.data);
        setContrato(response.data.contrato?.arquivo);
        setRomaneio(response.data.romaneio);
        setOrdemColeta(response.data.ordem_coleta);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idCarga]);

  const alertRetificarCteAnulacao = (doc, cte) => {
    confirmAlert({
      title: "Retificar CTe?",
      message: `Erro de emissão:  ${
        doc.ctes[0]?.erros_emissao?.sefaz ||
        doc.ctes[0]?.erros_emissao?.tecnospeed
      }`,
      overlayClassName: "alert-remover-nfe",
      buttons: [
        {
          label: "Ok",
          onClick: () => setIdCteAnulacaoRetificar(cte.id),
          className: "botao-cancelar",
        },
        {
          label: "Cancelar",
          className: "botao-confirmar",
        },
      ],
    });
  };

  const handleRetificarCte = (doc, cte) => {
    if (cte.tipo === "Anulação") {
      alertRetificarCteAnulacao(doc, cte);
      return;
    }
    if (cte.status === "Negado") {
      setTipoCtePadrao("0");
      alertRetificarCte(doc, cte.id);
      return;
    }
    handleModalGerarCte(doc, cte.id);
  };

  const adicionarCtesParaNfes = (nfes) => {
    if (!nfes.length) {
      return;
    }
    handleModalDadosCte();
    handleModalGerarCte(nfes[0], null, nfes[0].coleta?.id || null);
  };

  const handleCompleteCteLote = () => {
    setGerarCteLote(false);
    requisitarDocumentos();
  }

  return (
    <div className="tabela-relatorio-cargas">
      <div className="botoes-topo">
        <div className="btns-esquerda">
          <button className="btn-financeiro" onClick={handleOpenAnexarDocumento}>
            Incluir Documento
            <FaPlus className="icone"></FaPlus>
          </button>

          <button className="btn-financeiro" onClick={() => setGerarCteLote(true)}>
            CT-e em Lote
            <IoDocumentsOutline className="icone"></IoDocumentsOutline>
          </button>
        </div>

        <div className="btns-direita">
          {carga?.is_fracionada && (
            <>
              {!!romaneio?.arquivo && (
                <>
                  <button
                    onClick={() =>
                      saveAs(romaneio.arquivo, `Romaneio-Carga-${idCarga}.pdf`)
                    }
                  >
                    <FiDownload className="icone"></FiDownload>
                    <p>Baixar Romaneio</p>
                  </button>
                  
                  <button onClick={() => criarRomaneio(true)}>
                    <IoReload className="icone"></IoReload>
                    <p>Atualizar Romaneio</p>
                  </button>
                </>
              )}
    
              {!!romaneio && !romaneio.arquivo && (
                <button onClick={() => atualizarInformacaoRomaneio()} title="Clique para atualizar">
                  <FaFileContract className="icone" />
                  <p>Gerando Romaneio...</p>
                </button>
              )}
    
              {!romaneio && (
                <button onClick={() => criarRomaneio(false)}>
                  <IoReload className="icone"></IoReload>
                  <p>Gerar Romaneio</p>
                </button>
              )}
            </>
          )}

          {!contrato && (
            <button onClick={() => criarContrato(false)}>
              <FaFileContract className="icone"></FaFileContract>
              <p>Gerar Contrato</p>
            </button>
          )}

          {contrato && (
            <>
              <button
                onClick={() =>
                  saveAs(contrato, `Contrato-Carga-${idCarga}.pdf`)
                }
              >
                <FiDownload className="icone"></FiDownload>
                <p>Baixar Contrato</p>
              </button>

              <button onClick={() => criarContrato(true)}>
                <IoReload className="icone"></IoReload>
                <p>Atualizar Contrato</p>
              </button>
            </>
          )}

          <div className="prints">
            <div className="tag">
              <span tag-dado="Relatório Transportadora">
                <BsPrinterFill
                  className="icone"
                  onClick={() => exportarRelatorio("transportadora")}
                ></BsPrinterFill>
              </span>
            </div>

            <div className="tag">
              <span tag-dado="Relatório Motorista">
                <BsPrinterFill
                  className="icone color"
                  onClick={() => exportarRelatorio("motorista")}
                ></BsPrinterFill>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="container-tabela-relatorio-cargas">
        <table>
          <thead>
            <tr>
              <th>NF-e</th>
              {carga?.is_fracionada && (
                <th>Tipo Operação</th>
              )}
              <th>CT-e</th>
              <th>Status CT-e</th>
              <th>Tipo CT-e</th>
              <th>CT-e Averbado?</th>
              <th>Cliente</th>
              <th>Destino</th>
              <th>UF</th>
              <th>Peso</th>
              <th>
                Valor
                <br />
                TN CTe
              </th>
              <th>
                Total
                <br />
                CTe
              </th>
              <th>
                Valor
                <br />
                TN
              </th>
              <th>
                Valor
                <br />
                Total
              </th>
              <th>
                Descarga
                <br />
                Total
              </th>
              <th>
                Repasse
                <br />
                TN
              </th>
              <th>Repasse</th>
              <th>
                Repasse
                <br />
                Total
              </th>
              <th>
                Previsão
                <br />
                de Imposto
              </th>
              <th>Lucro %</th>
              <th>Saldo</th>
              <th>Ações</th>
            </tr>
          </thead>

          <tbody>
            {documentos?.tabela?.map((doc, index) => (
              <tr
                key={index}
                className={
                  idDocumentoSelecionado === index
                    ? "linha-selecionada"
                    : `linha ${index % 2 === 0 ? "par" : "impar"}`
                }
              >
                {doc.id_tipo === "minutas" || doc.id_tipo === "aditivos" ? (
                  <td colspan={5} className="coluna-modal titulo-esquerda">
                    {doc.id_tipo === "minutas"
                      ? "Reembolso de Despesas"
                      : "Aditivos Manuais"}
                  </td>
                ) : (
                  <>
                    <td className="coluna-modal">
                      {doc.numero === "-" ? (
                        <>{"-"}</>
                      ) : (
                        <span onClick={() => handleModalDadosNfe(doc.id)}>
                          {doc.numero ? doc.numero : doc.chave}
                        </span>
                      )}
                    </td>
                    {carga?.is_fracionada && (
                      <td className="coluna-modal">
                        {["COLETA", "EM_COLETA", "COLETADA"].includes(doc.coleta?.status) ? "Coleta" : "Entrega"}
                      </td>
                    )}
                    <td className="coluna-modal">
                      {doc.ctes.length === 0 ? (
                        <>
                          {doc.chave ? (
                            <HiOutlineDocumentAdd
                              className="icone"
                              onClick={() => verificarCertificadoDigital(doc)}
                            />
                          ) : (
                            <HiOutlineDocumentAdd
                              className="icone disabled"
                              title="Chave da NF-e não presente. No caso de uma coleta, é possível que essa seja uma coleta avulsa, sem NF-e anexada. Para emitir CT-e, vá a tela de coletas e anexe uma NF-e na coleta."
                            />
                          )}
                        </>
                      ) : (
                        <span
                          onClick={() => handleModalDadosCte(doc.ctes[0].id)}
                        >
                          {doc.ctes.map((cte, index) => {
                            return index !== 0
                              ? `${cte.numero}\n`
                              : `${cte.numero}`;
                          })}
                        </span>
                      )}
                    </td>
                    <td>
                      {doc.ctes.length > 0
                        ? doc.ctes.map((cte, index) => {
                            if (
                              cte.status === "Pendente" ||
                              cte.status === "Negado" ||
                              cte.status === "Cancelado"
                            ) {
                              return (
                                <span
                                  key={cte.id}
                                  className="cte-retificar"
                                  onClick={() => handleRetificarCte(doc, cte)}
                                >
                                  {cte.status}
                                </span>
                              );
                            }
                            if (cte.status === "Anulado" && !cte.substituido) {
                              return (
                                <span
                                  key={cte.id}
                                  className="cte-retificar"
                                  onClick={() =>
                                    handleModalGerarCteSubstituto(doc, cte.id)
                                  }
                                >
                                  {cte.status}
                                </span>
                              );
                            }
                            return index !== 0
                              ? `${cte.status}\n`
                              : `${cte.status}`;
                          })
                        : "-"}
                    </td>
                    <td>
                      {doc.ctes.length > 0 ? (
                        <>{doc.ctes[0].tipo}</>
                      ) : (
                        <>{"-"}</>
                      )}
                    </td>
                    <td>
                      {doc.ctes.length > 0
                        ? doc.ctes.map((cte) => (
                            <span
                              key={cte.id}
                              className={cte.averbado ? `` : "cte-retificar"}
                              onClick={
                                cte.averbado
                                  ? () => {}
                                  : () => alertAverbacao(cte)
                              }
                            >
                              {cte.averbado ? "Sim" : "Não"}
                            </span>
                          ))
                        : "-"}
                    </td>
                  </>
                )}
                <td>
                  {doc.modalidade_frete === "CIF"
                    ? doc.remetente_razao_social
                    : doc.destinatario_razao_social}
                </td>
                <td>{doc.destinatario_endereco?.nome_municipio}</td>
                <td>{doc.destinatario_endereco?.uf}</td>
                <td>
                  {doc.peso?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {doc.valor_cte_tn?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {doc.valor_cte_total?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {doc.valor_tn?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {doc.valor_total?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {doc.descarga_total?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {doc.repasse_tn?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {doc.repasse?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {doc.repasse_final?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {doc.previsao_impostos?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {doc.lucro?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
                  %
                </td>
                <td>
                  {doc.saldo?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td className="coluna-acoes">
                  {!(
                    doc.id_tipo === "minutas" || doc.id_tipo === "aditivos"
                  ) && (
                    <>
                      {doc.ctes.length > 0 && (
                        <HiOutlineDocumentAdd
                          className="icone"
                          title="Emitir CT-e Complementar"
                          onClick={() =>
                            handleGerarCteComplementar(doc, doc.ctes[0].id, doc.coleta?.id || null)
                          }
                        ></HiOutlineDocumentAdd>
                      )}
                      <FaEdit
                        className="icone"
                        title="Editar Informações"
                        onClick={
                          idDocumentoSelecionado !== doc.id
                            ? () => setIdDocumentoSelecionado(doc.id)
                            : () => setIdDocumentoSelecionado(null)
                        }
                      ></FaEdit>
                      <FaTrashAlt
                        className="icone lixo"
                        title="Excluir"
                        onClick={() => alertRemoverNfe(doc.id)}
                      ></FaTrashAlt>
                    </>
                  )}
                </td>
              </tr>
            ))}
            <tr className="linha-total">
              <td colspan={carga?.is_fracionada ? 9 : 8}></td>
              <td className="coluna-total">
                {documentos?.totals?.peso?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                })}
              </td>
              <td></td>
              <td className="coluna-total">
                {documentos?.totals?.valor_cte_total?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                })}
              </td>
              <td></td>
              <td className="coluna-total">
                {documentos?.totals?.valor_total?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                })}
              </td>
              <td></td>
              <td className="coluna-total">
                {documentos?.totals?.repasse_final_tn?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                })}
              </td>
              <td className="coluna-total">
                {documentos?.totals?.repasse?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                })}
              </td>
              <td className="coluna-total">
                {documentos?.totals?.repasse_final?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                })}
              </td>
              <td className="coluna-total">
                {documentos?.totals?.previsao_impostos?.toLocaleString(
                  "pt-br",
                  { minimumFractionDigits: 2 }
                )}
              </td>
              <td className="coluna-total"></td>
              <td className="coluna-total">
                {documentos?.totals?.saldo?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                })}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      {carga?.is_fracionada && (
        <>
          <p className="titulo mdf">Ordem de Coleta</p>
          
          <div className="botoes-topo">
            <div className="btns-direita">
              {!!ordemColeta?.arquivo && (
                <>
                  <button
                    onClick={() =>
                      saveAs(ordemColeta.arquivo, `OrdemColeta-Carga-${idCarga}.pdf`)
                    }
                  >
                    <FiDownload className="icone"></FiDownload>
                    <p>Baixar Ordem de Coleta</p>
                  </button>
                  
                  <button onClick={() => criarOrdemColeta(true)}>
                    <IoReload className="icone"></IoReload>
                    <p>Atualizar Ordem de Coleta</p>
                  </button>
                </>
              )}
    
              {!!ordemColeta && !ordemColeta.arquivo && (
                <button onClick={() => atualizarInformacaoRomaneio()} title="Clique para atualizar">
                  <FaFileContract className="icone" />
                  <p>Gerando Ordem de Coleta...</p>
                </button>
              )}
    
              {!ordemColeta && (
                <button onClick={() => criarOrdemColeta(false)}>
                  <IoReload className="icone"></IoReload>
                  <p>Gerar Ordem de Coleta</p>
                </button>
              )}
            </div>
          </div>
        </>
      )}


      {idDocumentoSelecionado !== null && (
        <FormularioEditarRelatorio
          idDocumentoSelecionado={idDocumentoSelecionado}
          tipoDocumentoSelecionado={tipoDocumentoSelecionado}
          setIdDocumentoSelecionado={setIdDocumentoSelecionado}
          setTipoDocumentoSelecionado={setTipoDocumentoSelecionado}
          requisitarDocumentos={requisitarDocumentos}
        ></FormularioEditarRelatorio>
      )}

      {anexarDocumentoOpen && (
        <ModalAnexarDFE
          idCarga={idCarga}
          isOpen={anexarDocumentoOpen}
          onClose={handleCloseAnexarDocumento}
          title="Inserir Tipo de Documento"
          onAttach={() => requisitarDocumentos()}
          onRemoved={() => requisitarDocumentos()}
        />
      )}

      {modalDadosNfe && (
        <ModalDadosNfe
          idNfeSelecionada={idDocumentoVisualizar}
          modalDadosNfe={modalDadosNfe}
          handleModalDadosNfe={handleModalDadosNfe}
        />
      )}

      {modalDadosCte && (
        <ModalDadosCte
          idCteSelecionado={idDocumentoVisualizar}
          modalDadosCte={modalDadosCte}
          handleModalDadosCte={handleModalDadosCte}
          requisitarDocumentos={requisitarDocumentos}
          adicionarCtesParaNfes={adicionarCtesParaNfes}
          handleGerarCteComplementar={handleGerarCteComplementar}
        />
      )}

      {dadosNfeSelecionada && (
        <ModalGerarCte
          dadosNfeSelecionada={dadosNfeSelecionada}
          idCteSelecionado={idCteSelecionado}
          idColeta={idColetaSelecionado}
          tipoCteInicial={tipoCtePadrao}
          handleModalGerarCte={handleModalGerarCte}
          requisitarDocumentos={requisitarDocumentos}
          carga={carga}
        />
      )}

      {idCteAnulacaoRetificar && (
        <ModalAnularCte
          isOpen={!!idCteAnulacaoRetificar}
          onClose={() => setIdCteAnulacaoRetificar(null)}
          handleModalDadosCte={handleModalDadosCte}
          requisitarDocumentos={requisitarDocumentos}
          idAnulacao={idCteAnulacaoRetificar}
        />
      )}

      {gerarCteLote && (
        <ModalSelecionarNotas
          isOpen={gerarCteLote}
          idCarga={idCarga}
          onClose={() => setGerarCteLote(false)}
          onComplete={handleCompleteCteLote}
        />
      )}
    </div>
  );
};

export default memo(TabelaRelatorioCargas);
