import { Button } from '../../components/common';

export const Linha = ({ cliente, onConfirmSelect }) => {
  return (
    <div className="tabela-item">
      <div className="data">
        <div className="nomeCliente">{cliente?.nome_completo}</div>
        <div className="informations">
          <span><strong>CNPJ: </strong>{cliente.cnpj}</span>
          <span><strong>Endereço: </strong>{cliente.endereco.logradouro}, {cliente.endereco.municipio}, {cliente.endereco.uf}</span>
        </div>
      </div>
      <div className="action">
        <Button variant="ghost" type="button" onClick={() => onConfirmSelect(cliente)}>Importar</Button>
      </div>
    </div>
  );
}
