import './dashboard-header.scss';

export const DashboardHeader = ({ title, description, children }) => {
  return (
    <div className="dashboard-header">
      <div>
        <p className="title">{title}</p>
        <p className="subtitle">{description}</p>
      </div>

      <div className="actions-region">
        {children}
      </div>
    </div>
  )
}
