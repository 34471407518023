import { useCallback } from 'react';
import Modal from 'react-modal'
import { confirmAlert } from 'react-confirm-alert'
import { RiCloseCircleFill } from 'react-icons/ri'
import { GrAttachment } from 'react-icons/gr'
import Nfe from '../../../ModalPerfilMotoristaCarga/Financeiro/TabelaRelatorioCargas/ModalNovoDocumento/Nfe';
import api from "../../../../../../services/api"
import './styles.css';

const ModalIncluirNota = ({ isOpen, onClose, onGerarCteAvulso, dadosDocumentos, setDadosDocumentos }) => {
  const anexarDocumento = async (listaAnexos) => {
    try {
      if (listaAnexos.length > 0) {
        const promises = []
        for (const anexo of listaAnexos) {
          const formData = new FormData()
      
          const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))

          const idTransportadora = dadosUsuario.transportadora_id

          formData.append("transportadora", idTransportadora)
          formData.append("carga", '')
          formData.append("arquivo", anexo)
          promises.push(api.post(`documentos_fiscais/nfe/`, formData))
        }
        await Promise.all(promises).then((response) => {
          let listaDadosDocumentos = response.map(item => item.data)
          setDadosDocumentos((prevState) => ([...prevState, ...listaDadosDocumentos]))
          //requisitarDocumentos()
        })
      }
    } catch (err) {
      if (err.response?.data?.non_field_errors[0]) {
        confirmAlert({
          title: "Erro",
          message: `${err.response?.data?.non_field_errors[0]}`,
          overlayClassName: "alert-sucesso-nfe",
          buttons: [
            {
              label: "Ok"
            }
          ]
        })
      }

      console.log(err.response)
    }
  }

  const removerDocumento = useCallback((idDocumento) => {
    api.delete(`documentos_fiscais/nfe/${idDocumento}/`)
      .then(function () {
        setDadosDocumentos((prevState) => prevState.filter((doc) => doc.id !== idDocumento));
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-nova-carga"
      overlayClassName="overlay-modal-nova-carga"
      ariaHideApp={false}
    >
      <div className="container-nova-carga">
        <RiCloseCircleFill className="icone-fechar" onClick={onClose} />
        
        <p className="titulo">Inserir Nota Fiscal Avulsa</p>

        <div className="btn-upload">
          <label htmlFor="arquivo">
            <p>Anexar NF-e</p>
            <GrAttachment className="icone"></GrAttachment>
          </label>

          <input
            type="file"
            name="arquivo"
            accept='.xml'
            onChange={(e) => anexarDocumento(Array.from(e.target.files))}
            multiple
          />
        </div>

        {dadosDocumentos.map((documento) => (
          <Nfe
            key={documento.id}
            documento={documento}
            removerDocumento={removerDocumento}
            isDocumentoUnico={dadosDocumentos.length < 2 ? true : false}
            handleModalNovoDocumento={() => {}}
            requisitarDocumentos={() => {}}
          ></Nfe>
        ))}

        <div className="container-cotinue-cte">
          <button className="btn-continue-cte" onClick={onGerarCteAvulso}>
            Gerar CT-e Avulso
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalIncluirNota;
