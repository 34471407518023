import React, { memo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FaUserPlus } from 'react-icons/fa'
import { Suspense } from '../../../../components/utils';
import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import { TabelaData } from './TabelaData';

const routes = [
  { path: '/acesso', breadcrumb: 'Início' },
  { path: '/acesso/clientes/gerenciar', breadcrumb: 'Gerenciar Clientes' },
]

const GerenciarCliente = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const handleGoToNew = () => {
    if (!searchParams.get('cliente')) {
      return history.push("/acesso/clientes/tabelas/criar")
    }
    history.push({
      pathname: "/acesso/clientes/tabelas/criar",
      search: `?cliente=${searchParams.get('cliente')}`,
    })
  }

  return (
    <div className="container-gerenciar-destinatario">
      <BreadCrumbs routes={routes} />

      <div className="gerenciar-destinatario">
        <div className="container-topo">
          <div>
            <p className="titulo-pagina">Gerenciar Tabelas de Frete</p>
          </div>

          <button className="btn-novo-destinatario" onClick={handleGoToNew}>
            <FaUserPlus className="icone"></FaUserPlus>
            Nova Tabela
          </button>
        </div>

        <Suspense>
          <TabelaData />
        </Suspense>
      </div>
    </div>
  )
}

export default memo(GerenciarCliente)
