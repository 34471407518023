import React, { memo, useState, useEffect, useCallback } from "react";
import { FaRegIdCard } from "react-icons/fa";
import { parseISO, format } from "date-fns";

import "./styles.css";

import TabelaRelatorioCargas from "./TabelaRelatorioCargas";
import TabelaMDFe from "./TabelaMDFe";
import TabelaFretesReceber from "./TabelaFretesReceber";
import TabelaFretesPagar from "./TabelaFretesPagar";

import api from "../../../../../services/api";

const Financeiro = ({ idCarga, fretePago }) => {
  const [updateState, setUpdateState] = useState(false);
  const forceUpdate = useCallback(() => setUpdateState((state) => !state), []);

  const [carga, setCarga] = useState(null);
  const [totaisDocumentos, setTotaisDocumentos] = useState("");
  const [frete, setFretePago] = useState(null);
  const [mdfeBaixado, setMdfeBaixado] = useState(null);
  const [documentos, setDocumentos] = useState(null);
  const [manifestos, setManifestos] = useState([]);
  const [clientes, setClientes] = useState([]);

  const criarListaClientes = useCallback((arrayDados) => {
    const listaClientes = arrayDados.filter(
      (este, i) => arrayDados.indexOf(este) === i
    );

    setClientes(listaClientes);
  }, []);

  const requisitarInformacoesMdfe = useCallback(() => {
    api
      .get(`documentos_fiscais/mdfe/?carga=${idCarga}&status=Encerrado`)
      .then(function (response) {
        if (response.data.count > 0) {
          setMdfeBaixado(response.data.results[0]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idCarga]);

  const requisitarMdfes = useCallback(() => {
    api
      .get(`documentos_fiscais/mdfe/?carga=${idCarga}`)
      .then(function (response) {
        setManifestos(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idCarga]);

  const requisitarDocumentos = useCallback(() => {
    forceUpdate();
    setDocumentos([]);

    api
      .get(`documentos_fiscais/web/?carga=${idCarga}`)
      .then(function (response) {
        setDocumentos(response.data);
        setFretePago(response.data.frete_pago);

        let remetentesEDestinatarios = [];
        response.data?.tabela?.forEach((item) => {
          remetentesEDestinatarios.push(item.remetente_razao_social);
          remetentesEDestinatarios.push(item.destinatario_razao_social);
        });
        criarListaClientes(remetentesEDestinatarios);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [forceUpdate, criarListaClientes, idCarga]);

  const websocketAtualizacoesCargasPainel = useCallback(() => {
    let wsAtualizacoesCargasPainel = new WebSocket(
      `${process.env.REACT_APP_LINK_API_WEBSOCKET}ws/docs/notify/${idCarga}`
    );

    wsAtualizacoesCargasPainel.onopen = function () {
      console.log("websocketDocumentosFinanceiro conectado");
    };

    wsAtualizacoesCargasPainel.onmessage = function (e) {
      let JSONMessage = JSON.parse(e.data);

      if (JSONMessage.tipo === "atualizacao_status_mdfe") {
        requisitarMdfes();
        requisitarInformacoesMdfe();
      }

      if (JSONMessage.tipo === "atualizacao_status_cte") {
        requisitarDocumentos();
      }
    };

    wsAtualizacoesCargasPainel.onclose = function () {
      wsAtualizacoesCargasPainel = null;
      setTimeout(function () {
        websocketAtualizacoesCargasPainel();
      }, 5000);
    };
  }, [
    idCarga,
    requisitarInformacoesMdfe,
    requisitarDocumentos,
    requisitarMdfes,
  ]);

  useEffect(() => {
    api
      .get(`cargas/${idCarga}/`)
      .then(function (response) {
        setCarga(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idCarga]);

  useEffect(() => {
    api
      .get(`documentos_fiscais/web/?carga=${idCarga}`)
      .then(function (response) {
        setTotaisDocumentos(response.data.totals);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idCarga, updateState]);

  useEffect(() => {
    requisitarMdfes();
    requisitarInformacoesMdfe();
    requisitarDocumentos();
  }, [
    idCarga,
    requisitarInformacoesMdfe,
    requisitarDocumentos,
    requisitarMdfes,
  ]);

  useEffect(() => {
    websocketAtualizacoesCargasPainel();
  }, [websocketAtualizacoesCargasPainel]);

  return (
    <div className="modal-perfil-motorista-carga-financeiro">
      <div className="dados-pedido">
        <div className="informacoes">
          <div>
            <FaRegIdCard className="icone"></FaRegIdCard>
            <p>
              <span>ID:</span> {carga?.id}
            </p>
          </div>

          <p className="pedido">
            <span>Pedido:</span>
            {carga?.pedidos.map((item, index) =>
              index < carga.pedidos.length - 1
                ? `${item.numero}, `
                : ` ${item.numero}`
            )}
          </p>

          {
            carga?.motorista_em_viagem?.tipo_cadastro === "NoApp" && <p className="message-app">Cadastro de motorista sem utilização do APP, algumas funcionalidades não disponíveis</p>
          }
        </div>
      </div>

      <p className="titulo">Relatório de Carga</p>
      <TabelaRelatorioCargas
        idCarga={idCarga}
        documentos={documentos}
        requisitarDocumentos={requisitarDocumentos}
        manifestos={manifestos}
      ></TabelaRelatorioCargas>

      <p className="titulo mdf">MDF-e</p>
      <TabelaMDFe
        idCarga={idCarga}
        requisitarMdfes={requisitarMdfes}
        manifestos={manifestos}
      ></TabelaMDFe>

      <div className="tabelas-fretes">
        <div className="container-tabela">
          <p className="titulo">Frete a Receber</p>
          <TabelaFretesReceber
            idCarga={idCarga}
            updateState={updateState}
            clientes={clientes}
            requisitarDocumentos={requisitarDocumentos}
          ></TabelaFretesReceber>
        </div>

        <div className="container-tabela">
          <p className="titulo">Frete a Pagar</p>
          <TabelaFretesPagar
            idCarga={idCarga}
            updateState={updateState}
            requisitarDocumentos={requisitarDocumentos}
          ></TabelaFretesPagar>
        </div>
      </div>

      <div className="observacoes">
        <p>Observações Importantes</p>
      </div>

      <div className="container-opcoes">
        <div className="opcao">
          <p>
            MDF Baixado:
            {mdfeBaixado && (
              <span className="nome">
                {mdfeBaixado?.data_hora_encerrado
                  ? format(
                      parseISO(mdfeBaixado.data_hora_encerrado),
                      "dd/MM/yyyy HH:mm"
                    )
                  : ""}{" "}
                - {mdfeBaixado?.usuario_encerrado?.nome}
              </span>
            )}
          </p>

          <div className="container-input-checkbox">
            <span className="checkbox">
              <input type="checkbox" />
              <span className={`${mdfeBaixado && "checked"}`}></span>
            </span>
          </div>
        </div>

        <div className="opcao">
          <p>
            Frete Pago:
            {frete && (
              <span className="nome">
                {frete?.data
                  ? format(parseISO(frete.data), "dd/MM/yyyy HH:mm")
                  : ""}{" "}
                - {frete?.usuario?.nome}
              </span>
            )}
          </p>

          <div className="container-input-checkbox">
            <span className="checkbox">
              <input type="checkbox" />
              <span className={`${frete && "checked"}`}></span>
            </span>
          </div>
        </div>
      </div>

      <div className="informacoes">
        <p className="titulo">Informações</p>

        <div className="container-informacoes">
          <div className="quadro">
            <div>
              <p>Peso Total</p>
            </div>
            <div>
              <p>
                {totaisDocumentos.peso?.toLocaleString("pt-br", {
                  maximumFractionDigits: 2,
                })}{" "}
                Kg
              </p>
            </div>
          </div>

          <div className="quadro">
            <div>
              <p>Valor Total Frete</p>
            </div>
            <div>
              <p>
                R${" "}
                {totaisDocumentos.valor_total?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                })}
              </p>
            </div>
          </div>

          <div className="quadro">
            <div>
              <p>Valor Total Repasse</p>
            </div>
            <div>
              <p>
                R${" "}
                {totaisDocumentos.repasse_final?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                })}
              </p>
            </div>
          </div>

          <div className="quadro">
            <div>
              <p>Saldo Empresa</p>
            </div>
            <div>
              <p>
                R${" "}
                {totaisDocumentos.saldo?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Financeiro);
