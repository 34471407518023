import React from 'react';
import { Formik, Form } from 'formik';
import { useLocation, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { DashboardPage } from '../../../../components/layout/dashboard-page';
import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import { Suspense } from '../../../../components/utils';
import { Button } from "../../../../components/common";
import { ActionsBar } from "../../../../components/layout/actions-bar";
import { useCreateOrUpdateClientTableMutation } from '../../../../core/mutations';
import TabelaPrecos from "./TabelaPrecos";

const initialValues = {
  cliente: '',
  ativa: true,
  ida_e_volta: false,
  origem: {
    uf: '',
    municipio: '',
  },
  destino: {
    uf: '',
    municipio: '',
  },
  tipo_rota: 'UF_MUNICIPIO',
  tipo_tabela: 'PESO_KG',
  produto: {
    produto: null,
  },
  peso: {
    valor: 0,
    excedente: 0,
    tipo: 'PESO',
    pesos: [
      {
        peso_inicial: 0,
        peso_final: 0,
      }
    ],
    faixas: [
      {
        distancia_inicial: 0,
        distancia_final: 0,
        excedente: 0,
        valores: [
          {
            valor: 0,
          },
        ]
      }
    ],
  },
  cubagem: {
    base: 0,
    considerar_peso_cubagem: false,
  },
  volumes: {
    valor: 0,
  },
  nota_fiscal: {
    porcentagem: 0,
    valor_limite: 0,
    valor_por_minimo: 0,
    considerar_valor_maior_peso: false,
  },
  pallets: {
    valor: 0,
  },
  combinado_veiculos: [],
  generalidades: {
    gris: 0,
    gris_minimo: 0,
    seguro: 0,
    despacho: 0,
    outros: 0,
    pedagio: 0,
    pedagio_fracao_kg: 0,
    sec_cat: 0,
    sec_cat_limite: 0,
    sec_cat_excedente: 0,
    taxa_fixa: 0,
    taxa_fixa_limite: 0,
    taxa_fixa_excedente: 0,
    taxa_fixa_cobrar_a_partir: 0,
    frete_minimo: 0,
    desconsiderar_gris: false,
    desconsiderar_seguro: false,
    desconsiderar_despacho: false,
    desconsiderar_pedagio: false,
    desconsiderar_taxa_fixa: false,
    desconsiderar_sec_cat: false,
    desconsiderar_outros: false,
    tde: 0,
    reentrega: 0,
    devolucao: 0,
    incluir_icms: false,
    incluir_pis_cofins: false,
    desconsiderar_icms_pis_frete_minimo: false,
    prazo_entrega: 0,
  }
};

const NovaTabela = () => {
  const match = useRouteMatch();
  const isReadOnly = match.path === '/acesso/clientes/tabelas/visualizar/:id' ? true : false;
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const createTableMutation = useCreateOrUpdateClientTableMutation();
  const { id } = useParams();
  const history = useHistory();

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/clientes', breadcrumb: 'Clientes' },
    { path: '/acesso/clientes/tabelas', breadcrumb: 'Tabelas de Frete' },
    { path: `/acesso/clientes/tabelas/criar`, breadcrumb: 'Nova Tabela' },
    { path: `/acesso/clientes/tabelas/visualizar`, breadcrumb: null },
    { path: `/acesso/clientes/tabelas/visualizar/${id}`, breadcrumb: 'Ver Tabela' },
    { path: `/acesso/clientes/tabelas/editar`, breadcrumb: null },
    { path: `/acesso/clientes/tabelas/editar/${id}`, breadcrumb: 'Editar Tabela' },
  ];

  const handleSubmit = (rawData, actions) => {
    let faixas = [];
    for (const faixa of rawData.peso.faixas) {
      for (let i = 0; i < rawData.peso.pesos.length; i += 1) {
        if (faixa.excedente > 0) {
          faixas.push({
            peso_inicial: 0,
            peso_final: 0,
            distancia_inicial: faixa.distancia_inicial,
            distancia_final: faixa.distancia_final,
            valor: faixa.excedente,
            tipo: 'FIXO',
          });
        }
        faixas.push({
          peso_inicial: rawData.peso.pesos[i].peso_inicial,
          peso_final: rawData.peso.pesos[i].peso_final,
          distancia_inicial: faixa.distancia_inicial,
          distancia_final: faixa.distancia_final,
          valor: faixa.valores[i].valor,
          tipo: 'FIXO',
        });
      }
    }

    let data = {
      ...rawData,
      peso: {
        ...rawData.peso,
        faixas,
      },
    };
    createTableMutation.mutate(
      { id, data },
      {
        onError: (data) => actions.setErrors(data),
      }
    );
  }

  return (
    <DashboardPage>
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <Formik
        initialValues={{
          ...initialValues,
          cliente: searchParams.get('cliente') || '',
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <Suspense>
            <TabelaPrecos />

            {!isReadOnly && (
              <ActionsBar>
                <Button
                  variant="saveBack"
                  type="button"
                  disabled={createTableMutation.isLoading}
                  onClick={() => history.goBack()}
                >
                  Cancelar
                </Button>
                <Button
                  variant="save"
                  type="submit"
                  isLoading={createTableMutation.isLoading}
                  loadingText="Salvando..."
                >
                  Salvar
                </Button>
              </ActionsBar>
            )}
          </Suspense>
        </Form>
      </Formik>
    </DashboardPage>
  )
}

export default NovaTabela;
