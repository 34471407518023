import React, { memo } from 'react'
import { useCards } from '../CardsContext';
import CardEmTransito from "./CardEmTransito"
import ContainerCards from '../ContainerCards'
import './styles.css';

const QuadroEmTransito = () => {
  const { transito, setTransito, setEntregue, fetchTransito } = useCards();

  return (
    <div className="quadro em-transito">
      <div className="titulo">
        <p>EM TRÂNSITO</p>
      </div>

      <div className="detalhes">
        <div className="total" onClick={() => setTransito((prev) => ({ ...prev, filtroCarga: '' }))}>
          <p>Total: <span>{transito.painel.todas}</span></p>
        </div>
        <div className="prazo" onClick={() => setTransito((prev) => ({ ...prev, filtroCarga: 'no_prazo' }))}>
          <p>No Prazo: <span>{transito.painel.no_prazo}</span></p>
        </div>
        <div className="atraso" onClick={() => setTransito((prev) => ({ ...prev, filtroCarga: 'possivel_atraso' }))}>
          <p>Possível<br />Atraso: <span>{transito.painel.possivel_atraso}</span></p>
        </div>
        <div className="atrasado"  onClick={() => setTransito((prev) => ({ ...prev, filtroCarga: 'atrasado' }))}>
          <p>Entrega<br />Atrasada: <span>{transito.painel.atrasado}</span></p>
        </div>
      </div>

      <ContainerCards
        component={
          <CardEmTransito
            moverCarga={(id) => {
              console.log('MOVER ', id);
              const [carga] = transito.cargas.filter((item) => item.id === id);
              setTransito((prev) => ({
                ...prev,
                cargas: prev.cargas.filter((item) => item.id !== id),
                total: prev.total - 1,
              }));
              setEntregue((prev) => ({
                ...prev,
                cargas: [carga, ...prev.cargas],
                total: prev.total + 1,
              }))
            }}
          />
        }
        cargas={transito.cargas}
        total={transito.total}
        loading={transito.loading}
        onLoadMore={() => {
          fetchTransito(transito.offset + 10);
        }}
      />
    </div>
  )
}

export default memo(QuadroEmTransito);
