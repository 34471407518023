import './styles.css'

export function LoadingAnimated({ styles }) {
  return (
    <div style={styles} className='lds_ring'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
