import React, { memo } from 'react'
import { RiCloseCircleFill } from 'react-icons/ri'
import Modal from 'react-modal'
import { parseISO, format } from 'date-fns'

import './styles.css'

const ModalNotas = ({ notasTransportadas, modalNotas, handleModalNotas }) => {
  return (
    <Modal
      isOpen={modalNotas}
      onRequestClose={handleModalNotas}
      className="modal-notas"
      overlayClassName="overlay-modal-notas"
      ariaHideApp={false}
    >
      <div className="notas">
        <RiCloseCircleFill className="icone-fechar" onClick={handleModalNotas}></RiCloseCircleFill>

        <p className="titulo">Dados da NF-e</p>

        {notasTransportadas.length > 0
          ? notasTransportadas.map(nota => (
            <div className="container-formulario">
              <div className="container-inputs-3col">
                <div className="container-input-comum">
                  <input
                    required
                    type="text"
                    disabled
                    defaultValue={nota.nfe.numero}
                  />
                  <span>Número</span>
                </div>

                <div className="container-input-comum">
                  <input
                    required
                    type="text"
                    disabled
                    defaultValue={nota.nfe.serie}
                  />
                  <span>Série</span>
                </div>

                <div className="container-input-comum">
                  <input
                    required
                    type="text"
                    disabled
                    defaultValue={nota.nfe.emissao ? format(parseISO(nota.nfe.emissao), "dd/MM/yyyy HH:mm") : ''}
                  />
                  <span>Emissão</span>
                </div>
              </div>


              <div className="container-inputs-3col">
                <div className="container-input-comum">
                  <input
                    required
                    type="text"
                    defaultValue={nota.nfe.valor}
                    disabled
                  />
                  <span>Valor</span>
                </div>

                <div className="container-input-comum">
                  <input
                    required
                    type="number"
                    disabled
                    defaultValue={nota.nfe.peso}
                  />
                  <span>Peso</span>
                </div>

                <div className="container-input-comum">
                  <input
                    required
                    type="number"
                    disabled
                    defaultValue={nota.nfe.volume}
                  />
                  <span>Volume</span>
                </div>
              </div>

              <div className="container-inputs-2col">
                <div className="container-input-comum">
                  <input
                    required
                    type="text"
                    disabled
                    defaultValue={nota.nfe.cfop}
                  />
                  <span>CFOP</span>
                </div>

                <div className="container-input-comum">
                  <input
                    required
                    type="text"
                    disabled
                    defaultValue={nota.nfe.chave}
                  />
                  <span>Chave de Acesso NF-e</span>
                </div>
              </div>

              <div className="container-inputs-3col">
                <div className="container-input-comum">
                  <input
                    required
                    type="text"
                    disabled
                    defaultValue={nota.nfe.remetente_razao_social}
                  />
                  <span>Razão Social Emitente</span>
                </div>

                <div className="container-input-comum">
                  <input
                    required
                    type="text"
                    disabled
                    defaultValue={nota.nfe.destinatario_razao_social}
                  />
                  <span>Razão Social Destinatário</span>
                </div>

                <div className="container-input-comum">
                  <input
                    required
                    type="text"
                    disabled
                    defaultValue={`${nota.nfe?.remetente_endereco?.nome_municipio}-${nota.nfe?.remetente_endereco?.uf}`}
                  />
                  <span>Origem - Cidade UF</span>
                </div>
              </div>

              <div className="container-inputs-3col">
                <div className="container-input-comum">
                  <input
                    required
                    type="text"
                    disabled
                    defaultValue={`${nota.nfe?.destinatario_endereco?.nome_municipio}-${nota.nfe?.destinatario_endereco?.uf}`}
                  />
                  <span>Destino - Cidade UF</span>
                </div>
              </div>
            </div>
          ))
          : <p className='lista-vazia'>Nenhuma nota transportada!</p>
        }
      </div>
    </Modal>
  )
}

export default memo(ModalNotas)