import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { useMessages } from '../../../context/messages-context';

export const useCreateOrUpdateClientMutation = () => {
  const { showApiErrors } = useMessages();
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation(async ({ id, data }) => {
    try {
      if (id) {
        await api.patch(`/fracionado/clientes/${id}/`, data);
      } else {
        await api.post(`/fracionado/clientes/`, data);
      }
    } catch (err) {
      if (err?.response?.status === 400 && err?.response?.data) {
        throw err?.response?.data;
      }
      showApiErrors(err);
      return null;
    }

    await queryClient.invalidateQueries(['clients']);
    await queryClient.refetchQueries(['clients', '', 1]);
    history.push('/acesso/clientes/gerenciar');
  });
}
