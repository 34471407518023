import React, { memo, useState, useCallback } from 'react'
import { FaRegCalendarAlt, FaTag, FaTruck, FaUserTie } from 'react-icons/fa'
import { FiTruck } from 'react-icons/fi'
import { BsCheckLg } from 'react-icons/bs'

import '../styles.css'
import './styles.css'

import ModalPerfilMotoristaCarga from '../../../../ModalPerfilMotoristaCarga'

const CardEmbarcado = () => {
  const [modalPerfilMotoristaCarga, setModalPerfilMotoristaCarga] = useState(false)

  const handleModalPerfilMotoristaCarga = useCallback(() => {
    if (modalPerfilMotoristaCarga) {
      setModalPerfilMotoristaCarga(false)
      document.querySelector("body").style.overflow = "visible"
    } else {
      setModalPerfilMotoristaCarga(true)
      document.querySelector("body").style.overflow = "hidden"
    }
  }, [modalPerfilMotoristaCarga])

  return (
    <div className="card-agendamento embarcado" >
      <div className="linha">
        <div className="id">
          <p><span>ID:</span> 123456</p>
        </div>

        <div className="pedido">
          <p><span>Pedido: </span>987464</p>
        </div>

        <div className="transportadora">
          <FiTruck className="icone"></FiTruck>
          <p>Transportadora </p>
        </div>
      </div>

      <div className="linha">
        <div className="carga">
          <FaTag className="icone"></FaTag>
          <p>Lajes</p>
        </div>

        <div className="cidade">
          <p>Anápolis-GO / Guarulhos-SP</p>
        </div>
      </div>

      <div className="linha">
        <div className="caminhao">
          <FaTruck className="icone"></FaTruck>
          <p>Carreta Graneleiro - 5 Eixos</p>
        </div>

        <div className="embarque">
          <FaRegCalendarAlt className="icone"></FaRegCalendarAlt>
          <p><span>Embarque: </span>10/08 às 8:30</p>
        </div>
      </div>

      <div className="linha">
        <div className="dados-motorista">
          <FaUserTie className="icone"></FaUserTie>
          <div>
            <p className="nome">João Alberto Costa</p>
            <p>CPF: 062.7894.654-95</p>
            <p>KVJ-6132 / KNB-8765</p>
          </div>
        </div>

        <button className="liberar-viagem" onClick={handleModalPerfilMotoristaCarga}>
          <BsCheckLg className="icone"></BsCheckLg>
          <p>Liberar Viagem</p>
        </button>
      </div>

      {modalPerfilMotoristaCarga &&
        <ModalPerfilMotoristaCarga
          modalPerfilMotoristaCarga={modalPerfilMotoristaCarga}
          handleModalPerfilMotoristaCarga={handleModalPerfilMotoristaCarga}
        ></ModalPerfilMotoristaCarga>
      }
    </div>
  )
}

export default memo(CardEmbarcado)