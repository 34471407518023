import React, { memo, useState, useCallback } from 'react'

import './styles.css'

import registro from "../../../../assets/registro.png"

import ModalPerfilMotoristaCarga from '../../../../pages/plataformaAcessada/PainelMonitoramento/ModalPerfilMotoristaCarga'

import api from '../../../../services/api'

const NotificacaoNovoRegistro = ({ forceRender, carga, closeToast, idNotificacao }) => {
  const [modalPerfilMotoristaCarga, setModalPerfilMotoristaCarga] = useState(false)

  const registrarNotificacaoVisualizada = useCallback(async () => {
    await api.patch(`empresa_notificacoes/${idNotificacao}/`, {
      visualizado: true
    })
      .then(function () {
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idNotificacao])

  const handleModalPerfilMotoristaCarga = useCallback(() => {
    if (modalPerfilMotoristaCarga) {
      setModalPerfilMotoristaCarga(false)
      document.querySelector("body").style.overflow = "visible"
      closeToast()
    } else {
      registrarNotificacaoVisualizada()
      setModalPerfilMotoristaCarga(true)
      document.querySelector("body").style.overflow = "hidden"
    }
  }, [modalPerfilMotoristaCarga, closeToast, registrarNotificacaoVisualizada])

  return (
    <div className="notificacao-novo-registro">
      <img src={registro} alt="" />

      <div className="conteudo">
        <p>
          O motorista {carga.motorista_em_viagem.nome_completo}, enviou um novo registro na carga de ID
          {carga.id}, com o destino para
          {carga.pontos_parada.map((item, index) => {
            return (
              index !== 0
                ? ` | ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
                : ` ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
            )
          })}.
        </p>

        <button onClick={handleModalPerfilMotoristaCarga}>Visualizar</button>
      </div>

      {modalPerfilMotoristaCarga &&
        <ModalPerfilMotoristaCarga
          modalPerfilMotoristaCarga={modalPerfilMotoristaCarga}
          handleModalPerfilMotoristaCarga={handleModalPerfilMotoristaCarga}
          idCarga={carga.id}
          moverCarga={forceRender}
          closeToast={closeToast}
        ></ModalPerfilMotoristaCarga>
      }
    </div>
  )
}

export default memo(NotificacaoNovoRegistro)