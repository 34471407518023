import React, { Fragment } from 'react';
import { useRouteMatch } from 'react-router-dom'
import { useFormikContext } from 'formik';
import { MdAdd, MdDelete } from 'react-icons/md'
import { Input, InputContainer, Select } from '../../../../../../components/forms';
import { Button } from '../../../../../../components/common';
import './TabelaPesoFaixas.scss';

export const TabelaPesoFaixas = () => {
  const match = useRouteMatch();
  const isReadOnly = match.path === '/acesso/clientes/tabelas/visualizar/:id' ? true : false;
  const { values, setFieldValue } = useFormikContext();

  const handleAddFaixa = () => {
    const { faixas, pesos } = values.peso;

    let distancia_inicial = 0
    if (faixas.length > 0) {
      distancia_inicial = faixas[faixas.length - 1].distancia_final
    }
    
    faixas.push({
      distancia_inicial,
      distancia_final: 0,
      valores: Array.from({ length: pesos.length }).map((_) => 0),
    });

    setFieldValue('peso.faixas', faixas);
  };

  const handleRemoveFaixa = (index) => {
    const { faixas } = values.peso;
    setFieldValue('peso.faixas', faixas.filter((_, subIndex) => subIndex !== index));
  }

  const handleRemovePeso = (index) => {
    const { faixas, pesos } = values.peso;

    setFieldValue('peso.pesos', pesos.filter((_, ind) => ind !== index))
    setFieldValue('peso.faixas', faixas.map((item) => {
      return {
        ...item,
        valores: item.valores.filter((_, ind) => ind !== index),
      };
    }));
  }

  const handleAddPeso = () => {
    const { faixas, pesos } = values.peso;
    
    pesos.push({
      peso_inicial: 0,
      peso_final: 0,
    })
    setFieldValue('peso.pesos', pesos)
    setFieldValue('peso.faixas', faixas.map((item) => {
      const { valores } = item;
      valores.push(0);

      return {
        ...item,
        valores,
      };
    }));
  }
  
  return (
    <table className="tabela-faixas-peso">
      <thead>
        <tr>
          <th colSpan={2}>Percurso</th>
          <th colSpan={values.peso.pesos.length + 3}>Peso</th>
        </tr>
        <tr>
          <th width="100px" style={{ minWidth: 100, }}>De (km)</th>
          <th width="100px" style={{ minWidth: 100, }}>Até (km)</th>
          {values.peso.pesos.map((item, index) => (
            <Fragment key={index}>
              <th>
                <div className="inline-header">
                  De (kg)
                  <Input
                    type="number"
                    name={`peso.pesos.${index}.peso_inicial`}
                    disabled={isReadOnly}
                    small
                  />
                  Até (kg)
                  <Input
                    type="number"
                    name={`peso.pesos.${index}.peso_final`}
                    disabled={isReadOnly}
                    small
                  />

                  <span style={{ cursor: 'pointer' }} onClick={() => handleRemovePeso(index)}>
                    <MdDelete size={22} />
                  </span>
                </div>
              </th>
            </Fragment>
          ))}
          <th width="30">
            <Button
              disabled={isReadOnly}
              type="button"
              variant="ghost"
              title="Adicionar Nova Faixa de Peso"
              onClick={handleAddPeso}
            >
              <MdAdd size={22} />
            </Button>
          </th>
          <th>Excedente (R$/kg)</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {values.peso.faixas.map((item, index) => (
          <tr key={index}>
            <td>
              <Input
                type="number"
                name={`peso.faixas.${index}.distancia_inicial`}
                disabled={isReadOnly}
                small
              />
            </td>
            <td>
              <Input
                type="number"
                name={`peso.faixas.${index}.distancia_final`}
                disabled={isReadOnly}
                small
              />
            </td>
            {item.valores.map((subitem, subindex) => (
              <td key={subindex}>
                <Input
                  type="number"
                  name={`peso.faixas.${index}.valores.${subindex}.valor`}
                  disabled={isReadOnly}
                  small
                />
              </td>
            ))}
            <td></td>
            <td>
              <Input
                type="number"
                name={`peso.faixas.${index}.excedente`}
                disabled={isReadOnly}
                small
              />
            </td>
            {/*
            <td>
              <Input
                type="number"
                name={`peso.faixas.${index}.peso_inicial`}
                disabled={isReadOnly}
                small
              />
            </td>
            <td>
              <Input
                type="number"
                name={`peso.faixas.${index}.peso_final`}
                disabled={isReadOnly}
                small
              />
            </td>
            <td>
              <Input
                type="number"
                name={`peso.faixas.${index}.valor`}
                disabled={isReadOnly}
                small
              />
            </td>
            <td>
              <InputContainer width="100px">
                <Select
                  name={`peso.faixas.${index}.tipo`}
                  label="Tipo"
                  disabled={isReadOnly}
                  options={[
                    {
                      label: 'Fixo',
                      value: 'FIXO'
                    },
                    {
                      label: 'Por Kg',
                      value: 'POR_KG'
                    }
                  ]}
                  small
                />
              </InputContainer>
            </td>
            */}
            <td>
              <Button 
                disabled={isReadOnly}
                type="button"
                variant="ghost"
                title="Remover Faixa"
                onClick={() => handleRemoveFaixa(index)}
              >
                <MdDelete size={22} />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={values.peso.pesos.length + 5}>
            <Button
              disabled={isReadOnly}
              type="button"
              variant="ghost"
              title="Adicionar Nova Faixa"
              onClick={handleAddFaixa}
            >
              <MdAdd size={22} />
            </Button>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

