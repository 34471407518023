import React, { useState } from 'react';
import clsx from 'clsx';
import { useField } from 'formik';
import ReactSelect from 'react-select';
import '../input/input.scss';
import './select.scss';

export const Select = ({
  label,
  small,
  options,
  white,
  borderless,
  ...props
}) => {
  const [rawField, meta, helpers] = useField(props);
  const value = options.find((item) => item.value === rawField.value);
  const [searchInputValue, setSearchInputValue] = useState('');

  const field = {
    ...rawField,
    onChange: (e) => {
      const { value } = e;
      helpers.setValue(value);
    },
    value,
  }

  return (
    <div className="form-input-container">
      <div
        className={clsx({
          'form-select': true,
          'small': !!small,
          'with-value': !!field.value,
          'with-search': !!searchInputValue,
          'white': !!white,
          'borderless': !!borderless,
        })}
      >
        <ReactSelect
          options={options}
          placeholder={`${label}${props.required ? ' *' : ''}`}
          classNamePrefix="form_select"
          isDisabled={props.disabled || props.isDisabled}
          menuPosition="fixed"
          menuPortalTarget={document.body}
          onInputChange={setSearchInputValue}
          debounceTimeout={400}
          {...field}
          {...props}
        />
        {label && (<span>{label} {props.required && <i>*</i>}</span>)}
      </div>
      {meta.touched && meta.error && (
        <span className="form-input-error">{meta.error}</span>
      )}
    </div>
  );
}
