import { useFormikContext } from 'formik';
import { useRouteMatch } from 'react-router-dom'
import { Input, Select, InputContainer } from '../../../../../../components/forms';
import './TabelaValorCombinado.scss';

export const TabelaValorCombinado = () => {
  const match = useRouteMatch();
  const isReadOnly = match.path === '/acesso/clientes/tabelas/visualizar/:id' ? true : false;
  const { values } = useFormikContext();

  return (
    <table className="tabela-valor-combinado">
      <thead>
        <tr>
          <th className="th-table-child">Tipo Veiculo</th>
          <th className="th-table-child">Valor</th>
          <th className="th-table-child">Tipo</th>
          <th className="th-table-child">Pedágio</th>
        </tr>
      </thead>
      <tbody>
        {values.combinado_veiculos.map((item, index) => (
          <tr key={item.veiculo}>
            <td className='veiculo-line'>
              {item.veiculo_nome}
            </td>
            <td>
              <InputContainer width="80px">
                <Input disabled={isReadOnly} type="number" name={`combinado_veiculos.${index}.valor`} small />
              </InputContainer>
            </td>
            <td>
              <InputContainer width="100px">
                <Select
                  name={`combinado_veiculos.${index}.tipo`}
                  label="Tipo"
                  disabled={isReadOnly}
                  options={[
                    {
                      label: 'Fixo',
                      value: 'FIXO'
                    },
                    {
                      label: 'Por Kg',
                      value: 'POR_KG'
                    }
                  ]}
                  small
                />
              </InputContainer>
            </td>
            <td>
              <div className='pedagio-line'>
                <InputContainer width="60px">
                  <Input disabled={isReadOnly} type="number" name={`combinado_veiculos.${index}.pedagio`} small />
                </InputContainer>
                a cada
                <InputContainer width="60px">
                  <Input disabled={isReadOnly} type="number" name={`combinado_veiculos.${index}.pedagio_km`} small />
                </InputContainer>
                kg ou fração.
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
};
