import React, { useState, useEffect, memo } from "react";
import Select from 'react-select';
import { useFormik } from "formik";
import {useMessages} from '../../../../../../../../context/messages-context';
import { confirmAlert } from 'react-confirm-alert';

import "./styles.css";

import api from "../../../../../../../../services/api";
import ted from "../../../../../../../../assets/ted.png";
import pix from "../../../../../../../../assets/pix.png";

const Pagamentos = ({ id, dadosBancarios, editable, onSaved }) => {
  const { showApiErrors } = useMessages();
  const [bancos, setBancos] = useState([]);
  const formik = useFormik({
    initialValues: {
      dados_bancarios: {
        banco: '',
        agencia: '',
        titular: '',
        conta: '',
        tipo_pix: '',
        chave_pix: '',
        tipo_conta: '',
      },
    }
  });
  const motoristaTitles = {
    'dados_bancarios.banco': 'Banco',
    'dados_bancarios.agencia': 'Agência',
    'dados_bancarios.conta': 'Conta',
    'dados_bancarios.tipo_pix': 'Tipo da Chave Pix',
    'dados_bancarios.chave_pix': 'Chave Pix',
    'dados_bancarios.tipo_conta': 'Tipo da Conta',
  }

  useEffect(() => {
    formik.setValues({
      ...formik.initialValues,
      dados_bancarios: {...dadosBancarios},
    })
  }, [dadosBancarios]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await api.get('/banks');
        setBancos(data);
      } catch {}
    };
    fetch();
  }, []);

  const desabilitarInputs = !editable;
  const inputClassName = editable ? 'guep-editable' : '';

  const handleSaveData = async () => {
    try {
      await api.patch(`/motorista/${id}/`, formik.values);
      onSaved();
      confirmAlert({
        title: "Salvo!",
        message: `Dados do Motorista Salvos!`,
        overlayClassName: "alert-remover-nfe",
        buttons: [
          {
            label: "Ok",
            className: "botao-cancelar"
          }
        ]
      })
    } catch (err) {
      showApiErrors(err, motoristaTitles);
    }
  }

  return (
    <div className="container-pagamentos">
      <p className="titulo">Preferências de Pagamento:</p>

      <div className="container-informacoes-pagamento">
        <div className="titulo">
          <img src={ted} alt="" />
          <p>TED</p>
        </div>

        <div className="col-4">
          <label>
            Banco <i>*</i>

            <Select
              required
              className={`banco ${inputClassName}`}
              type="text"
              value={{
                value: formik.values.dados_bancarios.banco,
                label: formik.values.dados_bancarios.banco
              }}
              id="dados_bancarios.banco"
              onChange={(item) => formik.setFieldValue('dados_bancarios.banco', item.value)}
              disabled={desabilitarInputs}
              options={bancos.map((item) => ({
                value: item.full_name,
                label: item.full_name,
              }))}
            />
          </label>

          <label>
            AG <i>*</i>
            <input
              required
              type="text"
              value={formik.values.dados_bancarios.agencia}
              id="dados_bancarios.agencia"
              onChange={formik.handleChange}
              className={inputClassName}
              disabled={desabilitarInputs}
            />
          </label>

          <label>
            Tipo Conta <i>*</i>
            <select
              required
              type="text"
              value={formik.values.dados_bancarios.tipo_conta}
              id="dados_bancarios.tipo_conta"
              onChange={formik.handleChange}
              className={inputClassName}
              disabled={desabilitarInputs}
            >
              <option value="" disabled></option>
              <option value="corrente">Conta Corrente</option>
              <option value="poupanca">Conta Poupança</option>
            </select>
          </label>

          <label>
            Conta <i>*</i>
            <input
              required
              type="text"
              value={formik.values.dados_bancarios.conta}
              id="dados_bancarios.conta"
              onChange={formik.handleChange}
              className={inputClassName}
              disabled={desabilitarInputs}
            />
          </label>

          <label>
          Nome Completo do Titular <i>*</i>
            <input
              required
              type="text"
              value={formik.values.dados_bancarios.titular}
              id="dados_bancarios.titular"
              onChange={formik.handleChange}
              className={inputClassName}
              disabled={desabilitarInputs}
            />
          </label>
        </div>
      </div>

      <div className="container-informacoes-pagamento">
        <div className="titulo">
          <img src={pix} alt="" />
          <p>PIX</p>
        </div>

        <div className="col-4">
          <label>
            Tipo PIX <i>*</i>
            <select
              required
              type="text"
              className={inputClassName}
              value={formik.values.dados_bancarios.tipo_pix}
              id="dados_bancarios.tipo_pix"
              onChange={formik.handleChange}
              disabled={desabilitarInputs}
            >
              <option value="" disabled></option>
              <option value="cpf">CPF</option>
              <option value="cnpj">CNPJ</option>
              <option value="email">E-mail</option>
              <option value="telefone">Telefone</option>
              <option value="aleatória">Aleatória</option>
              <option value="sem-pix">Sem PIX</option>
            </select>
          </label>

          <label>
            Chave PIX
            <input
              type="text"
              className={inputClassName}
              value={formik.values.dados_bancarios.chave_pix}
              id="dados_bancarios.chave_pix"
              onChange={formik.handleChange}
              disabled={desabilitarInputs}
            />
          </label>
        </div>
      </div>


      <div className="submit-row">
        <button
          type="button"
          className="btn salvar"
          onClick={handleSaveData}
        >
          SALVAR
        </button>
      </div>
    </div>
  );
};

export default memo(Pagamentos);
