import React, { memo, useState, useCallback } from 'react'

import './styles.css'

import caminhao from "../../../../assets/truck_blue.png"

import ModalPropostaCarga from '../../../../pages/plataformaAcessada/PainelMonitoramento/Painel/Quadros/NovasCargas/ModalPropostaCarga'

import api from '../../../../services/api'

const NotificacaoNovaCandidatura = ({ forceRender, dadosCandidatura, closeToast, idNotificacao }) => {
  const [modalPropostaCarga, setModalPropostaCarga] = useState(false)

  const registrarNotificacaoVisualizada = useCallback(async () => {
    await api.patch(`empresa_notificacoes/${idNotificacao}/`, {
      visualizado: true
    })
      .then(function () {
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idNotificacao])

  const handleModalPropostaCarga = useCallback(() => {
    if (modalPropostaCarga) {
      setModalPropostaCarga(false)
      document.querySelector("body").style.overflow = "visible"
      closeToast()
    } else {
      registrarNotificacaoVisualizada()
      setModalPropostaCarga(true)
      document.querySelector("body").style.overflow = "hidden"
    }
  }, [modalPropostaCarga, closeToast, registrarNotificacaoVisualizada])

  return (
    <div className="notificacao-nova-candidatura">
      <img src={caminhao} alt="" />

      <div className="conteudo">
        <p>
          Motorista {dadosCandidatura.candidatura_item.motorista.nome_completo} se candidatou a carga
          {dadosCandidatura.carga.id} para {dadosCandidatura.carga?.pontos_parada.map((item, index) => {
            return (
              index !== 0
                ? ` | ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
                : ` ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
            )
          })}.
        </p>

        <button onClick={handleModalPropostaCarga}>Visualizar</button>
      </div>

      {modalPropostaCarga &&
        <ModalPropostaCarga
          atualizarCargas={() => { }}
          moverCarga={forceRender}
          carga={dadosCandidatura.carga}
          modalPropostaCarga={modalPropostaCarga}
          handleModalPropostaCarga={handleModalPropostaCarga}
        ></ModalPropostaCarga>
      }
    </div>
  )
}

export default memo(NotificacaoNovaCandidatura)