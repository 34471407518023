import React, { memo, useState, useEffect, useMemo } from "react";
import Modal from "react-modal";
import InputMask from 'react-input-mask';
import { useFormik } from 'formik';
import { RiCloseCircleFill } from "react-icons/ri";
import { confirmAlert } from 'react-confirm-alert';
import DocumentoDono from '../../../../../../../../components/formularios/DocumentoDono'
import InputSearchVeiculoCarroceria from '../../../../../../../../components/plataformaAcessada/InputSearchVeiculoCarroceria'
import InputCPFouCNPJ from '../../../../../../../../components/formularios/InputCPFouCNPJ'
import UFSelectOptions from '../../../../../../../../components/formularios/UFSelectOptions';
import {mergeDeep} from '../../../../../../../../utils/deep-merge';
import {useMessages} from '../../../../../../../../context/messages-context';

import "./styles.css";

import api from "../../../../../../../../services/api";

const InformacoesMotorista = ({
  motorista,
  editable,
  idCandidaturaRetificar,
  handleModalPerfilMotorista,
  onSaved,
}) => {
  const { showApiErrors } = useMessages();

  const [expandirInfoVeiculo, setExpandirInfoVeiculo] = useState(false);
  const [modalVisualizarFotos, setModalVisualizarFotos] = useState(null);
  const [imagensVeiculo, setImagensVeiculo] = useState([]);

  const [errosGuepRaw, setErrosGuepRaw] = useState([]);
  const [errosValidacao, setErrosValidacao] = useState([]);
  const [tiposVeiculos, setTiposVeiculos] = useState([]);
  const [tipoCarroceiras, setTiposCarroceria] = useState([]);
  const [erroNaoParametro, setErroNaoParametro] = useState(false);

  
  useEffect(() => {
    if (!editable) {
      return;
    }
    const fetchData = async () => {
      try {
        const { data } = await api.get(`/gr/erro-nao-parametro/by-motorista?motorista=${motorista.id}`);
        
        if (!data || !data.error) {
          setErroNaoParametro(false);
          return;
        }
        setErroNaoParametro(true);
      } catch {}
    };

    fetchData();
  }, [editable, motorista])

  useEffect(() => {
    api.get(`tipoveiculo/`)
      .then(function (response) {
        setTiposVeiculos(response.data.results)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, []);

  useEffect(() => {
    api.get(`tipocarroceria/`)
      .then(function (response) {
        setTiposCarroceria(response.data.results)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, []);

  const motoristaForm = useFormik({
    initialValues: {
      nome_completo: '',
      data_nascimento: '',
      sexo: 'M',
      telefone1: '',
      telefone2: '',
      documentos: {
        cpf: '',
        nome_mae: '',
        nome_pai: '',
        rg: '',
        rg_uf: '',
        rg_orgao_expedidor: '',
        cnh: '',
        cnh_uf: '',
        categoria: '',
        cnh_primeira: '',
        cnh_validade: '',
        cnh_seguranca: '',
        pis_pasep: '',
      },
      endereco: {
        pais: 'Brasil',
        uf: '',
        municipio: '',
        logradouro: '',
        bairro: '',
        numero: '',
        cep: '',
        complemento: '',
      },
    }
  });
  const motoristaTitles = {
    data_nascimento: 'Data de Nascimento',
    sexo: 'Sexo',
    'documentos.cpf': 'CPF',
    'documentos.nome_mae': 'Nome da Mãe',
    'documentos.nome_pai': 'Nome do Pai',
    'documentos.rg': 'RG',
    'documentos.rg_uf': 'UF do RG',
    'documentos.cnh': 'CNH',
    'documentos.cnh_uf': 'UF da CNH',
    'documentos.categoria': 'Categoria da CNH',
    'documentos.cnh_primeira': 'Data da Primeira CNH',
    'documentos.cnh_validade': 'Validade da CNH',
    'documentos.cnh_seguranca': 'Código de Segurança da CNH',
    'nome_completo': 'Nome Completo',
    telefone1: 'Contato 1',
    telefone2: 'Contato 2',
    'documentos.rg_orgao_expedidor': 'Órgão Expedidor do RG',
    'documentos.pis_pasep': 'PIS/PASEP',
    'endereco.pais': 'Pais',
    'endereco.uf': 'UF',
    'endereco.municipio': 'Municipio',
    'endereco.logradouro': 'Logradouro',
    'endereco.bairro': 'Bairro',
    'endereco.numero': 'Número',
    'endereco.cep': 'CEP',
    'endereco.complemento': 'Complemento',
  }

  const veiculoForm = useFormik({
    initialValues: {
      placa_cavalo: '',
      marca_cavalo: '',
      rntrc: '',
      ano_fabricacao_cavalo: '',
      nome_dono: '',
      tipo_pessoa_dono: 'PF',
      cpf_cnpj_dono: '',
      renavam_cavalo: '',
      uf: '',
      antt_cavalo: '',
      tipo_veiculo: '',
      tipo_veiculo_nome: '',
      marca_rastreador: '',
      id_rastreador: '',
      status_rastreador: '',
      quantidade_eixos: '',
    }
  });
  const veiculoTitles = {
    placa_cavalo: 'Placa do Veículo',
    marca_cavalo: 'Marca do Cavalo',
    rntrc: 'RNTRC',
    ano_fabricacao_cavalo: 'Ano de Fabricação do Cavalo',
    nome_dono: 'Nome do Dono',
    tipo_pessoa_dono: 'Tipo de Pessoa do Dono do Veículo',
    cpf_cnpj_dono: 'CPF/CNPJ do Dono do Veículo',
    renavam_cavalo: 'Renavam do Veículo',
    uf: 'UF do Veículo',
    antt_cavalo: 'Documento da ANTT do Veículo',
    tipo_veiculo: 'Tipo do Veículo',
    marca_rastreador: 'Marca do Rastreador',
    id_rastreador: 'ID do Rastreador',
    status_rastreador: 'Status do Rastreador',
    quantidade_eixos: 'Quantidade de Eixos'
  }

  const carroceriasForm = useFormik({
    initialValues: {
      carrocerias: [],
    },
  });

  const {canEdit, inputClassName} = useMemo(() => {
    if (!editable) {
      return {
        canEdit: false,
        inputClassName: ''
      }
    }
    return {
      canEdit: true,
      inputClassName: 'guep-editable'
    };
  }, [editable]);

  useEffect(() => {
    if (!editable) {
      return;
    }
    const fetchData = async () => {
      try {
        const { data } = await api.get(`/gr/correcoes/by-motorista?motorista=${motorista.id}`);

        const errors = mergeDeep(...data.map((item) => item.error_data));
        const { motorista: errorsMotorista, veiculo: errorsVeiculo } = errors;
        
        const saveErrors = []
        for (const key of Object.keys(errorsMotorista)) {
          if (!errorsMotorista[key][0]) {
            continue;
          }
          const { label, message } = errorsMotorista[key][0];
          saveErrors.push({ label, message });
        }
        for (const key of Object.keys(errorsVeiculo)) {
          if (!errorsVeiculo[key][0]) {
            continue;
          }
          const { label, message } = errorsVeiculo[key][0];
          saveErrors.push({ label, message });
        }

        setErrosGuepRaw(data);
        setErrosValidacao(saveErrors);
      } catch {}
    };

    fetchData();
  }, [editable, motorista])

  useEffect(() => {
    if (!motorista) {
      motoristaForm.setValues(motoristaForm.initialValues);
      return;
    }

    motoristaForm.setValues({
      data_nascimento: motorista.data_nascimento || '',
      sexo: motorista.sexo || '',
      nome_completo: motorista.nome_completo || '',
      telefone1: motorista.telefone1 || '',
      telefone2: motorista.telefone2 || '',
      documentos: {
        cpf: motorista.documentos.cpf || '',
        nome_mae: motorista.documentos.nome_mae || '',
        nome_pai: motorista.documentos.nome_pai || '',
        rg: motorista.documentos.rg || '',
        rg_uf: motorista.documentos.rg_uf || '',
        rg_orgao_expedidor: motorista.documentos.rg_orgao_expedidor || '',
        cnh: motorista.documentos.cnh || '',
        cnh_uf: motorista.documentos.cnh_uf || '',
        categoria: motorista.documentos.categoria || '',
        cnh_primeira: motorista.documentos.cnh_primeira || '',
        cnh_validade: motorista.documentos.cnh_validade || '',
        cnh_seguranca: motorista.documentos.cnh_seguranca || '',
        pis_pasep: motorista.documentos.pis_pasep || '',
      },
      endereco: {
        pais: motorista.endereco.pais || '',
        uf: motorista.endereco.uf || '',
        municipio: motorista.endereco.municipio || '',
        logradouro: motorista.endereco.logradouro || '',
        bairro: motorista.endereco.bairro || '',
        numero: motorista.endereco.numero || '',
        cep: motorista.endereco.cep || '',
        complemento: motorista.endereco.complemento || '',
      },
    });
    veiculoForm.setValues({
      antt_cavalo: motorista.veiculo.antt_cavalo || '',
      cpf_cnpj_dono: motorista.veiculo.cpf_cnpj_dono || '',
      placa_cavalo: motorista.veiculo.placa_cavalo || '',
      renavam_cavalo: motorista.veiculo.renavam_cavalo || '',
      tipo_pessoa_dono: motorista.veiculo.tipo_pessoa_dono || '',
      uf: motorista.veiculo.uf || '',
      marca_cavalo: motorista.veiculo.marca_cavalo || '',
      rntrc: motorista.veiculo.rntrc || '',
      ano_fabricacao_cavalo: motorista.veiculo.ano_fabricacao_cavalo || '',
      nome_dono: motorista.veiculo.nome_dono || '',
      tipo_veiculo: motorista.veiculo.tipo_veiculo.id || '',
      tipo_veiculo_nome: motorista.veiculo.tipo_veiculo.tipo || '',
      marca_rastreador: motorista.veiculo.marca_rastreador || '',
      id_rastreador: motorista.veiculo.id_rastreador || '',
      status_rastreador: motorista.veiculo.status_rastreador || '',
      quantidade_eixos: motorista.veiculo.quantidade_eixos || '',
    })
    
    carroceriasForm.setValues({
      carrocerias: motorista.veiculo.carrocerias.map((item) => ({
        id: item.id,
        rntrc: item.rntrc,
        placa: item.placa?.toUpperCase(),
        marca: item.marca,
        antt: item.antt,
        tipo_pessoa_dono: item.tipo_pessoa_dono,
        cpf_cnpj_dono: item.cpf_cnpj_dono,
        nome_dono: item.nome_dono,
        tipo: item.tipo.id,
        tipo_nome: item.tipo.tipo,
      }))
    });
  }, [motorista]);

  useEffect(() => {
    if (!canEdit) {
      return;
    }
    setExpandirInfoVeiculo(true);
  }, [canEdit]);

  const handleRetificar = async () => {
    try {
      await api.post('/gr/correcoes/resend', {
        ids: errosGuepRaw.map((item) => item.id),
        candidatura: idCandidaturaRetificar,
      });
      confirmAlert({
        title: "Salvo!",
        message: `Reenviado!`,
        overlayClassName: "alert-remover-nfe",
        buttons: [
          {
            label: "Ok",
            className: "botao-cancelar"
          }
        ]
      })
      handleModalPerfilMotorista();
    } catch {
      confirmAlert({
        title: "Erro!",
        message: `Houve um Erro!`,
        overlayClassName: "alert-remover-nfe",
        buttons: [
          {
            label: "Ok",
            className: "botao-cancelar"
          }
        ]
      });
    }
  }

  const ModalVisualizarFotos = () => {
    return (
      <Modal
        isOpen={modalVisualizarFotos !== null ? true : false}
        onRequestClose={() => setModalVisualizarFotos(null)}
        className="modal-visualizar-fotos"
        overlayClassName="overlay-modal-visualizar-fotos"
        ariaHideApp={false}
      >
        <div className="conteudo">
          <div className="topo">
            <RiCloseCircleFill
              className="icone-fechar"
              onClick={() => setModalVisualizarFotos(false)}
            ></RiCloseCircleFill>
          </div>

          <img src={modalVisualizarFotos} alt="" />
        </div>
      </Modal>
    );
  };

  useEffect(() => {
    api
      .get(`imagemveiculo/?veiculo=${motorista.veiculo.id}`)
      .then(function (response) {
        setImagensVeiculo(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [motorista.veiculo]);

  const handleSaveData = async () => {
    try {
      await api.patch(`/motorista/${motorista.id}/`, motoristaForm.values);
      onSaved();
      confirmAlert({
        title: "Salvo!",
        message: `Dados do Motorista Salvos!`,
        overlayClassName: "alert-remover-nfe",
        buttons: [
          {
            label: "Ok",
            className: "botao-cancelar"
          }
        ]
      })
    } catch (err) {
      showApiErrors(err, motoristaTitles);
    }
  }

  const handleSaveVeiculo = async () => {
    try {
      await api.patch(`/veiculo/${motorista.veiculo.id}/`, veiculoForm.values);
      onSaved();
      confirmAlert({
        title: "Salvo!",
        message: `Dados do Veículo Salvos!`,
        overlayClassName: "alert-remover-nfe",
        buttons: [
          {
            label: "Ok",
            className: "botao-cancelar"
          }
        ]
      })
    } catch (err) {
      showApiErrors(err, veiculoTitles);
    }
  }

  const handleUpdateCarroceria = async (index) => {
    const data = carroceriasForm.values.carrocerias[index];

    try {
      await api.patch(`/carroceria/${data.id}/`, data);
      onSaved();
      confirmAlert({
        title: "Salvo!",
        message: `Dados da Carroceria Salvos!`,
        overlayClassName: "alert-remover-nfe",
        buttons: [
          {
            label: "Ok",
            className: "botao-cancelar"
          }
        ]
      })
    } catch (err) {
      showApiErrors(err, veiculoTitles);
    }
  }

  return (
    <div className="container-informacoes">
      <div className="categoria">
        {editable && (
          <>
            <div className="gr-banner">
              Entendemos que esse motorista está associado as operações dessa transportadora e,
              portanto, para fins de correção dos dados de Gerenciador de Riscos (GR), permitimos que os{' '}
              dados relevantes para o sistema de GR sejam corrigidos por meio dessa tela pela transportadora.
              Os campos tracejados são editáveis. Para editar os dados do veículo, clique em "Ver Mais".
            </div>
            <div className="actions-banner">
              <h3 className="banner-title">Gerenciador de Riscos</h3>

              {erroNaoParametro && (
                <div style={{ marginBottom: '20px' }}>
                  Existe um erro com a GUEP que NÃO é causado por parâmetros incorretos. Para a correção desses, é necessário fazer login no <a href="https://score.guep.com.br/v/#/login" target="_blank" rel="noreferrer">painel da GUEP</a>.
                </div>
              )}

              {errosGuepRaw.length > 0 && errosValidacao.length > 0 && (
                  <div className="errors-section">
                    Erros de parâmetros salvos no nosso sistema para esse motorista e veículo:

                    <ul>
                      {errosValidacao.map((item) => (
                        <li key={item.label}>{item.label} - {item.message}</li>
                      ))}
                    </ul>
                  </div>
                )}

              {!!idCandidaturaRetificar && errosGuepRaw.length > 0 && (
                <>
                  Após verificar, atualizar corretamente os dados do motorista e do veículo e salvar, utilize o botão abaixo para reenviar para a Gestão de Risco:

                  <div className="resend-button-row">
                    <button className="resend-button" type="button" onClick={handleRetificar}>
                      Reenviar
                    </button>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        <p className="titulo-categoria">Dados do Motorista:</p>

        <div className="container-dados">
          <div className="col-4">
            <label>
              Nome Completo
              <input
                id="nome_completo"
                value={motoristaForm.values.nome_completo}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
                className={inputClassName}
              />
            </label>

            <label>
              Contato 1
              <InputMask
                required
                mask="(99) 99999-9999"
                maskChar=" "
                id="telefone1"
                value={motoristaForm.values.telefone1}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
                className={inputClassName}
              />
            </label>

            <label>
              Contato 2
              <InputMask
                required
                mask="(99) 99999-9999"
                maskChar=" "
                id="telefone2"
                value={motoristaForm.values.telefone2}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
                className={inputClassName}
              />
            </label>

            <label>
              CEP
              <InputMask
                required
                mask="99999-999"
                maskChar=""
                id="endereco.cep"
                className={inputClassName}
                value={motoristaForm.values.endereco.cep}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
              />
            </label>

            <label>
              UF
              <select
                id="endereco.uf"
                className={inputClassName}
                value={motoristaForm.values.endereco.uf}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
              >
                <UFSelectOptions />
              </select>
            </label>

            <label>
              Cidade
              <input 
                id="endereco.municipio"
                className={inputClassName}
                value={motoristaForm.values.endereco.municipio}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
              />
            </label>

            <label>
              Logradouro
              <input 
                id="endereco.logradouro"
                className={inputClassName}
                value={motoristaForm.values.endereco.logradouro}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
              />
            </label>

            <label>
              Bairro
              <input 
                id="endereco.bairro"
                className={inputClassName}
                value={motoristaForm.values.endereco.bairro}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
              />
            </label>

            <label>
              Número
              <input 
                id="endereco.numero"
                className={inputClassName}
                value={motoristaForm.values.endereco.numero}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
              />
            </label>

            <label>
              Complemento
              <input 
                id="endereco.complemento"
                className={inputClassName}
                value={motoristaForm.values.endereco.complemento}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
              />
            </label>
                
            <label>
              CNH
              <InputMask
                required
                mask="99999999999"
                maskChar=" "
                id="documentos.cnh"
                className={inputClassName}
                value={motoristaForm.values.documentos.cnh}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
              />
            </label>

            <label>
              Validade CNH
              <input
                id="documentos.cnh_validade"
                className={inputClassName}
                value={motoristaForm.values.documentos.cnh_validade}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
                type="date"
              />
            </label>

            <label>
              Primeira CNH
              <input
                id="documentos.cnh_primeira"
                className={inputClassName}
                value={motoristaForm.values.documentos.cnh_primeira}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
                type="date"
              />
            </label>

            <label>
              Código de Verificação CNH
              <input
                id="documentos.cnh_seguranca"
                className={inputClassName}
                value={motoristaForm.values.documentos.cnh_seguranca}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
              />
            </label>

            <label>
              Categoria
              <select
                id="documentos.categoria"
                className={inputClassName}
                value={motoristaForm.values.documentos.categoria}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
              >
                <option value="" disabled></option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
                <option value="E">E</option>
                <option value="AB">AB</option>
                <option value="AC">AC</option>
                <option value="AD">AD</option>
                <option value="AE">AE</option>
              </select>
            </label>
            
            <label>
              UF da CNH
              <select
                id="documentos.cnh_uf"
                className={inputClassName}
                value={motoristaForm.values.documentos.cnh_uf}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
              >
                <UFSelectOptions />
              </select>
            </label>

            <label>
              RG
              <InputMask
                required
                mask="9999999"
                maskChar=" "
                id="documentos.rg"
                value={motoristaForm.values.documentos.rg}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
                className={inputClassName}
              />
            </label>

            <label>
              RG Orgão Expeditor
              <input
                id="documentos.rg_orgao_expedidor"
                value={motoristaForm.values.documentos.rg_orgao_expedidor}
                disabled={!canEdit}
                onChange={motoristaForm.handleChange}
                className={inputClassName}
              />
            </label>

            <label>
              UF do RG
              <select
                id="documentos.rg_uf"
                className={inputClassName}
                value={motoristaForm.values.documentos.rg_uf}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
              >
                <UFSelectOptions />
              </select>
            </label>

            <label>
              Data de Nascimento
              <input
                id="data_nascimento"
                className={inputClassName}
                value={motoristaForm.values.data_nascimento}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
                type="date"
              />
            </label>
            
            <label>
              CPF
              <InputMask
                required
                mask="999.999.999-99"
                maskChar=" "
                id="documentos.cpf"
                className={inputClassName}
                value={motoristaForm.values.documentos.cpf}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
              />
            </label>
            <label>
              Nome do Pai
              <input
                id="documentos.nome_pai"
                className={inputClassName}
                value={motoristaForm.values.documentos.nome_pai}
                disabled={!canEdit}
                onChange={motoristaForm.handleChange}
              />
            </label>
            <label>
              Nome da Mãe
              <input
                id="documentos.nome_mae"
                className={inputClassName}
                value={motoristaForm.values.documentos.nome_mae}
                disabled={!canEdit}
                onChange={motoristaForm.handleChange}
              />
            </label>
            <label>
              Sexo
              <select
                id="sexo"
                className={inputClassName}
                value={motoristaForm.values.sexo}
                onChange={motoristaForm.handleChange}
                disabled={!canEdit}
              >
                <option value="M">Masculino</option>
                <option value="F">Feminino</option>
              </select>
            </label>
            
            <label>
              PIS/PASEP
              <InputMask
                mask="999.99999.99-9"
                maskChar=" "
                id="documentos.pis_pasep"
                value={motoristaForm.values.documentos.pis_pasep}
                disabled={!canEdit}
                onChange={motoristaForm.handleChange}
                className={inputClassName}
              />
            </label>
          </div>
        </div>
      </div>

      {canEdit && (
        <div className="submit-row">
          <button
            type="button"
            className="btn salvar"
            onClick={handleSaveData}
          >
            SALVAR
          </button>
        </div>
      )}

      <div className="categoria">
        <p className="titulo-categoria">Dados do Veículo:</p>

        {!expandirInfoVeiculo ? (
          <div className="container-dados">
            <div className="col-4">
              <label>
                Placa Cavalo
                <input value={motorista.veiculo.placa_cavalo} disabled />
              </label>

              <label>
                Tipo Cavalo
                <input value={motorista.veiculo.tipo_veiculo.tipo} disabled />
              </label>

              <label>
                Ano Fabricação Cavalo
                <input
                  value={motorista.veiculo.ano_fabricacao_cavalo}
                  disabled
                />
              </label>

              {motorista.veiculo.carrocerias.map((item) => (
                <label key={item.id}>
                  Placa Carreta
                  <input value={item.placa} disabled />
                </label>
              ))}
            </div>

            <div className="col-3-link">
              {motorista.veiculo.carrocerias.map((item) => (
                <label key={item.id}>
                  Tipo Carreta
                  <input value={item.tipo.tipo} disabled />
                </label>
              ))}

              <label>
                Veículo Rastreavel?
                <input
                  value={
                    motorista.veiculo.status_rastreador !== 0 ? "Sim" : "Não"
                  }
                  disabled
                />
              </label>
            </div>

            <p
              className="veiculo-ver-mais"
              onClick={() => setExpandirInfoVeiculo(true)}
            >
              Ver Mais
            </p>
          </div>
        ) : (
          <div className="container-dados veiculo">
            <div className="titulo-dados-veiculo">
              <p>Cavalo</p>
              <hr />
            </div>

            <div className="col-4">
              <label>
                Nome do Dono
                <input
                  id="nome_dono"
                  value={veiculoForm.values.nome_dono}
                  disabled={!canEdit}
                  onChange={veiculoForm.handleChange}
                  className={inputClassName}
                />
              </label>

              <label>
                Placa Cavalo
                <InputMask
                  required
                  mask="***-****"
                  maskChar=" "
                  id="placa_cavalo"
                  value={veiculoForm.values.placa_cavalo}
                  onChange={veiculoForm.handleChange}
                  disabled={!canEdit}
                  className={inputClassName}
                />
              </label>

              <label className={`${inputClassName} modal-input-tipo`}>
                Tipo Cavalo
                <InputSearchVeiculoCarroceria
                  placeholder="Buscar Tipo Veículo"
                  value={veiculoForm.values.tipo_veiculo_nome}
                  saveValue={(e) => veiculoForm.setFieldValue('tipo_veiculo_nome', e)}
                  saveId={(e) => veiculoForm.setFieldValue('tipo_veiculo', e)}
                  disabled={!canEdit}
                  suggestions={tiposVeiculos}
                />
              </label>

              <label>
                Ano Fabricação Cavalo
                <input
                  id="ano_fabricacao_cavalo"
                  value={veiculoForm.values.ano_fabricacao_cavalo}
                  disabled={!canEdit}
                  onChange={veiculoForm.handleChange}
                  className={inputClassName}
                />
              </label>

              <label>
                Marca
                <input
                  id="marca_cavalo"
                  value={veiculoForm.values.marca_cavalo}
                  disabled={!canEdit}
                  onChange={veiculoForm.handleChange}
                  className={inputClassName}
                />
              </label>

              <label>
                Renavam
                <InputMask
                  required
                  mask="99999999999"
                  maskChar=" "
                  id="renavam_cavalo"
                  value={veiculoForm.values.renavam_cavalo}
                  onChange={veiculoForm.handleChange}
                  disabled={!canEdit}
                  className={inputClassName}
                />
              </label>

              <label>
                ANTT
                <input
                  id="antt_cavalo"
                  value={veiculoForm.values.antt_cavalo}
                  onChange={veiculoForm.handleChange}
                  disabled={!canEdit}
                  className={inputClassName}
                />
              </label>

              <label>
                RNTRC
                <InputMask
                  required
                  mask="999999999"
                  maskChar=" "
                  id="rntrc"
                  value={veiculoForm.values.rntrc}
                  disabled={!canEdit}
                  onChange={veiculoForm.handleChange}
                  className={inputClassName}
                />
              </label>

              <label>
                UF
                <select
                  id="uf"
                  className={inputClassName}
                  value={veiculoForm.values.uf}
                  onChange={veiculoForm.handleChange}
                  disabled={!canEdit}
                >
                  <UFSelectOptions />
                </select>
              </label>

              <DocumentoDono
                idTipo="tipo_pessoa_dono"
                valueTipo={veiculoForm.values.tipo_pessoa_dono}
                idDocumento="cpf_cnpj_dono"
                valueDocumento={veiculoForm.values.cpf_cnpj_dono}
                onChange={veiculoForm.handleChange}
                disabled={!canEdit}
                className={inputClassName}
              />
              
              <label>
                Marca do Rastreador
                <input
                  id="marca_rastreador"
                  value={veiculoForm.values.marca_rastreador}
                  onChange={veiculoForm.handleChange}
                  disabled={!canEdit}
                  className={inputClassName}
                />
              </label>

              <label>
                ID do Rastreador
                <input
                  id="id_rastreador"
                  value={veiculoForm.values.id_rastreador}
                  onChange={veiculoForm.handleChange}
                  disabled={!canEdit}
                  className={inputClassName}
                />
              </label>

              <span
                className="status-checkbox"
                onClick={!canEdit ? () => { } : () => veiculoForm.setFieldValue('status_rastreador', veiculoForm.values.status_rastreador === '1' ? '0' : '1')}
              >
                <input
                  type="checkbox"
                  checked={String(veiculoForm.values.status_rastreador) === '1'}
                  onChange={() => veiculoForm.setFieldValue('status_rastreador', veiculoForm.values.status_rastreador === '1' ? '0' : '1')}
                  disabled={!canEdit}
                />
                <span className="checked"></span>
                Rastreador Ativo?
              </span>

              <label>
                Quantidade de Eixos
                <input
                  id="quantidade_eixos"
                  value={veiculoForm.values.quantidade_eixos}
                  onChange={veiculoForm.handleChange}
                  disabled={!canEdit}
                  className={inputClassName}
                />
              </label>
            </div>

            {canEdit && (
              <div className="submit-row bottom">
                <button
                  type="button"
                  className="btn salvar"
                  onClick={handleSaveVeiculo}
                >
                  SALVAR
                </button>
              </div>
            )}

            <div className="titulo-dados-veiculo">
              <p>Carroceria</p>
              <hr />
            </div>

            {carroceriasForm.values.carrocerias.map((item, index) => (
              <>
                <div className="col-4" key={item.id}>
                  <label>
                    Placa <i>*</i>
                    <InputMask
                      required
                      mask="***-****"
                      maskChar=" "
                      className={inputClassName}
                      value={item.placa}
                      id={`carrocerias.${index}.placa`}
                      onChange={carroceriasForm.handleChange}
                      disabled={!canEdit}
                    />
                  </label>

                  <label>
                    RNTRC <i>*</i>
                    <InputMask
                      required
                      mask="999999999"
                      maskChar=" "
                      className={inputClassName}
                      type="text"
                      value={item.rntrc}
                      id={`carrocerias.${index}.rntrc`}
                      onChange={carroceriasForm.handleChange}
                      disabled={!canEdit}
                    />
                  </label>

                  <label>
                    Marca <i>*</i>
                    <input
                      required
                      type="text"
                      className={inputClassName}
                      value={item.marca}
                      id={`carrocerias.${index}.marca`}
                      onChange={carroceriasForm.handleChange}
                      disabled={!canEdit}
                    />
                  </label>

                  <label>
                    CPF/CNPJ ANTT <i>*</i>
                    <InputCPFouCNPJ
                      required
                      type="text"
                      value={item.antt}
                      id={`carrocerias.${index}.antt`}
                      onChange={carroceriasForm.handleChange}
                      disabled={!canEdit}
                      className={inputClassName}
                    />
                  </label>

                  <DocumentoDono
                    idTipo={`carrocerias.${index}.tipo_pessoa_dono`}
                    valueTipo={item.tipo_pessoa_dono}
                    idDocumento={`carrocerias.${index}.cpf_cnpj_dono`}
                    valueDocumento={item.cpf_cnpj_dono}
                    onChange={carroceriasForm.handleChange}
                    disabled={!canEdit}
                    className={inputClassName}
                  />

                  <label>
                    Nome do Dono <i>*</i>
                    <input
                      required
                      type="text"
                      value={item.nome_dono}
                      className={inputClassName}
                      id={`carrocerias.${index}.nome_dono`}
                      onChange={carroceriasForm.handleChange}
                      disabled={!canEdit}
                    />
                  </label>

                  <label className={`${inputClassName} modal-input-tipo`}>
                    Tipo de Carroceria
                    <InputSearchVeiculoCarroceria
                      placeholder="Buscar Tipo Veículo"
                      value={item.tipo_nome}
                      saveValue={(e) => carroceriasForm.setFieldValue(`carrocerias.${index}.tipo_nome`, e)}
                      saveId={(e) => carroceriasForm.setFieldValue(`carrocerias.${index}.tipo`, e)}
                      disabled={!canEdit}
                      suggestions={tipoCarroceiras}
                    />
                  </label>
                </div>
              
                <div className="submit-row" style={{ marginTop: '18px' }}>
                  <button
                    type="button"
                    className="btn salvar"
                    onClick={() => handleUpdateCarroceria(index)}
                  >
                    SALVAR
                  </button>
                </div>
              </>
            ))}

            <div className="titulo-dados-veiculo">
              <p>Fotos</p>
              <hr />
            </div>

            <div className="container-documentos">
              {imagensVeiculo.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="documento"
                    onClick={() => setModalVisualizarFotos(item.imagem)}
                  >
                    <img src={item.imagem} alt="" />
                  </div>
                );
              })}
            </div>

            <p
              className="veiculo-ver-menos"
              onClick={() => setExpandirInfoVeiculo(false)}
            >
              Ver Menos
            </p>
          </div>
        )}
      </div>

      <div className="categoria">
        <p className="titulo-categoria">Documentos:</p>

        <div className="container-documentos">
          {motorista.documentos.cnh_frente ? (
            <div
              className="documento"
              onClick={() =>
                setModalVisualizarFotos(motorista.documentos.cnh_frente)
              }
            >
              <img src={motorista.documentos.cnh_frente} alt="" />
            </div>
          ) : (
            <></>
          )}
          {motorista.documentos.cnh_verso ? (
            <div
              className="documento"
              onClick={() =>
                setModalVisualizarFotos(`${motorista.documentos.cnh_verso}`)
              }
            >
              <img src={motorista.documentos.cnh_verso} alt="" />
            </div>
          ) : (
            <></>
          )}
          {motorista.comprovante_endereco ? (
            <div
              className="documento"
              onClick={() =>
                setModalVisualizarFotos(`${motorista.comprovante_endereco}`)
              }
            >
              <img src={motorista.comprovante_endereco} alt="" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {modalVisualizarFotos && <ModalVisualizarFotos></ModalVisualizarFotos>}
    </div>
  );
};

export default memo(InformacoesMotorista);
