import React, { memo } from 'react'
import Modal from 'react-modal'
import { RiCloseCircleFill } from 'react-icons/ri'

import './styles.css'

import CardEmbarcado from './CardsAgendamento/Embarcado'
import CardPortaria from './CardsAgendamento/Portaria'
import CardEmbarcando from './CardsAgendamento/Embarcando'
import CardProgramadoEmbarque from './CardsAgendamento/ProgramadoEmbarque'
import CardInicioViagem from './CardsAgendamento/InicioViagem'
import CardEntregue from './CardsAgendamento/Entregue'
import EntregaPrevista from './CardsAgendamento/EntregaPrevista'

const ModalAgendamentos = ({ modalAgendamento, handleModalAgendamento, tipoEventoSelecionado }) => {
  const cards = [1, 2, 3]

  const renderizarCards = () => {
    switch (tipoEventoSelecionado) {
      case "embarcado":
        return cards.map(item => <CardEmbarcado key={item}></CardEmbarcado>)
      case "entrega-prevista":
        return cards.map(item => <EntregaPrevista key={item}></EntregaPrevista>)
      case "inicio-viagem":
        return cards.map(item => <CardInicioViagem key={item}></CardInicioViagem>)
      case "embarcando":
        return cards.map(item => <CardEmbarcando key={item}></CardEmbarcando>)
      case "programado":
        return cards.map(item => <CardProgramadoEmbarque key={item}></CardProgramadoEmbarque>)
      case "entregue":
        return cards.map(item => <CardEntregue key={item}></CardEntregue>)
      case "portaria":
        return cards.map(item => <CardPortaria key={item}></CardPortaria>)
      default:
        break
    }
  }

  return (
    <Modal
      isOpen={modalAgendamento}
      onRequestClose={handleModalAgendamento}
      className="modal-agendamento"
      overlayClassName="overlay-modal-agendamento"
      ariaHideApp={false}
    >

      <div className="titulo">
        <p>{tipoEventoSelecionado.replace("-", " ").toUpperCase()}</p>
        <RiCloseCircleFill className="icone" onClick={handleModalAgendamento}></RiCloseCircleFill>
      </div>

      <div className="container-cards-agendamentos">
        {renderizarCards()}
      </div>
    </Modal>
  )
}

export default memo(ModalAgendamentos)