import { BsFilm } from "react-icons/bs";

const CardVideos = ({ index, handleModalVideo, video }) => {

    return (
        <div className={`modal-tutorial-body-item-videos ${!(index % 2 === 0) ? 'body-item-ligth' : 'body-item-green'}`}>
            <div className="modal-tutorial-body-item-videos-propriedades">
                <div className={`modal-tutorial-body-item-videos-contador ${video.assistido
                    ? 'video-conclude' : ''} ''`}>
                    <span></span>
                    <span>
                        {video.assistido ? 'Sim' : 'Não'}
                    </span>
                </div>
                <div className="modal-tutorial-body-item-videos-title">
                    <p>{video.titulo}</p>
                </div>
            </div>
            <button onClick={() => handleModalVideo({
                    modal_video: true,
                    titulo: video.titulo,
                    videoIdYoutube: video.video_id,
                    videoId: video.id,
                })} className="modal-tutorial-body-item-videos-play">
                <span>
                    <BsFilm />
                </span>
                <span>
                    Ver Vídeo
                </span>
            </button>
        </div>
    )
}

export default CardVideos;