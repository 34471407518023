import { useEffect, useMemo, useState, Fragment } from 'react';
import { useFormik } from 'formik';
import { AiOutlineArrowUp, AiOutlineArrowDown, AiOutlineClose } from 'react-icons/ai';
import { HStack } from '../../../../../components/utils'
import { Button } from '../../../../../components/common'
import { Tabela } from '../../../../../components/layout'
import { ModalSelecionarColeta } from '../../../../../modals/modal-selecionar-coleta'
import api from '../../../../../services/api'
import './styles.css';

const EditarColetas = ({ idCarga, coletas, onClose }) => {
  const [isSelectingColeta, setIsSelectingColeta] = useState(false);

  const form = useFormik({
    initialValues: {
      coletas: {},
    }
  });

  useEffect(() => {
    const formatted = coletas.reduce((prev, item) => ({
      ...prev,
      [item.coleta.id]: {
        active: true,
        id: item.coleta.id,
        status: item.coleta.status,
        nfe: item.coleta.nfe.numero,
        cte: item.coleta.cte?.numero,
        cliente: item.coleta.nfe.modalidade_frete === 'CIF' ? item.coleta.nfe.remetente_razao_social : item.coleta.nfe.destinatario_razao_social,
        destino: `${item.coleta.nfe.destinatario_endereco.nome_municipio} - ${item.coleta.nfe.destinatario_endereco.uf}`,
      },
    }), {});
    
    form.setFieldValue('coletas', formatted);
  }, [coletas]);

  const coletasList = useMemo(() => {
    const original = form.values.coletas;
    const ordered = Object
      .keys(original)
      .map((item) => ({
        ...original[item],
      }))
      .sort((a, b) => {
        if (a.order === undefined || a.order === null) {
          return 1;
        }
        if (b.order === undefined || b.order === null) {
          return -1;
        }
        return a.order - b.order;
      })
      .map((item, index) => ({
        ...item,
        order: index,
      }));

    return ordered;
  }, [form.values.coletas]);

  const handleMove = (id, dir = -1) => {
    const index = coletasList.findIndex((item) => item.id === id);
    const coleta = coletasList.find((item) => item.id === id);

    const newOrdered = coletas
      .map((item, itemIndex) => {
        if (itemIndex === index + dir) {
          return {
            ...item,
            order: coleta.order,
          };
        }
        if (itemIndex === index) {
          return {
            ...item,
            order: coletas[index + dir].order,
          };
        }
        return item;
      })
      .sort((a, b) => {
        if (a.order === undefined || a.order === null) {
          return 1;
        }
        if (b.order === undefined || b.order === null) {
          return -1;
        }
        return a.order - b.order;
      })
      .map((item, index) => ({
        ...item,
        order: index,
      }));

    const newColetas = newOrdered.reduce((prev, item) => ({
      ...prev,
      [item.id]: item,
    }), {});

    form.setFieldValue('coletas', newColetas);
  };

  const handleAddColetas = (coletas) => {
    form.setFieldValue('coletas', {
      ...form.values.coletas,
      ...coletas,
    });
    setIsSelectingColeta(false);
  };

  const handleRemoveColeta = (id) => {
    const filtered = coletasList
      .filter((item) => item.id !== id)
      .map((item, index) => ({
        ...item,
        order: index,
      }));

    const newColetas = filtered.reduce((prev, item) => ({
      ...prev,
      [item.id]: item,
    }), {});

    form.setFieldValue('coletas', newColetas);
  }

	const handleConfirm = async () => {
		try {
			await api.patch(`/cargas/${idCarga}/`, {
        coletas: Object
          .keys(form.values.coletas)
          .map((item) => form.values.coletas[item])
          .sort((a, b) => {
            if (a.order === undefined || a.order === null) {
              return 1;
            }
            if (b.order === undefined || b.order === null) {
              return -1;
            }
            return a.order - b.order;
          })
          .map((item) => item.id),
			});
			onClose();
		} catch (err) {
      console.log(err)
    }
	}

	return (
    <div className="editar-coletas">
      <div className="coletas">
        <p>Coletas</p>

        <div>
          {coletasList.length === 0 && (
            <HStack height="100px" alignItems="center" justifyContent="center">
              Nenhuma Coleta Anexada Ainda!
            </HStack>
          )}
          {coletasList.length > 0 && (
            <>
              <Tabela>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Status</th>
                    <th>NF-e</th>
                    <th>CT-e</th>
                    <th>Cliente</th>
                    <th>Destino</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {coletasList.map((item, index) => (
                    <Fragment key={item.id}>
                      <tr className="divisoria-linhas-tabela"></tr>
                      <tr>
                        <td>{item.id}</td>
                        <td>{item.status}</td>
                        <td>{item.nfe?.numero || '-'}</td>
                        <td>{item.cte || '-'}</td>
                        <td>
                          <div className="elipsis" title={item.cliente}>
                            {item.cliente}
                          </div>
                        </td>
                        <td>
                          <div className="elipsis" title={item.destino}>
                            {item.destino}
                          </div>
                        </td>
                        <td>
                          <div className="coleta-actions">
                            {index > 0 ? (
                              <AiOutlineArrowUp className="coleta-icon" title="Mover para Cima" onClick={() => handleMove(item.id, -1)} />
                            ) : (
                              <AiOutlineArrowUp className="coleta-icon-hide"/>
                            )}
                            {index < coletasList.length - 1 ? (
                              <AiOutlineArrowDown className="coleta-icon" title="Mover para Baixo" onClick={() => handleMove(item.id, 1)}  />
                            ) : (
                              <AiOutlineArrowDown className="coleta-icon-hide" />
                            )}
                            <AiOutlineClose className="coleta-icon" title="Remover Coleta" onClick={() => handleRemoveColeta(item.id)} />
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </Tabela>
            </>
          )}

          <div className="actions">
            <HStack justifyContent="flex-end">
              <Button variant="smallAction" onClick={() => setIsSelectingColeta(true)}>
                Adicionar Coleta
              </Button>
            </HStack>
          </div>
        </div>
      </div>

			<div className="fill" />

			<div className="submit-line">
				<button className="btn-cancelar" onClick={onClose}>
					Cancelar
				</button>
				<button
					className="btn-criar"
					onClick={handleConfirm}
				>
					Salvar
				</button>
			</div>

      {isSelectingColeta && (
        <ModalSelecionarColeta
          isOpen={isSelectingColeta}
          onClose={() => setIsSelectingColeta(false)}
          onConfirm={handleAddColetas}
        />
      )}
    </div>
  );
};

export default EditarColetas;
