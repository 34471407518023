import './styles.css';
import { BsWhatsapp } from 'react-icons/bs';
import { useEffect } from 'react';
import apiPrivada from '../../../services/api';
import { useState } from 'react';

const NUMBER_PHONE = '6282224950';

const ButtonSupport = () => {

    const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
    const [transportadora, setTransportadora] = useState({});

    useEffect(() => {
        apiPrivada.get(`transportadora/${dadosUsuario.transportadora_id}`)
          .then(function (response) {
            let dadosTransportadora = response.data
            setTransportadora(dadosTransportadora)
    
          })
          .catch(function (error) {
            console.log(error)
          })
      }, [dadosUsuario.transportadora_id])

    return (
        <div className="button-support">
            <a href={`https://web.whatsapp.com/send?text=Ol%C3%A1%2C%20sou%20o%20usu%C3%A1rio%20${transportadora.razao_social}%2C%20gostaria%20de%20atendimento%20com%20a%20seguinte%20situa%C3%A7%C3%A3o%3A&phone=${NUMBER_PHONE}@c.us`} className="button-support-link" target='_blank' rel='noreferrer'>
                <span className="button-support-icon">
                    <BsWhatsapp />
                </span>
                <span className="button-support-text">
                   Posso ajudar?
                </span>
            </a>
        </div>
    )
}

export default ButtonSupport;