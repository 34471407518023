import React, { memo, useEffect, useState, useCallback, useRef } from "react";
import { FaRegBell, FaEye } from "react-icons/fa";
import { BsCheckLg } from "react-icons/bs";
import { parseISO, format } from "date-fns";
import Loader from "react-loader-spinner";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactPaginate from "react-paginate";

import "./styles.css";

import candidatura from "../../../assets/truck_blue.png";
import portaria from "../../../assets/portaria.jpg";
import embarque from "../../../assets/embarque_finalizado.jpg";
import entrega from "../../../assets/entrega_finalizada.jpg";
import registro from "../../../assets/registro.png";
import retificacao from "../../../assets/ctes.png";

import BreadCrumbs from "../../../components/plataformaAcessada/BreadCrumbs";
import ModalAvaliacaoMotorista from "../../../components/plataformaAcessada/Notificacoes/ModalAvaliacaoMotorista";
import ModalPropostaCarga from "../PainelMonitoramento/Painel/Quadros/NovasCargas/ModalPropostaCarga";
import ModalPerfilMotoristaCarga from "../PainelMonitoramento/ModalPerfilMotoristaCarga";
import api from "../../../services/api";

const HistoricoNotificacoes = () => {
  const routes = [
    { path: "/acesso", breadcrumb: "Início" },
    {
      path: "/acesso/painel-monitoramento",
      breadcrumb: "Painel Monitoramento",
    },
    { path: "/acesso/historico-notificacoes", breadcrumb: "Notificações" },
  ];

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
  const tipoUsuario = dadosUsuario.user_type;

  const [loading, setLoading] = useState(true);
  const [modalPropostaCarga, setModalPropostaCarga] = useState(false);
  const [modalPerfilMotoristaCarga, setModalPerfilMotoristaCarga] =
    useState(false);
  const [modalAvaliacaoMotorista, setModalAvaliacaoMotorista] = useState(false);
  const [dataFechamentoCarga, setDataFechamentoCarga] = useState("");

  const [notificacoes, setNotificacoes] = useState([]);
  const [quantidadePaginas, setQuantidadePaginas] = useState(1);
  const [paginaAtual, setPaginaAtual] = useState(0);

  const inputDataFechamento = useRef();

  const obtemNotificacoes = useCallback(
    (paginaSelecionada, offSet) => {
      setPaginaAtual(paginaSelecionada);

      if (
        tipoUsuario === "transportadora" ||
        tipoUsuario === "colaborador_transportadora"
      ) {
        api
          .get(
            `empresa_notificacoes/?transportadora=${
              dadosUsuario.transportadora_id
            }&limit=15&offset=${offSet ? offSet : 0}`
          )
          .then(function (response) {
            setQuantidadePaginas(Math.ceil(response.data.count / 15));
            setNotificacoes(response.data.results);
            setLoading(false);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else if (
        tipoUsuario === "embarcador" ||
        tipoUsuario === "colaborador_embarcador"
      ) {
        api
          .get(
            `empresa_notificacoes/?embarcador=${
              dadosUsuario.embarcador_id
            }&limit=15&offset=${offSet ? offSet : 0}`
          )
          .then(function (response) {
            setQuantidadePaginas(Math.ceil(response.data.count / 15));
            setNotificacoes(response.data.results);
            setLoading(false);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    [tipoUsuario, dadosUsuario.transportadora_id, dadosUsuario.embarcador_id]
  );

  const registrarNotificacaoVisualizada = useCallback(
    async (idNotificacao) => {
      await api
        .patch(`empresa_notificacoes/${idNotificacao}/`, {
          visualizado: true,
        })
        .then(function () {
          obtemNotificacoes();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    [obtemNotificacoes]
  );

  const handleModalPropostaCarga = useCallback(
    (notificacao) => {
      if (modalPropostaCarga) {
        setModalPropostaCarga(false);
        document.querySelector("body").style.overflow = "visible";
      } else {
        setModalPropostaCarga(notificacao);
        registrarNotificacaoVisualizada(notificacao.id);
        document.querySelector("body").style.overflow = "hidden";
      }
    },
    [modalPropostaCarga, registrarNotificacaoVisualizada]
  );

  const handleModalPerfilMotoristaCarga = useCallback(
    (notificacao) => {
      if (modalPerfilMotoristaCarga) {
        setModalPerfilMotoristaCarga(false);
        document.querySelector("body").style.overflow = "visible";
      } else {
        setModalPerfilMotoristaCarga(notificacao);
        registrarNotificacaoVisualizada(notificacao.id);
        document.querySelector("body").style.overflow = "hidden";
      }
    },
    [modalPerfilMotoristaCarga, registrarNotificacaoVisualizada]
  );

  const handleModalAvaliacaoMotorista = useCallback(
    (notificacao) => {
      if (modalAvaliacaoMotorista) {
        setModalAvaliacaoMotorista(false);
        document.querySelector("body").style.overflow = "visible";
      } else {
        setModalAvaliacaoMotorista(notificacao);
        registrarNotificacaoVisualizada(notificacao.id);
        document.querySelector("body").style.overflow = "hidden";
      }
    },
    [modalAvaliacaoMotorista, registrarNotificacaoVisualizada]
  );

  const alterarDataFechamento = (notificacao) => {
    api
      .patch(`cargas/${notificacao.carga.id}/`, {
        data_fechamento: `${dataFechamentoCarga}:00`,
      })
      .then((e) => {
        registrarNotificacaoVisualizada(notificacao.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const cancelarProposta = (notificacao) => {
    api
      .patch(`cargas/${notificacao.carga.id}/`, {
        carga_cancelada: true,
        status_temporal: "no-prazo",
      })
      .then(() => {
        registrarNotificacaoVisualizada(notificacao.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const reprovarProposta = (notificacao) => {
    const reprovar = (onClose) => {
      api
        .post(`/candidatura/${notificacao.candidatura.id}/negar_candidatura/`)
        .then(function () {
          onClose();
          registrarNotificacaoVisualizada(notificacao.id);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="container-alert-proposta">
            <p className="titulo">
              Você deseja realmente <span className="reprovar">reprovar</span> o
              motorista para a carga?
            </p>

            <div className="motorista">
              <img src={notificacao.motorista.imagem_perfil} alt="" />
              <p>{notificacao.motorista.nome_completo}</p>
            </div>

            <div className="botoes">
              <button className="cancelar" onClick={onClose}>
                Não
              </button>
              <button className="confirmar" onClick={() => reprovar(onClose)}>
                Sim
              </button>
            </div>
          </div>
        );
      },
    });
  };

  useEffect(() => {
    obtemNotificacoes();
  }, [obtemNotificacoes]);

  const renderNotificacao = (notificacao) => {
    switch (notificacao.tipo) {
      case "fechamento_carga":
        return (
          <div
            key={notificacao.id}
            className={`quadro-notificacao fechamento ${
              notificacao.visualizado ? "visualizada" : ""
            }`}
          >
            {notificacao.visualizado ? (
              <BsCheckLg className="icone"></BsCheckLg>
            ) : (
              <FaEye className="icone visualizar fechamento"></FaEye>
            )}

            <img src={candidatura} alt="" />

            <div className="texto">
              <p>
                A carga de ID <span>{notificacao.carga.id}</span> atingiu seu
                horário de fechamento:
                <span>
                  {format(
                    parseISO(notificacao.carga.data_fechamento),
                    " dd/MM/yyyy 'às' HH:mm"
                  )}
                </span>
                .
              </p>

              {!notificacao.visualizado && (
                <>
                  <p>
                    Origem:&nbsp;
                    <span>
                      {notificacao.carga.origem.endereco.municipio} -{" "}
                      {notificacao.carga.origem.endereco.uf}
                    </span>
                  </p>

                  <p>
                    Destino(s):
                    <span>
                      {notificacao.carga.pontos_parada.map((item, index) => {
                        return index !== 0
                          ? ` | ${item.destino.endereco.municipio} - ${item.destino.endereco.uf}`
                          : ` ${item.destino.endereco.municipio} - ${item.destino.endereco.uf}`;
                      })}
                    </span>
                  </p>

                  <p>
                    Data de Embarque:{" "}
                    <span>
                      {format(
                        parseISO(notificacao.carga.data_embarque),
                        " dd/MM/yyyy 'às' HH:mm"
                      )}
                    </span>
                  </p>
                  <p>
                    Data de Descarga:{" "}
                    <span>
                      {format(
                        parseISO(notificacao.carga.data_descarga),
                        " dd/MM/yyyy 'às' HH:mm"
                      )}
                    </span>
                  </p>

                  <p className="pergunta">
                    Deseja alterar a data de fechamento?
                  </p>

                  <div className="container-input">
                    <input
                      ref={inputDataFechamento}
                      type="text"
                      onFocus={() =>
                        (inputDataFechamento.current.type = "datetime-local")
                      }
                      onBlur={() => {
                        dataFechamentoCarga === "" &&
                          (inputDataFechamento.current.type = "text");
                      }}
                      placeholder="Nova data"
                      min={new Date().toISOString().slice(0, -8)}
                      value={dataFechamentoCarga}
                      onChange={(e) => setDataFechamentoCarga(e.target.value)}
                    />
                    <span>Data Fechamento Proposta</span>
                  </div>

                  <div className="botoes-alterar-data">
                    <button
                      className="salvar"
                      onClick={() => alterarDataFechamento(notificacao)}
                    >
                      Salvar Alteração
                    </button>
                    <button
                      className="cancelar"
                      onClick={() => cancelarProposta(notificacao)}
                    >
                      Cancelar Proposta
                    </button>
                  </div>
                </>
              )}
            </div>

            <p className="data">
              {format(parseISO(notificacao.data), "dd/MM/yyyy 'às' HH:mm")}
            </p>
          </div>
        );

      case "nova_candidatura":
        return (
          <div
            key={notificacao.id}
            className={`quadro-notificacao ${
              notificacao.visualizado ? "visualizada" : ""
            }`}
          >
            {notificacao.visualizado ? (
              <BsCheckLg className="icone"></BsCheckLg>
            ) : (
              <FaEye
                className="icone visualizar"
                onClick={() => handleModalPropostaCarga(notificacao)}
              ></FaEye>
            )}

            <img src={candidatura} alt="" />

            <p className="texto">
              Motorista {notificacao.motorista.nome_completo} se candidatou a
              carga
              {notificacao.carga.id} para{" "}
              {notificacao.carga.pontos_parada.map((item, index) => {
                return index !== 0
                  ? ` | ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
                  : ` ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`;
              })}
              .
            </p>

            <p className="data">
              {format(parseISO(notificacao.data), "dd/MM/yyyy 'às' HH:mm")}
            </p>
          </div>
        );

      case "candidatura_negada":
        return (
          <div
            key={notificacao.id}
            className={`quadro-notificacao ${
              notificacao.visualizado ? "visualizada" : ""
            }`}
          >
            {notificacao.visualizado ? (
              <BsCheckLg className="icone"></BsCheckLg>
            ) : (
              <FaEye
                className="icone visualizar"
                onClick={() => registrarNotificacaoVisualizada(notificacao.id)}
              ></FaEye>
            )}

            <img src={candidatura} alt="" />

            <p className="texto">
              A candidatura do motorista {notificacao.motorista.nome_completo}{" "}
              na carga
              {notificacao.carga.id} para{" "}
              {notificacao.carga.pontos_parada.map((item, index) => {
                return index !== 0
                  ? ` | ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
                  : ` ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`;
              })}{" "}
              foi negada pois não foi possível enviar os dados do motorista para
              o gerenciador de riscos.
            </p>

            <p className="data">
              {format(parseISO(notificacao.data), "dd/MM/yyyy 'às' HH:mm")}
            </p>
          </div>
        );

      case "portaria":
        return (
          <div
            key={notificacao.id}
            className={`quadro-notificacao ${
              notificacao.visualizado ? "visualizada" : ""
            }`}
          >
            {notificacao.visualizado ? (
              <BsCheckLg className="icone"></BsCheckLg>
            ) : (
              <FaEye
                className="icone visualizar"
                onClick={() => handleModalPerfilMotoristaCarga(notificacao)}
              ></FaEye>
            )}

            <img src={portaria} alt="" />

            <p className="texto">
              Motorista {notificacao.carga.motorista_em_viagem.nome_completo}{" "}
              chegou na PORTARIA para carregar a carga de ID{" "}
              {notificacao.carga.id} para{" "}
              {notificacao.carga.pontos_parada.map((item, index) => {
                return index !== 0
                  ? ` | ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
                  : ` ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`;
              })}
              .
            </p>

            <p className="data">
              {format(parseISO(notificacao.data), "dd/MM/yyyy 'às' HH:mm")}
            </p>
          </div>
        );
      case "embarque_finalizado":
        return (
          <div
            key={notificacao.id}
            className={`quadro-notificacao ${
              notificacao.visualizado ? "visualizada" : ""
            }`}
          >
            {notificacao.visualizado ? (
              <BsCheckLg className="icone"></BsCheckLg>
            ) : (
              <FaEye
                className="icone visualizar"
                onClick={() => handleModalPerfilMotoristaCarga(notificacao)}
              ></FaEye>
            )}

            <img src={embarque} alt="" />

            <p className="texto">
              Motorista {notificacao.carga.motorista_em_viagem.nome_completo},
              do frete do ID {notificacao.carga.id}
              com Destino à{" "}
              {notificacao.carga.pontos_parada.map((item, index) => {
                return index !== 0
                  ? ` | ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
                  : ` ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`;
              })}{" "}
              Finalizou o carregamento. Anexe os documentos fiscais para liberar
              a viagem!.
            </p>

            <p className="data">
              {format(parseISO(notificacao.data), "dd/MM/yyyy 'às' HH:mm")}
            </p>
          </div>
        );
      case "guep_desacordo_retificar_dados":
        return (
          <div
            key={notificacao.id}
            className={`quadro-notificacao ${
              notificacao.visualizado ? "visualizada" : ""
            }`}
          >
            {notificacao.visualizado ? (
              <BsCheckLg className="icone"></BsCheckLg>
            ) : (
              <a
                href="https://score.guep.com.br/v/#/login"
                target="_blank"
                rel="noreferrer"
                onClick={() => registrarNotificacaoVisualizada(notificacao.id)}
              >
                <FaEye className="icone visualizar"></FaEye>
              </a>
            )}

            <img src={retificacao} alt="" />

            <p className="texto">
              O motorista {notificacao.motorista.nome_completo}, do frete do ID{" "}
              {notificacao.carga.id}
              com Destino à{" "}
              {notificacao.carga.pontos_parada.map((item, index) => {
                return index !== 0
                  ? ` | ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
                  : ` ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`;
              })}{" "}
              foi reprovado no Gerenciador de Riscos.
              <br />
              Deseja efetuar a retificação?
              <p className="aviso">
                (Use o usuário e senha pré informados no onboarding para
                realizar o login no WS da GUEP)
              </p>
              <div className="botoes">
                <a
                  href="https://score.guep.com.br/v/#/login"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    registrarNotificacaoVisualizada(notificacao.id)
                  }
                >
                  <button className="sim">Sim</button>
                </a>
                <button
                  className="nao"
                  onClick={() => reprovarProposta(notificacao)}
                >
                  Não
                </button>
              </div>
            </p>

            <p className="data">
              {format(parseISO(notificacao.data), "dd/MM/yyyy 'às' HH:mm")}
            </p>
          </div>
        );
      case "entrega":
        return (
          <div
            key={notificacao.id}
            className={`quadro-notificacao ${
              notificacao.visualizado ? "visualizada" : ""
            }`}
          >
            {notificacao.visualizado ? (
              <BsCheckLg className="icone"></BsCheckLg>
            ) : (
              <FaEye
                className="icone visualizar"
                onClick={() => handleModalAvaliacaoMotorista(notificacao)}
              ></FaEye>
            )}

            <img src={entrega} alt="" />

            <p className="texto">
              Motorista {notificacao.carga.motorista_em_viagem.nome_completo}{" "}
              finalizou a entrega com a carga
              {notificacao.carga.id}, do destino{" "}
              {notificacao.carga.pontos_parada.map((item, index) => {
                return index !== 0
                  ? ` | ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
                  : ` ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`;
              })}
              ,
              {notificacao.carga.situacao === "ocorrencia"
                ? " com ocorrência"
                : " sem ocorrência"}
              .
            </p>

            <p className="data">
              {format(parseISO(notificacao.data), "dd/MM/yyyy 'às' HH:mm")}
            </p>
          </div>
        );
      case "novo_registro":
        return (
          <div
            key={notificacao.id}
            className={`quadro-notificacao ${
              notificacao.visualizado ? "visualizada" : ""
            }`}
          >
            {notificacao.visualizado ? (
              <BsCheckLg className="icone"></BsCheckLg>
            ) : (
              <FaEye
                className="icone visualizar"
                onClick={() => handleModalPerfilMotoristaCarga(notificacao)}
              ></FaEye>
            )}

            <img src={registro} alt="" />

            <p className="texto">
              O motorista {notificacao.carga.motorista_em_viagem.nome_completo},
              enviou um novo registro na carga de ID
              {notificacao.carga.id}, com o destino para{" "}
              {notificacao.carga.pontos_parada.map((item, index) => {
                return index !== 0
                  ? ` | ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
                  : ` ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`;
              })}
              .
            </p>

            <p className="data">
              {format(parseISO(notificacao.data), "dd/MM/yyyy 'às' HH:mm")}
            </p>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="container-historico-notificacoes">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <div className="titulo">
        {!loading && (
          <div className="container-icone">
            <FaRegBell className="icone"></FaRegBell>

            {notificacoes.filter((item) => !item.visualizado).length > 0 && (
              <div className="quantidade">
                {notificacoes.filter((item) => !item.visualizado).length}
              </div>
            )}
          </div>
        )}

        <p>Notificações</p>
      </div>

      {loading ? (
        <div className="container-loading-historico-notificacoes">
          <Loader type="TailSpin" color="#001b48" height={50} width={50} />
        </div>
      ) : (
        <>
          <div className="container-lista-notificacoes">
            {notificacoes.map((item) => {
              return renderNotificacao(item);
            })}
          </div>

          {modalPropostaCarga && (
            <ModalPropostaCarga
              atualizarCargas={() => {}}
              moverCarga={() => {}}
              carga={modalPropostaCarga.carga}
              modalPropostaCarga={modalPropostaCarga ? true : false}
              handleModalPropostaCarga={handleModalPropostaCarga}
            ></ModalPropostaCarga>
          )}

          {modalPerfilMotoristaCarga && (
            <ModalPerfilMotoristaCarga
              modalPerfilMotoristaCarga={
                modalPerfilMotoristaCarga ? true : false
              }
              handleModalPerfilMotoristaCarga={handleModalPerfilMotoristaCarga}
              idCarga={modalPerfilMotoristaCarga.carga.id}
              moverCarga={() => {}}
              closeToast={() => {}}
            ></ModalPerfilMotoristaCarga>
          )}

          {modalAvaliacaoMotorista && (
            <ModalAvaliacaoMotorista
              modalAvaliacaoMotorista={modalAvaliacaoMotorista}
              handleModalAvaliacaoMotorista={handleModalAvaliacaoMotorista}
              carga={modalAvaliacaoMotorista.carga}
            ></ModalAvaliacaoMotorista>
          )}

          <div className="container-paginacao-notificacoes">
            <ReactPaginate
              previousLabel={"Anterior"}
              nextLabel={"Próxima"}
              breakLabel={"..."}
              pageCount={quantidadePaginas}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              forcePage={paginaAtual}
              onPageChange={(e) =>
                obtemNotificacoes(e.selected, e.selected * 15)
              }
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default memo(HistoricoNotificacoes);
