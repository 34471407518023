import { parseISO, format, isPast } from "date-fns";
import { FiTruck } from "react-icons/fi";
import { FaSync } from 'react-icons/fa';
import { useState } from "react";
import {
    FaRegCalendarAlt,
    FaRegCalendarCheck,
    FaTag,
    FaClock,
    FaUserTie,
    FaTruck,
    FaEye,
    FaShareSquare,
    FaTrashAlt,
    FaUserEdit,
    FaIdCard,
} from "react-icons/fa";

import ModalPropostaCarga from "../ModalPropostaCarga";
import { useCallback } from "react";
import ModalDadosCarga from "../ModalDadosCarga";
import { confirmAlert } from "react-confirm-alert";

import api from "../../../../../../../services/api";
import { GrStatusInfo } from "react-icons/gr";
import { RiCloseCircleFill } from "react-icons/ri";

const CardsNovasCargas = ({ onRemove, onUpdate, carga, setRetificarData, setRetificarCarga, setRetificarCandidatura, setRetificarModal, moverCarga, compartilharNovaCarga }) => {
    const [hover, setHover] = useState(false);
    const [cargaEscolhida, setCargaEscolhida] = useState(null);
    const [modalPropostaCarga, setModalPropostaCarga] = useState(false);
    const [abrirModalDadosCarga, setAbrirModalDadosCarga] = useState(false);

    const verificaCorStatusPedido = (candidaturas) => {
        if (candidaturas > 0) {
            return "negociacao";
        }
        return "sem-negociacao";
    };

    const handleModalPropostaCarga = useCallback(
        (carga) => {
            if (modalPropostaCarga) {
                setModalPropostaCarga(false);
                document.querySelector("body").style.overflow = "visible";
            } else {
                setCargaEscolhida(carga);
                setModalPropostaCarga(true);
                document.querySelector("body").style.overflow = "hidden";
            }
        },
        [modalPropostaCarga]
    );

    const abrirModal = (carga) => {
        setCargaEscolhida(carga);
        setAbrirModalDadosCarga(true);
        document.querySelector("body").style.overflow = "hidden";
    };

    const fecharModal = () => {
        setAbrirModalDadosCarga(false);
        document.querySelector("body").style.overflow = "visible";
    };

    const removerCarga = (id) => {
        const apagarCarga = (onClose) => {
            api
                .delete(`cargas/${id}/`)
                .then(function () {
                    onRemove(id);
                    onClose();
                    fecharModal();
                })
                .catch(function (error) {
                    console.log(error);
                });
        };

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="container-alert-remover-carga">
                        <div className="titulo">
                            <GrStatusInfo
                                className="icone remover"
                                onClick={onClose}
                            ></GrStatusInfo>
                            <div className="detalhes">
                                <p>Remover Carga</p>
                                <div>
                                    <FaIdCard className="icone id"></FaIdCard>
                                    <p>
                                        ID: <span> {id}</span>
                                    </p>
                                </div>
                            </div>
                            <RiCloseCircleFill
                                className="icone fechar"
                                onClick={onClose}
                            ></RiCloseCircleFill>
                        </div>

                        <div className="conteudo">
                            <p>Deseja realmente remover a carga "{id}" do sistema?</p>

                            <div className="botoes">
                                <button onClick={onClose}>Cancelar</button>
                                <button
                                    className="btn-remover"
                                    onClick={() => apagarCarga(onClose)}
                                >
                                    Remover
                                </button>
                            </div>
                        </div>
                    </div>
                );
            },
        });
    };

    const handleAtualizarStatusGR = (id) => {
        api.post('/gr/atualizar-status', { carga: id })
            .then(() => { onUpdate(id) })
            .catch((err) => { console.log(err) })
    }

    return (
        <div
            className={`card ${carga.is_fracionada ? 'fracionado' : ''}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div className="linha">
                <div className="linha-atraso">
                    <div className="data">
                        <FaClock className="icone"></FaClock>
                        {format(parseISO(carga.data_fechamento), "dd/MM - HH:mm")}
                    </div>

                    {isPast(parseISO(carga.data_fechamento)) && (
                        <FaClock className="icone-atraso"></FaClock>
                    )}
                </div>

                <div className="pedido">
                    <div className="cor-numero">
                        <div
                            className={`cor ${verificaCorStatusPedido(carga.candidaturas)}`}
                        ></div>
                        <p>
                            <span>Pedido: </span>
                            {carga.pedidos?.length > 0
                                ? carga.pedidos.map((pedido, index) => {
                                    if (index === carga.pedidos.length - 1) {
                                        return pedido.numero;
                                    }
                                    return `${pedido.numero}, `;
                                })
                                : "sem número"}
                        </p>
                    </div>
                    <p className="id">
                        <span>ID: </span>
                        {carga.id}
                    </p>
                </div>

                <div className="candidatos">
                    <FaUserTie className="icone"></FaUserTie>
                    <p
                        className={
                            carga.candidaturas > 0 ? "com-candidatos" : "sem-candidatos"
                        }
                    >
                        {carga.candidaturas}
                    </p>
                </div>

                <div className="transportadora">
                    <FiTruck className="icone"></FiTruck>
                    <p>
                        {carga.transportadora
                            ? carga.transportadora.razao_social?.length > 15
                                ? carga.transportadora.razao_social.substr(0, 15) + "..."
                                : carga.transportadora.razao_social
                            : ""}
                    </p>
                </div>
            </div>

            <div className="linha">
                <div className="embarque">
                    <FaRegCalendarAlt className="icone"></FaRegCalendarAlt>
                    <p>
                        <span>Embarque: </span>
                        {format(parseISO(carga.data_embarque), "dd/MM/yyyy")}
                    </p>
                </div>

                <div className="entrega">
                    <FaRegCalendarCheck className="icone"></FaRegCalendarCheck>
                    <p>
                        <span>Entrega: </span>
                        {format(parseISO(carga.data_descarga), "dd/MM/yyyy")}
                    </p>
                </div>

                <div className="cidade">
                    <p>
                        {carga.origem.endereco?.municipio?.length > 15
                            ? carga.origem.endereco?.municipio.substr(0, 15) + "..."
                            : carga.origem.endereco?.municipio}
                        -{carga.origem.endereco?.uf}/
                        {carga.pontos_parada[carga.pontos_parada.length - 1]?.destino
                            .endereco?.municipio?.length > 15
                            ? carga.pontos_parada[
                                carga.pontos_parada.length - 1
                            ]?.destino.endereco?.municipio?.substr(0, 15) + "..."
                            : carga.pontos_parada[carga.pontos_parada.length - 1]?.destino
                                .endereco?.municipio}
                        -
                        {
                            carga.pontos_parada[carga.pontos_parada.length - 1]?.destino
                                .endereco?.uf
                        }
                    </p>

                    {carga.status_gr === "pendente" && (
                        <p className={`status-${carga.status_gr}`}>Em análise pela GR</p>
                    )}

                    {carga.status_gr === "negada" && (
                        <p className={`status-${carga.status_gr}`}>Reprovado pela GR</p>
                    )}
                </div>
            </div>

            <div className="linha">
                <div className="valor">
                    <div>
                        <p>R$</p>
                    </div>

                    <p className="real">
                        {Number(carga.proposta_inicial).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        })}
                        {/* <span>{item.proposta_candidato ? `/ ${item.proposta_candidato}` : ""}</span> */}
                        <span>
                            {carga.proposta_tipo === "total" ? "Cheio" : "P/Tonelada"}
                        </span>
                    </p>
                </div>

                <div className="caminhao">
                    <FaTruck className="icone"></FaTruck>
                    <p>
                        {carga.tipos_veiculo[0]?.tipo} - {carga.tipos_carroceria[0]?.tipo}
                    </p>
                </div>

                <div
                    className="carga"
                    style={{ visibility: carga.produto?.produto ? "visible" : "hidden" }}
                >
                    <FaTag className="icone"></FaTag>
                    <p>
                        {carga.produto?.produto
                            ? carga.produto?.produto?.length > 19
                                ? carga.produto.produto.substr(0, 19) + "..."
                                : carga.produto.produto
                            : ""}
                    </p>
                </div>
            </div>

            <div className={`acoes-card novas-cargas ${hover ? "hover" : ""}`}>
                <FaEye className="icone" onClick={() => abrirModal(carga)} title="Ver Informações"></FaEye>
                <FaShareSquare
                    className="icone"
                    onClick={() => compartilharNovaCarga(carga)}
                    title="Compartilhar"
                ></FaShareSquare>
                <div
                    className="motoristas-icone"
                    onClick={() => handleModalPropostaCarga(carga)}
                    title="Ver Candidaturas"
                >
                    <FaUserTie className="icone"></FaUserTie>
                    <p>{carga.candidaturas}</p>
                </div>
                {carga.status_gr === "pendente" ? (
                    <FaSync
                        className="icone"
                        title="Atualizar Status da GR"
                        onClick={() => handleAtualizarStatusGR(carga.id)}
                    />
                ) : (
                    <FaUserEdit
                        className={`icone ${(carga.status_gr !== "negada" || !carga.motorista_gr_negada) ? 'disabled' : ''}`}
                        title="Retificar Dados"
                        onClick={() => {
                            setRetificarData(carga.motorista_gr_negada);
                            setRetificarCandidatura(carga.candidatura_gr_negada);
                            setRetificarCarga(carga.id);
                            setRetificarModal(true);
                        }}
                    />
                )}
                <FaTrashAlt
                    className="icone"
                    onClick={() => removerCarga(carga.id)}
                    title="Remover Carga"
                ></FaTrashAlt>
            </div>

            {cargaEscolhida && (
                <ModalDadosCarga
                    abrirModalDadosCarga={abrirModalDadosCarga}
                    setAbrirModalDadosCarga={(bool) => setAbrirModalDadosCarga(bool)}
                    carga={cargaEscolhida}
                    atualizarCargas={() => onUpdate(carga.id)}
                    fecharModal={fecharModal}
                    removerCarga={() => removerCarga(carga.id)}
                />
            )}

            {modalPropostaCarga && (
                <ModalPropostaCarga
                    atualizarCargas={() => onUpdate(carga.id)}
                    moverCarga={() => moverCarga(carga.id)}
                    carga={cargaEscolhida}
                    modalPropostaCarga={modalPropostaCarga}
                    handleModalPropostaCarga={handleModalPropostaCarga}
                />
            )}
        </div>
    );
};

export default CardsNovasCargas;