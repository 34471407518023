import React, { memo, useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FaUserPlus, FaEye, FaTrashAlt, FaEdit } from 'react-icons/fa'
import { ImSearch } from 'react-icons/im'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import api from '../../../../services/api'

const GerenciarCarroceria = () => {
  const history = useHistory()

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/carroceria', breadcrumb: 'Carroceria' },
    { path: '/acesso/carroceria/gerenciar-carroceria', breadcrumb: 'Gerenciar Carrocerias' },
  ]

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const [searchCarroceria, setSearchCarroceria] = useState('')
  const [tiposCarrocerias, setCarrocerias] = useState([])
  const [quantidadePaginas, setQuantidadePaginas] = useState(1)
  const [paginaAtual, setPaginaAtual] = useState(0)

  const requisitarCarrocerias = useCallback((paginaSelecionada, offSet) => {
    setPaginaAtual(paginaSelecionada)

    api.get(`tipocarroceria/?offset=${offSet ? offSet : 0}`)
      .then(function (response) {
        setQuantidadePaginas(Math.ceil(response.data.count / 30))
        setCarrocerias(response.data.results)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (searchCarroceria === '') {
      forceUpdate()
      requisitarCarrocerias()
    }
  }, [requisitarCarrocerias, forceUpdate, searchCarroceria])

  const removerCarroceria = (nome, id) => {
    confirmAlert({
      title: "Remover Carroceria",
      message: `Deseja realmente remover a carroceria "${nome}" do sistema?`,
      overlayClassName: "alert-remover-caminhao",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            api.delete(`tipocarroceria/${id}/`)
              .then(function () {
                requisitarCarrocerias()
              })
              .catch(function (error) {
                console.log(error)
              })
          },
          className: "botao-confirmar"
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar"
        }
      ]
    })
  }

  return (
    <div className="container-carroceria">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="gerenciar-carroceria">
        <div className="container-topo">
          <div>
            <p className="titulo-pagina">Gerenciar Carrocerias</p>
            <p className="subtitulo-pagina">Realize o filtro para busca da carroceria desejada</p>
          </div>

          <button className="btn-nova-carroceria" onClick={() => history.push("/acesso/carroceria/nova-carroceria")}>
            <FaUserPlus className="icone"></FaUserPlus>
            Nova Carroceria
          </button>
        </div>

        <div className="container-search">
          <div className="input-search">
            <input
              type="text"
              placeholder="Buscar Carroceria (Nome)"
              value={searchCarroceria}
              onChange={(e) => setSearchCarroceria(e.target.value)}
            />
            <ImSearch className="icone"></ImSearch>
          </div>

          <button className="btn-buscar">Buscar</button>
        </div>

        <div className="container-tabela-listar-carrocerias">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome Carroceria</th>
                <th>Ações</th>
              </tr>
            </thead>

            <tbody>
              {tiposCarrocerias.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr className="divisoria-linhas-tabela"></tr>
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.tipo}</td>

                      <td className="coluna-acoes">
                        <Link to={`/acesso/carroceria/visualizar-carroceria/${item.id}`}>
                          <FaEye className="icone"></FaEye>
                        </Link>
                        <Link to={`/acesso/carroceria/editar-carroceria/${item.id}`}>
                          <FaEdit className="icone"></FaEdit>
                        </Link>
                        <FaTrashAlt className="icone" onClick={() => removerCarroceria(item.tipo, item.id)}></FaTrashAlt>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="container-paginacao-carrocerias">
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próxima"}
            breakLabel={"..."}
            pageCount={quantidadePaginas}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={paginaAtual}
            onPageChange={(e) => requisitarCarrocerias(e.selected, e.selected * 30)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(GerenciarCarroceria)