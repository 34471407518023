import React from 'react';

const Step1 = ({ nfes, checked, onToggleCheck}) => {
  return (
    <div className="selecionar-notas-box">
      {nfes.length === 0 ? (
        <div className="sem-notas">
          Sem NF-e's Disponíveis para Essa Operação Nessa Carga.
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Série</th>
              <th>Número</th>
              <th>Remetente</th>
              <th>Destinatário</th>
              <th>CIF/FOB</th>
            </tr>
          </thead>
          <tbody>
            {nfes.map((item) => (
              <tr key={item.id} className={`${checked.includes(item) ? 'linha-selecionada' : ''}`}>
                <td>
                  {checked.includes(item) ? (
                    <div className="checked-box" onClick={() => onToggleCheck(item, false)} />
                  ) : (
                    <div className="unchecked-box" onClick={() => onToggleCheck(item, true)} />
                  )}
                </td>
                <td>{item.serie}</td>
                <td>{item.numero}</td>
                <td>{item.remetente_razao_social}</td>
                <td>{item.destinatario_razao_social}</td>
                <td>{item.modalidade_frete}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default Step1;
