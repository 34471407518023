import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useFormikContext } from 'formik';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useClientsTableById } from '../../../../../core/queries';
import { Button } from '../../../../../components/common';
import { ModalCopiarTabelaFrete } from '../../../../../modals/modal-copiar-tabela-frete';
import TabelaGeneralidades from "./TabelaGeneralidades";
import TabelaTiposFrete from "./TabelaTiposFrete";
import TabelaDadosPrincipais from "./TabelaDadosPrincipais";
import api from '../../../../../services/api';
import './TabelaPrecos.scss'

const TabelaPrecos = () => {
  const match = useRouteMatch();
  const isReadOnly = match.path === '/acesso/clientes/tabelas/visualizar/:id' ? true : false;
  const { setFieldValue, setValues } = useFormikContext();
  const { id } = useParams();
  const { data } = useClientsTableById(id);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (id) {
      return;
    }

    const fetch = async () => {
      try {
        const { data } = await api.get(`tipoveiculo/`);
        const dadosVeiculos = data.results;

        setFieldValue('combinado_veiculos', dadosVeiculos.map((item) => ({
          veiculo: item.id,
          veiculo_nome: item.tipo,
          valor: 0,
          tipo: 'FIXO',
          pedagio: 0,
          pedagio_km: 0,
        })));
      } catch { };
    };

    fetch();
  }, [id, setFieldValue]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const { faixas } = data.peso;
    let pesos = [];
    let distancias = []
    faixas.forEach((item) => {
      const pesoIdx = pesos.find((sub) => {
        return sub.peso_inicial === item.peso_inicial && sub.peso_final === item.peso_final;
      });
      const distIdx = distancias.find((sub) => {
        return sub.distancia_inicial === item.distancia_inicial && sub.distancia_final === item.distancia_final;
      });

      if (!pesoIdx && (parseFloat(item.peso_inicial) !== 0 || parseFloat(item.peso_final) !== 0)) {
        pesos.push({
          peso_inicial: item.peso_inicial,
          peso_final: item.peso_final,
        });
      }
      if (!distIdx) {
        distancias.push({
          distancia_inicial: item.distancia_inicial,
          distancia_final: item.distancia_final,
        });
      }
    });

    const novasFaixas = distancias.map((dist) => {
      const valores = pesos.map((peso) => {
        const faixaEncontrada = faixas.find((faixa) => {
          return (
            faixa.peso_inicial === peso.peso_inicial &&
            faixa.peso_final === peso.peso_final &&
            faixa.distancia_inicial === dist.distancia_inicial &&
            faixa.distancia_final === dist.distancia_final
          );
        });
        if (!faixaEncontrada) {
          return { valor: 0 };
        }
        return { valor: faixaEncontrada.valor };
      });
      const faixaExcedente = faixas.find((faixa) => {
        return (
          faixa.distancia_inicial === dist.distancia_inicial &&
          faixa.distancia_final === dist.distancia_final && 
          parseFloat(faixa.peso_inicial) === 0 && 
          parseFloat(faixa.peso_final) === 0
        )
      });
      const excedente = faixaExcedente ? faixaExcedente.valor : 0
      return {
        ...dist,
        valores,
        excedente,
      }
    })

    const formData = {
      ...data,
      peso: {
        ...data.peso,
        pesos,
        faixas: novasFaixas,
      },
      cliente: data.cliente.id,
    };
    if (formData.combinado_veiculos) {
      formData.combinado_veiculos = formData.combinado_veiculos.map((item) => ({
        veiculo: item.veiculo.id,
        veiculo_nome: item.veiculo.tipo,
        valor: item.valor,
        tipo: item.tipo,
        pedagio: item.pedagio,
        pedagio_km: item.pedagio_km,
      }))
    }
    setValues(formData);
  }, [data, setValues]);

  const handleCopyTable = (tabela) => {
    if (!tabela) {
      return;
    }
    const formData = {
      ...tabela,
      cliente: tabela.cliente.id,
    };
    if (formData.combinado_veiculos) {
      formData.combinado_veiculos = formData.combinado_veiculos.map((item) => ({
        veiculo: item.veiculo.id,
        veiculo_nome: item.veiculo.tipo,
        valor: item.valor,
        tipo: item.tipo,
        pedagio: item.pedagio,
        pedagio_km: item.pedagio_km,
      }))
    }
    setValues(formData);
    setModalOpen(false);
  }

  return (
    <div className="tabela-precos">
      {!isReadOnly && (
        <div className="action-copy-bar">
          <Button type="button" variant="detail" onClick={() => setModalOpen(true)}>
            Copiar Tabela de outro cliente
            <FaPlus className="icone"></FaPlus>
          </Button>
        </div>
      )}

      <TabelaDadosPrincipais />
      <TabelaTiposFrete />
      <TabelaGeneralidades />

      <ModalCopiarTabelaFrete
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirmCopy={handleCopyTable}
      />
    </div>
  )
}

export default TabelaPrecos;
