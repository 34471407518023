import React, { memo, useRef, useEffect, useState } from "react"

import './styles.css'

import api from "../../../../../../../services/api"

const FormularioEditarRelatorio = ({ idDocumentoSelecionado, setIdDocumentoSelecionado, requisitarDocumentos }) => {
  const [modalidadeFrete, setModalidadeFrete] = useState('')

  const nfe = useRef('')
  const cliente = useRef('')
  const cidadeUf = useRef('')
  const peso = useRef('')
  const valorTnCteSelecionado = useRef('')
  const valorTnCte = useRef('')
  const valorCobrancaSelecionado = useRef('')
  const valorCobranca = useRef('')
  const descargaTnSelecionada = useRef('')
  const descargaTn = useRef('')
  const tipoPagamentoSelecionado = useRef('')
  const previsaoImpostos = useRef('')

  const salvarAlteracoes = () => {
    let dados = {
      chave: nfe.current.value,
      peso: peso.current.value,
      valor_cte_tipo: valorTnCteSelecionado.current.value,
      valor_cte: valorTnCte.current.value,
      valor_cobranca_tipo: valorCobrancaSelecionado.current.value,
      valor_cobranca: valorCobranca.current.value,
      valor_descarga_tipo: descargaTnSelecionada.current.value,
      valor_descarga: descargaTn.current.value,
      tipo_pagamento: tipoPagamentoSelecionado.current.value,
      previsao_impostos: previsaoImpostos.current.value
    }

    if (modalidadeFrete === "CIF") {
      dados.remetente_razao_social = cliente.current.value
    } else {
      dados.destinatario_razao_social = cliente.current.value
    }

    api.patch(`documentos_fiscais/nfe/${idDocumentoSelecionado}/`, dados)
      .then(function () {
        setIdDocumentoSelecionado(null)
        requisitarDocumentos()
      })
      .catch(function (error) {
        console.log(error.response)
      })
  }

  useEffect(() => {
    api.get(`documentos_fiscais/nfe/${idDocumentoSelecionado}/`)
      .then(function (response) {
        let dadosDocumento = response.data
        console.log("🚀 ~ file: index.js ~ line 55 ~ dadosDocumento", dadosDocumento)

        setModalidadeFrete(dadosDocumento?.modalidade_frete)
        nfe.current.value = dadosDocumento?.chave
        cliente.current.value = dadosDocumento?.modalidade_frete === "CIF"
          ? dadosDocumento?.remetente_razao_social
          : dadosDocumento?.destinatario_razao_social
        cidadeUf.current.value = dadosDocumento.modalidade_frete === "CIF"
          ? `${dadosDocumento?.remetente_endereco.nome_municipio}-${dadosDocumento?.remetente_endereco.uf}`
          : `${dadosDocumento?.destinatario_endereco.nome_municipio}-${dadosDocumento?.destinatario_endereco.uf}`
        peso.current.value = dadosDocumento?.peso || 0
        valorTnCteSelecionado.current.value = dadosDocumento?.valor_cte_tipo
        valorTnCte.current.value = dadosDocumento?.valor_cte || 0
        valorCobrancaSelecionado.current.value = dadosDocumento?.valor_cobranca_tipo
        valorCobranca.current.value = dadosDocumento?.valor_cobranca || 0
        descargaTnSelecionada.current.value = dadosDocumento?.valor_descarga_tipo
        descargaTn.current.value = dadosDocumento?.valor_descarga || 0
        tipoPagamentoSelecionado.current.value = dadosDocumento?.tipo_pagamento
        previsaoImpostos.current.value = dadosDocumento?.previsao_impostos || 0
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idDocumentoSelecionado])

  return (
    <div className="container-editar-relatorio">
      <p className="titulo">Dados do Carregamento </p>

      <div className="container-inputs-3col">
        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={nfe}
          />
          <span>NF-e</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={cliente}
          />
          <span>Cliente</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={cidadeUf}
            disabled
          />
          <span>Cidade-UF</span>
        </div>
      </div>

      <div className="container-inputs-3col">
        <div className="container-input-comum">
          <input
            required
            type="text"
            ref={peso}
          />
          <span>Peso</span>
        </div>

        <div className="container-input-select">
          <select ref={valorTnCteSelecionado}>
            <option value='tonelada'>Valor TN CT-e</option>
            <option value='total'>Valor Total CT-e</option>
          </select>

          <input
            required
            type="number"
            ref={valorTnCte}
          />
        </div>

        <div className="container-input-select">
          <select ref={valorCobrancaSelecionado}>
            <option value='tonelada'>Valor Cobrança TN</option>
            <option value='total'>Valor Cobrança Total</option>
          </select>

          <input
            required
            type="number"
            ref={valorCobranca}
          />
        </div>
      </div>

      <div className="container-inputs-3col">
        <div className="container-input-select">
          <select ref={descargaTnSelecionada}>
            <option value='tonelada'>Descarga por TN</option>
            <option value='total'>Descarga Total</option>
          </select>

          <input
            required
            type="number"
            ref={descargaTn}
          />
        </div>

        <div className="container-input-comum">
          <select
            required
            ref={tipoPagamentoSelecionado}
          >
            <option value='boleto'>Boleto</option>
            <option value='pix'>PIX</option>
            <option value='ted-doc'>TED/DOC</option>
            <option value='mot-rec'>Mot. Rec</option>
            <option value='planilha'>Planilha</option>
          </select>
          <span>Tipo de Pagamento</span>
        </div>

        <div className="container-input-comum">
          <input
            required
            type="number"
            ref={previsaoImpostos}
          />
          <span>Previsão de Impostos</span>
        </div>
      </div>

      <button
        className="btn-salvar"
        onClick={salvarAlteracoes}
      >
        Salvar Alteração
      </button>
    </div>
  )
}

export default memo(FormularioEditarRelatorio)