import React from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { GiWallet } from 'react-icons/gi';
import Modal from "react-modal";
import { useHistory } from 'react-router-dom';
import { useSessionState } from '../../../services/useSessionState';
import "./styles.css";

const ModalAlertaCoins = () => {
  const history = useHistory();

  const [alertCoins, setAlertCoins] = useSessionState('@RASTRO:COINS_NOTIFICATION', () => {
    if (!localStorage.getItem("@RASTRO:USER")) {
      return false;
    }
    const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
    if (!dadosUsuario.notificar_coins) {
      return false;
    }
    return true;
  });

  const handleClose = () => {
    setAlertCoins(false);
  }

  const handleGoToBuy = () => {
    handleClose();
    history.push('/acesso/rastro-coins');
  }

  const handleGoToDetail = () => {
    handleClose();
    history.push('/acesso/historico-transacoes');
  }

  if (!alertCoins) {
    return null;
  }
  return (
    <Modal
      isOpen={alertCoins}
      className="modal-alerta-coins"
      overlayClassName="overlay-modal-alerta-coins"
      ariaHideApp={false}
    >
      <div className="alerta-coins">
        <RiCloseCircleFill
          className="icone-fechar"
          onClick={handleClose}
        />

        <GiWallet className="wallet" />

        <h1 className="title">
          Suas Rastro Coins estão acabando
        </h1>

        <p className="description">
          Para continuar operando dentro da Rastro você precisa comprar mais Rastro Coins.
        </p>

        <div className="actions">
          <button type="button" className="close" onClick={handleClose}>Fechar</button>
          <button type="button" className="detail" onClick={handleGoToDetail}>Ver Detalhes de Consumo</button>
          <button type="button" className="buy" onClick={handleGoToBuy}>Comprar Rastro Coins</button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAlertaCoins;
