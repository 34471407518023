import { useRouteMatch } from 'react-router-dom'
import {
  FormSectionTitle,
  Checkbox,
  InputContainer,
  APISelect,
  FourCols,
  TwoCols,
} from '../../../../../../components/forms';
import { TabelaRota } from './TabelaRota';

const TabelaDadosPrincipais = () => {
  const match = useRouteMatch();
  const isReadOnly = match.path === '/acesso/clientes/tabelas/visualizar/:id' ? true : false;

  return (
    <>
      <FormSectionTitle>Dados Principais</FormSectionTitle>

      <FourCols>
        <Checkbox
          name="ativa"
          label="Tabela Ativa?"
          disabled={isReadOnly}
        />
        <Checkbox
          name="ida_e_volta"
          label="Frete Ida e Volta"
          disabled={isReadOnly}
        />
      </FourCols>
      <FourCols>
        <APISelect
          name="cliente"
          label="Cliente"
          nameField="nome_completo"
          pageSize={10}
          route="/fracionado/clientes/"
          disabled={isReadOnly}
        />
        <APISelect
          name="produto.produto"
          label="Tipo de Produto"
          nameField="produto"
          pageSize={10}
          route="/produtocarga/"
          disabled={isReadOnly}
        />
      </FourCols>

      <FormSectionTitle small>Tipo de Rota</FormSectionTitle>

      <TwoCols>
        <TabelaRota />
      </TwoCols>
    </>
  )
}

export default TabelaDadosPrincipais;