import React, { useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { AsyncPaginate } from 'react-select-async-paginate';
import api from '../../../services/api';
import '../input/input.scss';
import '../select/select.scss';
import clsx from 'clsx';

export const APISelect = ({
  label,
  small,
  
  route,
  pageSize,
  nameField,

  ...props
}) => {
  const selectRef = useRef(null);
  const [rawField, meta, helpers] = useField(props);
  const [rawInitialValue, setRawInitialValue] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState('');

  let value = null;
  const currentSelection = selectRef.current?.props?.options?.find((item) => item.value === rawField.value);
  if (currentSelection) {
    value = currentSelection;
  }
  if (!value && rawInitialValue) {
    value = rawInitialValue;
  }

  useEffect(() => {
    if (rawInitialValue) {
      return;
    }

    const fetch = async () => {
      if (!rawField.value) {
        return;
      }
      if (selectRef.current?.props?.options?.length) {
        return;
      }

      const path = `${route}${rawField.value}/`;
      try {
        const { data } = await api.get(path);
        
        setRawInitialValue({
          label: data[nameField],
          value: rawField.value,
        });
      } catch {}
    };

    fetch();
  }, [rawField.value, route, nameField, rawInitialValue]);

  const field = {
    ...rawField,
    onChange: (e) => {
      const { value } = e;
      helpers.setValue(value);
    },
    value,
  }

  return (
    <div className="form-input-container">
      <div
        className={clsx({
          'form-select': true,
          'small': small,
          'with-value': !!field.value,
          'with-search': !!searchInputValue,
        })}
      >
        <AsyncPaginate
          selectRef={selectRef}
          placeholder=""
          classNamePrefix="form_select"
          isDisabled={props.disabled || props.isDisabled}
          loadOptions={async (search, options, additional) => {
            const { page } = additional || { page: 1 };
            const path = `${route}?search=${search}&offset=${page - 1 * pageSize}&limit=${pageSize}`;
            const { data } = await api.get(path, search);
            
            const rawOptions = [];
            if (!options.length) {
              rawOptions.push({ label: 'Selecionar', value: '' });
            }

            return {
              options: [
                ...rawOptions,
                ...data.results.map(item => ({
                  label: item[nameField],
                  value: item.id,
                })),
              ],
              hasMore: !!data.next,
              additional: {
                page: Number(page || 1) + 1,
              },
            };
          }}
          onInputChange={setSearchInputValue}
          loadingMessage={() => <>Carregando...</>}
          debounceTimeout={400}
          menuPosition="fixed"
          {...field}
          {...props}
        />
        {label && (<span>{label} {props.required && <i>*</i>}</span>)}
      </div>
      {meta.touched && meta.error && (
        <span className="form-input-error">{meta.error}</span>
      )}
    </div>
  );
}
