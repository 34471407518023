import React, { useRef } from "react";

import "./styles.css";

import pix from "../../../../../../assets/pix.png";
import qrCode from "../../../../../../assets/qr-code.png";
import copy from "../../../../../../assets/copy.png";

const Pix = ({ handleModalPagamento }) => {
  const codigoPix = useRef(null);

  const copiarCodigo = () => {
    codigoPix.current.select();
    document.execCommand("copy");
  };

  return (
    <div className="pagamento-pix">
      <p className="titulo">Compre sua Rastro Coins</p>
      <p className="subtitulo">Descrição Da Compra</p>

      <div className="quadro-totais">
        <div>
          <p>Total Rastro Coins</p>
          <input
            type="text"
            placeholder="Total..."
            value={`RC - ${34100}`}
            disabled
          />
        </div>

        <div>
          <p>R$ Total A Pagar - 6 Meses</p>
          <input
            type="text"
            placeholder="Total..."
            value={`R$ ${18661.23}`}
            disabled
          />
        </div>
      </div>

      <hr className="separador" />

      <img src={pix} alt="" className="icone-pagamento" />

      <p className="subtitulo centralizado">
        Faça o pagamento através do QRCode ou copie o código do pix abaixo:
      </p>

      <img src={qrCode} alt="" className="icone-qr-code" />

      <div className="codigo">
        <input ref={codigoPix} value="asdsdsdasd-dbfdbfbdfbf"></input>
        <img src={copy} alt="pix" onClick={copiarCodigo} />
      </div>

      <button onClick={handleModalPagamento}>Concluir</button>
    </div>
  );
};

export default Pix;
