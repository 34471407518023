import React, { memo, useState, useEffect, useCallback } from "react";
import Modal from "react-modal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FiEdit } from 'react-icons/fi';
import "react-tabs/style/react-tabs.css";
import { BsFillTelephoneFill, BsCheckLg, BsCardImage } from "react-icons/bs";
import { FiTruck } from "react-icons/fi";
import {
  FaRegIdCard,
  FaMoneyBillWave,
  FaShareSquare,
  FaRegCalendarAlt,
  FaTag,
  FaTruck,
  FaUserTie,
} from "react-icons/fa";
import {
  RiCloseCircleFill,
  RiFileHistoryLine,
  RiUserReceivedLine,
  RiMoneyDollarBoxLine,
} from "react-icons/ri";
import { GiPathDistance } from "react-icons/gi";
import { GrDocumentText, GrMapLocation } from "react-icons/gr";
import { MdWifiTethering, MdClear } from "react-icons/md";
// import Rating from 'react-rating'
import { parseISO, format, intervalToDuration } from "date-fns";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./styles.css";

import api from "../../../../services/api";

import Documentos from "./Documentos";
import Eventos from "./Eventos";
import Tracking from "./Tracking";
import Imagens from "./Imagens";
import Financeiro from "./Financeiro";
import ModalHistoricoNegociacoes from "./ModalHistoricoNegociacoes";
import ModalPerfilMotorista from "../Painel/Quadros/NovasCargas/ModalPerfilMotorista";
import ModalAvaliacaoMotorista from "../../../../components/plataformaAcessada/Notificacoes/ModalAvaliacaoMotorista";
import { useMessages } from '../../../../context/messages-context';
import EditarPontosParada from "./EditarPontosParada";
import EditarColetas from './EditarColetas';

const ModaPerfilMotoristaCarga = ({
  modalPerfilMotoristaCarga,
  handleModalPerfilMotoristaCarga,
  idCarga,
  moverCarga,
}) => {
  const { showError } = useMessages();

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
  const tipoUsuario = dadosUsuario.user_type;
  const idUsuario =
    tipoUsuario === "transportadora" ||
      tipoUsuario === "colaborador_transportadora"
      ? dadosUsuario.transportadora_id
      : tipoUsuario === "embarcador" || tipoUsuario === "colaborador_embarcador"
        ? dadosUsuario.embarcador_id
        : dadosUsuario.id;

  const [carga, setCarga] = useState(null);
  const [previsaoEntrega, setPrevisaoEntrega] = useState(null);
  const [checkpointCarga, setCheckpointCarga] = useState(null);
  const [fretePago, setFretePago] = useState(false);
  const [modalHistoricoNegociacoes, setModalHistoricoNegociacoes] =
    useState(false);
  const [modalPerfilMotorista, setModalPerfilMotorista] = useState(false);
  const [modalAvaliacaoMotorista, setModalAvaliacaoMotorista] = useState(false);
  const [editandoParadas, setEditandoParadas] = useState(false);

  const handleModalHistoricoNegociacoes = useCallback(() => {
    if (modalHistoricoNegociacoes) {
      setModalHistoricoNegociacoes(false);
      document.querySelector("body").style.overflow = "visible";
    } else {
      setModalHistoricoNegociacoes(true);
      document.querySelector("body").style.overflow = "hidden";
    }
  }, [modalHistoricoNegociacoes]);

  const handleModalPerfilMotorista = useCallback(() => {
    if (modalPerfilMotorista) {
      setModalPerfilMotorista(false);
      document.querySelector("body").style.overflow = "visible";
    } else {
      setModalPerfilMotorista(true);
      document.querySelector("body").style.overflow = "hidden";
    }
  }, [modalPerfilMotorista]);

  const handleModalAvaliacaoMotorista = useCallback(() => {
    if (modalAvaliacaoMotorista) {
      api
        .post(`cargas/${idCarga}/liberar_pagamento/`)
        .then(function () {
          setFretePago(true);
          setModalAvaliacaoMotorista(false);
          moverCarga();
          handleModalPerfilMotoristaCarga();
          document.querySelector("body").style.overflow = "visible";
        })
        .catch(function (error) {
          console.log(error);
          document.querySelector("body").style.overflow = "visible";
        });
    } else {
      setModalAvaliacaoMotorista(true);
      document.querySelector("body").style.overflow = "hidden";
    }
  }, [
    modalAvaliacaoMotorista,
    idCarga,
    moverCarga,
    handleModalPerfilMotoristaCarga,
  ]);

  const registrarLiberacaoPortaria = (onClose) => {
    const idCheckpoint = checkpointCarga.id;

    api
      .patch(`cargas_checkpoints/${idCheckpoint}/`, {
        entrada_carregamento: true,
        entrada_carregamento_data: new Date().toISOString(),
      })
      .then(function () {
        onClose();
        handleModalPerfilMotoristaCarga();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const liberarPortaria = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="container-alert-liberar-entrada">
            <div className="titulo">
              <p>Liberar Entrada</p>
              <RiCloseCircleFill
                className="icone fechar"
                onClick={onClose}
              ></RiCloseCircleFill>
            </div>

            <div className="conteudo">
              <p className="pergunta">Deseja realmente liberar a entrada?</p>

              <div className="detalhes-entrada">
                <div className="card-agendamento">
                  <div className="linha">
                    <div className="id">
                      <p>
                        <span>ID:</span> {carga?.id}
                      </p>
                    </div>

                    <div className="pedido">
                      <p>
                        <span>Pedido: </span>
                        {carga?.pedidos.map((item, index) =>
                          index < carga.pedidos.length - 1
                            ? `${item.numero}, `
                            : ` ${item.numero}`
                        )}
                      </p>
                    </div>

                    <div className="transportadora">
                      <FiTruck className="icone"></FiTruck>
                      <p>{carga?.transportadora.razao_social}</p>
                    </div>
                  </div>

                  <div className="linha">
                    <div className="carga">
                      <FaTag className="icone"></FaTag>
                      <p>{carga?.produto.produto}</p>
                    </div>

                    <div className="cidade">
                      <p>
                        {carga?.origem.endereco.municipio}/
                        {carga?.origem.endereco.uf} -
                        {carga?.pontos_parada.map((item, index) => {
                          return index !== 0
                            ? ` | ${item.destino.endereco.municipio}/${item.destino.endereco.uf}`
                            : ` ${item.destino.endereco.municipio}/${item.destino.endereco.uf}`;
                        })}
                      </p>
                    </div>
                  </div>

                  <div className="linha">
                    <div className="caminhao">
                      <FaTruck className="icone"></FaTruck>
                      <p>
                        {carga?.motorista_em_viagem.veiculo.tipo_veiculo.tipo}{" "}
                        {carga?.motorista_em_viagem.veiculo.carrocerias.map(
                          (item, index) => {
                            return index !== 0
                              ? ` | ${item.tipo.tipo}`
                              : `  ${item.tipo.tipo}`;
                          }
                        )}{" "}
                        - {carga?.motorista_em_viagem.veiculo.quantidade_eixos}{" "}
                        Eixos
                      </p>
                    </div>

                    <div className="embarque">
                      <FaRegCalendarAlt className="icone"></FaRegCalendarAlt>
                      <p>
                        <span>Embarque: </span>
                        {carga?.data_embarque
                          ? format(
                            parseISO(carga?.data_embarque),
                            "dd/MM 'às' HH:mm"
                          )
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card-agendamento">
                  <div className="linha">
                    <div className="dados-motorista">
                      <FaUserTie className="icone"></FaUserTie>
                      <div>
                        <p className="nome">
                          {carga?.motorista_em_viagem.nome_completo}
                        </p>
                        <p>
                          CPF:
                          {carga?.motorista_em_viagem.documentos.cpf.indexOf(
                            "-"
                          ) === -1
                            ? carga?.motorista_em_viagem.documentos.cpf
                              .replace(".", "")
                              .replace(
                                /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                                "$1.$2.$3-$4"
                              )
                            : carga?.motorista_em_viagem.documentos.cpf}
                        </p>
                        <p>
                          {carga?.motorista_em_viagem.veiculo.placa_cavalo} /{" "}
                          {carga?.motorista_em_viagem.veiculo.carrocerias.map(
                            (item, index) => {
                              return index !== 0
                                ? ` | ${item.placa}`
                                : `  ${item.placa}`;
                            }
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-botoes-liberar">
                <button className="negar" onClick={onClose}>
                  Não
                </button>
                <button
                  className="liberar"
                  onClick={() => registrarLiberacaoPortaria(onClose)}
                >
                  Liberar
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const finalizarEntrega = () => {
    const idCheckpoint = checkpointCarga.id;

    let data = {
      entrega_concluida: true,
      entrega_concluida_data: new Date().toISOString(),
    };
    if (!checkpointCarga?.chegada_destino) {
      data = {
        ...data,
        chegada_destino: true,
        chegada_destino_data: new Date().toISOString(),
      };
    }

    confirmAlert({
      title: "Mover Carga para Entregue",
      message: `Você está ciente que está pulando etapas do processo?`,
      overlayClassName: "alert-realizar-pagamento",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            api
              .patch(`cargas_checkpoints/${idCheckpoint}/`, data)
              .then(function () {
                api.patch(`cargas/${idCarga}/`, {
                  status: "entregue",
                }).then(() => {
                  handleModalPerfilMotoristaCarga();
                  moverCarga();
                  return;
                }).catch(function (error) {
                  console.log(error);
                });
              })
              .catch(function (error) {
                console.log(error);
              });
          },
          className: "botao-confirmar",
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar",
        },
      ],
    });
  }

  const liberarViagem = () => {
    if (!checkpointCarga?.carregamento_finalizado) {
      confirmAlert({
        title: "Liberar Viagem",
        message: `Você está ciente que está pulando etapas do processo?`,
        overlayClassName: "alert-realizar-pagamento",
        buttons: [
          {
            label: "Sim",
            onClick: () => {
              api.put(`cargas/${carga.id}/liberar_viagem/`)
                .then(() => {
                  handleModalPerfilMotoristaCarga();
                  moverCarga();
                  return;
                }).catch((err) => {
                  console.log(err);
                })
            },
            className: "botao-confirmar",
          },
          {
            label: "Não",
            onClick: () => { },
            className: "botao-cancelar",
          },
        ],
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="container-alert-liberar-viagem">
              <div className="titulo">
                <p>Liberar Viagem</p>
                <RiCloseCircleFill
                  className="icone fechar"
                  onClick={onClose}
                ></RiCloseCircleFill>
              </div>

              <div className="conteudo">
                <p className="pergunta">
                  Para prosseguir com a liberação da viagem, tenha certeza de
                  que todos os dados estão corretos, e de que os documentos
                  necessário forma anexados. Você confirma a liberação de
                  viagem?
                </p>

                <div className="container-botoes-liberar">
                  <button className="negar" onClick={onClose}>
                    Voltar e Verificar
                  </button>
                  <button
                    className="liberar"
                    onClick={() => {
                      api.put(`cargas/${carga.id}/liberar_viagem/`)
                        .then(() => {
                          handleModalPerfilMotoristaCarga();
                          moverCarga();
                          return;
                        }).catch((err) => {
                          console.log(err);
                        })
                    }}
                  >
                    Liberar Viagem
                  </button>
                </div>
              </div>
            </div>
          );
        },
      });
    }
  };

  const realizarPagamento = () => {
    confirmAlert({
      title: "Liberar Pagamento",
      message: `Deseja realmente liberar o pagamento da carga de ID ${idCarga}?`,
      overlayClassName: "alert-realizar-pagamento",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            handleModalAvaliacaoMotorista();
          },
          className: "botao-confirmar",
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar",
        },
      ],
    });
  };

  const removerMotorista = (idCarga) => {
    confirmAlert({
      title: "Remover Motorista",
      message: `Deseja realmente remover o motorista da carga ID ${idCarga}?`,
      overlayClassName: "alert-realizar-pagamento",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            api
              .post(`cargas/${idCarga}/remover_motorista/`)
              .then(function () {
                moverCarga();
                handleModalPerfilMotoristaCarga();
              })
              .catch(function (error) {
                if (error?.response?.data?.detail) {
                  showError("Erro", error?.response?.data?.detail)
                } else {
                  showError("Erro", 'Houve um erro ao remover o motorista.')
                }
              });
          },
          className: "botao-confirmar",
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar",
        },
      ],
    });
  };

  const obterDadosCarga = useCallback(() => {
    api
      .get(`cargas_full/${idCarga}/`)
      .then(function (response) {
        setCarga(response.data);
        setFretePago(response.data.status_pagamento);
        setPrevisaoEntrega(response.data.previsao_entrega);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idCarga]);

  const verificarStatusEmbarque = () => {
    if (carga?.status_temporal === "no-prazo" && !carga?.possivel_atraso) {
      return "no-prazo";
    }

    if (carga?.possivel_atraso) {
      return "possivel-atraso";
    }

    if (carga?.status_temporal === "atraso") {
      return "atrasado";
    }
  };

  const atrasoEMaiorQuePermitido = (dataEmbarque) => {
    let atraso = intervalToDuration({
      start: new Date(dataEmbarque),
      end: new Date(),
    });

    const { days, hours, minutes, months, years } = atraso;

    return (days || hours || months || years) > 1 || minutes > 30
      ? true
      : false;
  };

  const websocketPrevisaoEntregaECheckpoints = useCallback(() => {
    let wsPrevisaoEntregaECheckpoints = new WebSocket(
      `${process.env.REACT_APP_LINK_API_WEBSOCKET}ws/cargas/notify/${
        tipoUsuario === "colaborador_transportadora"
        ? "transportadora"
        : tipoUsuario === "colaborador_embarcador"
          ? "embarcador"
          : tipoUsuario
      }/${idUsuario}`
    );

    wsPrevisaoEntregaECheckpoints.onopen = function () {
      console.log(
        "WebSocket carga_atualizada (previsão de entrega) e checkpoint_atualizado conectado"
      );
    };

    wsPrevisaoEntregaECheckpoints.onmessage = function (e) {
      let JSONMessage = JSON.parse(e.data);

      if (JSONMessage.tipo === "carga_atualizada") {
        obterDadosCarga();
        return;
      }

      if (JSONMessage.tipo === "checkpoint_atualizado") {
        setCheckpointCarga(JSONMessage.checkpoint);
        return;
      }
    };

    wsPrevisaoEntregaECheckpoints.onclose = function () {
      wsPrevisaoEntregaECheckpoints = null;
      setTimeout(function () {
        websocketPrevisaoEntregaECheckpoints();
      }, 5000);
    };
  }, [tipoUsuario, idUsuario, obterDadosCarga]);

  const RemoverMotoristaButton = ({ onClick }) => (
    <button className="remover-motorista" onClick={onClick}>
      <MdClear className="icone" />
      <p>Remover Motorista</p>
    </button>
  );

  useEffect(() => {
    websocketPrevisaoEntregaECheckpoints();
  }, [websocketPrevisaoEntregaECheckpoints]);

  useEffect(() => {
    obterDadosCarga();

    api
      .get(`cargas_checkpoints/?carga=${idCarga}`)
      .then(function (response) {
        setCheckpointCarga(response.data.results[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [obterDadosCarga, idCarga]);

  return (
    <Modal
      isOpen={modalPerfilMotoristaCarga}
      onRequestClose={handleModalPerfilMotoristaCarga}
      className="modal-perfil-motorista-carga"
      overlayClassName="overlay-modal-perfil-motorista-carga"
      ariaHideApp={false}
    >
      <div className="detalhes-motorista">
        <div className="quadro-topo">
          <RiCloseCircleFill
            className="icone-fechar"
            onClick={handleModalPerfilMotoristaCarga}
          ></RiCloseCircleFill>

          {/* <div className="rating">
            <Rating
              initialRating={carga?.motorista_em_viagem.estatisticas?.avaliacao || 0}
              emptySymbol={<BsStar className="icone vazio"></BsStar>}
              fullSymbol={<BsStarFill className="icone preenchido"></BsStarFill>}
            />
          </div> */}
        </div>

        <div className="foto-id">
          <div className="telefones">
            <div>
              <BsFillTelephoneFill className="icone"></BsFillTelephoneFill>
              <p>
                {carga?.motorista_em_viagem.telefone1
                  .replace(/\D+/g, "")
                  .replace(/(\d{2})(\d)/, "($1) $2")
                  .replace(/(\d{4})(\d)/, "$1-$2")
                  .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
                  .replace(/(-\d{4})\d+?$/, "$1")}
              </p>
            </div>
            {carga?.motorista_em_viagem.telefone2 && (
              <div>
                <BsFillTelephoneFill className="icone"></BsFillTelephoneFill>
                <p>
                  {carga?.motorista_em_viagem.telefone2
                    .replace(/\D+/g, "")
                    .replace(/(\d{2})(\d)/, "($1) $2")
                    .replace(/(\d{4})(\d)/, "$1-$2")
                    .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
                    .replace(/(-\d{4})\d+?$/, "$1")}
                </p>
              </div>
            )}
          </div>

          <img src={carga?.motorista_em_viagem.imagem_perfil} alt="" />

          <div className="id" onClick={handleModalPerfilMotorista}>
            <FaRegIdCard className="icone"></FaRegIdCard>
            <p>
              <i>ID: </i>
              {carga?.motorista_em_viagem.id}
            </p>
          </div>
        </div>

        <div className="dados">
          <p className="nome">{carga?.motorista_em_viagem.nome_completo}</p>
          <p className="cpf">
            CPF:{" "}
            {carga !== null &&
              carga.motorista_em_viagem.documentos.cpf
                .replace(/\D/g, "")
                .replace(/(\d{3})(\d)/, "$1.$2")
                .replace(/(\d{3})(\d)/, "$1.$2")
                .replace(/(\d{3})(\d{1,2})$/, "$1-$2")}
          </p>
          {
            <p className="cadastro-app">
              Cadastro:
              <span className={carga?.motorista_em_viagem?.tipo_cadastro !== 'App' ? 'cadastro-sem-app' : 'cadastro-com-app'}>
                {carga?.motorista_em_viagem?.tipo_cadastro === 'App' ? ' App' : ' Sem App'}
              </span>
            </p>
          }
        </div>

        <div className="veiculo">
          <p>
            <span>{carga?.motorista_em_viagem?.veiculo.tipo_veiculo.tipo}</span>{" "}
            -
            {carga?.motorista_em_viagem?.veiculo.carrocerias.map(
              (item, index) =>
                index < carga.motorista_em_viagem.veiculo.carrocerias.length - 1
                  ? `${item.tipo.tipo}, `
                  : ` ${item.tipo.tipo}`
            )}
          </p>

          <p className="placa">
            {carga?.motorista_em_viagem?.veiculo.placa_cavalo.substring(0, 3)}-
            {carga?.motorista_em_viagem?.veiculo.placa_cavalo.substring(4, 8)} /
            {carga?.motorista_em_viagem?.veiculo.carrocerias.map(
              (item, index) =>
                index < carga.motorista_em_viagem.veiculo.carrocerias.length - 1
                  ? `${item.placa.substring(0, 3)}-${item.placa.substring(
                    4,
                    8
                  )}, `
                  : ` ${item.placa.substring(0, 3)}-${item.placa.substring(3)}`
            )}
          </p>
        </div>

        <div className="cidade-distancia">
          <p className="cidades">
            {carga?.origem.endereco.municipio}/{carga?.origem.endereco.uf} -
            {carga?.pontos_parada.map((item, index) => {
              return index !== 0
                ? ` | ${item.destino.endereco.municipio}/${item.destino.endereco.uf}`
                : ` ${item.destino.endereco.municipio}/${item.destino.endereco.uf}`;
            })}
          </p>

          <div className="distancia">
            <GiPathDistance className="icone"></GiPathDistance>
            <p>
              {carga?.distancia_total
                ? carga?.distancia_total
                  ? Math.round(
                    Number(carga.distancia_total / 1000)
                  ).toLocaleString("pt-BR")
                  : 0
                : 0}{" "}
              Km
            </p>
          </div>

          {!editandoParadas && (
            <div className="editar-paradas">
              <FiEdit size={20} onClick={() => setEditandoParadas(true)} />
            </div>
          )}
        </div>

        {editandoParadas ? (
          <>
            {carga?.is_fracionada ? (
              <EditarColetas
                coletas={carga?.coletas}
                idCarga={carga?.id}
                onClose={() => setEditandoParadas(false)}
              />
            ) : (
              <EditarPontosParada
                pontos_parada={carga?.pontos_parada}
                idCarga={carga?.id}
                onClose={() => setEditandoParadas(false)}
                refetch={() => moverCarga()}
              />
            )}
          </>
        ) : (
          <>
            <p className="linha">
              <span>Data do Carregamento: </span>
              {carga?.checkpoints.carregamento_finalizado_data
                ? format(
                  parseISO(carga.checkpoints.carregamento_finalizado_data),
                  "dd/MM/yyyy 'às' HH:mm"
                )
                : "Aguardando carregamento"}
            </p>

            <p className="linha">
              <span>Início Viagem: </span>
              {carga?.checkpoints.em_viagem_data
                ? format(
                  parseISO(carga.checkpoints.em_viagem_data),
                  "dd/MM/yyyy 'às' HH:mm"
                )
                : "Não iniciada"}
            </p>
            {carga?.checkpoints.entrega_concluida ? (
              <p className="linha">
                <span>Data e Horário de Entregue: </span>
                {carga.checkpoints.entrega_concluida_data !== null
                  ? format(
                    parseISO(carga.checkpoints.entrega_concluida_data),
                    "dd/MM/yyyy 'às' HH:mm"
                  )
                  : "Sem Registro"}
              </p>
            ) : (
              <p className="linha">
                <span>Previsão de Entrega: </span>
                {previsaoEntrega !== null
                  ? format(parseISO(previsaoEntrega), "dd/MM/yyyy 'às' HH:mm")
                  : "Não calculada"}
              </p>
            )}

            <hr className="separador" />

            <div className="linha status">
              <div className={`marcador ${verificarStatusEmbarque()}`}></div>
              <p>
                <span>Status Entrega: </span>
                {carga ? verificarStatusEmbarque()?.replace("-", " ") : ""}
              </p>
            </div>

            <hr className="separador" />

            <div className="linha distancia">
              {(carga?.status === "embarque" || carga?.status === "transito") && (
                <MdWifiTethering className="icone"></MdWifiTethering>
              )}
              <div className="dados-distancia">
                {(carga?.status === "embarque" || carga?.status === "transito") && (
                  <p>
                    <span>
                      {carga?.status === "embarque"
                        ? "Distância até embarque"
                        : "Distância até destino"}
                      :{" "}
                    </span>
                    {carga?.distancia_destino
                      ? Math.round(
                        Number(carga.distancia_destino / 1000)
                      ).toLocaleString("pt-BR")
                      : 0}{" "}
                    Km
                  </p>
                )}

                <div>
                  {carga?.status !== "entregue" && (
                    <>
                      <p>
                        <span>Ultima localização: </span>
                        {carga?.geolocalizacoes.length > 0
                          ? format(
                            parseISO(
                              carga.geolocalizacoes[
                                carga.geolocalizacoes.length - 1
                              ].data_hora
                            ),
                            "dd/MM/yyyy 'às' HH:mm"
                          )
                          : "Não registrada"}
                      </p>

                      {carga?.geolocalizacoes.length > 0 && (
                        <a
                          href={`http://maps.google.com/maps?z=12&t=m&q=loc:${carga.geolocalizacoes[carga.geolocalizacoes.length - 1]
                            .latitude
                            }+${carga.geolocalizacoes[carga.geolocalizacoes.length - 1]
                              .longitude
                            }`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaShareSquare className="icone"></FaShareSquare>
                        </a>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            {(carga?.status === "embarque" || carga?.status === "transito") && (
              <hr className="separador" />
            )}

            <div className="linha pagamento">
              <FaMoneyBillWave className="icone"></FaMoneyBillWave>
              <p>
                <span>Status Pagamento: </span>{" "}
                {carga ? carga.status_pagamento.replace("-", " ") : ""}
              </p>
            </div>

            <div className="linha historico">
              <div onClick={handleModalHistoricoNegociacoes}>
                <RiFileHistoryLine className="icone"></RiFileHistoryLine>
                <p>Histórico de Negociações</p>
              </div>


              <>
                {
                  carga?.status !== 'transito' && carga?.status !== 'entregue' &&
                  !checkpointCarga?.viagem_liberada && (
                    <RemoverMotoristaButton onClick={() => removerMotorista(carga?.id)} />
                  )}
              </>

              {checkpointCarga?.chegada_local_embarque &&
                !checkpointCarga?.entrada_carregamento && (
                  <button className="liberar-viagem" onClick={liberarPortaria}>
                    <BsCheckLg className="icone"></BsCheckLg>
                    <p>Liberar Portaria</p>
                  </button>
                )}

              {!checkpointCarga?.viagem_liberada && (
                <button className="liberar-viagem" onClick={liberarViagem}>
                  <BsCheckLg className="icone"></BsCheckLg>
                  <p>Liberar Viagem</p>
                </button>
              )}

              {checkpointCarga?.em_viagem && !checkpointCarga?.entrega_concluida && (
                <button className="liberar-viagem" onClick={finalizarEntrega}>
                  <BsCheckLg className="icone"></BsCheckLg>
                  <p>Carga Entregue</p>
                </button>
              )}

              {carga?.status_pagamento === "a-pagar" &&
                checkpointCarga?.entrega_concluida && (
                  <button className="liberar-viagem" onClick={realizarPagamento}>
                    <BsCheckLg className="icone"></BsCheckLg>
                    <p>Liberar Pagamento</p>
                  </button>
                )}
            </div>

            {carga?.status === "entregue" && (
              <div className="linha recebimento">
                <RiUserReceivedLine className="icone"></RiUserReceivedLine>
                <div>
                  <p>
                    <span>Nome do Recebedor:</span> {carga.ocorrencia?.nome}
                  </p>
                  <p>
                    <span>RG:</span> {carga.ocorrencia?.rg}
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className="todas-informacoes">
        <Tabs>
          <TabList className="container-abas">
            <Tab className="aba" selectedClassName="selecionada">
              <GrDocumentText className="icone"></GrDocumentText>
              <p>Documentos</p>
            </Tab>
            <Tab className="aba" selectedClassName="selecionada">
              <FiTruck className="icone"></FiTruck>
              <p>Eventos</p>
            </Tab>
            <Tab className="aba" selectedClassName="selecionada">
              <GrMapLocation className="icone"></GrMapLocation>
              <p>Tracking</p>
            </Tab>
            <Tab className="aba" selectedClassName="selecionada">
              <BsCardImage className="icone"></BsCardImage>
              <p>Imagens</p>
            </Tab>
            <Tab className="aba" selectedClassName="selecionada">
              <RiMoneyDollarBoxLine className="icone"></RiMoneyDollarBoxLine>
              <p>Financeiro</p>
            </Tab>
          </TabList>

          <TabPanel>
            <Documentos idCarga={idCarga}></Documentos>
          </TabPanel>
          <TabPanel>
            <Eventos idCarga={idCarga}></Eventos>
          </TabPanel>
          <TabPanel>
            <Tracking idCarga={idCarga}></Tracking>
          </TabPanel>
          <TabPanel>
            <Imagens idCarga={idCarga}></Imagens>
          </TabPanel>
          <TabPanel>
            <Financeiro idCarga={idCarga} fretePago={fretePago}></Financeiro>
          </TabPanel>
        </Tabs>
      </div>

      {modalHistoricoNegociacoes && (
        <ModalHistoricoNegociacoes
          idCarga={idCarga}
          modalHistoricoNegociacoes={modalHistoricoNegociacoes}
          handleModalHistoricoNegociacoes={handleModalHistoricoNegociacoes}
        ></ModalHistoricoNegociacoes>
      )}

      {modalPerfilMotorista && (
        <ModalPerfilMotorista
          motorista={carga.motorista_em_viagem}
          modalPerfilMotorista={modalPerfilMotorista}
          handleModalPerfilMotorista={handleModalPerfilMotorista}
          editable={true}
          onSaved={() => obterDadosCarga()}
        />
      )}

      {modalAvaliacaoMotorista && (
        <ModalAvaliacaoMotorista
          modalAvaliacaoMotorista={modalAvaliacaoMotorista}
          handleModalAvaliacaoMotorista={handleModalAvaliacaoMotorista}
          carga={carga}
        ></ModalAvaliacaoMotorista>
      )}
    </Modal>
  );
};

export default memo(ModaPerfilMotoristaCarga);
