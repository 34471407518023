import React, { memo } from 'react'
import { FaRegCalendarAlt, FaTag, FaTruck, FaUserTie } from 'react-icons/fa'
import { FiTruck } from 'react-icons/fi'
import { BsCheckLg } from 'react-icons/bs'
import { RiCloseCircleFill } from 'react-icons/ri'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import '../styles.css'
import './styles.css'

const CardPortaria = () => {
  const liberarEntrada = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='container-alert-liberar-entrada'>
            <div className="titulo">
              <p>Liberar Entrada</p>
              <RiCloseCircleFill className="icone fechar" onClick={onClose}></RiCloseCircleFill>
            </div>

            <div className="conteudo">
              <p className="pergunta">
                Deseja realmente liberar a entrada?
              </p>

              <div className="detalhes-entrada">
                <div className="card-agendamento">
                  <div className="linha">
                    <div className="id">
                      <p><span>ID:</span> 123456</p>
                    </div>

                    <div className="pedido">
                      <p><span>Pedido: </span>987464</p>
                    </div>

                    <div className="transportadora">
                      <FiTruck className="icone"></FiTruck>
                      <p>Transportadora </p>
                    </div>
                  </div>

                  <div className="linha">
                    <div className="carga">
                      <FaTag className="icone"></FaTag>
                      <p>Lajes</p>
                    </div>

                    <div className="cidade">
                      <p>Anápolis-GO / Guarulhos-SP</p>
                    </div>
                  </div>

                  <div className="linha">
                    <div className="caminhao">
                      <FaTruck className="icone"></FaTruck>
                      <p>Carreta Graneleiro - 5 Eixos</p>
                    </div>

                    <div className="embarque">
                      <FaRegCalendarAlt className="icone"></FaRegCalendarAlt>
                      <p><span>Embarque: </span>10/08 às 8:30</p>
                    </div>
                  </div>
                </div>

                <div className="card-agendamento">
                  <div className="linha">
                    <div className="dados-motorista">
                      <FaUserTie className="icone"></FaUserTie>
                      <div>
                        <p className="nome">João Alberto Costa</p>
                        <p>CPF: 062.7894.654-95</p>
                        <p>KVJ-6132 / KNB-8765</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-botoes-liberar">
                <button className="negar" onClick={onClose}>Não</button>
                <button className="liberar" onClick={onClose}>Liberar</button>
              </div>
            </div>
          </div>
        )
      }
    })
  }

  return (
    <div className="card-agendamento portaria">
      <div className="linha">
        <div className="id">
          <p><span>ID:</span> 123456</p>
        </div>

        <div className="pedido">
          <p><span>Pedido: </span>987464</p>
        </div>

        <div className="transportadora">
          <FiTruck className="icone"></FiTruck>
          <p>Transportadora </p>
        </div>
      </div>

      <div className="linha">
        <div className="carga">
          <FaTag className="icone"></FaTag>
          <p>Lajes</p>
        </div>

        <div className="cidade">
          <p>Anápolis-GO / Guarulhos-SP</p>
        </div>
      </div>

      <div className="linha">
        <div className="caminhao">
          <FaTruck className="icone"></FaTruck>
          <p>Carreta Graneleiro - 5 Eixos</p>
        </div>

        <div className="embarque">
          <FaRegCalendarAlt className="icone"></FaRegCalendarAlt>
          <p><span>Embarque: </span>10/08 às 8:30</p>
        </div>
      </div>

      <div className="linha">
        <div className="dados-motorista">
          <FaUserTie className="icone"></FaUserTie>
          <div>
            <p className="nome">João Alberto Costa</p>
            <p>CPF: 062.7894.654-95</p>
            <p>KVJ-6132 / KNB-8765</p>
          </div>
        </div>

        <button className="liberar-entrada" onClick={liberarEntrada}>
          <BsCheckLg className="icone"></BsCheckLg>
          <p>Liberar Entrada</p>
        </button>
      </div>
    </div>
  )
}

export default memo(CardPortaria)