import { useMutation, useQueryClient } from 'react-query';
import { useSearch } from '../../hooks';
import api from '../../../services/api';
import { useMessages } from '../../../context/messages-context';

export const useCreateColetaAvulsaMutation = () => {
  const { showApiErrors } = useMessages();
  const { page } = useSearch();
  const queryClient = useQueryClient();

  return useMutation(async (data) => {
    try {
      await api.post(`fracionado/coletas/avulsa/`, data);
    } catch (err) {
      showApiErrors(err);
      return null;
    }

    await queryClient.invalidateQueries(['coletas']);
    await queryClient.refetchQueries(['coletas', page]);
    
    return true;
  });
}
