import { InputMask } from '../input-mask';

export const InputPhone = ({ ...props }) => {
  return (
    <InputMask
      {...props}
      mask="(99) 99999-9999"
      maskChar=" "
    />
  );
};
