import React, { memo, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import InputMask from 'react-input-mask'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { FaRegIdCard } from 'react-icons/fa'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import './styles.css'

import avatar from "../../../../assets/avatar.png"

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import api from "../../../../services/api"

const PerfilTransportadora = () => {
  const history = useHistory()

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/perfil-transportadora', breadcrumb: 'Perfil' },
  ]

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
  const idTransportadora = dadosUsuario.transportadora_id

  const [transportadora, setTransportadora] = useState(null)
  const [embarcador, setEmbarcador] = useState(null)

  const [razaoSocial, setRazaoSocial] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [email, setEmail] = useState('')
  const [logradouro, setLogradouro] = useState('')
  const [cidade, setCidade] = useState('')
  const [bairro, setBairro] = useState('')
  const [cep, setCep] = useState('')
  const [observacoes, setObservacoes] = useState('')

  const obtemNomeEmbarcador = (id) => {
    api.get(`embarcador/${id}`)
      .then(function (response) {
        setEmbarcador(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const alterarCadastro = () => {
    const removerMascaraCampo = (dado) => {
      return dado.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '').replaceAll('.', '').replaceAll('/', '')
    }

    let dados = {
      razao_social: razaoSocial,
      cnpj: removerMascaraCampo(cnpj),
      email: email,
      endereco: {
        municipio: cidade,
        logradouro: logradouro,
        bairro: bairro,
        cep: removerMascaraCampo(cep),
      },
      observacoes: observacoes
    }

    api.patch(`transportadora/${idTransportadora}/`, dados)
      .then(function (response) {
        confirmAlert({
          title: "Dados Alterados!",
          message: `Alteração realizada com sucesso.`,
          overlayClassName: "alert-dados-transportadora-alterados",
          buttons: [
            {
              label: "Ok"
            }
          ]
        })
      })
      .catch(function (error) {
        console.log(error.response)
      })
  }

  useEffect(() => {
    api.get(`transportadora/${idTransportadora}`)
      .then(function (response) {
        let dadosTransportadora = response.data
        setTransportadora(dadosTransportadora)

        setRazaoSocial(dadosTransportadora.razao_social)
        setCnpj(dadosTransportadora.cnpj)
        setEmail(dadosTransportadora.email)
        setLogradouro(dadosTransportadora.endereco?.logradouro || '')
        setCidade(dadosTransportadora.endereco?.municipio || '')
        setBairro(dadosTransportadora.endereco?.bairro || '')
        setCep(dadosTransportadora.endereco?.cep || '')

        if (dadosTransportadora.embarcador) {
          obtemNomeEmbarcador(dadosTransportadora.embarcador)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idTransportadora])

  return (
    <div className="container-perfil-transportadora">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <div className="perfil-transportadora">
        <div className="container-foto">
          <div className="quadro-topo">
            {embarcador && <p>Embarcador: {embarcador.razao_social}</p>}
          </div>

          <div className="foto-id">
            <img src={transportadora?.imagem_perfil ? transportadora.imagem_perfil : avatar} alt="" />
            <div className="id">
              <FaRegIdCard className="icone"></FaRegIdCard>
              <p><i>ID: </i>{transportadora?.id}</p>
            </div>
          </div>

          <div className="dados">
            <p className="nome">{transportadora?.razao_social}</p>

            <div className="telefones">
              <div>
                <BsFillTelephoneFill className="icone"></BsFillTelephoneFill>
                {
                  transportadora?.telefone
                    ? transportadora.telefone.replace(/\D+/g, '')
                      .replace(/(\d{2})(\d)/, '($1) $2')
                      .replace(/(\d{4})(\d)/, '$1-$2')
                      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
                      .replace(/(-\d{4})\d+?$/, '$1')
                    : ''
                }
              </div>
            </div>
          </div>

          {/* <div className="container-assinatura">
            <p className="titulo">Assinatura</p>

            <div className="dados-assinatura">
              <p className="nome-assinatura">"Nome assinatura"</p>
              <p className="validade">Validade: 17/12/2021</p>

              <button className="btn-assinatura">
                Gerenciar Assinatura
              </button>

              <p className="cancelar">Cancelar Assinatura</p>
            </div>
          </div> */}
        </div>

        <div className="container-campos">
          <div className="topo">
            <p>Editar Perfil</p>

            <button onClick={alterarCadastro}>Alterar</button>
          </div>

          <div className="container-inputs">
            <div className="container-inputs-2col">
              <label>
                Razão Social
                <input
                  placeholder="Dados..."
                  value={razaoSocial}
                  onChange={(e) => setRazaoSocial(e.target.value)}
                />
              </label>

              <label>
                CNPJ
                <InputMask
                  mask="99.999.999/9999-99"
                  value={cnpj}
                  onChange={(e) => setCnpj(e.target.value)}
                ></InputMask>
              </label>

              <label>
                E-mail
                <input
                  placeholder="Dados..."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
            </div>

            <div className="container-inputs-1col">
              <label>
                Logradouro
                <input
                  placeholder="Dados..."
                  value={logradouro}
                  onChange={(e) => setLogradouro(e.target.value)}
                />
              </label>
            </div>

            <div className="container-inputs-3col">
              <label>
                Cidade
                <input
                  placeholder="Dados..."
                  value={cidade}
                  onChange={(e) => setCidade(e.target.value)}
                />
              </label>

              <label>
                Bairro
                <input
                  placeholder="Dados..."
                  value={bairro}
                  onChange={(e) => setBairro(e.target.value)}
                />
              </label>

              <label>
                CEP
                <InputMask
                  placeholder="Dados..."
                  mask="99999-999"
                  maskChar="_"
                  value={cep}
                  onChange={(e) => setCep(e.target.value)}
                ></InputMask>
              </label>
            </div>

            <div className="container-inputs-1col">
              <label>
                Observações
                <textarea
                  placeholder="Dados..."
                  rows={4}
                  type="text"
                  value={observacoes}
                  onChange={(e) => setObservacoes(e.target.value)}
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <button className="btn-voltar" onClick={() => history.goBack()}>Voltar Para Carga</button>
    </div>
  )
}

export default memo(PerfilTransportadora)