import { useMutation } from 'react-query';
import api from '../../../services/api';
import { useMessages } from '../../../context/messages-context';

export const useAttachCtesMutation = () => {
  const { showApiErrors } = useMessages();
  
  return useMutation(async ({ transportadora, carga, data }) => {
    const result = [];
    for (const item of data) {
      try {
        const formData = new FormData();
        formData.append("transportadora", transportadora)
        formData.append("carga", carga || '')
        formData.append("arquivo", item);
        
        const { data } = await api.post(`documentos_fiscais/cte/`, formData);
        result.push(data);
      } catch (err) {
        showApiErrors(err);
        return null;
      }
    }
    return result;
  });
}
