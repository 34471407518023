import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'
import { FaUserPlus } from 'react-icons/fa'
import { BsSearch } from 'react-icons/bs';
import { Suspense } from '../../../../components/utils';
import { InputFiltro } from '../../../../components/layout/filtros';
import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import { ClienteTable } from './ClienteTable';
import './styles.css'

const routes = [
  { path: '/acesso', breadcrumb: 'Início' },
  { path: '/acesso/clientes/gerenciar', breadcrumb: 'Gerenciar Clientes' },
]

const GerenciarCliente = () => {
  const history = useHistory()

  return (
    <div className="container-gerenciar-destinatario">
      <BreadCrumbs routes={routes} />

      <div className="gerenciar-destinatario">
        <div className="container-topo">
          <div>
            <p className="titulo-pagina">Gerenciar Clientes</p>
            <p className="subtitulo-pagina">Realize o filtro para busca do cliente desejado</p>
          </div>

          <button className="btn-novo-destinatario" onClick={() => history.push("/acesso/clientes/novo")}>
            <FaUserPlus className="icone"></FaUserPlus>
            Novo Cliente
          </button>
        </div>

        <div className="container-search">
          <InputFiltro
            icon={<BsSearch className="icone-filtro" />}
            placeholder="Pesquisar Cliente"
            parameter="search"
          />
        </div>

        <Suspense>
          <ClienteTable />
        </Suspense>
      </div>
    </div>
  )
}

export default memo(GerenciarCliente)
