const Homologacao = ({ children }) => {
    const isTestingEnv = process?.env?.REACT_APP_TEST_ENV === 'true';

    if (!isTestingEnv) {
        return null;
    }

    return children;
};

export default Homologacao;
