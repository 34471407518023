import { useMutation, useQueryClient } from 'react-query';
import { useSearch } from '../../hooks';
import api from '../../../services/api';
import { useMessages } from '../../../context/messages-context';

export const useDeleteColetaMutation = () => {
  const { showSuccess, showApiErrors } = useMessages();
  const { page } = useSearch();
  const queryClient = useQueryClient();

  return useMutation(async ({ id }) => {
    try {
      await api.delete(`fracionado/coletas/${id}/`);
      showSuccess("Sucesso!", "Coleta Removida com Sucesso!");
      
      await queryClient.invalidateQueries(['coletas']);
      await queryClient.refetchQueries(['coletas', page]);
    } catch (err) {
      showApiErrors(err);
      return null;
    }
  });
}
