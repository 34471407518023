import React, { memo, useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ImSearch } from 'react-icons/im'
import ReactPaginate from "react-paginate";

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import TabelaListarMotoristas from '../../../../components/plataformaAcessada/TabelaListarMotoristas'
import api from "../../../../services/api"

const MotoristasPublicos = () => {
  const history = useHistory()

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/motorista', breadcrumb: 'Motorista' },
    { path: '/acesso/motorista/motoristas-publicos', breadcrumb: 'Motoristas Públicos' },
  ]

  const [searchMotorista, setSearchMotorista] = useState('')
  const [motoristas, setMotoristas] = useState([]);
  const [quantidadePaginas, setQuantidadePaginas] = useState(1);
  const [paginaAtual, setPaginaAtual] = useState(0);

  const requisitarMotoristas = useCallback((query = '', pagina = 0) => {
    api.get(`motoristas/publicos/?search=${query}&offset=${pagina * 30}&limit=30`)
      .then(function (response) {
        setMotoristas(response.data.results)
        setQuantidadePaginas(Math.ceil(response.data.count / 30));
        setPaginaAtual(pagina);
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const filtrarItens = () => {
    setMotoristas([])
    requisitarMotoristas(searchMotorista, 0);
  }

  const verificarTeclaSearch = (e) => {
    if (e.keyCode === 13) {
      filtrarItens()
    }
  }

  useEffect(() => {
    if (searchMotorista === '') {
      requisitarMotoristas('', 0)
    }
  }, [requisitarMotoristas, searchMotorista])

  return (
    <div className="container-motoristas-publicos">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="motoristas-publicos">
        <div className="container-topo">
          <div>
            <p className="titulo-pagina">Motoristas Públicos</p>
            <p className="subtitulo-pagina">Realize o filtro para busca do motorista desejado</p>
          </div>
        </div>

        <div className="container-search">
          <div className="input-search motorista">
            <input
              type="text"
              placeholder="Buscar Motorista (Razão Social; CNPJ)"
              value={searchMotorista}
              onChange={(e) => setSearchMotorista(e.target.value)}
              onKeyDown={verificarTeclaSearch}
            />
            <ImSearch className="icone"></ImSearch>
          </div>

          <button className="btn-buscar" onClick={filtrarItens}>Buscar</button>
        </div>

        <TabelaListarMotoristas motoristas={motoristas} isPublico={true}></TabelaListarMotoristas>


        <div className="container-paginacao-motoristas">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={quantidadePaginas}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={paginaAtual}
            onPageChange={(e) => requisitarMotoristas(searchMotorista, e.selected)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(MotoristasPublicos)