import React, { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate'
import { useSearch } from '../../../core/hooks';
import './pagination.scss';

export const Pagination = ({
  count,
  size,
  page: controledPage,
  onChangePage,
  router = true
}) => {
  const { search, hash, pathname } = useLocation();
  const history = useHistory();
  const { page: routerPage } = useSearch();

  const page = useMemo(() => {
    if (!router) {
      return controledPage;
    }
    return routerPage;
  }, [router, controledPage, routerPage]);

  const handleGoToPage = (page) => {
    if (!router) {
      onChangePage(page);
      return;
    }

    const searchQuery = new URLSearchParams(search);
    searchQuery.set('page', String(page + 1));
    
    history.push({
      pathname,
      hash,
      search: searchQuery.toString(),
    });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  if (!count || !size) {
    return null;
  }

  const pages = Math.ceil(count / size);

  if (pages <= 1) {
    return null;
  }

  return (
    <ReactPaginate
      previousLabel={"Anterior"}
      nextLabel={"Próxima"}
      breakLabel={"..."}
      pageCount={pages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      forcePage={page - 1}
      onPageChange={(e) => handleGoToPage(e.selected)}
      containerClassName="pagination-container"
      activeClassName="active"
    />
  );
};
