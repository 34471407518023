import React, { memo, useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FaUserPlus, FaEye, FaTrashAlt, FaEdit } from 'react-icons/fa'
import { ImSearch } from 'react-icons/im'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ReactPaginate from 'react-paginate'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import api from "../../../../services/api"

const GerenciarDestinatario = () => {
  const history = useHistory()

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
  const tipoUsuario = dadosUsuario.user_type

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/destinatario', breadcrumb: 'Destinatário' },
    { path: '/acesso/destinatario/gerenciar-destinatario', breadcrumb: 'Gerenciar Destinatários' },
  ]

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const [search, setSearch] = useState('')
  const [destinatarios, setDestinatarios] = useState([])
  const [quantidadePaginas, setQuantidadePaginas] = useState(1)
  const [paginaAtual, setPaginaAtual] = useState(0)

  const requisitarDestinatarios = useCallback((paginaSelecionada, offSet) => {
    setPaginaAtual(paginaSelecionada)

    if (tipoUsuario === "embarcador" || tipoUsuario === "colaborador_embarcador") {
      api.get(`destinatario/?embarcador=${dadosUsuario.embarcador_id}&?offset=${offSet ? offSet : 0}`)
        .then(function (response) {
          setQuantidadePaginas(Math.ceil(response.data.count / 30))

          let auxDestinatarios = []

          response.data.results.sort((x, y) => x.id - y.id).forEach((item) => {
            auxDestinatarios.push(item)
          })

          setDestinatarios(auxDestinatarios)
        })
        .catch(function (error) {
          console.log(error)
        })
    } else if (tipoUsuario === "transportadora" || tipoUsuario === "colaborador_transportadora") {
      api.get(`destinatario/?transportadora=${dadosUsuario.transportadora_id}&?offset=${offSet ? offSet : 0}`)
        .then(function (response) {
          setQuantidadePaginas(Math.ceil(response.data.count / 30))

          let auxDestinatarios = []

          response.data.results.sort((x, y) => x.id - y.id).forEach((item) => {
            auxDestinatarios.push(item)
          })

          setDestinatarios(auxDestinatarios)
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      api.get(`destinatario/?offset=${offSet ? offSet : 0}`)
        .then(function (response) {
          setQuantidadePaginas(Math.ceil(response.data.count / 30))
          setDestinatarios(response.data.results)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [tipoUsuario, dadosUsuario.embarcador_id, dadosUsuario.transportadora_id])

  const removerDestinatario = (nome, id) => {
    confirmAlert({
      title: "Remover Destinatário",
      message: `Deseja realmente remover o destinatário "${nome}" do sistema?`,
      overlayClassName: "alert-remover-destinatario",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            api.delete(`destinatario/${id}/`)
              .then(function () {
                requisitarDestinatarios()
              })
              .catch(function (error) {
                console.log(error)
              })
          },
          className: "botao-confirmar"
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar"
        }
      ]
    })
  }

  useEffect(() => {
    if (search === '') {
      forceUpdate()
      requisitarDestinatarios()
    }
  }, [requisitarDestinatarios, forceUpdate, search])

  return (
    <div className="container-gerenciar-destinatario">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="gerenciar-destinatario">
        <div className="container-topo">
          <div>
            <p className="titulo-pagina">Gerenciar Destinatários</p>
            <p className="subtitulo-pagina">Realize o filtro para busca do destinatário desejado</p>
          </div>

          <button className="btn-novo-destinatario" onClick={() => history.push("/acesso/destinatario/novo-destinatario")}>
            <FaUserPlus className="icone"></FaUserPlus>
            Novo Destinatário
          </button>
        </div>

        <div className="container-search">
          <div className="input-search">
            <input
              type="text"
              placeholder="Buscar Destinatário (Nome; Empresa)"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <ImSearch className="icone"></ImSearch>
          </div>

          <button className="btn-buscar">Buscar</button>
        </div>

        <div className="container-tabela-listar-destinatarios">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome Destinatário</th>
                <th>Empresa</th>
                <th>Ações</th>
              </tr>
            </thead>

            <tbody>
              {destinatarios.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr className="divisoria-linhas-tabela"></tr>
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.nome_completo}</td>
                      <td>{item.empresa}</td>

                      <td className="coluna-acoes">
                        <Link to={`/acesso/destinatario/visualizar-destinatario/${item.id}`}>
                          <FaEye className="icone"></FaEye>
                        </Link>
                        <Link to={`/acesso/destinatario/editar-destinatario/${item.id}`}>
                          <FaEdit className="icone"></FaEdit>
                        </Link>
                        <FaTrashAlt className="icone" onClick={() => removerDestinatario(item.nome_completo, item.id)}></FaTrashAlt>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="container-paginacao-destinatario">
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próxima"}
            breakLabel={"..."}
            pageCount={quantidadePaginas}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={paginaAtual}
            onPageChange={(e) => requisitarDestinatarios(e.selected, e.selected * 30)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(GerenciarDestinatario)