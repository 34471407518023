import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import {
  Input,
  InputPhone,
  InputPostalCode,
  InputCNPJ,
  SelectUF,
  SelectMunicipio,
  Checkbox,
} from '../../../../components/forms';
import { useClientById } from '../../../../core/queries';
import { getAddressFromPostalCode } from '../../../../utils/address';

export const ClienteForm = ({ id, readonly }) => {
  const { values, setValues } = useFormikContext();
  const { data } = useClientById(id);

  const handlePostalCodeComplete = async (cep) => {
    try {
      const data = await getAddressFromPostalCode(cep);
      
      setValues({
        ...values,
        endereco: {
          ...values.endereco,
          ...data,
        }
      })
    } catch {}
  }

  useEffect(() => {
    if (!data) {
      return;
    }
    setValues(data);
  }, [data, setValues]);

  return (
    <>
      <div className="categoria">
        <p className="titulo-categoria">Dados do Cliente</p>
        <div className="container-inputs-2col">
          <Input
            required
            name="nome_completo"
            label="Razão Social"
            disabled={readonly}
          />
          <InputCNPJ
            required
            name="cnpj"
            label="CNPJ"
            disabled={readonly}
          />
          <InputPhone
            required
            name="telefone"
            label="Telefone Contato"
            disabled={readonly}
          />
          <Input
            required
            name="email"
            type="email"
            label="E-mail"
            disabled={readonly}
          />
        </div>
      </div>

      <div className="categoria">
        <p className="titulo-categoria">Endereço</p>
        <div className="container-inputs-2col">
          <Input
            required
            name="endereco.pais"
            label="País"
            disabled
          />
          <InputPostalCode
            required
            name="endereco.cep"
            label="CEP"
            disabled={readonly}
            onPostalCodeComplete={handlePostalCodeComplete}
          />
          <SelectUF
            required
            name="endereco.uf"
            label="UF"
            disabled={readonly}
            ufCodeField="endereco.uf_codigo"
            municipioField="endereco.municipio"
            municipioCodeField="endereco.municipio_codigo"
          />
          <Input
            required
            name="endereco.uf_codigo"
            label="Código UF"
            disabled
          />
          <SelectMunicipio
            required
            name="endereco.municipio"
            label="Municipio"
            ufField="endereco.uf"
            disabled={readonly}
            municipioCodeField="endereco.municipio_codigo"
          />
          <Input
            required
            name="endereco.municipio_codigo"
            label="Código Municipio"
            disabled
          />
          <Input
            required
            name="endereco.bairro"
            label="Bairro"
            disabled={readonly}
          />
          <Input
            required
            name="endereco.logradouro"
            label="Logradouro"
            disabled={readonly}
          />
          <Input
            name="endereco.numero"
            label="Número"
            disabled={readonly}
          />
          <Input
            name="endereco.complemento"
            label="Complemento"
            disabled={readonly}
          />
        </div>
      </div>

      <div className="categoria">
        <p className="titulo-categoria">Status Cliente</p>
        
        <div className="container-inputs-2col">
          <Checkbox
            name="is_ativo"
            label="Ativo"
            disabled={readonly}
          />
        </div>
      </div>
    </>
  )
}
