import { useQuery } from 'react-query';
import api from '../../../services/api'
import { useSearch } from '../../hooks'
import {API_CLIENT_PAGE_SIZE} from '../../config';

const getClientsPaginated = async ({ page, term }) => {
    let path = `/fracionado/clientes/?limit=${API_CLIENT_PAGE_SIZE}&`;
    if (page) {
        path = `${path}offset=${(page - 1) * API_CLIENT_PAGE_SIZE}&`;
    }
    if (term) {
        path = `${path}search=${term}&`
    }

    const { data } = await api.get(path);
    return data;
}

export const useClientsPaginated = (rawPage) => {
    const { page, term } = useSearch();

    return useQuery(['clients', term, rawPage || page], async () => {
        return getClientsPaginated({ page: rawPage || page, term });
    });
};
