import React, { memo, useRef, useEffect, useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { BsSearch } from "react-icons/bs";
import Modal from "react-modal";
import { parseISO, format } from "date-fns";

import "./styles.css";

import api from "../../../../../../../../services/api";

const ModalSelecionarCtes = ({
  idCarga,
  modalSelecionarCtes,
  handleModalSelecionarCtes,
  registrarCtesSelecionados,
}) => {
  const [ctes, setCtes] = useState([]);

  const searchCte = useRef("");
  const [tipo, setTipo] = useState("carga");
  const serieNumero = useRef("");
  const serie1 = useRef("");
  const serie2 = useRef("");

  const [ctesSelecionados, setCtesSelecionados] = useState([]);

  const handleSelecionarCte = (cteSelecionado) => {
    if (
      ctesSelecionados.findIndex((cte) => cte.id === cteSelecionado.id) !== -1
    ) {
      setCtesSelecionados(
        ctesSelecionados.filter((cte) => cte.id !== cteSelecionado.id)
      );
      return;
    }

    setCtesSelecionados((ctes) => [...ctes, cteSelecionado]);
  };

  const handleSelecionarTodosCtes = () => {
    if (ctesSelecionados.length === ctes.length) {
      setCtesSelecionados([]);
      return;
    }

    setCtesSelecionados(ctes);
  }

  useEffect(() => {
    let url = 'documentos_fiscais/cte/';
    if (tipo === 'avulso') {
      url = `${url}?carga__isnull=1&status=Confirmado`;
    } else {
      url = `${url}?carga=${idCarga}&with_coleta=1&status=Confirmado`
    }
    api
      .get(url)
      .then(function (response) {
        setCtes(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idCarga, tipo]);
  
  return (
    <Modal
      isOpen={modalSelecionarCtes}
      onRequestClose={handleModalSelecionarCtes}
      className="modal-selecionar-cte"
      overlayClassName="overlay-modal-selecionar-cte"
      ariaHideApp={false}
    >
      <div className="selecionar-cte">
        <RiCloseCircleFill
          className="icone-fechar"
          onClick={handleModalSelecionarCtes}
        ></RiCloseCircleFill>

        <p className="titulo">Selecionar os CT-e(s) desejados abaixo:</p>

        <div className="container-campos">
          <div className="primeira-etapa">
            <div className="container-input-search-cte">
              <input type="text" ref={searchCte} placeholder="Buscar CT-e(s)" />
              <BsSearch className="icone"></BsSearch>
            </div>

            <div className="container-input-comum">
              <select value={tipo} onChange={(e) => setTipo(e.target.value)}>
                <option value="carga">Vinculados a Carga</option>
                <option value="avulso">Avulsos</option>
              </select>
            </div>
          </div>

          <div className="segunda-etapa">
            <div className="container-input-comum serie">
              <input required type="text" ref={serieNumero} />
              <span>Série/Número</span>
            </div>

            <span className="separador">/</span>

            <div className="container-input-comum serie1">
              <input required type="text" ref={serie1} />
              <span></span>
            </div>

            <div className="container-input-comum serie2">
              <input required type="text" ref={serie2} />
              <span></span>
            </div>
          </div>
        </div>

        <div className="container-tabela-buscar-ctes">
          <table>
            <thead>
              <tr>
              <th>
                    <div className="container-input-checkbox">
                      <span
                        className="checkbox"
                        onClick={() => handleSelecionarTodosCtes()}
                      >
                        <input
                          type="checkbox"
                          checked={ctesSelecionados.length === ctes.length && ctes.length > 0 ? true : false}
                          onChange={() => handleSelecionarTodosCtes()}
                        />
                        <span className="checked"></span>
                      </span>
                    </div>
                  </th>
                <th>Emissão</th>
                <th>CT-e</th>
                <th>Remetente</th>
                <th>Origem</th>
                <th>Destinatário</th>
                <th>Destino</th>
                <th>Vol"</th>
                {/* <th>Vol" Real</th> */}
                <th>Peso</th>
                {/* <th>Peso Real</th> */}
                <th>R$</th>
                <th>R$ Frete</th>
              </tr>
            </thead>

            <tbody>
              {ctes.map((cte, index) => (
                <tr key={index}>
                  <td>
                    <div className="container-input-checkbox">
                      <span
                        className="checkbox"
                        onClick={() => handleSelecionarCte(cte)}
                      >
                        <input
                          type="checkbox"
                          checked={
                            ctesSelecionados.findIndex(
                              (cteSel) => cteSel.id === cte.id
                            ) !== -1
                              ? true
                              : false
                          }
                          onChange={() => handleSelecionarCte(cte)}
                        />
                        <span className="checked"></span>
                      </span>
                    </div>
                  </td>
                  <td>
                    {cte.emissao
                      ? format(parseISO(cte.emissao), "dd/MM/yyyy")
                      : ""}
                  </td>
                  <td className="coluna-destaque">{cte.numero}</td>
                  <td>{cte.remetente_razao_social}</td>
                  <td>
                    {cte?.remetente_endereco?.nome_municipio}-
                    {cte?.remetente_endereco?.uf}
                  </td>
                  <td>{cte.destinatario_razao_social}</td>
                  <td>
                    {cte?.destinatario_endereco?.nome_municipio}-
                    {cte?.destinatario_endereco?.uf}
                  </td>
                  <td>{cte.volumes}</td>
                  {/* <td></td> */}
                  <td>{cte.peso}</td>
                  {/* <td></td> */}
                  <td>{cte.valor_carga}</td>
                  <td>{cte.valor_cte}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <button
          className="btn-selecionar"
          onClick={() => {
            registrarCtesSelecionados(ctesSelecionados);
            handleModalSelecionarCtes();
          }}
        >
          Selecionar CT-e(s)
        </button>
      </div>
    </Modal>
  );
};

export default memo(ModalSelecionarCtes);
