import { parseISO, format } from "date-fns";

export const buildWithQueryParams = (path, params) => {
  const query = Object.keys(params).reduce((prev, current) => {
    if (!params[current]) {
      return prev;
    }
    if (prev === '') {
      return `?${current}=${params[current]}`;
    }
    return `${prev}&${current}=${params[current]}`;
  }, '');

  return `${path}${query}`;
}

export const formatarData = (embarque, formato) => {
  if (embarque.length > 9) {
    if (formato === "gte") {
      return format(parseISO(embarque), "yyyy-MM-dd'T'00:00:00");
    } else {
      return format(parseISO(embarque), "yyyy-MM-dd'T'23:59:59");
    }
  }
  return "";
};
