import { useState, useEffect, useRef, useMemo, Fragment } from 'react';
import { FaInfoCircle, FaEdit } from "react-icons/fa";
import { AiOutlineArrowUp, AiOutlineArrowDown, AiOutlineClose } from 'react-icons/ai';
import { confirmAlert } from 'react-confirm-alert'
import { ImArrowLeft, ImArrowRight } from "react-icons/im";
import { WithContext as ReactTags } from "react-tag-input";
import InputSearchContaFrete from "../../../../../../components/plataformaAcessada/InputSearchDadosCarga/InputSearchContaFrete";
import InputSearchProduto from "../../../../../../components/plataformaAcessada/InputSearchDadosCarga/InputSearchProduto";
import InputSearchEspecie from "../../../../../../components/plataformaAcessada/InputSearchDadosCarga/InputSearchEspecie";
import api from "../../../../../../services/api";
import SelecionarEndereco from './selecionar-endereco';
import 'react-confirm-alert/src/react-confirm-alert.css'
import './styles.css';
import { ModalSelecionarColeta } from '../../../../../../modals/modal-selecionar-coleta'
import { Button } from '../../../../../../components/common';
import { HStack } from '../../../../../../components/utils';
import { Tabela } from '../../../../../../components/layout';

const keyCodesPedidos = {
  comma: 188,
  enter: [10, 13],
};
const delimitadores = [...keyCodesPedidos.enter, keyCodesPedidos.comma];

const Etapa1 = ({
  currentStep,
  totalSteps,
  nextStep,
  destinatarios,
  quantidadeCargas,
  setQuantidadeCargas,
  tiposContaFrete,
  produtos,
  especiesProdutos,
  idCarga,
  setTiposContaFrete,
  setProdutos,
  setEspeciesProdutos,
  
  form,
}) => {
  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
  const idUsuario = dadosUsuario.id;

  const [manterTipoFrete, setManterTipoFrete] = useState("filtrar");
  const [msgErroContaFrete, setMsgErroContaFrete] = useState(false);
  const [editarContaFrete, setEditarContaFrete] = useState("");

  const [msgErroProduto, setMsgErroProduto] = useState(false);
  const [manterProduto, setManterProduto] = useState("filtrar");
  const [editarProduto, setEditarProduto] = useState("");
  
  const [msgErroEspecie, setMsgErroEspecie] = useState(false);
  const [manterEspecieProduto, setManterEspecieProduto] = useState("filtrar");
  const [editarEspecieProduto, setEditarEspecieProduto] = useState("");

  const inputContaFrete = useRef(null);
  const inputEspecie = useRef(null);
  const inputProduto = useRef(null);

  const [isSelectingColeta, setIsSelectingColeta] = useState(false);

  const coletas = useMemo(() => {
    const original = form.values.coletas;
    const ordered = Object
      .keys(original)
      .map((item) => ({
        ...original[item],
      }))
      .sort((a, b) => {
        if (a.order === undefined || a.order === null) {
          return 1;
        }
        if (b.order === undefined || b.order === null) {
          return -1;
        }
        return a.order - b.order;
      })
      .map((item, index) => ({
        ...item,
        order: index,
      }));

    return ordered;
  }, [form.values.coletas]);

  const handleMove = (id, dir = -1) => {
    const index = coletas.findIndex((item) => item.id === id);
    const coleta = coletas.find((item) => item.id === id);

    const newOrdered = coletas
      .map((item, itemIndex) => {
        if (itemIndex === index + dir) {
          return {
            ...item,
            order: coleta.order,
          };
        }
        if (itemIndex === index) {
          return {
            ...item,
            order: coletas[index + dir].order,
          };
        }
        return item;
      })
      .sort((a, b) => {
        if (a.order === undefined || a.order === null) {
          return 1;
        }
        if (b.order === undefined || b.order === null) {
          return -1;
        }
        return a.order - b.order;
      })
      .map((item, index) => ({
        ...item,
        order: index,
      }));

    const newColetas = newOrdered.reduce((prev, item) => ({
      ...prev,
      [item.id]: item,
    }), {});

    form.setFieldValue('coletas', newColetas);
  };

  const handleAddColetas = (coletas) => {
    form.setFieldValue('coletas', {
      ...form.values.coletas,
      ...coletas,
    });
    setIsSelectingColeta(false);
  };
  
  const handleRemoveColeta = (id) => {
    const filtered = coletas
      .filter((item) => item.id !== id)
      .map((item, index) => ({
        ...item,
        order: index,
      }));

    const newColetas = filtered.reduce((prev, item) => ({
      ...prev,
      [item.id]: item,
    }), {});

    form.setFieldValue('coletas', newColetas);
  }

  const adicionarPedido = (text) => {
    form.setFieldValue('pedidos', [...form.values.pedidos, text]);
  };

  const deletarPedido = (i) => {
    form.setFieldValue('pedidos', form.values.pedidos.filter((_, index) => index !== i));
  };

  useEffect(() => {
  }, [manterTipoFrete, idUsuario]);

  const cadastrarNovaContaFrete = () => {
    api
      .post(`tipocontafrete/`, {
        descricao: form.values.nome_conta_frete,
        criado_por: idUsuario,
      })
      .then(function (response) {
        setManterTipoFrete("filtrar");

        form.setFieldValue("conta_frete", response.data);
        form.setFieldValue('nome_conta_frete', response.data.descricao);
        setMsgErroContaFrete(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const alterarContaFrete = (id) => {
    api
      .put(`tipocontafrete/${id}/`, {
        descricao: editarContaFrete,
      })
      .then(function () {
        form.setFieldValue('nome_conta_frete', '');
        setEditarContaFrete("");
        setManterTipoFrete("filtrar");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const renderizarCampoTipoContaFrete = () => {
    if (manterTipoFrete === "filtrar") {
      return (
        <>
          <InputSearchContaFrete
            form={form}
            inputContaFrete={inputContaFrete}
            saveValue={(e) => form.setFieldValue('nome_conta_frete', e)}
            adicionarContaFrete={(contaFrete) => {
              form.setFieldValue("conta_frete", {
                id: contaFrete.id,
                descricao: contaFrete.descricao,
              });
              form.setFieldValue('nome_conta_frete', contaFrete.descricao);
              setMsgErroContaFrete(false);
            }}
            cadastrarNovaContaFrete={cadastrarNovaContaFrete}
            placeholder="Tipo de Conta Frete"
            suggestions={tiposContaFrete}
            idCarga={idCarga}
            setSuggestions={setTiposContaFrete}
          />

          {msgErroContaFrete && (
            <p className="msg-erro">Selecione uma conta frete primeiro!</p>
          )}
        </>
      );
    }
    if (manterTipoFrete === "editar") {
      return (
        <>
          <div className="container-input manter">
            <div>
              <input
                required
                type="text"
                value={editarContaFrete}
                onChange={(e) => setEditarContaFrete(e.target.value)}
              />
              <span>
                Digite o novo nome <i>*</i>
              </span>
            </div>
            <button
              onClick={() => alterarContaFrete(form.values.conta_frete.id)}
            >
              Alterar
            </button>
          </div>
          <p className="nome-atual-dado">
            Nome atual: {form.values.conta_frete.descricao}
          </p>
        </>
      );
    }
  }

  const cadastrarNovoProduto = () => {
    api
      .post(`produtocarga/`, {
        produto: form.values.nome_produto,
        criado_por: idUsuario,
      })
      .then(function (response) {
        setManterProduto("filtrar");

        form.setFieldValue("produto", response.data);
        form.setFieldValue('nome_produto', response.data.produto);
        setMsgErroProduto(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const alterarProduto = (id) => {
    api
      .put(`produtocarga/${id}/`, {
        produto: editarProduto,
      })
      .then(function () {
        form.setFieldValue('nome_produto', '');
        setEditarProduto("");
        setManterProduto("filtrar");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const renderizarCampoProduto = () => {
    if (manterProduto === "filtrar") {
      return (
        <>
          <InputSearchProduto
            form={form}    
            inputProduto={inputProduto}
            saveValue={(e) => form.setFieldValue('nome_produto', e)}
            adicionarProduto={(produto) => {
              form.setFieldValue("produto", {
                id: produto.id,
                produto: produto.produto,
              });
              form.setFieldValue('nome_produto', produto.produto);
              setMsgErroProduto(false);
            }}
            cadastrarNovoProduto={cadastrarNovoProduto}
            placeholder="Inserir Produto (Carga)"
            suggestions={produtos}
            idCarga={idCarga}
            setSuggestions={setProdutos}
          ></InputSearchProduto>

          {msgErroProduto && (
            <p className="msg-erro">Selecione um produto primeiro!</p>
          )}
        </>
      );
    }
    if (manterProduto === "editar") {
      return (
        <>
          <div className="container-input manter">
            <div>
              <input
                required
                type="text"
                value={editarProduto}
                onChange={(e) => setEditarProduto(e.target.value)}
              />
              <span>
                Digite o novo nome <i>*</i>
              </span>
            </div>
            <button onClick={() => alterarProduto(form.values.produto.id)}>
              Alterar
            </button>
          </div>
          <p className="nome-atual-dado">
            Nome atual: {form.values.produto.produto}
          </p>
        </>
      );
    }
  };

  const cadastrarNovaEspecieProduto = () => {
    api
      .post(`especieproduto/`, {
        especie: form.values.nome_especie_produto,
        criado_por: idUsuario,
      })
      .then(function (response) {
        setManterEspecieProduto("filtrar");

        form.setFieldValue("especie_produto", response.data);
        form.setFieldValue('nome_especie_produto', response.data.especie)
        setMsgErroEspecie(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const alterarEspecieProduto = (id) => {
    api
      .put(`especieproduto/${id}/`, {
        especie: editarEspecieProduto,
      })
      .then(function () {
        form.setFieldValue('nome_especie_produto', '');
        setEditarEspecieProduto("");
        setManterEspecieProduto("filtrar");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const renderizarCampoEspecieProduto = () => {
    if (manterEspecieProduto === "filtrar") {
      return (
        <>
          <InputSearchEspecie
            form={form}
            inputEspecie={inputEspecie}
            saveValue={(e) => form.setFieldValue('nome_especie_produto', e)}
            adicionarEspecie={(especie) => {
              form.setFieldValue("especie_produto", {
                id: especie.id,
                especie: especie.especie,
              });
              form.setFieldValue('nome_especie_produto', especie.especie);
              setMsgErroEspecie(false);
            }}
            cadastrarNovaEspecieProduto={cadastrarNovaEspecieProduto}
            placeholder="Espécie Produto"
            suggestions={especiesProdutos}
            idCarga={idCarga}
            setSuggestions={setEspeciesProdutos}
          ></InputSearchEspecie>

          {msgErroEspecie && (
            <p className="msg-erro">Selecione uma espécie primeiro!</p>
          )}
        </>
      );
    }
    if (manterEspecieProduto === "editar") {
      return (
        <>
          <div className="container-input manter">
            <div>
              <input
                required
                type="text"
                value={editarEspecieProduto}
                onChange={(e) => setEditarEspecieProduto(e.target.value)}
              />
              <span>
                Digite o novo nome <i>*</i>
              </span>
            </div>
            <button
              onClick={() => alterarEspecieProduto(form.values.especie_produto.id)}
            >
              Alterar
            </button>
          </div>
          <p className="nome-atual-dado">
            Nome atual: {form.values.especie_produto.especie}
          </p>
        </>
      );
    }
  };

  const handleAddPontoParada = (destino_uf, destino_municipio) => {
    if (!destino_uf.value || !destino_municipio.value) {
      return;
    }

    form.setFieldValue('pontos_parada', ([
      ...form.values.pontos_parada,
      {
        uf: destino_uf,
        municipio: destino_municipio,
      }
    ]));
    form.setFieldValue('destino_uf', form.initialValues.destino_uf)
    form.setFieldValue('destino_municipio', form.initialValues.destino_municipio)
  }

  const setDestinoMunicipio = (item) => {
    form.setFieldValue('destino_municipio', item);
    if (item.value && item.codigo && form.values.destino_uf.value && form.values.destino_uf.codigo) {
      handleAddPontoParada(form.values.destino_uf, item);
    }
  };

  const handleRemovePontoParada = (index) => {
    form.setFieldValue(
      'pontos_parada',
      form.values.pontos_parada.filter((_, itemIndex) => itemIndex !== index)
    );
  }

  const handleChangeItemUf = (index, uf) => {
    form.setFieldValue(
      'pontos_parada',
      form.values.pontos_parada.map((item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            uf,
          };
        }
        return item;
      })
    );
  }

  const handleChangeItemMunicipio = (index, municipio) => {
    form.setFieldValue(
      'pontos_parada',
      form.values.pontos_parada.map((item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            municipio,
          };
        }
        return item;
      })
    );
  }

  const handleNextStep = () => {
    if (!Object.keys(form.values.coletas).length && form.values.is_fracionada) {
      confirmAlert({
        title: "Coletas",
        message: 'Você não adicionou nenhuma coleta.',
        overlayClassName: "alert-fechar-modal",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
            },
            className: "botao-confirmar"
          },
        ]
      })
      return;
    }
    if (!form.values.pontos_parada.length && !form.values.is_fracionada) {
      confirmAlert({
        title: "Pontos de Parada",
        message: 'Você não adicionou nenhum ponto de parada (é necessário clicar no botão ao lado após preencher a informação).',
        overlayClassName: "alert-fechar-modal",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
            },
            className: "botao-confirmar"
          },
        ]
      })
      return;
    }

    if(!form.values.conta_frete){
      confirmAlert({
        title: "Conta Frete",
        message: 'Você não adicionou uma conta de frete.',
        overlayClassName: "alert-fechar-modal",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
            },
            className: "botao-confirmar"
          },
        ]
      })

      inputContaFrete.current.focus();
      return;
    }

    if(!form.values.produto){
      confirmAlert({
        title: "Inserir Produto",
        message: 'Você não adicionou o produto(carga).',
        overlayClassName: "alert-fechar-modal",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
            },
            className: "botao-confirmar"
          },
        ]
      })

      inputProduto.current.focus();
      return;
    }

    if(!form.values.especie_produto){
      confirmAlert({
        title: "Espécie Produto",
        message: 'Você não adicionou uma espécie produto.',
        overlayClassName: "alert-fechar-modal",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
            },
            className: "botao-confirmar"
          },
        ]
      })

      inputEspecie.current.focus();
      return;
    }

    nextStep()
  }

  return (
    <>
      <div className="carga-etapa-1">
        <div className="conteudo">
          <p className="titulo-etapa">
            Por favor, informe os dados abaixo, para cadastrar uma nova carga.
          </p>

          <div className="input-radio">
            <div className="label">
              <p>Carga Fracionada?</p>
              <FaInfoCircle className="icone"></FaInfoCircle>
            </div>

            <div className="container-radio">
              <div className="container-input-radio">
                <span className="radio" onClick={() => form.setFieldValue('is_fracionada', true)}>
                  <input
                    type="radio"
                    value={true}
                    checked={form.values.is_fracionada}
                    onChange={() => form.setFieldValue('is_fracionada', true)}
                  />
                  <span className="checked"></span>
                  Sim
                </span>
              </div>

              <div className="container-input-radio">
                <span className="radio" onClick={() => form.setFieldValue('is_fracionada', false)}>
                  <input
                    type="radio"
                    value={false}
                    checked={!form.values.is_fracionada}
                    onChange={() => form.setFieldValue('is_fracionada', false)}
                  />
                  <span className="checked"></span>
                  Não
                </span>
              </div>
            </div>
          </div>

          <div className="container-input-combo">
            <FaInfoCircle className="icone"></FaInfoCircle>
            <ReactTags
              placeholder="Informe o número do pedido"
              tags={form.values.pedidos}
              handleDelete={deletarPedido}
              handleAddition={adicionarPedido}
              handleInputBlur={(e) =>
                e.length > 0 ? adicionarPedido({ id: e, text: e }) : () => {}
              }
              delimiters={delimitadores}
              allowUnique={true}
            />
          </div>

          <div className="container-input-manter">
            <div className="container-icones">
              <FaEdit
                className="icone"
                onClick={
                  form.values.conta_frete === ""
                    ? () => setMsgErroContaFrete(true)
                    : () => setManterTipoFrete("editar")
                }
              ></FaEdit>
            </div>

            {renderizarCampoTipoContaFrete()}
          </div>

          <div className="container-input-manter">
            <div className="container-icones">
              <FaEdit
                className="icone"
                onClick={
                  form.values.produto === ""
                    ? () => setMsgErroProduto(true)
                    : () => setManterProduto("editar")
                }
              ></FaEdit>
            </div>

            {renderizarCampoProduto()}
          </div>

          <div className="container-input-manter">
            <div className="container-icones">
              <FaEdit
                className="icone"
                onClick={
                  form.values.especie_produto === ""
                    ? () => setMsgErroEspecie(true)
                    : () => setManterEspecieProduto("editar")
                }
              ></FaEdit>
            </div>

            {renderizarCampoEspecieProduto()}
          </div>

          <div className="input-radio">
            <div className="label">
              <p>Carga Rastreada?</p>
              <FaInfoCircle className="icone"></FaInfoCircle>
            </div>

            <div className="container-radio">
              <div className="container-input-radio">
                <span className="radio" onClick={() => form.setFieldValue("is_rastreada", true)}>
                  <input
                    type="radio"
                    value={true}
                    checked={form.values.is_rastreada}
                    onChange={() => form.setFieldValue("is_rastreada", true)}
                  />
                  <span className="checked"></span>
                  Sim
                </span>
              </div>

              <div className="container-input-radio">
                <span className="radio" onClick={() => form.setFieldValue("is_rastreada", false)}>
                  <input
                    type="radio"
                    value={false}
                    checked={!form.values.is_rastreada}
                    onChange={() => form.setFieldValue("is_rastreada", false)}
                  />
                  <span className="checked"></span>
                  Não
                </span>
              </div>
            </div>
          </div>

          {form.values.is_fracionada ? (
            <div className="coletas">
              <p>Coletas</p>

              <div>
                {coletas.length === 0 && (
                  <HStack height="100px" alignItems="center" justifyContent="center">
                    Nenhuma Coleta Anexada Ainda!
                  </HStack>
                )}
                {coletas.length > 0 && (
                  <>
                    <Tabela>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Status</th>
                          <th>NF-e</th>
                          <th>CT-e</th>
                          <th>Cliente</th>
                          <th>Destino</th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {coletas.map((item, index) => (
                          <Fragment key={item.id}>
                            <tr className="divisoria-linhas-tabela"></tr>
                            <tr>
                              <td>{item.id}</td>
                              <td>{item.status}</td>
                              <td>{item.nfe?.numero || '-'}</td>
                              <td>{item.cte || '-'}</td>
                              <td>
                                <div className="elipsis" title={item.cliente}>
                                  {item.cliente}
                                </div>
                              </td>
                              <td>
                                <div className="elipsis" title={item.destino}>
                                  {item.destino}
                                </div>
                              </td>
                              <td>
                                <div className="coleta-actions">
                                  {index > 0 ? (
                                    <AiOutlineArrowUp className="coleta-icon" title="Mover para Cima" onClick={() => handleMove(item.id, -1)} />
                                  ) : (
                                    <AiOutlineArrowUp className="coleta-icon-hide"/>
                                  )}
                                  {index < coletas.length - 1 ? (
                                    <AiOutlineArrowDown className="coleta-icon" title="Mover para Baixo" onClick={() => handleMove(item.id, 1)}  />
                                  ) : (
                                    <AiOutlineArrowDown className="coleta-icon-hide" />
                                  )}
                                  <AiOutlineClose className="coleta-icon" title="Remover Coleta" onClick={() => handleRemoveColeta(item.id)} />
                                </div>
                              </td>
                            </tr>
                          </Fragment>
                        ))}
                      </tbody>
                    </Tabela>
                  </>
                )}

                <div className="actions">
                  <HStack justifyContent="flex-end">
                    <Button variant="smallAction" onClick={() => setIsSelectingColeta(true)}>
                      Adicionar Coleta
                    </Button>
                  </HStack>
                </div>
              </div>
            </div>
          ) : (
            <>
              <SelecionarEndereco
                title="Origem"
                uf={form.values.origem_uf}
                onChangeUf={(item) => form.setFieldValue('origem_uf', item)}
                municipio={form.values.origem_municipio}
                onChangeMunicipio={(item) => form.setFieldValue('origem_municipio', item)}
              />
    
              {form.values.pontos_parada.map((item, index) => (
                <SelecionarEndereco
                  title={`Ponto de Parada #${index + 1}`}
                  uf={item.uf}
                  key={index}
                  onChangeUf={(item) => handleChangeItemUf(index, item)}
                  municipio={item.municipio}
                  onChangeMunicipio={(item) => handleChangeItemMunicipio(index, item)}
                  removeButton
                  onRemoveButtonClick={() => handleRemovePontoParada(index)}
                />
              ))}
    
              <SelecionarEndereco
                title="Adicionar Ponto de Parada"
                uf={form.values.destino_uf}
                onChangeUf={(item) => form.setFieldValue('destino_uf', item)}
                municipio={form.values.destino_municipio}
                onChangeMunicipio={(item) => setDestinoMunicipio(item)}
              />
            </>
          )}

          <div className="container-input-quantidade">
            <div className="label">
              <p>Quantas cargas deseja criar com as mesmas informações?</p>
            </div>

            <div className="input">
              <button
                className="decrementa"
                onClick={
                  quantidadeCargas > 1
                    ? () => setQuantidadeCargas(quantidadeCargas - 1)
                    : () => {}
                }
              >
                {" "}
                -{" "}
              </button>
              <input
                type="number"
                value={quantidadeCargas}
                onChange={(e) => setQuantidadeCargas(e.target.value)}
              />
              <button
                className="incrementa"
                onClick={() => setQuantidadeCargas(quantidadeCargas + 1)}
              >
                +
              </button>
            </div>
          </div>
        </div>

        <div className="navegadores">
          <ImArrowLeft
            className={`icone ${currentStep !== 1 ? "active" : ""}`}
          ></ImArrowLeft>
          <ImArrowRight
            className={`icone ${currentStep !== totalSteps ? "active" : ""}`}
            onClick={() => handleNextStep()}
          ></ImArrowRight>
        </div>
      </div>

      {isSelectingColeta && (
        <ModalSelecionarColeta
          isOpen={isSelectingColeta}
          onClose={() => setIsSelectingColeta(false)}
          onConfirm={handleAddColetas}
        />
      )}
    </>
  );
};

export default Etapa1;
