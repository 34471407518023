import { useClientsPaginated } from '../../core/queries';
import { Pagination } from '../../components/layout/pagination';
import { API_CLIENT_PAGE_SIZE } from '../../core/config';
import { Linha } from './linha';

export const Clientes = ({ page, setPage, onConfirmSelect }) => {
  const { data } = useClientsPaginated(page);

  return (
    <div className="tabelas-grid">
      {!data.results.length && (
        <div className="empty">
          Não existem clientes para importar os dados!
        </div>
      )}
      {data.results.map((item) => (
        <Linha key={item.id} cliente={item} onConfirmSelect={onConfirmSelect} />
      ))}

      <div>
        <Pagination
          count={data?.count || 0}
          size={API_CLIENT_PAGE_SIZE}
          router={false}
          page={page}
          onChangePage={setPage}
        />
      </div>
    </div>
  );
};
