import React from 'react';
import './four-cols.scss';

export const FourCols = ({
  children,
  gap = undefined,
  columnGap = undefined,
  width = undefined,
}) => {
  return (
    <div className="inputs-four-columns" style={{ gap, columnGap, width }}>
      {children}
    </div>
  )
}
