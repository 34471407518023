import React, { memo, useState, useEffect, useRef } from "react";
import { FaRegIdCard, FaFileUpload } from "react-icons/fa";
import { FcDocument } from "react-icons/fc";
import { RiCloseCircleFill } from "react-icons/ri";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./styles.css";

import QuadroRegistros from "../QuadroRegistros";

import api from "../../../../../services/api";

const Documentos = ({ idCarga }) => {
  const [carga, setCarga] = useState(null);
  const [documentosAnexados, setDocumentosAnexados] = useState([]);

  const anexoDocumento = useRef("");

  const enviarDocumentos = async () => {
    const arrayArquivos = Array.from(anexoDocumento.current.files);
    const promises = [];

    for (const item of arrayArquivos) {
      const formData = new FormData();
      formData.append("arquivo", item);
      formData.append("carga", idCarga);

      promises.push(
        api.post("cargas_documentos/", formData).then(function (response) {
          setDocumentosAnexados((documentos) => [...documentos, response.data]);
        })
      );
    }

    await Promise.all(promises);
  };

  const removerDocumento = (idDocumento) => {
    api
      .delete(`cargas_documentos/${idDocumento}/`)
      .then(function () {
        setDocumentosAnexados(
          documentosAnexados.filter((doc) => doc.id !== idDocumento)
        );
      })
      .catch(function (error) {
        console.log(error);

        confirmAlert({
          title: "Erro",
          message: `Erro ao remover documento. Tente novamente`,
          overlayClassName: "alert-remover-documento",
          buttons: [
            {
              label: "Ok",
              className: "botao-cancelar",
            },
          ],
        });
      });
  };

  const alertRemoverDocumento = (idDocumento) => {
    confirmAlert({
      title: "Remover Documento",
      message: `Deseja realmente remover este documento?`,
      overlayClassName: "alert-remover-documento",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            removerDocumento(idDocumento);
          },
          className: "botao-confirmar",
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar",
        },
      ],
    });
  };

  useEffect(() => {
    api
      .get(`cargas_full/${idCarga}/`)
      .then(function (response) {
        setCarga(response.data);
        setDocumentosAnexados(response.data.documentos);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idCarga]);

  return (
    <div className="modal-perfil-motorista-carga-documentos">
      <div className="dados-pedido">
        {/* <CircularProgressbar
          className="progresso"
          value={0}
          text={`${0}%`}
        /> */}

        <div className="informacoes">
          <div>
            <FaRegIdCard className="icone"></FaRegIdCard>
            <p>
              <span>ID:</span> {carga?.id}
            </p>
          </div>
          <p className="pedido">
            <span>Pedido:</span>
            {carga?.pedidos.map((item, index) =>
              index < carga.pedidos.length - 1
                ? `${item.numero}, `
                : ` ${item.numero}`
            )}
          </p>
          {
            carga?.motorista_em_viagem?.tipo_cadastro === "NoApp" && <p className="message-app">Cadastro de motorista sem utilização do APP, algumas funcionalidades não disponíveis</p>
          }
        </div>
      </div>

      <hr className="separador" />

      <div className="documentos-carga">
        <p className="titulo">Documentos relacionados à carga</p>

        <div className="lista-documentos">
          {documentosAnexados.map((item) => (
            <div className="documento" key={item.id}>
              <RiCloseCircleFill
                className="icone-remover"
                onClick={() => alertRemoverDocumento(item.id)}
              ></RiCloseCircleFill>
              <a href={item.arquivo} target="_blank" rel="noreferrer">
                <FcDocument className="icone-documento"></FcDocument>
              </a>
              <p>{item.arquivo.split("/").pop()}</p>
            </div>
          ))}
        </div>

        <div className="btn-upload">
          <label htmlFor="arquivo">
            <FaFileUpload className="icone"></FaFileUpload>
            <p>Upload de arquivo</p>
          </label>

          <input
            type="file"
            name="arquivo"
            ref={anexoDocumento}
            onChange={enviarDocumentos}
            multiple
          />
        </div>
      </div>

      <hr className="separador" />

      <QuadroRegistros idCarga={idCarga}></QuadroRegistros>
    </div>
  );
};

export default memo(Documentos);
