import React, { memo, useState, useEffect, useRef, useCallback } from "react";
import { BsSearch, BsFillPersonFill } from "react-icons/bs";
import { MdClear } from "react-icons/md";
import { FiTruck } from "react-icons/fi";

import {
  FaRegCalendarAlt,
  FaRegCalendarCheck,
  FaTag,
  FaMapMarkerAlt,
  FaMapMarker,
} from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./styles.css";

import BreadCrumbs from "../../../../components/plataformaAcessada/BreadCrumbs";
import NotificacaoFechamentoCarga from "../../../../components/plataformaAcessada/Notificacoes/FechamentoCarga";
import NotificacaoNovaCandidatura from "../../../../components/plataformaAcessada/Notificacoes/NovaCandidatura";
import RetificacaoDocumento from "../../../../components/plataformaAcessada/Notificacoes/RetificacaoDocumento";
import NotificacaoMotoristaNaPortaria from "../../../../components/plataformaAcessada/Notificacoes/MotoristaNaPortaria";
import NotificacaoEmbarqueFinalizado from "../../../../components/plataformaAcessada/Notificacoes/EmbarqueFinalizado";
import NotificacaoEntregaFinalizada from "../../../../components/plataformaAcessada/Notificacoes/EntregaFinalizada";
import NotificacaoNovoRegistro from "../../../../components/plataformaAcessada/Notificacoes/NovoRegistro";
import NotificacaoCandidaturaNegada from "../../../../components/plataformaAcessada/Notificacoes/CandidaturaNegada";
import NovasCargas from "./Quadros/NovasCargas";
import Embarque from "./Quadros/Embarque";
import EmTransito from "./Quadros/EmTransito";
import Entregue from "./Quadros/Entregue";

import { CardsProvider } from './Quadros/CardsContext';

import api from "../../../../services/api";
import ButtonSupport from "../../../../components/plataformaAcessada/ButtonSupport";

const PainelMonitoramento = () => {
  const routes = [
    { path: "/acesso", breadcrumb: "Início" },
    {
      path: "/acesso/painel-monitoramento",
      breadcrumb: "Painel Monitoramento",
    },
  ];

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
  const tipoUsuario = dadosUsuario.user_type;
  const idUsuario =
    tipoUsuario === "transportadora" ||
    tipoUsuario === "colaborador_transportadora"
      ? dadosUsuario.transportadora_id
      : tipoUsuario === "embarcador" || tipoUsuario === "colaborador_embarcador"
      ? dadosUsuario.embarcador_id
      : dadosUsuario.id;

  const [filtroCarga, setFiltroCarga] = useState("");
  const [filtroTransportadora, setFiltroTransportadora] = useState("");
  const [filtroEmbarque, setFiltroEmbarque] = useState("");
  const [filtroDescarga, setFiltroDescarga] = useState("");
  const [filtroTipoCarga, setFiltroTipoCarga] = useState("");
  const [filtroOrigem, setFiltroOrigem] = useState("");
  const [filtroDestino, setFiltroDestino] = useState("");
  const [filtroMotorista, setFiltroMotorista] = useState("");
  const [checkboxTodos, setCheckboxTodos] = useState(false);
  const [checkboxPrazo, setCheckboxPrazo] = useState(false);
  const [checkboxPendencia, setCheckboxPendencia] = useState(false);
  const [checkboxOcorrencia, setCheckboxOcorrencia] = useState(false);
  const [checkboxAtrasado, setCheckboxAtrasado] = useState(false);

  const [gerarNovoRender, setGerarNovoRender] = useState(false);

  const inputEmbarque = useRef();
  const inputDescarga = useRef();

  // configuracao do pop-up de notificações
  toast.configure({
    autoClose: 15000,
    closeOnClick: false,
  });

  const wsAtualizacoesCargasPainel = useRef(null);

  const websocketAtualizacoesCargasPainel = useCallback(() => {
    wsAtualizacoesCargasPainel.current = new WebSocket(
      `${process.env.REACT_APP_LINK_API_WEBSOCKET}ws/cargas/notify/${
        tipoUsuario === "colaborador_transportadora"
          ? "transportadora"
          : tipoUsuario === "colaborador_embarcador"
          ? "embarcador"
          : tipoUsuario
      }/${idUsuario}`
    );

    const registrarCargasNotificadas = async (cargasNaoNegociadas) => {
      wsAtualizacoesCargasPainel.current.send(
        JSON.stringify({
          cargas_ids: cargasNaoNegociadas.map((item) => item.id),
        })
      );

      const promises = [];
      for (const item of cargasNaoNegociadas) {
        promises.push(
          api
            .post(`empresa_notificacoes/`, {
              tipo: "fechamento_carga",
              visualizado: false,
              transportadora: dadosUsuario.transportadora_id,
              embarcador: dadosUsuario.embarcador_id,
              carga: item.id,
            })
            .then(function (response) {
              toast(
                <NotificacaoFechamentoCarga
                  carga={item}
                  atualizarCargas={() =>
                    setGerarNovoRender((oldState) => !oldState)
                  }
                  idNotificacao={response.data.id}
                />
              );
            })
            .catch(function (error) {
              console.log(error);
            })
        );
      }

      await Promise.all(promises);
    };

    wsAtualizacoesCargasPainel.current.onopen = function () {
      console.log("websocketAtualizacoesCargasPainel conectado");
    };

    wsAtualizacoesCargasPainel.current.onmessage = function (e) {
      let JSONMessage = JSON.parse(e.data);
      console.log("🚀 RETORNO WEBSOCKET ->", JSONMessage);

      // Cargas que chegaram a data de fechamento sem negociação
      if (JSONMessage.tipo === "tempo_esgotado_nao_negociado") {
        let cargasNaoNegociadas = [];

        JSONMessage.cargas.forEach((item) => {
          cargasNaoNegociadas.push(item);
        });

        registrarCargasNotificadas(cargasNaoNegociadas);
      }

      // Cargas que tiveram cadastro ou remoção de candidaturas
      if (JSONMessage.tipo === "atualizar_candidaturas") {
        if (JSONMessage.evento === "criado") {
          api
            .post(`empresa_notificacoes/`, {
              tipo: "nova_candidatura",
              visualizado: false,
              transportadora: dadosUsuario.transportadora_id,
              embarcador: dadosUsuario.embarcador_id,
              motorista: JSONMessage.candidatura_item.motorista.id,
              carga: JSONMessage.carga.id,
            })
            .then(function (response) {
              toast(
                <NotificacaoNovaCandidatura
                  dadosCandidatura={JSONMessage}
                  forceRender={() =>
                    setGerarNovoRender((oldState) => !oldState)
                  }
                  idNotificacao={response.data.id}
                />
              );
            })
            .catch(function (error) {
              console.log(error);
            });
        } else if (JSONMessage.evento === "delete") {
          api
            .post(`empresa_notificacoes/`, {
              tipo: "candidatura_negada",
              visualizado: false,
              transportadora: dadosUsuario.transportadora_id,
              embarcador: dadosUsuario.embarcador_id,
              motorista: JSONMessage.candidatura_item.motorista.id,
              carga: JSONMessage.carga.id,
            })
            .then(function (response) {
              toast(
                <NotificacaoCandidaturaNegada
                  dadosCandidatura={JSONMessage}
                  idNotificacao={response.data.id}
                />
              );
            })
            .catch(function (error) {
              console.log(error);
            });
        }

        setGerarNovoRender((oldState) => !oldState);
      }

      // Cargas que tiveram o motorista reprovado no gerenciador de riscos
      if (JSONMessage.tipo === "guep_desacordo_retificar_dados") {
        api
          .post(`empresa_notificacoes/`, {
            tipo: "guep_desacordo_retificar_dados",
            visualizado: false,
            transportadora: dadosUsuario.transportadora_id,
            embarcador: dadosUsuario.embarcador_id,
            motorista: JSONMessage.motorista.id,
            carga: JSONMessage.carga.id,
          })
          .then(function (response) {
            toast(
              <RetificacaoDocumento
                dadosCandidatura={JSONMessage}
                forceRender={() => setGerarNovoRender((oldState) => !oldState)}
                idNotificacao={response.data.id}
              />
            );
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      // Cargas que tiveram cadastro ou atualização  de registros
      if (JSONMessage.tipo === "registro_atualizado") {
        // setGerarNovoRender(!gerarNovoRender)

        if (JSONMessage.evento === "criado") {
          api
            .post(`empresa_notificacoes/`, {
              tipo: "novo_registro",
              visualizado: false,
              transportadora: dadosUsuario.transportadora_id,
              embarcador: dadosUsuario.embarcador_id,
              motorista: JSONMessage.carga.motorista_em_viagem.id,
              carga: JSONMessage.carga.id,
            })
            .then(function (response) {
              toast(
                <NotificacaoNovoRegistro
                  carga={JSONMessage.carga}
                  forceRender={() =>
                    setGerarNovoRender((oldState) => !oldState)
                  }
                  idNotificacao={response.data.id}
                />
              );
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }

      // Cargas que tiveram alteração no checkpoint
      if (JSONMessage.tipo === "checkpoint_atualizado") {
        if (
          JSONMessage.checkpoint.chegada_local_embarque &&
          !JSONMessage.checkpoint.entrada_carregamento
        ) {
          api
            .post(`empresa_notificacoes/`, {
              tipo: "portaria",
              visualizado: false,
              transportadora: dadosUsuario.transportadora_id,
              embarcador: dadosUsuario.embarcador_id,
              motorista: JSONMessage.carga.motorista_em_viagem.id,
              carga: JSONMessage.carga.id,
            })
            .then(function (response) {
              toast(
                <NotificacaoMotoristaNaPortaria
                  carga={JSONMessage.carga}
                  forceRender={() =>
                    setGerarNovoRender((oldState) => !oldState)
                  }
                  idNotificacao={response.data.id}
                />
              );
              setGerarNovoRender((oldState) => !oldState);
              return;
            })
            .catch(function (error) {
              console.log(error);
            });
        }

        //Momento em que o motorista entra para o carregamento
        if (
          JSONMessage.checkpoint.entrada_carregamento &&
          !JSONMessage.checkpoint.carregamento_finalizado
        ) {
          setGerarNovoRender((oldState) => !oldState);
        }

        if (
          JSONMessage.checkpoint.carregamento_finalizado &&
          !JSONMessage.checkpoint.viagem_liberada
        ) {
          api
            .post(`empresa_notificacoes/`, {
              tipo: "embarque_finalizado",
              visualizado: false,
              transportadora: dadosUsuario.transportadora_id,
              embarcador: dadosUsuario.embarcador_id,
              motorista: JSONMessage.carga.motorista_em_viagem.id,
              carga: JSONMessage.carga.id,
            })
            .then(function (response) {
              toast(
                <NotificacaoEmbarqueFinalizado
                  carga={JSONMessage.carga}
                  forceRender={() =>
                    setGerarNovoRender((oldState) => !oldState)
                  }
                  idNotificacao={response.data.id}
                />
              );
            })
            .catch(function (error) {
              console.log(error);
            });
        }

        if (JSONMessage.checkpoint.entrega_concluida) {
          api
            .post(`empresa_notificacoes/`, {
              tipo: "entrega",
              visualizado: false,
              transportadora: dadosUsuario.transportadora_id,
              embarcador: dadosUsuario.embarcador_id,
              motorista: JSONMessage.carga.motorista_em_viagem.id,
              carga: JSONMessage.carga.id,
            })
            .then(function (response) {
              toast(
                <NotificacaoEntregaFinalizada
                  carga={JSONMessage.carga}
                  forceRender={() =>
                    setGerarNovoRender((oldState) => !oldState)
                  }
                  idNotificacao={response.data.id}
                />,
                {
                  autoClose: false,
                  hideProgressBar: true,
                  closeButton: false,
                }
              );
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }

      // Cargas que passaram do status de no-prazo para atraso, ou tiveram alteração na previsão de entrega
      if (
        JSONMessage.tipo === "carga_em_atraso" ||
        JSONMessage.tipo === "carga_atualizada"
      ) {
        setGerarNovoRender((oldState) => !oldState);
      }
    };

    wsAtualizacoesCargasPainel.current.onclose = function () {
      wsAtualizacoesCargasPainel.current = null;
      // setTimeout(function () {
      //   websocketAtualizacoesCargasPainel();
      // }, 5000);
    };
  }, [
    tipoUsuario,
    idUsuario,
    dadosUsuario.transportadora_id,
    dadosUsuario.embarcador_id,
    wsAtualizacoesCargasPainel,
  ]);

  useEffect(() => {
    if (wsAtualizacoesCargasPainel.current === null) {
      websocketAtualizacoesCargasPainel();
    }

    return () => {
      wsAtualizacoesCargasPainel.current.close();
    };
  }, [websocketAtualizacoesCargasPainel, wsAtualizacoesCargasPainel]);

  useEffect(() => {
    if (checkboxTodos) {
      setCheckboxPrazo(true);
      setCheckboxPendencia(true);
      setCheckboxOcorrencia(true);
      setCheckboxAtrasado(true);
    } else {
      setCheckboxPrazo(false);
      setCheckboxPendencia(false);
      setCheckboxOcorrencia(false);
      setCheckboxAtrasado(false);
    }
  }, [checkboxTodos]);

  // useEffect(() => {
  //   console.log("dados", dadosUsuario)
  // }, [])

  return (
    <div className="container-painel-monitoramento">
      <BreadCrumbs routes={routes} isPainelMonitoramento={true}></BreadCrumbs>

      <div className="painel-monitoramento">
        <div className="container-filtros">
          <div className="input-filtro">
            <BsSearch className="icone-filtro"></BsSearch>
            <input
              type="text"
              placeholder="Pesquisar Carga"
              value={filtroCarga}
              onChange={(e) => setFiltroCarga(e.target.value)}
            />
            <MdClear
              onClick={() => setFiltroCarga("")}
              className="icone-limpar"
            ></MdClear>
          </div>

          <div className="input-filtro">
            <FiTruck className="icone-filtro"></FiTruck>
            <input
              type="text"
              placeholder="Transportadora"
              value={filtroTransportadora}
              onChange={(e) => setFiltroTransportadora(e.target.value)}
            />
            <MdClear
              onClick={() => setFiltroTransportadora("")}
              className="icone-limpar"
            ></MdClear>
          </div>

          <div className="input-filtro">
            <FaRegCalendarAlt className="icone-filtro"></FaRegCalendarAlt>
            <input
              ref={inputEmbarque}
              type="text"
              onFocus={() => (inputEmbarque.current.type = "date")}
              onBlur={() => {
                filtroEmbarque === "" && (inputEmbarque.current.type = "text");
              }}
              placeholder="Data Embarque"
              value={filtroEmbarque}
              onChange={(e) => setFiltroEmbarque(e.target.value)}
            />
            <MdClear
              className="icone-limpar"
              onClick={() => {
                setFiltroEmbarque("");
                inputEmbarque.current.type = "text";
              }}
            ></MdClear>
          </div>

          <div className="input-filtro">
            <FaRegCalendarCheck className="icone-filtro"></FaRegCalendarCheck>
            <input
              ref={inputDescarga}
              type="text"
              onFocus={() => (inputDescarga.current.type = "date")}
              onBlur={() => {
                filtroDescarga === "" && (inputDescarga.current.type = "text");
              }}
              placeholder="Data Descarga"
              value={filtroDescarga}
              onChange={(e) => setFiltroDescarga(e.target.value)}
            />
            <MdClear
              className="icone-limpar"
              onClick={() => {
                setFiltroDescarga("");
                inputDescarga.current.type = "text";
              }}
            ></MdClear>
          </div>

          <div className="input-filtro">
            <FaTag className="icone-filtro"></FaTag>
            <input
              type="text"
              placeholder="Tipo de Carga"
              value={filtroTipoCarga}
              onChange={(e) => setFiltroTipoCarga(e.target.value)}
            />
            <MdClear
              onClick={() => setFiltroTipoCarga("")}
              className="icone-limpar"
            ></MdClear>
          </div>

          <div className="input-filtro">
            <BsFillPersonFill className="icone-filtro"></BsFillPersonFill>
            <input
              type="text"
              placeholder="Motorista"
              value={filtroMotorista}
              onChange={(e) => setFiltroMotorista(e.target.value)}
            />
            <MdClear
              onClick={() => setFiltroMotorista("")}
              className="icone-limpar"
            ></MdClear>
          </div>

          <div className="input-filtro">
            <FaMapMarkerAlt className="icone-filtro"></FaMapMarkerAlt>
            <input
              type="text"
              placeholder="Origem em"
              value={filtroOrigem}
              onChange={(e) => setFiltroOrigem(e.target.value)}
            />
            <MdClear
              onClick={() => setFiltroOrigem("")}
              className="icone-limpar"
            ></MdClear>
          </div>

          <div className="input-filtro">
            <FaMapMarker className="icone-filtro"></FaMapMarker>
            <input
              type="text"
              placeholder="Destino em"
              value={filtroDestino}
              onChange={(e) => setFiltroDestino(e.target.value)}
            />
            <MdClear
              onClick={() => setFiltroDestino("")}
              className="icone-limpar"
            ></MdClear>
          </div>

          {/* <button className="btn-filtro-avancado">
            <FaFilter className="icone"></FaFilter>
            <p>Filtro Avançado</p>
          </button> */}
        </div>

        <div className="container-status">
          <div className="container-input-checkbox">
            <span
              className="checkbox"
              onClick={() => setCheckboxTodos(!checkboxTodos)}
            >
              <input
                type="checkbox"
                checked={checkboxTodos}
                onChange={() => setCheckboxTodos(!checkboxTodos)}
              />
              <span className="checked"></span>
              <div className="label">
                <p>Todos</p>
                <div className="cor todos"></div>
              </div>
            </span>
          </div>

          <div className="container-input-checkbox">
            <span
              className="checkbox"
              onClick={() => setCheckboxPrazo(!checkboxPrazo)}
            >
              <input
                type="checkbox"
                checked={checkboxPrazo}
                onChange={() => setCheckboxPrazo(!checkboxPrazo)}
              />
              <span className="checked"></span>
              <div className="label">
                <p>No Prazo</p>
                <div className="cor prazo"></div>
              </div>
            </span>
          </div>

          <div className="container-input-checkbox">
            <span
              className="checkbox"
              onClick={() => setCheckboxPendencia(!checkboxPendencia)}
            >
              <input
                type="checkbox"
                checked={checkboxPendencia}
                onChange={() => setCheckboxPendencia(!checkboxPendencia)}
              />
              <span className="checked"></span>
              <div className="label">
                <p>Pendência</p>
                <div className="cor pendencia"></div>
              </div>
            </span>
          </div>

          <div className="container-input-checkbox">
            <span
              className="checkbox"
              onClick={() => setCheckboxOcorrencia(!checkboxOcorrencia)}
            >
              <input
                type="checkbox"
                checked={checkboxOcorrencia}
                onChange={() => setCheckboxOcorrencia(!checkboxOcorrencia)}
              />
              <span className="checked"></span>
              <div className="label">
                <p>Com Registros</p>
                <div className="cor ocorrencia"></div>
              </div>
            </span>
          </div>

          <div className="container-input-checkbox">
            <span
              className="checkbox"
              onClick={() => setCheckboxAtrasado(!checkboxAtrasado)}
            >
              <input
                type="checkbox"
                checked={checkboxAtrasado}
                onChange={() => setCheckboxAtrasado(!checkboxAtrasado)}
              />
              <span className="checked"></span>
              <div className="label">
                <p>Atrasado</p>
                <div className="cor atrasado"></div>
              </div>
            </span>
          </div>
        </div>

        <div className="container-quadros">
          <CardsProvider
            filtroCarga={filtroCarga}
            filtroTransportadora={filtroTransportadora}
            filtroEmbarque={filtroEmbarque.replaceAll("_", "")}
            filtroDescarga={filtroDescarga.replaceAll("_", "")}
            filtroTipoCarga={filtroTipoCarga}
            filtroOrigem={filtroOrigem}
            filtroDestino={filtroDestino}
            filtroMotorista={filtroMotorista}
            checkboxTodos={checkboxTodos}
            checkboxPrazo={checkboxPrazo ? "no-prazo" : ""}
            checkboxPendencia={checkboxPendencia ? "pendencia" : ""}
            checkboxOcorrencia={checkboxOcorrencia ? "ocorrencia" : ""}
            checkboxAtrasado={checkboxAtrasado ? "atraso" : ""}
          >
            <NovasCargas />
            <Embarque />
            <EmTransito />
            <Entregue />
          </CardsProvider>
          {/*
          <EmTransito
            moverCarga={() => setGerarNovoRender(!gerarNovoRender)}
            moverCargaParaTransito={gerarNovoRender}
            filtroCarga={filtroCarga}
            filtroTransportadora={filtroTransportadora}
            filtroEmbarque={filtroEmbarque.replaceAll("_", "")}
            filtroDescarga={filtroDescarga.replaceAll("_", "")}
            filtroTipoCarga={filtroTipoCarga}
            filtroOrigem={filtroOrigem}
            filtroDestino={filtroDestino}
            filtroMotorista={filtroMotorista}
            checkboxTodos={checkboxTodos}
            checkboxPrazo={checkboxPrazo ? "no-prazo" : ""}
            checkboxPendencia={checkboxPendencia ? "pendencia" : ""}
            checkboxOcorrencia={checkboxOcorrencia ? "ocorrencia" : ""}
            checkboxAtrasado={checkboxAtrasado ? "atraso" : ""}
          ></EmTransito>
          <Entregue
            moverCarga={() => setGerarNovoRender(!gerarNovoRender)}
            moverCargaParaEntregue={gerarNovoRender}
            filtroCarga={filtroCarga}
            filtroTransportadora={filtroTransportadora}
            filtroEmbarque={filtroEmbarque.replaceAll("_", "")}
            filtroDescarga={filtroDescarga.replaceAll("_", "")}
            filtroTipoCarga={filtroTipoCarga}
            filtroOrigem={filtroOrigem}
            filtroDestino={filtroDestino}
            filtroMotorista={filtroMotorista}
            checkboxTodos={checkboxTodos}
            checkboxPrazo={checkboxPrazo ? "no-prazo" : ""}
            checkboxPendencia={checkboxPendencia ? "pendencia" : ""}
            checkboxOcorrencia={checkboxOcorrencia ? "ocorrencia" : ""}
            checkboxAtrasado={checkboxAtrasado ? "atraso" : ""}
          ></Entregue>
          */}
        </div>
      </div>
      <div>
        <ButtonSupport />
      </div>
    </div>
  );
};

export default memo(PainelMonitoramento);
