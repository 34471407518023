import clsx from 'clsx';
import './form-section-title.scss';

export const FormSectionTitle = ({ children, small }) => {
  return (
    <p
      className={clsx({
        "section-title": true,
        "small": small,
      })}
    >
      {children}
    </p>
  );
};
