import React from 'react';
import { useField } from 'formik';
import clsx from 'clsx';
import '../input/input.scss';
import './checkbox.scss';

export const Checkbox = ({ label, small, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const handleChange = () => {
    helpers.setValue(!meta.value);
  }

  const handleClick = () => {
    if (props.disabled) {
      return;
    }
    handleChange();
  }

  return (
    <div className="form-input-container">
      <div
        className={clsx({
          "form-checkbox": true,
          'small': small,
          'disabled': props.disabled,
        })}
      >
        <span className="checkbox" onClick={handleClick} >
          <input
            type="checkbox"
            checked={meta.value}
            onChange={handleChange}
            {...props}
          />
          <span className="checked"></span>
          {label}
        </span>
      </div>
      {meta.touched && meta.error && (
        <span className="form-input-error">{meta.error}</span>
      )}
    </div>
  );
}