export const papelDefaults = {
  cpf: '',
  cnpj: '',
  razao_social: '',
  ie: '',
  endereco: {
    logradouro: '',
    numero: '',
    bairro: '',
    codigo_municipio: '',
    nome_municipio: '',
    uf: '',
    cep: '',
    complemento: '',
  }
};

export const converterPapelParaEnvio = (papel) => {
  if (!papel) {
    return null;
  }

  return {
    cpf: papel.cpf || null,
    cnpj: papel.cnpj || null,
    razao_social: papel.razao_social || null,
    inscricao_estadual: papel.ie || null,
    endereco: {
      logradouro: papel.endereco.logradouro || null,
      numero: papel.endereco.numero || null,
      complemento: papel.endereco.complemento || null,
      bairro: papel.endereco.bairro || null,
      codigo_municipio: papel.endereco.codigo_municipio || null,
      nome_municipio: papel.endereco.nome_municipio || null,
      cep: papel.endereco.cep.replace('-', '') || null,
      uf: papel.endereco.uf || null,
    }
  };
};

export const converterPapelDeDadosEmissao = (papel) => {
  if (!papel) {
    return null;
  }

  return {
    cpf: papel.cpf || '',
    cnpj: papel.cnpj || '',
    razao_social: papel.razao_social || '',
    ie: papel.inscricao_estadual || '',
    endereco: {
      logradouro: papel.endereco.logradouro || '',
      numero: papel.endereco.numero || '',
      complemento: papel.endereco.complemento || '',
      bairro: papel.endereco.bairro || '',
      codigo_municipio: papel.endereco.codigo_municipio || '',
      nome_municipio: papel.endereco.nome_municipio || '',
      cep: papel.endereco.cep || '',
      uf: papel.endereco.uf || '',
    }
  };
};
