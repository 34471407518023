import React, { memo, useCallback } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import './styles.css'

import retificacao from "../../../../assets/ctes.png"

import api from '../../../../services/api'

const RetificacaoDocumento = ({ dadosCandidatura, forceRender, idNotificacao, closeToast }) => {
  const registrarNotificacaoVisualizada = useCallback(async () => {
    await api.patch(`empresa_notificacoes/${idNotificacao}/`, {
      visualizado: true
    })
      .then(function () {
        closeToast()
        forceRender()
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idNotificacao, closeToast, forceRender])

  const reprovarProposta = () => {
    const reprovar = (onClose) => {
      api.post(`/candidatura/${dadosCandidatura.candidatura.id}/negar_candidatura/`)
        .then(function () {
          onClose()
          registrarNotificacaoVisualizada()
        })
        .catch(function (error) {
          console.log(error)
        })
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='container-alert-proposta'>
            <p className="titulo">
              Você deseja realmente <span className="reprovar">reprovar</span> o motorista para a carga?
            </p>

            <div className="motorista">
              <img src={dadosCandidatura.motorista.imagem_perfil} alt="" />
              <p>{dadosCandidatura.motorista.nome_completo}</p>
            </div>

            <div className="botoes">
              <button className="cancelar" onClick={onClose}>Não</button>
              <button className="confirmar" onClick={() => reprovar(onClose)}>Sim</button>
            </div>
          </div>
        )
      }
    })
  }

  return (
    <div className="notificacao-retificacao-documento">
      <img src={retificacao} alt="" />

      <div className="conteudo">
        <p>
          O motorista {dadosCandidatura.motorista.nome_completo}, do frete do ID {dadosCandidatura.carga.id}
          com Destino à {dadosCandidatura.carga.pontos_parada.map((item, index) => {
            return (
              index !== 0
                ? ` | ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
                : ` ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
            )
          })} foi reprovado no Gerenciador de Riscos.<br />
          Deseja efetuar a retificação?
        </p>

        <p className="aviso">
          (Use o usuário e senha pré informados no onboarding para realizar o login no WS da GUEP)
        </p>

        <div className="botoes">
          <a href="https://score.guep.com.br/v/#/login" target="_blank" rel="noreferrer" onClick={registrarNotificacaoVisualizada}>
            <button className="sim">Sim</button>
          </a>
          <button className="nao" onClick={reprovarProposta}>Não</button>
        </div>
      </div>
    </div>
  )
}

export default memo(RetificacaoDocumento)