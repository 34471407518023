import React, { useEffect } from 'react'
import { ImArrowLeft, ImArrowRight } from 'react-icons/im'
import { FaInfoCircle } from 'react-icons/fa'
import NumberFormat from 'react-number-format'
import './styles.css'

const Etapa2 = ({
  currentStep,
  totalSteps,
  nextStep,
  previousStep,
  form,
  
  todosVeiculos,
  todasCarrocerias,
}) => {
  const verificarTiposVeiculosSelecionados = () => {
    let tiposVeiculosSelecionados = []

    const obterIDVeiculos = (nome) => {
      todosVeiculos.forEach((item) => {
        if (item.tipo === nome) {
          tiposVeiculosSelecionados.push(item.id)
        }
      })
    }

    for (let property in form.values.tipos_veiculo_leve) {
      if (form.values.tipos_veiculo_leve[property] === true) {
        obterIDVeiculos(property)
      }
    }

    for (let property in form.values.tipos_veiculo_medio) {
      if (form.values.tipos_veiculo_medio[property] === true) {
        obterIDVeiculos(property)
      }
    }

    for (let property in form.values.tipos_veiculo_pesado) {
      if (form.values.tipos_veiculo_pesado[property] === true) {
        obterIDVeiculos(property)
      }
    }

    return tiposVeiculosSelecionados
  }

  const verificarTiposCarroceriasSelecionadas = () => {
    let tiposCarroceriasSelecionadas = []

    const obterIDCarrocerias = (nome) => {
      todasCarrocerias.forEach((item) => {
        if (item.tipo === nome) {
          tiposCarroceriasSelecionadas.push(item.id)
        }
      })
    }

    for (let property in form.values.tipos_carroceria_fechada) {
      if (form.values.tipos_carroceria_fechada[property] === true) {
        obterIDCarrocerias(property)
      }
    }

    for (let property in form.values.tipos_carroceria_aberta) {
      if (form.values.tipos_carroceria_aberta[property] === true) {
        obterIDCarrocerias(property)
      }
    }

    for (let property in form.values.tipos_carroceria_especial) {
      if (form.values.tipos_carroceria_especial[property] === true) {
        obterIDCarrocerias(property)
      }
    }

    return tiposCarroceriasSelecionadas
  }

  const handleGoToNext = () => {
    form.setFieldValue('tipos_veiculo', verificarTiposVeiculosSelecionados());
    form.setFieldValue('tipos_carroceria', verificarTiposCarroceriasSelecionadas());

    nextStep();
  }

  useEffect(() => {
    if (!Object.keys(form.values.coletas).length) {
      return;
    }
    const coletas = Object.keys(form.values.coletas).reduce((prev, item) => [...prev, form.values.coletas[item]], []);

    const peso = coletas.reduce((prev, coleta) => {
      return prev + (parseFloat(coleta?.nfe?.peso || 0));
    }, 0);
    const volume = coletas.reduce((prev, coleta) => {
      return prev + (parseFloat(coleta?.nfe?.volume || 0));
    }, 0);
    const volumes = coletas.reduce((prev, coleta) => {
      return prev + (parseFloat(coleta?.nfe?.volumes || 0));
    }, 0);

    form.setFieldValue('volume_unitario', (volume / (volumes || 1)));
    form.setFieldValue('peso_unitario', (peso / (volumes || 1)));
    form.setFieldValue('quantidade_volume', volumes);
  }, []);

  return (
    <div className="carga-etapa-2">
      <div className="conteudo">
        <p className="titulo-etapa">
          Por favor, informe os dados solicitados para cadastrar uma nova carga.
        </p>

        <div className="quadro-informacoes">
          <div className="titulo-quadro">
            <FaInfoCircle className="icone"></FaInfoCircle>
            <p>Veículo <span>*</span></p>
          </div>

          <div className="container-opcoes">
            <div className="quadro">
              <p className="titulo-opcao">Leves</p>

              <div className="lista-opcoes">
                {
                  Object.keys(form.values.tipos_veiculo_leve).map((item, index) => {
                    return (
                      <div className="container-input-checkbox" key={index}>
                        <span
                          className="checkbox"
                          onClick={() => form.setFieldValue('tipos_veiculo_leve', { ...form.values.tipos_veiculo_leve, [item]: !form.values.tipos_veiculo_leve[item] })}
                        >
                          <input
                            type="checkbox"
                            value={item}
                            checked={form.values.tipos_veiculo_leve[item]}
                            onChange={() => form.setFieldValue('tipos_veiculo_leve', { ...form.values.tipos_veiculo_leve, [item]: !form.values.tipos_veiculo_leve[item] })}
                          />
                          <span className="checked"></span>
                          {item}
                        </span>
                      </div>
                    )
                  })
                }
              </div>
            </div>

            <div className="quadro">
              <p className="titulo-opcao">Médios</p>

              <div className="lista-opcoes">
                {
                  Object.keys(form.values.tipos_veiculo_medio).map((item, index) => {
                    return (
                      <div className="container-input-checkbox" key={index}>
                        <span
                          className="checkbox"
                          onClick={() => form.setFieldValue('tipos_veiculo_medio', { ...form.values.tipos_veiculo_medio, [item]: !form.values.tipos_veiculo_medio[item] })}
                        >
                          <input
                            type="checkbox"
                            value={item}
                            checked={form.values.tipos_veiculo_medio[item]}
                            onChange={() => form.setFieldValue('tipos_veiculo_medio', { ...form.values.tipos_veiculo_medio, [item]: !form.values.tipos_veiculo_medio[item] })}
                          />
                          <span className="checked"></span>
                          {item}
                        </span>
                      </div>
                    )
                  })
                }
              </div>
            </div>

            <div className="quadro">
              <p className="titulo-opcao">Pesados</p>

              <div className="lista-opcoes">
                {
                  Object.keys(form.values.tipos_veiculo_pesado).map((item, index) => {
                    return (
                      <div className="container-input-checkbox" key={index}>
                        <span
                          className="checkbox"
                          onClick={() => form.setFieldValue('tipos_veiculo_pesado', { ...form.values.tipos_veiculo_pesado, [item]: !form.values.tipos_veiculo_pesado[item] })}
                        >
                          <input
                            type="checkbox"
                            value={item}
                            checked={form.values.tipos_veiculo_pesado[item]}
                            onChange={() => form.setFieldValue('tipos_veiculo_pesado', { ...form.values.tipos_veiculo_pesado, [item]: !form.values.tipos_veiculo_pesado[item] })}
                          />
                          <span className="checked"></span>
                          {item}
                        </span>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>

        <div className="quadro-informacoes">
          <div className="titulo-quadro">
            <FaInfoCircle className="icone"></FaInfoCircle>
            <p>Carroceria <span>*</span></p>
          </div>

          <div className="container-opcoes">
            <div className="quadro">
              <p className="titulo-opcao">Fechada</p>

              <div className="lista-opcoes">
                {
                  Object.keys(form.values.tipos_carroceria_fechada).map((item, index) => {
                    return (
                      <div className="container-input-checkbox" key={index}>
                        <span
                          className="checkbox"
                          onClick={() => form.setFieldValue('tipos_carroceria_fechada', { ...form.values.tipos_carroceria_fechada, [item]: !form.values.tipos_carroceria_fechada[item] })}
                        >
                          <input
                            type="checkbox"
                            value={item}
                            checked={form.values.tipos_carroceria_fechada[item]}
                            onChange={() => form.setFieldValue('tipos_carroceria_fechada', { ...form.values.tipos_carroceria_fechada, [item]: !form.values.tipos_carroceria_fechada[item] })}
                          />
                          <span className="checked"></span>
                          {item}
                        </span>
                      </div>
                    )
                  })
                }
              </div>
            </div>

            <div className="quadro">
              <p className="titulo-opcao">Aberta</p>

              <div className="lista-opcoes">
                {
                  Object.keys(form.values.tipos_carroceria_aberta).map((item, index) => {
                    return (
                      <div className="container-input-checkbox" key={index}>
                        <span
                          className="checkbox"
                          onClick={() => form.setFieldValue('tipos_carroceria_aberta', { ...form.values.tipos_carroceria_aberta, [item]: !form.values.tipos_carroceria_aberta[item] })}
                        >
                          <input
                            type="checkbox"
                            value={item}
                            checked={form.values.tipos_carroceria_aberta[item]}
                            onChange={() => form.setFieldValue('tipos_carroceria_aberta', { ...form.values.tipos_carroceria_aberta, [item]: !form.values.tipos_carroceria_aberta[item] })}
                          />
                          <span className="checked"></span>
                          {item}
                        </span>
                      </div>
                    )
                  })
                }
              </div>
            </div>

            <div className="quadro">
              <p className="titulo-opcao">Especial</p>

              <div className="lista-opcoes">
                {
                  Object.keys(form.values.tipos_carroceria_especial).map((item, index) => {
                    return (
                      <div className="container-input-checkbox" key={index}>
                        <span
                          className="checkbox"
                          onClick={() => form.setFieldValue('tipos_carroceria_especial', { ...form.values.tipos_carroceria_especial, [item]: !form.values.tipos_carroceria_especial[item] })}
                        >
                          <input
                            type="checkbox"
                            value={item}
                            checked={form.values.tipos_carroceria_especial[item]}
                            onChange={() => form.setFieldValue('tipos_carroceria_especial', { ...form.values.tipos_carroceria_especial, [item]: !form.values.tipos_carroceria_especial[item] })}
                          />
                          <span className="checked"></span>
                          {item}
                        </span>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>

        <div className="campos">
          <div className="container-inputs-3col">
            <div className="container-input">
              <NumberFormat
                required
                value={form.values.quantidade_volume}
                onChange={(e) => form.setFieldValue('quantidade_volume', e.target.value)}
                displayType={'number'}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>
                <FaInfoCircle className="icone"></FaInfoCircle>
                Quantidade de Volumes (Un)
              </span>
            </div>

            <div className="container-input">
              <NumberFormat
                required
                value={form.values.peso_unitario}
                onChange={(e) => form.setFieldValue('peso_unitario', e.target.value)}
                displayType={'number'}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>
                <FaInfoCircle className="icone"></FaInfoCircle>
                Peso Unitário (kg)
              </span>
            </div>

            <div className="container-input">
              <NumberFormat
                required
                value={form.values.volume_unitario}
                onChange={(e) => form.setFieldValue('volume_unitario', e.target.value)}
                displayType={'number'}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>
                <FaInfoCircle className="icone"></FaInfoCircle>
                Volume Unitário (m3)
              </span>
            </div>
          </div>

          <div className="container-input">
            <input
              min={(new Date(new Date().getTime() - (3 * 60 * 60 * 1000))).toISOString().slice(0, -8)}
              type="datetime-local"
              value={form.values.data_embarque}
              onChange={(e) => form.setFieldValue('data_embarque', e.target.value)}
            />
            <span>
              <FaInfoCircle className="icone"></FaInfoCircle>
              Data Embarque <i>*</i>
            </span>
          </div>

          <div className="container-input">
            <input
              min={
                form.values.data_embarque
                  ? (new Date(new Date(form.values.data_embarque).getTime() - (3 * 60 * 60 * 1000))).toISOString().slice(0, -8)
                  : (new Date(new Date().getTime() - (3 * 60 * 60 * 1000))).toISOString().slice(0, -8)
              }
              type="datetime-local"
              value={form.values.data_descarga}
              onChange={(e) => form.setFieldValue('data_descarga', e.target.value)}
            />
            <span>
              <FaInfoCircle className="icone"></FaInfoCircle>
              Data Descarga <i>*</i>
            </span>
          </div>
        </div>
      </div>

      <div className="navegadores">
        <ImArrowLeft
          className={`icone ${currentStep !== 1 ? "active" : ""}`}
          onClick={previousStep}
        ></ImArrowLeft>
        <ImArrowRight
          className={`icone ${currentStep !== totalSteps ? "active" : ""}`}
          onClick={() => handleGoToNext()}
        ></ImArrowRight>
      </div>
    </div>
  );
};

export default Etapa2;
