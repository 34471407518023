import React, { useEffect, memo, useRef, useState } from 'react'
import { RiCloseCircleFill } from 'react-icons/ri'
import Modal from 'react-modal'
import { parseISO, format } from 'date-fns'
import { saveAs } from 'file-saver';

import './styles.css'

// import pdfIcon from "../../../../../../../assets/pdf.png"
import xmlIcon from "../../../../../../../assets/xml.png"

import api from "../../../../../../../services/api"

const ModalDadosNfe = ({ idNfeSelecionada, modalDadosNfe, handleModalDadosNfe }) => {
  const [nfe, setNfe] = useState(null)
  // const [pdfNfe, setPdfNfe] = useState('')

  const numero = useRef('')
  const serie = useRef('dado')
  const emissao = useRef('')
  const valor = useRef('')
  const peso = useRef('')
  const volume = useRef('')
  const cfop = useRef('')
  const chaveAcessoNfe = useRef('')
  const razaoSocialEmitente = useRef('')
  const razaoSocialDestinatario = useRef('')
  const origemCidadeUf = useRef('')
  const destinoCidadeUf = useRef('')

  useEffect(() => {
    api.get(`documentos_fiscais/nfe/${idNfeSelecionada}/`)
      .then(function (response) {
        let documento = response.data

        setNfe(documento)
        numero.current.value = documento.numero
        serie.current.value = documento.serie
        emissao.current.value = format(parseISO(documento.emissao), "dd/MM/yyyy HH:mm")
        valor.current.value = documento.valor
        peso.current.value = documento.peso
        volume.current.value = documento.volume
        cfop.current.value = documento.cfop
        chaveAcessoNfe.current.value = documento.chave
        razaoSocialEmitente.current.value = documento.remetente_razao_social
        razaoSocialDestinatario.current.value = documento.destinatario_razao_social
        origemCidadeUf.current.value = `${documento?.remetente_endereco.nome_municipio}-${documento?.remetente_endereco.uf}`
        destinoCidadeUf.current.value = `${documento?.destinatario_endereco.nome_municipio}-${documento?.destinatario_endereco.uf}`
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idNfeSelecionada])

  return (
    <Modal
      isOpen={modalDadosNfe}
      onRequestClose={handleModalDadosNfe}
      className="modal-dados-nfe"
      overlayClassName="overlay-modal-dados-nfe"
      ariaHideApp={false}
    >
      <div className="dados-nfe">
        <RiCloseCircleFill className="icone-fechar" onClick={handleModalDadosNfe}></RiCloseCircleFill>

        <p className="titulo">Dados da NF-e</p>

        <div className="container-acoes">
          <img src={xmlIcon} alt="xml" onClick={() => saveAs(nfe.arquivo, `NF-e${nfe.numero}.xml`)} />
          {/* <img src={pdfIcon} alt="pdf" /> */}
        </div>

        <div className="container-formulario">
          <div className="container-inputs-3col">
            <div className="container-input-comum">
              <input
                required
                type="text"
                ref={numero}
                disabled
                defaultValue="Dado"
              />
              <span>Número</span>
            </div>

            <div className="container-input-comum">
              <input
                required
                type="text"
                ref={serie}
                disabled
                defaultValue="Dado"
              />
              <span>Série</span>
            </div>

            <div className="container-input-comum">
              <input
                required
                type="text"
                ref={emissao}
                disabled
                defaultValue="Dado"
              />
              <span>Emissão</span>
            </div>
          </div>


          <div className="container-inputs-3col">
            <div className="container-input-comum">
              <input
                required
                type="text"
                ref={valor}
                disabled
              />
              <span>Valor</span>
            </div>

            <div className="container-input-comum">
              <input
                required
                type="number"
                ref={peso}
                disabled
              />
              <span>Peso</span>
            </div>

            <div className="container-input-comum">
              <input
                required
                type="number"
                ref={volume}
                disabled
              />
              <span>Volume</span>
            </div>
          </div>

          <div className="container-inputs-2col">
            <div className="container-input-comum">
              <input
                required
                type="text"
                ref={cfop}
                disabled
                defaultValue="Dado"
              />
              <span>CFOP</span>
            </div>

            <div className="container-input-comum">
              <input
                required
                type="text"
                ref={chaveAcessoNfe}
                disabled
              />
              <span>Chave de Acesso NF-e</span>
            </div>
          </div>

          <div className="container-inputs-3col">
            <div className="container-input-comum">
              <input
                required
                type="text"
                ref={razaoSocialEmitente}
                disabled
              />
              <span>Razão Social Emitente</span>
            </div>

            <div className="container-input-comum">
              <input
                required
                type="text"
                ref={razaoSocialDestinatario}
                disabled
              />
              <span>Razão Social Destinatário</span>
            </div>

            <div className="container-input-comum">
              <input
                required
                type="text"
                ref={origemCidadeUf}
                disabled
              />
              <span>Origem - Cidade UF</span>
            </div>
          </div>

          <div className="container-inputs-3col">
            <div className="container-input-comum">
              <input
                required
                type="text"
                ref={destinoCidadeUf}
                disabled
              />
              <span>Destino - Cidade UF</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default memo(ModalDadosNfe)