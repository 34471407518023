import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'
import BreadCrumbs from "../../../components/plataformaAcessada/BreadCrumbs";
import { LoadingAnimated } from '../../../components/plataformaAcessada/LoadingAnimated'
import api from "../../../services/api";
import 'react-confirm-alert/src/react-confirm-alert.css'
import "./styles.css";

const GerenciarAliquotas = () => {
  const history = useHistory();
  const routes = [
    { path: "/acesso", breadcrumb: "Início" },
    { path: "/acesso/financeiro", breadcrumb: "Financeiro" },
  ];
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await api.get(`aliquotas/tabela/`);
        setData(data);
      } catch {}
    }

    fetch();
  }, []);

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      await api.put(`aliquotas/tabela/`, data);

      confirmAlert({
        title: "Sucesso!",
        message: `Dados salvos com sucesso!`,
        overlayClassName: "alert-remover-colaborador",
        buttons: [
          {
            label: "Ok",
            className: "botao-cancelar"
          }
        ]
      })
    } catch {
      confirmAlert({
        title: "Erro!",
        message: `Houve um erro ao salvar os dados! Tente novamente mais tarde!`,
        overlayClassName: "alert-remover-colaborador",
        buttons: [
          {
            label: "Ok",
            className: "botao-cancelar"
          }
        ]
      })
    };
  }

  const handleChange = (origem, destino, valor) => {
    setData((prev) => {

      return prev.map((item) => {
        if (item.origem !== origem) {
          return item;
        }
        return {
          ...item,
          destinos: item.destinos.map((itemDest) => {
            if (itemDest.destino !== destino) {
              return itemDest;
            }
            return {
              ...itemDest,
              aliquota: parseFloat(valor),
            };
          }),
        };
      })
    })
  }

  return (
    <div className="container-aliquotas">
      <BreadCrumbs routes={routes} />

      {!data.length && (
        <div className="loading-container">
          <LoadingAnimated />
        </div>
      )}

      <form onSubmit={handleSave}>
        <div>
          <div className="destinoTitle">
            DESTINO
          </div>
        </div>
        <div className="base">
          <div className="table-left">
            <table className="aliquotas-table">
              <tbody>
                <tr>
                  <th rowSpan={28} className="origemTitleContainer">
                    <span className="origemTitle">ORIGEM</span>
                  </th>
                  <th scope="row">&nbsp;</th>
                </tr>
                {data.map((line) => (
                  <tr key={line.origem}>
                    <th scope="row">{line.origem}</th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div>
            <div className="table-sticky">
              <table className="aliquotas-table">
                <thead>
                  <tr>
                    {data.map((line) => (
                      <th key={line.origem}>{line.origem}</th>
                    ))}
                  </tr>
                </thead>
              </table>
            </div>
            <div className="table">
              <table className="aliquotas-table">
                <tbody>
                  {data.map((line) => (
                    <tr key={line.origem}>
                      {line.destinos.map((column) => (
                        <td className="input-container" key={`${line.origem}_${column.destino}`}>
                          <input
                            type="number"
                            value={column.aliquota}
                            onChange={(e) => handleChange(line.origem, column.destino, e.target.value)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="table-right">
            <table className="aliquotas-table">
              <tbody>
                <tr>
                  <th scope="row">&nbsp;</th>
                  <th rowSpan={28} className="origemTitleContainer">
                    <span className="origemTitle">ORIGEM</span>
                  </th>
                </tr>
                {data.map((line) => (
                  <tr key={line.origem}>
                    <th scope="row">{line.origem}</th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {!!data.length && (
          <div className="container-botoes">
            <button
              className="btn-cancelar"
              onClick={() => history.push("/acesso/destinatario")}
            >
              Cancelar
            </button>

            <button className="btn-cadastrar" type="submit">
              Salvar Alíquotas
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default GerenciarAliquotas;
