import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import PrivateRoute from "./PrivateRoute"
import PlataformaLogin from './PlataformaLogin'
import PlataformaAcessada from './PlataformaAcessada'

import { AuthProvider } from '../context/Auth'
import { MessagesProvider } from '../context/messages-context'

export default function Routes() {
  return (
    <MessagesProvider>
      <BrowserRouter>
        <AuthProvider>
          <Switch>
            <PrivateRoute path='/acesso' component={PlataformaAcessada}></PrivateRoute>
            <Route path='/' component={PlataformaLogin}></Route>
          </Switch>
        </AuthProvider>
      </BrowserRouter>
    </MessagesProvider>
  )
}