import { useState } from 'react';
import { Formik, Form } from 'formik';
import { useQueryClient } from 'react-query';
import { Suspense } from '../../../../components/utils';
import { DashboardPage, DashboardHeader } from '../../../../components/layout';
import { ModalAnexarDFE } from '../../../../modals/modal-anexar-dfe';
import { ModalEmitirCteLote } from '../../../../modals/modal-emitir-cte-lote';
import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import { HStack } from '../../../../components/utils';
import { FourCols } from '../../../../components/forms';
import { InputFiltro, SelectFiltro } from '../../../../components/layout/filtros';
import api from '../../../../services/api';
import { ColetasTable } from './ColetasTable';
import { ColetasActions } from './ColetasActions';
import ModalGerarCteAvulso from '../../PainelMonitoramento/DocumentosGerais/GerarDocumentoAvulso/ModalGerarCteAvulso';
import { useMessages } from '../../../../context/messages-context';

const routes = [
  { path: '/acesso', breadcrumb: 'Início' },
  { path: '/acesso/coletas', breadcrumb: 'Coletas' },
]

const GerenciarColetas = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { showError } = useMessages();
  
  const [isCteOpen, setIsCteOpen] = useState(false);
  const [idColeta, setIdColeta] = useState(null);
  const [dadoNfe, setDadoNfe] = useState(null);

  const [idsColetas, setIdsColetas] = useState(null);
  const [isCteLoteOpen, setIsCteLoteOpen] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (data) => {
    const items = Object.keys(data).filter((item) => data[item]);
    if (!items.length) {
      return;
    }

    if (items.length === 1) {
      const { data } = await api.get(`/fracionado/coletas/${items[0]}/`);
      if (!data.nfe.chave) {
        showError('Erro!', 'A coleta não possui uma NF-e anexada!');
        return;
      }

      setIsCteOpen(true);
      setIdColeta(items[0]);
      setDadoNfe([data.nfe]);
      return;
    }
    setIdsColetas(items);
    setIsCteLoteOpen(true);
  };

  return (
    <DashboardPage>
      <BreadCrumbs routes={routes} />

      <Formik initialValues={{}} onSubmit={handleSubmit}>
        <Form>
          <DashboardHeader title="Coletas" description="Altere o filtro para buscar pela coleta desejada">
            <ColetasActions onCreate={() => setIsOpen(true)} />
          </DashboardHeader>

          <>
            <HStack padding="20px 0">
              <FourCols gap="20px" columnGap="20px" width='100%'>
                <InputFiltro
                  placeholder="Cliente"
                  parameter="search"
                />
                <InputFiltro
                  placeholder="UF do Remetente"
                  parameter="ufRemetente"
                />
                <InputFiltro
                  placeholder="Município do Remetente"
                  parameter="municipioRemetente"
                />
                <InputFiltro
                  placeholder="UF do Destinatário"
                  parameter="ufDestinatario"
                />
                <InputFiltro
                  placeholder="Município do Destinatário"
                  parameter="municipioDestinatario"
                />
                <InputFiltro
                  placeholder="Data de Solicitação (De)"
                  type="date"
                  parameter="dataInicial"
                />
                <InputFiltro
                  placeholder="Data de Solicitação (Até)"
                  type="date"
                  parameter="dataFinal"
                />
                <SelectFiltro
                  placeholder="Status"
                  parameter="status"
                  options={[
                    { value: 'COLETA', label: 'Coleta' },
                    { value: 'PROCESSADA', label: 'Processada' },
                    { value: 'EM_ENTREGA', label: 'Em Entrega' },
                    { value: 'ENTREGUE', label: 'Entregue' },
                    { value: 'EM_COLETA', label: 'Em Coleta' },
                    { value: 'COLETADA', label: 'Coletada' },
                  ]}
                />
              </FourCols>
            </HStack>
          </>

          <Suspense>
            <ColetasTable onCreateClick={() => setIsOpen(true)} />
          </Suspense>

          {isOpen && (
            <ModalAnexarDFE
              isOpen={isOpen}
              title={"Anexar NF-e para Criar Coleta"}
              isColeta
              onClose={() => setIsOpen(false)}
            />
          )}
        </Form>
      </Formik>

      {isCteLoteOpen && idsColetas && (
        <ModalEmitirCteLote
          isOpen={isCteLoteOpen}
          onClose={() => {
            setIsCteLoteOpen(false);
            setIsCteLoteOpen(false);
          }}
          idsColetas={idsColetas}
          onComplete={() => {
            setIsCteLoteOpen(false);
            setIdsColetas(null);
            queryClient.invalidateQueries(['coletas']);
          }}
        />
      )}

      {isCteOpen && idColeta && dadoNfe && (
        <ModalGerarCteAvulso
          isOpen={isCteOpen}
          tipoCteInicial={'1'}
          onClose={() => {
            setIsCteOpen(false);
            setIdColeta(null);
            setDadoNfe(null);
            queryClient.invalidateQueries(['coletas']);
          }}
          dadosNfes={dadoNfe}
          idColeta={idColeta}
        />
      )}
    </DashboardPage>
  )
}

export default GerenciarColetas;
