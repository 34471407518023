import React, { useEffect } from 'react';
import {useFormikContext} from 'formik';
import { NoItemsBox, Pagination, Tabela } from '../../../../components/layout';
import { API_CLIENT_PAGE_SIZE } from '../../../../core/config';
import { useColetasPaginated } from '../../../../core/queries';
import { HStack } from '../../../../components/utils';
import { ColetasRow } from './ColetasRow';

export const ColetasTable = ({ onCreateClick }) => {
  const { data } = useColetasPaginated();
  const { setValues } = useFormikContext();

  useEffect(() => {
    if (!data?.results.length) {
      return;
    }

    const values = data.results.reduce((prev, item) => ({
      ...prev,
      [item.id]: false,
    }), {});
    setValues(values);
  }, [data, setValues]);

  return (
    <>
      <div className="container-tabela-listar-destinatarios" style={{ paddingTop: 40 }}>
        <Tabela>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>ID</th>
              <th>Status</th>
              <th>NF-e</th>
              <th>CT-e</th>
              <th>Status CT-e</th>
              <th>Data Solicitação</th>
              <th>Cliente</th>
              <th>Origem</th>
              <th>Destino</th>
              <th>Motorista</th>
              <th>Veículo</th>
              <th>Carreta</th>
              <th>Ações</th>
            </tr>
          </thead>

          <tbody>
            {data?.results?.map((item) => (
              <ColetasRow coleta={item} key={item.id} />
            ))}

            {!data?.results.length && (
              <tr>
                <td colSpan={13}>
                  <NoItemsBox
                    text="Não existem coletas cadastradas!"
                    onCreateClick={onCreateClick}
                    createButton
                  />
                </td>
              </tr>
            )}
          </tbody>
        </Tabela>
      </div>

      <HStack justifyContent="flex-end" padding="20px 0">
        <Pagination
          count={data?.count || 0}
          size={API_CLIENT_PAGE_SIZE}
        />
      </HStack>
    </>
  );
};
