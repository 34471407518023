import React, { memo, useState } from 'react'
import { parseISO, format } from 'date-fns'

import './styles.css'

import api from "../../../../services/api"

const NotificacaoFechamentoCarga = ({ closeToast, carga, atualizarCargas, idNotificacao }) => {
  const [dataFechamento, setDataFechamento] = useState(carga.data_fechamento)

  const registrarNotificacaoVisualizada = async () => {
    await api.patch(`empresa_notificacoes/${idNotificacao}/`, {
      visualizado: true
    })
      .then(function () {
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const alterarDataFechamento = () => {
    api.patch(`cargas/${carga.id}/`, {
      data_fechamento: `${dataFechamento}:00`
    })
      .then(() => {
        registrarNotificacaoVisualizada()
        atualizarCargas()
        closeToast()
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const cancelarProposta = () => {
    api.patch(`cargas/${carga.id}/`, {
      carga_cancelada: true,
      status_temporal: "no-prazo"
    })
      .then(() => {
        registrarNotificacaoVisualizada()
        atualizarCargas()
        closeToast()
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <div className="notificacao-fechamento-carga">
      <p>
        A carga de ID <span>{carga.id}</span>  atingiu seu horário de fechamento:
        <span>{format(parseISO(carga.data_fechamento), " dd/MM/yyyy 'às' HH:mm")}</span>.
      </p>

      <p>Origem:&nbsp;
        <span>{carga.origem.endereco.municipio} - {carga.origem.endereco.uf}</span>
      </p>

      <p>Destino(s):
        <span>
          {carga.pontos_parada.map((item, index) => {
            return (
              index !== 0
                ? ` | ${item.destino.endereco.municipio} - ${item.destino.endereco.uf}`
                : ` ${item.destino.endereco.municipio} - ${item.destino.endereco.uf}`
            )
          })}
        </span>
      </p>


      <p>Data de Embarque: <span>{format(parseISO(carga.data_embarque), " dd/MM/yyyy 'às' HH:mm")}</span></p>
      <p>Data de Descarga: <span>{format(parseISO(carga.data_descarga), " dd/MM/yyyy 'às' HH:mm")}</span></p>

      <p className="pergunta">Deseja alterar a data de fechamento?</p>

      <div className="container-input">
        <input
          min={new Date().toISOString().slice(0, -8)}
          type="datetime-local"
          value={dataFechamento}
          onChange={(e) => setDataFechamento(e.target.value)}
        />
        <span>Data Fechamento Proposta</span>
      </div>

      <div className="botoes-alterar-data">
        <button className="salvar" onClick={alterarDataFechamento}>Salvar Alteração</button>
        <button className="cancelar" onClick={cancelarProposta}>Cancelar Proposta</button>
      </div>
    </div>
  )
}

export default memo(NotificacaoFechamentoCarga)

