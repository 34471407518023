import React, { memo, useEffect, useState, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FaEye, FaTrashAlt, FaEdit, FaUserPlus } from 'react-icons/fa'
import { ImSearch } from 'react-icons/im'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ReactPaginate from 'react-paginate'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import api from "../../../../services/api"

const GerenciarColaborador = () => {
  const history = useHistory()

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
  const tipoUsuario = dadosUsuario.user_type

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/colaborador', breadcrumb: 'Colaborador' },
    { path: '/acesso/colaborador/gerenciar-colaborador', breadcrumb: 'Gerenciar Colaboradores' },
  ]

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const [colaboradores, setColaboradores] = useState([])
  const [search, setSearch] = useState('')
  const [quantidadePaginas, setQuantidadePaginas] = useState(1)
  const [paginaAtual, setPaginaAtual] = useState(0)

  const requisitarColaboradores = useCallback((paginaSelecionada, offSet) => {
    setPaginaAtual(paginaSelecionada)

    if (tipoUsuario === "embarcador" || tipoUsuario === "colaborador_embarcador") {
      api.get(`colaboradores/?embarcador=${dadosUsuario.embarcador_id}&?offset=${offSet ? offSet : 0}`)
        .then(function (response) {
          setQuantidadePaginas(Math.ceil(response.data.count / 30))

          let auxColaboradores = []

          response.data.results.sort((x, y) => x.id - y.id).forEach((item) => {
            auxColaboradores.push(item)
          })

          setColaboradores(auxColaboradores)
        })
        .catch(function (error) {
          console.log(error)
        })
    } else if (tipoUsuario === "transportadora" || tipoUsuario === "colaborador_transportadora") {
      api.get(`colaboradores/?transportadora=${dadosUsuario.transportadora_id}&?offset=${offSet ? offSet : 0}`)
        .then(function (response) {
          setQuantidadePaginas(Math.ceil(response.data.count / 30))

          let auxColaboradores = []

          response.data.results.sort((x, y) => x.id - y.id).forEach((item) => {
            auxColaboradores.push(item)
          })

          setColaboradores(auxColaboradores)
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      api.get(`colaboradores/?offset=${offSet ? offSet : 0}`)
        .then(function (response) {
          setQuantidadePaginas(Math.ceil(response.data.count / 30))
          setColaboradores(response.data.results)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [dadosUsuario.embarcador_id, dadosUsuario.transportadora_id, tipoUsuario])

  const filtrarItens = (paginaSelecionada, offSet) => {
    setPaginaAtual(paginaSelecionada)

    api.get(`colaboradores/?search=${search}&?offset=${offSet ? offSet : 0}`)
      .then(function (response) {
        setQuantidadePaginas(Math.ceil(response.data.count / 30))
        let auxColaboradores = []

        response.data.results.sort((x, y) => x.id - y.id).forEach((item) => {
          auxColaboradores.push(item)
        })

        setColaboradores(auxColaboradores)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const removerColaborador = (nome, id) => {
    confirmAlert({
      title: "Remover Colaborador",
      message: `Deseja realmente remover o colaborador "${nome}" do sistema?`,
      overlayClassName: "alert-remover-colaborador",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            api.delete(`colaboradores/${id}/`)
              .then(function () {
                requisitarColaboradores()
              })
              .catch(function (error) {
                console.log(error)
              })
          },
          className: "botao-confirmar"
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar"
        }
      ]
    })
  }

  const verificarTeclaSearch = (e) => {
    if (e.keyCode === 13) {
      filtrarItens()
    }
  }

  useEffect(() => {
    if (search === '') {
      forceUpdate()
      requisitarColaboradores()
    }
  }, [requisitarColaboradores, forceUpdate, search])

  return (
    <div className="container-gerenciar-colaborador">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="gerenciar-colaborador">
        <div className="container-topo">
          <div>
            <p className="titulo-pagina">Gerenciar Colaboradores</p>
            <p className="subtitulo-pagina">Realize o filtro para busca do colaborador desejado</p>
          </div>

          <button className="btn-novo-colaborador" onClick={() => history.push("/acesso/colaborador/novo-colaborador")}>
            <FaUserPlus className="icone"></FaUserPlus>
            Novo Colaborador
          </button>
        </div>

        <div className="container-search">
          <div className="input-search colaborador">
            <input
              type="text"
              placeholder="Buscar Colaborador (Nome)"
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={verificarTeclaSearch}
            />
            <ImSearch className="icone"></ImSearch>
          </div>

          <button className="btn-buscar" onClick={() => filtrarItens()}>Buscar</button>
        </div>

        <div className="container-tabela-listar-colaboradores">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Ações</th>
              </tr>
            </thead>

            <tbody>
              {colaboradores.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr className="divisoria-linhas-tabela"></tr>
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td className="coluna-nome">{item.nome_completo}</td>

                      <td className="coluna-acoes">
                        <Link to={`/acesso/colaborador/visualizar-colaborador/${item.id}`}>
                          <FaEye className="icone"></FaEye>
                        </Link>
                        <Link to={`/acesso/colaborador/editar-colaborador/${item.id}`}>
                          <FaEdit className="icone"></FaEdit>
                        </Link>
                        <FaTrashAlt className="icone" onClick={() => removerColaborador(item.nome_completo, item.id)}></FaTrashAlt>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="container-paginacao-colaborador">
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próxima"}
            breakLabel={"..."}
            pageCount={quantidadePaginas}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={paginaAtual}
            onPageChange={(e) => search === ""
              ? requisitarColaboradores(e.selected, e.selected * 30)
              : filtrarItens(e.selected, e.selected * 30)
            }
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(GerenciarColaborador)