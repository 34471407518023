import { useFormik } from 'formik';
import SelecionarEndereco from '../../Painel/AdicionarCarga/Etapa1/selecionar-endereco';
import api from '../../../../../services/api'
import './styles.css';

const EditarPontosParada = ({ idCarga, pontos_parada, onClose, refetch }) => {
	const formik = useFormik({
		initialValues: {
      destino_uf: {
        label: '',
        value: '',
        codigo: '',
      },
      destino_municipio: {
        label: '',
        value: '',
        codigo: '',
      },
			pontos_parada: pontos_parada.map((ponto) => ({
				uf: {
					value: ponto.destino.endereco.uf,
					label: ponto.destino.endereco.uf,
					codigo: ponto.destino.endereco.uf_codigo,
				},
				municipio: {
					value: ponto.destino.endereco.municipio,
					label: ponto.destino.endereco.municipio,
					codigo: ponto.destino.endereco.municipio_codigo,
				}
			})),
		}
	});

  const handleRemovePontoParada = (index) => {
    formik.setFieldValue(
      'pontos_parada',
      formik.values.pontos_parada.filter((_, itemIndex) => itemIndex !== index)
    );
  }
  const handleChangeItemUf = (index, uf) => {
    formik.setFieldValue(
      'pontos_parada',
      formik.values.pontos_parada.map((item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            uf,
          };
        }
        return item;
      })
    );
  }

  const handleChangeItemMunicipio = (index, municipio) => {
    formik.setFieldValue(
      'pontos_parada',
      formik.values.pontos_parada.map((item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            municipio,
          };
        }
        return item;
      })
    );
  }

  const handleAddPontoParada = (destino_uf, destino_municipio) => {
    if (!destino_uf.value || !destino_municipio.value) {
      return;
    }

    formik.setFieldValue('pontos_parada', ([
      ...formik.values.pontos_parada,
      {
        uf: destino_uf,
        municipio: destino_municipio,
      }
    ]));
    formik.setFieldValue('destino_uf', formik.initialValues.destino_uf)
    formik.setFieldValue('destino_municipio', formik.initialValues.destino_municipio)
  }

  const setDestinoMunicipio = (item) => {
    formik.setFieldValue('destino_municipio', item);
    if (item.value && item.codigo && formik.values.destino_uf.value && formik.values.destino_uf.codigo) {
      handleAddPontoParada(formik.values.destino_uf, item);
    }
  };

	const handleConfirm = async () => {
		try {
			await api.patch(`/cargas/${idCarga}/`, {
				pontos_parada: formik.values.pontos_parada.map((item) => ({
					'uf': item.uf.value,
					'uf_codigo': item.uf.codigo,
					'municipio': item.municipio.value,
					'municipio_codigo': item.municipio.codigo,
				})),
			});
			onClose();
		} catch {}
	}

	return (
    <div className="editar-pontos-parada">
    	{formik.values.pontos_parada.map((item, index) => (
				<SelecionarEndereco
					title={`Ponto de Parada #${index + 1}`}
					uf={item.uf}
					key={index}
					onChangeUf={(item) => handleChangeItemUf(index, item)}
					municipio={item.municipio}
					onChangeMunicipio={(item) => handleChangeItemMunicipio(index, item)}
					removeButton
					onRemoveButtonClick={() => handleRemovePontoParada(index)}
				/>
			))}

			<SelecionarEndereco
				title="Adicionar Ponto de Parada"
				uf={formik.values.destino_uf}
				onChangeUf={(item) => formik.setFieldValue('destino_uf', item)}
				municipio={formik.values.destino_municipio}
				onChangeMunicipio={(item) => setDestinoMunicipio(item)}
			/>

			<div className="fill" />

			<div className="submit-line">
				<button className="btn-cancelar" onClick={onClose}>
					Cancelar
				</button>
				<button
					className="btn-criar"
					onClick={handleConfirm}
				>
					Salvar
				</button>
			</div>
    </div>
	);
};

export default EditarPontosParada;
