import React, { useEffect } from 'react';
import { Formik, useFormikContext } from 'formik';
import { RiCloseCircleFill } from 'react-icons/ri'
import {
  FourCols,
  Input,
  ThreeCols,
  InputValorPeso,
  Select,
  Radio,
} from '../../../components/forms';
import { HStack } from '../../../components/utils';
import { Button } from '../../../components/common';
import { confirmDelete } from '../../../components/actions';
import { useUpdateCteMutation, useDeleteCteMutation } from '../../../core/mutations'

const initialValues = {
  modalidade: 'CIF',
  chave: '',
  nfe: '',
  cliente: '',
  cidadeUf: '',
  peso: '',
  valorTnCtSelecionado: '',
  valorTnCt: '',
  valorCobrancaSelecionado: '',
  valorCobranca: '',
  tipoPagamento: '',
  observacoes: '',
}

const CteForm = ({ documento, onRemoved }) => {
  const { values, setValues } = useFormikContext();
  const saveMutation = useUpdateCteMutation();
  const deleteMutation = useDeleteCteMutation();

  const handleSave = () => {
    if (deleteMutation.isLoading || saveMutation.isLoading) {
      return;
    }

    saveMutation.mutate({
      id: documento.id,
      data: {
        modalidade: values.modalidade === 'CIF' ? "CIF" : "FOB",
        valor_cte_tipo: values.valorTnCtSelecionado,
        valor_cte: values.valorTnCt,
        valor_cobranca_tipo: values.valorCobrancaSelecionado,
        valor_cobranca: Number((values.valorCobranca || '0').replaceAll(".", '').replaceAll(",", '.')),
        tipo_pagamento: values.tipoPagamento,
        observacoes: values.observacoes,
      },
    });
  }

  const handleDelete = async () => {
    if (deleteMutation.isLoading || saveMutation.isLoading) {
      return;
    }
    const confirm = await confirmDelete({
      title: "Tem certeza?",
      message: `Tem certeza que deseja deletar o CT-e ${documento.numero}? Essa ação não poderá ser desfeita!`
    });
    if (!confirm) {
      return;
    }
    
    deleteMutation.mutate(
      { id: documento.id },
      {
        onSuccess: () => onRemoved(documento.id)
      }
    );
  }

  useEffect(() => {
    let cliente = documento?.remetente_razao_social;
    let cidadeUf = `${documento?.remetente_endereco.nome_municipio}-${documento?.remetente_endereco.uf}`;
    if (documento?.modalidade !== "CIF") {
      cliente = documento?.destinatario_razao_social;
      cidadeUf = `${documento?.destinatario_endereco.nome_municipio}-${documento?.destinatario_endereco.uf}`;
    }

    setValues({
      modalidade: documento?.modalidade === "CIF" ? "CIF" : "FOB",
      chave: documento?.chave,
      nfe: documento?.notas_transportadas[0]?.chave,
      cliente,
      cidadeUf,
      peso: documento?.peso,
      valorTnCtSelecionado: documento?.valor_cte_tipo,
      valorTnCt: documento?.valor_cte || 0,
      valorCobrancaSelecionado: documento?.valor_cobranca_tipo,
      valorCobranca: documento?.valor_cobranca || 0,
      tipoPagamento: documento?.tipo_pagamento,
      observacoes: documento?.observacoes,
    });
  }, [documento, setValues]);

  return (
    <div className="cte-item">
      <RiCloseCircleFill
        className="close"
        onClick={handleDelete}
      />

      <p className="titulo">CT-e {documento.numero}</p>
      
      <FourCols>
        <Input
          disabled
          white
          borderless
          name="chave"
          label="CT-e"
        />
        <Input
          disabled
          white
          borderless
          name="nfe"
          label="NF-e"
        />
        <Input
          disabled
          white
          borderless
          name="cliente"
          label="Cliente"
        />
        <Input
          disabled
          white
          borderless
          name="cidadeUf"
          label="Cidade-UF"
        />
      </FourCols>
      <ThreeCols>
        <Input
          disabled
          white
          borderless
          type="number"
          name="peso"
          label="Peso"
        />
        <InputValorPeso
          white
          borderless
          name="valorTnCt"
          label="Valor CT-e"
          tipoField="valorTnCtSelecionado"
        />
        <InputValorPeso
          white
          borderless
          name="valorCobranca"
          label="Valor Cobrança"
          tipoField="valorCobrancaSelecionado"
        />
      </ThreeCols>
      <ThreeCols>
        <Select
          white
          borderless
          name="tipoPagamento"
          label="Tipo de Pagamento"
          options={[
            {
              label: "Boleto",
              value: "boleto"
            },
            {
              label: "PIX",
              value: "pix"
            },
            {
              label: "TED/DOC",
              value: "ted-doc"
            },
            {
              label: "Mot. Rec",
              value: "mot-rec"
            },
            {
              label: "Planilha",
              value: "planilha"
            },
          ]}
        />
        <Input
          white
          borderless
          name="observacoes"
          label="Observações"
        />
        <HStack alignItems="center" gap="20px">
          <Radio
            name="modalidade"
            value="CIF"
            label="CIF"
            id="cif"
          />
          <Radio
            name="modalidade"
            value="FOB"
            label="FOB"
            id="fob"
          />
        </HStack>
      </ThreeCols>
      <HStack justifyContent="center" padding="10px 0">
        <Button
          variant="save"
          disabled={saveMutation.isLoading || deleteMutation.isLoading}
          onClick={handleSave}
          small
        >
          {saveMutation.isLoading ? 'Salvando...' : 'Salvar'}
        </Button>
      </HStack>
    </div>
  )
}

export const Cte = ({
  documento,
  onRemoved,
}) => {

  return (
    <Formik initialValues={initialValues}>
      <CteForm documento={documento} onRemoved={onRemoved} />
    </Formik>
  )
}
