import React, { memo, useRef, useState, useCallback } from 'react'
import { ImSearch } from 'react-icons/im'
import { useHistory } from 'react-router-dom'

import './styles.css'

import BreadCrumbs from '../../../../../components/plataformaAcessada/BreadCrumbs'

const NovoGrupoPermissoes = ({ match }) => {
  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const id = match.params?.id
  const desabilitarInputs = match.path === '/acesso/colaborador/permissoes/visualizar-grupo-permissoes/:id' ? true : false
  const isEditar = match.path === '/acesso/colaborador/permissoes/editar-grupo-permissoes/:id' ? true : false

  const history = useHistory()

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/colaborador', breadcrumb: 'Colaborador' },
    { path: '/acesso/colaborador/permissoes', breadcrumb: 'Permissões' },
    { path: '/acesso/colaborador/permissoes/novo-grupo-permissoes', breadcrumb: 'Novo Grupo de Permissões' },
    { path: `/acesso/colaborador/permissoes/visualizar-grupo-permissoes`, breadcrumb: null },
    { path: `/acesso/colaborador/permissoes/visualizar-grupo-permissoes/${id}`, breadcrumb: 'Visualizar Grupo Permissões' },
    { path: `/acesso/colaborador/permissoes/editar-grupo-permissoes`, breadcrumb: null },
    { path: `/acesso/colaborador/permissoes/editar-grupo-permissoes/${id}`, breadcrumb: 'Editar Grupo Permissões' },
  ]

  const [todasPermissoes, setTodasPermissoes] = useState([
    "Permissão 0; Permissão 0; Permissão 0",
    "Permissão 1; Permissão 1; Permissão 1",
    "Permissão 2; Permissão 2; Permissão 2",
    "Permissão 4; Permissão 4; Permissão 4",
    "Permissão 6; Permissão 6; Permissão 6",
    "Permissão 7; Permissão 7; Permissão 7",
    "Permissão 8; Permissão 8; Permissão 8",
    "Permissão 9; Permissão 9; Permissão 9"
  ])

  const [permissoesClicadas, setPermissoesClicadas] = useState([])

  const [permissoesEscolhidas, setPermissoesEscolhidas] = useState([
    "Permissão 3; Permissão 3; Permissão 3",
    "Permissão 5; Permissão 5; Permissão 5",
  ])

  const nomeGrupoPermissao = useRef('')

  const selecionarPermissao = (itemClicado) => {
    if (permissoesClicadas.indexOf(itemClicado) === -1) {
      setPermissoesClicadas(permissoesClicadas => [...permissoesClicadas, itemClicado])
    } else {
      let aux = permissoesClicadas
      aux.splice(aux.indexOf(itemClicado), 1)
      setPermissoesClicadas(aux)
    }

    forceUpdate()
  }

  const escolherTodasPermissoes = () => {
    let aux = permissoesEscolhidas.concat(todasPermissoes)
    setPermissoesEscolhidas(aux)
    setTodasPermissoes([])
    setPermissoesClicadas([])
  }

  const escolherPermissoes = () => {
    permissoesClicadas.forEach((escolhida) => {
      todasPermissoes.forEach((permissao) => {
        if (escolhida === permissao) {
          let aux = todasPermissoes
          aux.splice(aux.indexOf(escolhida), 1)
          setTodasPermissoes(aux)
        }
      })

      setPermissoesEscolhidas(permissoesEscolhidas => [...permissoesEscolhidas, escolhida])
      setPermissoesClicadas([])
    })
  }

  const removerPermissoes = () => {
    permissoesClicadas.forEach((escolhida) => {
      permissoesEscolhidas.forEach((permissao) => {
        if (escolhida === permissao) {
          let aux = permissoesEscolhidas
          aux.splice(aux.indexOf(escolhida), 1)
          setPermissoesEscolhidas(aux)

          let auxTodasPermissoes = todasPermissoes
          auxTodasPermissoes.push(escolhida)
          setTodasPermissoes(auxTodasPermissoes)
        }
      })
    })

    setPermissoesClicadas([])
    forceUpdate()
  }

  return (
    <div className="container-novo-grupo-permissoes">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="novo-grupo-permissoes">
        <p className="titulo-pagina">
          {
            desabilitarInputs && !isEditar
              ? "Colaborador - Visualizar Grupo De Permissões"
              : isEditar
                ? "Colaborador - Editar Grupo De Permissões"
                : "Colaborador - Novo Grupo De Permissões"
          }
        </p>
        <p className="subtitulo-pagina">
          {
            desabilitarInputs && !isEditar
              ? "Dados informados no cadastro do grupo de permissões"
              : isEditar
                ? "Por favor, informe os dados solicitados editar o cadastro de novo grupo de permissões"
                : "Por favor, informe os dados solicitados para cadastro de novo grupo de permissões"
          }
        </p>

        <div className="container-nome-grupo-permissoes">
          <p>Selecione as permissões abaixo para o novo grupo de permissões:</p>

          <div className="container-input">
            <input
              required
              type="text"
              ref={nomeGrupoPermissao}
            />
            <span>Nome Grupo de Permissão<i>*</i></span>
          </div>
        </div>

        <div className="container-quadros-permissoes">
          <div className="quadro">
            <div className="input-search colaborador">
              <input type="text" placeholder="Buscar Permissão" />
              <ImSearch className="icone"></ImSearch>
            </div>

            <div className="container-permissoes">
              {todasPermissoes.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={!desabilitarInputs
                      ? permissoesClicadas.indexOf(item) === -1 ? "permissao" : "permissao selecionada"
                      : "permissao desabilitada"
                    }
                    onClick={() => selecionarPermissao(item)}
                  >
                    <p>{item}</p>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="container-botoes">
            <button onClick={escolherTodasPermissoes} disabled={desabilitarInputs}>Escolher Todas</button>
            <button onClick={escolherPermissoes} disabled={desabilitarInputs}>Escolher</button>
            <button onClick={removerPermissoes} disabled={desabilitarInputs}>Remover</button>
          </div>

          <div className="quadro">
            <div className="input-search colaborador">
              <input type="text" placeholder="Buscar Permissão" />
              <ImSearch className="icone"></ImSearch>
            </div>

            <div className="container-permissoes">
              {permissoesEscolhidas.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={!desabilitarInputs
                      ? permissoesClicadas.indexOf(item) === -1 ? "permissao" : "permissao selecionada"
                      : "permissao desabilitada"
                    }
                    onClick={() => selecionarPermissao(item)}
                  >
                    <p>{item}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        {
          !desabilitarInputs &&
          <div className="container-botoes">
            <button
              className="btn-cancelar"
              onClick={() => history.replace("/acesso/colaborador/permissoes")}
            >
              Cancelar
            </button>

            <button
              className="btn-cadastrar"
              onClick={() => history.push("/acesso/colaborador/permissoes")}
            >
              {isEditar ? "Salvar Grupo" : "Cadastrar Grupo"}
            </button>
          </div>
        }
      </div>
    </div>
  )
}

export default memo(NovoGrupoPermissoes)