import { useRouteMatch } from 'react-router-dom';
import { Radio, SelectUF, SelectMunicipio } from '../../../../../../components/forms';
import './TabelaRota.scss';

export const TabelaRota = () => {
  const match = useRouteMatch();
  const isReadOnly = match.path === '/acesso/clientes/tabelas/visualizar/:id' ? true : false;

  return (
    <table className="tabela-rota">
      <thead>
        <tr>
          <th>
            <Radio
              name="tipo_rota"
              id="tipo_rota_uf_municipio"
              value="UF_MUNICIPIO"
              label="Por UF/Municipio"
              disabled={isReadOnly}
            />
          </th>
          <th>
            <Radio
              name="tipo_rota"
              id="tipo_rota_uf"
              value="UF"
              label="Por UF"
              disabled={isReadOnly}
            />
          </th>
          <th>
            <Radio
              name="tipo_rota"
              id="tipo_rota_global"
              value="GERAL"
              label="Geral"
              disabled={isReadOnly}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={3}>
            <div className="uf_municipio">
              <div className="origem">
                <div className="title">Origem:</div>
                <div className="uf">
                  <SelectUF
                    name="origem.uf"
                    label="Estado"
                    municipioField="origem.municipio"
                    disabled={isReadOnly}
                  />
                </div>
                <div className="municipio">
                  <SelectMunicipio
                    name="origem.municipio"
                    label="Município"
                    ufField="origem.uf"
                    disabled={isReadOnly}
                  />
                </div>
              </div>
              <div className="destino">
                <div className="title">Destino:</div>
                <div className="uf">
                  <SelectUF
                    name="destino.uf"
                    label="Estado"
                    municipioField="destino.municipio"
                    disabled={isReadOnly}
                  />
                </div>
                <div className="municipio">
                  <SelectMunicipio
                    name="destino.municipio"
                    label="Município"
                    ufField="destino.uf"
                    disabled={isReadOnly}
                  />
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}