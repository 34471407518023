import React, { useEffect, useMemo, useState } from 'react';
import Modal from "react-modal";
import { RiCloseCircleFill } from "react-icons/ri";
import { useFormik } from 'formik';
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "react-router-dom";
import { addDays } from "date-fns";
import api from "../../../../../../../services/api";
import './styles.css';
import Step1 from './step1';
import Step2 from './step2';
import { getDisplayErrors } from "../../utilities";
import { round } from './utils';
import "react-confirm-alert/src/react-confirm-alert.css";

const ModalSelecionarNotas = ({
	isOpen,
	onClose,
	idCarga,
  onComplete,
}) => {
  const history = useHistory();
	const [nfes, setNfes] = useState([]);
	const [checked, setChecked] = useState([]);
  const [step, setStep] = useState(0);
  const prestacaoForm = useFormik({
    initialValues: {
      isAPagar: true,
      isFracionado: true,

      valorTotalFrete: '',
      dividir: 'valor_nfe',
      
      situacaoIcms: '',
      aliquota: '',
      percentual_reducao: '',
      valor_credito: '',
      
      observacoesGerais: '',
      dataFechamentoCte: '',
      textoCfopSelecionado: 'Prest. de serv. de trans. a estab. industria',
      cfopSelecionado: '6352',
      tomadorContribuinte: '1',

      embutir_icms: false,
      embutir_impostos_federais: false,

      agrupar: false,

      parcelas: '0',
      periodo: '0',
      duplicatas: [],
    }
  });
  const [carga, setCarga] = useState(null);

	useEffect(() => {
		if (!idCarga) {
			return;
		}

		const fetch = async () => {
			try {
				const { data } = await api.get(`/documentos_fiscais/nfe/?carga=${idCarga}&limit=200`);
				setNfes(data.results);
			} catch {
				setNfes([]);
			}

      try {
        const { data } = await api.get(`/cargas_full/${idCarga}/`);
        setCarga(data);
        const periodoInicial = data?.transportadora?.omie_vencimento_conta_receber || 0;
        prestacaoForm.setFieldValue(
          'dataFechamentoCte',
          addDays(new Date(), periodoInicial)?.toISOString().split("T")[0]
        );
      } catch {
        setCarga(null);
      }
		}

		fetch();
	}, [idCarga]);


  const rateio = useMemo(() => {
    const calcularPIS = () => {
      if (!carga?.transportadora?.cte_embutir_pis) {
        return 0;
      }
      const aliq = Number(carga?.transportadora?.cte_aliq_pis);
      return aliq;
    };

    const calcularIR = () => {
      if (!carga?.transportadora?.cte_embutir_ir) {
        return 0;
      }
      const aliq = Number(carga?.transportadora?.cte_aliq_ir);
      return aliq;
    };

    const calcularINSS = () => {
      if (!carga?.transportadora?.cte_embutir_inss) {
        return 0;
      }
      const aliq = Number(carga?.transportadora?.cte_aliq_inss);
      return aliq;
    };

    const calcularCSSL = () => {
      if (!carga?.transportadora?.cte_embutir_cssl) {
        return 0;
      }
      const aliq = Number(carga?.transportadora?.cte_aliq_cssl);
      return aliq;
    };

    const calcularCOFINS = () => {
      if (!carga?.transportadora?.cte_embutir_cofins) {
        return 0;
      }
      const aliq = Number(carga?.transportadora?.cte_aliq_cofins);
      return aliq;
    };

    const getTotal = () => {
      return checked.reduce((prev, cur) => {
        if (prestacaoForm.values.dividir === 'valor_nfe') {
          return prev + parseFloat(cur.valor);
        }
        if (prestacaoForm.values.dividir === 'peso_nfe') {
          return prev + parseFloat(cur.peso);
        }
        if (prestacaoForm.values.dividir === 'qtd_volumes') {
          return prev + parseFloat(cur.volumes);
        }
        if (prestacaoForm.values.dividir === 'qtd_nfe') {
          return prev + 1;
        }
        return prev;
      }, 0);
    };

    const getItem = (item) => {
      if (prestacaoForm.values.dividir === 'valor_nfe') {
        return parseFloat(item.valor);
      }
      if (prestacaoForm.values.dividir === 'peso_nfe') {
        return parseFloat(item.peso);
      }
      if (prestacaoForm.values.dividir === 'qtd_volumes') {
        return parseFloat(item.volumes);
      }
      if (prestacaoForm.values.dividir === 'qtd_nfe') {
        if (!prestacaoForm.values.agrupar) {
          return 1;
        }
        const alreadyHave = checked.filter((current) => {
          if (item.remetente_cnpj !== current.remetente_cnpj) {
            return false;
          }
          if (item.remetente_cpf !== current.remetente_cpf) {
            return false;
          }
          if (item.remetente_ie !== current.remetente_ie) {
            return false;
          }
          if (item.destinatario_cnpj !== current.destinatario_cnpj) {
            return false;
          }
          if (item.destinatario_cpf !== current.destinatario_cpf) {
            return false;
          }
          if (item.destinatario_ie !== current.destinatario_ie) {
            return false;
          }
          return true;
        });
        return alreadyHave.length;
      }
      return 0;
    };

    const total = getTotal();
    const ratear = (item) => {
      if (!total) {
        return 0;
      }
      const internationalized = prestacaoForm.values.valorTotalFrete.replaceAll(".", "").replaceAll(",", ".");
      return (getItem(item) * parseFloat(internationalized)) / total;
    }

    return checked.map((item) => ({
      ...item,
      chaves: [item.chave],
    })).reduce((prev, current) => {
      if (!prestacaoForm.values.agrupar) {
        return [...prev, current];
      }
      const alreadyHave = prev.find((item) => {
        if (item.remetente_cnpj !== current.remetente_cnpj) {
          return false;
        }
        if (item.remetente_cpf !== current.remetente_cpf) {
          return false;
        }
        if (item.remetente_ie !== current.remetente_ie) {
          return false;
        }
        if (item.destinatario_cnpj !== current.destinatario_cnpj) {
          return false;
        }
        if (item.destinatario_cpf !== current.destinatario_cpf) {
          return false;
        }
        if (item.destinatario_ie !== current.destinatario_ie) {
          return false;
        }
        return true;
      });
      if (!alreadyHave) {
        return [...prev, current];
      }

      const newItem = {
        ...alreadyHave,
        peso: parseFloat(alreadyHave.peso) + parseFloat(current.peso),
        valor: parseFloat(alreadyHave.valor) + parseFloat(current.valor),
        volumes: parseFloat(alreadyHave.volumes) + parseFloat(current.volumes),
        chaves: [...alreadyHave.chaves, ...current.chaves],
      };
            
      return [...prev.filter((item) => item.id !== newItem.id), newItem];
    }, []).map((item, index) => {
      let frete = ratear(item);

      let embutir = 0;
      if (prestacaoForm.values.embutir_icms) {
        embutir += parseFloat(prestacaoForm.values.aliquota);
      }
      if (prestacaoForm.values.embutir_impostos_federais) {
        embutir += calcularPIS();
        embutir += calcularIR();
        embutir += calcularINSS();
        embutir += calcularCSSL();
        embutir += calcularCOFINS();
      }

      if (embutir > 0) {
        frete = round(frete / (1 - (embutir / 100)), 2);
      }
      
      return {
        ...item,
        'valor_frete': frete,
        'numero_cte': parseInt(carga.transportadora.numero_cte, 10) + index,
      };
    })
  }, [prestacaoForm.values, checked, carga]);

  useEffect(() => {
    if (!prestacaoForm.values.isFracionado) {
      return;
    }
    
    const fetch = async () => {
      const dados = rateio.map((item) => {
        const origemMunicipio = item.remetente_endereco.nome_municipio;
        const destinoMunicipio = item.destinatario_endereco.nome_municipio;
        const origemUf = item.remetente_endereco.uf;
        const destinoUf = item.destinatario_endereco.uf;
        const pesoCubado = (item.largura || 0) * (item.altura || 0) * (item.comprimento || 0);

        return {
          "origem_municipio": origemMunicipio,
          "origem_uf": origemUf,
          "destino_uf": destinoUf,
          "destino_municipio": destinoMunicipio,
          'cnpj_tomador': item.modalidade_frete === 'CIF' ? item.remetente_cnpj : item.destinatario_cnpj,
          "tipo_produto": carga?.produto?.id || null,
          "tipo_veiculo": carga?.motorista_em_viagem?.veiculo?.tipo_veiculo?.id,
          "peso_carga": item.peso,
          "valor_carga": item.valor,
          "peso_carga_cubado": pesoCubado,
          'devolucao': false,
          'reentrega': false,
          'distancia': item.distancia,
        };
      });

      try {
        const { data } = await api.post(`/fracionado/valor_cte/`, dados)

        if (data && data.length > 0) {
          const [item] = data;
          const periodoInicial = item.options.prazo || 0;

          prestacaoForm.setFieldValue('dataFechamentoCte', addDays(new Date(), periodoInicial)?.toISOString().split("T")[0]);
          prestacaoForm.setFieldValue('embutir_icms', item.options.embutir_icms);
          prestacaoForm.setFieldValue('embutir_impostos_federais', item.options.embutir_impostos_federais);
        }
        const aliquotas = Array.from(new Set(data.map((item) => item.aliquota_icms)));
        if (aliquotas.length === 1) {
          prestacaoForm.setFieldValue('aliquota', String(aliquotas[0]).replace('.', ','));
        }
        const valorFrete = data.reduce((prev, cur) => {
          return prev + (
            cur.taxa_fixa +
            cur.frete_peso +
            cur.frete_valor +
            cur.sec_cat +
            cur.pedagio +
            cur.outros +
            cur.seguro +
            cur.tde +
            cur.gris +
            cur.despacho
          );
        }, 0);
        prestacaoForm.setFieldValue('valorTotalFrete', String(valorFrete).replace('.', ','));
      } catch {}
    };

    const timer = setTimeout(() => {
      fetch();
    }, 400);

    return () => {
      clearTimeout(timer);
    }
  }, [rateio, prestacaoForm, carga]);

  const gerarICMS = (item) => {
    const bc = item.valor_frete;
    const aliquota = prestacaoForm.values.aliquota
    const aliq = parseFloat(aliquota) / 100;
    const valor_icms = round(bc * aliq, 2);
    const percentual_reducao = prestacaoForm.values.percentual_reducao;
    const valor_credito = parseFloat(prestacaoForm.values.valor_credito.replaceAll(".", "").replaceAll(",", "."));

    const valor_base_calculo = parseFloat(bc).toFixed(2);

    switch (prestacaoForm.values.situacaoIcms) {
      case "00":
        return {
          icms00: { valor_base_calculo, aliquota, valor_icms },
        };
      case "20":
        return {
          icms20: { valor_base_calculo, aliquota, valor_icms, percentual_reducao },
        };
      case "60":
        return {
          icms60: { valor_base_calculo, aliquota, valor_icms, valor_credito },
        };
      case "90":
        let icms90 = { valor_base_calculo, aliquota, valor_icms, valor_credito };
        if (String(percentual_reducao) !== "0") {
          icms90 = { ...icms90, percentual_reducao, };
        }
        return { icms90 };
      default:
        return;
    }
  };

  const handleGenerateItemData = (item, emitir) => {
    return {
      emitir: emitir,
      carga: idCarga,
      coleta: item.coleta ? Number(item.coleta) : null,
      data_programada_entrega: prestacaoForm.values.dataFechamentoCte,
      natureza_operacao: prestacaoForm.values.textoCfopSelecionado,
      numero: null,
      codigo_fiscal_operacao_prestacao: prestacaoForm.values.cfopSelecionado,
      tomador: item.modalidade_frete === 'CIF' ? '0' : '3',
      tipo: "0",
      tipo_servico: "0",
      lotacao: prestacaoForm.values.isFracionado ? "Fracionado" : "Cheio",
      forma_pagamento: prestacaoForm.values.isAPagar ? "A Pagar" : "Pago",
      apolice_seguro: "",
      nome_seguradora: "",
      valor_cte_tipo: "total",
      valor_cte: parseFloat(item.valor_frete).toFixed(2),
      observacoes: prestacaoForm.values.observacoesGerais,
      codigo_municipio_inicio_prestacao: item.remetente_endereco.codigo_municipio,
      uf_inicio_prestacao: item.remetente_endereco.uf,
      codigo_municipio_fim_prestacao: item.destinatario_endereco.codigo_municipio,
      uf_fim_prestacao: item.destinatario_endereco.uf,
      remetente: {
        cpf: item.remetente_cpf || null,
        cnpj: item.remetente_cnpj || null,
        razao_social: item.remetente_razao_social || null,
        inscricao_estadual: item.remetente_ie || null,
        endereco: {
          logradouro: item.remetente_endereco.logradouro || null,
          numero: item.remetente_endereco.numero || null,
          complemento: item.remetente_endereco.complemento || null,
          bairro: item.remetente_endereco.bairro || null,
          codigo_municipio: item.remetente_endereco.codigo_municipio || null,
          nome_municipio: item.remetente_endereco.nome_municipio || null,
          cep: item.remetente_endereco.cep.replace('-', '') || null,
          uf: item.remetente_endereco.uf || null,
        }
      },
      destinatario: {
        cpf: item.destinatario_cpf || null,
        cnpj: item.destinatario_cnpj || null,
        razao_social: item.destinatario_razao_social || null,
        inscricao_estadual: item.destinatario_ie || null,
        endereco: {
          logradouro: item.destinatario_endereco.logradouro || null,
          numero: item.destinatario_endereco.numero || null,
          complemento: item.destinatario_endereco.complemento || null,
          bairro: item.destinatario_endereco.bairro || null,
          codigo_municipio: item.destinatario_endereco.codigo_municipio || null,
          nome_municipio: item.destinatario_endereco.nome_municipio || null,
          cep: item.destinatario_endereco.cep.replace('-', '') || null,
          uf: item.destinatario_endereco.uf || null,
        }
      },
      //recebedor: converterPapelParaEnvio(recebedor),
      //expedidor: converterPapelParaEnvio(expedidor),
      versao_modal: "3.00",
      prestacao: {
        valor_total: parseFloat(item.valor_frete).toFixed(2),
        valor_receber: prestacaoForm.values.isAPagar ? parseFloat(item.valor_frete).toFixed(2) : "0",
        componentes: [
          {
            nome: "VALOR TAXA FIXA",
            valor: 0,
          },
          {
            nome: "VALOR ITR",
            valor: 0,
          },
          {
            nome: "VALOR DESPACHO",
            valor: 0,
          },
          {
            nome: "VALOR ADEME",
            valor: 0,
          },
          {
            nome: "VALOR PESO",
            valor: 0,
          },
          {
            nome: "VALOR FRETE",
            valor: parseFloat(item.valor_frete).toFixed(2),
          },
          {
            nome: "VALOR SEC CAT",
            valor: 0,
          },
          {
            nome: "VALOR OUTROS",
            valor: 0,
          },
          {
            nome: "VALOR GRIS",
            valor: 0,
          },
          {
            nome: "VALOR PEDAGIO",
            valor: 0,
          },
          {
            nome: "VALOR DESCARGA",
            valor: 0,
          },
          {
            nome: "VALOR TDE",
            valor: 0,
          },
          {
            nome: "VALOR DESCONTO",
            valor: 0,
          },
        ],
      },
      informacoes_cte: {
        valor_carga: item.valor,
        produto_predominante: carga?.produto?.produto || "NAO ESPECIFICADO",
        informacoes_quantidade: [
          {
            unidade: "01",
            tipo_medida: "PESO BRUTO",
            quantidade: item.peso,
          },
          {
            unidade: "03",
            tipo_medida: "VOLUMES",
            quantidade: item.volumes || "0.00",
          },
        ],
        documentos: {
          nfes: item.chaves.map((chave) => ({
            chave: chave,
          })),
        },
        duplicatas: prestacaoForm.values.duplicatas.map((dup) => {
          const qtde = prestacaoForm.values.duplicatas.length;
          const valor = round(item.valor_frete / qtde, 2);

          return {
            numero: dup.numero,
            data_vencimento: dup.data_vencimento,
            valor: valor,
          };
        }),
      },
      motorista: carga?.motorista_em_viagem?.nome_completo,
      placa: String(carga?.motorista_em_viagem?.veiculo?.placa_cavalo).toUpperCase().replaceAll('-', ''),
      acrescimo_repasse: 0,
      acrescimo_repasse_tipo: 'total',
      embutir_icms: false,
      embutir_impostos_federais: false,
      desconsiderar_descarga: false,
      desconsiderar_pedagio: false,
      indicador_tomador: prestacaoForm.values.tomadorContribuinte,
      periodo: parseInt(prestacaoForm.values.periodo || '0', 10),
      impostos: {
        situacao: prestacaoForm.values.situacaoIcms,
        ...gerarICMS(item),
      },
    };
  }

  const handleError = (error) => {
    const { data } = error.response;
    const errors = getDisplayErrors(data);
    let erroCoins = false;

    errors.forEach((item) => {
      if (item?.title === "Rastro Coins Insuficientes.") {
        erroCoins = true;
      }
    });

    if (erroCoins) {
      erroCoins = false;

      confirmAlert({
        overlayClassName: "alert-sucesso-cte",
        customUI: ({ onClose }) => (
          <div className="react-confirm-alert-body errors-modal-body">
            <h1>Rastro Coins Insuficientes</h1>
            <div className="errors-modal-part">
              <p>
                Suas Rastro Coins acabaram. Realize a compra de mais coins!
              </p>
            </div>
            <div className="react-confirm-alert-button-group">
              <button
                className="botao-cancelar"
                onClick={() => {
                  onClose();
                  history.push("/acesso/rastro-coins");
                }}
              >
                Comprar Rastro Coins
              </button>
            </div>
          </div>
        ),
      });
    } else {
      confirmAlert({
        overlayClassName: "alert-sucesso-cte",
        customUI: ({ onClose }) => (
          <div className="react-confirm-alert-body errors-modal-body">
            <h1>Houveram Alguns Erros</h1>
            <div className="errors-modal-part">
              {errors.map((item) => (
                <div key={JSON.stringify(item)}>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
            <div className="react-confirm-alert-button-group">
              <button className="botao-cancelar" onClick={onClose}>
                Ok
              </button>
            </div>
          </div>
        ),
      });
    }
  }

  const handleSave = () => {
    const data = {
      ctes: rateio.map((item) => handleGenerateItemData(item, false)),
      transportadora: carga.transportadora.id,
      carga: carga.id,
      emitir: false,
    };
    api.post('/documentos_fiscais/emitircte/lote/', data).then(() => {
      onComplete();
    }).catch(handleError);
  };

  const handleEmitir = () => {
    const data = {
      ctes: rateio.map((item) => handleGenerateItemData(item, true)),
      transportadora: carga.transportadora.id,
      carga: carga.id,
      emitir: true,
    };
    api.post('/documentos_fiscais/emitircte/lote/', data).then(() => {
      onComplete();
    }).catch(handleError);
  };

	const handleToggleCheck = (item, state) => {
		if (state) {
			setChecked((prev) => {
				if (prev.includes(item)) {
					return prev;
				}
				return [...prev, item];
			});
			return;
		}
		setChecked((prev) => prev.filter((prevItem) => prevItem !== item));
	};

  const handleGoNext = () => {
    if (step === 0 && checked.length > 0) {
      setStep(1);
      return;
    }
  }

	return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-nova-carga modal-selecionar-notas"
      overlayClassName="overlay-modal-nova-carga"
      ariaHideApp={false}
    >
			<div className="container-nova-carga">
        <RiCloseCircleFill
          className="icone-fechar"
          onClick={onClose}
        />

        <p className="titulo">Emitir CT-e's em Lote</p>
				
        {step === 0 && (
          <Step1
            onToggleCheck={handleToggleCheck}
            nfes={nfes}
            checked={checked}
          />
        )}
        {step === 1 && (
          <Step2
            prestacaoForm={prestacaoForm}
            rateio={rateio}
            carga={carga}
          />
        )}
			</div>

      {step === 0 && (
        <div className="submit-line">
          <button
            className="btn-cancelar"
            onClick={onClose}
          >
            Cancelar
          </button>
          <button
            className="btn-salvar"
            onClick={handleGoNext}
          >
            Continuar
          </button>
        </div>
      )}
      {step === 1 && (
        <div className="submit-line">
          <button
            className="btn-cancelar"
            onClick={onClose}
          >
            Cancelar
          </button>
          <button
            className="btn-salvar"
            onClick={handleSave}
          >
            Salvar
          </button>
          <button
            className="btn-salvar"
            onClick={handleEmitir}
          >
            Enviar P/ Sefaz
          </button>
        </div>
      )}
		</Modal>
	);
}

export default ModalSelecionarNotas;
