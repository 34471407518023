import React, { memo, useState, useCallback } from "react";
import { MdClear } from "react-icons/md";
import { GiWallet } from "react-icons/gi";

import "./styles.css";

import Pagamento from "../../../Pagamento";
import QuadroQuantidades from "../QuadroQuantidades";

import api from "../../../../../../services/api";

const Calculadora = () => {
  const [modalPagamento, setModalPagamento] = useState(false);
  const [quantidadeCargasTotais, setQuantidadeCargasTotais] = useState("");
  const [quantidadeCargasComGr, setQuantidadeCargasComGr] = useState("");
  const [quantidadeMediaPesquisas, setQuantidadeMediaPesquisas] = useState("");
  const [quantidadeDocumentosFiscais, setQuantidadeDocumentosFiscais] =
    useState("");
  const [totalSugeridoCoins, setTotalSugeridoCoins] = useState("");
  const [quantidadeMeses, setQuantidadeMeses] = useState(1);
  const [totalPagar, setTotalPagar] = useState("");

  const opcoesQuantidadeMeses = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const handleModalPagamento = useCallback(() => {
    if (modalPagamento) {
      setModalPagamento(false);
      document.querySelector("body").style.overflow = "visible";
    } else {
      setModalPagamento(true);
      document.querySelector("body").style.overflow = "hidden";
    }
  }, [modalPagamento]);

  const composeTotalPagar = () => {
    return `R$ ${totalPagar}`;
  };

  const calculaTotalAPagar = useCallback(
    (totalSugeridoCoins) => {
      let dados = {
        coins: totalSugeridoCoins * quantidadeMeses,
      };

      api
        .post("pagamentos/coins/preco/", dados)
        .then(function (response) {
          setTotalPagar(response.data.price);
        })
        .catch(function (e) {
          console.log(e.response.data);
        });
    },
    [quantidadeMeses]
  );

  const calculaQuantidadeCoins = useCallback(() => {
    let dados = {
      documentos: quantidadeDocumentosFiscais || 0,
      viagens: quantidadeCargasTotais || 0,
      viagens_com_gr: quantidadeCargasComGr || 0,
      pesquisas: quantidadeMediaPesquisas || 0,
    };

    api
      .post("pagamentos/coins/quantidade/", dados)
      .then(function (response) {
        setTotalSugeridoCoins(response.data.coins);
        calculaTotalAPagar(response.data.coins);
      })
      .catch(function (e) {
        console.log(e.response.data);
      });
  }, [
    quantidadeDocumentosFiscais,
    quantidadeCargasTotais,
    quantidadeCargasComGr,
    quantidadeMediaPesquisas,
    calculaTotalAPagar,
  ]);

  return (
    <div className="calculadora">
      <p className="subtitulo">
        Informe abaixo a quantidade de cargas, documentos e pesquisas realizadas
        no mês:
      </p>

      <div className="quadro-quatidades">
        <p>Quantidade De Cargas Totais</p>

        <div className="input-quantidade">
          <input
            type="number"
            placeholder="Digite..."
            value={quantidadeCargasTotais}
            onChange={(e) => setQuantidadeCargasTotais(e.target.value)}
            onBlur={calculaQuantidadeCoins}
          />
          <MdClear
            onClick={() => setQuantidadeCargasTotais("")}
            className="icone-limpar"
          ></MdClear>
        </div>
      </div>

      <QuadroQuantidades
        type="number"
        label="Quantidade De Cargas Com GR"
        value={quantidadeCargasComGr}
        setValue={setQuantidadeCargasComGr}
        onBlur={calculaQuantidadeCoins}
      ></QuadroQuantidades>

      <QuadroQuantidades
        type="number"
        label="Quantidade Média De Pesquisas"
        value={quantidadeMediaPesquisas}
        setValue={setQuantidadeMediaPesquisas}
        onBlur={calculaQuantidadeCoins}
      ></QuadroQuantidades>

      <QuadroQuantidades
        type="number"
        label="Quantidade De Documentos Fiscais"
        value={quantidadeDocumentosFiscais}
        setValue={setQuantidadeDocumentosFiscais}
        onBlur={calculaQuantidadeCoins}
      ></QuadroQuantidades>

      <hr className="separador" />

      <div className="quadro-total">
        <p>Total Sugerido De Rastro Coins</p>

        <div className="input-quantidade">
          <input
            type="number"
            placeholder="Valor..."
            defaultValue={totalSugeridoCoins}
            disabled
          />
        </div>
      </div>

      <p className="subtitulo">
        Compre rastro coins para mais de meses e receba um grande desconto.
        Simule abaixo:
      </p>

      <QuadroQuantidades
        type="select"
        options={opcoesQuantidadeMeses}
        label="Quantidade De Meses"
        value={quantidadeMeses}
        setValue={setQuantidadeMeses}
        onBlur={() => calculaTotalAPagar(totalSugeridoCoins)}
      ></QuadroQuantidades>

      <hr className="separador" />

      <p className="subtitulo">
        Os rastro coins podem ser utilizados durante um período de 12 meses
      </p>

      <div className="container-total-pagar">
        <div className="quadro-total pagar">
          <p>
            R$ Total A Pagar -{" "}
            {`${quantidadeMeses} ${
              Number(quantidadeMeses) === 1 ? "mês" : "meses"
            }`}
          </p>

          <div className="input-quantidade">
            <input
              type="text"
              placeholder="R$"
              value={composeTotalPagar()}
              disabled
            />
          </div>
        </div>

        <div className="desconto">
          <p>Desconto de 15%</p>
        </div>
      </div>

      <button className="btn-comprar" onClick={handleModalPagamento}>
        <div>
          <GiWallet className="icone" />
          <p>Comprar Agora</p>
        </div>
      </button>

      {modalPagamento && (
        <Pagamento
          modalPagamento={modalPagamento}
          handleModalPagamento={handleModalPagamento}
          totalSugeridoCoins={totalSugeridoCoins}
          quantidadeMeses={quantidadeMeses}
          totalPagar={totalPagar}
        ></Pagamento>
      )}
    </div>
  );
};

export default memo(Calculadora);
