import React, { memo, useState, useEffect, useCallback } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { parseISO, format } from 'date-fns'

import './styles.css'

import api from "../../../../../services/api"

const QuadroRegistros = ({ idCarga }) => {
  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
  const tipoUsuario = dadosUsuario.user_type
  const idUsuario = (tipoUsuario === "transportadora" || tipoUsuario === "colaborador_transportadora")
    ? dadosUsuario.transportadora_id
    : (tipoUsuario === "embarcador" || tipoUsuario === "colaborador_embarcador")
      ? dadosUsuario.embarcador_id
      : dadosUsuario.id

  const [registro, setRegistro] = useState('')
  const [historicoRegistros, setHistoricoRegistros] = useState([])
  const [ocorrencia, setOcorrencia] = useState(null)

  const obtemRegistros = (id) => {
    api.get(`cargas_full/${id}/`)
      .then(function (response) {
        setHistoricoRegistros(response.data.registros)
        setOcorrencia(response.data.ocorrencia)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const enviarRegistro = () => {
    api.post(`cargas_registro/`, {
      texto: registro,
      carga: idCarga
    })
      .then(function (response) {
        setRegistro('')
        setHistoricoRegistros(historico => [...historico, response.data]);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const visualizarImagem = (imagem) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="container-visualizar-imagem-registro">
            <img
              className="imagem-registro-aberta"
              src={imagem}
              alt=""
            />

            <div className="container-btn-visualizar-imagem-registro">
              <button
                className="btn-cancelar"
                onClick={onClose}
              >
                Fechar
              </button>
            </div>
          </div>
        )
      }
    })
  }

  const websocketNovosRegistros = useCallback(() => {
    let wsNovosRegistros = new WebSocket(`${process.env.REACT_APP_LINK_API_WEBSOCKET}ws/cargas/notify/${tipoUsuario === "colaborador_transportadora" ? "transportadora" : tipoUsuario === "colaborador_embarcador" ? "embarcador" : tipoUsuario}/${idUsuario}`)

    wsNovosRegistros.onopen = function () {
      console.log('WebSocket registro_atualizado  conectado')
    }

    wsNovosRegistros.onmessage = function (e) {
      let JSONMessage = JSON.parse(e.data)

      if (JSONMessage.tipo === "registro_atualizado ") {
        obtemRegistros(JSONMessage.carga.id)
      }
    }

    wsNovosRegistros.onclose = function () {
      wsNovosRegistros = null
      setTimeout(function () { websocketNovosRegistros() }, 5000)
    }
  }, [tipoUsuario, idUsuario])

  useEffect(() => {
    websocketNovosRegistros()
  }, [websocketNovosRegistros])

  useEffect(() => {
    obtemRegistros(idCarga)
  }, [idCarga])

  return (
    <div className="registro-carga">
      <div className="novo-registro">
        <p className="titulo">
          Novo Registro
        </p>

        <textarea cols="30" rows="8" value={registro} onChange={(e) => setRegistro(e.target.value)}>Escreve aqui...</textarea>

        <div className="container-btn-enviar">
          <button className="enviar-registro" onClick={enviarRegistro}>Enviar</button>
        </div>
      </div>

      <div className="historico-registros">
        <div className="quadro">
          <p className="titulo">
            Histórico de registros:
          </p>

          <div className="lista-registros">
            {historicoRegistros.length > 0
              ? historicoRegistros.map(item => (
                <p key={item.id}>{item.texto}<span className='data'>{format(parseISO(item?.data), "dd/MM/yy - HH:mm")}</span>
                  {item.imagem
                    && <><br />
                      <span
                        className='open-image'
                        onClick={() => visualizarImagem(item.imagem)}
                      >
                        Clique para abrir a imagem
                      </span>
                    </>
                  }
                </p>
              ))
              : <p>Sem registros</p>
            }
          </div>
        </div>

        <div className="quadro">
          <p className="titulo">
            Histórico de ocorrências:
          </p>

          <div className="lista-registros">
            {ocorrencia
              ? <>
                <p key={ocorrencia.id}>{ocorrencia.descricao}</p>
                <span className='data'>{format(parseISO(ocorrencia.data), "dd/MM/yyyy - HH:mm")}</span>
              </>
              : <p>Sem ocorrências</p>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(QuadroRegistros)