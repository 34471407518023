import { useMemo } from 'react';
import { Button } from '../../components/common';

export const Linha = ({ tabela, onConfirmCopy }) => {
  const tipoTabela = useMemo(() => {
    if (tabela.tipo_tabela === "PESO_KG") {
      return "Peso/KG";
    }
    if (tabela.tipo_tabela === "PESO_CUBAGEM") {
      return "Peso/Cubagem";
    }
    if (tabela.tipo_tabela === "NOTA_FISCAL") {
      return "% Nota Fiscal";
    }
    if (tabela.tipo_tabela === "COMBINADO") {
      return "Combinado";
    }
    if (tabela.tipo_tabela === "POR_VOLUME") {
      return "Por Volume";
    }
    if (tabela.tipo_tabela === "POR_PALLET") {
      return "Por Pallet";
    }
    return '';
  }, [tabela]);

  const origem = useMemo(() => {
    if (tabela.tipo_rota === "GERAL") {
      return "Tabela Global"
    }
    if (tabela.tipo_rota === "UF") {
      return tabela.origem?.uf||'';
    }
    if (tabela.origem?.municipio) {
      return `${tabela.origem?.municipio} - ${tabela.origem?.uf}`
    }
    return tabela.origem?.uf;
  }, [tabela]);

  const destino = useMemo(() => {
    if (tabela.tipo_rota === "GERAL") {
      return "Tabela Global"
    }
    if (tabela.tipo_rota === "UF") {
      return tabela.destino?.uf||'';
    }
    if (tabela.destino?.municipio) {
      return `${tabela.destino?.municipio} - ${tabela.destino?.uf}`
    }
    return tabela.destino?.uf;
  }, [tabela]);

  return (
    <div className="tabela-item">
      <div className="data">
        <div className="nomeCliente">{tabela.cliente?.nome_completo}</div>
        <div className="informations">
          <span><strong>Tipo da Tabela: </strong>{tipoTabela}</span>
          <span><strong>Origem: </strong>{origem}</span>
          <span><strong>Destino: </strong>{destino}</span>
        </div>
      </div>
      <div className="action">
        <Button variant="ghost" type="button" onClick={() => onConfirmCopy(tabela)}>Copiar</Button>
      </div>
    </div>
  );
}
