import React, { memo, useRef, useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { BsGlobe, BsFacebook, BsLinkedin } from 'react-icons/bs'
import { FaInstagramSquare } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import api from "../../../../services/api"

const NovoEmbarcador = ({ match }) => {
  const history = useHistory()
  const idEmbarcador = match.params?.idEmbarcador
  const desabilitarInputs = match.path === '/acesso/embarcador/visualizar-embarcador/:idEmbarcador' ? true : false
  const isEditar = match.path === '/acesso/embarcador/editar-embarcador/:idEmbarcador' ? true : false

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/embarcador', breadcrumb: 'Embarcador' },
    { path: '/acesso/embarcador/novo-embarcador', breadcrumb: 'Novo Embarcador' },
    { path: `/acesso/embarcador/visualizar-embarcador`, breadcrumb: null },
    { path: `/acesso/embarcador/visualizar-embarcador/${idEmbarcador}`, breadcrumb: 'Visualizar Embarcador' },
    { path: `/acesso/embarcador/editar-embarcador`, breadcrumb: null },
    { path: `/acesso/embarcador/editar-embarcador/${idEmbarcador}`, breadcrumb: 'Editar Embarcador' },
  ]

  // const [paises, setPaises] = useState([])
  const [ufs, setUfs] = useState([])
  const [municipios, setMunicipios] = useState([])

  const [pais, setPais] = useState('Brasil')
  const [cep, setCep] = useState('')
  const [uf, setUf] = useState('')
  const [municipio, setMunicipio] = useState('')
  const [bairro, setBairro] = useState('')
  const [logradouro, setLogradouro] = useState('')
  const [checkboxStatus, setCheckboxStatus] = useState(true)
  const [msgErro, setMsgErro] = useState(false)

  const razaoSocial = useRef('')
  const fotoPerfil = useRef('')
  const cnpj = useRef('')
  const inscricaoEstadual = useRef('')
  const telefoneContato = useRef('')
  const email = useRef('')
  const numero = useRef('')
  const complemento = useRef('')
  const site = useRef('')
  const instagram = useRef('')
  const facebook = useRef('')
  const linkedin = useRef('')
  const observacoes = useRef('')
  const plano = useRef('')
  const validadePlano = useRef('')

  const removerMascaraCampo = (dado) => {
    return dado.replace('(', '').replace(')', '').replace(' ', '').replace('-', '').replace('.', '').replace('/', '')
  }

  const verificaPreenchimentoCampos = () => {
    if (razaoSocial.current.value.trim() === "" || cnpj.current.value.trim() === "" || inscricaoEstadual.current.value.trim() === ""
      || telefoneContato.current.value.trim() === "" || email.current.value.trim() === "" || pais.trim() === ""
      || cep.trim() === "" || uf.trim() === "" || municipio.trim() === ""
      || logradouro.trim() === "" || bairro.trim() === ""
      || numero.current.value.trim() === "" || numero.current.value.trim() === "") {
      return setMsgErro(true)
    } else {
      return true
    }
  }

  const cadastrarEmbarcador = () => {
    if (verificaPreenchimentoCampos()) {
      const formData = new FormData()
      formData.append("imagem_perfil", fotoPerfil.current.value !== "" ? fotoPerfil.current.files[0] : "")
      formData.append("razao_social", razaoSocial.current.value)
      formData.append("cnpj", removerMascaraCampo(cnpj.current.value).replace('.', ''))
      formData.append("inscricao_estadual", inscricaoEstadual.current.value)
      formData.append("telefone", removerMascaraCampo(telefoneContato.current.value))
      formData.append("email", email.current.value)
      formData.append("endereco.pais", pais)
      formData.append("endereco.uf", uf)
      formData.append("endereco.municipio", municipio)
      formData.append("endereco.logradouro", logradouro)
      formData.append("endereco.bairro", bairro)
      formData.append("endereco.numero", numero.current.value)
      formData.append("endereco.cep", cep)
      formData.append("endereco.complemento", complemento.current.value)
      formData.append("redes_sociais.site", site.current.value)
      formData.append("redes_sociais.instagram", instagram.current.value)
      formData.append("redes_sociais.facebook", facebook.current.value)
      formData.append("redes_sociais.linkedin", linkedin.current.value)
      formData.append("observacoes", observacoes.current.value)
      formData.append("user_type", 1)
      formData.append("is_ativo", checkboxStatus)

      api.post("embarcador/", formData)
        .then(function () {
          history.push("/acesso/embarcador")
        })
        .catch(function (e) {
          console.log(e)
        })
    }
  }

  const editarEmbarcador = () => {
    if (verificaPreenchimentoCampos()) {
      const formData = new FormData()
      if (fotoPerfil.current.files.length > 0) {
        formData.append("imagem_perfil", fotoPerfil.current.value !== "" ? fotoPerfil.current.files[0] : "")
      }

      formData.append("razao_social", razaoSocial.current.value)
      formData.append("cnpj", removerMascaraCampo(cnpj.current.value).replace('.', ''))
      formData.append("inscricao_estadual", inscricaoEstadual.current.value)
      formData.append("telefone", removerMascaraCampo(telefoneContato.current.value))
      formData.append("email", email.current.value)
      formData.append("endereco.pais", pais)
      formData.append("endereco.uf", uf)
      formData.append("endereco.municipio", municipio)
      formData.append("endereco.logradouro", logradouro)
      formData.append("endereco.bairro", bairro)
      formData.append("endereco.numero", numero.current.value)
      formData.append("endereco.cep", cep)
      formData.append("endereco.complemento", complemento.current.value)
      formData.append("redes_sociais.site", site.current.value)
      formData.append("redes_sociais.instagram", instagram.current.value)
      formData.append("redes_sociais.facebook", facebook.current.value)
      formData.append("redes_sociais.linkedin", linkedin.current.value)
      formData.append("observacoes", observacoes.current.value)
      formData.append("user_type", 1)
      formData.append("is_ativo", checkboxStatus)

      api.put(`embarcador/${idEmbarcador}/`, formData)
        .then(function () {
          history.push("/acesso/embarcador")
        })
        .catch(function (e) {
          console.log(e)
        })
    }
  }

  // useEffect(() => {
  //   fetch('https://servicodados.ibge.gov.br/api/v1/paises')
  //     .then(res => {
  //       return res.json()
  //     })
  //     .then(paises => {
  //       setPaises(paises)
  //     })
  // }, [])

  useEffect(() => {
    if (cep.length === 9) {
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((response) => {
          response.json()
            .then((data) => {
              let dadosEndereco = data

              setUf(dadosEndereco.uf)
              setMunicipio(dadosEndereco.localidade)
              setBairro(dadosEndereco.bairro)
              setLogradouro(dadosEndereco.logradouro)
            })
        })
        .catch((error) => {
          console.log("ERRO: " + error.message)
        })
    }
  }, [cep])

  useEffect(() => {
    if (pais === 'Brasil') {
      fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        .then(res => {
          return res.json()
        })
        .then(ufs => {
          setUfs(ufs)
        })
    } else {
      setUfs([])
    }
  }, [pais])

  useEffect(() => {
    fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`)
      .then(res => {
        return res.json()
      })
      .then(municipios => {
        setMunicipios(municipios)
      })
  }, [uf])

  useEffect(() => {
    if (idEmbarcador) {
      api.get(`embarcador/${idEmbarcador}/`)
        .then(function (response) {
          let dadosEmbarcador = response.data

          razaoSocial.current.value = dadosEmbarcador.razao_social
          cnpj.current.value = dadosEmbarcador.cnpj
          inscricaoEstadual.current.value = dadosEmbarcador.inscricao_estadual
          telefoneContato.current.value = dadosEmbarcador.telefone
          email.current.value = dadosEmbarcador.email
          setPais(dadosEmbarcador.endereco.pais)
          setCep(dadosEmbarcador.endereco.cep)
          setUf(dadosEmbarcador.endereco.uf)
          setMunicipio(dadosEmbarcador.endereco.municipio)
          setLogradouro(dadosEmbarcador.endereco.logradouro)
          setBairro(dadosEmbarcador.endereco.bairro)
          numero.current.value = dadosEmbarcador.endereco.numero
          complemento.current.value = dadosEmbarcador.endereco.complemento
          site.current.value = dadosEmbarcador.redes_sociais.site
          instagram.current.value = dadosEmbarcador.redes_sociais.instagram
          facebook.current.value = dadosEmbarcador.redes_sociais.facebook
          linkedin.current.value = dadosEmbarcador.redes_sociais.linkedin
          observacoes.current.value = dadosEmbarcador.observacoes
          setCheckboxStatus(dadosEmbarcador.is_ativo)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [idEmbarcador])

  return (
    <div className="container-novo-embarcador">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="novo-embarcador">
        <p className="titulo-pagina">
          {
            desabilitarInputs && !isEditar
              ? "Visualizar Embarcador"
              : isEditar
                ? "Editar Embarcador"
                : "Novo Embarcador"
          }
        </p>
        <p className="subtitulo-pagina">
          {
            desabilitarInputs && !isEditar
              ? "Dados informados no cadastro do embarcador"
              : isEditar
                ? "Por favor, informe os dados solicitados para editar o cadastro do embarcador"
                : "Por favor, informe os dados solicitados para cadastro de novo embarcador"
          }
        </p>

        <div className="categoria">
          <p className="titulo-categoria">Dados do Embarcador</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <input
                required
                type="text"
                ref={razaoSocial}
                disabled={desabilitarInputs}
              />
              <span>Razão Social <i>*</i></span>
            </div>

            <div className="container-input file">
              <input
                placeholder="Anexar Imagem Curso"
                type="file"
                accept="image/png, image/jpeg"
                ref={fotoPerfil}
              />
              <span>Foto</span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="99.999.999/9999-99"
                ref={cnpj}
                disabled={desabilitarInputs}
              ></InputMask>
              <span>CNPJ <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={inscricaoEstadual}
                disabled={desabilitarInputs}
              />
              <span>Inscrição Estadual <i>*</i></span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="(99) 99999-9999"
                maskChar=" "
                ref={telefoneContato}
                disabled={desabilitarInputs}
              ></InputMask>
              <span>Telefone Contato <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={email}
                disabled={desabilitarInputs}
              />
              <span>E-mail <i>*</i></span>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Endereço</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <select
                required
                value={pais}
                // onChange={(e) => setPais(e.target.value)}
                disabled={true}
              >
                <option value="Brasil">Brasil</option>
                {/* {paises.map((item) => {
                  return (
                    <option key={item.id.M49} value={item.nome.abreviado}>{item.nome.abreviado}</option>
                  )
                })} */}
              </select>
              <span>País <i>*</i></span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="99999-999"
                maskChar=""
                value={cep}
                onChange={(e) => setCep(e.target.value)}
                disabled={desabilitarInputs}
              ></InputMask>
              <span>CEP <i>*</i></span>
            </div>

            {ufs.length > 0
              ? <div className="container-input">
                <select
                  required
                  value={uf}
                  onChange={(e) => setUf(e.target.value)}
                  disabled={desabilitarInputs}
                >
                  <option value="" disabled></option>
                  {ufs.map((item) => {
                    return (
                      <option key={item.id} value={item.sigla}>{item.sigla}</option>
                    )
                  })}
                </select>
                <span>UF <i>*</i></span>
              </div>
              : <div className="container-input">
                <input
                  required
                  type="text"
                  value={uf}
                  onChange={(e) => setUf(e.target.value)}
                  disabled={desabilitarInputs}
                />
                <span>UF <i>*</i></span>
              </div>
            }

            {ufs.length > 0
              ? <div className="container-input">
                <select
                  required
                  value={municipio}
                  onChange={(e) => setMunicipio(e.target.value)}
                  disabled={desabilitarInputs}
                >
                  <option value="" disabled></option>
                  {municipios.map((item) => {
                    return (
                      <option key={item.id} value={item.nome}>{item.nome}</option>
                    )
                  })}
                </select>
                <span>Município <i>*</i></span>
              </div>
              : <div className="container-input">
                <input
                  required
                  type="text"
                  value={municipio}
                  onChange={(e) => setMunicipio(e.target.value)}
                  disabled={desabilitarInputs}
                />
                <span>Município <i>*</i></span>
              </div>
            }

            <div className="container-input">
              <input
                required
                type="text"
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
                disabled={desabilitarInputs}
              />
              <span>Bairro <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                value={logradouro}
                onChange={(e) => setLogradouro(e.target.value)}
                disabled={desabilitarInputs}
              />
              <span>Logradouro <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={numero}
                maxLength={4}
                disabled={desabilitarInputs}
              />
              <span>Número <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={complemento}
                disabled={desabilitarInputs}
              />
              <span>Complemento <i>*</i></span>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Redes Sociais</p>

          <div className="container-inputs-2col">
            <div>
              <div className="container-input">
                <input
                  required
                  type="text"
                  ref={site}
                  disabled={desabilitarInputs}
                />
                <span className="input-rede-social">
                  Site
                  <BsGlobe className="icone"></BsGlobe>
                </span>
              </div>

              <div className="container-input">
                <input
                  required
                  type="text"
                  ref={instagram}
                  disabled={desabilitarInputs}
                />
                <span className="input-rede-social">
                  Instagram
                  <FaInstagramSquare className="icone instagram"></FaInstagramSquare>
                </span>
              </div>
            </div>

            <div>
              <div className="container-input">
                <input
                  required
                  type="text"
                  ref={facebook}
                  disabled={desabilitarInputs}
                />
                <span className="input-rede-social">
                  Facebook
                  <BsFacebook className="icone facebook"></BsFacebook>
                </span>
              </div>

              <div className="container-input">
                <input
                  required
                  type="text"
                  ref={linkedin}
                  disabled={desabilitarInputs}
                />
                <span className="input-rede-social">
                  Linkedin
                  <BsLinkedin className="icone linkedin"></BsLinkedin>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Observações</p>

          <div className="container-inputs-1col-expandida">
            <div className="container-input">
              <textarea
                required
                rows={6}
                type="text"
                ref={observacoes}
                disabled={desabilitarInputs}
              />
              <span>Observações do embarcador</span>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Plano do Embarcador</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <select
                required
                ref={plano}
                defaultValue={''}
                disabled={desabilitarInputs}
              >
                <option value="" disabled></option>
              </select>
              <span>Selecione o Plano <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="date"
                ref={validadePlano}
                disabled={desabilitarInputs}
              />
              <span>Validade do Plano <i>*</i></span>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Status Embarcador</p>

          <div className="container-inputs-1col">
            <div className="container-input-checkbox">
              <span className="checkbox" onClick={desabilitarInputs ? () => { } : () => setCheckboxStatus(!checkboxStatus)} >
                <input
                  type="checkbox"
                  checked={checkboxStatus}
                  onChange={() => setCheckboxStatus(!checkboxStatus)}
                  disabled={desabilitarInputs}
                />
                <span className="checked"></span>
                Ativo
              </span>
            </div>
          </div>
        </div>

        {
          !desabilitarInputs &&
          <div className="container-botoes">
            <button
              className="btn-cancelar"
              onClick={() => history.replace("/acesso/embarcador")}
            >
              Cancelar
            </button>

            <button
              className="btn-cadastrar"
              onClick={isEditar ? editarEmbarcador : cadastrarEmbarcador}
            >
              {isEditar ? "Salvar Embarcador" : "Cadastrar Embarcador"}
            </button>
          </div>
        }

        {msgErro && <p className="msg-erro">Todos os campos com * devem ser preenchidos!</p>}
      </div>
    </div>
  )
}

export default memo(NovoEmbarcador)