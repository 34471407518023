import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useColetasPaginated } from '../../core/queries';
import { Tabela } from '../../components/layout';
import { Pagination } from '../../components/layout';
import { HStack } from '../../components/utils';
import { API_CLIENT_PAGE_SIZE } from '../../core/config';
import { ModalSelecionarColetaRow } from './modal-selecionar-coleta-row';

export const ModalSelecionarColetaTabela = ({
  cliente,
  ufRemetente,
  ufDestinatario,
  municipioRemetente,
  municipioDestinatario,
  dataInicial,
  dataFinal,
}) => {
  const [page, setPage] = useState(1);
  const { data } = useColetasPaginated(
    page,
    cliente,
    ufRemetente,
    ufDestinatario,
    municipioRemetente,
    municipioDestinatario,
    dataInicial,
    dataFinal,
    true
  );
  const { setValues } = useFormikContext();

  useEffect(() => {
    setPage(1);
  }, [
    cliente,
    ufRemetente,
    ufDestinatario,
    municipioRemetente,
    municipioDestinatario,
    dataInicial,
    dataFinal,
    setPage,
  ]);

  useEffect(() => {
    if (!data?.results) {
      return;
    }

    const values = data.results.reduce((prev, item) => ({
      ...prev,
      [item.id]: {
        active: false,
        id: item.id,
        status: item.status,
        nfe: item.nfe,
        cte: item.cte?.numero,
        cliente: item.nfe.modalidade_frete === 'CIF' ? item.nfe.remetente_razao_social : item.nfe.destinatario_razao_social,
        destino: `${item.nfe.destinatario_endereco.nome_municipio} - ${item.nfe.destinatario_endereco.uf}`,
      },
    }), {});
    setValues(values);
  }, [data, setValues]);

  return (
    <>
      <Tabela>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>ID</th>
            <th>Status</th>
            <th>NF-e</th>
            <th>CT-e</th>
            <th>Data Solicitação</th>
            <th>Cliente</th>
            <th>Origem</th>
            <th>Destino</th>
          </tr>
        </thead>

        <tbody>
          {data?.results?.map((item) => (
            <ModalSelecionarColetaRow coleta={item} key={item.id} />
          ))}
        </tbody>
      </Tabela>

      <HStack justifyContent="flex-end" padding="20px 0">
        <Pagination
          count={data?.count || 0}
          size={API_CLIENT_PAGE_SIZE}
          router={false}
          page={page}
          onChangePage={(p) => {
            setPage(p + 1);
          }}
        />
      </HStack>
    </>
  )
};
