import { useState, useRef, useEffect, useCallback } from 'react'
import { ImSearch } from 'react-icons/im'
import { FaInfoCircle } from 'react-icons/fa'

import './styles.css'
import api from '../../../../services/api'
import { LoadingAnimated } from '../../LoadingAnimated'

const InputSearchMotoristas = ({ placeholder, disabled, value, saveId, saveValue }) => {
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [motoristas, setMotoristas] = useState([])

  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [scrollTop, setScrollTop] = useState(0)

  const list = useRef(null)

  const handleSearchInputChanges = (e) => {  
    saveValue(e.target.value)
    setOffset(0)
    setShowSuggestions(true)
  }

  const onClick = (motorista) => {
    setShowSuggestions(false)
    saveValue(motorista.nome_completo)
    saveId(motorista.id)
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      onClick(motoristas[activeSuggestionIndex])
      setActiveSuggestionIndex(0)
    } else if (e.keyCode === 38) {
      if (activeSuggestionIndex === 0) {
        return
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1)
    }
    else if (e.keyCode === 40) {
      if (activeSuggestionIndex === motoristas.length -1) {
        return
      }

      setActiveSuggestionIndex(activeSuggestionIndex + 1)
    }
  }

  const showActiveSuggestion = useCallback((index) => {
    if (index <= motoristas.length - 1) {
      const parentRect = list.current.getBoundingClientRect()
      const child = list.current.querySelectorAll('li')[index]
      const childRect = child.getBoundingClientRect()

      const yStart = (childRect.top - parentRect.top) + list.current.scrollTop

      if (yStart >= list.current.scrollTop
        && yStart < (list.current.scrollTop + parentRect.height)) {
        return
      }

      list.current.scrollTop = yStart
    }
  }, [motoristas])

  useEffect(() => {
    if (list.current) {
      showActiveSuggestion(activeSuggestionIndex)
    }
  }, [activeSuggestionIndex, showActiveSuggestion])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/motorista/?cadastro_completo=true&limit=30&offset=${offset}&search=${value}`)
        let data = response.data
        setTotal(data.count)
        setMotoristas(prevMotoristas => {
          if (offset === 0) {
            return data.results
          }
          return [...prevMotoristas, ...data.results]
        })
        setLoading(false)
      } catch (e) {
        console.log(e)
      } 
    }

    fetchData()
  }, [offset, value])


  useEffect(() => {
    if (list.current) {
      list.current.scrollTop = scrollTop
    }
  }, [motoristas, scrollTop])

  return (
    <div className="container-input-search-cidades">
      <div className="input-search">
        <input
          required
          type="text"
          onChange={handleSearchInputChanges}
          onKeyDown={onKeyDown}
          value={value}
        />
        <span>
          <FaInfoCircle className="icone-ajuda"></FaInfoCircle>
          {placeholder} <i>*</i>
        </span>
        <ImSearch className="icone"></ImSearch>
      </div>
      {showSuggestions && value &&
        <SuggestionsListComponent
          motoristas={motoristas}
          total={total}
          loading={loading}
          activeSuggestionIndex={activeSuggestionIndex}
          list={list}
          setOffset={(number) => setOffset(number)}
          setScrollTop={(number) => setScrollTop(number)}
          setLoading={(number) => setLoading(number)}
          onClick={onClick}
          offset={offset}
        />}
    </div>
  )
}

const SuggestionsListComponent = ({
  motoristas, total, activeSuggestionIndex, list, loading, setScrollTop, setOffset, onClick, offset, setLoading
}) => {

  const handleScroll = event => {
    const {
      target: { scrollTop, scrollHeight, clientHeight }
    } = event
    if (scrollTop + clientHeight >= scrollHeight && motoristas.length < total) {
      setScrollTop(scrollTop)
      setLoading(true)
      setOffset(offset + 30)
    }
  }

  return motoristas.length ? (
    <ul onScroll={handleScroll} className="suggestions" ref={list}>
      {motoristas.map((suggestion, index) => {
        let className = ""

        if (index === activeSuggestionIndex) {
          className = "suggestion-active"
        }

        return (
          <li
            className={className}
            key={suggestion.id}
            onClick={() => onClick(suggestion)}
          >
            <span className='suggestion-name'>{suggestion.id} - {suggestion.nome_completo} -</span>
            <span>{suggestion.veiculo.placa_cavalo}</span>
          </li>
        )
      })}
      {loading && <LoadingAnimated styles={{ display: "block", margin: "auto" }} />}
    </ul>
  ) : (
    <div className="no-suggestions">
      <em>Nenhum motorista encontrado!</em>
    </div>
  )
}

export default InputSearchMotoristas