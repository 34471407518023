import React, { useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { Checkbox } from '../../components/forms';

export const ModalSelecionarColetaRow = ({ coleta }) => {
  const status = useMemo(() => {
    if (coleta.status === 'COLETA') {
      return 'COLETA';
    }
    if (coleta.status === 'PROCESSADA') {
      return 'PROCESSADA';
    }
    if (coleta.status === 'EM_ENTREGA') {
      return 'EM ENTREGA';
    }
    if (coleta.status === 'ENTREGUE') {
      return 'ENTREGUE';
    }
    if (coleta.status === 'EM_COLETA') {
      return 'EM COLETA';
    }
    if (coleta.status === 'COLETADA') {
      return 'COLETADA';
    }
    return '';
  }, [coleta]);

  return (
    <>
      <tr className="divisoria-linhas-tabela"></tr>
      <tr>
        <td>
          <Checkbox name={`${coleta.id}.active`} small />
        </td>
        <td>{coleta.id}</td>
        <td>{status}</td>
        <td>{coleta.nfe.numero}</td>
        <td>{coleta.cte?.numero || '-'}</td>
        <td>{format(parseISO(coleta.data_solicitacao), 'dd/MM/yyyy')}</td>
        <td>{coleta.cliente}</td>
        <td>{coleta.nfe.remetente_endereco.nome_municipio} - {coleta.nfe.remetente_endereco.uf}</td>
        <td>{coleta.nfe.destinatario_endereco.nome_municipio} - {coleta.nfe.destinatario_endereco.uf}</td>
      </tr>
    </>
  )
};
