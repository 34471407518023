import { useState, useMemo } from 'react'
import InputMask from 'react-input-mask'

const cleanDigits = (document) => {
  return document
    .replaceAll('.', '')
    .replaceAll('_', '')
    .replaceAll('-', '')
    .replaceAll('/', '')
    .trim();
};

const InputCPFouCNPJ = ({ value, ...rest }) => {
  const [fixedMask, setFixedMask] = useState(null);

  const handleDocumentFocus = () => {
    setFixedMask(null);
  };

  const handleDocumentBlur = () => {
    const digits = cleanDigits(String(value));
    console.log(digits);
    if (digits.length <= 11) {
      return setFixedMask('999.999.999-99');
    }
    return setFixedMask('99.999.999/9999-99');
  };

  const mask = useMemo(() => {
    const digits = cleanDigits(String(value));
    if (digits.length < 11) {
      return '999.999.999-99';
    }
    return '99.999.999/9999-99';
  }, [value]);

  return (
    <InputMask 
      mask={fixedMask || mask}
      value={value}
      onBlur={handleDocumentBlur}
      onFocus={handleDocumentFocus}
      {...rest}
    />
  );
}

export default InputCPFouCNPJ;
