import './dashboard-page.scss';

export const DashboardPage = ({ children }) => {
  return (
    <div className="dashboard-page">
      <div className="dashboard-inner">
        {children}
      </div>
    </div>
  )
}
