import React, { memo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Loader from 'react-loader-spinner'

import { useAuth } from '../../../context/Auth'

import './styles.css'

const Loading = ({ location }) => {
  const { setIsAutenticado } = useAuth()
  const history = useHistory()

  useEffect(() => {
    const isAutenticado = async () => {
      const tokenLocalStorage = localStorage.getItem("@RASTRO:TOKEN")

      if (tokenLocalStorage) {
        setIsAutenticado(true)
        return history.replace(location?.state?.pathname || "/acesso/painel-monitoramento")
      }

      return history.replace("/")
    }

    return isAutenticado()
  }, [history, setIsAutenticado, location])


  return (
    <div className="container-loading-login">
      <h1>Carregando...</h1>

      <Loader
        type="TailSpin"
        color="#fff"
        height={150}
        width={150}
      />
    </div>
  )
}

export default memo(Loading)