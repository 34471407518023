import { useMemo } from "react";
import { useFormikContext } from "formik"
import { MdAssignmentAdd } from 'react-icons/md';
import { HStack } from "../../../../components/utils"
import { Button } from "../../../../components/common"

export const ColetasActions = ({ onCreate }) => {
  const { values, handleSubmit } = useFormikContext()

  const isBaixarDisabled = useMemo(() => {
    const keys = Object.keys(values);
    if (!keys.length) {
      return true;
    }
    const filtered = keys.filter((item) => values[item]);
    return !filtered.length;
  }, [values]);

  return (
    <HStack>
      <Button
        onClick={() => handleSubmit()}
        variant="save"
        type="button"
        disabled={isBaixarDisabled}
      >
        Baixar Coletas
      </Button>
      <Button onClick={onCreate} variant="save" type="button">
        <MdAssignmentAdd size={22} />
        Criar Coleta
      </Button>
    </HStack>
  )
}
