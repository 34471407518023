import React, { memo, useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ImSearch } from 'react-icons/im'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import TabelaListarTransportadoras from '../../../../components/plataformaAcessada/TabelaListarTransportadoras'
import api from "../../../../services/api"

const TransportadorasFavoritas = () => {
  const history = useHistory()

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/transportadora', breadcrumb: 'Transportadora' },
    { path: '/acesso/transportadora/transportadoras-favoritas', breadcrumb: 'Transportadoras Favoritas' },
  ]

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const [searchTransportadora, setSearchTransportadora] = useState('')
  const [transportadoras, setTransportadoras] = useState([])

  const requisitarTransportadoras = useCallback(() => {
    api.get(`transportadorafavorita/?embarcador=${dadosUsuario.embarcador_id}`)
      .then(function (response) {
        let auxListaTransportadoras = []

        response.data.results.forEach((item) => {
          auxListaTransportadoras.push(item.transportadora)
        })

        setTransportadoras(auxListaTransportadoras)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [dadosUsuario.embarcador_id])

  const filtrarItens = () => {
    setTransportadoras([])

    api.get(`transportadorafavorita/?search=${searchTransportadora}`)
      .then(function (response) {
        let listaTransportadoras = response.data.results
        let auxListaTransportadoras = []

        listaTransportadoras.forEach((item) => {
          if (item.embarcador === dadosUsuario.embarcador_id) {
            auxListaTransportadoras.push(item.transportadora)
          }
        })

        setTransportadoras(auxListaTransportadoras)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const verificarTeclaSearch = (e) => {
    if (e.keyCode === 13) {
      filtrarItens()
    }
  }

  useEffect(() => {
    if (searchTransportadora === '') {
      forceUpdate()
      requisitarTransportadoras()
    }
  }, [requisitarTransportadoras, forceUpdate, searchTransportadora])

  return (
    <div className="container-transportadoras-favoritas">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="transportadoras-favoritas">
        <div className="container-topo">
          <div>
            <p className="titulo-pagina">Transportadoras Favoritas</p>
            <p className="subtitulo-pagina">Realize o filtro para busca da transportadora desejada</p>
          </div>
        </div>

        <div className="container-search">
          <div className="input-search transpotadora">
            <input
              type="text"
              placeholder="Buscar Transportadora (Razão Social; CNPJ)"
              value={searchTransportadora}
              onChange={(e) => setSearchTransportadora(e.target.value)}
              onKeyDown={verificarTeclaSearch}
            />
            <ImSearch className="icone"></ImSearch>
          </div>

          <button className="btn-buscar" onClick={filtrarItens}>Buscar</button>
        </div>

        <TabelaListarTransportadoras transportadoras={transportadoras} isPublica={true}></TabelaListarTransportadoras>
      </div>
    </div>
  )
}

export default memo(TransportadorasFavoritas)