import React from "react";

import "./styles.css";

import pix from "../../../../../../assets/pix.png";
import cartao from "../../../../../../assets/credit-card.png";

const EscolherFormaPagamento = ({
  handleModalPagamento,
  nextStep,
  formaPagamento,
  setFormaPagamento,
  totalSugeridoCoins,
  quantidadeMeses,
  totalPagar,
}) => {
  return (
    <div className="escolher-forma-pagamento">
      <p className="titulo">Compre sua Rastro Coins</p>
      <p className="subtitulo">Descrição Da Compra</p>

      <div className="quadro-total-pagamento">
        <p>Total Rastro Coins</p>

        <div className="input-quantidade">
          <input
            type="number"
            placeholder="Total..."
            value={totalSugeridoCoins}
            disabled
          />
        </div>
      </div>

      <div className="quadro-total-pagamento valor">
        <p>
          R$ Total A Pagar -{" "}
          {`${quantidadeMeses} ${
            Number(quantidadeMeses) === 1 ? "mês" : "meses"
          }`}
        </p>

        <div className="input-quantidade">
          <input
            type="text"
            placeholder="Total..."
            value={`R$ ${totalPagar}`}
            disabled
          />
        </div>
      </div>

      <hr className="separador" />

      <p className="subtitulo">Selecione a forma de pagamento</p>

      <div className="quadro-forma-pagamento">
        <div className="container-input-checkbox">
          <span className="checkbox" onClick={() => setFormaPagamento("PIX")}>
            <input
              type="checkbox"
              checked={formaPagamento === "PIX"}
              onChange={() => setFormaPagamento("PIX")}
            />
            <span className="checked"></span>
          </span>
        </div>

        <p>PIX</p>

        <img src={pix} alt="" />
      </div>

      <div className="quadro-forma-pagamento">
        <div className="container-input-checkbox">
          <span
            className="checkbox"
            onClick={() => setFormaPagamento("cartao")}
          >
            <input
              type="checkbox"
              checked={formaPagamento === "cartao"}
              onChange={() => setFormaPagamento("cartao")}
            />
            <span className="checked"></span>
          </span>
        </div>

        <p>Cartão De Crédito</p>

        <img src={cartao} alt="" />
      </div>

      <div className="container-botoes">
        <button onClick={handleModalPagamento}>Voltar</button>
        <button className="avancar" onClick={nextStep}>
          Próximo
        </button>
      </div>
    </div>
  );
};

export default EscolherFormaPagamento;
