import { useState, useCallback, useEffect, useContext } from "react";
import api from "../../../../../services/api";
import { formatarData, buildWithQueryParams } from './utils';

const { createContext } = require("react");

const CardsContext = createContext({});

const LIMIT_API = 10;

export const CardsProvider = ({
  children,
  filtroCarga,
  filtroTransportadora,
  filtroEmbarque,
  filtroDescarga,
  filtroTipoCarga,
  filtroOrigem,
  filtroDestino,
  filtroMotorista,
  checkboxTodos,
  checkboxPrazo,
  checkboxPendencia,
  checkboxOcorrencia,
  checkboxAtrasado,
}) => {
  const [novas, setNovas] = useState({
    cargas: [],
    offset: 0,
    total: 0,
    loading: false,
    painel: {},
    filtroCarga: '',
  });
  const [embarque, setEmbarque] = useState({
    cargas: [],
    offset: 0,
    total: 0,
    loading: false,
    painel: {},
    filtroCarga: '',
  });
  const [transito, setTransito] = useState({
    cargas: [],
    offset: 0,
    total: 0,
    loading: false,
    painel: {},
    filtroCarga: '',
  });
  const [entregue, setEntregue] = useState({
    cargas: [],
    offset: 0,
    total: 0,
    loading: false,
    painel: {},
    filtroCarga: '',
  });

  const updateCarga = async (handle, id) => {
    try {
      const { data } = await api.get(`/cargas/${id}/`);
      handle((prev) => ({
        ...prev,
        cargas: prev.cargas.map((item) => {
          if (item.id === data.id) {
            return data;
          }
          return item;
        }),
      }));
    } catch {}
  }

  const fetchEntregues = async (offset) => {
    let status_temporal = '';
    if (!checkboxTodos) {
      status_temporal = checkboxPrazo || checkboxAtrasado;
      if (!status_temporal) {
        if (entregue.filtroCarga === "no_prazo") {
          status_temporal = "no-prazo"
        } else if (entregue.filtroCarga === "atrasado") {
          status_temporal = "atraso"
        }
      }
    }
    let situacao = '';
    if (!checkboxTodos) {
      situacao = checkboxPendencia || checkboxOcorrencia;
      if (!situacao) {
        if (entregue.filtroCarga === "ocorrencia") {
          situacao = "ocorrencia"
        }
      }
    }

    const queryParams = {
      'painel': entregue.filtroCarga,
      'origem': filtroOrigem,
      'transportadora__razao_social__icontains': filtroTransportadora,
      'motorista_em_viagem__nome_completo__icontains': filtroMotorista,
      'status_temporal': status_temporal,
      'situacao': situacao,
      'data_embarque__gte': formatarData(filtroEmbarque, "gte"),
      'data_embarque__lte': formatarData(filtroEmbarque, "lte"),
      'data_descarga__gte': formatarData(filtroDescarga, "gte"),
      'data_descarga__lte': formatarData(filtroDescarga, "lte"),
      'id__icontains': filtroCarga,
      'destino': filtroDestino,
      'produto__produto__unaccent__icontains': filtroTipoCarga,
      'limit': LIMIT_API,
      'offset': offset,
    }
    const url = buildWithQueryParams(`/painel/cargas/entregue/`, queryParams);

    setEntregue((prev) => ({ ...prev, offset: offset || 0, loading: true }));
    try {
      const { data } = await api.get(url);
      setEntregue((prev) => ({
        ...prev,
        total: data.count,
        cargas: offset === 0 ? data.results : [...prev.cargas, ...data.results],
        painel: data.painel,
        loading: false,
      }));
    } catch {
      setEntregue((prev) => ({ ...prev, loading: false }));
    }
  }

  const fetchTransito = async (offset) => {
    const queryParams = {
      'painel': transito.filtroCarga,
      'origem': filtroOrigem,
      'transportadora__razao_social__icontains': filtroTransportadora,
      'motorista_em_viagem__nome_completo__icontains': filtroMotorista,
      'status_temporal': checkboxTodos ? '' : checkboxPrazo || checkboxAtrasado || '',
      'situacao': checkboxTodos ? '' : checkboxPendencia || checkboxOcorrencia || '',
      'data_embarque__gte': formatarData(filtroEmbarque, "gte"),
      'data_embarque__lte': formatarData(filtroEmbarque, "lte"),
      'data_descarga__gte': formatarData(filtroDescarga, "gte"),
      'data_descarga__lte': formatarData(filtroDescarga, "lte"),
      'id__icontains': filtroCarga,
      'destino': filtroDestino,
      'produto__produto__unaccent__icontains': filtroTipoCarga,
      'possivel_atraso': transito.filtroCarga === "possivel_atraso" ? true : '',
      'limit': LIMIT_API,
      'offset': offset,
    }
    const url = buildWithQueryParams(`/painel/cargas/transito/`, queryParams);

    setTransito((prev) => ({ ...prev, offset: offset || 0, loading: true }));
    try {
      const { data } = await api.get(url);
      setTransito((prev) => ({
        ...prev,
        total: data.count,
        cargas: offset === 0 ? data.results : [...prev.cargas, ...data.results],
        painel: data.painel,
        loading: false,
      }));
    } catch {
      setTransito((prev) => ({ ...prev, loading: false }));
    };
  }

  const fetchEmbarque = async (offset) => {
    let situacao = embarque.filtroCarga === "pendencia" ? "pendencia" : "";
    if (!checkboxTodos) {
      situacao = checkboxPendencia || checkboxOcorrencia || '';
    }

    let queryParams = {
      'painel': embarque.filtroCarga,
      'origem': filtroOrigem,
      'transportadora__razao_social__icontains': filtroTransportadora,
      'status_temporal': checkboxTodos ? '' : (checkboxPrazo || checkboxAtrasado || ''),
      'situacao': situacao,
      'data_embarque__gte': formatarData(filtroEmbarque, "gte"),
      'data_embarque__lte': formatarData(filtroEmbarque, "lte"),
      'data_descarga__gte': formatarData(filtroDescarga, "gte"),
      'data_descarga__lte': formatarData(filtroDescarga, "lte"),
      'id__icontains': filtroCarga,
      'motorista_em_viagem__nome_completo__icontains': filtroMotorista,
      'destino': filtroDestino,
      'produto__produto__unaccent__icontains': filtroTipoCarga,
      'checkpoints__chegada_local_embarque': embarque.filtroCarga === "portaria" ? true : "",
      'checkpoints__entrada_carregamento': embarque.filtroCarga === "portaria" ? false : "",
      'limit': LIMIT_API,
      'offset': offset,
    }
    if (embarque.filtroCarga === "carregando") {
      queryParams = {
        ...queryParams,
        'checkpoints__entrada_carregamento': true,
        'checkpoints__carregamento_finalizado': false,
      };
    }
    const url = buildWithQueryParams('painel/cargas/embarque/', queryParams);
    
    setEmbarque((prev) => ({ ...prev, offset: offset || 0, loading: true }));
    try {
      const { data } = await api.get(url);
      setEmbarque((prev) => ({
        ...prev,
        total: data.count,
        cargas: offset === 0 ? data.results : [...prev.cargas, ...data.results],
        painel: data.painel,
        loading: false,
      }));
    } catch {
      setEmbarque((prev) => ({ ...prev, loading: false }));
    };
  }

  const fetchNovasCargas = async (offset) => {
    const queryParams = {
      'painel': novas.filtroCarga,
      'status_temporal': checkboxTodos ? '' : (checkboxPrazo || checkboxAtrasado),
      'situacao': checkboxTodos ? '' : (checkboxPendencia || checkboxOcorrencia || ''),
      'origem': filtroOrigem,
      'transportadora__razao_social__icontains': filtroTransportadora,
      'data_embarque__gte': formatarData(filtroEmbarque, "gte"),
      'data_embarque__lte': formatarData(filtroEmbarque, "lte"),
      'data_descarga__gte': formatarData(filtroDescarga, "gte"),
      'data_descarga__lte': formatarData(filtroDescarga, "lte"),
      'id__icontains': filtroCarga,
      'destino': filtroDestino,
      'produto__produto__unaccent__icontains': filtroTipoCarga,
      'limit': LIMIT_API,
      'offset': offset || 0,
    }
    const url = buildWithQueryParams('painel/cargas/novas/', queryParams);
    
    setNovas((prev) => ({ ...prev, offset: offset || 0, loading: true }));
    try {
      const { data } = await api.get(url);
      setNovas((prev) => ({
        ...prev,
        total: data.count,
        cargas: offset === 0 ? data.results : [...prev.cargas, ...data.results],
        painel: data.painel,
        loading: false,
      }));
    } catch {
      setNovas((prev) => ({ ...prev, loading: false }));
    };
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setNovas((prev) => ({...prev, offset: 0, cargas: [], total: 0,}));
      fetchNovasCargas(0);
    }, 500);

    return () => {
      clearTimeout(timer);
    }
  }, [
    novas.filtroCarga,
    filtroCarga,
    filtroTransportadora,
    filtroEmbarque,
    filtroDescarga,
    filtroTipoCarga,
    filtroMotorista,
    filtroOrigem,
    filtroDestino,
    checkboxTodos,
    checkboxPrazo,
    checkboxPendencia,
    checkboxOcorrencia,
    checkboxAtrasado,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setEmbarque((prev) => ({...prev, offset: 0, cargas: [], total: 0,}));
      fetchEmbarque(0);
    }, 500);

    return () => {
      clearTimeout(timer);
    }
  }, [
    embarque.filtroCarga,
    filtroCarga,
    filtroTransportadora,
    filtroEmbarque,
    filtroDescarga,
    filtroTipoCarga,
    filtroMotorista,
    filtroOrigem,
    filtroDestino,
    checkboxTodos,
    checkboxPrazo,
    checkboxPendencia,
    checkboxOcorrencia,
    checkboxAtrasado,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTransito((prev) => ({...prev, offset: 0, cargas: [], total: 0,}));
      fetchTransito(0);
    }, 500);

    return () => {
      clearTimeout(timer);
    }
  }, [
    transito.filtroCarga,
    filtroCarga,
    filtroTransportadora,
    filtroEmbarque,
    filtroDescarga,
    filtroTipoCarga,
    filtroMotorista,
    filtroOrigem,
    filtroDestino,
    checkboxTodos,
    checkboxPrazo,
    checkboxPendencia,
    checkboxOcorrencia,
    checkboxAtrasado,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setEntregue((prev) => ({...prev, offset: 0, cargas: [], total: 0,}));
      fetchEntregues(0);
    }, 500);

    return () => {
      clearTimeout(timer);
    }
  }, [
    entregue.filtroCarga,
    filtroCarga,
    filtroTransportadora,
    filtroEmbarque,
    filtroDescarga,
    filtroTipoCarga,
    filtroMotorista,
    filtroOrigem,
    filtroDestino,
    checkboxTodos,
    checkboxPrazo,
    checkboxPendencia,
    checkboxOcorrencia,
    checkboxAtrasado,
  ]);

  return (
    <CardsContext.Provider
        value={{
          novas,
          setNovas,
          embarque,
          setEmbarque,
          transito,
          setTransito,
          entregue,
          setEntregue,
          fetchNovasCargas,
          fetchEmbarque,
          fetchTransito,
          fetchEntregues,
          updateCarga,
        }}
    >
      {children}
    </CardsContext.Provider>
  );
};

export const useCards = () => useContext(CardsContext);
