import React, { memo, useRef, useState, useEffect } from 'react'
import InputMask from 'react-input-mask'

import { useHistory } from 'react-router-dom'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import api from "../../../../services/api"

const NovoDestinatario = ({ match }) => {
  const history = useHistory()
  const idDestinatario = match.params?.idDestinatario
  const desabilitarInputs = match.path === '/acesso/destinatario/visualizar-destinatario/:idDestinatario' ? true : false
  const isEditar = match.path === '/acesso/destinatario/editar-destinatario/:idDestinatario' ? true : false

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
  const tipoUsuario = dadosUsuario.user_type

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/destinatario', breadcrumb: 'Destinatário' },
    { path: '/acesso/destinatario/novo-destinatario', breadcrumb: 'Novo Destinatário' },
    { path: `/acesso/destinatario/visualizar-destinatario`, breadcrumb: null },
    { path: `/acesso/destinatario/visualizar-destinatario/${idDestinatario}`, breadcrumb: 'Visualizar Destinatário' },
    { path: `/acesso/destinatario/editar-destinatario`, breadcrumb: null },
    { path: `/acesso/destinatario/editar-destinatario/${idDestinatario}`, breadcrumb: 'Editar Destinatário' },
  ]

  const [telefoneContato, setTelefoneContato] = useState('')
  const [ufs, setUfs] = useState([])
  const [municipios, setMunicipios] = useState([])
  const [pais, setPais] = useState('Brasil')
  const [cep, setCep] = useState('')
  const [uf, setUf] = useState('')
  const [codigoUf, setCodigoUf] = useState('')
  const [municipio, setMunicipio] = useState('')
  const [codigoMunicipio, setCodigoMunicipio] = useState('')
  const [bairro, setBairro] = useState('')
  const [logradouro, setLogradouro] = useState('')
  const numero = useRef('')
  const complemento = useRef('')
  const [checkboxStatus, setCheckboxStatus] = useState(true)
  const [msgErro, setMsgErro] = useState(false)


  const nome = useRef('')
  const empresa = useRef('')
  const email = useRef('')

  const removerMascaraCampo = (dado) => {
    return dado.replace('(', '').replace(')', '').replace(' ', '').replace('-', '').replace('.', '').replace('/', '')
  }

  const verificaPreenchimentoCampos = (dados) => {
    if (dados.empresa?.trim() === "" || dados.endereco.cep?.trim() === "" || dados.endereco.uf?.trim() === ""
      || dados.endereco.municipio?.trim() === "" || dados.endereco.bairro?.trim() === ""
      || dados.endereco.logradouro?.trim() === "") {
      return setMsgErro(true)
    } else {
      return true
    }
  }

  const cadastrarDestinatario = () => {
    let dados = {
      nome_completo: nome.current.value,
      empresa: empresa.current.value,
      telefone: removerMascaraCampo(telefoneContato),
      email: email.current.value,
      is_ativo: true,
      transportadora: (tipoUsuario === "transportadora" || tipoUsuario === "colaborador_transportadora")
        ? dadosUsuario.transportadora_id
        : null,
      embarcador: (tipoUsuario === "embarcador" || tipoUsuario === "colaborador_embarcador")
        ? dadosUsuario.embarcador_id
        : null,
      endereco: {
        pais: pais,
        uf: uf,
        uf_codigo: codigoUf,
        municipio: municipio,
        municipio_codigo: codigoMunicipio,
        logradouro: logradouro,
        bairro: bairro,
        numero: numero.current.value,
        cep: removerMascaraCampo(cep),
        complemento: complemento.current.value
      }
    }

    if (verificaPreenchimentoCampos(dados)) {
      api.post("destinatario/", dados)
        .then(function () {
          history.push("/acesso/destinatario")
        })
        .catch(function (e) {
          console.log(e)
        })
    }
  }

  const editarDestinatario = () => {
    let dados = {
      nome_completo: nome.current.value,
      empresa: empresa.current.value,
      telefone: removerMascaraCampo(telefoneContato),
      email: email.current.value,
      is_ativo: checkboxStatus,
      endereco: {
        pais: pais,
        uf: uf,
        uf_codigo: codigoUf,
        municipio: municipio,
        municipio_codigo: codigoMunicipio,
        logradouro: logradouro,
        bairro: bairro,
        numero: numero.current.value,
        cep: removerMascaraCampo(cep),
        complemento: complemento.current.value
      }
    }

    if (verificaPreenchimentoCampos(dados)) {
      api.put(`destinatario/${idDestinatario}/`, dados)
        .then(function () {
          history.push("/acesso/destinatario")
        })
        .catch(function (e) {
          console.log(e.response)
        })
    }
  }

  useEffect(() => {
    if (cep.length === 9) {
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((response) => {
          response.json()
            .then((data) => {
              let dadosEndereco = data
              let dadosUF = ufs.find(listUf => listUf.sigla === dadosEndereco.uf)

              setUf(dadosEndereco.uf)
              setCodigoUf(dadosUF?.id)
              setMunicipio(dadosEndereco.localidade)
              setCodigoMunicipio(dadosEndereco.ibge)
              setBairro(dadosEndereco.bairro)
              setLogradouro(dadosEndereco.logradouro)
            })
        })
        .catch((error) => {
          console.log("ERRO: " + error.message)
        })
    }
  }, [cep, ufs])

  useEffect(() => {
    if (pais === 'Brasil') {
      fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        .then(res => {
          return res.json()
        })
        .then(ufs => {
          setUfs(ufs)
        })
    } else {
      setUfs([])
    }
  }, [pais])

  useEffect(() => {
    fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`)
      .then(res => {
        return res.json()
      })
      .then(municipios => {
        setMunicipios(municipios)
      })
  }, [uf])

  useEffect(() => {
    if (idDestinatario) {
      api.get(`destinatario/${idDestinatario}/`)
        .then(function (response) {
          let dadosDestinatario = response.data

          nome.current.value = dadosDestinatario.nome_completo
          empresa.current.value = dadosDestinatario.empresa
          setTelefoneContato(dadosDestinatario.telefone)
          email.current.value = dadosDestinatario.email
          setPais(dadosDestinatario.endereco.pais)
          setCep(dadosDestinatario.endereco.cep)
          setUf(dadosDestinatario.endereco.uf)
          setCodigoUf(dadosDestinatario.endereco.uf_codigo)
          setMunicipio(dadosDestinatario.endereco.municipio)
          setCodigoMunicipio(dadosDestinatario.endereco.municipio_codigo)
          setLogradouro(dadosDestinatario.endereco.logradouro)
          setBairro(dadosDestinatario.endereco.bairro)
          numero.current.value = dadosDestinatario.endereco.numero
          complemento.current.value = dadosDestinatario.endereco.complemento
          setCheckboxStatus(dadosDestinatario.is_ativo)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [idDestinatario])

  return (
    <div className="container-novo-destinatario">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="novo-destinatario">
        <p className="titulo-pagina">
          {
            desabilitarInputs && !isEditar
              ? "Visualizar Destinatário"
              : isEditar
                ? "Editar Destinatário"
                : "Novo Destinatário"
          }
        </p>
        <p className="subtitulo-pagina">
          {
            desabilitarInputs && !isEditar
              ? "Dados informados no cadastro do destinatário"
              : isEditar
                ? "Por favor, informe os dados solicitados para editar o cadastro do destinatário"
                : "Por favor, informe os dados solicitados para cadastro de novo destinatário"
          }
        </p>

        <div className="categoria">
          <p className="titulo-categoria">Dados do Destinatário</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <input
                required
                type="text"
                ref={nome}
                disabled={desabilitarInputs}
              />
              <span>Nome</span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={empresa}
                disabled={desabilitarInputs}
              />
              <span>Empresa <i>*</i></span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="(99) 99999-9999"
                maskChar=" "
                value={telefoneContato}
                onChange={(e) => setTelefoneContato(e.target.value)}
                disabled={desabilitarInputs}
              ></InputMask>
              <span>Telefone Contato</span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={email}
                disabled={desabilitarInputs}
              />
              <span>E-mail</span>
            </div>

            <div className="container-input">
              <select
                required
                value={pais}
                disabled={true}
              >
                <option value="Brasil" disabled>Brasil</option>
              </select>
              <span>País <i>*</i></span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="99999-999"
                maskChar=""
                value={cep}
                onChange={(e) => setCep(e.target.value)}
                disabled={desabilitarInputs}
              ></InputMask>
              <span>CEP <i>*</i></span>
            </div>

            <div className="container-input">
              <select
                required
                value={uf}
                onChange={(e) => {
                  setUf(e.target.value);
                  setCodigoUf(e.target.selectedOptions[0].getAttribute('data-codigo'))
                }}
                disabled={desabilitarInputs}
              >
                <option value="" disabled></option>
                {ufs.map((item) => {
                  return (
                    <option key={item.id} value={item.sigla} data-codigo={item.id}>{item.sigla}</option>
                  )
                })}
              </select>
              <span>UF <i>*</i></span>
            </div>

            <div className="container-input">
              <select
                required
                value={municipio}
                onChange={(e) => {
                  setMunicipio(e.target.value);
                  setCodigoMunicipio(e.target.selectedOptions[0].getAttribute('data-codigo'))
                }}
                disabled={desabilitarInputs}
              >
                <option value="" disabled></option>
                {municipios.map((item) => {
                  return (
                    <option key={item.id} value={item.nome} data-codigo={item.id}>{item.nome}</option>
                  )
                })}
              </select>
              <span>Município <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
                disabled={desabilitarInputs}
              />
              <span>Bairro <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                value={logradouro}
                onChange={(e) => setLogradouro(e.target.value)}
                disabled={desabilitarInputs}
              />
              <span>Logradouro <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={numero}
                maxLength={4}
                disabled={desabilitarInputs}
              />
              <span>Número</span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={complemento}
                disabled={desabilitarInputs}
              />
              <span>Complemento</span>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Status Destinatário</p>

          <div className="container-inputs-1col">
            <div className="container-input-checkbox">
              <span className="checkbox" onClick={desabilitarInputs ? () => { } : () => setCheckboxStatus(!checkboxStatus)} >
                <input
                  type="checkbox"
                  checked={checkboxStatus}
                  onChange={() => setCheckboxStatus(!checkboxStatus)}
                  disabled={desabilitarInputs}
                />
                <span className="checked"></span>
                Ativo
              </span>
            </div>
          </div>
        </div>

        {
          !desabilitarInputs &&
          <div className="container-botoes">
            <button
              className="btn-cancelar"
              onClick={() => history.push("/acesso/destinatario")}
            >
              Cancelar
            </button>

            <button
              className="btn-cadastrar"
              onClick={isEditar ? editarDestinatario : cadastrarDestinatario}
            >
              {isEditar ? "Salvar Destinatário" : "Cadastrar Destinatário"}
            </button>
          </div>
        }

        {msgErro && <p className="msg-erro">Todos os campos com * devem ser preenchidos!</p>}
      </div>
    </div>
  )
}

export default memo(NovoDestinatario)