import { useQuery } from 'react-query';
import api from '../../../services/api'

const getClienTableById = async (id) => {
  let path = `/fracionado/tabelas/${id}/`;
  const { data } = await api.get(path);
  return data;
}

export const useClientsTableById = (id) => {
  return useQuery(['clients-table-by-id', id], async () => {
    if (!id) {
      return null;
    }
    return getClienTableById(id);
  }, {
    cacheTime: 0,
    retry: false,
    refetchInterval: 0,
    refetchIntervalInBackground: 0,
  });
};
