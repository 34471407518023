import React from 'react';
import { useField, useFormikContext } from 'formik';
import clsx from 'clsx';
import NumberFormat from 'react-number-format'
import './input-valor-peso.scss';

export const InputValorPeso = ({
  label,
  small,
  white,
  borderless,
  helpText,
  tipoField,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { getFieldMeta, setFieldValue } = useFormikContext();

  const tipoMeta = getFieldMeta(tipoField);

  return (
    <div className="form-input-container">
      <div
        className={clsx({
          'form-input-peso': true,
          'small': small,
          'no-label': !label,
          'disabled': props.disabled,
          'white': !!white,
          'borderless': !!borderless,
        })}
      >
        <select
          value={tipoMeta.value}
          onChange={(e) => setFieldValue(tipoField, e.target.value)}
        >
          <option value='tonelada'>{label} TN</option>
          <option value='total'>{label} Total</option>
        </select>

        <NumberFormat
          displayType={'number'}
          thousandSeparator="."
          decimalSeparator=","
          {...props}
          {...field}
        />
      </div>
      {meta.touched && meta.error && (
        <span className="form-input-error">{meta.error}</span>
      )}
      {helpText && !(meta.touched && meta.error) && (
        <span className="form-input-helper">{helpText}</span>
      )}
    </div>
  );
}
