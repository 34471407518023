import React from 'react';
import { useHistory } from 'react-router-dom'
import { Pagination } from '../../../../components/layout/pagination';
import { NoItemsBox } from '../../../../components/layout/no-items-box';
import { API_CLIENT_PAGE_SIZE } from '../../../../core/config';
import { useClientsPaginated } from '../../../../core/queries';
import { ClienteRow } from './ClienteRow';

export const ClienteTable = () => {
  const history = useHistory();
  const { data } = useClientsPaginated();

  return (
    <>
      <div className="container-tabela-listar-destinatarios">
        <table>
          <thead>
            <tr>
              <th>CNPJ</th>
              <th>Nome do Cliente</th>
              <th>Ações</th>
              <th>Tabelas</th>
            </tr>
          </thead>

          <tbody>
            {data?.results?.map((item) => (
              <ClienteRow cliente={item} key={item.id} />
            ))}

            {!data?.results.length && (
              <tr>
                <td colSpan={4}>
                  <NoItemsBox
                    text="Não existem clientes cadastrados!"
                    onCreateClick={() => history.push("/acesso/clientes/novo")}
                    createButton
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="container-paginacao-destinatario">
        <Pagination
          count={data?.count || 0}
          size={API_CLIENT_PAGE_SIZE}
        />
      </div>
    </>
  );
};
