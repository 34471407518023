import React from 'react';
import './button.scss';

export const Button = ({
  className: rawClassName,
  variant,
  isLoading = false,
  loadingText = 'Carregando...',
  small = false,
  children,
  disabled,
  ...props
}) => {
  let className = `${rawClassName} btn`;
  if (variant === 'smallAction') {
    className = `${className} smallAction`;
  }
  if (variant === 'ghost') {
    className = `${className} ghost`;
  }
  if (variant === 'save') {
    className = `${className} save`;
  }
  if (variant === 'saveBack') {
    className = `${className} saveBack`;
  }
  if (variant === 'detail') {
    className = `${className} detail`;
  }
  if (small) {
    className = `${className} small`;
  }

  return (
    <button className={className} disabled={disabled || isLoading} {...props}>
      {isLoading ? <>{loadingText}</> : <>{children}</>}
    </button>
  )
};
