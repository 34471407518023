import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { MdClear } from 'react-icons/md';
import './input-filtro.scss';

export const InputFiltro = ({
  icon,
  placeholder,
  type,
  parameter,
  value: rawValue,
  setValue: rawSetValue,
}) => {
  const { search, pathname, hash } = useLocation();
  const history = useHistory();
  const [value, setValue] = useState(() => {
    if (!parameter) {
      return rawValue;
    }
    const searchParameters = new URLSearchParams(search);
    if (!searchParameters.get(parameter)) {
      return '';
    }
    return searchParameters.get(parameter);
  });
  const [settedType, setSettedType] = useState('text');

  const handleUpdate = (text) => {
    setValue(text);
  }

  const handleUpdateFilter = useCallback((value) => {
    if (!parameter) {
      return;
    }
    const searchParameters = new URLSearchParams(search);
    searchParameters.set(parameter, value);

    history.push({
      pathname,
      search: searchParameters.toString(),
      hash,
    });
  }, [history, search, pathname, hash, parameter]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      handleUpdateFilter(value);
      
      if (!parameter) {
        rawSetValue(value);
      }
    }, 500);

    return () => {
      clearTimeout(debounce);
    }
  }, [value, rawSetValue, parameter]);

  const customType = useMemo(() => {
    if (!type) {
      return 'text';
    }
    if (type === 'date') {
      if (settedType) {
        return settedType;
      }
      return 'date';
    }
    return type;
  }, [type, settedType]);

  const handleBlur = (e) => {
    if (!type || type !== 'date') {
      return;
    }
    if (e.target.value === '') {
      setSettedType('text');
      return;
    }
    setSettedType('date');
  };

  const handleFocus = () => {
    if (!type || type !== 'date') {
      return;
    }
    setSettedType('date');
  }

  const handleClear = () => {
    handleUpdate('');
    setSettedType('text');
  }
  
  return (
    <div className="input-filtro">
      {icon}
      <input
        type={customType}
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleUpdate(e.target.value)}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <MdClear onClick={handleClear} className="icone-limpar" />
    </div>
  );
};
