import { useRouteMatch } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { TabelaPeso } from './TabelaPeso';
import { TabelaValorCombinado } from './TabelaValorCombinado';
import {
  FormSectionTitle,
  Input,
  Checkbox,
  FourCols,
  TwoCols,
  Select,
} from '../../../../../../components/forms';

const TabelaTiposFrete = () => {
  const match = useRouteMatch();
  const isReadOnly = match.path === '/acesso/clientes/tabelas/visualizar/:id' ? true : false;
  const { values } = useFormikContext();

  return(
    <>
      <FormSectionTitle>Tipos de Frete</FormSectionTitle>

      <FourCols>
        <Select
          name="tipo_tabela"
          label="Tipo de Tabela"
          options={[
            {
              label: "Peso/KG",
              value: "PESO_KG",
            },
            {
              label: "Peso/Cubagem",
              value: "PESO_CUBAGEM",
            },
            {
              label: "% Nota Fiscal",
              value: "NOTA_FISCAL",
            },
            {
              label: "Combinado",
              value: "COMBINADO",
            },
            {
              label: "Por Volume",
              value: "POR_VOLUME",
            },
          ]}
        />
      </FourCols>

      {['PESO_KG', 'PESO_CUBAGEM'].includes(values.tipo_tabela) && (
        <>
          <FormSectionTitle small>Peso/Kg</FormSectionTitle>
          
          <div className="row mb">
            <Checkbox
              name="cubagem.considerar_peso_cubagem"
              disabled={isReadOnly}
              label="Considerar Peso/Cubagem caso o peso da mercadoria seja inferior ao peso cubado"
            />
          </div>

          <TabelaPeso />

          <FormSectionTitle small>Peso/Cubagem</FormSectionTitle>

          <FourCols>
            <Input
              disabled={isReadOnly}
              name="cubagem.base"
              type="number"
              label="Base"
              helpText="Exemplo: Volumes * Comprimento(m) * Largura * Altura(m) * base = Peso Cubado"
            />
          </FourCols>
        </>
      )}

      {values.tipo_tabela === 'POR_VOLUME' && (
        <>
          <FormSectionTitle small>Quantidade de Volumes</FormSectionTitle>

          <FourCols>
            <Input
              disabled={isReadOnly}
              name="volumes.valor"
              type="number"
              label="Valor"
            />
          </FourCols>
        </>
      )}

      {values.tipo_tabela === 'NOTA_FISCAL' && (
        <>
          <FormSectionTitle small>Porcentagem Nota Fiscal (Ou CT-e para Redespacho)</FormSectionTitle>

          <FourCols>
            <Input
              name="nota_fiscal.porcentagem"
              type="number"
              disabled={isReadOnly}
              label="Porcentagem (%)"
            />

            <Input
              name="nota_fiscal.valor_limite"
              type="number"
              disabled={isReadOnly}
              label="Não aplicar % caso o valor não passe de"
            />

            <Input
              name="nota_fiscal.valor_por_minimo"
              type="number"
              disabled={isReadOnly}
              label="E cobrar o valor de"
            />
          </FourCols>
          {/*
          <TwoCols>
            <Checkbox
              name="nota_fiscal.considerar_valor_maior_peso"
              label="Considerar o valor de frete maior entre Peso/kg e % Nota Fiscal"
              disabled={isReadOnly}
            />
          </TwoCols>
          */}
        </>
      )}

      {values.tipo_tabela === 'POR_PALLET' && (
        <>
          <FormSectionTitle small>Quantidade de Pallets</FormSectionTitle>

          <FourCols>
            <Input
              name="pallets.valor"
              type="number"
              disabled={isReadOnly}
              label="Valor"
            />
          </FourCols>
        </>
      )}

      {values.tipo_tabela === 'COMBINADO' && (
        <>
          <FormSectionTitle small>Valor combinado</FormSectionTitle>

          <TwoCols>
            <TabelaValorCombinado />
          </TwoCols>
        </>
      )}
    </>
  )
}

export default TabelaTiposFrete;