import React, { memo } from 'react'

import './styles.css'

import logo from "../../assets/logo.png"
import background from "../../assets/background.jpg"

import AcessarConta from './AcessarConta'
import PrimeiroAcesso from './PrimeiroAcesso'
import EsqueceuSenha from './EsqueceuSenha'

const Login = ({ match }) => {
  const path = match.path

  return (
    <div className="container-login">
      <img
        className="background"
        src={background}
        alt=""
      />

      <div className="quadro-login">
        <div className="titulo-quadro">
          <img src={logo} alt="" />
        </div>

        <div className="formulario-login">
          {path === "/redefinir-senha"
            ? <EsqueceuSenha></EsqueceuSenha>
            : path === "/primeiro-acesso"
              ? <PrimeiroAcesso></PrimeiroAcesso>
              : <AcessarConta></AcessarConta>
          }
        </div>
      </div>
    </div>
  )
}

export default memo(Login)