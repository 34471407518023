export const removeFieldMask = (value) => {
  return value
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll(' ', '')
    .replaceAll('-', '')
    .replaceAll('.', '')
    .replaceAll('/', '')
    .replaceAll('\\', '');
}
