import React, { memo, useState, useCallback } from 'react'

import './styles.css'

import entrega from "../../../../assets/entrega_finalizada.jpg"

import ModalAvaliacaoMotorista from '../ModalAvaliacaoMotorista'

import api from '../../../../services/api'

const NotificacaoEntregaFinalizada = ({ carga, closeToast, idNotificacao }) => {
  const [modalAvaliacaoMotorista, setModalAvaliacaoMotorista] = useState(false)

  const registrarNotificacaoVisualizada = useCallback(async () => {
    await api.patch(`empresa_notificacoes/${idNotificacao}/`, {
      visualizado: true
    })
      .then(function () {
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idNotificacao])

  const handleModalAvaliacaoMotorista = useCallback(() => {
    if (modalAvaliacaoMotorista) {
      closeToast()
      setModalAvaliacaoMotorista(false)
      document.querySelector("body").style.overflow = "visible"
    } else {
      registrarNotificacaoVisualizada()
      setModalAvaliacaoMotorista(true)
      document.querySelector("body").style.overflow = "hidden"
    }
  }, [modalAvaliacaoMotorista, closeToast, registrarNotificacaoVisualizada])

  return (
    <div className="notificacao-entrega-finalizada">
      <img src={entrega} alt="" />

      <div className="conteudo">
        <p>
          Motorista {carga.motorista_em_viagem.nome_completo} finalizou a entrega com a carga {carga.id}, do destino
          {carga.pontos_parada.map((item, index) => {
            return (
              index !== 0
                ? ` | ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
                : ` ${item.destino.endereco.municipio}-${item.destino.endereco.uf}`
            )
          })},
          {carga.situacao === "ocorrencia" ? " com ocorrência" : " sem ocorrência"}.
        </p>

        <button onClick={handleModalAvaliacaoMotorista}>Avaliar</button>
      </div>

      {modalAvaliacaoMotorista &&
        <ModalAvaliacaoMotorista
          modalAvaliacaoMotorista={modalAvaliacaoMotorista}
          handleModalAvaliacaoMotorista={handleModalAvaliacaoMotorista}
          carga={carga}
        ></ModalAvaliacaoMotorista>
      }
    </div>
  )
}

export default memo(NotificacaoEntregaFinalizada)