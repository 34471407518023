import clsx from 'clsx';
import './tabela.scss';

export const Tabela = ({ className, ...props }) => {
  return (
    <div className="tabela-container">
      <table
        className={clsx({
          [className || 'tabela-rastro']: true,
          'tabela-rastro': true,
        })}
        {...props}
      />
    </div>
  );
}
