import React, { memo, useEffect, useState, useRef, useMemo } from 'react'
import { FaEye, FaTrashAlt, FaEdit, FaStar } from 'react-icons/fa'
import { FiStar } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import './styles.css'

import avatar from "../../../assets/avatar.png"
import api from "../../../services/api"
import { useCallback } from 'react'

const TabelaListarMotoristas = ({ motoristas, isPublico, requisitarMotoristas }) => {
  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))

  const [grupos, setGrupos] = useState([])

  const grupoSelecionado = useRef("")

  const removerMotorista = (nome, id) => {
    confirmAlert({
      title: "Remover Motorista",
      message: `Deseja realmente remover o motorista "${nome}" do sistema?`,
      overlayClassName: "alert-remover-motorista",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            api.delete(`motoristas/all/${id}/`)
              .then(function () {
                requisitarMotoristas()
              })
              .catch(function (error) {
              })
          },
          className: "botao-confirmar"
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar"
        }
      ]
    })
  }

  const adicionarMotoristaAoGrupo = useCallback(
    async (idMotorista, onClose) => {
      try {
        const grupo = grupoSelecionado.current.value
        await api.post("motoristagrupo/", {
          grupo,
          motorista: idMotorista
        });
        onClose()
      } catch (error) {
        console.error(error)
      }
    },
    []
  )

  const LinhaTabela = ({ item }) => {
    const [idFavorito, setIdFavorito] = useState(item?.favorito?.id)
    const [verMotivoCadastroMotorista, setVerMotivoCadastroMotorista] = useState(false)

    const motivoRef = useRef(null);

    const gerenciarFavorito = async (idMotorista) => {
      try {
        let response

        if (idFavorito) {
          response = await api.delete(`motoristafavorito/${idFavorito}/`)
          setIdFavorito(null)
        } else {
          response = await api.post(`motoristafavorito/`, {
            transportadora: dadosUsuario.transportadora_id,
            motorista: idMotorista,
          })
          setIdFavorito(response.data.id)
        }

        if (grupos.length > 0) {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='container-alert-adicionar-motorista'>
                  <p className="titulo">
                    Deseja adicionar o motorista a um grupo?
                  </p>

                  <div className="container-input">
                    <select
                      required
                      ref={grupoSelecionado}
                    >
                      <option value="" disabled></option>
                      {grupos.map((item) => (
                        <option key={item.id} value={item.id}>{item.descricao}</option>
                      ))}
                    </select>
                    <span>Selecione o grupo desejado</span>
                  </div>

                  <div className="botoes">
                    <button className="cancelar" onClick={onClose}>Não</button>
                    <button
                      className="confirmar"
                      onClick={() => adicionarMotoristaAoGrupo(idMotorista, onClose)}
                    >Adicionar
                    </button>
                  </div>
                </div>
              )
            }
          })
        }
      } catch (error) {
        console.log(error?.response?.data)
      }
    }

    const errosMensagem = useMemo(() => {

      const camposMensagem = {
        documento: 'Documento',
        data_nasc: 'Data de Nascimento',
        mae: 'Nome da Mãe',
        nome: 'Nome',
        pai: 'Nome do Pai',
        sexo: 'Sexo',
        rg: 'RG',
        rg_uf: 'RG UF',
        cnh: 'CNH',
        cnh_uf: 'CNH UF',
        cnh_cat: 'CNH Categoria',
        cnh_first: 'CNH Primeira Habilitação',
        cnh_validate: 'CNH Validade',
        cnh_security: 'CNH Segurança',
        placa: 'Placa',
        tipo: 'Tipo',
        renavam: 'Renavam',
        uf: 'UF',
        docAntt: 'Doc Antt',
      }

      let error = '';

      if (item.erros_cadastro_completo) {
        Object.keys(item.erros_cadastro_completo).forEach((chave) => {
          const erros = item.erros_cadastro_completo[chave];
          if (Object.keys(erros).length > 0) {
            Object.keys(erros).forEach((campo) => {
              const motivoErro = erros[campo];
              error += `${camposMensagem[campo]}: ${motivoErro[0]}\n\n`;
            });
          }
        });
      }

      return error;

    }, [item]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (motivoRef.current && !motivoRef.current.contains(event.target)) {
          setVerMotivoCadastroMotorista(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [motivoRef]);

    return (
      <>
        <tr className="divisoria-linhas-tabela"></tr>
        <tr>
          <td>{item.id}</td>
          <td>
            <div className="coluna-nome-motorista">
              {item.imagem_perfil
                ? <img src={item.imagem_perfil} alt="" />
                : <img src={avatar} alt="" />
              }
              {item.nome_completo}
            </div>
          </td>
          <td>{item.veiculo?.tipo_veiculo?.tipo}</td>
          <td>{item.veiculo?.placa_cavalo}</td>
          <td>
            {verMotivoCadastroMotorista &&
              <div className="tooltip hidden" data-tooltip="tooltip-1">
                <p>
                  {
                    !(item.cadastro_completo) ? errosMensagem : ''}
                </p>
              </div>
            }
            {item.cadastro_completo ?
              <BsCheckCircleFill style={{ fill: '#52F170' }} /> :
              <BsXCircleFill style={{ fill: '#FF0000' }} />}

            {!item.cadastro_completo &&
              <p
                onClick={() => setVerMotivoCadastroMotorista(true)}
                className={'ver-motivo'}
                ref={motivoRef}
              >Ver Motivo</p>
            }
          </td>

          <td>{item.tipo_cadastro === "App" ? "App" : "Sem App"}</td>

          <td className="coluna-acoes">
            {isPublico
              ?
              <>
                {
                  idFavorito
                    ? <FaStar className="icone favorito" onClick={() => gerenciarFavorito(item.id)}></FaStar>
                    : <FiStar className="icone favorito" onClick={() => gerenciarFavorito(item.id)}></FiStar>
                }
              </>
              :
              <>
                <Link to={`/acesso/motorista/visualizar-motorista/${item.id}`}>
                  <FaEye className="icone"></FaEye>
                </Link>
                <Link to={`/acesso/motorista/editar-motorista/${item.id}`}>
                  <FaEdit className="icone"></FaEdit>
                </Link>
                <FaTrashAlt className="icone" onClick={() => removerMotorista(item.nome_completo, item.id)}></FaTrashAlt>
              </>
            }
          </td>
        </tr>
      </>
    )
  }

  useEffect(() => {
    api.get(`grupos/?transportadora=${dadosUsuario.transportadora_id}`)
      .then(function (response) {
        setGrupos(response.data.results)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [dadosUsuario.transportadora_id])

  return (
    <div className="container-tabela-listar-motoristas">
      <table>
        <thead>
          <tr>
            <th>ID </th>
            <th>Nome Motorista</th>
            <th>Tipo Veículo</th>
            <th>Placa Cavalo</th>
            <th>Cadastro Completo</th>
            <th>Tipo de Cadastro</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          {motoristas.map((item) => {
            return (
              <LinhaTabela key={item.id} item={item}></LinhaTabela>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default memo(TabelaListarMotoristas)