import React, { memo, useRef, useState } from 'react'
import InputMask from 'react-input-mask'
import { useHistory } from 'react-router-dom'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import api from "../../../../services/api"

const CadastroSimplificadoEmbarcador = () => {
  const history = useHistory()

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/embarcador', breadcrumb: 'Embarcador' },
    { path: '/acesso/embarcador/cadastro-simplificado', breadcrumb: 'Cadastro Simplificado' },
  ]

  const [checkboxStatus, setCheckboxStatus] = useState(true)
  const [msgErro, setMsgErro] = useState(false)

  const razaoSocial = useRef('')
  const cnpj = useRef('')
  const telefoneContato = useRef('')
  const email = useRef('')
  const plano = useRef('')
  const validadePlano = useRef('')

  const removerMascaraCampo = (dado) => {
    return dado.replace('(', '').replace(')', '').replace(' ', '').replace('-', '').replace('.', '').replace('/', '')
  }

  const verificaPreenchimentoCampos = (dados) => {
    if (dados.razao_social.trim() === "" || dados.cnpj.trim() === ""
      || dados.telefone.trim() === "" || dados.email.trim() === "") {
      return setMsgErro(true)
    } else {
      return true
    }
  }

  const cadastrarEmbarcador = () => {
    let dados = {
      razao_social: razaoSocial.current.value,
      cnpj: removerMascaraCampo(cnpj.current.value).replace('.', ''),
      email: email.current.value,
      telefone: removerMascaraCampo(telefoneContato.current.value),
      user_type: 1,
      is_ativo: checkboxStatus
    }

    if (verificaPreenchimentoCampos(dados)) {
      api.post("embarcador/", dados)
        .then(function () {
          history.push("/acesso/embarcador")
        })
        .catch(function (e) {
          console.log(e.response)
        })
    }
  }

  return (
    <div className="container-cadastro-simplificado-embarcador">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="novo-embarcador">
        <p className="titulo-pagina">Novo Embarcador - Cadastro Simplificado</p>
        <p className="subtitulo-pagina">Por favor, informe os dados solicitados para cadastro de novo embarcador</p>

        <div className="categoria">
          <p className="titulo-categoria">Dados do Embarcador</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <input
                required
                type="text"
                ref={razaoSocial}
              />
              <span>Razão Social <i>*</i></span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="99.999.999/9999-99"
                ref={cnpj}
              ></InputMask>
              <span>CNPJ <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={email}
              />
              <span>E-mail <i>*</i></span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="(99) 99999-9999"
                ref={telefoneContato}
              ></InputMask>
              <span>Telefone Contato <i>*</i></span>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Plano do Embarcador</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <select
                required
                ref={plano}
                defaultValue={''}
              >
                <option value="" disabled></option>
              </select>
              <span>Selecione o Plano <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="date"
                ref={validadePlano}
              />
              <span>Validade do Plano <i>*</i></span>
            </div>
          </div>
        </div>


        <div className="categoria">
          <p className="titulo-categoria">Status Embarcador</p>

          <div className="container-inputs-1col">
            <div className="container-input-checkbox">
              <span className="checkbox" onClick={() => setCheckboxStatus(!checkboxStatus)}>
                <input
                  type="checkbox"
                  checked={checkboxStatus}
                  onChange={() => setCheckboxStatus(!checkboxStatus)}
                />
                <span className="checked"></span>
                Ativo
              </span>
            </div>
          </div>
        </div>

        <div className="container-botoes">
          <button
            className="btn-cancelar"
            onClick={() => history.replace("/acesso/embarcador")}
          >
            Cancelar
          </button>

          <button
            className="btn-cadastrar"
            onClick={cadastrarEmbarcador}
          >
            Cadastrar Embarcador
          </button>
        </div>

        {msgErro && <p className="msg-erro">Todos os campos com * devem ser preenchidos!</p>}
      </div>
    </div>
  )
}

export default memo(CadastroSimplificadoEmbarcador)