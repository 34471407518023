import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useFormikContext, useField } from 'formik';
import Select from 'react-select';
import { getMunicipios } from '../../../utils/address';
import '../input/input.scss';
import '../select/select.scss';

export const SelectMunicipio = ({
  label,
  municipioCodeField,
  ufField,
  small,
  ...props
}) => {
  const [rawField, meta, helpers] = useField(props);
  const { getFieldMeta, setFieldValue } = useFormikContext();
  const [municipios, setMunicipios] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState('');

  const ufMeta = getFieldMeta(ufField);

  let value = null;
  const currentMunicipio = municipios.find((item) => item.nome === rawField.value);
  if (currentMunicipio) {
    value = { label: currentMunicipio.nome, value: currentMunicipio.nome };
  }

  useEffect(() => {
    const fetch = async () => {
      if (!ufMeta.value) {
        return;
      }
      try {
        const data = await getMunicipios(ufMeta.value);
        setMunicipios(data);
      } catch {}
    }

    fetch();
  }, [ufMeta.value]);

  const field = {
    ...rawField,
    onChange: (e) => {
      const { value } = e;
      if (municipioCodeField) {
        const municipio = municipios.find((item) => item.nome === value);
        if (municipio) {
          setFieldValue(municipioCodeField, municipio.id);
        }
      }

      helpers.setValue(value);
    },
    value,
  }

  return (
    <div className="form-input-container">
      <div
        className={clsx({
          'form-select': true,
          'small': small,
          'with-value': !!field.value,
          'with-search': !!searchInputValue,
        })}
      >
        <Select
          options={municipios.map((item) => ({
            label: item.nome,
            value: item.nome,
          }))}
          placeholder=""
          classNamePrefix="form_select"
          isDisabled={props.disabled || props.isDisabled}
          onInputChange={setSearchInputValue}
          debounceTimeout={400}
          menuPosition="fixed"
          {...field}
          {...props}
        />
        {label && (<span>{label} {props.required && <i>*</i>}</span>)}
      </div>
      {meta.touched && meta.error && (
        <span className="form-input-error">{meta.error}</span>
      )}
    </div>
  );
}
