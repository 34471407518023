import React, { useState } from 'react';
import clsx from 'clsx';
import { useFormikContext, useField } from 'formik';
import Select from 'react-select';
import { BrazillianStates } from '../../../utils/address';
import '../input/input.scss';
import '../select/select.scss';

export const SelectUF = ({
  label,
  ufCodeField,
  municipioField,
  municipioCodeField,
  small,
  ...props
}) => {
  const [rawField, meta, helpers] = useField(props);
  const { setFieldValue } = useFormikContext();
  const [searchInputValue, setSearchInputValue] = useState('');

  let value = null;
  const currentUf = BrazillianStates.find((item) => item.sigla === rawField.value);
  if (currentUf) {
    value = { label: currentUf.sigla, value: currentUf.sigla };
  }

  const field = {
    ...rawField,
    onChange: (e) => {
      const { value } = e;
      if (ufCodeField) {
        const uf = BrazillianStates.find((item) => item.sigla === value);
        if (uf) {
          setFieldValue(ufCodeField, uf.id);
        }
      }

      if (municipioField) {
        setFieldValue(municipioField, '');
      }
      if (municipioCodeField) {
        setFieldValue(municipioCodeField, '');
      }
      helpers.setValue(value)
    },
    value,
  }

  return (
    <div className="form-input-container">
      <div
        className={clsx({
          'form-select': true,
          'small': small,
          'with-value': !!field.value,
          'with-search': !!searchInputValue,
        })}
      >
        <Select
          options={BrazillianStates.map((item) => ({
            label: item.sigla,
            value: item.sigla,
          }))}
          placeholder=""
          classNamePrefix="form_select"
          isDisabled={props.disabled || props.isDisabled}
          onInputChange={setSearchInputValue}
          debounceTimeout={400}
          menuPosition="fixed"
          {...field}
          {...props}
        />
        {label && (<span>{label} {props.required && <i>*</i>}</span>)}
      </div>
      {meta.touched && meta.error && (
        <span className="form-input-error">{meta.error}</span>
      )}
    </div>
  );
}
