import React from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import { Pagination } from '../../../../components/layout/pagination';
import { NoItemsBox } from '../../../../components/layout/no-items-box';
import { API_CLIENT_PAGE_SIZE } from '../../../../core/config';
import { useClientsTablesPaginated } from '../../../../core/queries';
import { TabelaRow } from './TabelaRow';

export const TabelaData = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { data } = useClientsTablesPaginated();

  const handleGoToNew = () => {
    if (!searchParams.get('search')) {
      return history.push("/acesso/clientes/tabelas/criar")
    }
    history.push({
      pathname: "/acesso/clientes/tabelas/criar",
      search: `?cliente=${searchParams.get('search')}`,
    })
  }

  return (
    <>
      <div className="container-tabela-listar-destinatarios">
        <table>
          <thead>
            <tr>
              <th>Nome do Cliente</th>
              <th>Tipo da Tabela</th>
              <th>Origem</th>
              <th>Destino</th>
              <th>Ativa</th>
              <th>Ações</th>
            </tr>
          </thead>

          <tbody>
            {data?.results?.map((item) => (
              <TabelaRow tabela={item} key={item.id} />
            ))}

            {!data?.results.length && (
              <tr>
                <td colSpan={6}>
                  <NoItemsBox
                    text="Não existem tabelas cadastradas!"
                    onCreateClick={handleGoToNew}
                    createButton
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="container-paginacao-destinatario">
        <Pagination
          count={data?.count || 0}
          size={API_CLIENT_PAGE_SIZE}
        />
      </div>
    </>
  );
};
