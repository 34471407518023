import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'
import { FaUserPlus, FaEye, FaTrashAlt, FaEdit } from 'react-icons/fa'
import { ImSearch } from 'react-icons/im'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import './styles.css'

import BreadCrumbs from '../../../../../components/plataformaAcessada/BreadCrumbs'

const GerenciarPermissoes = () => {
  const history = useHistory()

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/colaborador', breadcrumb: 'Colaborador' },
    { path: '/acesso/colaborador/permissoes', breadcrumb: 'Permissões' },
  ]

  const grupoPermissoes = [
    {
      id: 1,
      nomeGrupoPermissoes: "Nome Grupo Teste 1"
    },
    {
      id: 2,
      nomeGrupoPermissoes: "Nome Grupo Teste 2"
    }
  ]

  const removerGrupoPermissoes = (nome) => {
    confirmAlert({
      title: "Remover Grupo De Permissões",
      message: `Deseja realmente remover o grupo de permissões "${nome}" do sistema?`,
      overlayClassName: "alert-remover-grupo-permissoes",
      buttons: [
        {
          label: "Sim",
          onClick: () => { },
          className: "botao-confirmar"
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar"
        }
      ]
    })
  }

  return (
    <div className="container-gerenciar-grupo-permissoes">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="gerenciar-grupo-permissoes">
        <div className="container-topo">
          <div>
            <p className="titulo-pagina">Gerenciar Grupo De Permissões</p>
            <p className="subtitulo-pagina">Realize o filtro para busca do grupo de permissões desejado</p>
          </div>

          <button className="btn-novo-grupo-permissoes" onClick={() => history.push("/acesso/colaborador/permissoes/novo-grupo-permissoes")}>
            <FaUserPlus className="icone"></FaUserPlus>
            Novo Grupo De Permissões
          </button>
        </div>

        <div className="container-search">
          <div className="input-search">
            <input type="text" placeholder="Buscar Grupo De Permissões" />
            <ImSearch className="icone"></ImSearch>
          </div>

          <button className="btn-buscar">Buscar</button>
        </div>

        <div className="container-tabela-listar-grupos-permissoes">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome Grupo De Permissões</th>
                <th>Ações</th>
              </tr>
            </thead>

            <tbody>
              {grupoPermissoes.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr className="divisoria-linhas-tabela"></tr>
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.nomeGrupoPermissoes}</td>

                      <td className="coluna-acoes">
                        <Link to="/acesso/colaborador/permissoes/visualizar-grupo-permissoes/1">
                          <FaEye className="icone"></FaEye>
                        </Link>
                        <Link to="/acesso/colaborador/permissoes/editar-grupo-permissoes/1">
                          <FaEdit className="icone"></FaEdit>
                        </Link>
                        <FaTrashAlt className="icone" onClick={() => removerGrupoPermissoes(item.nomeGrupoPermissoes)}></FaTrashAlt>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default memo(GerenciarPermissoes)