import React, { memo, useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import InputMask from 'react-input-mask'
import { FaEdit } from 'react-icons/fa'
import { FiPlusCircle } from 'react-icons/fi'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import api from "../../../../services/api"

const NovoColaborador = ({ match }) => {
  const idColaborador = match.params?.idColaborador
  const desabilitarInputs = match.path === '/acesso/colaborador/visualizar-colaborador/:idColaborador' ? true : false
  const isEditar = match.path === '/acesso/colaborador/editar-colaborador/:idColaborador' ? true : false

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
  const tipoUsuario = dadosUsuario.user_type

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/colaborador', breadcrumb: 'Colaborador' },
    { path: '/acesso/colaborador/novo-colaborador', breadcrumb: 'Novo Colaborador' },
    { path: `/acesso/colaborador/visualizar-colaborador`, breadcrumb: null },
    { path: `/acesso/colaborador/visualizar-colaborador/${idColaborador}`, breadcrumb: 'Visualizar Colaborador' },
    { path: `/acesso/colaborador/editar-colaborador`, breadcrumb: null },
    { path: `/acesso/colaborador/editar-colaborador/${idColaborador}`, breadcrumb: 'Editar Colaborador' },
  ]

  const history = useHistory()

  const [editarGrupoPermissao, setEditarGrupoPermissao] = useState(false)
  const [checkboxStatus, setCheckboxStatus] = useState(true)
  const [telefoneContato, setTelefoneContato] = useState('')
  const [msgErro, setMsgErro] = useState(false)

  const nome = useRef('')
  const cargo = useRef('')
  const email = useRef('')
  const grupoPermissaoColaborador = useRef('')

  const adicionarGrupoPermissao = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='container-alert-adicionar'>
            <h1>Adicionar Grupo De Permissões</h1>
            <p>Digite o nome do novo grupo de permissões:</p>

            <input
              required
              placeholder="Nome Novo Grupo De Permissões"
              type="text"
              ref={grupoPermissaoColaborador}
              disabled={desabilitarInputs}
            />

            <div className="container-btn-grupo-permissoes">
              <button
                className="btn-cancelar"
                onClick={onClose}
              >
                Cancelar
              </button>
              <button
                className="btn-adicionar"
                onClick={onClose}
              >
                Adicionar
              </button>
            </div>
          </div>
        )
      }
    })
  }

  const removerMascaraCampo = (dado) => {
    return dado.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')
  }

  const verificaPreenchimentoCampos = (dados) => {
    if (dados.nome_completo.trim() === "" || dados.cargo.trim() === ""
      || dados.telefone.trim() === "" || dados.email.trim() === "") {
      return setMsgErro(true)
    } else {
      return true
    }
  }

  const cadastrarColaborador = () => {
    let dados = {
      nome_completo: nome.current.value,
      cargo: removerMascaraCampo(cargo.current.value),
      telefone: removerMascaraCampo(telefoneContato),
      email: email.current.value,
      is_ativo: checkboxStatus,
      user_type: (tipoUsuario === "transportadora" || tipoUsuario === "colaborador_transportadora")
        ? 5
        : (tipoUsuario === "embarcador" || tipoUsuario === "colaborador_embarcador")
          ? 6
          : 4,
      user: dadosUsuario.id,
      transportadora: (tipoUsuario === "transportadora" || tipoUsuario === "colaborador_transportadora")
        ? dadosUsuario.transportadora_id
        : null,
      embarcador: (tipoUsuario === "embarcador" || tipoUsuario === "colaborador_embarcador")
        ? dadosUsuario.embarcador_id
        : null
    }

    if (verificaPreenchimentoCampos(dados)) {
      api.post("colaboradores/", dados)
        .then(function () {
          history.push("/acesso/colaborador")
        })
        .catch(function () {
          confirmAlert({
            title: "Erro!",
            message: `O e-email informado já existe!`,
            overlayClassName: "alert-remover-colaborador",
            buttons: [
              {
                label: "Ok",
                className: "botao-cancelar"
              }
            ]
          })
        })
    }
  }

  const editarColaborador = () => {
    let dados = {
      nome_completo: nome.current.value,
      cargo: removerMascaraCampo(cargo.current.value),
      telefone: removerMascaraCampo(telefoneContato),
      email: email.current.value,
      is_ativo: checkboxStatus,
      user_type: 4
    }

    if (verificaPreenchimentoCampos(dados)) {
      api.put(`colaboradores/${idColaborador}/`, dados)
        .then(function () {
          history.push("/acesso/colaborador")
        })
        .catch(function (e) {
          console.log(e.response)
        })
    }
  }

  useEffect(() => {
    if (idColaborador) {
      api.get(`colaboradores/${idColaborador}/`)
        .then(function (response) {
          let dadosColaborador = response.data

          nome.current.value = dadosColaborador.nome_completo
          cargo.current.value = dadosColaborador.cargo
          setTelefoneContato(dadosColaborador.telefone)
          email.current.value = dadosColaborador.email
          setCheckboxStatus(dadosColaborador.is_ativo)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [idColaborador])

  return (
    <div className="container-novo-colaborador">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="novo-colaborador">
        <p className="titulo-pagina">
          {
            desabilitarInputs && !isEditar
              ? "Visualizar Colaborador"
              : isEditar
                ? "Editar Colaborador"
                : "Novo Colaborador"
          }
        </p>
        <p className="subtitulo-pagina">
          {
            desabilitarInputs && !isEditar
              ? "Dados informados no cadastro do colaborador"
              : isEditar
                ? "Por favor, informe os dados solicitados para editar o cadastro do colaborador"
                : "Por favor, informe os dados solicitados para cadastro de novo colaborador"
          }
        </p>

        <div className="categoria">
          <p className="titulo-categoria">Dados do Colaborador</p>

          <div className="container-inputs-2col">
            <div className="container-input">
              <input
                required
                type="text"
                ref={nome}
                disabled={desabilitarInputs}
              />
              <span>Nome Completo <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={cargo}
                disabled={desabilitarInputs}
              />
              <span>Cargo <i>*</i></span>
            </div>

            <div className="container-input">
              <InputMask
                required
                mask="(99) 99999-9999"
                value={telefoneContato}
                onChange={(e) => setTelefoneContato(e.target.value)}
                disabled={desabilitarInputs}
              ></InputMask>
              <span>Telefone Contato <i>*</i></span>
            </div>

            <div className="container-input">
              <input
                required
                type="text"
                ref={email}
                disabled={desabilitarInputs}
              />
              <span>E-mail <i>*</i></span>
            </div>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Grupo de Permissão</p>

          <div className="container-inputs-1col-combobox">
            <div className="container-combobox-select">
              <div className="container-icones">
                <FaEdit
                  className="icone"
                  onClick={desabilitarInputs ? () => { } : () => setEditarGrupoPermissao(!editarGrupoPermissao)}
                ></FaEdit>
                <FiPlusCircle
                  className="icone"
                  onClick={desabilitarInputs ? () => { } : adicionarGrupoPermissao}
                ></FiPlusCircle>
              </div>

              {!editarGrupoPermissao
                ? <div className="container-input">
                  <select
                    required
                    ref={grupoPermissaoColaborador}
                    disabled={desabilitarInputs}
                  >
                    <option value="" disabled></option>
                  </select>
                  <span>Selecione o grupo de permissão do colaborador <i>*</i></span>
                </div>
                : <div className="container-input">
                  <input
                    required
                    type="text"
                    ref={grupoPermissaoColaborador}
                    disabled={desabilitarInputs}
                  />
                  <span>Digite o grupo de permissão do colaborador <i>*</i></span>
                </div>
              }
            </div>

            <button className="btn-selecionar-select-combobox">Selecionar</button>
          </div>
        </div>

        <div className="categoria">
          <p className="titulo-categoria">Status Colaborador</p>

          <div className="container-inputs-1col">
            <div className="container-input-checkbox">
              <span className="checkbox" onClick={desabilitarInputs ? () => { } : () => setCheckboxStatus(!checkboxStatus)} >
                <input
                  type="checkbox"
                  checked={checkboxStatus}
                  onChange={() => setCheckboxStatus(!checkboxStatus)}
                  disabled={desabilitarInputs}
                />
                <span className="checked"></span>
                Ativo
              </span>
            </div>
          </div>
        </div>

        {
          !desabilitarInputs &&
          <div className="container-botoes">
            <button
              className="btn-cancelar"
              onClick={() => history.replace("/acesso/colaborador")}
            >
              Cancelar
            </button>

            <button
              className="btn-cadastrar"
              onClick={isEditar ? editarColaborador : cadastrarColaborador}
            >
              {isEditar ? "Salvar Colaborador" : "Cadastrar Colaborador"}
            </button>
          </div>
        }

        {msgErro && <p className="msg-erro">Todos os campos com * devem ser preenchidos!</p>}

      </div>
    </div>
  )
}

export default memo(NovoColaborador)