import React, { memo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import './styles.css'

import apiPrivada, { apiPublica } from "../../../services/api"

const AcessarConta = () => {
  const history = useHistory()

  const [usuario, setUsuario] = useState('')
  const [senha, setSenha] = useState('')
  const [msgErro, setMsgErro] = useState(false)

  const erroLogin = (e) => {
    let message = `Erro ao realizar login. Verifique o usuário e senha e tente novamente!`
    if (e?.response?.data?.detail) {
      message = e?.response?.data?.detail;
    }
    if (e?.response?.data?.detail === 'No active account found with the given credentials') {
      message = `Erro ao realizar login. Verifique o usuário e senha e tente novamente!`;
    }
    
    confirmAlert({
      title: "Erro!",
      message,
      overlayClassName: "alert-erro-login",
      buttons: [
        {
          label: "Ok",
          onClick: () => { },
          className: "botao-confirmar"
        }
      ]
    })
  }

  const acessarConta = (e) => {
    e.preventDefault()

    if (usuario.trim() === "" || senha.trim() === "") {
      return setMsgErro(true)
    }

    let dados = {
      username: usuario,
      password: senha
    }

    apiPublica.post("auth/token/", dados)
      .then(function (response) {
        localStorage.setItem("@RASTRO:TOKEN", response.data.access_token)
        localStorage.setItem("@RASTRO:REFRESH_TOKEN", response.data.refresh_token)

        const userData = response.data.user;
        if (userData.user_type === "embarcador") {
          apiPrivada.get(`embarcador/?user=${userData.id}`)
            .then(function () {
              let userJSON = JSON.stringify(userData)
              localStorage.setItem("@RASTRO:USER", userJSON)

              if (userData.primeiro_acesso) {
                history.push('/primeiro-acesso')
                return
              }

              history.push('/loading')
            })
            .catch(function (e) {
              if (e.response.status === 404) {
                confirmAlert({
                  title: "Erro!",
                  message: `Este perfil não está ativo no sistema!`,
                  overlayClassName: "alert-erro-login",
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => { },
                      className: "botao-confirmar"
                    }
                  ]
                })
              }
            })
        } else if (userData.user_type === "transportadora") {
          apiPrivada.get(`transportadora/?user=${userData.id}`)
            .then(function () {
              let userJSON = JSON.stringify(userData)
              localStorage.setItem("@RASTRO:USER", userJSON)

              if (userData.primeiro_acesso) {
                history.push('/primeiro-acesso')
                return
              }

              history.push('/loading')
            })
            .catch(function (e) {
              if (e.response.status === 404) {
                confirmAlert({
                  title: "Erro!",
                  message: `Este perfil não está ativo no sistema!`,
                  overlayClassName: "alert-erro-login",
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => { },
                      className: "botao-confirmar"
                    }
                  ]
                })
              }
            })
        } else if (response.data.user_type === null) {
          confirmAlert({
            title: "Erro!",
            message: `Você não pode realizar o login, pois o usuário não tem perfil associado!`,
            overlayClassName: "alert-erro-login",
            buttons: [
              {
                label: "Ok",
                onClick: () => { },
                className: "botao-confirmar"
              }
            ]
          })
        } else {
          let userJSON = JSON.stringify(userData)
          localStorage.setItem("@RASTRO:USER", userJSON)

          if (userData.primeiro_acesso) {
            history.push('/primeiro-acesso')
            return
          }

          history.push('/loading')
        }
      })
      .catch(function (e) {
        erroLogin(e)
        console.log(e)
      })
  }

  return (
    <form className="formulario-acessar-conta" onSubmit={acessarConta}>
      <div className="titulo-formulario">
        <p>Acessar Sistema</p>
      </div>

      <div className="conteudo-formulario">
        <p>Informe abaixo as suas credenciais de acesso</p>

        <label>
          Usuário:
          <input
            required
            value={usuario}
            onChange={(e) => setUsuario(e.target.value)}
          />
        </label>

        <label>
          Senha:
          <input
            required
            type="password"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />
        </label>

        <button
          type="submit"
          onClick={acessarConta}
        >
          ACESSAR
        </button>

        <Link className="link-esqueceu-senha" to="/redefinir-senha">
          Esqueceu sua senha?
        </Link>

        {msgErro && <p className="msg-erro">Todos os campos devem ser preenchidos!</p>}
      </div>
    </form>
  )
}

export default memo(AcessarConta)