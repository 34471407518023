import { useState, useMemo, useCallback } from "react";
import { AiFillFileAdd } from "react-icons/ai";
import Modal from "react-modal";
import { RiCloseCircleFill } from "react-icons/ri";
import { useEffect } from "react";
import NumberFormat from "react-number-format";
import { addDays, isValid } from "date-fns";
import InputMask from "react-input-mask";
import { confirmAlert } from "react-confirm-alert";
import FormularioNfe from "../../../ModalPerfilMotoristaCarga/Financeiro/TabelaRelatorioCargas/ModalGerarCte/FormularioNfe";
import { getDisplayErrors } from "../../../ModalPerfilMotoristaCarga/Financeiro/utilities";
import listaCFOPs from "../../../../../../utils/listaCFOPs";
import api from "../../../../../../services/api";
import {
  papelDefaults,
  converterPapelDeDadosEmissao,
  converterPapelParaEnvio,
} from "../../../ModalPerfilMotoristaCarga/Financeiro/TabelaRelatorioCargas/ModalGerarCte/utils";
import FormularioPapelCte from "../../../../../../components/formularios/FormularioPapelCte";

const ModalGerarCteAvulso = ({
  isOpen,
  onClose,
  dadosNfes,
  idCteSelecionado,
  tipoCteInicial,
  idColeta,
}) => {
  const [nfesMesmoCnpj, setNfesMesmoCnpj] = useState([]);
  const [transportadora, setTransportadora] = useState(null);

  const [quantidadeEntregas, setQuantidadeEntregas] = useState("0");
  const [isFracionado, setIsFracionado] = useState(true);
  const [tipoCte, setTipoCte] = useState("0");
  const [tipoServico, setTipoServico] = useState("0");
  const [cfopSelecionado, setCfopSelecionado] = useState("6352");
  const [textoCfopSelecionado, setTextoCfopSelecionado] = useState(
    "Prest. de serv. de trans. a estab. industria"
  );
  const [dataFechamentoCte, setDataFechamentoCte] = useState("");
  const [nfesVinculadasCte, setNfesVinculadasCte] = useState(dadosNfes || []);
  const dadosNfeSelecionada = useMemo(() => {
    return nfesVinculadasCte[0];
  }, [nfesVinculadasCte]);
  const [freteModelo, setFreteModelo] = useState(
    dadosNfeSelecionada?.modalidade_frete === "CIF" ? "0" : "3"
  );
  const [tipoFrete, setTipoFrete] = useState("Combinado");
  const [taxaFixa, setTaxaFixa] = useState("0");
  const [itr, setItr] = useState("0");
  const [despacho, setDespacho] = useState("0");
  const [adme, setAdme] = useState("0");
  const [fretePeso, setFretePeso] = useState("0");
  const [freteValor, setFreteValor] = useState("0");
  const [secCat, setSecCat] = useState("0");
  const [outros, setOutros] = useState("0");
  const [gris, setGris] = useState("0");
  const [pedagio, setPedagio] = useState("0");
  const [descarga, setDescarga] = useState("0");
  const [tde, setTde] = useState("0");
  const [desconto, setDesconto] = useState("0");
  const [baseCalculo, setBaseCalculo] = useState("0");
  const [aliquotaPorcetagem, setAliquotaPorcetagem] = useState("0");
  const [porcentagemReducaoICMS, setPorcentagemReducaoICMS] = useState("0");
  const [creditoICMS, setCreditoICMS] = useState("0");
  const [icmsBarreira, setIcmsBarreira] = useState("0");
  const [pesoTaxado, setPesoTaxado] = useState(0);
  const [situacaoIcms, setSituacaoIcms] = useState("");
  const [valorTnSelecionado, setValorTnSelecionado] = useState(
    dadosNfeSelecionada?.valor_cte_tipo || "total"
  );
  const [valorTn, setValorTn] = useState(0);
  const [isAPagar, setIsAPagar] = useState(true);

  const [quantidadeParcelas, setQuantidadeParcelas] = useState(0);
  const [periodo, setPeriodo] = useState(0);
  const [duplicatas, setDuplicatas] = useState([]);

  const [vendedor, setVendedor] = useState("");
  const [observacoesGerais, setObservacoesGerais] = useState("");
  const [nomeMotorista, setNomeMotorista] = useState("");
  const [placaVeiculo, setPlacaVeiculo] = useState("");

  const [chaveCteOriginal, setChaveCteOriginal] = useState("");
  const [chaveCteAnulacao, setChaveCteAnulacao] = useState("");
  const [tomadorContribuinte, setTomadorContribuinte] = useState("1");

  const [idSobrescrita, setIdSobrescrita] = useState(null);

  const [remetente, setRemetente] = useState({ ...papelDefaults });
  const [destinatario, setDestinatario] = useState({ ...papelDefaults });
  const [expedidor, setExpedidor] = useState(null);
  const [recebedor, setRecebedor] = useState(null);
  const [mudaTomador, setMudaTomador] = useState(false);
  const [origemPrestacaoExpedidor, setOrigemPrestacaoExpedidor] = useState(false);
  const [fimPrestacaoRecebedor, setFimPrestacaoRecebedor] = useState(false);

  const [idColetaRetificar, setIdColetaRetificar] = useState(null);

  const [produtoPredominante, setProdutoPredominante] =
    useState("NAO ESPECIFICADO");

  const [acaoBotao, setAcaoBotao] = useState(null);
  const [loadingEnvio, setLoadingEnvio] = useState(false);
  const [numero, setNumero] = useState("0");

  const [embutirICMS, setEmbutirICMS] = useState(false);
  const [embutirImpostosFederais, setEmbutirImpostosFederais] = useState(false);

  const [desconsiderarPedagio, setDesconsiderarPedagio] = useState(false);
  const [desconsiderarDescarga, setDesconsiderarDescarga] = useState(false);

  const [dadosVeiculoColeta, setDadosVeiculoColeta] = useState({
    "tipo_produto": null,
    "tipo_veiculo": null,
  });

  const [outroTomador, setOutroTomador] = useState({
    cnpj: '',
    cpf: '',
    inscricao_estadual: '',
    razao_social: '',
    endereco: {
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      codigo_municipio: '',
      nome_municipio: '',
      cep: '',
      uf: '',
    },
  });

  const [documentosAnteriores, setDocumentosAnteriores] = useState({
    cnpj: '',
    inscricao_estadual: '',
    razao_social: '',
    uf: '',
  });
  const [ctesAnteriores, setCtesAnteriores] = useState(['']);
  const showAddCteAnterior = ctesAnteriores.filter((item) => item === '').length === 0;
  
  const handleAddCteAnterior = () => {
    if (!showAddCteAnterior) {
      return;
    }
    setCtesAnteriores((prev) => [...prev, '']);
  }

  const handleInverterRemetenteDestinatario = () => {
    const novoDestinatario = { ...remetente };
    const novoRemetente = { ...destinatario };
    setRemetente(novoRemetente);
    setDestinatario(novoDestinatario);
  };

  const handleAdicionarExpedidor = () => {
    setExpedidor({ ...papelDefaults });
    setOrigemPrestacaoExpedidor(true);
  };

  const handleAdicionarRecebedor = () => {
    setRecebedor({ ...papelDefaults });
    setFimPrestacaoRecebedor(true);
  };

  const handleRemoverExpedidor = () => {
    setExpedidor(null);
    setOrigemPrestacaoExpedidor(false);
  };

  const handleRemoverRecebedor = () => {
    setRecebedor(null);
    setFimPrestacaoRecebedor(false);
  };

  const handleUpdateTipoCte = (tipo) => {
    if (tipo === "7") {
      setTaxaFixa("0");
      setItr("0");
      setDespacho("0");
      setAdme("0");
      setFretePeso("0");
      setFreteValor("1");
      setSecCat("0");
      setOutros("0");
      setGris("0");
      setPedagio("0");
      setDescarga("0");
      setTde("0");
      setDesconto("0");
      setIcmsBarreira("0");
      setPorcentagemReducaoICMS("0");
      setCreditoICMS("0");
      setQuantidadeParcelas("0");

      setValorTn("1");
      setValorTnSelecionado("total");
    }
    setTipoCte(tipo);
  };

  const valorTotalNf = useMemo(() => {
    return nfesVinculadasCte
      .map((nfe) => Number(nfe.valor))
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
  }, [nfesVinculadasCte]);

  const volumeTotalNf = useMemo(() => {
    return nfesVinculadasCte
      .map((nfe) => Number(nfe.volume))
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
  }, [nfesVinculadasCte]);

  const pesoTotalNf = useMemo(() => {
    return nfesVinculadasCte
      .map((nfe) => Number(nfe.peso))
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
  }, [nfesVinculadasCte]);

  const qtdVolumesTotalNf = useMemo(() => {
    return nfesVinculadasCte
      .map((nfe) => Number(nfe.volumes))
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
  }, [nfesVinculadasCte]);


  const calcularPIS = useCallback(() => {
    if (!transportadora?.cte_embutir_pis) {
      return 0;
    }
    const aliq = Number(transportadora?.cte_aliq_pis);
    return aliq;
  }, [transportadora]);

  const calcularIR = useCallback(() => {
    if (!transportadora?.cte_embutir_ir) {
      return 0;
    }
    const aliq = Number(transportadora?.cte_aliq_ir);
    return aliq;
  }, [transportadora]);

  const calcularINSS = useCallback(() => {
    if (!transportadora?.cte_embutir_inss) {
      return 0;
    }
    const aliq = Number(transportadora?.cte_aliq_inss);
    return aliq;
  }, [transportadora]);

  const calcularCSSL = useCallback(() => {
    if (!transportadora?.cte_embutir_cssl) {
      return 0;
    }
    const aliq = Number(transportadora?.cte_aliq_cssl);
    return aliq;
  }, [transportadora]);

  const calcularCOFINS = useCallback(() => {
    if (!transportadora?.cte_embutir_cofins) {
      return 0;
    }
    const aliq = Number(transportadora?.cte_aliq_cofins);
    return aliq;
  }, [transportadora]);

  const somaValoresPrestacao = useMemo(() => {
    let soma = (
      Number(taxaFixa.replaceAll(".", "").replaceAll(",", ".")) +
      Number(itr.replaceAll(".", "").replaceAll(",", ".")) +
      Number(despacho.replaceAll(".", "").replaceAll(",", ".")) +
      Number(adme.replaceAll(".", "").replaceAll(",", ".")) +
      Number(fretePeso.replaceAll(".", "").replaceAll(",", ".")) +
      Number(freteValor.replaceAll(".", "").replaceAll(",", ".")) +
      Number(secCat.replaceAll(".", "").replaceAll(",", ".")) +
      Number(outros.replaceAll(".", "").replaceAll(",", ".")) +
      Number(gris.replaceAll(".", "").replaceAll(",", ".")) +
      Number(pedagio.replaceAll(".", "").replaceAll(",", ".")) +
      Number(descarga.replaceAll(".", "").replaceAll(",", ".")) +
      Number(tde.replaceAll(".", "").replaceAll(",", ".")) -
      Number(desconto.replaceAll(".", "").replaceAll(",", "."))
    );
    let embutir = 0;
    if (embutirICMS) {
      embutir += Number(aliquotaPorcetagem);
    }
    if (embutirImpostosFederais) {
      embutir += calcularPIS();
      embutir += calcularIR();
      embutir += calcularINSS();
      embutir += calcularCSSL();
      embutir += calcularCOFINS();
    }
    if (embutir > 0) {
      return Number((soma / (1 - (embutir / 100))).toFixed(2));
    }
    return soma;
  }, [
    calcularPIS,
    calcularIR,
    calcularINSS,
    calcularCSSL,
    calcularCOFINS,
    aliquotaPorcetagem,
    embutirICMS,
    embutirImpostosFederais,
    taxaFixa,
    itr,
    descarga,
    despacho,
    adme,
    fretePeso,
    freteValor,
    secCat,
    outros,
    gris,
    pedagio,
    tde,
    desconto,
  ]);

  useEffect(() => {
    const fetch = async () => {
      if (!idColeta) {
        return;
      }

      const { data } = await api.get(`/fracionado/coletas/${idColeta}/`)
      if (!data?.cargas?.length) {
        return;
      }
      const carga = data?.cargas[0].carga;

      const { produto, motorista_em_viagem } = carga;
      const veiculo = motorista_em_viagem?.veiculo?.tipo_veiculo?.id;

      setDadosVeiculoColeta({
        tipo_produto: produto || null,
        tipo_veiculo: veiculo || null,
      });
    }

    fetch();
  }, [idColeta]);

  useEffect(() => {
    setValorTn(String(somaValoresPrestacao).replace(".", ","));
  }, [somaValoresPrestacao]);

  const valorIcms = useMemo(() => {
    if (String(baseCalculo) === "0") {
      return '0';
    }
    let bc = String(baseCalculo);
    console.log('BC INICIAL', bc);
    if (/^(\d+\.\d+)$/i.test(bc)) {
      bc = bc.replaceAll(".", ",");
    }
    if (!bc.includes(",")) {
      bc = `${bc},00`;
    }
    bc = Number(bc.replaceAll(".", "").replaceAll(",", "."))
    return (Number(bc) * Number(aliquotaPorcetagem / 100)).toFixed(2);
  }, [baseCalculo, aliquotaPorcetagem]);

  const pisConfinsIrCssl = useMemo(() => {
    let bc = String(baseCalculo);
    console.log('BC INICIAL', bc);
    if (/^(\d+\.\d+)$/i.test(bc)) {
      bc = bc.replaceAll(".", ",");
    }
    if (!bc.includes(",")) {
      bc = `${bc},00`;
    }
    bc = Number(bc.replaceAll(".", "").replaceAll(",", "."))

    console.log('BC', bc);
    const aliq = calcularPIS() + calcularIR() + calcularINSS() + calcularCSSL() + calcularCOFINS();
    console.log('ALIQ', aliq);
    console.log('CALC', (bc * (aliq / 100)))
    return (bc * (aliq / 100)).toFixed(2);
  }, [
    baseCalculo,
    calcularPIS,
    calcularIR,
    calcularINSS,
    calcularCSSL,
    calcularCOFINS,
  ]);

  useEffect(() => {
    if (isFracionado) {
      return;
    }
    
    const obterAliquota = async () => {
      if (!dadosNfeSelecionada) {
        return;
      }

      const origem = dadosNfeSelecionada.remetente_endereco?.uf;
      const destino = dadosNfeSelecionada.destinatario_endereco?.uf;
      try {
        const { data } = await api.get(`/documentos_fiscais/aliquotas/?origem=${origem || ''}&destino=${destino || ''}`);
        if (data?.aliquota) {
          setAliquotaPorcetagem(String(data.aliquota))
        }
      } catch {}
    }

    obterAliquota();
  }, [dadosNfeSelecionada, isFracionado]);

  useEffect(() => {
    if (!isFracionado) {
      return;
    }

    const fetch = async () => {
      const tomador = freteModelo;
      let cnpjTomador = '';
      if (tomador === '0') {
        cnpjTomador = remetente.cnpj;
      } else if (tomador === '1' && expedidor) {
        cnpjTomador = expedidor.cnpj;
      } else if (tomador === '2' && recebedor) {
        cnpjTomador = recebedor.cnpj;
      } else if (tomador === '3') {
        cnpjTomador = destinatario.cnpj;
      }

      const origemMunicipio = origemPrestacaoExpedidor ? expedidor.endereco.nome_municipio : remetente.endereco.nome_municipio;
      const origemUf = origemPrestacaoExpedidor ? expedidor.endereco.uf : remetente.endereco.uf;
      const destinoMunicipio = fimPrestacaoRecebedor ? recebedor.endereco.nome_municipio : destinatario.endereco.nome_municipio;
      const destinoUf = fimPrestacaoRecebedor ? recebedor.endereco.uf : destinatario.endereco.uf;
      const pesoCubado = nfesVinculadasCte.reduce((prev, item) => {
        const partial = (item.largura || 0) * (item.altura || 0) * (item.comprimento || 0);
        return prev + partial;
      }, 0);
      const distancia = nfesVinculadasCte.reduce((prev, item) => {
        return prev + item.distancia;
      }, 0);

      const dados = [
        {
          "origem_municipio": origemMunicipio,
          "origem_uf": origemUf,
          "destino_uf": destinoUf,
          "destino_municipio": destinoMunicipio,
          "tipo_produto": null,
          "tipo_veiculo": null,
          "cnpj_tomador": cnpjTomador,
          "peso_carga": pesoTotalNf,
          "valor_carga": valorTotalNf,
          "peso_carga_cubado": pesoCubado,
          'devolucao': tipoCte === "6",
          'reentrega': tipoCte === "5",
          'distancia': distancia,
          ...dadosVeiculoColeta,
        }
      ];

      try {
        const { data } = await api.post(`/fracionado/valor_cte/`, dados)

        if (data && data.length === 1) {
          const [item] = data;
          const periodoInicial = item.options.prazo || 0;
          
          setDataFechamentoCte(addDays(new Date(), periodoInicial)?.toISOString().split("T")[0]);
          setEmbutirICMS(item.options.embutir_icms);
          setEmbutirImpostosFederais(item.options.embutir_impostos_federais);
          setTaxaFixa(String(item.taxa_fixa).replace('.', ','));
          setFretePeso(String(item.frete_peso).replace('.', ','));
          setFreteValor(String(item.frete_valor).replace('.', ','));
          setSecCat(String(item.sec_cat).replace('.', ','));
          setPedagio(String(item.pedagio).replace('.', ','));
          setOutros(String(item.outros + item.seguro).replace('.', ','));
          setTde(String(item.tde).replace('.', ','));
          setGris(String(item.gris).replace('.', ','));
          setAliquotaPorcetagem(String(item.aliquota_icms).replace('.', ','));
          setDespacho(String(item.despacho).replace('.', ','));
        }
      } catch {}
    };

    const timer = setTimeout(() => {
      fetch();
    }, 400);

    return () => {
      clearTimeout(timer);
    }
  }, [
    isFracionado,
    valorTotalNf,
    dadosVeiculoColeta,
    tipoCte,
    pesoTotalNf,
    nfesVinculadasCte,
    freteModelo,
    remetente,
    destinatario,
    expedidor,
    recebedor,
    origemPrestacaoExpedidor,
    fimPrestacaoRecebedor,
  ]);

  useEffect(() => {
    const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
    const idTransportadora = dadosUsuario.transportadora_id;

    api
      .get(`transportadora/${idTransportadora}/`)
      .then(function (response) {
        setTransportadora(response.data);
        //const periodoInicial =
        //  response.data?.omie_vencimento_conta_receber || 0;
        //setDataFechamentoCte(
        //  addDays(new Date(), periodoInicial)?.toISOString().split("T")[0]
        //);

        setNumero((prev) => {
          if (prev === "0") {
            return response.data.numero_cte;
          }
          return prev;
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (idColeta) {
      return api
        .get(`/fracionado/nfes-by-coletas/?coletas=${idColeta}`)
        .then((response) => {
          setNfesMesmoCnpj(response.data.results);
        })
        .catch((err) => {
          setNfesMesmoCnpj([dadosNfeSelecionada]);
        });
    }

    const params = `carga__isnull=1&remetente_cnpj=${dadosNfeSelecionada?.remetente_cnpj}&destinatario_cnpj=${dadosNfeSelecionada?.destinatario_cnpj}`;
    const url = `documentos_fiscais/nfe/?${params}`;
    api
      .get(url)
      .then(function (response) {
        setNfesMesmoCnpj(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [dadosNfeSelecionada, idColeta]);

  useEffect(() => {
    if (!dadosNfeSelecionada) {
      return;
    }
    setRemetente({
      cnpj: dadosNfeSelecionada.remetente_cnpj || "",
      cpf: dadosNfeSelecionada.remetente_cpf || "",
      ie: dadosNfeSelecionada.remetente_ie || "",
      razao_social: dadosNfeSelecionada.remetente_razao_social || "",
      endereco: {
        bairro: dadosNfeSelecionada.remetente_endereco?.bairro || "",
        cep: dadosNfeSelecionada.remetente_endereco?.cep || "",
        codigo_municipio:
          dadosNfeSelecionada.remetente_endereco?.codigo_municipio || "",
        nome_municipio:
          dadosNfeSelecionada.remetente_endereco?.nome_municipio || "",
        logradouro: dadosNfeSelecionada.remetente_endereco?.logradouro || "",
        numero: dadosNfeSelecionada.remetente_endereco?.numero || "",
        uf: dadosNfeSelecionada.remetente_endereco?.uf || "",
        complemento: dadosNfeSelecionada.remetente_endereco?.complemento || "",
      },
    });
    setDestinatario({
      cnpj: dadosNfeSelecionada.destinatario_cnpj || "",
      cpf: dadosNfeSelecionada.destinatario_cpf || "",
      ie: dadosNfeSelecionada.destinatario_ie || "",
      razao_social: dadosNfeSelecionada.destinatario_razao_social || "",
      endereco: {
        bairro: dadosNfeSelecionada.destinatario_endereco?.bairro || "",
        cep: dadosNfeSelecionada.destinatario_endereco?.cep || "",
        codigo_municipio:
          dadosNfeSelecionada.destinatario_endereco?.codigo_municipio || "",
        nome_municipio:
          dadosNfeSelecionada.destinatario_endereco?.nome_municipio || "",
        logradouro: dadosNfeSelecionada.destinatario_endereco?.logradouro || "",
        numero: dadosNfeSelecionada.destinatario_endereco?.numero || "",
        uf: dadosNfeSelecionada.destinatario_endereco?.uf || "",
        complemento:
          dadosNfeSelecionada.destinatario_endereco?.complemento || "",
      },
    });
  }, [dadosNfeSelecionada]);

  //useEffect(() => {
  //  const obterAliquota = async () => {
  //    if (!dadosNfeSelecionada) {
  //      return;
  //    }
//
  //    const origem = dadosNfeSelecionada.remetente_endereco?.uf;
  //    const destino = dadosNfeSelecionada.destinatario_endereco?.uf;
  //    try {
  //      const { data } = await api.get(`/documentos_fiscais/aliquotas/?origem=${origem || ''}&destino=${destino || ''}`);
  //      if (data?.aliquota) {
  //        setAliquotaPorcetagem(String(data.aliquota))
  //      }
  //    } catch {}
  //  }
//
  //  obterAliquota();
  //}, [dadosNfeSelecionada]);

  useEffect(() => {
    let bc = Number(somaValoresPrestacao);
    if (desconsiderarDescarga) {
      bc -= Number(descarga.replaceAll(".", "").replaceAll(",", "."))
    }
    if (desconsiderarPedagio) {
      bc -= Number(pedagio.replaceAll(".", "").replaceAll(",", "."))
    }
    setBaseCalculo(bc);
  }, [somaValoresPrestacao, desconsiderarDescarga, desconsiderarPedagio, descarga, pedagio]);

  useEffect(() => {
    let valorDuplicada =
      valorTn !== 0
        ? typeof valorTn === "number"
          ? (valorTn / quantidadeParcelas).toFixed(2)
          : (
              Number(valorTn.replaceAll(".", "").replaceAll(",", ".")) /
              quantidadeParcelas
            ).toFixed(2)
        : (parseFloat(somaValoresPrestacao) / quantidadeParcelas).toFixed(2);

    let duplicatasToSet = [];

    const periodoInicial = transportadora?.omie_vencimento_conta_receber || 0;

    for (let i = 0; i < quantidadeParcelas; i++) {
      let dataVencimento = addDays(new Date(), periodoInicial + periodo * i);

      duplicatasToSet = [
        ...duplicatasToSet,
        {
          numero: i + 1,
          data_vencimento: dataVencimento.toISOString().split("T")[0],
          valor: valorDuplicada,
        },
      ];
    }

    setDuplicatas(duplicatasToSet);
  }, [
    valorTn,
    periodo,
    quantidadeParcelas,
    somaValoresPrestacao,
    transportadora,
  ]);

  useEffect(() => {
    const obtemValoresPrestacoes = (componentes) => {
      componentes.forEach((comp) => {
        switch (comp.nome) {
          case "VALOR TAXA FIXA":
            setTaxaFixa(String(comp.valor));
            return;
          case "VALOR ITR":
            setItr(String(comp.valor));
            return;
          case "VALOR DESPACHO":
            setDespacho(String(comp.valor));
            return;
          case "VALOR ADEME":
            setAdme(String(comp.valor));
            return;
          case "VALOR PESO":
            setFretePeso(String(comp.valor));
            return;
          case "VALOR FRETE":
            setFreteValor(String(comp.valor));
            return;
          case "VALOR SEC CAT":
            setSecCat(String(comp.valor));
            return;
          case "VALOR OUTROS":
            setOutros(String(comp.valor));
            return;
          case "VALOR GRIS":
            setGris(String(comp.valor));
            return;
          case "VALOR PEDAGIO":
            setPedagio(String(comp.valor));
            return;
          case "VALOR DESCARGA":
            setDescarga(String(comp.valor));
            return;
          case "VALOR TDE":
            setTde(String(comp.valor));
            return;
          case "VALOR DESCONTO":
            setDesconto(String(comp.valor));
            return;
          default:
            return;
        }
      });
    };

    if (idCteSelecionado) {
      if (tipoCteInicial && String(tipoCteInicial) === "1") {
        setIdSobrescrita(null);
        setTipoCte("1");
        api
          .get(`documentos_fiscais/cte/${idCteSelecionado}/`)
          .then((response) => {
            setChaveCteOriginal(response.data.chave || "");
          })
          .catch(function (error) {
            console.log(error);
          });
        return;
      }

      if (tipoCteInicial && String(tipoCteInicial) === "-1") {
        setIdSobrescrita(null);
        setTipoCte("0");
        api
          .get(`documentos_fiscais/cte/${idCteSelecionado}/`)
          .then((response) => {
            if (!response.data?.notas_transportadas?.length) {
              return;
            }

            const nfes = response.data.notas_transportadas.map(
              (item) => item.nfe
            );
            setNfesVinculadasCte(nfes);
            //setChaveCteOriginal(response.data.chave || '');
          })
          .catch(function (error) {
            console.log(error);
          });
        return;
      }

      api
        .get(`documentos_fiscais/cte/${idCteSelecionado}/dados_emissao/`)
        .then(function (response) {
          let dadosCte = response.data;
          console.log("dadosCte backend", dadosCte);

          setIdColetaRetificar(dadosCte.coleta || null);
          setIsFracionado(dadosCte.lotacao === "Fracionado" ? true : false);
          setTipoCte(dadosCte.tipo_original || dadosCte.tipo);
          setTipoServico(dadosCte.tipo_servico);

          if (dadosCte.tipo_servico === "1" && dadosCte.documentos_anteriores) {
            const item = {...dadosCte.documentos_anteriores}
            delete item.ctes;
            setDocumentosAnteriores(item);
            setCtesAnteriores(dadosCte.documentos_anteriores.ctes.map((item) => item.chave));
          }

          setCfopSelecionado(dadosCte.codigo_fiscal_operacao_prestacao);
          setTextoCfopSelecionado(dadosCte.natureza_operacao);
          setDataFechamentoCte(dadosCte.data_programada_entrega);
          setFreteModelo(dadosCte.tomador);
          if (dadosCte.tomador === "4" && dadosCte.outro_tomador) {
            setOutroTomador(dadosCte.outro_tomador)
          }
          if (dadosCte.informacoes_cte?.documentos?.nfes) {
            setNfesVinculadasCte(
              dadosCte.informacoes_cte?.documentos?.nfes.map((item) => item)
            );
          }
          setBaseCalculo(
            Number(dadosCte.impostos?.icms00?.valor_base_calculo) ||
              Number(dadosCte.impostos?.icms20?.valor_base_calculo) ||
              Number(dadosCte.impostos?.icms60?.valor_base_calculo) ||
              Number(dadosCte.impostos?.icms90?.valor_base_calculo) ||
              0
          );
          setAliquotaPorcetagem(
            dadosCte.impostos?.icms00?.aliquota ||
              dadosCte.impostos?.icms20?.aliquota ||
              dadosCte.impostos?.icms60?.aliquota ||
              dadosCte.impostos?.icms90?.aliquota ||
              0
          );
          setPorcentagemReducaoICMS(
            dadosCte.impostos?.icms00?.percentual_reducao ||
              dadosCte.impostos?.icms20?.percentual_reducao ||
              dadosCte.impostos?.icms60?.percentual_reducao ||
              dadosCte.impostos?.icms90?.percentual_reducao ||
              0
          );
          setCreditoICMS(
            dadosCte.impostos?.icms00?.valor_credito ||
              dadosCte.impostos?.icms20?.valor_credito ||
              dadosCte.impostos?.icms60?.valor_credito ||
              dadosCte.impostos?.icms90?.valor_credito ||
              0
          );
          setSituacaoIcms(dadosCte.impostos?.situacao || "00");
          setValorTnSelecionado(dadosCte.valor_cte_tipo);
          setValorTn(dadosCte.valor_cte);
          setIsAPagar(dadosCte.forma_pagamento === "A Pagar" ? true : false);
          setQuantidadeParcelas(dadosCte.informacoes_cte?.duplicatas.length);
          setPeriodo(dadosCte.periodo);
          setTomadorContribuinte(dadosCte.indicador_tomador);
          setObservacoesGerais(dadosCte.observacoes);
          setNomeMotorista(dadosCte.motorista);
          setPlacaVeiculo(dadosCte.placa);
          setVendedor(dadosCte.vendedor);
          setProdutoPredominante(
            dadosCte.informacoes_cte?.produto_predominante || "DESCONHECIDO"
          );
          obtemValoresPrestacoes(dadosCte.prestacao.componentes);

          setDestinatario(converterPapelDeDadosEmissao(dadosCte.destinatario));
          setRemetente(converterPapelDeDadosEmissao(dadosCte.remetente));
          setExpedidor(converterPapelDeDadosEmissao(dadosCte.expedidor));
          setRecebedor(converterPapelDeDadosEmissao(dadosCte.recebedor));
          if (dadosCte.expedidor) {
            setOrigemPrestacaoExpedidor(true);
          }
          if (dadosCte.recebedor) {
            setFimPrestacaoRecebedor(true);
          }

          setEmbutirICMS(dadosCte.embutir_icms)
          setEmbutirImpostosFederais(dadosCte.embutir_impostos_federais)

          setDesconsiderarDescarga(dadosCte.desconsiderar_descarga)
          setDesconsiderarPedagio(dadosCte.desconsiderar_pedagio)
          
          if (dadosCte.status === "Negado") {
            setNumero(dadosCte.numero);
          }

          if (tipoCteInicial && String(tipoCteInicial) === "3") {
            setIdSobrescrita(null);
            setTipoCte("3");
            setChaveCteOriginal(dadosCte.chave || "");
            setChaveCteAnulacao(dadosCte.cte_anulacao || "");
          } else {
            setIdSobrescrita(idCteSelecionado);
            setTipoCte(dadosCte.tipo || "1");
            console.log(dadosCte.informacoes_complementar);
            if (dadosCte.informacoes_substituicao) {
              setChaveCteAnulacao(
                dadosCte.informacoes_substituicao.cte_anulacao
              );
              setChaveCteOriginal(
                dadosCte.informacoes_substituicao.cte_original
              );
            } else if (dadosCte.informacoes_complementar) {
              setChaveCteOriginal(
                dadosCte.informacoes_complementar.cte_original
              );
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [idCteSelecionado, tipoCteInicial]);

  const formataChavesNfesVinculadas = () => {
    return nfesVinculadasCte.map((nfe) => ({
      chave: nfe.chave,
    }));
  };

  const removerMascaraCampo = (dado) => {
    return dado
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll(" ", "")
      .replaceAll("-", "")
      .replaceAll(".", "")
      .replaceAll("/", "");
  };

  const gerarICMS = () => {
    switch (situacaoIcms) {
      case "00":
        return {
          icms00: {
            valor_base_calculo:
              String(baseCalculo) !== "0"
                ? isNaN(baseCalculo)
                  ? Number(
                      String(baseCalculo)
                        .replaceAll(".", "")
                        .replaceAll(",", ".")
                    )
                  : baseCalculo
                : somaValoresPrestacao,
            aliquota: aliquotaPorcetagem.toString(),
            valor_icms: valorIcms,
          },
        };
      case "20":
        return {
          icms20: {
            percentual_reducao: porcentagemReducaoICMS,
            valor_base_calculo:
              String(baseCalculo) !== "0"
                ? isNaN(baseCalculo)
                  ? Number(
                      String(baseCalculo)
                        .replaceAll(".", "")
                        .replaceAll(",", ".")
                    )
                  : baseCalculo
                : somaValoresPrestacao,
            aliquota: aliquotaPorcetagem.toString(),
            valor_icms: valorIcms,
          },
        };
      case "60":
        return {
          icms60: {
            valor_base_calculo:
              String(baseCalculo) !== "0"
                ? isNaN(baseCalculo)
                  ? Number(
                      String(baseCalculo)
                        .replaceAll(".", "")
                        .replaceAll(",", ".")
                    )
                  : baseCalculo
                : somaValoresPrestacao,
            aliquota: aliquotaPorcetagem.toString(),
            valor_icms: valorIcms,
            valor_credito: creditoICMS,
          },
        };
      case "90":
        let icms90 = {
          valor_base_calculo:
            String(baseCalculo) !== "0"
              ? isNaN(baseCalculo)
                ? Number(
                    String(baseCalculo).replaceAll(".", "").replaceAll(",", ".")
                  )
                : baseCalculo
              : somaValoresPrestacao,
          aliquota: aliquotaPorcetagem.toString(),
          valor_icms: valorIcms,
          valor_credito: creditoICMS,
        };
        if (String(porcentagemReducaoICMS) !== "0") {
          icms90 = {
            ...icms90,
            percentual_reducao: porcentagemReducaoICMS,
          };
        }
        return { icms90 };
      default:
        return;
    }
  };

  const gerarCteSubstituicao = () => {
    if (tipoCte !== "3") {
      return {};
    }
    return {
      informacoes_substituicao: {
        cte_original: chaveCteOriginal,
        cte_anulacao: chaveCteAnulacao,
      },
    };
  };

  const gerarCteComplementar = () => {
    if (tipoCte !== "1") {
      return {};
    }
    return {
      informacoes_complementar: {
        cte_original: chaveCteOriginal,
      },
    };
  };

  const gerarCteNormal = () => {
    if (tipoCte === "1") {
      return {};
    }
    return {
      informacoes_cte: {
        valor_carga: valorTotalNf,
        produto_predominante: produtoPredominante,
        informacoes_quantidade: [
          {
            unidade: "01",
            tipo_medida: "PESO BRUTO",
            quantidade: pesoTotalNf,
          },
          {
            unidade: "03",
            tipo_medida: "VOLUMES",
            quantidade: qtdVolumesTotalNf || "0.00",
          },
        ],
        documentos: {
          nfes: formataChavesNfesVinculadas(),
        },
        duplicatas: duplicatas,
      },
    };
  };

  const registrarCte = (e) => {
    e.preventDefault();
    setLoadingEnvio(true);

    const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));
    const idTransportadora = dadosUsuario.transportadora_id;

    let dados = {
      emitir: acaoBotao === "Salvar" ? false : true,
      cte: idSobrescrita || null,
      carga: dadosNfeSelecionada?.carga,
      transportadora: idTransportadora,
      data_programada_entrega: dataFechamentoCte,
      natureza_operacao: textoCfopSelecionado,
      numero: numero,
      codigo_fiscal_operacao_prestacao: cfopSelecionado,
      tomador: freteModelo,
      tipo: tipoCte,
      tipo_servico: tipoServico,
      lotacao: isFracionado ? "Fracionado" : "Cheio",
      forma_pagamento: isAPagar ? "A Pagar" : "Pago",
      apolice_seguro: transportadora?.asseguradoras[0]?.apolice || "",
      nome_seguradora: transportadora?.asseguradoras[0]?.nome || "",
      valor_cte_tipo: valorTnSelecionado,
      valor_cte:
        valorTn === 0
          ? Number(somaValoresPrestacao)
          : typeof valorTn === "number"
          ? valorTn
          : Number(valorTn.replaceAll(".", "").replaceAll(",", ".")),
      observacoes: observacoesGerais,
      codigo_municipio_inicio_prestacao: origemPrestacaoExpedidor ? expedidor.endereco.codigo_municipio : remetente.endereco.codigo_municipio,
      uf_inicio_prestacao: origemPrestacaoExpedidor ? expedidor.endereco.uf : remetente.endereco.uf,
      codigo_municipio_fim_prestacao: fimPrestacaoRecebedor ? recebedor.endereco.codigo_municipio : destinatario.endereco.codigo_municipio,
      uf_fim_prestacao: fimPrestacaoRecebedor ? recebedor.endereco.uf : destinatario.endereco.uf,
      remetente: converterPapelParaEnvio(remetente),
      destinatario: converterPapelParaEnvio(destinatario),
      recebedor: converterPapelParaEnvio(recebedor),
      expedidor: converterPapelParaEnvio(expedidor),
      versao_modal: tipoCte === "1" ? null : "3.00",
      prestacao: {
        valor_total: somaValoresPrestacao,
        valor_receber: isAPagar ? somaValoresPrestacao : "0",
        componentes: [
          {
            nome: "VALOR TAXA FIXA",
            valor: Number(taxaFixa.replaceAll(".", "").replaceAll(",", ".")),
          },
          {
            nome: "VALOR ITR",
            valor: Number(itr.replaceAll(".", "").replaceAll(",", ".")),
          },
          {
            nome: "VALOR DESPACHO",
            valor: Number(despacho.replaceAll(".", "").replaceAll(",", ".")),
          },
          {
            nome: "VALOR ADEME",
            valor: Number(adme.replaceAll(".", "").replaceAll(",", ".")),
          },
          {
            nome: "VALOR PESO",
            valor: Number(fretePeso.replaceAll(".", "").replaceAll(",", ".")),
          },
          {
            nome: "VALOR FRETE",
            valor: Number(freteValor.replaceAll(".", "").replaceAll(",", ".")),
          },
          {
            nome: "VALOR SEC CAT",
            valor: Number(secCat.replaceAll(".", "").replaceAll(",", ".")),
          },
          {
            nome: "VALOR OUTROS",
            valor: Number(outros.replaceAll(".", "").replaceAll(",", ".")),
          },
          {
            nome: "VALOR GRIS",
            valor: Number(gris.replaceAll(".", "").replaceAll(",", ".")),
          },
          {
            nome: "VALOR PEDAGIO",
            valor: Number(pedagio.replaceAll(".", "").replaceAll(",", ".")),
          },
          {
            nome: "VALOR DESCARGA",
            valor: Number(descarga.replaceAll(".", "").replaceAll(",", ".")),
          },
          {
            nome: "VALOR TDE",
            valor: Number(tde.replaceAll(".", "").replaceAll(",", ".")),
          },
          {
            nome: "VALOR DESCONTO",
            valor: Number(desconto.replaceAll(".", "").replaceAll(",", ".")),
          },
        ],
      },
      impostos: {
        situacao: situacaoIcms,
        ...gerarICMS(),
      },
      ...gerarCteNormal(),
      indicador_tomador: tomadorContribuinte,
      ...gerarCteSubstituicao(),
      ...gerarCteComplementar(),
      periodo: periodo,
      motorista: nomeMotorista,
      placa: String(placaVeiculo || '').toUpperCase().replaceAll("-", ""),
      vendedor: removerMascaraCampo(vendedor),

      embutir_icms: embutirICMS,
      embutir_impostos_federais: embutirImpostosFederais,

      desconsiderar_descarga: desconsiderarDescarga,
      desconsiderar_pedagio: desconsiderarDescarga,
    };

    if (idColeta || idColetaRetificar) {
      dados['coleta'] = idColeta || idColetaRetificar;
    }

    if (mudaTomador && tipoCte === "3") {
      dados["indica_alterar_tomador"] = "1";
    }

    if (freteModelo === "4") {
      dados["outro_tomador"] = {
        ...outroTomador,
        endereco: {
          ...outroTomador.endereco,
          cep: outroTomador.endereco.cep.replace('-', ''),
        }
      };
    }

    if (tipoServico === "1") {
      dados["documentos_anteriores"] = {
        ...documentosAnteriores,
        ctes: ctesAnteriores.filter((item) => !!item).map((item) => ({
          chave: item,
        })),
      }
    }

    api
      .post("documentos_fiscais/emitircte/", dados)
      .then(function () {
        setLoadingEnvio(false);

        confirmAlert({
          title: "Sucesso",
          message: `Documento ${
            acaoBotao === "Salvar" ? "salvo" : "enviado"
          } com sucesso!`,
          overlayClassName: "alert-sucesso-cte",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                onClose();
              },
            },
          ],
        });
      })
      .catch(function (error) {
        setLoadingEnvio(false);
        console.log(error.response);

        const { data } = error.response;
        const errors = getDisplayErrors(data);

        confirmAlert({
          overlayClassName: "alert-sucesso-cte",
          customUI: ({ onClose }) => (
            <div className="react-confirm-alert-body errors-modal-body">
              <h1>Houveram Alguns Erros</h1>
              <div className="errors-modal-part">
                {errors.map((item) => (
                  <div key={JSON.stringify(item)}>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                ))}
              </div>
              <div className="react-confirm-alert-button-group">
                <button className="botao-cancelar" onClick={onClose}>
                  Ok
                </button>
              </div>
            </div>
          ),
        });
      });
  };

  const editarDuplicatas = (novoDado, index, chave) => {
    let auxDuplicatas = [...duplicatas];
    let duplicataEscolhida = auxDuplicatas[index];
    duplicataEscolhida[chave] = novoDado;

    if (chave === "data_vencimento") {
      auxDuplicatas.forEach((duplicata, index) => {
        if (isValid(new Date(novoDado), periodo * index)) {
          duplicata.data_vencimento = addDays(
            new Date(novoDado),
            periodo * index
          )
            ?.toISOString()
            .split("T")[0];
        }
      });
    }

    setDuplicatas(auxDuplicatas);
    return;
  };

  const handleChangeCep = (value) => {
    if (value.length !== 9) {
      setOutroTomador((prev) => ({
        ...prev,
        endereco: {
          ...prev.endereco,
          cep: value,
        }
      }));
      return;
    }
    fetch(`https://viacep.com.br/ws/${value}/json/`)
      .then((response) => {
        response.json()
          .then((data) => {
            setOutroTomador((prev) => ({
              ...prev,
              endereco: {
                ...prev.endereco,
                cep: value,
                uf: data.uf,
                nome_municipio: data.localidade,
                codigo_municipio: data.ibge,
                logradouro: data.logradouro,
                bairro: data.bairro,
              }
            }));
          })
      })
      .catch((error) => {
        console.log(error.message)
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-gerar-cte"
      overlayClassName="overlay-modal-gerar-cte"
      ariaHideApp={false}
    >
      <form className="gerar-cte" onSubmit={registrarCte}>
        <RiCloseCircleFill className="icone-fechar" onClick={onClose} />

        <p className="titulo">Gerar Novo CT-e Avulso</p>

        <div className="container-formulario">
          <div className="container-inputs-3col">
            <div className="container-input-comum">
              <input
                required
                type="text"
                value={quantidadeEntregas}
                onChange={(e) => setQuantidadeEntregas(e.target.value)}
              />
              <span>Quantidade de Entregas "Num"</span>
            </div>

            <div className="input-radio-gerar-cte">
              <div className="container-input-radio">
                <span
                  className={`radio ${isFracionado && "checked"}`}
                  onClick={() => setIsFracionado(true)}
                >
                  <input
                    type="radio"
                    value={true}
                    checked={isFracionado === true}
                    onChange={() => setIsFracionado(true)}
                  />
                  <span className="checked"></span>
                  Fracionado
                </span>
              </div>

              <div className="container-input-radio">
                <span
                  className={`radio ${!isFracionado && "checked"}`}
                  onClick={() => setIsFracionado(false)}
                >
                  <input
                    type="radio"
                    value={false}
                    checked={isFracionado === false}
                    onChange={() => setIsFracionado(false)}
                  />
                  <span className="checked"></span>
                  Cheio
                </span>
              </div>
            </div>

            <div className="container-input-comum">
              <input
                required
                type="number"
                value={numero}
                onChange={(e) => setNumero(e.target.value)}
                disabled
              />
              <span>Número do CT-e</span>
            </div>
          </div>

          <div className="container-inputs-4col">
            <div className="container-input-comum">
              <select
                value={tipoCte}
                onChange={(e) => handleUpdateTipoCte(e.target.value)}
                required
              >
                <option value="0">Normal</option>
                <option value="1">Complementar</option>
                <option value="3">Substituição</option>
                <option value="4">Diária</option>
                <option value="5">Reentrega</option>
                <option value="6">Devolução</option>
                <option value="7">Cortesia</option>
              </select>
              <span>Tipo do CT-e</span>
            </div>

            <div className="container-input-comum">
              <select
                value={tipoServico}
                onChange={(e) => setTipoServico(e.target.value)}
                required
              >
                <option value="0">Normal</option>
                <option value="1">SubContratação</option>
                <option value="2">Redespacho</option>
                <option value="3">Redespacho Intermediário</option>
                <option value="4">Serviço Vinculado Multimodal</option>
              </select>
              <span>Tipo de Serviço</span>
            </div>

            <div className="container-input-comum">
              <select
                value={cfopSelecionado}
                onChange={(e) => {
                  setCfopSelecionado(e.target.value);
                  setTextoCfopSelecionado(
                    e.target.selectedOptions[0].getAttribute("data-texto")
                  );
                }}
              >
                <option value="">Selecione</option>
                {listaCFOPs.map((cfop) => {
                  return (
                    <option
                      key={cfop
                        .split("-")[0]
                        .replaceAll(" ", "")
                        .replaceAll(".", "")}
                      value={cfop
                        .split("-")[0]
                        .replaceAll(" ", "")
                        .replaceAll(".", "")}
                      data-texto={cfop.split("-")[1]}
                    >
                      {cfop}
                    </option>
                  );
                })}
              </select>
              <span>CFOP</span>
            </div>

            <div className="container-input-comum">
              <input
                type="date"
                value={dataFechamentoCte}
                onChange={(e) => setDataFechamentoCte(e.target.value)}
              />
              <span>Fechamento CT-e</span>
            </div>
          </div>

          <div className="container-inputs-4col">
            <div className="container-input-comum">
              <select
                value={tomadorContribuinte}
                onChange={(e) => setTomadorContribuinte(e.target.value)}
                required
              >
                <option value="1">Contribuinte ICMS</option>
                <option value="2">Contribuinte isento de inscrição</option>
                <option value="9">Não Contribuinte</option>
              </select>
              <span>Tomador Contribuinte</span>
            </div>
          </div>
        </div>

        {tipoServico === "1" && (
          <div className="container-formulario">
            <p className="titulo-formulario">Emissor de Documentos Anteriores</p>

            <div className="container-inputs-3col">
              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  onChange={(e) => setDocumentosAnteriores((prev) => ({
                    ...prev,
                    razao_social: e.target.value,
                  }))}
                  value={documentosAnteriores.razao_social}
                />
                <span>Nome / R.Social</span>
              </div>

              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  onChange={(e) => setDocumentosAnteriores((prev) => ({
                    ...prev,
                    cnpj: e.target.value,
                  }))}
                  value={documentosAnteriores.cnpj}
                />
                <span>CNPJ</span>
              </div>

              <div className="container-input-comum">
                <input
                  type="text"
                  onChange={(e) => setDocumentosAnteriores((prev) => ({
                    ...prev,
                    inscricao_estadual: e.target.value,
                  }))}
                  value={documentosAnteriores.inscricao_estadual}
                />
                <span>I.E</span>
              </div>

              <div className="container-input-comum">
                <input
                  type="text"
                  onChange={(e) => setDocumentosAnteriores((prev) => ({
                    ...prev,
                    uf: e.target.value,
                  }))}
                  value={documentosAnteriores.uf}
                />
                <span>UF</span>
              </div>
            </div>

            <p className="titulo-formulario" style={{ marginTop: 25 }}>Documentos Anteriores</p>

            <div className="container-inputs-3col">
              {ctesAnteriores.map((item, index) => (
                <div className="container-input-comum" key={index}>
                  <input
                    type="text"
                    onChange={(e) => setCtesAnteriores((prev) => prev.map((cte, cteIndex) => {
                      if (cteIndex === index) {
                        return e.target.value;
                      }
                      return cte;
                    }))}
                    value={item}
                  />
                  <span>Chave</span>
                </div>
              ))}
              {showAddCteAnterior && (
                <AiFillFileAdd
                  size={24}
                  onClick={handleAddCteAnterior}
                  style={{ cursor: 'pointer' }}
                  title="Adicionar novo documento anterior"
                />
              )}
            </div>
          </div>
        )}

        <FormularioPapelCte
          papel={remetente}
          setPapel={setRemetente}
          title="Remetente"
          showSwap={true}
          onSwapClick={handleInverterRemetenteDestinatario}
        />

        <FormularioPapelCte
          papel={destinatario}
          setPapel={setDestinatario}
          title="Destinatário"
          showSwap={true}
          onSwapClick={handleInverterRemetenteDestinatario}
        />

        {!!recebedor && (
          <>
            <FormularioPapelCte
              papel={recebedor}
              setPapel={setRecebedor}
              title="Recebedor"
              showSwap={false}
              showClose={true}
              onCloseClick={handleRemoverRecebedor}
            />

            <div style={{ marginTop: 10 }}>
              <span className="checkbox">
                <input
                  id="fim-prestacao-recebedor"
                  type="checkbox"
                  value={true}
                  checked={fimPrestacaoRecebedor}
                  onChange={(e) =>
                    setFimPrestacaoRecebedor(e.target.checked)
                  }
                />
                <label htmlFor="fim-prestacao-recebedor" style={{ paddingLeft: 5 }}>
                  Municipio/UF de Fim da Prestação é o Recebedor
                </label>
              </span>
            </div>
          </>
        )}

        {!!expedidor && (
          <>
            <FormularioPapelCte
              papel={expedidor}
              setPapel={setExpedidor}
              title="Expedidor"
              showSwap={false}
              showClose={true}
              onCloseClick={handleRemoverExpedidor}
            />

            <div style={{ marginTop: 10 }}>
              <span className="checkbox">
                <input
                  id="inicio-prestacao-expedidor"
                  type="checkbox"
                  value={true}
                  checked={origemPrestacaoExpedidor}
                  onChange={(e) =>
                    setOrigemPrestacaoExpedidor(e.target.checked)
                  }
                />
                <label htmlFor="inicio-prestacao-expedidor" style={{ paddingLeft: 5 }}>
                  Municipio/UF de Início da Prestação é o Expedidor
                </label>
              </span>
            </div>
          </>
        )}

        {(!recebedor || !expedidor) && (
          <div className="novos-papeis">
            {!expedidor && (
              <button type="button" onClick={handleAdicionarExpedidor}>
                Adicionar Expedidor
              </button>
            )}
            {!recebedor && (
              <button type="button" onClick={handleAdicionarRecebedor}>
                Adicionar Recebedor
              </button>
            )}
          </div>
        )}

        <div className="container-formulario">
          <p className="titulo-formulario">Tomador do Serviço</p>

          <div className="container-inputs-2col">
            <div className="input-radio-gerar-cte">
              <div className="container-input-radio">
                <span
                  className={`radio ${freteModelo === "0" && "checked"}`}
                  onClick={() => setFreteModelo("0")}
                >
                  <input
                    type="radio"
                    value={true}
                    checked={freteModelo === "0"}
                    onChange={() => setFreteModelo("0")}
                  />
                  <span className="checked"></span>
                  CIF (Remetente)
                </span>
              </div>

              <div className="container-input-radio">
                <span
                  className={`radio ${!freteModelo === "3" && "checked"}`}
                  onClick={() => setFreteModelo("3")}
                >
                  <input
                    type="radio"
                    value={false}
                    checked={freteModelo === "3"}
                    onChange={() => setFreteModelo("3")}
                  />
                  <span className="checked"></span>
                  FOB (Destinatário)
                </span>
              </div>

              <div className="container-input-radio">
                <span
                  className={`radio ${!freteModelo === "1" && "checked"}`}
                  onClick={() => setFreteModelo("1")}
                >
                  <input
                    type="radio"
                    value={false}
                    checked={freteModelo === "1"}
                    onChange={() => setFreteModelo("1")}
                  />
                  <span className="checked"></span>
                  Expedidor
                </span>
              </div>

              <div className="container-input-radio">
                <span
                  className={`radio ${!freteModelo === "2" && "checked"}`}
                  onClick={() => setFreteModelo("2")}
                >
                  <input
                    type="radio"
                    value={false}
                    checked={freteModelo === "2"}
                    onChange={() => setFreteModelo("2")}
                  />
                  <span className="checked"></span>
                  Recebedor
                </span>
              </div>

              <div className="container-input-radio">
                <span
                  className={`radio ${!freteModelo === "4" && "checked"}`}
                  onClick={() => setFreteModelo("4")}
                >
                  <input
                    type="radio"
                    value={false}
                    checked={freteModelo === "4"}
                    onChange={() => setFreteModelo("4")}
                  />
                  <span className="checked"></span>
                  Outro
                </span>
              </div>
            </div>
          </div>
          
          {freteModelo === "4" && (
            <>
              <p className="titulo-formulario" style={{ marginTop: 25 }}>Dados do Tomador de Serviço</p>

              <div className="container-inputs-3col">
                <div className="container-input-comum">
                  <input
                    required
                    type="text"
                    onChange={(e) => setOutroTomador((prev) => ({
                      ...prev,
                      razao_social: e.target.value,
                    }))}
                    value={outroTomador.razao_social}
                  />
                  <span>Nome / R.Social</span>
                </div>

                <div className="container-input-comum">
                  <input
                    type="text"
                    onChange={(e) => setOutroTomador((prev) => ({
                      ...prev,
                      cnpj: e.target.value,
                    }))}
                    value={outroTomador.cnpj}
                  />
                  <span>CNPJ</span>
                </div>

                <div className="container-input-comum">
                  <input
                    type="text"
                    onChange={(e) => setOutroTomador((prev) => ({
                      ...prev,
                      cpf: e.target.value,
                    }))}
                    value={outroTomador.cpf}
                  />
                  <span>CPF</span>
                </div>

                <div className="container-input-comum">
                  <input
                    type="text"
                    onChange={(e) => setOutroTomador((prev) => ({
                      ...prev,
                      inscricao_estadual: e.target.value,
                    }))}
                    value={outroTomador.inscricao_estadual}
                  />
                  <span>I.E</span>
                </div>
              </div>
      
              <p className="titulo-formulario prestacao">Endereço</p>

              <div className="container-inputs-3col">
                <div className="container-input-comum">
                  <InputMask
                    required
                    mask="99999-999"
                    maskChar=""
                    onChange={(e) => handleChangeCep(e.target.value)}
                    value={outroTomador.endereco.cep}
                  ></InputMask>
                  <span>CEP</span>
                </div>

                <div className="container-input-comum">
                  <input
                    required
                    type="text"
                    onChange={(e) => setOutroTomador((prev) => ({
                      ...prev,
                      endereco: {
                        ...prev.endereco,
                        uf: e.target.value,
                      }
                    }))}
                    value={outroTomador.endereco.uf}
                  />
                  <span>UF</span>
                </div>
                
                <div className="container-input-comum">
                  <input
                    type="text"
                    onChange={(e) => setOutroTomador((prev) => ({
                      ...prev,
                      endereco: {
                        ...prev.endereco,
                        nome_municipio: e.target.value,
                      }
                    }))}
                    value={outroTomador.endereco.nome_municipio}
                  />
                  <span>Nome Municipio</span>
                </div>

                <div className="container-input-comum">
                  <input
                    required
                    type="text"
                    onChange={(e) => setOutroTomador((prev) => ({
                      ...prev,
                      endereco: {
                        ...prev.endereco,
                        codigo_municipio: e.target.value,
                      }
                    }))}
                    value={outroTomador.endereco.codigo_municipio}
                  />
                  <span>Código Municipio</span>
                </div>

                <div className="container-input-comum">
                  <input
                    required
                    type="text"
                    onChange={(e) => setOutroTomador((prev) => ({
                      ...prev,
                      endereco: {
                        ...prev.endereco,
                        bairro: e.target.value,
                      }
                    }))}
                    value={outroTomador.endereco.bairro}
                  />
                  <span>Bairro</span>
                </div>

                <div className="container-input-comum">
                  <input
                    required
                    type="text"
                    onChange={(e) => setOutroTomador((prev) => ({
                      ...prev,
                      endereco: {
                        ...prev.endereco,
                        logradouro: e.target.value,
                      }
                    }))}
                    value={outroTomador.endereco.logradouro}
                  />
                  <span>Logradouro</span>
                </div>

                <div className="container-input-comum">
                  <input
                    required
                    type="text"
                    onChange={(e) => setOutroTomador((prev) => ({
                      ...prev,
                      endereco: {
                        ...prev.endereco,
                        numero: e.target.value,
                      }
                    }))}
                    value={outroTomador.endereco.numero}
                  />
                  <span>Número</span>
                </div>

                <div className="container-input-comum">
                  <input
                    type="text"
                    onChange={(e) => setOutroTomador((prev) => ({
                      ...prev,
                      endereco: {
                        ...prev.endereco,
                        complemento: e.target.value,
                      }
                    }))}
                    value={outroTomador.endereco.complemento}
                  />
                  <span>Complemento</span>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="container-notas-fiscais">
          {nfesMesmoCnpj.map((nfe) => (
            <FormularioNfe
              key={nfe.id}
              nfe={nfe}
              isNfeSelecionada={nfe.id === dadosNfeSelecionada?.id}
              nfesVinculadasCte={nfesVinculadasCte}
              vincularNfe={(novaNfe) =>
                setNfesVinculadasCte((nfes) => [...nfes, novaNfe])
              }
              desvincularNfe={(nfeSelecionada) => {
                setNfesVinculadasCte(
                  nfesVinculadasCte.filter(
                    (nfes) => nfes.chave !== nfeSelecionada.chave
                  )
                );
              }}
            ></FormularioNfe>
          ))}

          <div className="container-formulario">
            <p className="titulo-formulario">Totais NF</p>

            <div className="container-inputs-3col">
              <div className="container-input-comum cinza">
                <input required type="text" value={valorTotalNf} disabled />
                <span>Valor Total NF</span>
              </div>

              <div className="container-input-comum cinza">
                <input required type="text" value={pesoTotalNf} disabled />
                <span>Peso Total</span>
              </div>

              <div className="container-input-comum cinza">
                <input required type="text" value={volumeTotalNf} disabled />
                <span>Volumes</span>
              </div>
            </div>
          </div>
        </div>

        <div className="container-formulario">
          <div className="titulo-opcoes-componentes">
            <p className="titulo-formulario">
              Opções de componentes do valor de prestação
            </p>

            <div className="container-input-comum">
              <select
                value={tipoFrete}
                onChange={(e) => setTipoFrete(e.target.value)}
                required
              >
                <option value="Combinado">Combinado</option>
                <option value="Peso/kg">Peso/kg</option>
                <option value="Peso / Cubagem">Peso / Cubagem</option>
                <option value="% Nota Fiscal">% Nota Fiscal</option>
                <option value="Por Volume">Por Volume</option>
                <option value="Por KM">Por KM</option>
                <option value="Por Pallet">Por Pallet</option>
              </select>
              <span>Tipo do Frete</span>
            </div>
            <div className="container-embutir-total">
              <p>Embutir no total da prestação?</p>

              <div className="input-radio-gerar-cte azul">
                <div className="container-input-radio">
                  <span className="checkbox">
                    <input
                      id="embutir-federias"
                      type="checkbox"
                      value={true}
                      checked={embutirImpostosFederais}
                      onChange={(e) => setEmbutirImpostosFederais(e.target.checked)}
                    />
                    <label htmlFor="embutir-federias">PIS/CONFINS/IR/CSSL</label>
                  </span>
                </div>

                <div className="container-input-radio">
                  <span className="checkbox">
                    <input
                      id="embutir-icms"
                      type="checkbox"
                      value={false}
                      checked={embutirICMS}
                      onChange={(e) => setEmbutirICMS(e.target.checked)}
                    />
                    <label htmlFor="embutir-icms">ICMS</label>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="container-inputs-6col">
            <div className="container-input-comum">
              <NumberFormat
                required
                value={taxaFixa}
                onChange={(e) => setTaxaFixa(e.target.value)}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>Taxa Fixa</span>
            </div>

            <div className="container-input-comum">
              <NumberFormat
                required
                value={itr}
                onChange={(e) => setItr(e.target.value)}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>ITR</span>
            </div>

            <div className="container-input-comum">
              <NumberFormat
                required
                value={despacho}
                onChange={(e) => setDespacho(e.target.value)}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>Despacho</span>
            </div>

            <div className="container-input-comum">
              <NumberFormat
                required
                value={adme}
                onChange={(e) => setAdme(e.target.value)}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>ADME</span>
            </div>

            <div className="container-input-comum">
              <NumberFormat
                required
                value={fretePeso}
                onChange={(e) => setFretePeso(e.target.value)}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>Frete Peso</span>
            </div>

            <div className="container-input-comum">
              <NumberFormat
                required
                value={freteValor}
                onChange={(e) => setFreteValor(e.target.value)}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>Frete Valor</span>
            </div>

            <div className="container-input-comum">
              <NumberFormat
                required
                value={secCat}
                onChange={(e) => setSecCat(e.target.value)}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>SEC/CAT</span>
            </div>

            <div className="container-input-comum">
              <NumberFormat
                required
                value={outros}
                onChange={(e) => setOutros(e.target.value)}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>Outros</span>
            </div>

            <div className="container-input-comum">
              <NumberFormat
                required
                value={gris}
                onChange={(e) => setGris(e.target.value)}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>GRIS</span>
            </div>

            <div className="container-input-comum">
              <NumberFormat
                required
                value={pedagio}
                onChange={(e) => setPedagio(e.target.value)}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>Pedágio</span>
            </div>

            <div className="container-input-comum">
              <NumberFormat
                required
                value={descarga}
                onChange={(e) => setDescarga(e.target.value)}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>Descarga</span>
            </div>

            <div className="container-input-comum">
              <NumberFormat
                required
                value={tde}
                onChange={(e) => setTde(e.target.value)}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>TDE</span>
            </div>

            <div className="container-input-comum vermelho">
              <NumberFormat
                required
                value={desconto}
                onChange={(e) => setDesconto(e.target.value)}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>Desconto</span>
            </div>

            <div className="input-radio-gerar-cte azul">
              <div className="container-input-radio">
                <span className="checkbox">
                  <input
                    id="desc-pedagio"
                    type="checkbox"
                    value={true}
                    checked={desconsiderarPedagio}
                    onChange={(e) => setDesconsiderarPedagio(e.target.checked)}
                  />
                  <label htmlFor="desc-pedagio">Desc. Pedágio BC</label>
                </span>
              </div>
            </div>

            <div className="input-radio-gerar-cte azul">
              <div className="container-input-radio">
                <span className="checkbox">
                  <input
                    id="desc-descarga"
                    type="checkbox"
                    value={true}
                    checked={desconsiderarDescarga}
                    onChange={(e) => setDesconsiderarDescarga(e.target.checked)}
                  />
                  <label htmlFor="desc-descarga">Desc. Descarga BC</label>
                </span>
              </div>
            </div>
          </div>

          <div className="container-inputs-6col">
            <div className="container-input-comum cinza">
              <input
                required
                type="number"
                value={somaValoresPrestacao}
                disabled={true}
              />
              <span>Total Prest.</span>
            </div>

            <div className="container-input-comum">
              <NumberFormat
                required
                value={baseCalculo}
                onChange={(e) => setBaseCalculo(e.target.value)}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>Base Cálc.</span>
            </div>

            <div className="container-input-comum">
              <input
                required
                type="number"
                value={aliquotaPorcetagem}
                onChange={(e) => setAliquotaPorcetagem(e.target.value)}
              />
              <span>Aliq. %</span>
            </div>

            <div className="container-input-comum cinza">
              <input required type="number" value={valorIcms} disabled />
              <span>ICMS</span>
            </div>

            <div className="container-input-comum cinza">
              <input type="text" disabled value={pisConfinsIrCssl} />
              <span>PIS/CONFINS/IR/CSSL</span>
            </div>

            <div className="container-input-comum">
              <NumberFormat
                // required
                value={icmsBarreira}
                onChange={(e) => setIcmsBarreira(e.target.value)}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>ICMS Barreira</span>
            </div>

            <div className="container-input-comum">
              <input
                // required
                type="number"
                value={porcentagemReducaoICMS}
                onChange={(e) => setPorcentagemReducaoICMS(e.target.value)}
              />
              <span>Redução da BC. %</span>
            </div>
          </div>

          <div className="container-inputs-3col prestacao">
            <div className="container-input-comum">
              <NumberFormat
                // required
                value={creditoICMS}
                onChange={(e) => setCreditoICMS(e.target.value)}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
              <span>Crédito Outorgado/Presumido</span>
            </div>

            <div className="container-input-comum">
              <input
                required
                type="number"
                value={pesoTaxado}
                onChange={(e) => setPesoTaxado(e.target.value)}
              />
              <span>Peso Taxado Kg</span>
            </div>

            <div className="container-input-comum">
              <select
                value={situacaoIcms}
                onChange={(e) => setSituacaoIcms(e.target.value)}
                required
              >
                <option value="" disabled></option>
                <option value="00">00 – Tributada Integralmente</option>
                <option value="20">20 – Com redução de base de Cálculo</option>
                <option value="40">40 – Isenta</option>
                <option value="41">41 - Não Tributada</option>
                <option value="51">51 - Diferimento</option>
                <option value="60">
                  60 - ICMS cobrado anteriormente por substituição tributária
                </option>
                <option value="90">90 - Outros</option>
              </select>
              <span>Situação ICMS</span>
            </div>
          </div>

          <p className="titulo-formulario prestacao">Outras Informações</p>

          <div className="container-inputs-3col prestacao">
            <div className="container-input-select">
              <select
                value={valorTnSelecionado}
                onChange={(e) => setValorTnSelecionado(e.target.value)}
              >
                <option value="total">Valor Total</option>
                <option value="tonelada">Valor TN</option>
              </select>

              <NumberFormat
                required
                value={valorTn}
                onChange={(e) => {
                  setDuplicatas([]);
                  setValorTn(e.target.value);
                }}
                displayType={"number"}
                thousandSeparator="."
                decimalSeparator=","
              />
            </div>

            <div className="container-input-comum">
              <input
                type="text"
                // ref={seguradora}
                disabled
                defaultValue={transportadora?.asseguradoras[0]?.nome || ""}
              />
              <span>Seguradora</span>
            </div>

            <div className="container-input-comum">
              <input
                type="text"
                // ref={apoliceSeguro}
                disabled
                defaultValue={transportadora?.asseguradoras[0]?.apolice || ""}
              />
              <span>Apólice seguro</span>
            </div>

            <div className="container-forma-pagamento">
              <p>Forma de Pagamento</p>

              <div className="input-radio-gerar-cte">
                <div className="container-input-radio">
                  <span
                    className={`radio ${isAPagar && "checked"}`}
                    onClick={() => setIsAPagar(true)}
                  >
                    <input
                      type="radio"
                      value={true}
                      checked={isAPagar === true}
                      onChange={() => setIsAPagar(true)}
                    />
                    <span className="checked"></span>A Pagar
                  </span>
                </div>

                <div className="container-input-radio">
                  <span
                    className={`radio ${!isAPagar && "checked"}`}
                    onClick={() => setIsAPagar(false)}
                  >
                    <input
                      type="radio"
                      value={false}
                      checked={isAPagar === false}
                      onChange={() => setIsAPagar(false)}
                    />
                    <span className="checked"></span>
                    Pago
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-formulario">
          <p className="titulo-formulario">Duplicatas a Receber</p>

          <div className="container-inputs-3col">
            <div className="container-input-comum">
              <select
                required
                value={quantidadeParcelas}
                onChange={(e) => {
                  setDuplicatas([]);
                  setQuantidadeParcelas(e.target.value);
                }}
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              <span>Quantidade de Parcelas</span>
            </div>

            <div className="container-input-comum">
              <input
                required
                type="number"
                value={periodo}
                onChange={(e) => setPeriodo(e.target.value)}
              />
              <span>Período</span>
            </div>
          </div>

          {duplicatas.map((_, index) => (
            <div key={index} className="container-inputs-3col">
              <div className="container-input-comum">
                <input
                  required
                  type="number"
                  defaultValue={duplicatas[index]?.numero}
                  disabled
                />
                <span>Parcela</span>
              </div>

              <div className="container-input-comum">
                <input
                  type="date"
                  value={duplicatas[index]?.data_vencimento}
                  onChange={(e) =>
                    editarDuplicatas(e.target.value, index, "data_vencimento")
                  }
                />
                <span>Vencimento</span>
              </div>

              <div className="container-input-comum">
                <NumberFormat
                  required
                  value={duplicatas[index]?.valor}
                  isNumericString={true}
                  thousandSeparator="."
                  decimalSeparator=","
                  disabled
                />
                <span>Valor</span>
              </div>
            </div>
          ))}
        </div>

        {tipoCte === "1" && (
          <div className="container-formulario">
            <p className="titulo-formulario">CT-e Complementar</p>

            <div className="container-inputs-3col">
              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  value={chaveCteOriginal}
                  onChange={(e) => setChaveCteOriginal(e.target.value)}
                />
                <span>Chave CT-e Original</span>
              </div>
            </div>
          </div>
        )}

        {tipoCte === "3" && (
          <div className="container-formulario">
            <p className="titulo-formulario">CT-e de Substituição</p>

            <div className="container-inputs-3col">
              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  value={chaveCteOriginal}
                  onChange={(e) => setChaveCteOriginal(e.target.value)}
                />
                <span>Chave CT-e Original</span>
              </div>

              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  value={chaveCteAnulacao}
                  onChange={(e) => setChaveCteAnulacao(e.target.value)}
                />
                <span>Chave CT-e Anulação</span>
              </div>

              <div className="container-input-checkbox">
                <span
                  className="checkbox"
                  onClick={() => {
                    setMudaTomador((prev) => !prev);
                  }}
                >
                  <input
                    type="checkbox"
                    checked={mudaTomador}
                    onChange={(e) => setMudaTomador(e.target.checked)}
                  />
                  <span className="checked"></span>
                  Houve Alteração de Tomador?
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="container-formulario">
          <p className="titulo-formulario">Observações</p>

          <div className="container-inputs-3col">
            <div className="container-input-comum">
              <input
                type="text"
                value={nomeMotorista}
                onChange={(e) => setNomeMotorista(e.target.value)}
              />
              <span>Nome Motorista</span>
            </div>

            <div className="container-input-comum">
              <input
                type="text"
                value={placaVeiculo}
                onChange={(e) => setPlacaVeiculo(e.target.value)}
              />
              <span>Placa Veículo</span>
            </div>

            <div className="container-input-comum">
              <InputMask
                mask="99.999.999/9999-99"
                value={vendedor}
                onChange={(e) => setVendedor(e.target.value)}
              />
              <span>Vendedor</span>
            </div>
          </div>

          <div className="container-inputs-2col">
            <div className="container-input-comum">
              <input
                // required
                type="text"
                value={observacoesGerais}
                onChange={(e) => setObservacoesGerais(e.target.value)}
              />
              <span>Observações Gerais</span>
            </div>
            <div className="container-input-comum">
              <input
                required
                type="text"
                value={produtoPredominante}
                onChange={(e) => setProdutoPredominante(e.target.value)}
              />
              <span>Produto Predominante</span>
            </div>
          </div>
        </div>

        <div className="container-botoes">
          <button className="btn cancelar" onClick={onClose}>
            CANCELAR
          </button>

          <button
            type="submit"
            className="btn salvar"
            disabled={loadingEnvio}
            onClick={() => setAcaoBotao("Salvar")}
          >
            SALVAR
          </button>

          <button
            type="submit"
            className="btn enviar"
            disabled={loadingEnvio}
            onClick={() => setAcaoBotao("Enviar")}
          >
            ENVIAR CT-e PARA SEFAZ
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalGerarCteAvulso;
