import React, { useEffect, memo, useRef, useState, useCallback } from "react";
import { RiCloseCircleFill, RiFileEditLine } from "react-icons/ri";
import { MdOutlineLayersClear, MdOutlineLibraryAdd } from "react-icons/md";
import { HiOutlineDocumentAdd } from "react-icons/hi";
import Modal from "react-modal";
import { parseISO, format } from "date-fns";
import { saveAs } from "file-saver";
import { confirmAlert } from "react-confirm-alert";
import ModalAnularCte from "./ModalAnularCte";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./styles.css";

import ModalCartaCorrecao from "../../../../../../../components/plataformaAcessada/ModalCartaCorrecao";

// import docEdit from "../../../../../../../assets/docEdit.png"
import cancel from "../../../../../../../assets/cancel.png";
import refresh from "../../../../../../../assets/refresh.png";
import pdfIcon from "../../../../../../../assets/pdf.png";
import xmlIcon from "../../../../../../../assets/xml.png";

import api from "../../../../../../../services/api";

const ModalDadosCte = ({
  idCteSelecionado,
  modalDadosCte,
  handleModalDadosCte,
  requisitarDocumentos,
  adicionarCtesParaNfes,
  handleGerarCteComplementar,
}) => {
  const [inputDesabilitado, setInputDesabilitado] = useState(true);
  const [cte, setCte] = useState(null);

  const chaveCte = useRef("");
  const serie = useRef("");
  const emissao = useRef("");
  const remetente = useRef("");
  const destinatario = useRef("");
  // const consignatario = useRef('')
  const valor = useRef("");
  const numeroAverbacao = useRef("");

  const chaveAcessoNfe = useRef("");
  const numeroRecebido = useRef("");
  const dataHoraRecebido = useRef("");
  const status = useRef("");
  // const isAverbadoRecebido = useRef('')

  const justificativaCancelarCTe = useRef("");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalCartaCorrecao, setModalCartaCorrecao] = useState(false);

  const handleModalCartaCorrecao = useCallback(() => {
    if (modalCartaCorrecao) {
      setModalCartaCorrecao(false);
      document.querySelector("body").style.overflow = "visible";
    } else {
      setModalCartaCorrecao(true);
      document.querySelector("body").style.overflow = "hidden";
    }
  }, [modalCartaCorrecao]);

  const cancelarCte = (e, idCte, onClose) => {
    e.preventDefault();

    api
      .post(`documentos_fiscais/cte/${idCte}/cancelar/`, {
        justificativa: justificativaCancelarCTe.current.value,
      })
      .then(function () {
        requisitarDocumentos();
        onClose();
        handleModalDadosCte();
      })
      .catch(function (error) {
        confirmAlert({
          title: "Erro!",
          message: `Erro ao cancelar CTe: ${error.response?.data?.detail}`,
          overlayClassName: "alert-remover-nfe",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      });
  };

  const sincronizarDadosCte = (idCte) => {
    api
      .post(`documentos_fiscais/cte/${idCte}/resolver/`)
      .then(function () {
        confirmAlert({
          title: "Enviado para Sincronizar!",
          message: 'O CT-e foi enviado para sincronizar. Em alguns instantes a tabela deve atualizar.',
          overlayClassName: "alert-remover-nfe",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                requisitarDocumentos();
                handleModalDadosCte();
              }
            },
          ],
        });
      })
      .catch(function (error) {
        confirmAlert({
          title: "Erro!",
          message: `Erro ao resolver CTe: ${error.response?.data?.detail}`,
          overlayClassName: "alert-remover-nfe",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      });
  }

  const alertCancelarCte = (idCte, numero) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <form
            className="container-remover-documento"
            onSubmit={(e) => cancelarCte(e, idCte, onClose)}
          >
            <p className="titulo">
              Você deseja realmente <span className="cancelar">cancelar</span> o
              CTe de número {numero} do sistema?
            </p>

            <div className="justificativa">
              <textarea
                required
                type="text"
                placeholder="Preencha a justificativa... (Mínimo 15 caracteres)"
                rows={5}
                minLength="15"
                ref={justificativaCancelarCTe}
              />
            </div>

            <div className="botoes">
              <button className="cancelar" onClick={onClose}>
                Não
              </button>
              <button className="confirmar" type="submit">
                Sim
              </button>
            </div>
          </form>
        );
      },
    });
  };

  const salvarAlteracoes = () => {
    const dados = {
      remetente_razao_social: remetente.current.value,
      destinatario_razao_social: destinatario.current.value,
      valor_cte: valor.current.value,
    };

    api
      .patch(`documentos_fiscais/cte/${idCteSelecionado}/`, dados)
      .then(function () {
        setInputDesabilitado(true);
        requisitarDocumentos();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    api
      .get(`documentos_fiscais/cte/${idCteSelecionado}/`)
      .then(function (response) {
        let documento = response.data;

        setCte(documento);
        chaveCte.current.value = documento.chave;
        serie.current.value = documento.serie;
        emissao.current.value = documento.emissao
          ? format(parseISO(documento.emissao), "dd/MM/yyyy HH:mm")
          : "";
        remetente.current.value = documento.remetente_razao_social;
        destinatario.current.value = documento.destinatario_razao_social;
        valor.current.value = documento.valor_cte;
        numeroAverbacao.current.value = documento.numero_averbacao;
        chaveAcessoNfe.current.value =
          documento.notas_transportadas[0].nfe.chave;
        numeroRecebido.current.value =
          documento.notas_transportadas[0].nfe.numero;
        status.current.value = documento.status;
        dataHoraRecebido.current.value = format(
          parseISO(documento.notas_transportadas[0].nfe.emissao),
          "dd/MM/yyyy HH:mm"
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idCteSelecionado]);

  const complementarCte = () => {
    if (!cte?.notas_transportadas.length) {
      return;
    }
    const nfe = cte?.notas_transportadas.map((item) => item.nfe)[0];
    const idCte = cte?.id;
    handleGerarCteComplementar(nfe, idCte);
  };

  return (
    <>
      <Modal
        isOpen={modalDadosCte}
        onRequestClose={handleModalDadosCte}
        className="modal-dados-cte"
        overlayClassName="overlay-modal-dados-cte"
        ariaHideApp={false}
      >
        <div className="dados-cte">
          <RiCloseCircleFill
            className="icone-fechar"
            onClick={handleModalDadosCte}
          ></RiCloseCircleFill>

          <p className="titulo">Dados da CT-e</p>

          <div className="container-acoes">
            {/*<RiFileEditLine
              onClick={handleModalCartaCorrecao}
              title="Adicionar carta de correção"
              size="36"
              style={{ cursor: "pointer", marginRight: 10 }}
            />*/}

            {!!handleGerarCteComplementar && (
              <MdOutlineLibraryAdd
                onClick={complementarCte}
                title="Complementar CT-e"
                size="36"
                style={{ cursor: "pointer", marginRight: 10 }}
              />
            )}

            {!!adicionarCtesParaNfes && (
              <HiOutlineDocumentAdd
                onClick={() =>
                  adicionarCtesParaNfes(
                    (cte?.notas_transportadas || []).map((item) => item.nfe)
                  )
                }
                title="Emitir Outro CT-e para mesmas NF-e's"
                size="36"
                style={{ cursor: "pointer", marginRight: 10 }}
              />
            )}

            {cte?.status === "Confirmado" &&
              cte?.origem === "emitido" &&
              cte?.tipo !== "Anulação" &&
              cte?.tipo !== "Substituicao" &&
              !cte?.cte_anulacao &&
              !cte?.cte_substituto && (
                <MdOutlineLayersClear
                  onClick={() => setModalIsOpen(true)}
                  title="Anular CT-e"
                  size="36"
                  style={{ cursor: "pointer", marginRight: 10 }}
                />
              )}

            {cte?.status === "Confirmado" &&
              cte?.origem === "emitido" &&
              cte?.justificativa_cancelamento === "" &&
              cte?.tipo !== "Anulação" &&
              cte?.tipo !== "Substituicao" &&
              !cte?.cte_anulado &&
              !cte?.cte_substituto && (
                <img
                  src={cancel}
                  className="cancelar"
                  alt="cancelar"
                  title="Cancelar CT-e"
                  onClick={() => alertCancelarCte(cte?.id, cte?.numero)}
                />
              )}

            <img
              src={refresh}
              alt="recarregar"
              title="Sincronizar dados do CT-e"
              onClick={() => sincronizarDadosCte(cte?.id)}
            />

            {cte?.arquivo && (
              <img
                src={xmlIcon}
                alt="xml"
                title="Baixar XML do CT-e"
                onClick={() => saveAs(cte.arquivo, `CT-e${cte.numero}.xml`)}
              />
            )}
            {cte?.dacte && (
              <img
                src={pdfIcon}
                alt="pdf"
                title="Baixar Documento Auxiliar de CT-e"
                onClick={() => saveAs(cte.dacte, `CT-e${cte.numero}.pdf`)}
              />
            )}
          </div>

          <div className="container-formulario">
            <div className="container-inputs-3col">
              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  ref={chaveCte}
                  disabled={inputDesabilitado}
                  defaultValue="Dado"
                />
                <span>CT-e</span>
              </div>

              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  ref={serie}
                  disabled={inputDesabilitado}
                  defaultValue="Dado"
                />
                <span>Série</span>
              </div>

              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  ref={emissao}
                  disabled={inputDesabilitado}
                />
                <span>Emissão</span>
              </div>
            </div>

            <div className="container-inputs-3col">
              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  ref={remetente}
                  disabled={inputDesabilitado}
                />
                <span>Remetente</span>
              </div>

              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  ref={destinatario}
                  disabled={inputDesabilitado}
                />
                <span>Destinatário</span>
              </div>

              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  ref={valor}
                  disabled={inputDesabilitado}
                />
                <span>Valor</span>
              </div>
            </div>

            <div className="container-inputs-3col">
              <div className="container-input-comum">
                <input required type="text" ref={numeroAverbacao} disabled />
                <span>Número de Averbação</span>
              </div>
            </div>
          </div>

          <div className="container-formulario">
            <div className="container-inputs-2col">
              <div className="container-input-comum">
                <input required type="text" ref={chaveAcessoNfe} disabled />
                <span>Chave de Acesso NF-e</span>
              </div>

              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  ref={numeroRecebido}
                  disabled={inputDesabilitado}
                  defaultValue="Dado"
                />
                <span>Número do recebido</span>
              </div>
            </div>

            <div className="container-inputs-3col">
              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  ref={dataHoraRecebido}
                  disabled={inputDesabilitado}
                  defaultValue="Dado"
                />
                <span>Data e hora de envio</span>
              </div>

              <div className="container-input-comum">
                <input
                  required
                  type="text"
                  ref={status}
                  disabled={inputDesabilitado}
                  defaultValue="Dado"
                />
                <span>Status</span>
              </div>
              {/* 
            <div className="container-input-comum">
              <input
                required
                type="text"
                ref={isAverbadoRecebido}
                disabled={inputDesabilitado}
                defaultValue="Dado"
              />
              <span>Averbado?</span>
            </div> */}
            </div>
          </div>

          {!inputDesabilitado && (
            <button className="btn-salvar" onClick={salvarAlteracoes}>
              Salvar
            </button>
          )}
        </div>
      </Modal>
      <ModalAnularCte
        id={cte?.id}
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        handleModalDadosCte={handleModalDadosCte}
        requisitarDocumentos={requisitarDocumentos}
      />

      {modalCartaCorrecao && (
        <ModalCartaCorrecao
          modalCartaCorrecao={modalCartaCorrecao}
          handleModalCartaCorrecao={handleModalCartaCorrecao}
        />
      )}
    </>
  );
};

export default memo(ModalDadosCte);
