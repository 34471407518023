import { useRouteMatch } from 'react-router-dom';
import {
  Input,
  Checkbox,
  FormSectionTitle,
  FourCols,
} from '../../../../../../components/forms';

const TabelaGeneralidades = () => {
  const match = useRouteMatch();
  const isReadOnly = match.path === '/acesso/clientes/tabelas/visualizar/:id' ? true : false;

  return (
    <>
      <FormSectionTitle>Generalidades</FormSectionTitle>

      <FourCols>
        <Input
          type="number"
          disabled={isReadOnly}
          name="generalidades.frete_minimo"
          id="generalidades.frete_minimo"
          label="Frete Mínimo"
        />
      </FourCols>

      <FourCols>
        <Input
          type="number"
          name="generalidades.gris"
          disabled={isReadOnly}
          label="GRIS (%)"
        />
        <Input
          type="number"
          name="generalidades.gris_minimo"
          id="generalidades.gris_minimo"
          disabled={isReadOnly}
          label="GRIS Mínimo"
        />
        <Input
          type="number"
          disabled={isReadOnly}
          name="generalidades.seguro"
          id="generalidades.seguro"
          label="Seguro (%)"
        />
        <Input
          type="number"
          disabled={isReadOnly}
          name="generalidades.despacho"
          id="generalidades.despacho"
          label="Despacho"
        />
        <Input
          type="number"
          name="generalidades.tde"
          id="generalidades.tde"
          disabled={isReadOnly}
          label="TDE (%)"
        />
        <Input
          type="number"
          name="generalidades.reentrega"
          id="generalidades.reentrega"
          disabled={isReadOnly}
          label="Reentrega (%)"
        />
        <Input
          type="number"
          name="generalidades.devolucao"
          id="generalidades.devolucao"
          disabled={isReadOnly}
          label="Devolução (%)"
        />
        <Input
          type="number"
          disabled={isReadOnly}
          name="generalidades.outros"
          id="generalidades.outros"
          label="Outros Serviços (%)"
        />
      </FourCols>

      <FormSectionTitle small>Pedágio</FormSectionTitle>

      <FourCols>
        <Input
          type="number"
          disabled={isReadOnly}
          name="generalidades.pedagio"
          id="generalidades.pedagio"
          label="Pedágio"
        />
        <Input
          type="number"
          disabled={isReadOnly}
          name="generalidades.pedagio_fracao_kg"
          id="generalidades.pedagio_fracao_kg"
          label="A cada kg"
        />
      </FourCols>

      <FormSectionTitle small>SEC/CAT (Taxa Destino)</FormSectionTitle>

      <FourCols>
        <Input
          type="number"
          disabled={isReadOnly}
          name="generalidades.sec_cat"
          id="generalidades.sec_cat"
          label="SEC/CAT (Taxa Destino)"
        />
        <Input
          type="number"
          disabled={isReadOnly}
          name="generalidades.sec_cat_limite"
          id="generalidades.sec_cat_limite"
          label="Até kg"
        />
        <Input
          type="number"
          disabled={isReadOnly}
          name="generalidades.sec_cat_excedente"
          id="generalidades.sec_cat_excedente"
          label="Excedente por Kg"
        />
      </FourCols>

      <FormSectionTitle small>Taxa Fixa (Taxa Origem)</FormSectionTitle>

      <FourCols>
        <Input
          type="number"
          disabled={isReadOnly}
          name="generalidades.taxa_fixa"
          id="generalidades.taxa_fixa"
          label="Taxa Fixa (Taxa Origem)"
        />
        <Input
          type="number"
          disabled={isReadOnly}
          name="generalidades.taxa_fixa_limite"
          id="generalidades.taxa_fixa_limite"
          label="Até Kg"
        />
        <Input
          type="number"
          disabled={isReadOnly}
          name="generalidades.taxa_fixa_excedente"
          id="generalidades.taxa_fixa_excedente"
          label="Excedente por Kg"
        />
        <Input
          type="number"
          disabled={isReadOnly}
          name="generalidades.taxa_fixa_cobrar_a_partir"
          id="generalidades.taxa_fixa_cobrar_a_partir"
          label="Cobrar a partir da montagem de X entregas"
        />
      </FourCols>

      <FormSectionTitle small>Desconsiderar no cálculo do frete minimo</FormSectionTitle>

      <div className="grid">
        <div className="row">
          <Checkbox
            name="generalidades.desconsiderar_gris"
            label="GRIS"
            disabled={isReadOnly}
          />
        </div>
        <div className="row">
          <Checkbox
            name="generalidades.desconsiderar_seguro"
            label="Seguro"
            disabled={isReadOnly}
          />
        </div>
        <div className="row">
          <Checkbox
            name="generalidades.desconsiderar_despacho"
            label="Despacho"
            disabled={isReadOnly}
          />
        </div>
        <div className="row">
          <Checkbox
            name="generalidades.desconsiderar_outros"
            label="Outros (%)"
            disabled={isReadOnly}
          />
        </div>
        <div className="row">
          <Checkbox
            name="generalidades.desconsiderar_pedagio"
            label="Pedágio"
            disabled={isReadOnly}
          />
        </div>
        <div className="row">
          <Checkbox
            name="generalidades.desconsiderar_taxa_fixa"
            label="Taxa fixa"
            disabled={isReadOnly}
          />
        </div>
        <div className="row">
          <Checkbox
            name="generalidades.desconsiderar_sec_cat"
            label="SEC/CAT"
            disabled={isReadOnly}
          />
        </div>
      </div>

      <FormSectionTitle small>Outras Informações</FormSectionTitle>

      <div className="grid">
        <div className="row">
          <Checkbox
            name="generalidades.incluir_icms"
            label="Incluir ICMS"
            disabled={isReadOnly}
          />
        </div>
        <div className="row">
          <Checkbox
            name="generalidades.incluir_pis_cofins"
            label="Incluir PIS/CONFIS/IR/CSSL"
            disabled={isReadOnly}
          />
        </div>
        <div className="row">
          <Checkbox
            name="generalidades.desconsiderar_icms_pis_frete_minimo"
            label="Desconsiderar inclusão de ICMS e Impostos Federais sobre o total da prestação em caso de frete mínimo."
            disabled={isReadOnly}
          />
        </div>
      </div>

      <FourCols>
        <Input
          type="number"
          name="generalidades.prazo_entrega"
          id="generalidades.prazo_entrega"
          disabled={isReadOnly}
          label="Prazo de Entrega (dias corridos)"
        />
      </FourCols>
    </>
  )
}

export default TabelaGeneralidades;