import { useState, useRef } from 'react'
import InputMask from 'react-input-mask'

import './styles.css'

import api from "../../../services/api"

const InputSearchCnpj = ({ tipoCnpj, suggestions, value, saveValue, placeholder }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([])
  // const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0)
  const [showSuggestions, setShowSuggestions] = useState(false)

  const list = useRef(null)

  const removerMascaraCampo = (dado) => {
    return dado.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '').replaceAll('.', '').replaceAll('/', '')
  }

  const salvarCNPJ = () => {
    api.post("documentos_fiscais/valepedagio/", {
      cnpj_fornecedor: tipoCnpj === "fornecedor" ? removerMascaraCampo(value) : '',
      cnpj_responsavel: tipoCnpj === "responsavel" ? removerMascaraCampo(value) : ''
    })
      .then(function () {
        setFilteredSuggestions([])
        setShowSuggestions(false)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const onChange = (e) => {
    const userInput = e.target.value

    const unLinked = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(removerMascaraCampo(userInput).toLowerCase()) > -1
    )

    saveValue(e.target.value)
    setFilteredSuggestions(unLinked)
    setShowSuggestions(true)
  }

  const onClick = (e) => {
    setFilteredSuggestions([])
    // setActiveSuggestionIndex(0)
    setShowSuggestions(false)
    saveValue(e.target.innerText)
  }

  // const onKeyDown = (e) => {
  //   if (e.keyCode === 13 || e.keyCode === 9) {
  //     if (filteredSuggestions.length === 0) {
  //       setFilteredSuggestions([])
  //       setShowSuggestions(false)
  //     } else {
  //       onClick(e, filteredSuggestions[activeSuggestionIndex])
  //     }
  //   } else if (e.keyCode === 38) {
  //     if (activeSuggestionIndex === 0) {
  //       return
  //     }

  //     setActiveSuggestionIndex(activeSuggestionIndex - 1)
  //   }
  //   else if (e.keyCode === 40) {
  //     if (activeSuggestionIndex === filteredSuggestions.length - 1) {
  //       return
  //     }

  //     setActiveSuggestionIndex(activeSuggestionIndex + 1)
  //   }
  // }

  // const showActiveSuggestion = useCallback((index) => {
  //   if (index <= filteredSuggestions.length - 1) {
  //     const parentRect = list.current.getBoundingClientRect()
  //     const child = list.current.querySelectorAll('li')[index]
  //     const childRect = child.getBoundingClientRect()

  //     const yStart = (childRect.top - parentRect.top) + list.current.scrollTop

  //     if (yStart >= list.current.scrollTop
  //       && yStart < (list.current.scrollTop + parentRect.height)) {
  //       return
  //     }

  //     list.current.scrollTop = yStart
  //   }
  // }, [filteredSuggestions])

  // useEffect(() => {
  //   if (list.current) {
  //     showActiveSuggestion(activeSuggestionIndex)
  //   }
  // }, [activeSuggestionIndex, showActiveSuggestion])

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <ul className="suggestions" ref={list}>
        {filteredSuggestions.map((suggestion) => {
          // let className = ""

          // if (index === activeSuggestionIndex) {
          //   className = "suggestion-active"
          // }

          return (
            <li
              // className={className}
              key={suggestion}
              onClick={(e) => onClick(e, suggestion)}
            >
              {suggestion}
            </li>
          )
        })}
      </ul>
    ) : (
      <div className="no-suggestions">
        <em><span onClick={salvarCNPJ}> Salvar CNPJ</span></em>
      </div>
    )
  }

  return (
    <div className="container-input-search-cnpj">
      <div className="container-input-comum">

        <InputMask
          mask="99.999.999/9999-99"
          maskChar=""
          required
          type="text"
          onChange={onChange}
          // onKeyDown={onKeyDown}
          value={value}
        ></InputMask>

        {/* <input
          required
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={value}
        /> */}
        <span>{placeholder}</span>
      </div>
      {showSuggestions && value && <SuggestionsListComponent />}
    </div>
  )
}

export default InputSearchCnpj