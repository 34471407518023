import { useState } from "react";
import { Modal } from "../modal-base";
import { Suspense } from "../../components/utils";
import { Button } from "../../components/common";
import { Tabelas } from './tabelas';
import './modal-copiar-tabela-frete.scss';

export const ModalCopiarTabelaFrete = ({ isOpen, onClose, onConfirmCopy }) => {
  const [page, setPage] = useState(1);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Copiar Tabela de Outro Cliente"
    >
      <Suspense>
        <Tabelas
          page={page}
          setPage={setPage}
          onConfirmCopy={onConfirmCopy}
        />
      </Suspense>

      <div className="modal-footer">
        <Button variant="saveBack" small type="button" onClick={onClose}>Fechar</Button>
      </div>
    </Modal>
  );
};
