import { useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { MdClear } from 'react-icons/md';
import './select-filtro.scss';

export const SelectFiltro = ({
  icon,
  placeholder,
  parameter,
  options,
  value: rawValue,
  setValue: rawSetValue,
}) => {
  const { search, pathname, hash } = useLocation();
  const history = useHistory();
  const [value, setValue] = useState(() => {
    if (!parameter) {
      return rawValue;
    }
    const searchParameters = new URLSearchParams(search);
    if (!searchParameters.get(parameter)) {
      return '';
    }
    return searchParameters.get(parameter);
  });

  const handleUpdate = (text) => {
    setValue(text);
  }

  const handleUpdateFilter = useCallback((value) => {
    if (!parameter) {
      return;
    }
    const searchParameters = new URLSearchParams(search);
    searchParameters.set(parameter, value);

    history.push({
      pathname,
      search: searchParameters.toString(),
      hash,
    });
  }, [history, search, pathname, hash, parameter]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      handleUpdateFilter(value);
      
      if (!parameter) {
        rawSetValue(value);
      }
    }, 500);

    return () => {
      clearTimeout(debounce);
    }
  }, [value, rawSetValue, parameter]);

  const handleClear = () => {
    handleUpdate('');
  }
  
  return (
    <div className="select-filtro">
      {icon}
      <select
        value={value}
        onChange={(e) => handleUpdate(e.target.value)}
      >
        <option value="">{placeholder}</option>
        {options.map((item) => (
          <option value={item.value} key={item.value}>{item.label}</option>
        ))}
      </select>
      <MdClear onClick={handleClear} className="icone-limpar" />
    </div>
  );
};
