import React, { memo, useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ImSearch } from 'react-icons/im'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import TabelaListarMotoristas from '../../../../components/plataformaAcessada/TabelaListarMotoristas'
import api from "../../../../services/api"

const MotoristasFavoritos = () => {
  const history = useHistory()

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/motorista', breadcrumb: 'Motorista' },
    { path: '/acesso/motorista/motoristas-favoritos', breadcrumb: 'Motoristas Favoritos' },
  ]

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const [searchMotorista, setSearchMotorista] = useState('')
  const [motoristas, setMotoristas] = useState([])

  const requisitarMotoristas = useCallback(() => {
    setMotoristas([]);

    api.get(`motoristafavorito/?search=${searchMotorista}&transportadora=${dadosUsuario.transportadora_id}`)
      .then(response => {
        let ListaMotoristas = response.data.results;
        let auxListaMotoristas = [];

        ListaMotoristas.forEach(item => {
          auxListaMotoristas.push(item.motorista);
        });

        setMotoristas(auxListaMotoristas);
      })
      .catch(error => {
        console.log(error);
      });
  }, [dadosUsuario.transportadora_id, searchMotorista]);

  const verificarTeclaSearch = (e) => {
    if (e.keyCode === 13) {
      requisitarMotoristas()
    }
  }

  useEffect(() => {
    if (searchMotorista === '') {
      forceUpdate()
      requisitarMotoristas()
    }
  }, [requisitarMotoristas, forceUpdate, searchMotorista])

  return (
    <div className="container-motoristas-favoritos">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="motoristas-favoritos">
        <div className="container-topo">
          <div>
            <p className="titulo-pagina">Motoristas Favoritos</p>
            <p className="subtitulo-pagina">Realize o filtro para busca do motorista desejado</p>
          </div>
        </div>

        <div className="container-search">
          <div className="input-search motorista">
            <input
              type="text"
              placeholder="Buscar Motorista (Razão Social; CNPJ)"
              value={searchMotorista}
              onChange={(e) => setSearchMotorista(e.target.value)}
              onKeyDown={verificarTeclaSearch}
            />
            <ImSearch className="icone"></ImSearch>
          </div>

          <button className="btn-buscar" onClick={requisitarMotoristas}>Buscar</button>
        </div>

        <TabelaListarMotoristas motoristas={motoristas} isPublico={true}></TabelaListarMotoristas>
      </div>
    </div>
  )
}

export default memo(MotoristasFavoritos)