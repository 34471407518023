import React, { memo, useEffect, useState, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FaUserPlus } from 'react-icons/fa'
import { ImSearch } from 'react-icons/im'
import { FaTrashAlt, FaEdit, FaIdCard } from 'react-icons/fa'
import ReactPaginate from 'react-paginate'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import './styles.css'

import BreadCrumbs from '../../../../components/plataformaAcessada/BreadCrumbs'
import api from "../../../../services/api"

const GruposMotoristas = () => {
  const history = useHistory()

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))

  const routes = [
    { path: '/acesso', breadcrumb: 'Início' },
    { path: '/acesso/motorista', breadcrumb: 'Motorista' },
    { path: '/acesso/motorista/grupos-motoristas', breadcrumb: 'Grupos de Motoristas' },
  ]

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const [searchGrupo, setSearchGrupo] = useState('')
  const [grupos, setGrupos] = useState([])
  const [quantidadePaginas, setQuantidadePaginas] = useState(1)
  const [paginaAtual, setPaginaAtual] = useState(0)

  const requisitarGrupos = useCallback((paginaSelecionada, offSet) => {
    setPaginaAtual(paginaSelecionada)

    api.get(`grupos/?transportadora=${dadosUsuario.transportadora_id}&?offset=${offSet ? offSet : 0}`)
      .then(function (response) {
        setQuantidadePaginas(Math.ceil(response.data.count / 30))
        setGrupos(response.data.results)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [dadosUsuario.transportadora_id])

  const filtrarItens = () => {
    setQuantidadePaginas(1)
    setGrupos([])
    setPaginaAtual(0)

    api.get(`/grupos/filtro_grupos_transportadora/?transportadora=${dadosUsuario.transportadora_id}&descricao=${searchGrupo}`)
      .then(function (response) {
        setGrupos(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const verificarTeclaSearch = (e) => {
    if (e.keyCode === 13) {
      filtrarItens()
    }
  }

  const removerGrupo = (nome, id) => {
    confirmAlert({
      title: "Remover Grupo",
      message: `Deseja realmente remover o grupo de motoristas "${nome}" do sistema?`,
      overlayClassName: "alert-remover-grupo-motoristas",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            api.delete(`grupos/${id}/`)
              .then(function () {
                requisitarGrupos()
              })
              .catch(function (error) {
                console.log(error)
              })
          },
          className: "botao-confirmar"
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar"
        }
      ]
    })
  }

  useEffect(() => {
    if (searchGrupo === '') {
      forceUpdate()
      requisitarGrupos()
    }
  }, [requisitarGrupos, forceUpdate, searchGrupo])

  return (
    <div className="container-grupos-motoristas">
      <BreadCrumbs routes={routes}></BreadCrumbs>

      <button className="btn-voltar-pagina" onClick={() => history.goBack()}>Voltar</button>

      <div className="grupos-motoristas">
        <div className="container-topo">
          <div>
            <p className="titulo-pagina">Grupos de Motoristas</p>
            <p className="subtitulo-pagina">Realize o filtro para busca do grupo desejado</p>
          </div>

          <button
            className="btn-novo-grupo"
            onClick={() => history.push("/acesso/motorista/grupos-motoristas/novo-grupo")}
          >
            <FaUserPlus className="icone"></FaUserPlus>
            Novo Grupo
          </button>
        </div>

        <div className="container-search">
          <div className="input-search motorista">
            <input
              type="text"
              placeholder="Buscar Grupo (Nome)"
              value={searchGrupo}
              onChange={(e) => setSearchGrupo(e.target.value)}
              onKeyDown={verificarTeclaSearch}
            />
            <ImSearch className="icone"></ImSearch>
          </div>

          <button className="btn-buscar" onClick={filtrarItens}>Buscar</button>
        </div>

        <div className="container-tabela-grupos-motoristas">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Quantidade Motoristas</th>
                <th>Ações</th>
              </tr>
            </thead>

            <tbody>
              {grupos.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr className="divisoria-linhas-tabela"></tr>
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td className="coluna-nome">{item.descricao}</td>
                      <td>{item.motoristas}</td>

                      <td className="coluna-acoes">
                        <Link to={`/acesso/motorista/grupos-motoristas/gerenciar-motoristas/${item.id}`}>
                          <FaIdCard className="icone motorista"></FaIdCard>
                        </Link>
                        <Link to={`/acesso/motorista/grupos-motoristas/editar-grupo/${item.id}`}>
                          <FaEdit className="icone"></FaEdit>
                        </Link>
                        <FaTrashAlt className="icone" onClick={() => removerGrupo(item.descricao, item.id)}></FaTrashAlt>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="container-paginacao-grupos-motoristas">
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próxima"}
            breakLabel={"..."}
            pageCount={quantidadePaginas}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={paginaAtual}
            onPageChange={(e) => requisitarGrupos(e.selected, e.selected * 30)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(GruposMotoristas)