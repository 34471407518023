import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Login from '../pages/plataformaLogin'
import Loading from '../pages/plataformaLogin/Loading'

export default function PlataformaAcessada({ match }) {
  return (
    <Switch>
      <Route path={`${match.url}`} exact component={Login}></Route>
      <Route path={`${match.url}primeiro-acesso`} exact component={Login}></Route>
      <Route path={`${match.url}redefinir-senha`} exact component={Login}></Route>
      <Route path={`${match.url}loading`} exact component={Loading}></Route>
    </Switch>
  )
}