import React, { memo, useCallback, useState, useEffect } from "react";
import {
  BsBookmarkStarFill,
  BsBookmarkStar,
  BsFillStarFill,
} from "react-icons/bs";
import { HiOutlineCursorClick } from "react-icons/hi";
import { MdPhoneInTalk, MdCheck, MdOutlineClear } from "react-icons/md";
import { GiPathDistance, GiMoneyStack } from "react-icons/gi";
import { RiMedalFill } from "react-icons/ri";
import { FaRegClock, FaMapMarkedAlt } from "react-icons/fa";
import { FiTruck } from "react-icons/fi";
import { AiFillTag } from "react-icons/ai";
import { getDisplayErrors } from "../../../../../ModalPerfilMotoristaCarga/Financeiro/utilities";
import { parseISO, format } from "date-fns";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./styles.css";

import ModalPerfilMotorista from "../../ModalPerfilMotorista";
import api from "../../../../../../../../services/api";
import { useHistory } from "react-router-dom";

const CardsMotoristas = ({
  candidatura,
  index,
  atualizarCargas,
  modalPerfilMotorista,
  setModalPerfilMotorista,
  moverCarga,
  handleModalPropostaCarga,
}) => {
  const history = useHistory(); 
  const [idMotoristaFavorito, setIdMotoristaFavorito] = useState(null);

  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"));

  const handleModalPerfilMotorista = useCallback(() => {
    if (modalPerfilMotorista) {
      setModalPerfilMotorista(false);
      document.querySelector("body").style.overflow = "visible";
    } else {
      setModalPerfilMotorista(true);
      document.querySelector("body").style.overflow = "hidden";
    }
  }, [modalPerfilMotorista, setModalPerfilMotorista]);

  const reprovarProposta = (candidatura) => {
    const reprovar = (onClose) => {
      api
        .post(`/candidatura/${candidatura.id}/negar_candidatura/`)
        .then(function () {
          atualizarCargas();
          onClose();
          handleModalPropostaCarga();
        })
        .catch(function (error) {
          console.log(error.response);

          const { data } = error.response;
          const errors = getDisplayErrors(data);
          let erroCoins = false;

          {
            errors.forEach((item) => {
              if (item?.title === "Rastro Coins Insuficientes.") {
                erroCoins = true;
              }
            });
          }

          if (erroCoins) {
            erroCoins = false;

            confirmAlert({
              overlayClassName: "alert-sucesso-cte",
              customUI: ({ onClose }) => (
                <div className="react-confirm-alert-body errors-modal-body">
                  <h1>Rastro Coins Insuficientes</h1>
                  <div className="errors-modal-part">
                    <p>
                      Suas Rastro Coins acabaram. Realize a compra de mais
                      coins!
                    </p>
                  </div>
                  <div className="react-confirm-alert-button-group">
                    <button
                      className="botao-cancelar"
                      onClick={() => {
                        onClose();
                        history.push("/acesso/rastro-coins");
                      }}
                    >
                      Comprar Rastro Coins
                    </button>
                  </div>
                </div>
              ),
            });
          } else {
            confirmAlert({
              overlayClassName: "alert-sucesso-cte",
              customUI: ({ onClose }) => (
                <div className="react-confirm-alert-body errors-modal-body">
                  <h1>Houveram Alguns Erros</h1>
                  <div className="errors-modal-part">
                    {errors.map((item) => (
                      <div key={JSON.stringify(item)}>
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                      </div>
                    ))}
                  </div>
                  <div className="react-confirm-alert-button-group">
                    <button className="botao-cancelar" onClick={onClose}>
                      Ok
                    </button>
                  </div>
                </div>
              ),
            });
          }
        });
    };

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="container-alert-proposta">
            <p className="titulo">
              Você deseja realmente <span className="reprovar">reprovar</span> o
              motorista para a carga?
            </p>

            <div className="motorista">
              <img src={candidatura.motorista.imagem_perfil} alt="" />
              <p>{candidatura.motorista.nome_completo}</p>
            </div>

            <div className="botoes">
              <button className="cancelar" onClick={onClose}>
                Não
              </button>
              <button className="confirmar" onClick={() => reprovar(onClose)}>
                Sim
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const AlertAprovarProposta = ({ onClose, aprovar }) => {
    const [checkboxRealizarConsulta, setCheckboxRealizarConsulta] =
      useState(true);

    return (
      <div className="container-alert-proposta">
        {!candidatura.carga?.transportadora.transportadora_teste && (
          <div className="container-input-checkbox-consulta">
            <input
              type="checkbox"
              checked={checkboxRealizarConsulta}
              onChange={() =>
                setCheckboxRealizarConsulta(!checkboxRealizarConsulta)
              }
            />
            <p>
              Realizar{" "}
              {candidatura.gr_registro_valido ? "consulta" : "pesquisa"} no
              gerenciador de risco.
            </p>
          </div>
        )}

        <div className="container-conferir-documentos">
          <p>
            Deseja conferir os documentos do motorista?{" "}
            <span onClick={handleModalPerfilMotorista}>Clique aqui!</span>
          </p>
        </div>

        <div className="motorista">
          <img src={candidatura.motorista.imagem_perfil} alt="" />
          <p>{candidatura.motorista.nome_completo}</p>
        </div>

        <p className="titulo">
          Você deseja realmente <span className="aprovar">aprovar</span> o
          motorista para a carga?
        </p>

        <div className="botoes">
          <button className="cancelar" onClick={onClose}>
            Não
          </button>
          <button
            className="confirmar"
            onClick={() => aprovar(onClose, checkboxRealizarConsulta)}
          >
            Sim
          </button>
        </div>
      </div>
    );
  };

  const aprovarProposta = (candidatura) => {
    const aprovar = (onClose, checkboxRealizarConsulta) => {
      api
        .post(
          `/candidatura/${candidatura.id}/aprovar_candidatura/`,
          checkboxRealizarConsulta && { gr_pesquisa: true }
        )
        .then(function () {
          // atualizarCargas();
          moverCarga();
          onClose();
          handleModalPropostaCarga();
        })
        .catch(function (error) {
        });
    };

    confirmAlert({
      customUI: ({ onClose }) => (
        <AlertAprovarProposta
          onClose={onClose}
          aprovar={aprovar}
        ></AlertAprovarProposta>
      ),
    });
  };

  const handleMotoristaFavorito = () => {
    if (idMotoristaFavorito !== null) {
      api
        .delete(`motoristafavorito/${idMotoristaFavorito}/`)
        .then(function () {
          setIdMotoristaFavorito(null);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      api
        .post(`motoristafavorito/`, {
          transportadora: dadosUsuario.transportadora_id,
          motorista: candidatura.motorista.id,
        })
        .then(function (response) {
          setIdMotoristaFavorito(response.data.id);
        })
        .catch(function (error) {
          console.log(error.response.data);
        });
    }
  };

  useEffect(() => {
    api
      .get(
        `motoristafavorito/?transportadora=${dadosUsuario.transportadora_id}`
      )
      .then(function (response) {
        let dados = response.data.results;

        dados.forEach((item) => {
          if (item.motorista.id === candidatura.motorista.id) {
            setIdMotoristaFavorito(item.id);
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [dadosUsuario.transportadora_id, candidatura.motorista.id]);

  return (
    <div className="cards-motoristas">
      <div className="card">
        <div
          className={`tag-lateral ${candidatura.motorista.estatisticas?.nivel}`}
        >
          {idMotoristaFavorito !== null ? (
            <div
              className="container-favorito"
              onClick={handleMotoristaFavorito}
            >
              <BsBookmarkStarFill className="icone-estrela"></BsBookmarkStarFill>
              <div className="background"></div>
            </div>
          ) : (
            <BsBookmarkStar
              className="icone-estrela"
              onClick={handleMotoristaFavorito}
            ></BsBookmarkStar>
          )}

          <div className="posicao">
            <p>{index + 1}º</p>
          </div>

          <div className="tipo-motorista">
            <p>{candidatura.motorista.estatisticas?.nivel}</p>
          </div>
        </div>

        <div className="conteudo">
          <div className="linha-perfil" onClick={handleModalPerfilMotorista}>
            <img src={candidatura.motorista.imagem_perfil} alt="" />
            <p>{candidatura.motorista.nome_completo}</p>
            <HiOutlineCursorClick className="icone"></HiOutlineCursorClick>
          </div>

          <div className="linha">
            <p>
              <span>{candidatura.motorista.veiculo.tipo_veiculo.tipo}</span> -
              {candidatura.motorista.veiculo.carrocerias.map((item, index) =>
                index < candidatura.motorista.veiculo.carrocerias.length - 1
                  ? `${item.tipo.tipo}, `
                  : ` ${item.tipo.tipo}`
              )}
            </p>

            <div className="telefone">
              <MdPhoneInTalk className="icone"></MdPhoneInTalk>
              <p>{candidatura.motorista.telefone1}</p>
            </div>
          </div>

          <div className="linha">
            <div className="placas">
              <div className="placa">
                <p>{candidatura.motorista.veiculo.placa_cavalo}</p>
              </div>
              <div className="placa">
                {candidatura.motorista.veiculo.carrocerias.map((item) => (
                  <p key={item.id}>{item.placa}</p>
                ))}
              </div>
            </div>

            <p className="cpf">
              CPF:{" "}
              {candidatura.motorista.documentos.cpf
                .replace(/\D/g, "")
                .replace(/(\d{3})(\d)/, "$1.$2")
                .replace(/(\d{3})(\d)/, "$1.$2")
                .replace(/(\d{3})(\d{1,2})$/, "$1-$2")}
            </p>
          </div>

          {candidatura.motorista.tipo_cadastro === 'NoApp' && (
            <div className="linha">
              <p>Motorista Cadastrado por Transportadora. Algumas funções podem não estar disponíveis.</p>
            </div>
          )}

          <div className="linha">
            <div className="container-dado tag">
              <span tag-dado="Quantidade de cargas no ultimo ano">
                <GiPathDistance className="icone"></GiPathDistance>
                <p>{candidatura.motorista.estatisticas?.cargas_ultimo_ano}</p>
              </span>
            </div>
            <div className="container-dado tag">
              <span tag-dado="Avaliação">
                <BsFillStarFill className="icone estrela"></BsFillStarFill>
                <p>{candidatura.motorista.estatisticas?.avaliacao}</p>
              </span>
            </div>
            <div className="container-dado tag">
              <span
                className="tag-esquerda"
                tag-dado="Quantidade de cargas sem ocorrencia"
              >
                <RiMedalFill className="icone"></RiMedalFill>
                <p>
                  {Number(
                    candidatura.motorista.estatisticas
                      ?.porcentagem_sem_ocorrencia
                  ).toFixed(0)}
                  %
                </p>
              </span>
            </div>
            <div className="container-dado tag">
              <span
                className="tag-esquerda"
                tag-dado="Quantidade de cargas no tempo"
              >
                <FiTruck className="icone"></FiTruck>
                <p>
                  {Number(
                    candidatura.motorista.estatisticas?.porcentagem_no_tempo
                  ).toFixed(0)}
                  %
                </p>
              </span>
            </div>
          </div>

          <div className="linha valores">
            <div className="container-dado">
              <GiMoneyStack className="icone dinheiro"></GiMoneyStack>
              <p>
                {candidatura.contra_proposta === null
                  ? Number(candidatura.carga.proposta_inicial).toLocaleString(
                    "pt-br",
                    { style: "currency", currency: "BRL" }
                  )
                  : Number(candidatura.contra_proposta).toLocaleString(
                    "pt-br",
                    { style: "currency", currency: "BRL" }
                  )}
              </p>
              <span className="real">
                {candidatura.proposta_tipo === "tonelada"
                  ? "P/Tonelada"
                  : "Cheio"}
              </span>
            </div>
            <div className="container-dado tag">
              <span
                className="tag-esquerda"
                tag-dado="Diferença proposta ofertada com proposta inicial"
              >
                <AiFillTag className="contra_proposta_percentual diferenca"></AiFillTag>
                <p>{candidatura.contra_proposta_percentual}%</p>
              </span>
            </div>
            <div className="container-dado">
              <FaMapMarkedAlt className="icone"></FaMapMarkedAlt>

              <p className="distancia">
                {candidatura?.distancia_embarque
                  ? Math.round(
                    Number(candidatura.distancia_embarque / 1000)
                  ).toLocaleString("pt-BR")
                  : 0}{" "}
                Km de distância
              </p>
            </div>
          </div>

          <div className="linha data-botoes">
            <div className="data">
              <FaRegClock className="icone"></FaRegClock>
              <p>
                {format(
                  parseISO(candidatura.criado_em),
                  "'Enviado em' dd/MM/yy - 'às' HH:mm"
                )}
              </p>
            </div>
            <div className="botoes">
              <button
                className="recusar"
                onClick={() => reprovarProposta(candidatura)}
              >
                <MdOutlineClear className="icone"></MdOutlineClear>
              </button>
              <button
                className="aceitar"
                onClick={() => aprovarProposta(candidatura)}
              >
                <MdCheck className="icone"></MdCheck>
              </button>
            </div>
          </div>
        </div>
      </div>

      {modalPerfilMotorista && (
        <ModalPerfilMotorista
          idCandidatura={candidatura.id}
          motorista={candidatura.motorista}
          modalPerfilMotorista={modalPerfilMotorista}
          handleModalPerfilMotorista={handleModalPerfilMotorista}
          handleModalPropostaCarga={handleModalPropostaCarga}
          editable={true}
        ></ModalPerfilMotorista>
      )}
    </div>
  );
};

export default memo(CardsMotoristas);
