import React, { useEffect, useState } from 'react'
import { FaEdit } from 'react-icons/fa'
import { FiPlusCircle } from 'react-icons/fi'
import { confirmAlert } from 'react-confirm-alert'
import ModalNovaCarga from './modal';
import 'react-confirm-alert/src/react-confirm-alert.css'

import './styles.css'

import api from "../../../../../services/api"

const AdicionarCarga = ({ idCarga, atualizarCargas, fecharModalDadosCarga }) => {
  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))
  const tipoUsuario = dadosUsuario.user_type

  const [abrirModalNovaCarga, setAbrirModalNovaCarga] = useState(false)
  const [destinatarios, setDestinatarios] = useState([])

  const abrirModal = () => {
    setAbrirModalNovaCarga(true);
    document.querySelector("body").style.overflow = "hidden"
  }

  const fecharModalSemAlert = () => {
    setAbrirModalNovaCarga(false)
    document.querySelector("body").style.overflow = "visible"
  }

  const fecharModal = () => {
    confirmAlert({
      title: "Sair do Formulário",
      message: idCarga
        ? `Deseja realmente sair da alteração de carga? Todos os dados não salvos, serão descartados.`
        : `Deseja realmente sair da criação da nova carga? Todos os dados não salvos, serão descartados.`,
      overlayClassName: "alert-fechar-modal",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            fecharModalSemAlert()
          },
          className: "botao-confirmar"
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar"
        }
      ]
    })
  }

  useEffect(() => {
    switch (tipoUsuario) {
      case "embarcador":
      case "colaborador_embarcador":
        api.get(`destinatario/?embarcador=${dadosUsuario.embarcador_id}`)
          .then(function (response) {
            setDestinatarios(response.data.results)
          })
          .catch(function (error) {
            console.log(error)
          })
        break;
      case "transportadora":
      case "colaborador_transportadora":
        api.get(`destinatario/?transportadora=${dadosUsuario.transportadora_id}`)
          .then(function (response) {
            setDestinatarios(response.data.results)
          })
          .catch(function (error) {
            console.log(error)
          })
        break;
      default:
        api.get(`destinatario/`)
          .then(function (response) {
            setDestinatarios(response.data.results)
          })
          .catch(function (error) {
            console.log(error)
          })
        break;
    }
  }, [tipoUsuario, dadosUsuario.embarcador_id, dadosUsuario.transportadora_id])

  return (
    <>
      {
        idCarga
          ? <FaEdit className="icone-adicionar-carga editar" onClick={abrirModal}></FaEdit>
          : <FiPlusCircle className="icone adicionar" onClick={abrirModal}></FiPlusCircle>
      }
      {abrirModalNovaCarga && <ModalNovaCarga
        idCarga={idCarga}
        abrirModalNovaCarga={abrirModalNovaCarga}
        fecharModal={fecharModal}
        fecharModalSemAlert={fecharModalSemAlert}
        fecharModalDadosCarga={fecharModalDadosCarga}
        atualizarCargas={atualizarCargas}
        destinatarios={destinatarios}
      />}
    </>
  )
}

export default AdicionarCarga