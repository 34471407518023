import React, { Suspense } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query';

import Routes from '../src/routes/index'

import './global.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    }
  }
})

export default function App() {
  return (
    <Suspense fallback={<></>}>
      <QueryClientProvider client={queryClient}>
        <Routes />
      </QueryClientProvider>
    </Suspense>
  )
}
