import React, { memo, useState, useEffect, useCallback } from "react"
import { FaTrashAlt, FaEdit, FaPlus } from 'react-icons/fa'
import { confirmAlert } from 'react-confirm-alert'
import { parseISO, format } from 'date-fns'
import 'react-confirm-alert/src/react-confirm-alert.css'

import ModalNovoFretePagar from "./ModalNovoFretePagar"

import './styles.css'

import api from "../../../../../../services/api"

const TabelaFretesPagar = ({ idCarga, updateState, requisitarDocumentos }) => {
  const [fretes, setFretes] = useState([])
  const [saldo, setSaldo] = useState(0)
  const [modalNovoFretePagar, setModalNovoPagar] = useState(false)
  const [dadosFreteSelecionado, setDadosFreteSelecionado] = useState(null)

  const handleModalNovoFrete = useCallback(() => {
    if (modalNovoFretePagar) {
      setModalNovoPagar(false)
      document.querySelector("body").style.overflow = "visible"
    } else {
      setModalNovoPagar(true)
      document.querySelector("body").style.overflow = "hidden"
    }
  }, [modalNovoFretePagar])

  const requisitarFretes = useCallback(() => {
    api.get(`documentos_fiscais/web/?carga=${idCarga}`)
      .then(function (response) {
        setFretes(response.data.frete_pagar);
        setSaldo(response.data.saldo_total);
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idCarga])

  const removerFrete = (idFrete) => {
    api.delete(`documentos_fiscais/fretepagar/${idFrete}/`)
      .then(function () {
        requisitarFretes();
        requisitarDocumentos();
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const alertRemoverFrete = (idFrete) => {
    confirmAlert({
      title: "Remover Frete",
      message: `Deseja realmente remover o frete de ID ${idFrete} sistema?`,
      overlayClassName: "alert-remover-frete",
      buttons: [
        {
          label: "Sim",
          onClick: () => removerFrete(idFrete),
          className: "botao-confirmar"
        },
        {
          label: "Não",
          onClick: () => { },
          className: "botao-cancelar"
        }
      ]
    })
  }

  const editarFrete = (idFrete) => {
    setDadosFreteSelecionado(idFrete)
    handleModalNovoFrete()
  }

  useEffect(() => {
    requisitarFretes()
  }, [requisitarFretes, updateState])

  return (
    <div className="tabela-fretes-pagar">
      <button className="btn-frete" onClick={handleModalNovoFrete}>
        Novo Frete a Pagar
        <FaPlus className="icone"></FaPlus>
      </button>

      <div className="container-tabela-fretes-pagar">
        <table>
          <thead>
            <tr>
              <th>Data</th>
              <th>Tipo</th>
              <th>Valor</th>
              <th>Observações</th>
              <th>Ações</th>
            </tr>
          </thead>

          <tbody>
            {fretes.map((frete, index) => (
              <tr
                key={index}
                className={`linha ${index % 2 === 0 ? "par" : "impar"}`}
              >
                <td>{format(parseISO(frete.data), "dd/MM/yyyy")}</td>
                <td>{frete.tipo}</td>
                <td>{frete.valor}</td>
                <td className="coluna-observacoes">{frete.observacoes}</td>
                <td className="coluna-acoes">
                  <FaEdit className="icone" onClick={() => editarFrete(frete)}></FaEdit>
                  <FaTrashAlt className="icone lixo" onClick={() => alertRemoverFrete(frete.id)}></FaTrashAlt>
                </td>
              </tr>
            ))}
            <tr className="linha-total">
              <td colSpan={2}>Saldo</td>
              <td className="coluna-total">{saldo?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
              <td colSpan={2}></td>
            </tr>
          </tbody>
        </table>
      </div >

      {modalNovoFretePagar &&
        <ModalNovoFretePagar
          modalNovoFretePagar={modalNovoFretePagar}
          handleModalNovoFrete={handleModalNovoFrete}
          dadosFreteSelecionado={dadosFreteSelecionado}
          requisitarFretes={requisitarFretes}
          requisitarDocumentos={requisitarDocumentos}
          idCarga={idCarga}
        ></ModalNovoFretePagar>
      }
    </div>
  )
}

export default memo(TabelaFretesPagar)