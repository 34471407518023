import React from 'react';
import InputMask from 'react-input-mask';
import DocumentoDono from '../../../../components/formularios/DocumentoDono'
import InputSearchVeiculoCarroceria from '../../../../components/plataformaAcessada/InputSearchVeiculoCarroceria'
import InputCPFouCNPJ from '../../../../components/formularios/InputCPFouCNPJ'

const CarroceriaItem = ({
  desabilitarInputs,
  handleChange,
  setFieldValue,
  item,
  index,
  tipoCarroceiras,
}) => {

  return (
    <div className="container-inputs-2col">
      <div className="container-input">
        <InputMask
          required
          mask="***-****"
          maskChar=" "
          value={item.placa}
          id={`carrocerias.${index}.placa`}
          onChange={handleChange}
          disabled={desabilitarInputs}
        ></InputMask>
        <span>Placa <i>*</i></span>
      </div>
      <div className="container-input">
        <InputMask
          required
          mask="999999999"
          maskChar=" "
          type="text"
          value={item.rntrc}
          id={`carrocerias.${index}.rntrc`}
          onChange={handleChange}
          disabled={desabilitarInputs}
        />
        <span>RNTRC <i>*</i></span>
      </div>
      <div className="container-input">
        <input
          required
          type="text"
          value={item.marca}
          id={`carrocerias.${index}.marca`}
          onChange={handleChange}
          disabled={desabilitarInputs}
        />
        <span>Marca <i>*</i></span>
      </div>
      <div className="container-input">
        <InputCPFouCNPJ
          required
          type="text"
          value={item.antt}
          id={`carrocerias.${index}.antt`}
          onChange={handleChange}
          disabled={desabilitarInputs}
        />
        <span>CPF/CNPJ registrado na ANTT <i>*</i></span>
      </div>

      <DocumentoDono
        idTipo={`carrocerias.${index}.tipo_pessoa_dono`}
        valueTipo={item.tipo_pessoa_dono}
        idDocumento={`carrocerias.${index}.cpf_cnpj_dono`}
        valueDocumento={item.cpf_cnpj_dono}
        onChange={handleChange}
        disabled={desabilitarInputs}
      />

      <div className="container-input">
        <input
          required
          type="text"
          value={item.nome_dono}
          id={`carrocerias.${index}.nome_dono`}
          onChange={handleChange}
          disabled={desabilitarInputs}
        />
        <span>Nome do Dono <i>*</i></span>
      </div>

      <InputSearchVeiculoCarroceria
        placeholder="Buscar Tipo Veículo"
        value={item.tipo_nome}
        saveValue={(e) => setFieldValue(`carrocerias.${index}.tipo_nome`, e)}
        saveId={(e) => setFieldValue(`carrocerias.${index}.tipo`, e)}
        disabled={desabilitarInputs}
        suggestions={tipoCarroceiras}>
      </InputSearchVeiculoCarroceria>
    </div>
  )
}

export default CarroceriaItem;
