import { useEffect, useRef } from "react";
import apiPrivada from "../../../../../services/api";

const YouTubePlayer = ({ videoId, videoIdYoutube }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    let player;

    const loadYouTubeScript = (callback) => {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      window.onYouTubeIframeAPIReady = () => {
        callback();
        tag.remove();
      };;
    }

    loadYouTubeScript(() => {
      player = new window.YT.Player(playerRef.current, {
        videoId: videoIdYoutube,
        events: {
          onStateChange: handlePlayerStateChange,
        },
      });
    });


    return () => {
      player?.destroy();
    };

  }, [videoIdYoutube]);

  const handlePlayerStateChange = (event) => {
    const playerState = event.data;
    console.log(playerState);
    if (playerState === window.YT.PlayerState.ENDED) {
      // Vídeo como concluído aqui
      apiPrivada.post("/tutoriais/assistido/", {
        video: videoId
      })
    }
  }

  return (
    <div>
      <div key={videoId} ref={playerRef} />
    </div>
  );
}

export default YouTubePlayer;
