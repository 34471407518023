import React, { useMemo, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { FaTrashAlt } from 'react-icons/fa'
import { useQueryClient } from 'react-query';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmDelete } from '../../../../components/actions';
import { useDeleteColetaMutation } from '../../../../core/mutations'
import { Checkbox } from '../../../../components/forms';
import ModalDadosCte from "../../PainelMonitoramento/ModalPerfilMotoristaCarga/Financeiro/TabelaRelatorioCargas/ModalDadosCte";
import ModalDadosNfe from "../../PainelMonitoramento/ModalPerfilMotoristaCarga/Financeiro/TabelaRelatorioCargas/ModalDadosNfe";
import ModalGerarCteAvulso from '../../PainelMonitoramento/DocumentosGerais/GerarDocumentoAvulso/ModalGerarCteAvulso';
import { ModalAnexarDFE } from '../../../../modals/modal-anexar-dfe';
import api from '../../../../services/api';
import { useMessages } from '../../../../context/messages-context';
import { useSearch } from '../../../../core/hooks';

export const ColetasRow = ({ coleta }) => {
  const deleteMutation = useDeleteColetaMutation();
  const { showApiErrors } = useMessages();
  const { page } = useSearch();
  const [idCteVisualizar, setIdCteVisualizar] = useState(null);
  const [idNfeVisualizar, setIdNfeVisualizar] = useState(null);
  const [idColetaAnexar, setIdColetaAnexar] = useState(null);
  
  const [retificarCte, setRetificarCte] = useState(false);

  const queryClient = useQueryClient();

  const handleDelete = async () => {
    const title = "Remover Coleta";
    const message = `Deseja realmente remover a coleta "${coleta.id}" do sistema?`;

    const confirm = await confirmDelete({ title, message });
    if (!confirm) {
      return;
    }

    deleteMutation.mutate({ id: coleta.id });
  }

  const motorista = useMemo(() => {
    if (!coleta.cargas.length) {
      return '';
    }
    return coleta.cargas[0].carga?.motorista_em_viagem?.nome_completo || '';
  }, [coleta])

  const placaVeiculo = useMemo(() => {
    if (!coleta.cargas.length) {
      return '';
    }
    return coleta.cargas[0].carga?.motorista_em_viagem?.veiculo?.placa_cavalo || '';
  }, [coleta]);

  const placaCarreta = useMemo(() => {
    if (!coleta.cargas.length) {
      return '';
    }
    const items = coleta.cargas[0].carga?.motorista_em_viagem?.veiculo?.carrocerias?.filter((item) => item.placa !== placaVeiculo);
    if (!items?.length) {
      return '';
    }
    return items[0].placa || '';
  }, [coleta, placaVeiculo]);

  const status = useMemo(() => {
    if (coleta.status === 'COLETA') {
      return 'COLETA';
    }
    if (coleta.status === 'PROCESSADA') {
      return 'PROCESSADA';
    }
    if (coleta.status === 'EM_ENTREGA') {
      return 'EM ENTREGA';
    }
    if (coleta.status === 'ENTREGUE') {
      return 'ENTREGUE';
    }
    if (coleta.status === 'EM_COLETA') {
      return 'EM COLETA';
    }
    if (coleta.status === 'COLETADA') {
      return 'COLETADA';
    }
    return '';
  }, [coleta]);

  const handleRetificar = () => {
    if (!coleta.cte) {
      return;
    }
    confirmAlert({
      title: "Retificar CTe?",
      message: `Erro de emissão:  ${
        coleta.cte.erros_emissao?.sefaz ||
        coleta.cte.erros_emissao?.tecnospeed
      }`,
      overlayClassName: "alert-remover-nfe",
      buttons: [
        {
          label: "Ok",
          onClick: () => setRetificarCte(true),
          className: "botao-cancelar",
        },
        {
          label: "Cancelar",
          className: "botao-confirmar",
        },
      ],
    });
  }

  const handleAttachNfe = async (files, isNfe) => {
    if (!isNfe || !files.length) {
      return;
    }
    const [file] = files;
    const form = new FormData();
    form.append('nfe', file);

    try {
      api.put(`/fracionado/coletas/${idColetaAnexar}/attach/`, form);

      await queryClient.invalidateQueries(['coletas']);
      await queryClient.refetchQueries(['coletas', page]);

      setIdColetaAnexar(null);
    } catch (err) {
      showApiErrors(err);
    }
  }

  return (
    <>
      <tr className="divisoria-linhas-tabela"></tr>
      <tr>
        <td>
          {coleta.cte ? (
            <>&nbsp;</>
          ) : (
            <Checkbox name={coleta.id} small />
          )}
        </td>
        <td>{coleta.id}</td>
        <td>{status}</td>
        <td>
          {coleta.nfe.chave ? (
            <span className="action-link" onClick={() => setIdNfeVisualizar(coleta.nfe.id)}>
              {coleta.nfe.numero}
            </span>
          ) : (
            <span className="action-link" onClick={() => setIdColetaAnexar(coleta.id)}>
              Anexar NF-e
            </span>
          )}
        </td>
        <td>
          {!!coleta.cte ? (
            <span className="action-link" onClick={() => setIdCteVisualizar(coleta.cte.id)}>
              {coleta.cte.numero}
            </span>
          ) : (
            <>-</>
          )}
        </td>
        <td>
          {!!coleta.cte ? (
            <>
              {["Negado", "Cancelado"].includes(coleta.cte.status) ? (
                <span className="action-link" onClick={handleRetificar}>
                  {coleta.cte.status}
                </span>
              ) : (
                <>{coleta.cte.status}</>
              )}
            </>
          ) : (
            <>-</>
          )}
        </td>
        <td>{format(parseISO(coleta.data_solicitacao), 'dd/MM/yyyy')}</td>
        <td>{coleta.cliente}</td>
        <td>{coleta.nfe.remetente_endereco.nome_municipio} - {coleta.nfe.remetente_endereco.uf}</td>
        <td>{coleta.nfe.destinatario_endereco.nome_municipio} - {coleta.nfe.destinatario_endereco.uf}</td>
        <td>{motorista}</td>
        <td>{placaVeiculo}</td>
        <td>{placaCarreta}</td>

        <td className="coluna-acoes">
          {coleta.status === 'COLETA' && !coleta.carga && (
            <>
              {coleta.cte ? (
                <>&nbsp;</>
              ) : (
                <>
                  {deleteMutation.isLoading ? (
                    <>Deletando...</>
                  ) : (
                    <>
                      <FaTrashAlt className="icone" onClick={handleDelete}></FaTrashAlt>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </td>
      </tr>

      {!!idCteVisualizar && (
        <ModalDadosCte
          idCteSelecionado={idCteVisualizar}
          modalDadosCte={!!idCteVisualizar}
          handleModalDadosCte={() => setIdCteVisualizar(null)}
          requisitarDocumentos={() => {}}
          adicionarCtesParaNfes={() => {}}
          handleGerarCteComplementar={() => {}}
        />
      )}

      {!!idNfeVisualizar && (
        <ModalDadosNfe
          idNfeSelecionada={idNfeVisualizar}
          modalDadosNfe={!!idNfeVisualizar}
          handleModalDadosNfe={() => setIdNfeVisualizar(null)}
        />
      )}

      {retificarCte && (
        <ModalGerarCteAvulso
          isOpen={retificarCte}
          onClose={() => {
            setRetificarCte(false);
            queryClient.invalidateQueries(['coletas']);
          }}
          dadosNfes={[coleta.nfe]}
          idColeta={coleta.id}
          idCteSelecionado={coleta.cte.id}
        />
      )}

      {idColetaAnexar && (
        <ModalAnexarDFE
          isOpen={!!idColetaAnexar}
          title={"Anexar NF-e para Criar Coleta"}
          isColeta
          hideAvulso
          onClose={() => setIdColetaAnexar(null)}
          onFileChanged={handleAttachNfe}
        />
      )}
    </>
  );
};
