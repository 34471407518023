import React from 'react';
import { useField } from 'formik';
import '../input/input.scss';
import './radio.scss';

export const Radio = ({ id, name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <div className="form-input-container">
      <div className="radio-container">
        <input
          type="radio"
          id={id}
          checked={field.value === props.value}
          {...field}
          {...props}
        />
        <label htmlFor={id}>{label}</label>
      </div>
      {meta.touched && meta.error && (
        <span className="form-input-error">{meta.error}</span>
      )}
    </div>
  );
}
