import { memo, useEffect, useState } from "react"
import Modal from "react-modal";
import { RiCloseCircleFill } from "react-icons/ri";
import { confirmAlert } from "react-confirm-alert";
import listaCFOPs from './listaCFOPs';

import api from "../../../../../../../../services/api";

const ModalAnularCte = ({
  id,
  isOpen,
  onClose,
  requisitarDocumentos,
  handleModalDadosCte,

  idAnulacao,
}) => {
  const [cfopSelecionado, setCfopSelecionado] = useState("2206");
  const [textoCfopSelecionado, setTextoCfopSelecionado] = useState("Anul. de vl. rel. a prest. de serv. de transp.")
  const [dataAnulacaoCte, setDataAnulacaoCte] = useState("");
  const [justificativa, setJustificativa] = useState("");
  const [numeroCte, setNumeroCte] = useState(null);

  const [loadingEnvio, setLoadingEnvio] = useState(false);
  const [idOriginal, setIdOriginal] = useState(null);

  useEffect(() => {
    if (!idAnulacao) {
      return;
    }
    api
      .get(`documentos_fiscais/cte/${idAnulacao}/dados_emissao/`)
      .then((response) => {
        const dadosCte = response.data;

        setIdOriginal(dadosCte.cte_original)
        setNumeroCte(dadosCte.numero);
        setCfopSelecionado(dadosCte.codigo_fiscal_operacao_prestacao);
        setTextoCfopSelecionado(dadosCte.natureza_operacao);
        
        if (dadosCte.informacoes_anulacao) {
          const data = dadosCte.informacoes_anulacao.data;
          setDataAnulacaoCte(data);
        }

        setJustificativa(dadosCte.observacoes || '');
      })
      .catch((error) => {
        console.log(error);
      })
  }, [idAnulacao]);

  const emitir = (e) => {
    e.preventDefault();
    setLoadingEnvio(true);

    const jsonData = {
      justificativa,
      data: dataAnulacaoCte,
      codigo_fiscal_operacao_prestacao: cfopSelecionado,
      natureza_operacao: textoCfopSelecionado,
      numero: numeroCte || null,
      cte: idAnulacao || null,
    };

    api
      .post(`documentos_fiscais/cte/${idOriginal || id}/anular/`, jsonData)
      .then(function () {
        requisitarDocumentos();
        onClose();
        if (!idOriginal) {
          handleModalDadosCte();
        }
      })
      .catch((error) => {
        confirmAlert({
          title: "Erro!",
          message: `Erro ao emitir CTe de Anulação: ${error.response?.data?.detail}`,
          overlayClassName: "alert-remover-nfe",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      })
      .finally(() => {
        setLoadingEnvio(false);
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-gerar-cte"
      overlayClassName="overlay-modal-gerar-cte"
      ariaHideApp={false}
    >
      <form className="gerar-cte" onSubmit={emitir}>
        <RiCloseCircleFill
          className="icone-fechar"
          onClick={onClose}
        ></RiCloseCircleFill>

        <p className="titulo">Emitir CT-e de Anulação</p>

        <div className="container-formulario">
          <div className="container-inputs-2col">
            <div className="container-input-comum">
              <select
                value={cfopSelecionado}
                onChange={(e) => {
                  setCfopSelecionado(e.target.value);
                  setTextoCfopSelecionado(
                    e.target.selectedOptions[0].getAttribute("data-texto")
                  );
                }}
              >
                <option value="">Selecione</option>
                {listaCFOPs.map((cfop) => {
                  return (
                    <option
                      key={cfop
                        .split("-")[0]
                        .replaceAll(" ", "")
                        .replaceAll(".", "")}
                      value={cfop
                        .split("-")[0]
                        .replaceAll(" ", "")
                        .replaceAll(".", "")}
                      data-texto={cfop.split("-")[1]}
                    >
                      {cfop}
                    </option>
                  );
                })}
              </select>
              <span>CFOP</span>
            </div>

            <div className="container-input-comum">
                <input
                  type="date"
                  value={dataAnulacaoCte}
                  onChange={(e) => setDataAnulacaoCte(e.target.value)}
                />
                <span>Data de Emissão de Declaração</span>
              </div>
          </div>

          <div className="container-inputs-2col">
            <div className="container-input-comum">
              <input
                required
                type="text"
                value={justificativa}
                minlength="15"
                onChange={(e) => setJustificativa(e.target.value)}
              />
              <span>Justificativa</span>
            </div>
          </div>
        </div>

        <div className="container-botoes">
          <button className="btn cancelar" onClick={onClose}>
            CANCELAR
          </button>

          <button
            type="submit"
            className="btn enviar"
            disabled={loadingEnvio}
          >
            ENVIAR CT-e PARA SEFAZ
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default memo(ModalAnularCte);
