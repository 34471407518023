import React, { useEffect, useState } from 'react';
import { useFormikContext, Formik, Form } from 'formik';
import {
    Radio,
    FourCols,
    Input,
    InputCNPJ,
    InputCPF,
    InputPostalCode,
    SelectUF,
    SelectMunicipio,
    FormSectionTitle,
    ThreeCols,
} from '../../../components/forms'
import { HStack } from '../../../components/utils'
import { Button } from '../../../components/common'
import { getAddressFromPostalCode } from '../../../utils/address';
import { ModalSelecionarDadosCliente } from '../../modal-selecionar-dados-cliente';

const initialData = {
    modalidade_frete: 'CIF',
    valor: '0',
    peso: '0',
    distancia: '0',
    largura: '0',
    altura: '0',
    comprimento: '0',
    volume: '0',
    remetente_cnpj: '',
    remetente_cpf: '',
    remetente_razao_social: '',
    remetente_endereco: {
        uf: '',
        cep: '',
        pais: 'BRASIL',
        bairro: '',
        numero: '',
        logradouro: '',
        complemento: '',
        nome_municipio: '',
        codigo_municipio: ''
    },
    destinatario_cnpj: '',
    destinatario_cpf: '',
    destinatario_razao_social: '',
    destinatario_endereco: {
        uf: '',
        cep: '',
        pais: 'BRASIL',
        bairro: '',
        numero: '',
        logradouro: '',
        complemento: '',
        nome_municipio: '',
        codigo_municipio: ''
    }
}

const InputVolume = () => {
    const { values, setFieldValue } = useFormikContext();
    const { largura, altura, comprimento } = values;

    useEffect(() => {
        const comp = parseFloat(comprimento || 0) / 100;
        const larg = parseFloat(largura || 0) / 100;
        const alt = parseFloat(altura || 0) / 100;

        const volume = (comp * larg * alt).toFixed(2);
        setFieldValue('volume', volume);
    }, [largura, altura, comprimento, setFieldValue])

    return (
        <Input type="number" name="volume" label="Volume (m^3)" />
    );
}

const Endereco = ({ field }) => {
    const { values, setValues } = useFormikContext();

    const handlePostalCodeComplete = async (cep) => {
        try {
          const data = await getAddressFromPostalCode(cep);
          
          setValues({
            ...values,
            [field]: {
              ...values[field],
              uf: data.uf,
              cep: data.cep,
              nome_municipio: data.municipio,
              codigo_municipio: data.municipio_codigo,
              bairro: data.bairro,
              numero: data.numero,
              logradouro: data.logradouro,
              complemento: data.complemento,
            }
          })
        } catch {}
    }

    return (
        <>
            <FourCols>
                <InputPostalCode
                    name={`${field}.cep`}
                    label="CEP"
                    onPostalCodeComplete={handlePostalCodeComplete}
                />
                <SelectUF
                    name={`${field}.uf`}
                    municipioField={`${field}.nome_municipio`}
                    municipioCodeField={`${field}.codigo_municipio`}
                    label="Estado"
                    menuPosition="absolute"
                />
                <SelectMunicipio
                    name={`${field}.nome_municipio`}
                    municipioCodeField={`${field}.codigo_municipio`}
                    label="Município"
                    ufField={`${field}.uf`}
                    menuPosition="absolute"
                />
                <Input
                    name={`${field}.bairro`}
                    label="Bairro"
                />
            </FourCols>
            <FourCols>
                <Input
                    name={`${field}.logradouro`}
                    label="Logradouro"
                />
                <Input
                    name={`${field}.numero`}
                    label="Número"
                />
                <Input
                    name={`${field}.complemento`}
                    label="Complemento"
                />
            </FourCols>
        </>
    )
}

const Modal = ({
    importarIsRemetente,
    selecionarDadosCliente,
    setSelecionarDadosCliente,
}) => {
    const { values, setValues } = useFormikContext();

    const handleConfirmarImportacao = (cliente) => {
        console.log(cliente);

        const { endereco } = cliente;

        const field = importarIsRemetente ? 'remetente_endereco' : 'destinatario_endereco';
        const cnpj = importarIsRemetente ? 'remetente_cnpj' : 'destinatario_cnpj';
        const cpf = importarIsRemetente ? 'remetente_cpf' : 'destinatario_cpf';
        const razao_social = importarIsRemetente ? 'remetente_razao_social' : 'destinatario_razao_social';
        const json = {
            ...values,
            [razao_social]: cliente.nome_completo,
            [cnpj]: cliente.cnpj,
            [cpf]: '',
            [field]: {
                ...values[field],
                uf: endereco.uf,
                cep: endereco.cep,
                nome_municipio: endereco.municipio,
                codigo_municipio: endereco.municipio_codigo,
                bairro: endereco.bairro,
                numero: endereco.numero,
                logradouro: endereco.logradouro,
                complemento: endereco.complemento,
            }
        };
        console.log(json);
        setValues(json);
        setSelecionarDadosCliente(false);
    }

    return (
        <>
            {selecionarDadosCliente && (
                <ModalSelecionarDadosCliente
                    isOpen={selecionarDadosCliente}
                    onClose={() => setSelecionarDadosCliente(false)}
                    onConfirmSelect={handleConfirmarImportacao}
                />
            )}
        </>
    );
}

const cleanDigits = (document) => {
    return document
      .replaceAll('.', '')
      .replaceAll('_', '')
      .replaceAll('-', '')
      .replaceAll('/', '')
      .trim();
  };

export const ColetaAvulsa = ({ onCreate }) => {
    const { values } = useFormikContext();
    const isAvulsa = values.tipo === 'avulsa';
    const [selecionarDadosCliente, setSelecionarDadosCliente] = useState(false);
    const [importarIsRemetente, setImportarIsRemetente] = useState(true);

    const handleImportarRemetente = () => {
        setImportarIsRemetente(true);
        setSelecionarDadosCliente(true);
    }
    
    const handleImportarDestinatario = () => {
        setImportarIsRemetente(false);
        setSelecionarDadosCliente(true);
    }

    const handleSubmit = (data) => {
        onCreate({
            ...data,
            carga: null,
            remetente_cnpj: cleanDigits(data.remetente_cnpj),
            remetente_cpf: cleanDigits(data.remetente_cpf),
            destinatario_cnpj: cleanDigits(data.destinatario_cnpj),
            destinatario_cpf: cleanDigits(data.destinatario_cpf),
        })
    }

    if (!isAvulsa) {
        return null;
    }

    return (
        <>
        <Formik initialValues={initialData} onSubmit={handleSubmit}>
            <Form>
                <div className='coleta-avulsa-form'>
                    <FourCols>
                        <Input type="number" name="valor" label="Valor da Entrega" />
                        <Input type="number" name="peso" label="Peso da Entrega (Kg)" />
                        <Input type="number" name="distancia" label="Distância da Entrega (KM)" required />
                        <HStack alignItems="center" justifyContent="space-between" gap="20px">
                            <Radio id="cif" name="modalidade_frete" value="CIF" label="CIF" />
                            <Radio id="fob" name="modalidade_frete" value="FOB" label="FOB" />
                        </HStack>
                        <div />
                    </FourCols>
                    <FourCols>
                        <Input type="number" name="largura" label="Largura (cm)" />
                        <Input type="number" name="altura" label="Altura (cm)" />
                        <Input type="number" name="comprimento" label="Comprimento (cm)" />
                        <InputVolume />
                    </FourCols>

                    <div className="header-block">
                        <FormSectionTitle>Remetente</FormSectionTitle>
                        <button className="btn-import-cliente" type="button" onClick={handleImportarRemetente}>
                            Importar de Cliente
                        </button>
                    </div>

                    <ThreeCols>
                        <InputCNPJ name="remetente_cnpj" label="CNPJ" />
                        <InputCPF name="remetente_cpf" label="CPF" />
                        <Input name="remetente_razao_social" label="Razão Social" />
                    </ThreeCols>
                    
                    <FormSectionTitle small>Endereço</FormSectionTitle>
                    <Endereco field="remetente_endereco" />

                    <div className="header-block">
                        <FormSectionTitle>Destinatário</FormSectionTitle>
                        <button className="btn-import-cliente" type="button" onClick={handleImportarDestinatario}>
                            Importar de Cliente
                        </button>
                    </div>

                    <ThreeCols>
                        <InputCNPJ name="destinatario_cnpj" label="CNPJ" />
                        <InputCPF name="destinatario_cpf" label="CPF" />
                        <Input name="destinatario_razao_social" label="Razão Social" />
                    </ThreeCols>

                    <FormSectionTitle small>Endereço</FormSectionTitle>
                    <Endereco field="destinatario_endereco" />
                </div>

                <div className='coleta-avulsa-submit-row'>
                    <Button type="submit" variant="save">
                        Criar Coleta
                    </Button>
                </div>

                <Modal
                    importarIsRemetente={importarIsRemetente}
                    selecionarDadosCliente={selecionarDadosCliente}
                    setSelecionarDadosCliente={setSelecionarDadosCliente}
                />
            </Form>
        </Formik>
        </>
    )
}