import React, { memo, useEffect, useState, useCallback } from 'react'
import Modal from 'react-modal'
import axios from "axios"
import { RiCloseCircleFill } from 'react-icons/ri'
import ReactPaginate from 'react-paginate'
import { ImSearch } from 'react-icons/im'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import './styles.css'
import api from "../../../../../services/api"

const ModalAdicionarMotorista = ({ idGrupo, abrirModalAdicionarMotorista, fecharModal, requisitarMotoristas }) => {
  const dadosUsuario = JSON.parse(localStorage.getItem("@RASTRO:USER"))

  const [quantidadePaginasTransportadora, setQuantidadePaginasTransportadora] = useState(1)
  const [paginaAtualTransportadora, setPaginaAtualTransportadora] = useState(0)

  const [motoristasEscolhidos, setMotoristasEscolhidos] = useState([])
  const [motoristasTransportadora, setMotoristasTransportadora] = useState([])
  
  const [motoristasPublicos, setMotoristasPublicos] = useState([])
  const [motoristasPublicosPaginas, setMotoristasPublicosPaginas] = useState(1);
  const [motoristasPublicosAtual, setMotoristasPublicosAtual] = useState(0);

  const [searchMotoristaPublico, setSearchMotoristaPublico] = useState("")
  const [searchMotoristaTransportadora, setSearchMotoristaTransportadora] = useState("")

  const adicionarMotoristaGrupo = () => {
    let aux = []

    motoristasEscolhidos.forEach((item) => {
      aux.push(
        api.post(`motoristasgrupo/`, {
          grupo: idGrupo,
          motorista: item.id
        })
          .then(function () {
          })
          .catch()
      )
    })

    axios.all(aux).then(function () {
      requisitarMotoristas()

      confirmAlert({
        title: "Sucesso!",
        message: `Os motoristas foram adicionados ao grupo.`,
        overlayClassName: "alert-motorista-adicionado",
        buttons: [
          {
            label: "Ok",
            onClick: () => { fecharModal() }
          }
        ]
      })
    }).catch(function () {
      confirmAlert({
        title: "Erro!",
        message: `O motorista já existe no grupo.`,
        overlayClassName: "alert-motorista-adicionado",
        buttons: [
          {
            label: "Ok",
            onClick: () => { fecharModal() }
          }
        ]
      })
    })
  }

  const requisitarMotoristasTransportadora = useCallback((query = '', page = 0) => {
    setMotoristasTransportadora([]);

    api.get(`motoristas/all/?transportadora=${dadosUsuario.transportadora_id}&search=${query}&offset=${page * 15}&limit=15`)
      .then(function (response) {
        setQuantidadePaginasTransportadora(Math.ceil(response.data.count / 15))
        setMotoristasTransportadora(response.data.results)
        setPaginaAtualTransportadora(page)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [dadosUsuario.transportadora_id])

  const requisitarMotoristasPublicos = useCallback((query = '', page = 0) => {
    setMotoristasPublicos([]);

    api.get(`motoristas/publicos/?search=${query}&offset=${page * 15}&limit=15`)
      .then(function (response) {
        setMotoristasPublicos(response.data.results);
        setMotoristasPublicosPaginas(Math.ceil(response.data.count / 15));
        setMotoristasPublicosAtual(page);
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const verificarTeclaSearch = (e, isPublico) => {
    if (e.keyCode === 13) {
      if (isPublico) {
        requisitarMotoristasPublicos(searchMotoristaPublico, 0);
      } else {
        requisitarMotoristasTransportadora(searchMotoristaTransportadora, 0)
      }
    }
  }

  const adicionarMotoristaLista = (motorista, isPublico) => {
    setMotoristasEscolhidos([...motoristasEscolhidos, motorista])

    if (isPublico) {
      let auxMotoristasPublicos = motoristasPublicos
      let indexMotoristaClicado = motoristasPublicos.findIndex(i => i.id === motorista.id)

      auxMotoristasPublicos.splice(indexMotoristaClicado, 1)

      setMotoristasPublicos(auxMotoristasPublicos)
    } else {
      let auxMotoristasTransportadora = motoristasTransportadora
      let indexMotoristaTransportadora = motoristasTransportadora.findIndex(i => i.id === motorista.id)

      auxMotoristasTransportadora.splice(indexMotoristaTransportadora, 1)

      setMotoristasTransportadora(auxMotoristasTransportadora)
    }
  }

  const removerMotoristaEscolhido = (motorista, index) => {
    let auxMotoristasEscolhidos = motoristasEscolhidos
    auxMotoristasEscolhidos.splice(index, 1)
    setMotoristasEscolhidos(auxMotoristasEscolhidos)

    if (motorista.transportadora === null) {
      setMotoristasPublicos([...motoristasPublicos, motorista])
    } else {
      setMotoristasTransportadora([...motoristasTransportadora, motorista])
    }
  }

  useEffect(() => {
    if (searchMotoristaPublico === "") {
      requisitarMotoristasPublicos('')
    }
  }, [searchMotoristaPublico, requisitarMotoristasPublicos])

  useEffect(() => {
    if (searchMotoristaTransportadora === "") {
      requisitarMotoristasTransportadora('');
    }
  }, [searchMotoristaTransportadora, requisitarMotoristasTransportadora])

  return (
    <Modal
      isOpen={abrirModalAdicionarMotorista}
      onRequestClose={fecharModal}
      className="modal-adicionar-motorista-grupo"
      overlayClassName="overlay-modal-adicionar-motorista-grupo"
      ariaHideApp={false}
    >

      <div className="titulo">
        <p>Adicionar Motorista</p>
        <RiCloseCircleFill className="icone" onClick={fecharModal}></RiCloseCircleFill>
      </div>

      <div className="conteudo">
        <>
          <p className="titulo-lista">Motoristas Escolhidos</p>

          {motoristasEscolhidos.length > 0
            ? <>
              <div className="lista-motoristas">
                <div className="titulo-dados">
                  <p>Nome</p>
                  <p>Tipo Veículo</p>
                  <p className="titulo-acoes">Ações</p>
                </div>

                {motoristasEscolhidos.map((item, index) => {
                  return (
                    <div
                      className="linha escolhido"
                      key={item.id}
                    >
                      <p>{item.nome_completo}</p>
                      <p>{item.veiculo?.tipo_veiculo?.tipo}</p>
                      <RiCloseCircleFill
                        className="icone-remover-motorista"
                        onClick={() => removerMotoristaEscolhido(item, index)}
                      ></RiCloseCircleFill>
                    </div>
                  )
                })}
              </div>

              <button
                className="btn-adicionar-motoristas"
                onClick={adicionarMotoristaGrupo}
              >
                Adicionar Motoristas
              </button>
            </>
            : <p className="msg-motorista-vazio">Nenhum motorista escolhido.</p>
          }
        </>

        <>
          <p className="titulo-lista">Motoristas Públicos</p>

          <div className="container-search">
            <div className="input-search-adicionar-motorista">
              <input
                type="text"
                placeholder="Buscar Motorista Público (Nome)"
                value={searchMotoristaPublico}
                onChange={(e) => setSearchMotoristaPublico(e.target.value)}
                onKeyDown={(e) => verificarTeclaSearch(e, true)}
              />
              <ImSearch className="icone"></ImSearch>
            </div>

            <button
              className="btn-buscar"
              onClick={() => requisitarMotoristasPublicos(searchMotoristaPublico, 0)}
            >
              Buscar
            </button>
          </div>

          <div className="lista-motoristas">
            <div className="titulo-dados">
              <p>Nome</p>
              <p>Tipo Veículo</p>
            </div>

            {motoristasPublicos.map((item) => {
              return (
                <div
                  className="linha"
                  key={item.id}
                  onClick={() => adicionarMotoristaLista(item, true)}
                >
                  <p>{item.nome_completo}</p>
                  <p>{item.veiculo?.tipo_veiculo?.tipo}</p>
                </div>
              )
            })}
          </div>

          {motoristasPublicosPaginas > 1 && (
            <div className="container-paginacao-adicionar-motorista">
              <ReactPaginate
                previousLabel={"Anterior"}
                nextLabel={"Próxima"}
                breakLabel={"..."}
                pageCount={motoristasPublicosPaginas}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                forcePage={motoristasPublicosAtual}
                onPageChange={(e) => requisitarMotoristasPublicos(searchMotoristaPublico, e.selected)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          )}
        </>

        <>
          <p className="titulo-lista">Motoristas da Transportadora</p>

          <div className="container-search">
            <div className="input-search-adicionar-motorista">
              <input
                type="text"
                placeholder="Buscar Motorista Transportadora (Nome)"
                value={searchMotoristaTransportadora}
                onChange={(e) => setSearchMotoristaTransportadora(e.target.value)}
                onKeyDown={(e) => verificarTeclaSearch(e, false)}
              />
              <ImSearch className="icone"></ImSearch>
            </div>

            <button
              className="btn-buscar"
              onClick={() => requisitarMotoristasTransportadora(searchMotoristaTransportadora, 0)}
            >Buscar
            </button>
          </div>

          <div className="lista-motoristas">
            <div className="titulo-dados">
              <p>Nome</p>
              <p>Tipo Veículo</p>
            </div>

            {motoristasTransportadora.map((item) => {
              return (
                <div
                  className="linha"
                  key={item.id}
                  // onClick={() => adicionarMotoristaGrupo(item.id, item.nome_completo)}
                  onClick={() => adicionarMotoristaLista(item, false)}
                >
                  <p>{item.nome_completo}</p>
                  <p>{item.veiculo?.tipo_veiculo?.tipo ? item.veiculo.tipo_veiculo.tipo : ""}</p>
                </div>
              )
            })}
          </div>

          {quantidadePaginasTransportadora > 1 &&
            <div className="container-paginacao-adicionar-motorista">
              <ReactPaginate
                previousLabel={"Anterior"}
                nextLabel={"Próxima"}
                breakLabel={"..."}
                pageCount={quantidadePaginasTransportadora}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                forcePage={paginaAtualTransportadora}
                onPageChange={(e) => requisitarMotoristasTransportadora(searchMotoristaTransportadora, e.selected)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          }
        </>
      </div>
    </Modal>
  )
}

export default memo(ModalAdicionarMotorista)