import { useEffect, useState } from "react";
import Select from "react-select";
import api from "../../../../services/api";

const DadosBancarios = ({
  dados_bancarios,
  handleChange,
  desabilitarInputs,
  setFieldValue,
}) => {
  const [bancos, setBancos] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await api.get("/banks");
        setBancos(data);
      } catch {}
    };
    fetch();
  }, []);

  return (
    <div className="categoria">
      <p className="titulo-categoria">Dados Bancários</p>

      <div className="container-inputs-2col">
        <div className="container-input">
          <Select
            required
            className="banco"
            type="text"
            value={{
              value: dados_bancarios.banco,
              label: dados_bancarios.banco
            }}
            id="dados_bancarios.banco"
            onChange={(item) => setFieldValue('dados_bancarios.banco', item.value)}
            disabled={desabilitarInputs}
            options={bancos.map((item) => ({
              value: item.full_name,
              label: item.full_name,
            }))}
          />
          <span className="fixed">
            Banco <i>*</i>
          </span>
        </div>

        <div className="container-input">
          <input
            required
            type="text"
            value={dados_bancarios.agencia}
            id="dados_bancarios.agencia"
            onChange={handleChange}
            disabled={desabilitarInputs}
          />
          <span>Agência</span>
        </div>

        <div className="container-input">
          <input
            required
            type="text"
            value={dados_bancarios.conta}
            id="dados_bancarios.conta"
            onChange={handleChange}
            disabled={desabilitarInputs}
          />
          <span>Conta</span>
        </div>

        <div className="container-input">
          <input
            required
            type="text"
            value={dados_bancarios.titular}
            id="dados_bancarios.titular"
            onChange={handleChange}
            disabled={desabilitarInputs}
          />
          <span>Nome Completo do Titular</span>
        </div>

        <div className="container-input">
          <select
            required
            type="text"
            value={dados_bancarios.tipo_conta}
            id="dados_bancarios.tipo_conta"
            onChange={handleChange}
            disabled={desabilitarInputs}
          >
            <option value="" disabled></option>
            <option value="corrente">Conta Corrente</option>
            <option value="poupanca">Conta Poupança</option>
          </select>
          <span>Tipo da Conta</span>
        </div>

        <div className="container-input">
          <select
            required
            type="text"
            value={dados_bancarios.tipo_pix}
            id="dados_bancarios.tipo_pix"
            onChange={handleChange}
            disabled={desabilitarInputs}
          >
            <option value="" disabled></option>
            <option value="cpf">CPF</option>
            <option value="cnpj">CNPJ</option>
            <option value="email">E-mail</option>
            <option value="telefone">Telefone</option>
            <option value="aleatória">Aleatória</option>
            <option value="sem-pix">Sem PIX</option>
          </select>
          <span>Tipo Chave PIX</span>
        </div>

        <div className="container-input">
          <input
            type="text"
            value={dados_bancarios.chave_pix}
            id="dados_bancarios.chave_pix"
            onChange={handleChange}
            disabled={desabilitarInputs}
          />
          <span>Chave PIX</span>
        </div>
      </div>
    </div>
  );
};

export default DadosBancarios;
