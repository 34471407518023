import React, { useState, useRef, useCallback, useEffect } from "react";
import Modal from "react-modal";
import { FaRegCalendarAlt, FaRegCalendarCheck } from "react-icons/fa";
import ReactPaginate from 'react-paginate'
import { BsPrinterFill } from "react-icons/bs";
import { parseISO, format } from "date-fns";

import "react-confirm-alert/src/react-confirm-alert.css";

import api from "../../../../../../services/api";

import "./styles.css";

const ModalHistorico = ({
  historicoSelecionado,
  modalHistorico,
  handleModalHistorico,
  tipo,
}) => {
  const [transacoes, setTransacoes] = useState([]);
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const inputDataInicial = useRef(null);
  const inputDataFinal = useRef(null);
  const [paginas, setPaginas] = useState(1);
  const [paginaAtual, setPaginaAtual] = useState(1);

  const formatarDatas = (data) => {
    if (data.length > 9) {
      return format(parseISO(data), "yyyy-MM-dd");
    }

    return "";
  };

  const requisitarTransacoes = useCallback((pagina) => {
    setPaginaAtual(pagina);
    setTransacoes([]);
    api
      .get(
        `/pagamentos/transacoes/?tipo=${tipo}&data__gte=${formatarDatas(
          dataInicial
        )}&data__lte=${formatarDatas(dataFinal)}&offset=${((pagina || 1) - 1) * 30}&limit=30`
      )
      .then(function (response) {
        setPaginas(Math.ceil(response.data.count / 30));
        setTransacoes(response.data.results);
      })
      .catch(function (e) {
        console.log(e.response);
      });
  }, [dataInicial, dataFinal, tipo]);

  useEffect(() => {
    const handleRequest = () => {
      if (
        dataInicial === "" ||
        dataInicial.length > 9 ||
        dataFinal === "" ||
        dataFinal.length > 9
      ) {
        requisitarTransacoes();
      }
    }
    const timeout = setTimeout(() => handleRequest(), 500);
    return () => {
      clearTimeout(timeout);
    }
  }, [requisitarTransacoes, dataInicial, dataFinal]);

  const handleGerarPlanilha = async () => {
    try {
      const { data } = await api.post('/pagamentos/coins/consumo/autorizar-planilha/');
      const { code: token } = data;
      let url = `pagamentos/coins/consumo/planilha/${token}/?`;
      if (dataInicial) {
        url = `${url}date_start=${dataInicial}&`
      }
      if (dataFinal) {
        url = `${url}date_end=${dataFinal}`
      }
      window.open(`${process.env.REACT_APP_LINK_API}${url}`);
    } catch {}
  }

  return (
    <Modal
      isOpen={modalHistorico}
      onRequestClose={handleModalHistorico}
      className="modal-historico-transacoes"
      overlayClassName="overlay-modal-historico-transacoes"
      ariaHideApp={false}
    >
      <p className="titulo">Histórico de {historicoSelecionado}</p>
      <p className="subtitulo">
        Histórico de{" "}
        {historicoSelecionado === "gastos"
          ? " todos os gastos realizados"
          : "todas as compras realizadas"}{" "}
        na plataforma:
      </p>

      <div className="datas">
        <div className="container-input-date">
          <FaRegCalendarAlt className="icone"></FaRegCalendarAlt>
          <input
            ref={inputDataInicial}
            type="text"
            onFocus={() => (inputDataInicial.current.type = "date")}
            onBlur={() => {
              dataInicial === "" && (inputDataInicial.current.type = "text");
            }}
            placeholder="Data Inicial"
            value={dataInicial}
            onChange={(e) => setDataInicial(e.target.value)}
          />
        </div>

        <div className="container-input-date">
          <FaRegCalendarCheck className="icone"></FaRegCalendarCheck>
          <input
            ref={inputDataFinal}
            type="text"
            onFocus={() => (inputDataFinal.current.type = "date")}
            onBlur={() => {
              dataFinal === "" && (inputDataFinal.current.type = "text");
            }}
            placeholder="Data Final"
            value={dataFinal}
            onChange={(e) => setDataFinal(e.target.value)}
          />
        </div>

        {tipo !== "entrada" && (
          <BsPrinterFill className="icone-imprimir" onClick={handleGerarPlanilha}></BsPrinterFill>
        )}
      </div>

      <div className="container-tabela-historico">
        <table>
          <thead>
            <tr>
              {tipo !== "entrada" && <th>Carga</th>}
              <th>Serviço</th>
              <th>{tipo === "entrada" ? 'RC Recarga' : 'RC Gasto'}</th>
              <th>RC Saldo</th>
              <th>Usuário</th>
              <th>Data</th>
            </tr>
          </thead>

          <tbody>
            {transacoes.map((item, index) => {
              return (
                <tr key={item.id} className={index % 2 !== 0 ? "destaque" : ""}>
                  {tipo !== "entrada" && <td>{item.carga?.id}</td>}
                  <td>{item.origem}</td>
                  <td>{item.coins}</td>
                  <td>{item.saldo_restante}</td>
                  <td>{item.usuario}</td>
                  <td>{format(parseISO(item.data), "dd/MM/yyyy")}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        
        {paginas > 1 && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={"Anterior"}
              nextLabel={"Próxima"}
              breakLabel={"..."}
              pageCount={paginas}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              forcePage={paginaAtual}
              onPageChange={(e) => requisitarTransacoes(e.selected)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        )}
      </div>

      <button className="botao-voltar" onClick={handleModalHistorico}>
        Voltar
      </button>
    </Modal>
  );
};

export default ModalHistorico;
