import React, { memo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import './styles.css'

import { apiPublica } from "../../../services/api"

const EsqueceuSenha = () => {
  const history = useHistory()

  const [email, setEmail] = useState('')

  const redefinirSenha = (e) => {
    e.preventDefault()

    apiPublica.post("auth/reset/", {
      email: email
    })
      .then(function (e) {
        confirmAlert({
          title: "E-mail enviado!",
          message: `Link de redefinição de senha enviado no e-mail informado.`,
          overlayClassName: "alert-redefinir-senha",
          buttons: [
            {
              label: "Ok",
              onClick: () => { history.goBack() }
            }
          ]
        })
      })
      .catch(function (e) {
        console.log(e)
      })
  }

  return (
    <form className="formulario-esqueceu-senha">
      <div className="titulo-formulario">
        <p>Redefinir Senha</p>
      </div>

      <div className="conteudo-formulario">
        <p>Informe abaixo o e-mail cadastrado para envio do link de redefinição:</p>

        <label>
          E-mail:
          <input
            required
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>

        <button
          type="submit"
          onClick={redefinirSenha}
        >
          ENVIAR
        </button>
      </div>
    </form>
  )
}

export default memo(EsqueceuSenha)